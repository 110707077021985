import MESSAGE_AUTHOR_TYPES from "../configs/messageAuthorTypes.js";
import MESSAGE_TAIL_POSITIONS from "../configs/messageTailPositions/index.js";

const { agent, customer } = MESSAGE_AUTHOR_TYPES;
const { left, right } = MESSAGE_TAIL_POSITIONS;

export default function getMessageTailPosition(
  currentMessageAuthorType,
  previousMessageAuthorType,
) {
  let messageTailPosition = null;

  if (
    currentMessageAuthorType === customer &&
    previousMessageAuthorType === agent
  )
    messageTailPosition = left;
  else if (
    currentMessageAuthorType === agent &&
    previousMessageAuthorType === customer
  )
    messageTailPosition = right;

  return messageTailPosition;
}
