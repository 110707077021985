import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { useMaterialUIController, setIsVipUser } from "context";
import { userMenus } from "globals/configuration/user-management";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import { useState } from "react";

const groupAdmin = AuthenticationService.allowGroupAdmin();

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableMultipleUpdate: false,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : userMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
          disabled: item.disabled,
          productionReady: item.productionReady,
        };
      }),
  settingsMode: false,
};

const operationurls = {
  update: "user/update",
  create: "user/register",
};

const getUpdatedColumns = (onEdit) => {
    const [controller, dispatch] = useMaterialUIController();
    const { isVipUser } = controller;

  const onChangeVip = (event, formik) => {
    if (event.target.checked) {
      setIsVipUser(dispatch, true);
    } else {
      setIsVipUser(dispatch, false);
    }
  };

  return [
    {
      Header: "User ID",
      accessor: "username",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact No./Extension No.",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "Active",
      accessor: "enabled",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Is VIP",
      accessor: "vip",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangeVip,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "VIP Name",
      accessor: "vipName",
      width: 6,
      displayInList: false,
      allowInForm: isVipUser,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Designation",
      accessor: "designation",
      width: 6,
      displayInList: false,
      allowInForm: isVipUser,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Rank",
      accessor: "rank",
      width: 6,
      displayInList: false,
      allowInForm: isVipUser,
      allowFilter: false,
      dataType: "integer",
      validation: formControl.string().digitsOnly().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getUpdatedColumns, Attributes, operationurls };
