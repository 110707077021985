import DefaultCell from "application/components/DefaultCell";
import CTLDownloadFile from "application/components/CTLDownloadFile";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import PAPlayAudio from "application/components/PAPlayAudio";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "agentCDR/list",
  pdfDownload: "/agentCDR/generateAndDownloadPdf",
  csvDownload: "agentCDR/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent ID",
      accessor: "agentName",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "fullName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign Name",
      accessor: "campaignName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "callDirection",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID",
      accessor: "agentTalkedTo",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "hangupCause",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Stamp",
      accessor: "startTime",
      displayInList: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Stamp",
      accessor: "answerTime",
      displayInList: true,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Stamp",
      accessor: "endTime",
      displayInList: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "talkDuration",
      displayInList: true,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Status",
      accessor: "isMissed",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Play",
      accessor: "",
      displayInList: true,
      Cell: ({ row }) => <PAPlayAudio row={row} agentCallDataAnalysis={"agentCallDataAnalysis"}/>,
    },
    {
      Header: "Recording File",
      accessor: "recordingFile",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <CTLDownloadFile row={row} value={value} agentCallDataAnalysis={"agentCallDataAnalysis"} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
