import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import StarsIcon from "@mui/icons-material/Stars";

function ChangeDateCell({ value, suffix, row }) {
  const showTooltip = row?.original?.changedCompletionOn;
  const expectedCompletionDate = row?.original?.expectedCompletionDate;
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value}
      {showTooltip && (
        <Tooltip
          title={`The user has changed the Due Date. Old Date: ${showTooltip}, New Date: ${expectedCompletionDate}`}
        >
          <StarsIcon sx={{ fontSize: "16px" }} color="info" />
        </Tooltip>
      )}
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

ChangeDateCell.defaultProps = {
  suffix: "",
};

export default ChangeDateCell;
