import CTLUnReadCell from "application/components/CTLUnReadCell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";


const getAgent = AuthenticationService.allowAgentMenus();

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  enableExitButton: getAgent ? true : false,

  modes: getAgent
    ? agentReportMoreVert.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : callCenterConfigurationReports.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      }),
};

const operationURLS = {
  list: "/agentPerformanceSummary/dailyPerformanceReportList",
  pdfDownload: "/agentSummary/generateAndDownloadPdf",
  csvDownload: "/agentPerformanceSummary/dailyPerformanceReportDownloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Agent Id",
      accessor: "agentName",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "fullname",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      downloadReports:false,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Offered",
      accessor: "totalOffered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Dialed Out",
      accessor: "totalDialed",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Answered",
      accessor: "totalAnswered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "No Answer",
      accessor: "totalNoAnswered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Ring Time",
      accessor: "totalRingDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Talk Time",
      accessor: "totalTalkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Average Talk Time",
      accessor: "averageTalkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: " Total Idle Time",
      accessor: "totalIdleDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Break Time",
      accessor: "totalBreakDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Net Login Time",
      accessor: "totalLoginDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "ACW Time",
      accessor: "totalAcwDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Login Time",
      accessor: "netLoginTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Unservice Time",
      accessor: "unServiceDuration",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "10 Second",
      accessor: "second10",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "20 Second",
      accessor: "second20",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "30 Second",
      accessor: "second30",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "40 Second",
      accessor: "second40",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "50 Second",
      accessor: "second50",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "60 Second",
      accessor: "second60",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

  ];
};

export { getColumns, Attributes, operationURLS };
