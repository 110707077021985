import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import CTLDate from "application/components/CTLDate";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import CTLDownloadFile from "application/components/CTLDownloadFile";
import VoicePickerFileDownload from "application/components/voicePickerFileDownload";
import DownloadFile from "../../DownloadFile";
import DownloadSingleForBackup from "../DownloadSingleFile";
import PlaySingleFile from "../PlaySingleFile";

const Attributes = {
  enableDownloadPdf: true,
  formSize: "md",
  columns: 3,
  enableFileExportButton: true,
  enableMultipleDeleteButton: true,
  settingsMode: false,
  enableAdvanceSearch: true,
  enableRefresh: true,
  ButtonNames: "Backup With Delete File",
  ButtonName: "Backup-File",
  modes: [
    {
      id: "backupdownloadfile",
      name: "Backup Download File",
      url: "home/app/configurations/backup-download-file",
      description: "User Can Backup file download in this Page",
    },
  ],

  enableUpdateTableRecords: true,
  enableDownloadButton: true,
};

const operationURLS = {
  list: "/voiceLogger/list",
  create: "/voiceLogger/createTar",
  delete: "/voiceLogger/deleteFiles",
  csvDownload: "/voiceLogger/downloadCSV",
  pdfDownload: "/voiceLogger/generateAndDownloadPdf",
  status: "/pdfReport/checkBackupStatus/voiceLogger",
};

const getColumns = (onDelete) => {
  return [
    {
      Header: "Play",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      sortDisable: true,
      Cell: ({ row, value }) => <PlaySingleFile row={row} value={value} />,
    },
    {
      Header: "Download File",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ row, value }) => (
        <DownloadSingleForBackup row={row} value={value} />
      ),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      sortDisable: true,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onDelete={onDelete} />
      ),
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording Type",
      accessor: "coralApp",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Number",
      accessor: "callerIdNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Name",
      accessor: "callerName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Destination Number",
      accessor: "destinationNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Destination Name",
      accessor: "destName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording Path",
      accessor: "recordFilename",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Location",
      accessor: "location",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      dataType: "date",
      requiredFilter: true,
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File Time",
      accessor: "fileTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "billsec",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
