import CTLACDCell from "application/components/CTLACDCell";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { useState } from "react";
import formControl from "globals/form-control";
import { useMaterialUIController, setShowAgent } from "context";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  defaultValueToolTip: false,
  formSize: "md",
  columns: 3,
  enableAddButton: false,
  enableAddAgentButton: false,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
  messages: {
    success: "created successfully",
    error: "Unable to create",
  },
};

const operationURLS = {
  queueCreate: "/queue/create",
};

const getColumns = (onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { showAgent } = controller;

  const onChangeAgent = (event, formik) => {
    setShowAgent(dispatch, event.target.checked);
  };

  const [requireddidNumber, setRequireddidNumber] = useState("");
  const [requiredodNumber, setRequireddodNumber] = useState("");
  const checkselect = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "0") {
      setRequireddidNumber(formControl.string().required("Required"));
      setRequireddodNumber("");
    } else if (selectedValue === "1") {
      setRequireddidNumber("");
      setRequireddodNumber(formControl.string().required("Required"));
    } else if (selectedValue === "2") {
      setRequireddidNumber(formControl.string().required("Required"));
      setRequireddodNumber(formControl.string().required("Required"));
    } else {
      setRequireddidNumber("");
      setRequireddodNumber("");
    }
  };

  return [
    {
      Header: "ACD Type",
      accessor: "acdType",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      width: 6,
      Cell: ({ value }) => <CTLACDCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "InBound" },
          { id: "1", description: "OutBound" },
          { id: "2", description: "Blended" },
        ],
      },
      validation: formControl.string().required("Required").nullable(true),
      onChange: checkselect,
    },
    {
      Header: "ACD Number",
      accessor: "acdNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Shortcode",
      accessor: "shortCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DidNumber",
      accessor: "didNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      validation: requireddidNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DodNumber",
      accessor: "dodNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: requiredodNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },
    {
      Header: "CreateAgent",
      accessor: "createAgent",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangeAgent,
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      allowInForm: showAgent,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Agent To",
      accessor: "to",
      allowInForm: showAgent,
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "MOH Sound",
      accessor: "mohSound",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
