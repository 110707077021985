const mediaCenterMenus = [
  {
    icon: "display_settings_icon",
    key: "mediaCenter",
    name: "MediaCenter",
    url: "home/app/mediaCenter",
    description: "mediaCenter Configuration",
    disabled: false,
    productionReady: false,
  },
];

export { mediaCenterMenus };
