import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function AssignmentDialog({ 
  open, 
  onClose, 
  title, 
  options = [],
  currentFolder,
  onConfirm,
  getOptionLabel = (option) => option?.name || option?.username || "",
}) {
  const [selected, setSelected] = React.useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  React.useEffect(() => {
    if (!open) {
      setSelected(null);
    }
  }, [open]);

  const handleConfirm = () => {
    if (selected) {
      onConfirm(selected);
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}>
        <MDTypography variant="h6">{title}</MDTypography>
      </DialogTitle>
      <DialogContent sx={{ 
        backgroundColor: darkMode ? "#202940" : "#FFFFFF", 
        pt: 2,
        minWidth: '400px'
      }}>
        <Autocomplete
          options={options || []}
          getOptionLabel={getOptionLabel}
          onChange={(_, value) => setSelected(value)}
          value={selected}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              variant="outlined"
              fullWidth
              sx={{
                marginTop: 2,
                "& .MuiOutlinedInput-root": {
                  color: darkMode ? "#FFFFFF" : "#000000",
                  "& fieldset": {
                    borderColor: darkMode ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: darkMode ? "#FFFFFF" : "#000000",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: darkMode ? "#FFFFFF" : "#000000",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: darkMode ? "#FFFFFF" : "#000000",
                },
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}>
        <MDBox display="flex" gap={2}>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={handleConfirm}
            disabled={!selected}
          >
            Confirm
          </MDButton>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );
}

export default AssignmentDialog; 