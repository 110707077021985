import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  makeStyles,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useMaterialUIController } from "context";
import FormField from "layouts/applications/wizard/components/FormField";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import loggerFactory from "globals/logger/logger-factory";
import CTLAdvancedFilterShow from "../CTLAdvancedFilterShow";
import CTLSelect from "../CTLSelect";
import { setTaskStatus } from "context";

export default function CTLAdvancedFilter({
  applyFilter,
  onApplyFilter,
  onCloseFilter,
  entityName,
  columns,
  advancedFilters,
}) {
  const componentName = "Advance Filter";
  const [open, setOpen] = React.useState(false);
  const [controller,dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [color, setColor] = useState({});
  const [switchValues, setSwitchValues] = useState({});

  const initialValues = {
    from: {
      string: {},
      date: {},
      integer: {},
      long: {},
      boolean: {},
    },
    to: {
      string: {},
      date: {},
      integer: {},
      long: {},
      boolean: {},
    },
  };

  const validationSchema = yup.object();
  columns &&
    columns.forEach((column) => {
      if (column.allowFilter) {
        initialValues.from[`${column?.dataType}`][`${column.accessor}`] = "";
        initialValues.to[`${column?.dataType}`][`${column.accessor}`] = "";
      }
    });

  const filterValues = [];
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loggerFactory.debug(componentName, "Advance Filter Applied", values);

      for (const fromTo in values) {
        loggerFactory.debug(componentName, "From/To Value : ", fromTo);
        for (const dataType in values[fromTo]) {
          loggerFactory.debug(componentName, "Data Type Value : ", dataType);
          for (const field in values[fromTo][dataType]) {
            if (values[fromTo][dataType][field]) {
              const direction = fromTo; // Use direction here

              loggerFactory.debug(componentName, "Field : ", {
                field,
                value: values[fromTo][dataType][field],
              });

              filterValues.push({
                direction,
                dataType,
                fieldName: field,
                value: values[fromTo][dataType][field],
              });
            }
          }
        }
      }

      loggerFactory.debug(componentName, "Data to Filter", filterValues);

      onApplyFilter(filterValues, null, 2);
    },
  });

  useEffect(() => {
    setOpen(applyFilter);
  }, [applyFilter]);

  const getValues = (subset, dataType, fieldName) => {
    if (formik.values[subset] && formik.values[subset][dataType]) {
      return formik.values[subset][dataType][fieldName] || "";
    } else {
      return "";
    }
  };

  const setSwitchValue = (event, column) => {
    const { name, checked } = event.target;
    const [subset, dataType, fieldName] = name.split(".");

    let fromValue, toValue;

    if (checked && column.StringValue === true) {
      fromValue = true;
      toValue = true;
    } else if (!checked && column.StringValue === false) {
      fromValue = false;
      toValue = false;
    } else {
      fromValue = checked ? "1" : "0";
      toValue = checked ? "1" : "0";
    }

    formik.setFieldValue(`from.${dataType}.${fieldName}`, fromValue);
    formik.setFieldValue(`to.${dataType}.${fieldName}`, toValue);

    setColor((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    setSwitchValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // Get Date Month and Year
  const getCurrentDate = () => {
    const currentDate = new Date();
    const yyyy = currentDate.getFullYear();
    const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    const dd = String(currentDate.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // set ByDefault Date and Date&Time after Click on input Field
  const handleFocus = (e, column, subset) => {
    const { name, value } = e.target;
    if (!value && column.dataType === "date" && !column.dateTime) {
      const currentDate = getCurrentDate();
      formik.setFieldValue(name, currentDate);
    } else if (!value && column.dateTime) {
      const fromTime = `${getCurrentDate()}T00:01`;
      const toTime = `${getCurrentDate()}T23:59`;

      if (subset === "from") {
        formik.setFieldValue(name, fromTime);
      } else if (subset === "to") {
        formik.setFieldValue(name, toTime);
      }
    }
  };

  const handleChange = (e, column) => {
    const { name, value } = e.target;
    const [subset, dataType, fieldName] = name.split(".");
    formik.handleChange(e);
    if (column.dataType === "date" && column.dateTime) {
      if (subset === "from") {
        const toTime = `${value.split("T")[0]}T23:59`;
        formik.setFieldValue(`to.${dataType}.${fieldName}`, toTime);
      }
    } else {
      formik.setFieldValue(`to.${dataType}.${fieldName}`, value);
    }
  };

  function onClearFilter() {
    formik.resetForm();
    formik.handleSubmit();
    setSwitchValues({});
    setTaskStatus(dispatch,"")
  }

  const handleClear = (fieldName) => {
    formik.setFieldValue(fieldName, "")
};
  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseFilter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          {entityName} : Advanced Filter
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            {columns &&
              columns.map((column) => {
                if (column.allowFilter) {
                  return (
                    <Grid key={column.accessor} container spacing={1} mb={2}>
                      {column.filterControlType === "select" ? (
                        <>
                          <Grid item xs={6}>
                            <CTLSelect
                              label={`From ${column.Header}`}
                              name={`from.${column.dataType}.${column.accessor}`}
                              value={getValues(
                                "from",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              error={
                                formik.touched[
                                  `from.${column.dataType}.${column.accessor}`
                                ] &&
                                formik.errors[
                                  `from.${column.dataType}.${column.accessor}`
                                ]
                              }
                              success={
                                !formik.touched[
                                  `from.${column.dataType}.${column.accessor}`
                                ] ||
                                !formik.errors[
                                  `from.${column.dataType}.${column.accessor}`
                                ]
                              }
                              options={
                                column.options && column.options.values
                                  ? column.options.values
                                  : []
                              }
                              keyField={
                                column.options && column.options.keyField
                                  ? column.options.keyField
                                  : ""
                              }
                              keyDescription={
                                column.options && column.options.keyDescription
                                  ? column.options.keyDescription
                                  : ""
                              }
                              accessorKeyField={
                                column.options &&
                                column.options.accessorKeyField
                                  ? column.options.accessorKeyField
                                  : ""
                              }
                              accessorValueField={
                                column.options &&
                                column.options.accessorValueField
                                  ? column.options.accessorValueField
                                  : ""
                              }
                              url={
                                column.options && column.options.url
                                  ? column.options.url
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CTLSelect
                              label={`To ${column.Header}`}
                              name={`to.${column.dataType}.${column.accessor}`}
                              value={getValues(
                                "to",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              error={
                                formik.touched[
                                  `to.${column.dataType}.${column.accessor}`
                                ] &&
                                formik.errors[
                                  `to.${column.dataType}.${column.accessor}`
                                ]
                              }
                              success={
                                !formik.touched[
                                  `to.${column.dataType}.${column.accessor}`
                                ] ||
                                !formik.errors[
                                  `to.${column.dataType}.${column.accessor}`
                                ]
                              }
                              options={
                                column.options && column.options.values
                                  ? column.options.values
                                  : []
                              }
                              keyField={
                                column.options && column.options.keyField
                                  ? column.options.keyField
                                  : ""
                              }
                              keyDescription={
                                column.options && column.options.keyDescription
                                  ? column.options.keyDescription
                                  : ""
                              }
                              accessorKeyField={
                                column.options &&
                                column.options.accessorKeyField
                                  ? column.options.accessorKeyField
                                  : ""
                              }
                              accessorValueField={
                                column.options &&
                                column.options.accessorValueField
                                  ? column.options.accessorValueField
                                  : ""
                              }
                              url={
                                column.options && column.options.url
                                  ? column.options.url
                                  : ""
                              }
                            />
                          </Grid>
                        </>
                      ) : column.filterControlType === "boolean" ? (
                        <>
                          <Stack spacing={20} direction="row">
                            {/* <Grid style={{ display: 'flex', alignItems: 'center' }}> */}
                            <label
                              style={{ marginRight: "10px", width: "150px" }}
                            >
                              {column.Header}
                            </label>
                            <Switch
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id={`to.${column.dataType}.${column.accessor}`}
                              name={`to.${column.dataType}.${column.accessor}`}
                              label={column.Header}
                              checked={
                                switchValues[
                                  `to.${column.dataType}.${column.accessor}`
                                ] || false
                              } // Set switch value based on state
                              value={getValues(
                                column.dataType,
                                column.accessor
                              )}
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor:
                                    color &&
                                    switchValues[
                                      `to.${column.dataType}.${column.accessor}`
                                    ]
                                      ? "green"
                                      : "red",
                                },
                              }}
                              onChange={(e) => setSwitchValue(e, column)}
                            />

                            {/* </Grid> */}
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <FormField
                              required={column.requiredFilter}
                              id={`from.${column.dataType}.${column.accessor}`}
                              name={`from.${column.dataType}.${column.accessor}`}
                              label={`From ${column.Header}`}
                              value={getValues(
                                "from",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              onFocus={(e) => handleFocus(e, column, "from")}
                              type={
                                column.dataType === "string"
                                  ? "text"
                                  : column.dateTime
                                  ? "datetime-local"
                                  : "date"
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: getValues(
                                  "from",
                                  column.dataType,
                                  column.accessor
                                ) ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleClear(`from.${column.dataType}.${column.accessor}`)
                                      }
                                      edge="end"
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormField
                              required={column.requiredFilter}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id={`to.${column.dataType}.${column.accessor}`}
                              name={`to.${column.dataType}.${column.accessor}`}
                              label={`To ${column.Header}`}
                              value={getValues(
                                "to",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={formik.handleChange}
                              onFocus={(e) => handleFocus(e, column, "to")}
                              type={
                                column.dataType === "string"
                                  ? "text"
                                  : column.dateTime
                                  ? "datetime-local"
                                  : "date"
                              }
                              InputProps={{
                                endAdornment: getValues(
                                  "to",
                                  column.dataType,
                                  column.accessor
                                ) ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleClear(`to.${column.dataType}.${column.accessor}`)
                                      }
                                      edge="end"
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  );
                }
              })}
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={onClearFilter} color="error">
              Clear
            </MDButton>
            <MDButton onClick={onCloseFilter} color="secondary">
              Cancel
            </MDButton>
            <MDButton color="success" autoFocus type="submit">
              Apply
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>

      <CTLAdvancedFilterShow
        advancedFilters={advancedFilters}
        onClearFilter={onClearFilter}
        columns={columns}
      />
    </>
  );
}
