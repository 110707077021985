import { useState, useEffect } from "react";
import { useGetNotificationDataQuery } from "features/apiSlice";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useGetDataQuery } from "features/apiSlice";

export default function useNotification(isNotificationRightAligned) {
  const [notificationKeys, setNotificationKeys] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const severityListQuery = useGetDataQuery(
    process.env.REACT_APP_SEVERITY_LIST_ENDPOINT,
  );

  const notificationDataQuery = useGetNotificationDataQuery("");

  useEffect(() => {
    if (
      severityListQuery.data &&
      notificationDataQuery.data &&
      notificationDataQuery.data.length > 0
    ) {
      const { nodeName, status, serviceCode } = notificationDataQuery.data[0];
      const severityList = severityListQuery.data;

      let variant = null;
      let variantColor = null;
      let textColor = null;
      let notificationMessage = null;
      let isPersistableNotification = false;
      if (typeof status === "string") {
        const transformedStatus = status.toUpperCase();

        isPersistableNotification = transformedStatus !== "OK";

        const severityObj = severityList.find(
          (severity) =>
            severity.severityName.toUpperCase() === transformedStatus,
        );

        const textObj = severityList.find(
          (severity) => severity.severityName.toUpperCase() === "TEXT",
        );

        textColor = textObj ? textObj.color : "#FFF";

        if (severityObj) {
          variant = severityObj.severityName;
          variantColor = severityObj.color;
        } else {
          variant = "default";
          variantColor = "#000";
        }

        notificationMessage = `${serviceCode}-${nodeName} is in status ${transformedStatus}`;
      } else if (typeof status === "boolean") {
        isPersistableNotification = !status;
        variant = status ? "success" : "error";
        notificationMessage = `${serviceCode}-${nodeName} is ${
          status ? "up" : "down"
        }`;
      }

      if (
        notificationKeys.length ===
        parseInt(process.env.REACT_APP_NOTIFICATION_MAX_SNACK)
      )
        closeSnackbar(notificationKeys[0]);

      enqueueSnackbar(notificationMessage, {
        variant,
        style: {
          color: textColor,
          backgroundColor: variantColor,
        },
        persist: isPersistableNotification,
        autoHideDuration: process.env.REACT_APP_NOTIFICATION_AUTO_HIDE_DURATION,
        anchorOrigin: {
          horizontal: isNotificationRightAligned ? "right" : "left",
          vertical: "bottom",
        },
        onEntered: (node, isAppearing, key) => {
          if (!notificationKeys.includes(key))
            setNotificationKeys((prev) => [...prev, key]);
        },
        onExited: (node, key) => {
          setNotificationKeys((prev) => prev.filter((k) => k !== key));
        },
        action: (key) => {
          return (
            <Button
              sx={{ color: textColor }}
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              Dismiss
            </Button>
          );
        },
      });
    }
  }, [notificationDataQuery.data, severityListQuery.data]);
}
