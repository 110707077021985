const CMSReportMenu = [
  {
    icon: "all_inclusive",
    key: "csm_reports",
    name: "All Complaints",
    url: "home/app/cms-reports/all-complaint ",
    description: "All Complaint ",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "fiber_new_icon",
    key: "newComplaint",
    name: "New Complaint",
    url: "home/app/cms-reports/new-complaint",
    description: "New Complaint",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "pending_actions",
    key: "PendingComplaintEngineer",
    name: "Pending Complaints with Engineer",
    url: "home/app/cms-reports/pending-complaint-with-engineer",
    description: "Pending Complaint with Engineer",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "timer",
    key: "pendingComplaintApproval",
    name: "Pending Complaints for approval ",
    url: "home/app/cms-reports/pending-complaints-for-approval",
    description: "Pending Complaints for approval ",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "close_icon",
    key: "ClosedComplaints",
    name: "Closed Complaints ",
    url: "home/app/cms-reports/closed-complaints",
    description: "Closed Complaints ",
    disabled: false,
    productionReady: true,
  },
];

export { CMSReportMenu };
