import React, { useState, useEffect, useRef } from "react";
import SIPPhoneService from "application/components/SIPPhone/SIPPhoneService";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { getColumns, Attributes, operationURLS } from "./model";
import PhoneIcon from "@mui/icons-material/Phone";
import PanToolIcon from "@mui/icons-material/PanTool";
import PlayDistorsion from "../playDistorsion";

import NotificationManager from "globals/websocket/WebSocket";

import {
  ButtonGroup,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Icon,
  IconButton,
  Tooltip,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  ListItemText,
  Avatar,
  TextField,
  Typography,
  Badge,
} from "@mui/material";
import ring from "assets/sound/ringing.wav";
import ringBack from "assets/sound/ringback_tone.mp3";
import {
  useMaterialUIController,
  setCallState,
  setConferenceData,
  setActionButton,
} from "context";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";

import {
  Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  RegistererState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions,
  Web,
  InvitationRejectOptions,
  LogLevel,
} from "sip.js";
import MDTypography from "components/MDTypography";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLNotification from "application/components/CTLNotification";
import CTLBaseDialogForm from "../CTLBaseDialogForm";
import { TouchApp } from "@material-ui/icons";
import { mcx } from "globals/endpoints";
import { setDialBroadcast } from "context";
import { axiosInstance } from "../CTLAxiosInstance";

function CTLConferencePhone({
  phoneType,
  setPhoneType,
  conferenceType,
  setConferenceType,
}) {
  const componentName = "SIPPhone";
  const [controller, dispatch] = useMaterialUIController();
  const { callState, conferenceData, dialBroadcast, actionButton } = controller;
  const [registration, setRegistration] = useState("Not Registered");
  const [inCall, setInCall] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [isDialing, setIsDialing] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const [dialNumber, setDialNumber] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [callFunction, setCallFunction] = useState(false);
  const [phoneDisplay, setPhoneDisplay] = useState("block");
  const [raiseHand, setRaiseHand] = useState("info");
  const [timer, setTimer] = useState("0:0:0");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [activeStopDistortion, setActiveStopDistortion] = useState(false);
  const [pttStatus, setPttStatus] = useState("PTT Available");
  const [allSessions, setAllSessions] = useState([]);
  const timeoutRef = useRef(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const [openDistorsion, setOpenDistorsion] = useState(false);

  function HandleOpenDistorsion() {
    setOpenDistorsion(true);
  }

  function HandleStopDistorsion() {
    application
      .post(`distortionFile/stopDistortion/${conferenceData.accessCode}`)
      .then((response) => {
        setActiveStopDistortion(false);
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        loggerFactory.debug("Unable to stop announcement {}", error);
      });
  }

  function HandleCloseDistorsion() {
    setOpenDistorsion(false);
  }

  let talkStart = null;
  let timerThread = null;

  const updateCallState = (callInfo) => {
    loggerFactory.debug(componentName, "Dispatching call state : {}", callInfo);
    setCallState(dispatch, callInfo);
  };

  const audioRinging = new Audio(ring);
  audioRinging.loop = true;

  const ringBackTone = new Audio(ringBack);
  ringBackTone.loop = true;

  useEffect(() => {
    if (dialBroadcast) {
      if (!inCall) {
        handleBroadcastCall(dialBroadcast, actionButton);
      } else {
        if (SIPPhoneService.inviteSession) {
          SIPPhoneService.inviteSession.bye();
        }

        if (allSessions && allSessions.length > 0) {
          allSessions.forEach((session) => {
            if (session.state !== SessionState.Terminated) {
              session.bye();
            }
          });
        }
        setAllSessions([]);
        handleBroadcastCall(dialBroadcast, actionButton);
      }
    }

    NotificationManager.receiveMessageForMCX(
      `user-${AuthenticationService.getUserName()}`,
      mcxUpdate,
      "mcx-events"
    );
  }, [dialBroadcast]);

  useEffect(() => {
    if (conferenceData) {
      if (conferenceData.blockedStatus == 1) {
        setNotify({
          isOpen: true,
          message: "",
          type: "warning",
          pagename: "Conference",
          status:
            "Sorry, you are not authorized to join this conference. Access is restricted",
        });
      } else {
        if (!inCall) {
          if (AuthenticationService.allowUCConferenceMenus()) {
            const server = conferenceData.serverName;
            mcx.defaults.baseURL = server
              ? window.location.protocol + "//" + `${server}` + "/mcx/app/v1/"
              : variables.api.mcx;
            NotificationManager.connectMCX(server);
          }
          dial(conferenceType, conferenceData.didMapping);
        } else {
          if (SIPPhoneService.inviteSession) {
            SIPPhoneService.inviteSession.bye();
          }

          if (allSessions && allSessions.length > 0) {
            allSessions.forEach((session) => {
              if (session.state !== SessionState.Terminated) {
                session.bye();
              }
            });
          }
          setAllSessions([]);
          if (AuthenticationService.allowUCConferenceMenus()) {
            const server = conferenceData.serverName;
            mcx.defaults.baseURL = server
              ? window.location.protocol + "//" + `${server}` + "/mcx/app/v1/"
              : variables.api.mcx;
            NotificationManager.connectMCX(server);
          }
          dial(conferenceType, conferenceData.didMapping);
        }
      }
    }

    NotificationManager.receiveMessageForMCX(
      `user-${AuthenticationService.getUserName()}`,
      mcxUpdate,
      "mcx-events"
    );
  }, [conferenceData]);

  const mcxUpdate = (message) => {
    if (message) {
      const mcxEvent = JSON.parse(message.body);
      if (mcxEvent.subType == 2 && mcxEvent.appName == "MCPC") {
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: "PTT enable successfully ",
        });
      } else if (mcxEvent.subType == 1 && mcxEvent.appName == "MCPT") {
        unMute();
        setPttStatus(`PTT Granted - You`);
      } else if (mcxEvent.subType == 10 && mcxEvent.appName == "MCPT") {
        mute();
        setPttStatus("PTT Available");
      } else if (mcxEvent.subType == 2 && mcxEvent.appName == "MCPT") {
        const username = mcxEvent.userIdStrData.split("@")[0];
        setPttStatus(`PTT Granted - ${username}`);
      } else if (mcxEvent.subType == 5 && mcxEvent.appName == "MCPT") {
        setPttStatus("PTT Available");
      }

      // else if (mcxEvent.subType == 3 && mcxEvent.appName == "MCPT") {
      //   const username = mcxEvent.userIdStrData.split("@")[0];
      //   if (username == AuthenticationService.getUserName()) {
      //     setPttStatus(`PTT is already taken by -${username}`);
      //   } else {
      //     setNotify({
      //       isOpen: true,
      //       message: "",
      //       type: "info",
      //       pagename: "Conference",
      //       status: "Please Re-join conference. PTT Not Connected!",
      //     });
      //   }
      // }
    }
  };

  function handleChange(event) {
    setDialNumber(event.target.value);
  }

  function onInvite(invitation) {
    openForIncomingCall(invitation);
  }

  function callIsRinging(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.play();
    callInfo.isRinging = true;
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsDialing(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    callInfo.isDialing = true;
    callInfo.dialNumber = "";
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsConnected(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    startTimer();

    audioRinging.pause();
    callInfo.inCall = true;
    callInfo.isMute = true;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = callStatus;
    callInfo.dialNumber = "";
    updateCallState(callInfo);
  }

  function callIsTerminated() {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.pause();

    callInfo.inCall = false;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = "";
    callInfo.dialNumber = "";

    stopTimer();

    updateCallState(callInfo);
  }

  function openForIncomingCall(invitation) {
    const messageFrom = invitation.incomingInviteRequest.message.from;

    callIsRinging("Incoming call : " + messageFrom.uri.normal.user);
    SIPPhoneService.inviteSession = invitation;

    invitation.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          callIsRinging();
          break;
        case SessionState.Establishing:
          callIsRinging();
          break;
        case SessionState.Established:
          const messageTalk =
            SIPPhoneService.inviteSession.incomingInviteRequest.message.from;

          callIsConnected("Talking : " + messageTalk.uri.normal.user);

          const remoteAudioStream = new MediaStream();
          const remoteVideoStream = new MediaStream();
          const localVideoStream = new MediaStream();

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getSenders()
            .forEach((sender) => {
              if (sender.track) {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              }
            });

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                if (receiver.track.kind === "audio") {
                  remoteAudioStream.addTrack(receiver.track);
                }
                if (receiver.track.kind === "video") {
                  remoteVideoStream.addTrack(receiver.track);
                }
              }
            });
          SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.play();

          SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
          SIPPhoneService.remoteVideoMedia.play();
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          callIsTerminated();
          SIPPhoneService.remoteAudioMedia.srcObject = null;
          SIPPhoneService.remoteAudioMedia.pause();

          SIPPhoneService.remoteVideoMedia.srcObject = null;
          SIPPhoneService.remoteVideoMedia.pause();

          SIPPhoneService.localVideoMedia.srcObject = null;
          SIPPhoneService.localVideoMedia.pause();

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }

  function updateTimer() {
    const total = Date.parse(new Date()) - Date.parse(talkStart);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setTimer(`${hours}:${minutes}:${seconds}`);
  }

  function startTimer() {
    talkStart = new Date();
    timerThread = setInterval(() => {
      updateTimer();
    }, 1000);
  }

  function stopTimer() {
    talkStart = null;

    if (timerThread) {
      clearInterval(timerThread);
      timerThread = null;
    }
  }

  function answerCall(asVideoCall) {
    SIPPhoneService.inviteSession?.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: asVideoCall,
        },
      },
    });
  }

  function updateRegistrationStatus(status) {
    setRegistration(status);
  }

  function bye() {
    setConferenceData(dispatch, "");
    setActionButton(dispatch, "");
    setPhoneType("");
    setActiveUser("");
    setConferenceType(false);
    setCallFunction(false);
    setActiveStopDistortion(false);
    setDialBroadcast(dispatch, []);

    if (SIPPhoneService.inviteSession) {
      SIPPhoneService.inviteSession.bye();
    }

    if (allSessions && allSessions.length > 0) {
      allSessions.forEach((session) => {
        if (session.state !== SessionState.Terminated) {
          session.bye();
        }
      });
    }
    setAllSessions([]);
  }

  function cancel() {
    setPhoneType("");
    setConferenceType(false);
    setConferenceData(dispatch, "");
    setActiveUser("");
    setCallFunction(false);
    setActiveStopDistortion(false);
    SIPPhoneService.inviteSession.cancel();
  }

  const handleMouseDown = () => {
    timeoutRef.current = setTimeout(() => {
      setIsMouseDown(true);
      handlePttRequest(isMute);
    }, 300);
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutRef.current);
    setIsMouseDown(false);
    if (isMouseDown) {
      handlePttRequest(isMute);
    }
  };

  async function handlePttRequest(isMute) {
    try {
      const url = isMute
        ? `floor/request/${
            conferenceData.accessCode
          }/${AuthenticationService.getUserName()}`
        : `floor/release/${
            conferenceData.accessCode
          }/${AuthenticationService.getUserName()}`;

      const response = await mcx.post(url);

      if (isMute) {
        loggerFactory.info("successfully request ptt");
      } else {
        loggerFactory.info("successfully release ptt");
      }
    } catch (error) {
      if (isMute) {
        loggerFactory.debug("unable to request ptt", error);
      } else {
        loggerFactory.debug("unable to release ptt", error);
      }
    }
  }

  function mute() {
    if (
      SIPPhoneService.currentSessionAudio &&
      SIPPhoneService.currentSessionAudio.track
    ) {
      SIPPhoneService.currentSessionAudio.track.enabled = false;
    }
    if (SIPPhoneService.broadcastAudioSenders) {
      SIPPhoneService.broadcastAudioSenders.forEach((sender) => {
        if (sender.track) {
          sender.track.enabled = false;
        }
      });
    }
    setIsMute(true);
  }

  function unMute() {
    if (
      SIPPhoneService.currentSessionAudio &&
      SIPPhoneService.currentSessionAudio.track
    ) {
      SIPPhoneService.currentSessionAudio.track.enabled = true;
    }
    if (SIPPhoneService.broadcastAudioSenders) {
      SIPPhoneService.broadcastAudioSenders.forEach((sender) => {
        if (sender.track) {
          sender.track.enabled = true;
        }
      });
    }
    setIsMute(false);
  }

  function keyClicked(key) {
    setDialNumber(dialNumber + key);
  }

  const joinPtt = () => {
    mcx
      .post(
        `floor/join/${
          conferenceData.accessCode
        }/${AuthenticationService.getUserName()}/${
          phoneType == "moderator" ? "M" : "A"
        }`
      )
      .then((response) => {
        loggerFactory.info("Successfully join PTT.");
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: "PTT Connected successfully",
        });
      })
      .catch((error) => {
        loggerFactory.debug("Failed to join PTT. Please refresh the page.");
      });
  };

  function dial(videoCall, number) {
    let toDial = number;

    if (toDial) {
      SIPPhoneService.lastNumberDialed = toDial;

      const number = "sip:" + toDial + "@" + variables.sip.domain;
      const target = UserAgent.makeURI(number);
      setDialNumber("");

      SIPPhoneService.inviteSession = new Inviter(
        SIPPhoneService.userAgent,
        target
      );
      const outgoingRequestMessage =
        SIPPhoneService.inviteSession.outgoingRequestMessage;

      outgoingRequestMessage.setHeader(
        "X-Conferencecall",
        conferenceData.accessCode
      );
      SIPPhoneService.inviteSession
        .invite({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: videoCall,
            },
          },
        })
        .catch((error) => {
          if (error.name === "NotFoundError") {
            setNotify({
              isOpen: true,
              message: "",
              type: "info",
              pagename: "Conference",
              status:
                "Microphone or speaker not found. Please check your device settings.",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "",
              type: "error",
              pagename: "Conference",
              status: "An error occurred while making the call.",
            });
          }
        });

      SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            if (toDial === SIPPhoneService.swipeCode) {
              callIsDialing("Call Swipe Initiated");
            } else {
              callIsDialing("Dialing : " + toDial);
            }
            break;

          case SessionState.Established:
            ringBackTone.pause();
            setCallFunction(true);
            if (AuthenticationService.allowUCConferenceMenus()) {
              joinPtt();
            }
            if (toDial === SIPPhoneService.swipeCode) {
              callIsConnected("Call Swiped");
            } else {
              callIsConnected(`Talking : ${toDial}`);
            }
            const remoteAudioStream = new MediaStream();
            const remoteVideoStream = new MediaStream();
            const localVideoStream = new MediaStream();

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getSenders()
              .forEach((sender) => {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);
                }

                SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                SIPPhoneService.localVideoMedia.play();
              });
            SIPPhoneService.currentSessionAudio.track.enabled = false;
            setIsMute(true);
            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getReceivers()
              .forEach((receiver) => {
                if (receiver.track) {
                  if (receiver.track.kind === "audio") {
                    remoteAudioStream.addTrack(receiver.track);
                  }
                  if (receiver.track.kind === "video") {
                    remoteVideoStream.addTrack(receiver.track);
                  }
                }
              });
            SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
            SIPPhoneService.remoteAudioMedia.play();

            SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
            SIPPhoneService.remoteVideoMedia.play();
            break;
          case SessionState.Terminated:
            ringBackTone.pause();
            callIsTerminated();
            setPttStatus("");
            SIPPhoneService.remoteAudioMedia.srcObject = null;
            SIPPhoneService.remoteAudioMedia.pause();

            SIPPhoneService.remoteVideoMedia.srcObject = null;
            SIPPhoneService.remoteVideoMedia.pause();

            SIPPhoneService.localVideoMedia.srcObject = null;
            SIPPhoneService.localVideoMedia.pause();

            break;
          default:
            break;
        }
      });
    }
  }

  function handleBroadcastCall(dialBroadcast, actionButton) {
    let callConnected = false;
    const sessions = [];
    dialBroadcast.forEach((item) => {
      let toDial = item.conferenceNumber;
      loggerFactory.info("conference number dial to ", item.conferenceNumber);
      if (toDial) {
        SIPPhoneService.lastNumberDialed = toDial;
        const number = "sip:" + toDial + "@" + variables.sip.domain;
        const target = UserAgent.makeURI(number);

        const inviteSession = new Inviter(SIPPhoneService.userAgent, target);
        const outgoingRequestMessage = inviteSession.outgoingRequestMessage;
        outgoingRequestMessage.setHeader("X-Conferencecall", item.accessCode);

        inviteSession
          .invite({
            sessionDescriptionHandlerOptions: {
              constraints: {
                audio: true,
                video: false,
              },
            },
          })
          .catch((error) => {
            if (error.name === "NotFoundError") {
              setNotify({
                isOpen: true,
                message: "",
                type: "info",
                pagename: "Conference",
                status:
                  "Microphone or speaker not found. Please check your device settings.",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "",
                type: "error",
                pagename: "Conference",
                status: "An error occurred while making the call.",
              });
            }
          });

        loggerFactory.debug(
          "add inviteSession of number ",
          toDial,
          inviteSession
        );
        setAllSessions((prevSessions) => [...prevSessions, inviteSession]);
        sessions.push(inviteSession);

        inviteSession.stateChange.addListener((newState) => {
          switch (newState) {
            case SessionState.Establishing:
              callIsDialing(`Dialing ${actionButton}`);
              break;

            case SessionState.Established:
              ringBackTone.pause();
              joinPtt();
              if (!callConnected) {
                callIsConnected(`Talking ${actionButton}`);
                callConnected = true;
              }
              const remoteAudioStream = new MediaStream();
              const remoteVideoStream = new MediaStream();
              const localVideoStream = new MediaStream();

              inviteSession.sessionDescriptionHandler.peerConnection
                .getSenders()
                .forEach((sender) => {
                  if (sender.track.kind === "audio") {
                    if (!SIPPhoneService.broadcastAudioSenders) {
                      SIPPhoneService.broadcastAudioSenders = [];
                    }
                    SIPPhoneService.broadcastAudioSenders.push(sender);
                    sender.track.enabled = false;
                  }
                  if (sender.track.kind === "video") {
                    localVideoStream.addTrack(sender.track);
                  }

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                });
              setIsMute(true);
              inviteSession.sessionDescriptionHandler.peerConnection
                .getReceivers()
                .forEach((receiver) => {
                  if (receiver.track) {
                    if (receiver.track.kind === "audio") {
                      remoteAudioStream.addTrack(receiver.track);
                    }
                    if (receiver.track.kind === "video") {
                      remoteVideoStream.addTrack(receiver.track);
                    }
                  }
                });
              SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.pause();

              SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
              SIPPhoneService.remoteVideoMedia.pause();
              break;

            case SessionState.Terminated:
              ringBackTone.pause();
              callIsTerminated();
              setPttStatus("");
              SIPPhoneService.remoteAudioMedia.srcObject = null;
              SIPPhoneService.remoteAudioMedia.pause();

              SIPPhoneService.remoteVideoMedia.srcObject = null;
              SIPPhoneService.remoteVideoMedia.pause();

              SIPPhoneService.localVideoMedia.srcObject = null;
              SIPPhoneService.localVideoMedia.pause();

              inviteSession.stateChange.removeAllListeners();
              sessions.splice(sessions.indexOf(inviteSession), 1);
              break;

            default:
              break;
          }
        });
      }
    });
  }

  useEffect(() => {
    if (!SIPPhoneService.callbacks.invite) {
      SIPPhoneService.callbacks.invite = onInvite;
      SIPPhoneService.callbacks.registration = updateRegistrationStatus;
      SIPPhoneService.remoteAudioMedia = document.getElementById("remoteAudio");
      SIPPhoneService.remoteVideoMedia = document.getElementById("remoteVideo");
      SIPPhoneService.localVideoMedia = document.getElementById("localVideo");
    }

    setRegistration(SIPPhoneService.registeredState);
    loggerFactory.debug(componentName, "State Update", callState);
    if (callState) {
      setCallStatus(callState.callStatus);
      setDialNumber(callState.dialNumber);
      setInCall(callState.inCall);
      setIsDialing(callState.isDialing);
      setIsMute(callState.isMute);
      setIsOnHold(callState.isOnHold);
      setIsRinging(callState.isRinging);
    }
  }, [callState]);

  useEffect(() => {
    SIPPhoneService.callbacks.registration = updateRegistrationStatus;
  }, [SIPPhoneService]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (callFunction) {
        fetchData(conferenceData);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [callFunction]);

  const [state, setState] = useState({ right: false });
  const [participantList, setParticipantList] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (participantList) {
      const filtered = participantList.filter(
        (item) =>
          item.participantName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.extensions.includes(searchQuery)
      );
      setFilteredList(filtered);
    }
  }, [participantList, searchQuery, activeUser]);

  useEffect(() => {
    fetchData(conferenceData);
  }, [conferenceData]);

  function fetchData(data) {
    if (data) {
      if (window.conferenceMode) {
        let url = window.location.protocol + "//" + `${data.serverName}`;

        axiosInstance
          .post(
            `${url}/services/api/v2/conferenceParticipant/list/${data.modId}`
          )
          .then((response) => {
            setParticipantList(response.data.data);
          })
          .catch((error) => {
            setParticipantList([]);
            loggerFactory.debug("Unable to get ParticipantList :{} ", error);
          });

        axiosInstance
          .post(
            `${url}/services/api/v2/conferenceParticipant/confCallDetail/${data.accessCode}`
          )
          .then((response) => {
            setActiveUser(response.data.data);
          })
          .catch((error) => {
            setActiveUser();
            loggerFactory.debug("Unable to get active Users :{}", error);
          });
      } else {
        application
          .post(`conferenceParticipant/list/${data.modId}`)
          .then((response) => {
            setParticipantList(response.data.data);
          })
          .catch((error) => {
            setParticipantList([]);
            loggerFactory.debug("Unable to get ParticipantList :{} ", error);
          });

        application
          .post(`conferenceParticipant/confCallDetail/${data.accessCode}`)
          .then((response) => {
            setActiveUser(response.data.data);
          })
          .catch((error) => {
            setActiveUser();
            loggerFactory.debug("Unable to get active Users :{}", error);
          });
      }
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    fetchData(conferenceData);
    setState({ ...state, ["left"]: open });
  };

  useEffect(() => {
    if (activeUser) {
      const number = SIPPhoneService.userAgentOptions.authorizationUsername;
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === number);
      if (matchedContact && matchedContact.isHandRaised == 0) {
        setRaiseHand("info");
      } else {
        setRaiseHand;
        ("success");
      }
    }
  }, [activeUser]);

  const handleRaiseHand = () => {
    const number = SIPPhoneService.userAgentOptions.authorizationUsername;
    const matchedContact = activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      setRaiseHand("success");
      application
        .post(`conferenceParticipant/updateHandRaise/${matchedContact.uuid}`)
        .then((response) => {
          setRaiseHand("success");
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          loggerFactory.debug("Unable to update handRaise :{} ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to get ${SIPPhoneService.userAgentOptions.authorizationUsername} data for hand raise update `,
      });
    }
  };

  const handleUnMute = (number) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/unmute/${conferenceData.accessCode}/${matchedContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          loggerFactory.debug("Unable to UnMute participant :{} ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to UnMute participant no match user found ! `,
      });
    }
  };

  const handleMute = (number) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/mute/${conferenceData.accessCode}/${matchedContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          loggerFactory.debug("Unable to Mute participant ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to Mute participant no match user found !`,
      });
    }
  };

  const handleMuteAll = () => {
    application
      .post(`conferenceParticipant/mute/all/${conferenceData.accessCode}`)
      .then((response) => {
        fetchData(conferenceData);
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        loggerFactory.debug("Unable to Mute participant :{} ", error);
      });
  };

  const handleJoinParticipant = (user) => {
    application
      .post(`conferenceParticipant/dial/${user.extensions}/${user.accessCode}`)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  };

  const handleAddParticipant = () => {
    setOpenForm(true);
    setFormData({
      eventTime: Date.now(),
    });
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function onSaveForm(data) {
    const dataToSave = {
      configureConferrerId: conferenceData.modId,
      memberType: "O",
      ...data,
    };
    application
      .post(operationURLS.create, dataToSave)
      .then((response) => {
        fetchData(conferenceData);
        setOpenForm(false);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  }

  const getColorForContact = (contactNumber) => {
    if (activeUser) {
      const activeContacts = activeUser.map((user) => user.contact);
      if (activeContacts.includes(contactNumber)) {
        return "success";
      }
    }
    return "error";
  };

  const getHandRaise = (contactNumber) => {
    if (activeUser) {
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === contactNumber);
      if (matchedContact) {
        return matchedContact.isHandRaised;
      } else {
        return 0;
      }
    }
  };

  const getMuteStatus = (contactNumber) => {
    if (activeUser) {
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === contactNumber);
      if (matchedContact && matchedContact.canSpeak == "false") {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleAccessRaiseHand = (contactNumber) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === contactNumber);
    const adminContact =
      activeUser &&
      activeUser.find(
        (item) =>
          item.contact ===
          SIPPhoneService.userAgentOptions.authorizationUsername
      );
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/unMuteRaisedUser/${conferenceData.didMapping}/${conferenceData.accessCode}/${matchedContact.id}/${adminContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          loggerFactory.info(
            "successfully unMuteRaiseUser :{} ",
            contactNumber
          );
        })
        .catch((error) => {
          loggerFactory.debug("Unable to unMuteRaiseUser :{} ", error);
        });
    }
  };

  const handleBard = (contactNumber) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === contactNumber);
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/${conferenceData.accessCode}/kick/${matchedContact.id}/${matchedContact.contact}`
        )
        .then((response) => {
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: "Conference",
            status: response.data.messageDetail,
          });
          loggerFactory.info("successfully kick out user ", contactNumber);
        })
        .catch((error) => {
          loggerFactory.debug("Unable to kick out ", error);
        });
    }
  };

  const handleUnblockParticipant = (contactNumber) => {
    application
      .post(
        `conferenceParticipant/unblock/${conferenceData.accessCode}/${contactNumber}`
      )
      .then((response) => {
        fetchData(conferenceData);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
        loggerFactory.info("Participant unblock successfully", contactNumber);
      })
      .catch((error) => {
        loggerFactory.debug(
          "Unable to unblock participant ",
          contactNumber,
          error
        );
      });
  };

  const list = (anchor) => (
    <MDBox
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ padding: "10px" }}
      sx={{ width: 400, overflow: "hidden" }}
    >
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <MDTypography variant="h6">Participant List</MDTypography>
        {AuthenticationService.allowUCMenus() && phoneType == "moderator" && (
          <MDButton
            variant="outlined"
            color="info"
            iconOnly
            onClick={() => handleAddParticipant()}
          >
            <Tooltip title="Add participant">
              <Icon color="info" sx={{ fontWeight: "bold" }}>
                add
              </Icon>
            </Tooltip>
          </MDButton>
        )}
      </MDBox>

      <Divider />
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchInputChange}
        autoComplete="off"
      />
      {phoneType == "moderator" && (
        <>
          <Divider />
          <MDButton fullWidth color="error" onClick={() => handleMuteAll()}>
            <Icon color="text">mic_off</Icon> &nbsp; Mute All Participant
          </MDButton>
        </>
      )}
      <Divider />
      <List fullWidth>
        {filteredList && filteredList.length === 0 ? (
          <MDTypography variant="h6" style={{ textAlign: "center" }}>
            No participant found
          </MDTypography>
        ) : (
          filteredList &&
          filteredList.map((item, index) => (
            <>
              <ListItem
                key={item.participantId}
                disablePadding
                sx={{ cursor: "auto" }}
              >
                <ListItemIcon>
                  {AuthenticationService.allowUCMenus() && (
                    <IconButton
                      color="info"
                      onClick={() => handleJoinParticipant(item)}
                    >
                      <PhoneIcon />
                    </IconButton>
                  )}
                </ListItemIcon>
                <Icon
                  style={{
                    marginBottom: "-4px",
                    marginRight: "10px",
                  }}
                  color={getColorForContact(item.extensions)}
                >
                  circle
                </Icon>{" "}
                {phoneType == "moderator" &&
                  conferenceData?.rptt &&
                  getColorForContact(item.extensions) == "success" &&
                  getHandRaise(item.extensions) == 1 && (
                    <IconButton
                      onClick={() => handleAccessRaiseHand(item.extensions)}
                    >
                      <Badge
                        badgeContent={getHandRaise(item.extensions)}
                        color="error"
                      >
                        <Icon color="warning"> pan_tool_icon</Icon>{" "}
                      </Badge>
                    </IconButton>
                  )}
                {phoneType == "participant" &&
                  conferenceData?.rptt &&
                  getColorForContact(item.extensions) == "success" &&
                  getHandRaise(item.extensions) == 1 && (
                    <>
                      <Badge
                        badgeContent={getHandRaise(item.extensions)}
                        color="error"
                      >
                        <Icon color="warning"> pan_tool_icon</Icon>
                      </Badge>
                    </>
                  )}
                {phoneType == "moderator" &&
                  getColorForContact(item.extensions) == "success" && (
                    <>
                      {getMuteStatus(item.extensions) ? (
                        <IconButton
                          variant="outlined"
                          onClick={() => handleMute(item.extensions)}
                        >
                          <Icon color="success">mic</Icon>
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => handleUnMute(item.extensions)}
                        >
                          <Icon color="error">mic_off</Icon>
                        </IconButton>
                      )}
                    </>
                  )}
                  {phoneType === "moderator" && !item.isVip &&
                      getColorForContact(item.extensions) === "success" && (
                        <Tooltip title={"Remove"}>
                          <IconButton
                            variant="outlined"
                            onClick={() => handleBard(item.extensions)}
                          >
                            <Icon color="error">
                              remove_circle_outline_icon
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                    {phoneType === "moderator" && item.blockedStatus == 1 && (
                      <Tooltip title={"Un-block"}>
                        <IconButton
                          variant="outlined"
                          onClick={() =>
                            handleUnblockParticipant(item.extensions)
                          }
                        >
                          <Icon color="warning">person_off_icon</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                &nbsp;
                <MDTypography>
                  <ListItemText
                    primary={
                      item.participantName ||
                      item.extensions ||
                      item.mobileNumber
                    }
                  />
                </MDTypography>
              </ListItem>
              <Divider />
            </>
          ))
        )}
      </List>
    </MDBox>
  );

  return (
    <Card id="phone" style={{ width: "100%" }}>
      {!AuthenticationService.getDeskPhoneStatus() && (
        <MDTypography
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
            minHeight: "10px",
          }}
        >
          <Icon
            style={{ marginBottom: "-4px", marginRight: "5px" }}
            color={registration === "Registered" ? "success" : "error"}
          >
            circle
          </Icon>
          {SIPPhoneService.userAgentOptions.authorizationUsername}
        </MDTypography>
      )}
      <MDTypography
        style={{
          textAlign: "center",
          minHeight: "10px",
          marginBottom: "10px",
        }}
      >
        {conferenceData && `${conferenceData?.roomName}`}
      </MDTypography>
      {!getMuteStatus(SIPPhoneService.userAgentOptions.authorizationUsername) &&
        inCall &&
        activeUser && (
          <MDTypography
            style={{
              textAlign: "center",
              minHeight: "10px",
              marginBottom: "10px",
              color: "red",
            }}
            component="p"
            variant="caption"
            color="text"
          >
            {`You are muted`}
          </MDTypography>
        )}
      {AuthenticationService.allowUCConferenceMenus() && inCall && (
        <MDTypography
          style={{
            textAlign: "center",
            minHeight: "10px",
            marginBottom: "10px",
          }}
          component="p"
          variant="caption"
          color="text"
        >
          {pttStatus}
        </MDTypography>
      )}
      <audio id="remoteAudio">
        <p>Your browser doesn't support HTML5 audio.</p>
      </audio>
      <MDBox id="video-block" style={{ width: "100%" }}>
        <video id="remoteVideo" style={{ width: "100%" }}></video>
        <video
          id="localVideo"
          style={{ width: "50%", display: "none" }}
        ></video>
      </MDBox>

      <MDBox style={{ maxWidth: "100%" }}>
        <MDTypography
          variant="h6"
          style={{
            marginBottom: "10px",
            textAlign: "center",
            minHeight: "10px",
          }}
        >
          {callStatus}
        </MDTypography>
        {inCall && (
          <MDTypography
            variant="h6"
            style={{
              marginBottom: "10px",
              textAlign: "center",
              minHeight: "10px",
            }}
          >
            Duration : {timer}
          </MDTypography>
        )}

        <MDBox
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <ButtonGroup>
            {/* {isRinging && (
              <Tooltip title="Answer Video Call">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(true)}
                >
                  <Icon color="text">videocam</Icon>
                </MDButton>
              </Tooltip>
            )} */}
            {isRinging && (
              <Tooltip title="Answer Audio Call">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(false)}
                >
                  <Icon color="text">phone</Icon>
                </MDButton>
              </Tooltip>
            )}
            {isDialing && (
              <Tooltip title="Cancel Call">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => cancel()}
                >
                  <Icon color="text">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && (
              <Tooltip title="Disconnect">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => bye()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {AuthenticationService.allowUCConferenceMenus() && inCall && (
              <MDButton
                variant="contained"
                id="call"
                size="medium"
                color={isMute ? "error" : "success"}
                onClick={() => handlePttRequest(isMute)}
                // onMouseUp={() => handleMouseUp()}
                // onMouseDown={() => handleMouseDown()}
              >
                <TouchApp> </TouchApp>
                <Typography variant="button">
                  {isMute ? "Muted" : "Speaking"}
                </Typography>
              </MDButton>
            )}
            {/* {inCall &&
              AuthenticationService.allowUCConferenceMenus() &&
              !isMute &&
              phoneType == "moderator" && (
                <Tooltip title="Un-Mute">
                  <MDButton
                    color="info"
                    variant="contained"
                    id="call"
                    size="medium"
                    onClick={() => mute()}
                  >
                    <Icon color="success">mic</Icon>
                  </MDButton>
                </Tooltip>
              )} */}
            {/* {inCall && AuthenticationService.allowUCConferenceMenus() &&
              isMute &&
              phoneType == "moderator" && (
                <Tooltip title="Mute">
                  <MDButton
                    color="info"
                    variant="contained"
                    id="call"
                    size="medium"
                    onClick={() => unMute()}
                  >
                    <Icon color="error">mic_off</Icon>
                  </MDButton>
                </Tooltip>
              )}*/}
            {inCall && !isMute && AuthenticationService.allowUCMenus() && (
              <Tooltip title="Un-Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => mute()}
                >
                  <Icon color="success">mic</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isMute && AuthenticationService.allowUCMenus() && (
              <Tooltip title="Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unMute()}
                >
                  <Icon color="error">mic_off</Icon>
                </MDButton>
              </Tooltip>
            )}
            {dialNumber.length > 0 && (
              <MDButton
                color="info"
                variant="contained"
                id="call"
                size="medium"
                onClick={() => setDialNumber("")}
              >
                <Icon color="warning">clear</Icon>
              </MDButton>
            )}{" "}
            {phoneType == "moderator" && inCall && !activeStopDistortion && (
              <Tooltip title="Play Announcement">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => HandleOpenDistorsion()}
                >
                  <Icon color="warning"> campaign_icon </Icon>
                </MDButton>
              </Tooltip>
            )}
            {phoneType == "moderator" && inCall && activeStopDistortion && (
              <Tooltip title="Stop Announcement">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => HandleStopDistorsion()}
                >
                  <Icon color="error"> music_off_icon </Icon>
                </MDButton>
              </Tooltip>
            )}
            {phoneType == "participant" &&
              conferenceData?.rptt &&
              inCall &&
              AuthenticationService.allowUCMenus() && (
                <Tooltip title="Raise hand">
                  <MDButton
                    color={raiseHand}
                    variant="contained"
                    id="call"
                    size="medium"
                    onClick={() => {
                      handleRaiseHand();
                    }}
                  >
                    <Icon color="error"> pan_tool_icon</Icon>
                  </MDButton>
                </Tooltip>
              )}
            {inCall && !actionButton && (
              <Tooltip title="participant List">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={toggleDrawer(true)}
                >
                  <Icon color="text">group_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
          </ButtonGroup>
        </MDBox>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer(false)}
          sx={{ "& .MuiDrawer-paper": { width: 400, overflow: "hidden" } }}
        >
          {list("left")}
        </Drawer>
      </MDBox>

      <CTLBaseDialogForm
        entityName="Add participant in conference"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <PlayDistorsion
        entityName="Play Distorsion"
        openDistorsion={openDistorsion}
        setOpenDistorsion={setOpenDistorsion}
        HandleCloseDistorsion={HandleCloseDistorsion}
        setActiveStopDistortion={setActiveStopDistortion}
        conferenceData={conferenceData}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

export default CTLConferencePhone;
