import { Card, Tab, Tabs, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@material-ui/core";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import EventList from "./EventList";
import LoginLogout from "./LoginLogout";
import AuthenticationService from "../authentication/AuthenticationService";
import CallsLog from "./CallsLog";

const Attributes = {
  enableSelectDispatcherMenu: false,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  selectUrl: "extensions/listAllDepartment",
  keyField: "department",
  modes: [],
};

export default function UserActivityLog({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const [selectedTab, setSelectedTab] = useState("Event");
  const [controller] = useMaterialUIController();
  const { activePage } = controller;

  useEffect(() => {
    Attributes && onLoad(Attributes);
  }, [activePage]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card style={{ marginBottom: "10px", borderRadius: "10px" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#3492eb",
                },
                height: 50,
              }}
            >
              <Tab
                label="Event Log"
                value="Event"
                icon={<Icon>event_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Message Log"
                value="message"
                icon={<Icon>message_icon </Icon>}
                iconPosition="start"
              />
              <Tab
                label="Call Log"
                value="call"
                icon={<Icon>call_icon</Icon>}
                iconPosition="start"
              />
                <Tab
                  label="login logout Log"
                  value="login"
                  icon={<Icon>lock_open_icon</Icon>}
                  iconPosition="start"
                />
            </Tabs>
          </Card>
        </Grid>

        {selectedTab == "Event" && (
          <Grid item xs={12}>
            <EventList
              url={"event"}
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "message" && (
          <Grid item xs={12}>
            <EventList
              url={"message"}
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "call" && (
          <Grid item xs={12}>
          <CallsLog
              url={"call"}
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}

        {selectedTab == "login" && (
          <Grid item xs={12}>
            <LoginLogout
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}
