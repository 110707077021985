import CTLUnReadCell from "application/components/CTLUnReadCell";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";


const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/queuePerformanceSummary/list",
  pdfDownload: "/queuePerformanceSummary/generateAndDownloadPdf",
  csvDownload: "/queuePerformanceSummary/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue ID",
      accessor: "queue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Call Direction",
      accessor: "callDirection",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Record DateTime",
      accessor: "recordEpoch",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      reports:false,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Joined",
      accessor: "totalJoined",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Connected With Agent",
      accessor: "totalConnectedWithAgent",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Abnd at ACD",
      accessor: "totalAbandonedAtQueue",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Agent Talk Time",
      accessor: "totalCustomerAgentTalkTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Customer Time",
      accessor: "totalCustomerTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "intestringger",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total No Contact",
      accessor: "totalNoContact",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Abandoned At Agent",
      accessor: "totalAbandonedAtAgent",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
