import DefaultCell from "application/components/DefaultCell";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableTeamLeaderSelect: true,
  selectUrl: "/queueTL/listTL",
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};
const getColumns = () => {
  return [
    {
      Header: "Agent Id",
      accessor: "id",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "agentFullName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes };
