import AgentChatPanel from "layouts/nms/AgentChatPanel";
import React from "react";

function whatsapp() {
  return (
    <>
      <AgentChatPanel agentId={"agent31"} customerNumber={"9999999999"} />
    </>
  );
}
export default whatsapp;
