import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import MDSnackbar from "components/MDSnackbar";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLNotification from "application/components/CTLNotification";
import { CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import { telemetry } from "globals/endpoints";


const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: theme.zIndex.modal + 1,
  },
}));


function Asset({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
    const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [deviceData, setDeviceData]= useState();
  const [metadata , setMetaData] = useState();
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [openMetaFrom , setOpenMetaForm] =useState(false);
  const [getAssetId, setAssetId] = useState("");
  const [notify, setNotify] = useState({
      isOpen: false,
      message: "",
      type: "",
      pagename: "",
      status: "",
    });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    application.post(operationURLS.delete, {
      id: [row.id]
    }).then((response) => {
      setUpdateList(Date.now());
    });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          openSuccessSB();
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          openSuccessSB();
        })
        .catch((error) => {
          openErrorSB();
        });
    }
    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function onCloseMetaForm(){
    setOpenMetaForm(false);
  }

  function onSaveMetaForm(data){
     application
       .post(`SCADAAsset/create/${getAssetId}`, data)
       .then((response) => {
         setOpenMetaForm(false);
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: pageName,
            status: response.data.message,
          });
       })
       .catch((error) => {
         setOpenMetaForm(false);
          setNotify({
            isOpen: true,
            message: error.response.message,
            type: "error",
            pagename: pageName,
            status: error.response.messageDetail,
          });
       });
  }
  
  const onUpdateConfiguration = (data) => {
    setLoading(true);
  const assetId = data.id
    if (assetId) {
      setAssetId(assetId);
      application
        .post(`SCADAAsset/getAssetConfig/${assetId}`)
        .then((response) => {
          setMetaData(response.data?.data);
          const updatedFormData = response.data?.data?.formData;
          if(updatedFormData == null){
          setDeviceData([]);
          }else{
          updatedFormData.id=1;
          setDeviceData(updatedFormData);
          }
          setOpenMetaForm(true);
          setLoading(false);
          
        })
        .catch((error) => {
         setLoading(false);
          setNotify({
            isOpen: true,
            message: error.response?.data.status,
            type: "error",
            pagename: pageName,
            status: error.response?.data.message,
          });
        });
    }
  };

const onUpdateFirmware = (row) => {
  if (row.macAddress) {
    telemetry
      .get(`deviceManager/updateFirmware/${row.macAddress}`)
      .then((response) => {
        setMetaData(response.data.data);
        setOpenMetaForm(true);
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "info",
          pagename: pageName,
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "info",
          pagename: pageName,
          status: error.response.messageDetail,
        });
      });
    }
};

 const onReboot = (row) => {
    if (row.macAddress) {
      telemetry.get(`deviceManager/reboot/${row.macAddress}`).then((response) => {
        setMetaData(response.data.data);
        setOpenMetaForm(true);
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "info",
          pagename: pageName,
          status: response.data.message,
        });
      })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "info",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    }
  } 

 const onReset = (row) => {
   if (row.macAddress) {
     telemetry
       .get(`deviceManager/reset/${row.macAddress}`)
       .then((response) => {
         setMetaData(response.data.data);
         setOpenMetaForm(true);
         setNotify({
           isOpen: true,
           message: response.data.status,
           type: "info",
           pagename: pageName,
           status: response.data.message,
         });
       })
       .catch((error) => {
         setNotify({
           isOpen: true,
           message: error.response.message,
           type: "info",
           pagename: pageName,
           status: error.response.messageDetail,
         });
       });
   }
  }

   const onDeployConfiguration = (row) => {
    if (row.macAddress) {
      telemetry.get(`deviceManager/updateConfiguration/${row.macAddress}`).then((response) => {
        setMetaData(response.data.data);
        setOpenMetaForm(true);
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "info",
          pagename: pageName,
          status: response.data.message,
        });
      })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "info",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    }
  }



  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <MDTypography variant="body1">
            <CircularProgress color="info" /> &nbsp; Loading...
          </MDTypography>
        </div>
      </Fade>
      <DeleteConfirmation
        entityName="Asset"
        data={selectedRow}
        columns={getColumns()}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="Asset"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      {metadata && metadata.form && (
        <CTLBaseDialogForm
          entityName="Device Configuration"
          columns={metadata.form.content}
          openForm={openMetaFrom}
          onSaveForm={onSaveMetaForm}
          onCloseForm={onCloseMetaForm}
          formData={deviceData || []}
          pageAttributes={{
            columns: 3,
            tabs: metadata?.form.tabs || [],
          }}
        />
      )}

      <CTLAdvancedFilter
        entityName="Asset"
        columns={getColumns()}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(
        deleteRow,
        editRow,
        // onUpdateConfiguration,
        onUpdateFirmware,
        onReboot,
        // onReset,
        // onDeployConfiguration
        )}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
      {renderSuccessSB}
      {renderErrorSB}

      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
      }
export default Asset;
