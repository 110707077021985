import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import EmptyImage from "../../../../../assets/images/mediacenter/emptyFolder.svg";
import { Container, Typography } from "@mui/material";

function BillingSummary({ onLoad, pageId, searchText, registerCallBacks }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue } = controller;

  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [columns, setColumns] = useState(getColumns());
  const [updateList, setUpdateList] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onRefresh: onRefresh,
      });
  }, [activePage]);


  const onRefresh = () => {
    setUpdateList(Date.now);
  };

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }
  const stateMachineRefreshInterval = window.stateMachineRefreshInterval;
  const shouldDisplayMessage =
    stateMachineRefreshInterval === 0 ||
    stateMachineRefreshInterval === -1 ||
    stateMachineRefreshInterval === undefined;
  const refetchInterval = shouldDisplayMessage
    ? null
    : stateMachineRefreshInterval * 1000;
  return (
    <MDBox>
      {shouldDisplayMessage ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Container sx={{ width: "100vw" }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "89vh" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <img src={EmptyImage} alt="Empty Folder" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" fontFamily={600}>
                  Real Time Data Not Found
                </Typography>
              </Box>
            </Box>
          </Container>
        </div>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <CTLTable
            apiURL={operationURLS.list}
            advancedFilters={advancedFilters}
            columns={columns}
            entriesPerPage={false}
            canSearch
            defaultSortDirection="desc"
            defaultSortColumn="direction"
            pageId={pageId}
            searchText={searchText}
            hasRowSelection={false}
            refetchInterval={refetchInterval}
            updateList={updateList}
            disablePagination={true}
          />
        </div>
      )}

      <CTLAdvancedFilter
        entityName="Billing Summary"
        columns={getColumns()}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <MDBox>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default BillingSummary;
