const voicePickerMenus = [
  {
    icon: " keyboard_voice",
    key: "configuration.voicePicker",
    name: "Old Voice Picker",
    url: "home/app/oldvoicePicker",
    description: "Voice Picker",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "backup_icon",
    key: "backuprecordingfile",
    name: "Voice Picker",
    url: "home/app/configurations/new-voice-picker",
    description:
      "Backup and Download ",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "download_for_offline_icon",
    key: "backupdownloadfile",
    name: "Backup Download File ",
    url: "home/app/configurations/backup-download-file ",
    description: "User Can Backup file download in this  Page ",
    productionReady: true,
    disabled: false,
  },
];

export { voicePickerMenus };
