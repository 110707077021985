import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";
import CTLNotificationBooleanCell from "application/components/CTLNotificationBooleanCell";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "SCADAAsset/list",
  update: "SCADAAsset/update",
  create: "SCADAAsset/create",
  delete: "SCADAAsset/delete/",
};

const getColumns = (
  onDelete,
  onEdit,
  // onUpdateConfiguration,
  onUpdateFirmware,
  onReboot,
  // onReset,
  // onDeployConfiguration
) => {
  return [
    {
      Header: "Back Ground URL",
      accessor: "backgroundURL",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Node Name",
      accessor: "nodeName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().required("Required").ipv4(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Catetory",
      accessor: "category",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "categoryCode",
        keyDescription: "categoryName",
        values: [],
        url: "categoryService/dropdownCategoryCode/list",
      },
    },
    {
      Header: "Control",
      accessor: "control",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: true, description: "Yes" },
          { id: false, description: "No" },
        ],
      },
    },
    {
      Header: "Location",
      accessor: "location",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,

      options: {
        keyField: "locationCode",
        keyDescription: "locationName",
        values: [],
        url: "location/dropdown/list",
      },
    },
    {
      Header: "Extension Number",
      accessor: "extensionNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MAC Address",
      accessor: "macAddress",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Cell Id",
      accessor: "cellId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Switch IP",
      accessor: "switchIP",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().ipv4(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Switch Port",
      accessor: "switchPort",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      validation: formControl.string().digitsOnly().nullable(),
      groupHeading: "Scada Asset",
    },
    {
      Header: "OID",
      accessor: "oid",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Server Group ID",
      accessor: "serverGroupId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Stack Id",
      accessor: "stackId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "X Cordinate",
      accessor: "xcoordinate",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Y Cordinate",
      accessor: "ycoordinate",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Notification",
      accessor: "disableNotification",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLNotificationBooleanCell value={value} />,
    },
    {
      Header: "Suppress Aggregation",
      accessor: "suppressAggregation",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Product Class",
      accessor: "productClass",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "productClass",
        keyDescription: "productClass",
        values: [],
        url: "deviceModel/listAll",
      },
    },

    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          // onUpdateConfiguration={onUpdateConfiguration}
          onUpdateFirmware={onUpdateFirmware}
          onReboot={onReboot}
          // onReset={onReset}
          // onDeployConfiguration={onDeployConfiguration}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
