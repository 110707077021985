import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const dispatcherQuickMenus = [
  {
    icon: (
      <Tooltip title="Map Dashboard">
        <Icon fontSize="medium">view_carousel</Icon>
      </Tooltip>
    ),
    name: "Default Dashboard",
    key: "nms.suraksha",
    url: "home/app/map",
    description: "Dashboard",
  },
  {
    icon: (
      <Tooltip title="Group Dashboard">
        <Icon fontSize="medium">group_icon</Icon>
      </Tooltip>
    ),
    name: "Group-Dashboard",
    key: "groupDashboard",
    url: "home/app/group",
    description: "Group Dashboard",
  },
  {
    icon: (
      <Tooltip title="Calls In Waiting">
        <Icon fontSize="medium">call_icon</Icon>
      </Tooltip>
    ),
    name: "call",
    key: "groupCallInQueue",
    url: "home/app/call",
    description: "Call",
  },
  {
    icon: (
      <Tooltip title="Dashboard">
        <Icon fontSize="medium">dashboard</Icon>
      </Tooltip>
    ),
    name: "Dashboard",
    key: "dashboard.controller",
    url: "home/app/dashboard",
    description: "Dashboard",
  },
  {
    icon: (
      <Tooltip title="Task Manager">
        <Icon fontSize="medium">assignment_turned_in_icon</Icon>
      </Tooltip>
    ),
    key: "taskManager",
    name: "Task Manager",
    url: "home/app/taskManager",
    description: "Task Manager",
    disabled: false,
    productionReady: true,
  },
];

export { dispatcherQuickMenus };
