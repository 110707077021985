import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import DispatcherActionIcon from "application/components/DispatcherActionIcon";
import DispatcherRowAction from "application/components/DispatcherRowAction";

const operationURLS = {
  csvDownload: "activityLog/downloadCSV",
};

const getColumns = (onClickToCall, onClickToMessage) => {
  return [
    {
      Header: "Created By",
      accessor: "createdBy",
      allowFilter: false,
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Created On",
      accessor: "createdOn",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Event Type",
      accessor: "event",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <DispatcherActionIcon row={row} value={value} />
      ),
    },
    {
      Header: "Event Category",
      accessor: "eventCategory",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Event Status",
      accessor: "eventStatus",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Caller Number",
      accessor: "callerNumber",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },

    {
      Header: "Destination Number",
      accessor: "destinationNumber",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value, row }) => <CTLUnReadCell value={value} row={row} />,
    },
    // {
    //   Header: "Action",
    //   accessor: "id",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value, row }) => (
    //     <DispatcherRowAction
    //       value={value}
    //       row={row}
    //       onClickToCall={onClickToCall}
    //       onClickToMessage={onClickToMessage}
    //     />
    //   ),
    // },
  ];
};

export { getColumns, operationURLS };
