import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import RoomMasterCell from "application/components/roomMasterCell";
import GroupMasterCell from "application/components/userGroupMasterCell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import { userMenus } from "globals/configuration/user-management";
const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "lg",
  enableAddButton: true,
  enableMultipleUpdate: false,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : userMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
          disabled: item.disabled,
          productionReady: item.productionReady,
        };
      }),
};

const operationURLSS = {
  multiUpdate: "user/multiUpdate/user",
};

const getMultipleUpdateColumns = (onDelete, onEdit, onView) => {
  return [
    // {
    //   Header: "Room",
    //   accessor: "nodeId",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: true,
    //   dataType: "string",
    //   controlType: "multipleSelect",
    //   Cell: ({ value,row }) => <RoomMasterCell value={value}  row={row} />,
    //   options: {
    //     keyField: "id",
    //     keyDescription: "nodeName",
    //     values: [],
    //     url: "configuration/list/nodes",
    //   },
    // },
    {
      Header: "Group",
      accessor: "group",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 12,
      controlType: window.singleGroupUpdate ? "select" : "multipleSelect",
      Cell: ({ value }) => <GroupMasterCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "code",
        values: [],
        url: "/userGroupMaster/listAll",
      },
    },
    {
      Header: "Role",
      accessor: "role",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 12,
      controlType: "multipleSelect",
      // validation: formControl.required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "role/dropdownList",
      },
    },
    {
      Header: "Area",
      accessor: "areaId",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      apiType: "telemetry",
      width: 12,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/area/listAll",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          onView={onView}
        />
      ),
    },
  ];
};

export { getMultipleUpdateColumns, Attributes, operationURLSS };
