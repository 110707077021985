import { useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CTLNotification from "application/components/CTLNotification";
import variables from "globals/variables";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
function DownloadFile({ row }) {
  let rowId = row?.original?.id;
  let status = row?.original?.status;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const filename = row?.original?.pdfLocation;
  let str = filename;
  let index = str.lastIndexOf("/");
  let extracted = str.slice(index + 1, str.length);



  // Download Voice Picker MP3 File
  async function onDownloadFile() {

    try {
      const operationURL = `${variables.app.services}auth/download/tar/${rowId}`;
      const response = await fetch(operationURL);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }
      setNotify({
        isOpen: true,
        message: "",
        type: "success",
        pagename: "Backup File Download ",
        status: "Backup File Download In Progress...",
      });
      const link = document.createElement("a");
      link.href = operationURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: "Backup File Download ",
        status: "Unable To Download Backup File",
      });
    }
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {status === "success" ? (
        <Tooltip title="DownloadFile">
          <IconButton color="error" onClick={() => onDownloadFile()}>
            <DownloadIcon></DownloadIcon>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Not availble">
          <IconButton color="white" onClick={() => onDownloadFile()}>
            <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
          </IconButton>
        </Tooltip>
      )}

      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDTypography>
  );
}

export default DownloadFile;
