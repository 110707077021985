import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import Sensor from "../Sensor";
import AssetImage from "../AssetImage";
import Label from "../Label";
import {
  useGetDataQuery,
  useGetCategoryListQuery,
  useSaveCategorySensorsMutation,
} from "features/apiSlice";
import AssignSensorPopup from "../AssignSensorPopup";

const DEFAULT_BUFFER_LEFT = 8;
const DEFAULT_BUFFER_TOP = 7;

function RenderSmartAsset({
  isAdmin,
  isEditMode,
  assetId,
  assetImageName,
  assetImageWidth,
  assetImageHeight,
  category,
  setIsSaving,
  parentRefCurrent,
  handleParentRefCurrent,
  bufferLeft = DEFAULT_BUFFER_LEFT,
  bufferTop = DEFAULT_BUFFER_TOP,
  assetIpAddress,
}) {
  const [droppedSensors, setDroppedSensors] = useState([]);
  const [undroppedSensors, setUndroppedSensors] = useState([]);
  const [saveCategorySensors] = useSaveCategorySensorsMutation();
  const [assignSensorPopupOpen, setAssignSensorPopupOpen] = useState(false);
  const [currentSensorCoordinates, setCurrentSensorCoordinates] =
    useState(null);

  const assetSensorListQuery = useGetDataQuery(
    `${process.env.REACT_APP_LIST_FOR_ASSET_ENDPOINT}${assetId}`,
    {
      pollingInterval: 1000,
    }
  );

  const categoryListQuery = useGetCategoryListQuery(
    process.env.REACT_APP_CATEGORY_LIST_ENDPOINT
  );

  useEffect(() => {
    if (
      assetSensorListQuery.data &&
      categoryListQuery.data &&
      parentRefCurrent
    ) {
      setDroppedSensors(() => {
        const renderedSensors = [];
        assetSensorListQuery.data.forEach((sensor) => {
          const targetCategory = categoryListQuery.data.filter(
            (curr) => curr.code === category
          )[0];
          const categorySensor = targetCategory.sensors.find(
            (categorySensor) => categorySensor.sensorType === sensor.sensorType
          );
          if (categorySensor && categorySensor.configuration) {
            const configuration = JSON.parse(categorySensor.configuration);
            renderedSensors.push({
              ...categorySensor,
              configuration: {
                ...configuration,
                sensorX:
                  (parseFloat(configuration.sensorX) *
                    parentRefCurrent.offsetWidth) /
                  100,
                sensorY:
                  (parseFloat(configuration.sensorY) *
                    parentRefCurrent.offsetHeight) /
                  100,
                labelX:
                  (parseFloat(configuration.labelX) *
                    parentRefCurrent.offsetWidth) /
                  100,
                labelY:
                  (parseFloat(configuration.labelY) *
                    parentRefCurrent.offsetHeight) /
                  100,
              },
              provisioningId: sensor.provisioningId,
              sensorCode: sensor.sensorCode,
              sensorStatus: sensor.sensorStatus,
            });
          }
        });
        return renderedSensors;
      });
      setUndroppedSensors(() => {
        const dropdownSensors = [];
        assetSensorListQuery.data.forEach((sensor) => {
          const targetCategory = categoryListQuery.data.filter(
            (curr) => curr.code === category
          )[0];
          const categorySensor = targetCategory.sensors.find(
            (categorySensor) => categorySensor.sensorType === sensor.sensorType
          );
          if (!categorySensor) {
            dropdownSensors.push({
              id: 0,
              categoryCode: category,
              sensorType: sensor.sensorType,
              configuration: null,
              provisioningId: sensor.provisioningId,
              sensorCode: sensor.sensorCode,
              sensorStatus: sensor.sensorStatus,
            });
          } else if (!categorySensor.configuration) {
            dropdownSensors.push({
              ...categorySensor,
              provisioningId: sensor.provisioningId,
              sensorCode: sensor.sensorCode,
              sensorStatus: sensor.sensorStatus,
            });
          }
        });
        return dropdownSensors;
      });
    }
  }, [assetSensorListQuery.data, categoryListQuery.data]);

  const handleSaveConfiguration = (newSensor) => {
    const sensorXPercentage =
      (newSensor.xcoordinate * 100) / parentRefCurrent.offsetWidth;
    const sensorYPercentage =
      (newSensor.ycoordinate * 100) / parentRefCurrent.offsetHeight;
    const labelXPercentage =
      (newSensor.configuration.labelX * 100) / parentRefCurrent.offsetWidth;
    const labelYPercentage =
      (newSensor.configuration.labelY * 100) / parentRefCurrent.offsetHeight;

    if (
      sensorXPercentage < 0 ||
      sensorXPercentage > 100 ||
      sensorYPercentage < 0 ||
      sensorYPercentage > 100 ||
      labelXPercentage < 0 ||
      labelXPercentage > 100 ||
      labelYPercentage < 0 ||
      labelYPercentage > 100
    ) {
      alert("Invalid sensor coordinates");
      return;
    }

    setIsSaving(true);
    saveCategorySensors({
      id: newSensor.id,
      categoryCode: newSensor.categoryCode,
      sensorType: newSensor.sensorType,
      configuration: newSensor.configuration
        ? JSON.stringify(newSensor.configuration)
        : null,
    })
      .unwrap()
      .then((res) => {
        setIsSaving(false);
        console.log(res);
      })
      .catch((err) => {
        setIsSaving(false);
        console.log(err);
      });
  };

  const handleAssignSensorPopupOpen = (left, top) => {
    setCurrentSensorCoordinates({
      sensorX: left,
      sensorY: top,
    });
    setAssignSensorPopupOpen(true);
  };

  const handleAssignSensorPopupClose = (droppedSensor) => {
    const newSensor = {
      ...droppedSensor,
      configuration: {
        sensorX:
          parseFloat(currentSensorCoordinates.sensorX.toFixed(2)) +
          markerSize / 2,
        sensorY:
          parseFloat(currentSensorCoordinates.sensorY.toFixed(2)) +
          markerSize / 2,
        labelX: parseFloat(currentSensorCoordinates.sensorX.toFixed(2)),
        labelY: parseFloat(currentSensorCoordinates.sensorY.toFixed(2)) + 25,
        labelText:
          droppedSensor.sensorCode || droppedSensor.sensorType || "Add label",
        labelOrientation: "horizontal",
        labelFontSize: 7,
      },
    };
    setUndroppedSensors((prevUndroppedSensors) =>
      prevUndroppedSensors.filter(
        (sensor) => sensor.provisioningId !== droppedSensor.provisioningId
      )
    );
    setDroppedSensors((prevDroppedSensors) => [
      ...prevDroppedSensors,
      newSensor,
    ]);
    setCurrentSensorCoordinates(null);
    setAssignSensorPopupOpen(false);
    handleSaveConfiguration(newSensor);
  };

  const handleLabelDragStop = (lastX, lastY, labelAssociatedSensor) => {
    const newSensor = {
      ...labelAssociatedSensor,
      configuration: {
        ...labelAssociatedSensor.configuration,
        labelX: lastX,
        labelY: lastY,
      },
    };
    setDroppedSensors((prevDroppedSensors) =>
      prevDroppedSensors.map((sensor) =>
        sensor.provisioningId === labelAssociatedSensor.provisioningId
          ? newSensor
          : sensor
      )
    );
    handleSaveConfiguration(newSensor);
  };

  const handleSensorDragStop = (lastX, lastY, draggedSensor) => {
    const newSensor = {
      ...draggedSensor,
      configuration: {
        ...draggedSensor.configuration,
        sensorX: lastX + markerSize / 2,
        sensorY: lastY + markerSize / 2,
      },
    };
    setDroppedSensors((prevDroppedSensors) =>
      prevDroppedSensors.map((sensor) =>
        sensor.provisioningId === draggedSensor.provisioningId
          ? newSensor
          : sensor
      )
    );
    handleSaveConfiguration(newSensor);
  };

  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    // Load the image
    const image = new Image();
    image.src = assetImageName;

    // Once the image is loaded, calculate the aspect ratio
    image.onload = () => {
      const { width, height } = image;
      const aspectRatioValue = width / height;
      setAspectRatio(aspectRatioValue);
    };
  }, []);

  return (
      <MDBox position="relative">
        <AssetImage
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          assetImageName={assetImageName}
          bufferLeft={bufferLeft}
          bufferTop={bufferTop}
          handleAssignSensorPopupOpen={handleAssignSensorPopupOpen}
          handleParentRefCurrent={handleParentRefCurrent}
          assetSensorListQuery={assetSensorListQuery}
          category={category}
          assetIpAddress={assetIpAddress}
          assetId={assetId}
        />
        <AssignSensorPopup
          undroppedSensors={undroppedSensors}
          assignSensorPopupOpen={assignSensorPopupOpen}
          setAssignSensorPopupOpen={setAssignSensorPopupOpen}
          handleAssignSensorPopupClose={handleAssignSensorPopupClose}
          setCurrentSensorCoordinates={setCurrentSensorCoordinates}
        />
        {droppedSensors.map((sensor) => (
          <Sensor
            key={sensor.provisioningId}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            sensor={sensor}
            handleSensorDragStop={handleSensorDragStop}
          />
        ))}
        {droppedSensors.map((sensor) => (
          <Label
            key={sensor.provisioningId}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            sensor={sensor}
            handleLabelDragStop={handleLabelDragStop}
            setDroppedSensors={setDroppedSensors}
            handleSaveConfiguration={handleSaveConfiguration}
          />
        ))}
      </MDBox>
  );
}

export default RenderSmartAsset;
