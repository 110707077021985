import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";


const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: false,
  enableDownloadButton: false,
  enableAdvanceSearch: false,
  enableDownloadPdf: false,
  enableRefresh: true,
  modes:[]
};
const operationURLS = {
  list: "/channels/getCallData",
};
const getColumns = () => {
  return [
    {
      Header: "CallId",
      accessor: "callId",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "State",
      accessor: "state",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      dateTime: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "localUser",
      accessor: "localUser",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "remoteUser",
      accessor: "remoteUser",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "callDirection",
      accessor: "callDirection",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "epochDate",
      accessor: "epochDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "ringEpochDate",
      accessor: "ringEpochDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "answerEpochDate",
      accessor: "answerEpochDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "endEpochDate",
      accessor: "endEpochDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "localTag",
      accessor: "localTag",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "remoteTag",
      accessor: "remoteTag",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "localContact",
      accessor: "localContact",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "remoteContact",
      accessor: "remoteContact",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "linkCallId",
      accessor: "linkCallId",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "holdState",
      accessor: "holdState",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "holdBy",
      accessor: "holdBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "holdTo",
      accessor: "holdTo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "transferType",
      accessor: "transferType",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "transferredBy",
      accessor: "transferredBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "transferredTo",
      accessor: "transferredTo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "referredBy",
      accessor: "referredBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "referTo",
      accessor: "referTo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "isAnswered",
      accessor: "isAnswered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "disconnectedBy",
      accessor: "disconnectedBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "disconnectedTo",
      accessor: "disconnectedTo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "cseqNumber",
      accessor: "cseqNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
