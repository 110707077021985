import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { Card, Grid, Icon, Tab, Tabs } from "@mui/material";
import { useMaterialUIController } from "context";
import EventList from "../EventList";
import ContactList from "./ContactList";

const Attributes = {
  modes: [],
};

const CommunicationView = ({ onLoad, pageId, searchText, registerCallBacks }) => {
  const [selectedTab, setSelectedTab] = useState("Contact");
  const [controller] = useMaterialUIController();
  const { activePage } = controller;

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <MDBox sx={{ height: "70vh", overflow: "auto", position: "relative" }}>
      <Grid item xs={12}>
        <Card
          style={{
            marginBottom: "10px",
            borderRadius: "0px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#3492eb",
              },
              height: 50,
            }}
          >
            <Tab label="Contact List" value="Contact" />
            <Tab label="Activity Log" value="Activity" />
            {/* <Tab label="Status" value="status" /> */}
          </Tabs>
        </Card>
        {selectedTab == "Contact" && (
          <Grid item xs={12}>
            <ContactList
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Activity" && (
          <Grid item xs={12}>
            <EventList
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "status" && <Grid item xs={12}></Grid>}
      </Grid>
    </MDBox>
  );
};
export default CommunicationView;
