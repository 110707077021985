import { useUpdateHopsitalJSONMutation } from "features/hospitalSlice";
import { enqueueSnackbar } from "notistack";

export const useChangeHospitalJSON = () => {
  const [updateHopsitalJSON] = useUpdateHopsitalJSONMutation();

  const changeHospitalJSON = async (folders, entityType) => {
    const payload = {
      data: folders,
    };
    try {
      const res = await updateHopsitalJSON(payload).unwrap();
      if (res.status === "OK") {
        enqueueSnackbar(`Successfully ${entityType ? entityType : ""}`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return { changeHospitalJSON };
};
