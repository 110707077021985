import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";

function TaskStatus({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
const [controller, dispatch] = useMaterialUIController();
const { activePage } = controller;
const [confirmDelete, setConfirmDelete] = useState(false);
const [selectedRow, setSelectedRow] = useState([]);
const [openFilter, setOpenFilter] = useState(false);
const [advancedFilters, setAdvancedFilters] = useState([]);
const [openForm, setOpenForm] = useState(false);
const [formData, setFormData] = useState({});
const [updateList, setUpdateList] = useState(Date.now());
const [notify, setNotify] = useState({
isOpen: false,
message: "",
type: "",
pagename: "",
status: "",
});

useEffect(() => {
Attributes && onLoad(Attributes);
registerCallBacks &&
registerCallBacks({
openAdvancedFilter: onOpenFilter,
openForm: onOpenForm,
});
}, [activePage]);

function onOpenFilter() {
setOpenFilter(true);
}

function onOpenForm() {
setFormData({
eventTime: Date.now(),
});
setOpenForm(true);
}

function onCloseFilter() {
setOpenFilter(false);
}

function onApplyFilter(advancedFilters) {
setAdvancedFilters(advancedFilters);
setOpenFilter(false);
}

const editRow = (row) => {
setFormData(row);
setOpenForm(true);
};

const deleteRow = (row) => {
setSelectedRow(row);
setConfirmDelete(true);
};

const onCancel = () => {
setSelectedRow([]);
setConfirmDelete(false);
};

const onAgree = (row) => {
console.log(row);
application
.post(operationURLS.delete, {
intId: [row.id],
})
.then((response) => {
setUpdateList(Date.now());
setNotify({
isOpen: true,
status: response.data.messageDetail,
type: "success",
pagename: pageName,
});
})
.catch((error) => {
setNotify({
isOpen: true,
status:
error.response.data.message +
" " +
error.response.data.messageDetail,
type: "error",
pagename: pageName,
});
});

setConfirmDelete(false);
};

function onSaveForm(data) {
if (data.id) {
application
.post(operationURLS.update, data)
.then((response) => {
setUpdateList(Date.now());
setNotify({
isOpen: true,
status: response.data.messageDetail,
type: "success",
pagename: pageName,
});
})
.catch((error) => {
setNotify({
isOpen: true,
status:
error.response.data.message +
" " +
error.response.data.messageDetail,
type: "error",
pagename: pageName,
});
});
} else {
application
.post(operationURLS.create, data)
.then((response) => {
setUpdateList(Date.now());
setNotify({
isOpen: true,
status: response.data.message,
type: "success",
pagename: pageName,
});
})
.catch((error) => {
setNotify({
isOpen: true,
status:
error.response.data.message +
" " +
error.response.data.messageDetail,
type: "error",
pagename: pageName,
});
});
}
setOpenForm(false);
}
function onCloseForm() {
setOpenForm(false);
}

return (
<MDBox>
<DeleteConfirmation
entityName="Task Status"
data={selectedRow}
columns={getColumns(deleteRow, editRow)}
confirmDelete={confirmDelete}
onAgree={onAgree}
onCancel={onCancel}
/>
<CTLBaseDialogForm
entityName="Task Status "
columns={getColumns(deleteRow, editRow)}
openForm={openForm}
onSaveForm={onSaveForm}
onCloseForm={onCloseForm}
formData={formData}
pageAttributes={Attributes}
/>
<CTLTable
apiURL={operationURLS.list}
advancedFilters={advancedFilters}
columns={getColumns(deleteRow, editRow)}
entriesPerPage={false}
canSearch
defaultSortDirection="asc"
defaultSortColumn="id"
pageId={pageId}
list
searchText={searchText}
hasRowSelection={false}
updateList={updateList}
disablePagination={false}
/>
<CTLAdvancedFilter
entityName="Task Status"
columns={getColumns(deleteRow, editRow)}
applyFilter={openFilter}
onApplyFilter={onApplyFilter}
onCloseFilter={onCloseFilter}
advancedFilters={advancedFilters}
/>
<CTLNotification notify={notify} setNotify={setNotify} />
</MDBox>
);
}

export default TaskStatus;