import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";


const Attributes = {
  formSize: "md",
  enableAddButton: true,
  settingsMode: false,
  enableAdvanceSearch: true,
  
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
    create: "company/create",
    update: "company/update",
    list: "company/list",
    delete: "company/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
        Header: "taskId",
        accessor: "taskId",
        displayInList: true,
        allowInForm: true,
        allowFilter: true,
        dataType: "string",
        width:6,
        validation: formControl.string().required("Required").nullable(),
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "taskStats",
        accessor: "taskStats",
        displayInList: true,
        allowInForm: true,
        allowFilter: true,
        dataType: "string",
        width:6,
        validation: formControl.string().required("Required").nullable(),
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "statusUpdatedBy",
        accessor: "statusUpdatedBy",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "statusUpdatedOn",
        accessor: "statusUpdatedOn",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "isDelay",
        accessor: "isDelay",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      }, {
        Header: "changedCompletionOn",
        accessor: "changedCompletionOn",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      }, {
        Header: "remarks",
        accessor: "remarks",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "id",
        displayInList: true,
        allowInForm: false,
        Cell: ({ row, value }) => (
          <RowAction
            row={row}
            value={value}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ),
      },
  ];
};

export { getColumns, Attributes, operationURLS };
