import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import CTLSelectedDownload from "application/components/CTLSelectDownload";
import MDTypography from "components/MDTypography";
import loggerFactory from "globals/logger/logger-factory";
import { setActivePage } from "context";
function BackupRecordingFile({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller] = useMaterialUIController();
  const {
    activePage,
    darkMode,
    tableValue,
    tableSortColumn,
    tableColumnDataType,
  } = controller;
  const [formData, setFormData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [updateList, setUpdateList] = useState([Date.now()]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openBackupBox, setOpenBackupBox] = useState(false);
  const [fileName, setfileName] = useState("");
  const [backup, setBackup] = useState(false);
  const [backupDelete, setBackupDelete] = useState(true);
  const [RowDelete, setRowDelete] = useState(false);
  const selectedRowsRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(getColumns());
  const [deletefunction, setDeleteFunction] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const [sortColumn, setSortColumn] = useState("startTime");
  const [sortColumnforbackup, setSortColumnForBackup] =
    useState("callerIdNumber");
  const [columnDataType, setColumnDataType] = useState("date");
  const filteredList = getColumns().filter(
    (item) => item.displayInList === true
  );
  const selectedColumnDownload = useRef(
    filteredList && filteredList.map((item) => item.accessor)
  );
  useEffect(() => {
    {
      (tableSortColumn != "" && setSortColumn(tableSortColumn)) ||
        setSortColumnForBackup(tableSortColumn);
    }
    {
      tableColumnDataType != "" && setColumnDataType(tableColumnDataType);
    }
  }, [tableSortColumn, tableColumnDataType]);

  // Getting the From Current Time
  const FromCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T00:01`;
    return isoString;
  };

  // Getting the To Current Time
  const ToCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T23:59`;
    return isoString;
  };
  const [advancedFilters, setAdvancedFilters] = useState([
    {
      direction: "from",
      dataType: "date",
      fieldName: "startTime",
      value: FromCurrentDateTime(),
    },
    {
      direction: "to",
      dataType: "date",
      fieldName: "startTime",
      value: ToCurrentDateTime(),
    },
  ]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onFileExport: onOpenBackupBox,
        onMultipleDelete: onOpenBackupDeleteBox,
        DownloadData: onUpdateDataDownload,
        UpdateTableRecords: DownloadData,
        DownloadPdf: DownloadPdf,
        onRefresh: onRefresh,
      });
  }, [activePage, tableValue]);

  // Refresh Function
  const onRefresh = () => {
    setUpdateList(Date.now);
  };

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  function DownloadData() {
    setOpenForm(true);
  }
  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };
  function onCloseForm() {
    setOpenForm(false);
  }

  // for Download CSV Report
  function onUpdateDataDownload() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
    ) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "warning",
        status: "You cann't download more that 10 column in pdf mode",
        message: "Please select 10 relevant column using Coumn Filer option",
      });
    }
    setLoading(true);
    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };

    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(operationURLS.csvDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Voice-Picker.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "CSV Download successful",
            type: "success",
          });
        }
      })

      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleFileName = (e) => {
    const value = e.target.value.trim();
    if (value) {
      setfileName(value);
    }
  };

  function handleCloseBackupBox() {
    setOpenBackupBox(false);
  }

  function onOpenBackupBox() {
    application
      .post(operationURLS.status)
      .then((response) => {
        if (response?.data?.status === "OK") {
          if (advancedFilters && Object.keys(advancedFilters).length > 0) {
            setOpenBackupBox(true);
          } else {
            setNotify({
              isOpen: true,
              status: "Please add First Date Filter",
              type: "error",
              pagename: pageName,
            });
          }
        } else {
          setNotify({
            isOpen: true,
            status: response.data.status === "ERROR" && response.data.message,
            type: "error",
            pagename: pageName,
          });
        }
      })
      .catch((error) => {
        loggerFactory.debug("Unable to fetch status for backup", error);
        setNotify({
          isOpen: true,
          status:
            error.response.data.status === "ERROR" &&
            error.response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
    setBackup(true);
    setBackupDelete(false);
  }

  function onOpenBackupDeleteBox() {
    if (advancedFilters.length < 0) {
      setNotify({
        isOpen: true,
        status: "Please First add Date Filter",
        type: "error",
        pagename: pageName,
      });
      return;
    }
    setBackup(false);
    application
      .post(operationURLS.status)
      .then((response) => {
        if (response?.data?.status === "OK") {
          if (advancedFilters && Object.keys(advancedFilters).length > 0) {
            setOpenBackupBox(true);
          } else {
            setNotify({
              isOpen: true,
              status: "Please add First Date Filter",
              type: "error",
              pagename: pageName,
            });
          }
        } else {
          setNotify({
            isOpen: true,
            status: response.data.status === "ERROR" && response.data.message,
            type: "error",
            pagename: pageName,
          });
        }
      })
      .catch((error) => {
        loggerFactory.debug("Unable to fetch status for backup", error);
        setNotify({
          isOpen: true,
          status:
            error.response.data.status === "ERROR" &&
            error.response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  }

  function onOpenFilter() {
    setAdvancedFilters([]);
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  const onSelectionChange = (id) => {
    setSelectedRows(id);
  };
  // For Delete With  Backup Recording File
  function DeleteWithBackup(data) {
    const url = RowDelete ? operationURLS.delete : operationURLS.create;
    application
      .post(url, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: `${response.data.message} ${response.data.messageDetail}`,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
  }
  // Only Backup
  function BackupFile() {
    let fromValue = null;
    let toValue = null;
    let fromCallerIdNumber = null;
    let toCallerIdNumber = null;
    let fromDestinationNumber = null;
    let toDestinationNumber = null;

    advancedFilters.forEach((filter) => {
      if (filter.dataType === "date" && filter.fieldName === "startTime") {
        if (filter.direction === "from") {
          fromValue = filter.value;
        } else if (filter.direction === "to") {
          toValue = filter.value;
        }
      } else if (
        filter.dataType === "string" &&
        filter.fieldName === "callerIdNumber"
      ) {
        if (filter.direction === "from") {
          fromCallerIdNumber = filter.value;
        } else if (filter.direction === "to") {
          toCallerIdNumber = filter.value;
        }
      } else if (
        filter.dataType === "string" &&
        filter.fieldName === "destinationNumber"
      ) {
        if (filter.direction === "from") {
          fromDestinationNumber = filter.value;
        } else if (filter.direction === "to") {
          toDestinationNumber = filter.value;
        }
      }
    });

    let requestData = {
      backupFilename: fileName,
      isDelete: false,
      from: fromValue,
      to: toValue,
      sortBy: sortColumnforbackup,
      search: searchText,
      fromCallerIdNumber: fromCallerIdNumber,
      toCallerIdNumber: toCallerIdNumber,
      fromDestinationNumber: fromDestinationNumber,
      toDestinationNumber: toDestinationNumber,
    };
    application
      .post(operationURLS.create, requestData)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: `${response.data.message} ${response.data.messageDetail}`,
          type: "success",
          pagename: pageName,
        });
        setOpenBackupBox(false);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);

    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
    setRowDelete(true);
  };

  // selected Delete With Backup
  const selectedDelete = () => {
    let fromValue = null;
    let toValue = null;
    let fromCallerIdNumber = null;
    let toCallerIdNumber = null;
    let fromDestinationNumber = null;
    let toDestinationNumber = null;

    advancedFilters.forEach((filter) => {
      if (filter.dataType === "date" && filter.fieldName === "startTime") {
        if (filter.direction === "from") {
          fromValue = filter.value;
        } else if (filter.direction === "to") {
          toValue = filter.value;
        }
      } else if (
        filter.dataType === "string" &&
        filter.fieldName === "callerIdNumber"
      ) {
        if (filter.direction === "from") {
          fromCallerIdNumber = filter.value;
        } else if (filter.direction === "to") {
          toCallerIdNumber = filter.value;
        }
      } else if (
        filter.dataType === "string" &&
        filter.fieldName === "destinationNumber"
      ) {
        if (filter.direction === "from") {
          fromDestinationNumber = filter.value;
        } else if (filter.direction === "to") {
          toDestinationNumber = filter.value;
        }
      }
    });

    let data = {
      backupFilename: fileName,
      isDelete: true,
      from: fromValue,
      to: toValue,
      sortBy: sortColumnforbackup,
      search: searchText,
      fromCallerIdNumber: fromCallerIdNumber,
      toCallerIdNumber: toCallerIdNumber,
      fromDestinationNumber: fromDestinationNumber,
      toDestinationNumber: toDestinationNumber,
    };
    DeleteWithBackup(data);
    setOpenBackupBox(false);
  };

  // single Delette in Table
  const onAgree = (selectedRow) => {
    const data = { id: [selectedRow.id] };
    DeleteWithBackup(data);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setConfirmDelete(false);
  };

  const updateColumns = (selectedItems) => {
    setDeleteFunction(false);
    const updatedColumns = getColumns().map((column) => {
      return {
        ...column,
        displayInList: selectedItems.includes(column.accessor),
      };
    });
    setColumns(updatedColumns);

    selectedColumnDownload.current = selectedItems;
  };

  // for Download pdf
  function DownloadPdf() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
    ) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "warning",
        status: "You cann't download more that 10 column in pdf mode",
        message: "Please select 10 relevant column using Coumn Filer option",
      });
    } else if (selectedColumnDownload.current.length > 10) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "error",
        status: "Select maximum of 10 columns",
      });
    }
    setLoading(true);
    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
      params: {
        reportType: "voiceRecordingPdf",
      },
    };
    application
      .post(operationURLS.pdfDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Voice-Picker.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "PDF Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleClick = () => {
    if (backupDelete && fileName) {
      selectedDelete();
    } else if (fileName) {
      BackupFile();
    }
  };

  return (
    <MDBox>
      <CTLSelectedDownload
        openForm={openForm}
        columns={getColumns()}
        pageName={pageName}
        onCloseForm={onCloseForm}
        onUpdate={updateColumns}
      />
      <DeleteConfirmation
        entityName="Backup & Download "
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLTable
        onSelectionChange={onSelectionChange}
        apiURL={operationURLS.list}
        columns={deletefunction ? getColumns(deleteRow, editRow) : columns}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="startTime"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />

      <CTLAdvancedFilter
        entityName="Backup & Download"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <Dialog open={openBackupBox} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          {backupDelete
            ? "Backup With Delete Recording File "
            : "Only Backup Recording File"}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <FormField
            placeholder={"Enter The Backup File Name"}
            autoFocus
            label="Enter The Backup File Name"
            onChange={(e) => handleFileName(e)}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleCloseBackupBox} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={handleClick} color="success" autoFocus>
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default BackupRecordingFile;
