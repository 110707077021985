 import Icon from "@mui/material/Icon";
 import Tooltip from "@material-ui/core/Tooltip";

 const chatMenus = [
   {
     icon: (
       <Tooltip title="Chat">
         <Icon fontSize="medium">chat</Icon>
       </Tooltip>
     ),
     name: "chat",
     key: "chat",
     url: "home/App/chat",
     description: "chat",
   },
 ];

export { chatMenus };
