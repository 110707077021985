import MDTypography from "components/MDTypography";
import { Box, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

function RowColorCell({ value, suffix, row }) {
  const DueDate = row.original.dueDate;
  const taskCompletedDate = row.original.taskCompletedOn;
  const currentDateTime = new Date();
  const expectedDate = new Date(DueDate);
  let backgroundColor = "transparent";
  let iconColor = "transparent";
  const hasValidValue = value !== null && value !== "";

  const colorTooltips = {
    "#FF474C": "Task In Progress With Delay",
    red: "Task Not Completed onTime",
    green: "Task Completed onTime",
    orange: "Task In Progress With onTime",
  };

  if (hasValidValue) {
    if (taskCompletedDate) {
      if (taskCompletedDate && DueDate >= taskCompletedDate) {
        backgroundColor = "green";
        iconColor = "green";
      } else {
        backgroundColor = "red";
        iconColor = "red";
      }
    } else {
      if (expectedDate <= currentDateTime) {
        backgroundColor = "#FF474C";
        iconColor = "#FF474C";
      } else {
        backgroundColor = "orange";
        iconColor = "orange";
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        padding: "8px",
        borderRadius: "0px",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip title={colorTooltips[backgroundColor] || ""} arrow>
        <CircleIcon
          sx={{ color: iconColor, fontSize: 20, marginRight: "8px" }}
        />
      </Tooltip>
      <Tooltip title={value && value.length > 30 ? value : ""} arrow>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {value && value.length > 30 ? `${value.substring(0, 30)}...` : value}
          {suffix && (
            <MDTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              &nbsp;&nbsp;{suffix}
            </MDTypography>
          )}
        </MDTypography>
      </Tooltip>
    </Box>
  );
}

RowColorCell.defaultProps = {
  suffix: "",
};

export default RowColorCell;
