import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
   modes: groupAdmin ? groupAdminMenu.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
   }):[],
  settingsMode: false,
};

const operationURLS = {
  list: "/distortionFile/list",
  update: "distortionFile/update",
  create: "distortionFile/create",
  delete: "distortionFile/delete",
};

const getColumns = (onDelete) => {
  return [
    {
      Header: "File Name ",
      accessor: "audioName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File Path",
      accessor: "path",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Announcement",
      accessor: "file",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "simpleFileUpload",
      fileTypeAccept: ".wav",
      uploadUrl: "/distortionFile/upload",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onDelete={onDelete} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
