import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import { Divider, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import NonQueueAgent from "./nonQAgent";
import QueueAgent from "./qAgent";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import CTLDialogContent from "application/components/styles/CTLDialogContent";

const operationURLSS = {
  nonAssociatedToAssociated: "/agent/updateTiers",
  associatedToNonAssociated: "/agent/deleteTiers",
};

function AgentSetting({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, activePage, ivrExtension } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [saveData, setSaveData] = useState();
  const [queueupdateList, setQueueUpdateList] = useState(Date.now());
  const [queueAgent, setQueueAgent] = useState([]);
  const [nonQueueAgent, setNonQueueAgent] = useState([]);
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onSaveForm() {
    let existingQueue = ivrExtension?.code?.queue;
    let newData = { ...saveData, existingQueue: existingQueue };
    application
      .post(operationURLS.agentCreate, newData)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response?.data?.messageDetail,
          type: "success",
          pagename: pageName,
        });
        setQueueUpdateList(Date.now());
        handleClose();
        onCloseForm();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
  }

  const handleOpen = (data) => {
    setSaveData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function onCloseForm() {
    setOpenForm(false);
  }

  // Non Associated Agent Move to associated Agent List
  function NonAssociatedAgent() {
    const requestBodyData = {
      name: nonQueueAgent,
      queue: ivrExtension?.code?.queue,
    };
    if (nonQueueAgent.length >= 1) {
      application
        .post(operationURLSS.nonAssociatedToAssociated, requestBodyData)
        .then((response) => {
          setQueueUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
          setNonQueueAgent([]);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      setNotify({
        isOpen: true,
        message: "Error: Please Select at Least One Non Associated Agent",
        type: "error",
        pagename: pageName,
      });
    }
  }

  // Associated Agent Move to Non Associated Agent List
  function AssociatedAgent() {
    const requestBodyData = {
      intId: queueAgent,
    };

    if (queueAgent.length >= 1) {
      application
        .post(operationURLSS.associatedToNonAssociated, requestBodyData)
        .then((response) => {
          setQueueUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
          setNonQueueAgent([]);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      setNotify({
        isOpen: true,
        message: "Error: Please Select at Least One Associated Agent",
        type: "error",
        pagename: pageName,
      });
    }
  }
  useEffect(() => {
    setQueueUpdateList(Date.now());
  }, [ivrExtension]);
  return (
    <>
      <MDBox>
        <tooltip title="Create Agent">
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              color: "error",
              marginRight: "45px",
            }}
          >
            <tooltip title="Create Agent">
              <IconButton color="error" onClick={() => onOpenForm()}>
                <AddCircleOutlineIcon />
              </IconButton>
            </tooltip>
          </div>
        </tooltip>
        <CTLBaseDialogForm
          entityName="Call-Center:Agent"
          columns={getColumns(null, null)}
          openForm={openForm}
          onSaveForm={handleOpen}
          onCloseForm={onCloseForm}
          formData={formData}
          pageAttributes={Attributes}
        />

        <Stack direction="row" spacing={1}>
          <Card
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              maxHeight: "calc(100vh - 160px)",
              minHeight: "calc(100vh - 160px)",
              marginLeft: 20,
              marginRight: 5,
              width: "50%",
            }}
          >
            <MDTypography
              sx={{
                marginBottom: 2,
                textAlign: "center",
              }}
            >
              Queue-Associated-Agent
            </MDTypography>
            {ivrExtension && (
              <QueueAgent
                onLoad={onLoad}
                registerCallBacks={registerCallBacks}
                pageId={pageId}
                searchText={searchText}
                pageName={pageName}
                setQueueAgent={setQueueAgent}
                queueupdateList={queueupdateList}
              />
            )}
          </Card>

          <Card style={{ marginTop: "180px" }}>
            <Stack direction="column" spacing={1}>
              <tooltip title="Move Right To Left">
                <MDBox>
                  <IconButton
                    color="success"
                    onClick={() => NonAssociatedAgent()}
                  >
                    <ArrowCircleLeftTwoToneIcon
                      fontSize="medium"
                      color="warning"
                    />
                  </IconButton>
                </MDBox>
              </tooltip>
              <Divider />
              <tooltip title="Move Left To Right">
                <MDBox>
                  <IconButton color="success" onClick={() => AssociatedAgent()}>
                    <ArrowCircleRightTwoToneIcon
                      fontSize="medium"
                      color="warning"
                    />
                  </IconButton>
                </MDBox>
              </tooltip>
            </Stack>
          </Card>

          <Card
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              maxHeight: "calc(100vh - 160px)",
              minHeight: "calc(100vh - 160px)",
              marginLeft: 15,
              width: "50%",
            }}
          >
            <MDTypography
              sx={{
                marginBottom: 2,
                textAlign: "center",
              }}
            >
              Without-Queue-Associated-Agent
            </MDTypography>
            {ivrExtension && (
              <NonQueueAgent
                onLoad={onLoad}
                registerCallBacks={registerCallBacks}
                pageId={pageId}
                searchText={searchText}
                pageName={pageName}
                setNonQueueAgent={setNonQueueAgent}
                queueupdateList={queueupdateList}
              />
            )}
          </Card>
        </Stack>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          classes={{ dialog }}
        >
          <DialogTitle
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            id="alert-dialog-title"
          >
            {!ivrExtension?.code?.id ? (
              <>
                You Have Not Selected Any Queue?
                <br />
                Are You Sure You Want To Create Agent?
              </>
            ) : (
              ""
            )}
          </DialogTitle>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={handleClose} color="secondary">
              Cancel
            </MDButton>
            <MDButton onClick={onSaveForm} color="error" autoFocus>
              Apply
            </MDButton>
          </DialogActions>
        </Dialog>
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>
    </>
  );
}

export default AgentSetting;
