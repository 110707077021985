import { useState } from "react";

// MUI imports
import { Fab, TextField } from "@mui/material";

// Template components
import MDBox from "components/MDBox";

// Customer support components
import SendIcon from "@mui/icons-material/Send";

function ChatActions({ handleSendAgentMessage }) {
  const [inputTextMessage, setInputTextMessage] = useState("");

  return (
    <MDBox
      display="flex"
      component="form"
      onSubmit={(event) => {
        handleSendAgentMessage(event, inputTextMessage);
        setInputTextMessage("");
      }}
      mt={2}
    >
      <TextField
        label="Type a message"
        variant="outlined"
        value={inputTextMessage}
        onChange={(event) => setInputTextMessage(event.target.value)}
        // multiline
        maxRows={3}
        focused
        autoFocus
        placeholder="Type a message"
        size="medium"
        sx={{ flexGrow: 1, marginRight: ".8em" }}
      />
      <Fab type="submit" color="info" size="small">
        <SendIcon fontSize="small" />
      </Fab>
    </MDBox>
  );
}

export default ChatActions;
