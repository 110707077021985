import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import CTLNotification from "application/components/CTLNotification";
import TeamleadNotAssociatedAgent from "./TeamLeadNotAssociatedAgent";
import { Grid, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { application } from "globals/endpoints";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";

function TeamleadAgentAssociation({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const [updateList, setUpdateList] = useState(Date.now());
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedNotAssociatTL, setSelectedNotAssociatTL] = useState([]);
  const [updateNotAssociateTL, setUpdateNotAssociatTL] = useState(Date.now);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  useEffect(() => {
    setUpdateList(Date.now());
    setUpdateNotAssociatTL(Date.now);
  }, [ivrExtension]);

  const QAgentoperationURLS = {
    AssociatedTeamleaderList: `/agent/listTL/${ivrExtension.code?.userName}`,
    updateAssociatTL: `/agent/update/reportingHeadBlank`,
    updateNotAssociatTL: `/agent/update/reportingHead`,
  };

  const onSelectionChange = (rowId) => {
    setSelectedRow(rowId);
  };
  // Update Queue Associated TeamLeader Agent
  const UpdateAssociatedAgentTL = () => {
    if (selectedRow.length === 0) {
      setNotify({
        isOpen: true,
        status:
          "Error : Please First select the Agent from Queue  Associated agent Table",
        type: "error",
        pagename: "Agent TeamLead Association",
      });
      return;
    }
    let data = {
      name: selectedRow,
    };
    application
      .post(QAgentoperationURLS.updateAssociatTL, data)
      .then((response) => {
        setUpdateList(Date.now);
        setUpdateNotAssociatTL(Date.now);
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: "Agent TeamLead Association",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.messageDetail,
          type: "error",
          pagename: "Agent TeamLead Association",
          message: error.response.data.message,
        });
      });
  };

  // Update Whithout Queue Associated TeamLeader Agent
  const UpdateNotAssociatedAgentTL = () => {
    if (!ivrExtension?.code?.userName && selectedNotAssociatTL.length === 0) {
      setNotify({
        isOpen: true,
        status:
          "Please Check the Teamleader User Dropdown and Select an Agent in table",
        type: "error",
        pagename: "Agent TeamLead Association",
      });
      return;
    } else if (!ivrExtension?.code?.userName) {
      setNotify({
        isOpen: true,
        status: "Error:Please select the Teamleader User from dropdown",
        type: "error",
        pagename: "Agent TeamLead Association",
      });
      return;
    } else if (selectedNotAssociatTL.length === 0) {
      setNotify({
        isOpen: true,
        status:
          "Please First select the Agent from Without Queue Associated Table",
        type: "error",
        pagename: "Agent TeamLead Association",
      });
      return;
    }
    let data = {
      name: selectedNotAssociatTL,
      reportingHead: ivrExtension?.code?.userName,
    };
    application
      .post(QAgentoperationURLS.updateNotAssociatTL, data)
      .then((response) => {
        setUpdateNotAssociatTL(Date.now);
        setUpdateList(Date.now);
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: "Agent TeamLead Association",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.messageDetail,
          type: "error",
          pagename: "Agent TeamLead Association",
          message: error.response.data.message,
        });
      });
  };

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MDTypography>Associated Agent With TeamLeader </MDTypography>
          </MDBox>
          <MDBox style={{ height: "590px", overflow: "auto" }}>
            <CTLTable
              apiURL={QAgentoperationURLS.AssociatedTeamleaderList}
              onSelectionChange={onSelectionChange}
              columns={getColumns(UpdateAssociatedAgentTL)}
              entriesPerPage={false}
              canSearch
              defaultSortDirection="asc"
              defaultSortColumn="name"
              pageId={pageId}
              searchText={searchText}
              hasRowSelection={true}
              updateList={updateList}
              disablePagination={true}
            />
          </MDBox>
        </Grid>

        {/* Grid for Icon Buttons */}
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <tooltip title="Associated Agent">
            <IconButton color="error" onClick={() => UpdateAssociatedAgentTL()}>
              <ArrowCircleRightTwoToneIcon fontSize="medium" color="warning" />
            </IconButton>
          </tooltip>

          <tooltip title="DeAssociated Agent">
            <IconButton
              color="error"
              onClick={() => UpdateNotAssociatedAgentTL()}
            >
              <ArrowCircleLeftTwoToneIcon fontSize="medium" color="warning" />
            </IconButton>
          </tooltip>
        </Grid>

        {/* Second Grid for without Queue Associated Agent TeamLeader */}
        <Grid item xs={5}>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MDTypography>
              without Associated Agent With TeamLeader 
            </MDTypography>
          </MDBox>
          <TeamleadNotAssociatedAgent
            onLoad={onLoad}
            pageId={pageId}
            searchText={searchText}
            registerCallBacks={registerCallBacks}
            updateNotAssociateTL={updateNotAssociateTL}
            setSelectedNotAssociatTL={setSelectedNotAssociatTL}
          />
        </Grid>
      </Grid>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default TeamleadAgentAssociation;
