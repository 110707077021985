import { Height } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EmptyImage from "../../../assets/images/mediacenter/emptyFolder.svg";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import HospitalComponent from "./HospitalComponent";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { v4 as uuidv4 } from "uuid";
import { useGetHospitalJSONQuery } from "features/hospitalSlice";
import { useChangeHospitalJSON } from "./useChangeHospitalJSON";

const index = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [entityName, setEntityName] = useState("");
  const [openCreateTableDiologue, setOpenCreateTableDiologue] = useState(false);
  const [createGroup, setCreateGroup] = useState("");
  const [navigateToMain, setNavigatetoMain] = useState(false);
  const [folders, setFolders] = useState([]);
  // const [getHospitalJSON, { isLoading, isSuccess, isError, data }] =
  //   useGetHospitalJSONMutation();
  const { changeHospitalJSON } = useChangeHospitalJSON();
  const { data, isLoading, isSuccess, isError } = useGetHospitalJSONQuery();
  console.log("data", data);
  const handleAddFolderAtParent = async (type, name) => {
    const newFolder = {
      id: uuidv4(),
      name: type === "company" ? name : "Company",
      type: type,
      scheduleIds: [],
      parentId: "",
      children: [],
    };
    await changeHospitalJSON([newFolder], `created ${type}`);
    setFolders((prevFolder) => [...prevFolder, newFolder]);
  };

  const handleNewData = () => {
    setOpenCreateTableDiologue(true);
  };

  const handleClose = () => {
    setOpenCreateTableDiologue(false);
  };

  const handleChangeEntityName = (e) => {
    setCreateGroup(e.target.value);
  };

  const handleSaveGroup = () => {
    // Add a new row with default values
    if (createGroup !== "") {
      //   const newRow = {
      //     id: rows.length + 1,
      //     type: createGroup ? createGroup : "Enter Group Name",
      //     name: createName ? createName : " Enter Name",
      //     ENG: "",
      //     HIN: "",
      //   };
      //   // Update the rows and tableProps
      //   setRows([...rows, newRow]);
      //   // Add editable cells for the new row
      //   const newEditableCells = columns.map((column) => ({
      //     columnKey: column.key,
      //     rowKeyValue: newRow.id,
      //   }));
      //   const filteredEditableCells = newEditableCells.filter(
      //     (item) => item.columnKey !== ":delete"
      //   );
      //   changeTableProps((prevState) => ({
      //     ...prevState,
      //     data: [...prevState.data, newRow],
      //     editableCells: [...prevState.editableCells, ...filteredEditableCells],
      //   }));
      //   // Reset the input fields
      setEntityName(createGroup);
      handleAddFolderAtParent("company", createGroup);
      setCreateGroup("");
      setOpenCreateTableDiologue(false);
      setNavigatetoMain(true);
      setMiniSidenav(dispatch, true);
    } else {
      alert("Please fill the input fields");
    }
  };

  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   getHospitalJSON();
  // }, [getHospitalJSON]);

  useEffect(() => {
    // Update folders state when data is successfully fetched
    if (isSuccess) {
      console.log("dataaa", data)
      const newFolders = JSON.parse(JSON.stringify(data));
      setFolders(newFolders);
    }
  }, [isSuccess]);

  return (
    <>
      {data?.length > 0 || folders?.length > 0 ? (
        <HospitalComponent
          entityName={entityName}
          folders={folders}
          setFolders={setFolders}
        />
      ) : (
        <Box
          sx={{
            width: "full",
            height: "93vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0",
            padding: "0",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <img src={EmptyImage} alt="Empty Folder" />
              </Box>
              <MDTypography
                variant="h4"
                sx={{
                  fontWeight: "400",
                }}
                textAlign="center"
              >
                You haven't configured anything yet
              </MDTypography>
              <MDTypography variant="p">
                Click on the button below to add{" "}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ marginTop: "3em" }}
                onClick={handleNewData}
              >
                Add Data
              </MDButton>
            </>
          )}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCreateTableDiologue}
        onClose={handleClose}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        >
          {/* Add Details */}
        </DialogTitle>

        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
            padding: "10px 30px 20px 30px",
          }}
        >
          <MDInput
            fullWidth
            sx={{ textAlign: "center" }}
            size="large"
            label="Node Name"
            autoFocus
            // placeholder="eg. St. Louis Hospital"
            variant="outlined"
            onChange={handleChangeEntityName}
            inputProps={{ autoComplete: "off" }}
          />
          {/* <MDInput
            size="large"
            label="Row Name"
            variant="outlined"
            onChange={handleChangeGroupeName}
          /> */}
        </MDBox>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSaveGroup}
          >
            Save
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default index;
