import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const emailMenus = [
  {
    icon: (
      <Tooltip title="Email">
        <Icon fontSize="medium">email_icon</Icon>
      </Tooltip>
    ),
    name: "Email Configuration",
    key: "emailConfiguration",
    url: "/app/home/emailConfiguration",
    description: "Email Configuration",
    productionReady: false,
  },
];

export { emailMenus };
