import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function CTLStatus({ row, isFromDate, isToDate }) {
  const getCurrentDateTime = () => new Date();

  const toDate = new Date(row?.original?.toDateTime);
  const currentDateTime = getCurrentDateTime();

  const isActive = toDate > currentDateTime;

  function formatDate(dateString) {
    console.log(dateString);
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${day}/${month}/${year} - ${hours}:${minutes} ${ampm}`;
  }

  return (
    <MDTypography
      variant="caption"
      fontWeight="large"
      display="flex"
      alignItems="center"
    >
      {isFromDate ? (
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {formatDate(row?.original?.fromDateTime)}
        </MDTypography>
      ) : isToDate ? (
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {formatDate(row?.original?.toDateTime)}
        </MDTypography>
      ) : isActive ? (
        <>
          <Icon color="success" fontSize="large" sx={{ marginRight: "4px" }}>
            <AccessTimeIcon />
          </Icon>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            Active Shift
          </MDTypography>
        </>
      ) : (
        <>
          <Icon color="error" fontSize="large" sx={{ marginRight: "4px" }}>
            <AccessTimeIcon />
          </Icon>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            Expired Shift
          </MDTypography>
        </>
      )}
    </MDTypography>
  );
}

export default CTLStatus;
