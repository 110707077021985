import DefaultCell from "application/components/DefaultCell";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";


const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "callDispositionQueue/list",
  pdfDownload: "/callDispositionQueue/generateAndDownloadPdf",
  csvDownload: "callDispositionQueue/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue",
      accessor: "queueId",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent ID",
      accessor: "agentId",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "agentName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      sortDisable:true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Disposition",
      accessor: "customerStatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Record Date",
      accessor: "recordCreatedOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Next Action Date",
      accessor: "recordLastModifiedOn",
      displayInList: true,
      allowFilter: false,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
