export const cosAttributes = {
  inbound: [
    {
      name: "cos",
      value: "",
      icon: "TextField",
    },
    {
      name: "dnd",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "vm",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cfa",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cfna",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cfb",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "pr",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "fm",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "caf",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cb",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "id",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "restrictfeatureaccess",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "acr",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "snmptrapenabledon",
      value: false,
      icon: "TextField",
    },
    {
      name: "callback_on_busy",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "callback_on_noanswer",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "callback_on_notregistered",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "busyindication",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "overrideibedtmf",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "allowgd",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "allowtrunkinwardcall",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "unma",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "campon",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "autorepeatdial",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "allowbossonly",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "inboudcos_pkey",
      value: false,
      icon: "Checkbox",
    },
  ],
  outbound: [
    {
      name: "cos",
      value: "",
      icon: "TextField",
    },
    {
      name: "isd",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "std",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "loc",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "ic",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cpe",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cpg",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "arb",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "vmacs",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "sep",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cf",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "bgn",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "id",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "gwoutbound",
      value: false,
      icon: "TextField",
    },
    {
      name: "ibe",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "outboundgroupgw",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "acdpickup",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "parkconf",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "trunktotrunktrfr",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "conferencebeep",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "extensiontransfer",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "isconferencemoderator",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cliinternalblock",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "cliexternalblock",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "secretarybypass",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "trunkbargin",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "campon",
      value: "",
      icon: "TextField",
    },
    {
      name: "allowpa",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "allowcallbackafterhold",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "allowemergencyconf",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "paging",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "allowtenant",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "whisper",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "gconfcall",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "dropcall",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "snmptrapenabledon",
      value: false,
      icon: "TextField",
    },
    {
      name: "cliforwardontransfer",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "overrideibedtmf",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "rdl",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "tac",
      value: false,
      icon: "TextField",
    },
    {
      name: "allowextensionservice",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "cb",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "trunkfailover",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "callpark",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "longtalkbeep",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "transferrecall",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "allowbgm",
      value: true,
      icon: "Checkbox",
    },
    {
      name: "allowgd",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "forceddnd",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "manualdnd",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "callforwardexternal",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "ard",
      value: false,
      icon: "Checkbox",
    },
    {
      name: "outboundcos_pkey",
      value: false,
      icon: "Checkbox",
    },
  ],
};
