import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import MenuComponent from "./MenuComponent";
import AlertDelete from "./AlertsDialogs/AlertDelete";
import FileViewer from "./FileViewer/FileViewer";
import { enqueueSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Schedule from "./schedule/Schedule";
import { List, IconButton, Tooltip } from "@mui/material";
import Folder from "./Folder";
import ClassOfService from "./ClassOfServices/ClassOfService";
import optionsData from "./options.json";
import { useChangeHospitalJSON } from "./useChangeHospitalJSON";

const FileExplorerWrapper = styled("div")({
  display: "flex",
  alignItems: "stretch",
  color: "white",
  height: "100%",
});

const FileNavigator = styled("div")(({ theme }) => ({
  flex: "flex",
  minWidth: "20em",
  padding: 0,
}));

const ResizableSidebar = styled("div")({
  width: "333px",
  height: "100%",
  overflowX: "hidden",
  position: "relative",
});

const Resizer = styled("div")({
  width: "5px",
  top: 0,
  right: 0,
  cursor: "col-resize",
  height: "95%",
  position: "absolute",
  backgroundColor: "#7d7d7d",
});

const FileViewerStyle = styled("div")({
  marginLeft: "1em",
  flex: "1 1 auto",
  padding: (theme) => theme.spacing(2),
});

// const featureList = [
//   {
//     icon: <MarkunreadMailboxIcon />,
//     text: "Code Blue",
//   },
//   {
//     icon: <MarkunreadMailboxIcon />,
//     text: "IVR",
//   },
//   {
//     icon: <MarkunreadMailboxIcon />,
//     text: "Call Center",
//   },
// ];

const FileExplorer = ({ entityName, folders, setFolders }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [width, setWidth] = useState(333);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isHovering, setisHovering] = useState(false);
  const [splitterPosition, setSplitterPosition] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderPath, setFolderPath] = useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [currentPage, setCurrentPage] = useState("Home");
  const [path, setPath] = useState([]);
  const [featureListSelected, setFeatureListSelected] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [featureList, setFeatureList] = useState(optionsData?.features || []);
  const { changeHospitalJSON } = useChangeHospitalJSON();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFolderEdit = (folderId, newName, newNumber, foldersToUpdate) => {
    return foldersToUpdate.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, name: newName, number: newNumber };
      }
      if (folder.children && folder.children.length > 0) {
        return {
          ...folder,
          children: handleFolderEdit(
            folderId,
            newName,
            newNumber,
            folder.children
          ),
        };
      }
      return folder;
    });
  };

  const handleFolderEditAndSave = (folderId, newName, newNumber) => {
    const updatedFolder = handleFolderEdit(
      folderId,
      newName,
      newNumber,
      folders
    );
    if (updatedFolder) {
      setFolders(updatedFolder);
      changeHospitalJSON(updatedFolder, "updated");
    }
  };
  const handleFolderDelete = (folder) => {
    const folderId = folder?.id;
    const parentId = folder?.parentId;
    const deleteFolder = (newFolders) => {
      for (let i = 0; i < newFolders.length; i++) {
        if (newFolders[i].id === folderId) {
          if (newFolders[i].children && newFolders[i].children.length > 0) {
            enqueueSnackbar(
              `You cannot delete ${newFolders[i]?.name} because it has children `,
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
            return false;
          }
          const deletedFolder = newFolders[i];
          newFolders.splice(i, 1);
          setIsDelete(false);

          // if (deletedFolder?.type !== "extension") {
          //   enqueueSnackbar(`Successfully Deleted ${deletedFolder.name}`, {
          //     variant: "success",
          //     anchorOrigin: {
          //       vertical: "top",
          //       horizontal: "right",
          //     },
          //   });
          // }
          // Check if parent needs to be deleted
          if (deletedFolder?.name === "number") {
            const parentFolder = findFolderById(folders, parentId);

            if (
              parentFolder?.type === "extension" &&
              parentFolder &&
              parentFolder.children.length === 0
            ) {
              handleFolderDelete(parentFolder);
            }
          }
          return true;
        }
        if (newFolders[i].children) {
          if (deleteFolder(newFolders[i].children)) return true;
        }
      }
      return false;
    };

    setFolders((prevFolders) => {
      const newFolders = [...prevFolders];
      if (deleteFolder(newFolders)) {
        changeHospitalJSON(newFolders, "deleted");
      }
      return newFolders;
    });
    setIsDelete(false);
  };

  const findFolderById = (folders, id) => {
    for (let folder of folders) {
      if (folder.id === id) {
        return folder;
      }
      if (folder?.children?.length > 0) {
        const result = findFolderById(folder.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleMouseDown = (e) => {
    const initialX = e.clientX;

    const handleMouseMove = (event) => {
      const dx = event.clientX - initialX;
      const newWidth = width + dx;
      if (newWidth >= 100) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleConfirmDelete = () => {
    setIsDelete(true);
  };
  const handleCurrentPage = (e, type) => {
    // alert("How Are yuou?")
    setCurrentPage(type);
    // setAnchorContextMenu(null);
  };

  // const handleAddParent = (event) => {
  //   setisHovering(true);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleAddBuilding = () => {
  //   handleCloseMenu();
  //   handleAddFolderAtParent("building");
  // };

  // const handleAddFloor = () => {
  //   handleCloseMenu();
  //   handleAddFolderAtParent("floor");
  // };

  // const handleAddRoom = () => {
  //   handleCloseMenu();
  //   handleAddFolderAtParent("room");
  // };

  const handleSelected = (e, folder) => {
    setCurrentFolder(folder);
    if (folder?.type === "services") {
      setCurrentPage(folder?.type);
    }
  };

  const getChildren = (currentFolder) => {
    const res =
      currentFolder?.children?.length > 0
        ? currentFolder?.children
        : currentFolder;
    return res;
  };

  useEffect(() => {
    const getPath = async (folder, currentPath) => {
      if (!folder) return; // Folder not found, exit recursion

      currentPath.unshift(folder.name); // Add current folder to the beginning of the path

      if (folder.parentFolderId) {
        // If the current folder has a parent folder, recursively get its path
        await getPath(folder.parentFolderId, currentPath);
      } else {
        // If no parent folder, we've reached the root folder (Home)
        setPath(currentPath);
      }
    };

    getPath(currentFolder, []);
  }, [currentFolder]);

  useEffect(() => {
    setCurrentPage(currentFolder?.type);
  }, [currentFolder, currentPage]);

  const handleFolderAdd = (parentId, type, roomNumber) => {
    const newFolder = {
      id: uuidv4(),
      name:
        roomNumber ||
        (type === "building"
          ? "New Building"
          : type === "floor"
          ? "New Floor"
          : type === "features"
          ? "Features"
          : roomNumber),
      // number: (roomNumber?.match(/\d+$/) || [])[0] || "",
      type: type,
      parentId: parentId,
      scheduleIds: [...currentFolder?.scheduleIds] || [],
      children: [],
    };

    const featureId = uuidv4();

    const newFeature = {
      id: featureId,
      name: "Features",
      type: type,
      parentId: parentId,
      scheduleIds: [...currentFolder?.scheduleIds] || [],
      children: featureList
        ?.filter((child) => featureListSelected?.[child.name])
        .map((selectedChild) => ({
          id: uuidv4(),
          name: selectedChild.name,
          type: selectedChild.name,
          parentId: featureId,
          scheduleIds: [...currentFolder?.scheduleIds] || [],
          children: [],
        })),
    };

    const newclassOfServices = {
      id: featureId,
      name: "Class of Services",
      type: type,
      parentId: parentId,
      COSid: "17254684354", // this will be the default id from the database table, so here the  developer can fetch default id and save it
    };

    const addChildFolder = (folders) => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === parentId) {
          if (type === "building" || type === "floor") {
            folders[i].children.push(newFolder);
          } else if (type === "features") {
            if (currentFolder?.type === "features") {
              currentFolder.children.push(...newFeature.children);
            } else {
              // check that parent has features if not then add it if yes then get that previous features and add the selected features to that
              const parentFolder = findFolderById(folders, parentId);
              const existingFeature = parentFolder.children.find(
                (child) => child.type === "features"
              );
              if (!existingFeature) {
                folders[i].children.unshift({ ...newFeature });
              } else {
                existingFeature.children.push(...newFeature.children);
              }
            }
          } else if (type === "services") {
            folders[i].children.unshift({ ...newclassOfServices });
          } else {
            folders[i].children.push({ ...newFolder, children: [] });
          }
          return true;
        }
        if (folders[i].children) {
          if (addChildFolder(folders[i].children)) return true;
        }
      }
      return false;
    };

    setFolders((prevFolders) => {
      const newFolders = [...prevFolders];
      addChildFolder(newFolders);
      // api for saving file to the database ..

      return newFolders;
    });
  };

  const findFolderPath = (folders, targetId, currentPath = []) => {
    for (const folder of folders) {
      // Add the current folder to the path
      const newPath = [...currentPath, folder];

      // If the current folder matches the target ID, return its path
      if (folder.id === targetId) {
        return newPath;
      }

      // If the current folder has children, recursively search through them
      if (folder.children && folder.children.length > 0) {
        const foundPath = findFolderPath(folder.children, targetId, newPath);
        // If the folder is found in the children, return its path
        if (foundPath) {
          return foundPath;
        }
      }
    }

    // If the target folder is not found in the current level or its children, return null
    return null;
  };

  useEffect(() => {
    if (folders && currentFolder) {
      const path = findFolderPath(folders, currentFolder?.id);
      setFolderPath(path);
    }
  }, [currentFolder]);

  const handleFolderClick = (folder) => {
    setCurrentFolder(folder);
  };

  const handleAddBuilding = () => {
    handleCloseMenu();
    // setIsOpen(true);
    handleFolderAdd(currentFolder.id, "building");
  };

  const handleAddFloor = () => {
    handleCloseMenu();
    handleFolderAdd(currentFolder.id, "floor");
  };

  const handleOpenAddForm = (e, type) => {
    setRoomInputDialog(type);
  };

  const pageRender = (type) => {
    const commonProps = {
      currentFolder,
      folderPath,
      isDelete,
      handleFolderClick,
      getChildren,
      setAnchorEl,
      handleSelected,
      findFolderById,
      folders,
    };
    switch (currentPage) {
      case "Home":
        return <FileViewer {...commonProps} />;
      case "Calendar":
        return (
          <Schedule
            folders={folders}
            setFolders={setFolders}
            currentFolder={currentFolder}
            schedules={schedules}
            setSchedules={setSchedules}
          />
        );
      case "services":
        return (
          <ClassOfService
            setCurrentFolder={setCurrentFolder}
            currentFolder={currentFolder}
            folders={folders}
          />
        );
      default:
        return <FileViewer {...commonProps} />;
    }
  };

  const handleBackButton = () => {
    const backFolder = findFolderById(folders, currentFolder?.parentId);
    setCurrentFolder(backFolder);
    if (backFolder?.type === "services") {
      setCurrentPage(backFolder.type);
    } else {
      setCurrentPage("Home");
    }
  };

  console.log("currentFolder", currentFolder);
  console.log("folders", folders);
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <ResizableSidebar style={{ width: width }}>
        <Resizer onMouseDown={handleMouseDown}></Resizer>
        <FileNavigator>
          <List component="nav" aria-label="main mailbox folders">
            {folders?.map((folder) => (
              <Folder
                key={folder.id}
                folder={folder}
                onFolderDelete={handleConfirmDelete}
                onFolderEdit={handleFolderEditAndSave}
                onFolderAdd={handleFolderAdd}
                level={0}
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                handleSelected={handleSelected}
                width={width}
                darkMode={darkMode}
                handleCurrentPage={handleCurrentPage}
                featureListSelected={featureListSelected}
                setFeatureListSelected={setFeatureListSelected}
                folders={folders}
                setFolders={setFolders}
                featureList={featureList}
              />
            ))}
          </List>
        </FileNavigator>
      </ResizableSidebar>
      <FileViewerStyle>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {(currentPage === "services" || currentPage === "Calendar") && (
            <>
              <Tooltip title="Back">
                <IconButton
                  onClick={handleBackButton}
                  sx={{ color: darkMode && "#fff" }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <MDTypography>
                {currentPage === "services" ? "Class of Services" : currentPage}
              </MDTypography>
            </>
          )}
        </MDBox>
        {pageRender()}
      </FileViewerStyle>
      {isDelete && (
        <AlertDelete
          name={currentFolder?.name}
          handleConfirmDelete={() => handleFolderDelete(currentFolder)}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          setCurrentFolder={setCurrentFolder}
        />
      )}
    </div>
  );
};
export default FileExplorer;
