import MDBox from "components/MDBox";
import { application } from "globals/endpoints";
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import loggerFactory from "globals/logger/logger-factory";
import CallIcon from "@mui/icons-material/Call";
import { setClickToCall } from "context";
import { useMaterialUIController } from "context";
import ChatIcon from "@mui/icons-material/Chat";
import CTLNotification from "application/components/CTLNotification";
import MDButton from "components/MDButton";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

const Attributes = {
  modes: [],
};

const ContactList = ({ onLoad, pageId, searchText, registerCallBacks }) => {
  const componentName = "Contact List";
   const userName = AuthenticationService.getUserName();
   const UserNumber = AuthenticationService.getExtensionNumber();
  const [contactList, setContactList] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [filteredContactList, setFilteredContactList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [message, setMessage] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const fetchContactList = async () => {
    try {
      const response = await application.post(`uc/phoneBook/listAll`);
      setContactList(response.data.data);
    } catch (error) {
      loggerFactory.debug("Unable to get Contact List ", error);
    }
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  useEffect(() => {
    if (searchText) {
      setFilteredContactList(
        contactList.filter(
          (contact) =>
            contact.offEXtn &&
            contact.offEXtn.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredContactList(contactList);
    }
  }, [searchText, contactList]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onClickToCall = (number) => {
    setClickToCall(dispatch, number);
  };

  const onClickToMessage = (data) => {
    setSelectedRow(data);
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setMessage("");
  };

  const activityLog = (
    event,
    eventCategory,
    eventStatus,
    callerNumber,
    destinationNumber,
    eventData
  ) => {
    const bodyData = {
      event: event,
      eventCategory: eventCategory,
      eventStatus: eventStatus,
      callerNumber: callerNumber,
      destinationNumber: destinationNumber,
      eventData: {
        msg: eventData,
      },
      userId: userName,
      deviceId: UserNumber,
      epochDate: Math.floor(Date.now() / 1000),
    };
    application.post("activityLog/create", bodyData);
  };

  const onMessageSend = () => {
    if (selectedRow) {
      const body = {
        extension: selectedRow,
        messageDetail: message,
      };
      application
        .post("chatMessage/sendMessageByExtension", body)
        .then((response) => {
          activityLog(
            "message",
            "Alert Message",
            "Send",
            UserNumber,
            selectedRow,
            message
          );
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: componentName,
            status: response.data.message,
          });
          setOpen(false);
          setMessage("");
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "",
            type: "error",
            pagename: componentName,
            status: error.response.data.message,
          });
          setOpen(false);
          setMessage("");
        });
    }
  };

  return (
    <div>
      <MDBox
        style={{
          position: "sticky",
          top: 60,
          zIndex: 1,
          paddingBottom: "10px",
        }}
      >
        <Card style={{ backgroundColor: "gray", padding: "10px" }}>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <MDTypography variant="h6">Call </MDTypography>
            </Grid>
            <Grid item xs={1}>
              <MDTypography variant="h6">Message </MDTypography>
            </Grid>
            <Grid item xs={4}>
              <MDTypography variant="h6">Contact Number</MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="h6">Contact Name</MDTypography>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox
        style={{
          height: "55vh",
          overflowY: "auto",
        }}
      >
        {filteredContactList?.length == 0 ? (
          <Card style={{ height: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="info" />
              &nbsp;&nbsp;
              <Typography variant="h6" color="text">
                Loading Contact List...
              </Typography>
            </Box>
          </Card>
        ) : (
          <Grid container spacing={2}>
            {filteredContactList &&
              filteredContactList.map((contact) => (
                <Grid item xs={12} key={contact.id}>
                  <Card>
                    <Box p={1}>
                      <Grid container alignItems="center">
                        <Grid item xs={1}>
                          <IconButton
                            color="info"
                            onClick={() => onClickToCall(contact.offEXtn)}
                          >
                            <CallIcon></CallIcon>
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            color="success"
                            onClick={() => onClickToMessage(contact.offEXtn)}
                          >
                            <ChatIcon></ChatIcon>
                          </IconButton>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="caption text">
                            {contact.offEXtn}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="caption text">
                            {contact.name}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
        <Dialog open={open} maxWidth="sm">
          <DialogTitle
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            id="alert-dialog-title"
          >
            Send Message
          </DialogTitle>
          <DialogContent
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            style={{ paddingTop: "10px" }}
          >
            <MDBox sx={{ width: 500 }}>
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                variant="outlined"
                label="Type Message"
                value={message}
                onChange={handleMessageChange}
                multiline
                rows={6}
              />
            </MDBox>
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={handleClickClose} color="secondary">
              Cancel
            </MDButton>
            <MDButton onClick={onMessageSend} color="success" autoFocus>
              Send
            </MDButton>
          </DialogActions>
        </Dialog>
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>
    </div>
  );
};

export default ContactList;
