import CTLDate from "application/components/CTLDate";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import DispatcherRowAction from "application/components/DispatcherRowAction";


const operationURLS = {
  list: "conferenceRoom/list/meetingStatus",
  csvDownload: "trainAlert/downloadCSV",
};

const getColumns = (onClickToConference) => {
  return [
    {
      Header: "Created By",
      accessor: "createdBy",
      allowFilter: false,
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Created On",
      accessor: "createdOn",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Device Type",
      accessor: "deviceType",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Device Number",
      accessor: "deviceNumber",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Zone",
      accessor: "zone",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Status",
      accessor: "mainStatus",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Closed By",
      accessor: "closedBy",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    // {
    //   Header: "Remarks",
    //   accessor: "closingRemarks",
    //   displayInList: true,
    //   allowFilter: false,
    //   allowInForm: false,
    //   dataType: "string",
    //   Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    // },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => (
        <DispatcherRowAction
          row={row}
          value={value}
          onClickToConference={onClickToConference}
        />
      ),
    },

    // {
    //   Header: "Destination Number",
    //   accessor: "destinationNumber",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    // },
  ];
};

export { getColumns, operationURLS };
