import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  TextField,
} from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function CTLBackupConfirm({
  confirmDelete,
  onAgree,
  onCancel,
  data,
}) {
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    setOpen(confirmDelete);
  }, [confirmDelete]);

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
    if (event.target.value) {
      setFileNameError(false);
    }
  };

  const handleDeleteFlagChange = (event) => {
    setDeleteFlag(event.target.checked);
  };

  const onSubmit = () => {
    if (fileName.trim() === "" || /\s/.test(fileName)) {
      setFileNameError(true);
      return;
    }
    onAgree(data, fileName, deleteFlag);
    setDeleteFlag(false);
    setFileName("");
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" classes={{ dialog }}>
      <DialogTitle
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        id="alert-dialog-title"
      >
        <MDTypography style={{ fontSize: "20px" }}>
          <Icon color="error" style={{ paddingTop: "5px" }} fontSize="medium">
            warning_amber
          </Icon>
          Enter the backup file name and select 'Delete' if desired
        </MDTypography>
      </DialogTitle>
      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <TextField
          fullWidth
          label="Backup File Name"
          variant="outlined"
          value={fileName}
          onChange={handleFileNameChange}
          margin="dense"
          required
          error={fileNameError}
          helperText={
            fileNameError
              ? "Backup file name is required and cannot contain spaces"
              : ""
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteFlag}
              onChange={handleDeleteFlagChange}
              color="primary"
            />
          }
          label="After backup, file deleted?"
        />
      </DialogContent>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <MDButton onClick={onCancel} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={onSubmit} color="success" autoFocus>
          Submit
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
