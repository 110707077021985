import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import MDTypography from "components/MDTypography";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";


const Attributes = {
  formSize: "lg",
  columns: 1,  
  enableAddButton: true,
  enableAdvanceSearch: true,
  settingsMode: false,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};
function getColorBox(value) {
    return (
      <div style={{ backgroundColor: value, width: "30px", height: "30px", borderRadius:"50%" }}>
    
      </div>
    );
  }
const operationURLS = {
    create: "department/create",
    update: "department/update",
    list: "department/list",
    delete: "department/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
        Header: "Department Name",
        accessor: "description",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        isUserGenerated:true,
        width:6,
        validation: formControl.string().required("Required").nullable(),
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Department Code",
        accessor: "code",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        isUserGenerated:true,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
        validation: formControl.string().required("Required").nullable(),
      },
      {
        Header: "Company",
        accessor: "companyCode",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "long",
        width:6,
        isUserGenerated:true,
        controlType: "select",
        Cell: ({ value }) => <DefaultCell value={value} />,
        options: {
          keyField: "description",
          keyDescription: "description",
          values: [],
          url: "/company/listAll",
        },
      },
      {
        Header: "Floor",
        accessor: "floor",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        width:6,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Color",
        accessor: "color",
        displayInList: true,
        allowInForm: true,
        allowFilter: false,
        dataType: "string",
        controlType: "colorPicker",
        width:6,
        Cell: ({ value }) => getColorBox(value),
      },
      {
        Header: "Action",
        accessor: "id",
        displayInList: true,
        allowInForm: false,
        Cell: ({ row, value }) => (
          <RowAction
            row={row}
            value={value}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ),
      },
  ];
};

export { getColumns, Attributes, operationURLS };
