import CTLTable from "application/components/CTLTable";
import { getColumns, operationURLS } from "./model";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import { Box, CircularProgress } from "@mui/material";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { setConferenceData } from "context";
import { setIsEmergencyCall } from "context";
import { v4 as uuidv4 } from "uuid";
import loggerFactory from "globals/logger/logger-factory";

const Attributes = {
  enableDownloadButton: false,
  enableAdvanceSearch: true,
  modes: [],
};

export default function EventList({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [updateList, setUpdateList] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        DownloadData: onDownload,
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);


   function onOpenFilter() {
     setOpenFilter(true);
   }

   function onCloseFilter() {
     setOpenFilter(false);
   }

   function onApplyFilter(advancedFilters) {
     setAdvancedFilters(advancedFilters);
     setOpenFilter(false);
   }


  const onDownload = () => {
    const requestBodyData = {
      sortBy: "createdOn",
      search: searchText,
      sortDataType: "string",
      sortDirection: "desc",
      columnNames: [
        "eventType",
        "eventSeverity",
        "createdOn",
        "createdBy",
        "zone",
        "type",
        "description",
      ],
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(operationURLS.csvDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Alert-Log.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: "Alert Log",
            status: "CSV Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: "Alert Log",
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const onClickToConference = (data) => {
     const dataWithUUID = {
       ...data,
       uuid: uuidv4(),
     };
     loggerFactory.debug("click to join conference data", dataWithUUID);
     setIsEmergencyCall(dispatch, true);
     setConferenceData(dispatch, dataWithUUID); 
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(onClickToConference)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="createdOn"
        updateList={updateList}
        list
        searchText={searchText}
        pageId={pageId}
        hasRowSelection={false}
        disablePagination={false}
        refetchInterval={5000}
      />
      <CTLAdvancedFilter
        entityName="Alert View"
        columns={getColumns(onClickToConference)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
