import React, { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import { Card, Divider, Grid, Icon } from "@mui/material";
import TaskManagementDashboard from "../taskManagementDashboard";
import CTLSelectedDownload from "application/components/CTLSelectDownload";
import { setAssignTo } from "context";
import { getUpdateColumns } from "./model/update";
import { setTaskFileData } from "context";
function TaskManager({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue, taskFileData, taskStatus } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateField, setUpdateField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [selectedData, setSelecteddata] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [columns, setColumns] = useState(getColumns());
  const [formDisable, setFormDisable] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const [sortColumn, setSortColumn] = useState("tasksName");
  const [columnDataType, setColumnDataType] = useState("string");
  const filteredList = getColumns().filter(
    (item) => item.displayInList === true
  );
  const selectedColumnDownload = useRef(
    filteredList && filteredList.map((item) => item.accessor)
  );

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        DownloadData: onUpdateDataDownload,
        DownloadPdf: DownloadPdf,
        UpdateTableRecords: DownloadData,
        Single: onOpenUpdate,
        onRefresh: onRefresh,
      });
  }, [activePage, tableValue]);

  useEffect(() => {
    if (!taskStatus || taskStatus === "Delayed") {
      setAdvancedFilters([]);
    } else if (taskStatus) {
      setAdvancedFilters([]);
    }
    setUpdateList(Date.now());
  }, [taskStatus]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onRefresh() {
    setUpdateList(Date.now());
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
    setUpdateField(false);
    setSelecteddata(false);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    const parseDateString = (dateString) => {
      const dateRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/;
      const match = dateString.match(dateRegex);
      if (!match) return null;
      const [_, year, month, day, hour, minute] = match;
      const isoString = `${year}-${month}-${day}T${hour}:${minute}:00`;
      return isoString;
    };
    const assignedOnFormatted = row.assignedOn
      ? parseDateString(row.assignedOn)
      : "";
    const expectedCompletionDate = row.expectedCompletionDate
      ? parseDateString(row.expectedCompletionDate)
      : "";

    if (row?.assignedTo !== undefined) {
      setAssignTo(dispatch, row.assignedTo);
    }
    if (row?.currentStats === "New") {
      setUpdateData(row.currentStats);
    } else {
      setUpdateData("");
    }

    setFormData({
      ...row,
      assignedOn: assignedOnFormatted,
      expectedCompletionDate: expectedCompletionDate,
      filePath: row.filePath,
    });

    setUpdateField(true);
    setSelecteddata(false);
  };

  function onOpenUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    setUpdateField(true);
    setSelecteddata(false);
  }

  function onCloseUpdate() {
    setUpdateField(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };
  function DownloadData() {
    setSelecteddata(true);
  }

  function onClose() {
    setSelecteddata(false);
  }

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setConfirmDelete(false);
  };

  // Create Data
  function OnCreate(data) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const assignedOnDate = data.assignedOn ? new Date(data.assignedOn) : null;
    const expectedCompletionDate = data.expectedCompletionDate
      ? new Date(data.expectedCompletionDate)
      : null;

    // Reset time for user-provided dates
    if (assignedOnDate) assignedOnDate.setHours(0, 0, 0, 0);
    if (expectedCompletionDate) expectedCompletionDate.setHours(0, 0, 0, 0);

    // Validation for assignedOnDate
    if (assignedOnDate && assignedOnDate < currentDate) {
      setNotify({
        isOpen: true,
        status:
          "You can select the current date or future date in the Start Date",
        type: "error",
        pagename: "Task Management System",
      });
      return;
    }

    // Validation for expectedCompletionDate
    if (expectedCompletionDate && expectedCompletionDate < currentDate) {
      setNotify({
        isOpen: true,
        status:
          "You can select the current date or  future date in the Due Date",
        type: "error",
        pagename: "Task Management System",
      });
      return;
    }

    if (data.assignedTo && typeof data.assignedTo === "string") {
      data = { ...data, assignedTo: parseInt(data.assignedTo, 10) };
    }
    const modifiedData = {
      ...data,
      expectedCompletionDate: data.expectedCompletionDate
        ? data.expectedCompletionDate.split("T")[0] +
          " " +
          data.expectedCompletionDate.split("T")[1].slice(0, 5)
        : "",
      assignedOn: data.assignedOn
        ? data.assignedOn.split("T")[0] +
          " " +
          data.assignedOn.split("T")[1].slice(0, 5)
        : "",
      filePath: taskFileData,
      currentStats: "New",
    };
    application
      .post(operationURLS.create, modifiedData)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
        setAssignTo(dispatch, "");
        setTaskFileData(dispatch,"")
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
    setOpenForm(false);
  }

  function onSaveForm(data) {
    const currentDateTime = new Date();
    const changedFields = Object.keys(data).filter(
      (key) => data[key] !== formData[key]
    );
    const isAssignedOnChanged = changedFields.includes("assignedOn");
    const isExpectedCompletionDateChanged = changedFields.includes(
      "expectedCompletionDate"
    );

    if (isAssignedOnChanged || isExpectedCompletionDateChanged) {
      const assignedOnDate = data.assignedOn ? new Date(data.assignedOn) : null;
      const expectedCompletionDate = data.expectedCompletionDate
        ? new Date(data.expectedCompletionDate)
        : null;

      if (assignedOnDate && assignedOnDate < currentDateTime) {
        setNotify({
          isOpen: true,
          status: "Start Date must be current or future date and time.",
          type: "error",
          pagename: "Task Management System",
        });
        return;
      }

      if (expectedCompletionDate && expectedCompletionDate < currentDateTime) {
        setNotify({
          isOpen: true,
          status: "Due Date must be current or future date and time.",
          type: "error",
          pagename: "Task Management System",
        });
        return;
      }
    }
    if (changedFields.length === 0) {
      setNotify({
        isOpen: true,
        status: "No changes detected. Please update some fields to apply.",
        type: "warning",
        pagename: pageName,
      });
      return;
    }

    const fieldNamesMap = {
      assignedOn: "Start Date",
      expectedCompletionDate: "Due Date",
      taskPriority: "Priority",
      currentStats: "Current Status",
      taskDescription: "Description",
      taskType: "TaskType",
      assignedTo: "AssignedTo",
      assignedUser: "AssignedUser",
      reviewer: "reviewer",
    };
    let remarks = changedFields
      .filter((key) => fieldNamesMap[key])
      .map((key) => {
        const friendlyName = fieldNamesMap[key];
        return `${friendlyName} updated from "${formData[key]}" to "${data[key]}"`;
      })
      .join("; ");

    if (data.assignedUser !== formData.assignedUser) {
      remarks += `; Task transferred from User: ${formData.assignedUser} to User: ${data.assignedUser}`;
    }
    const now = new Date();
    const DateTime = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;

    const modifiedData = {
      ...data,
      expectedCompletionDate: data.expectedCompletionDate
        ? data.expectedCompletionDate.split("T")[0] +
          " " +
          data.expectedCompletionDate.split("T")[1].slice(0, 5)
        : "",
      assignedOn: data.assignedOn
        ? data.assignedOn.split("T")[0] +
          " " +
          data.assignedOn.split("T")[1].slice(0, 5)
        : "",
      filePath: taskFileData,
      taskCompletedOn:
        data.currentStats === "Closed" ? DateTime : data.taskCompletedOn || "",
      remarks: remarks,
    };
    application
      .post(operationURLS.update, modifiedData)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
        setUpdateField(false);
        setAssignTo(dispatch, "");
        setTaskFileData(dispatch,"")
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }
  function onUpdateDataDownload() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
    ) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "warning",
        status: "You cann't download more that 10 column in pdf mode",
        message: "Please select 10 relevant column using Coumn Filer option",
      });
    }
    setLoading(true);

    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };

    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(operationURLS.downloadCSV, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Task-management.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "CSV Download successful",
            type: "success",
          });
        }
      })

      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const updateColumns = (selectedItems) => {
    setFormDisable(false);
    const updatedColumns = getColumns().map((column) => {
      return {
        ...column,
        displayInList: selectedItems.includes(column.accessor),
      };
    });
    setColumns(updatedColumns);

    selectedColumnDownload.current = selectedItems;
  };
  function DownloadPdf() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
    ) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "warning",
        status: "You cann't download more that 10 column in pdf mode",
        message: "Please select 10 relevant column using Coumn Filer option",
      });
    } else if (selectedColumnDownload.current.length > 10) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        type: "error",
        status: "Select maximum of 10 columns",
      });
    }
    setLoading(true);
    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
      params: {
        reportType: "taskPDF",
      },
    };
    application
      .post(operationURLS.pdfDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Voice-Picker.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "PDF Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const ListURL = {
    list:
      taskStatus === "Delayed"
        ? "/taskDelay/list"
        : taskStatus
        ? `/task/listWithStatus/${taskStatus}`
        : "/task/list",
  };
  return (
    <MDBox>
      <TaskManagementDashboard entityName="Admin Task Management" />
      <Divider />
      <CTLSelectedDownload
        openForm={selectedData}
        columns={columns}
        pageName={pageName}
        onCloseForm={onClose}
        onUpdate={updateColumns}
      />
      <DeleteConfirmation
        entityName="Task Management"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="Task Management"
        entityDiscription={formData.tasksName}
        columns={getColumns(deleteRow)}
        openForm={openForm}
        onSaveForm={OnCreate}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      {onOpenUpdate && (
        <CTLBaseDialogForm
          entityName="Task Management"
          entityDiscription={formData.tasksName}
          columns={getUpdateColumns(deleteRow, editRow, updateData, onRefresh)}
          openForm={updateField}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseUpdate}
          formData={formData}
          pageAttributes={Attributes}
        />
      )}
      <CTLTable
        apiURL={ListURL.list}
        advancedFilters={advancedFilters}
        columns={
          formDisable ? getColumns(deleteRow, editRow, onRefresh) : columns
        }
        getUpdateColumns
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="createdOn"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        disablePagination={false}
      />
      <CTLAdvancedFilter
        entityName="Task Management"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default TaskManager;
