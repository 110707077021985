import AssignUser from "application/components/AssignUser";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { CMSReportMenu } from "globals/configuration/CMSReportMenu";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,

  modes: CMSReportMenu.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/complaintDetail/assignmentList",
  pdfDownload: "/complaintDetail/generateAndDownloadPdf",
  csvDownload: "/complaintDetail/downloadCSV/assignment",
};

const getColumns = () => {
  return [
    {
      Header: "Location",
      accessor: "rluLocation",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Source",
      accessor: "compSource",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Type",
      accessor: "complaintType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Number",
      accessor: "complaintNo",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Time",
      accessor: "complaintTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension",
      accessor: "ext",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Description",
      accessor: "complaintDesc",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Current Status",
      accessor: "currentStatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "",
      displayInList: true,
      Cell: ({ row, value }) => (
        <AssignUser row={row} value={value} NewComplaint="NewComplaint"/>
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
