import MDTypography from "components/MDTypography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <tooltip {...props} classes={{ popper: className }}/>
  
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

function CTLUnReadCell({ value, suffix, row }) {
  const truncatedValue =
    value?.length > 20 ? value.slice(0, 25) + "  " : value;
  const unRead = row?.original?.isMessageRead;
  const fontSize = unRead ? "small" : "medium";
  const fontWeight = unRead ? "small" : "bold";
  

  return (
    <Tooltip title={value}>
      <MDTypography
        variant="caption"
        fontWeight={fontWeight}
     
       // style={{ fontSize }}
      >
        {truncatedValue}
        {suffix && (
          <MDTypography
            variant="caption"
            fontWeight={fontWeight}
         
          // style={{ fontWeight }}
          >
            &nbsp;&nbsp;{suffix}
          </MDTypography>
        )}
      </MDTypography>
    </Tooltip>
  );
}

CTLUnReadCell.defaultProps = {
  suffix: "",
};

export default CTLUnReadCell;
