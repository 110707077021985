const groupAdminMenu = [
  {
    color: "info",
    icon: "manage_accounts",
    key: "userManagement.roleMaster",
    name: "Role Master",
    url: "home/app/user/roleMaster",
    description: "Role Master",
    productionReady: false,
  },
  {
    color: "warning",
    icon: "management",
    key: "userMaster",
    name: "User Master",
    url: "home/app/userMaster",
    description: "User-Master",
    productionReady: false,
  },

  {
    color: "error",
    icon: "group",
    key: "groupmaster",
    name: "Group Master",
    url: "home/app/groupmaster",
    description: "Group Master",
    productionReady: false,
  },
  // {
  //   color: "success",
  //   icon: "room",
  //   key: "roomMaster",
  //   name: "Room Master",
  //   url: "home/app/roomMaster",
  //   description: "Room Master",
  //   productionReady: false,
  // },

  {
    color: "secondary",
    icon: "store_icon",
    key: "dialAssist",
    name: "Conference Location",
    description: "Conference Location",
    url: "home/app/dialAssist",
    disabled: false,
    productionReady: true,
  },
  // {
  //   color: "info",
  //   icon: "store_icon",
  //   key: "meetMeConference",
  //   name: "Meet Me Conference",
  //   description: "Conference",
  //   url: "home/app/meetMeConference",
  //   disabled: false,
  //   productionReady: true,
  // },
  // {
  //   color: "success",
  //   icon: "phone_icon",
  //   key: "Phone+VOIP",
  //   name: "Phone+VOIP",
  //   description: "Phone+VOIP",
  //   url: "home/app/Phone+VOIP",
  //   disabled: false,
  //   productionReady: true,
  // },
  {
    color: "info",
    icon: "settings",
    key: "uc.conference.setting",
    name: "Conference Setting",
    description: "conference Setting",
    url: "home/app/uc/ConferenceSetting",
  },
  {
    color: "primary",
    icon: "campaign_icon",
    name: "Conference Announcement",
    key: "distorsion",
    url: "/app/conference-Announcement",
    description: "Conference Announcement",
  },
  {
    color: "info",
    icon: "preview_icon ",
    name: "Debriefing",
    key: "debriefing",
    url: "/app/Debriefing",
    description: "Debriefing",
  },
  {
    color: "error",
    icon: "backup",
    name: "Backup Restore",
    key: "backupRestore",
    url: "/app/Backup-Restore",
    description: "Backup Restore",
  },
];

export { groupAdminMenu };
