import CTLUnReadCell from "application/components/CTLUnReadCell";
import DispatcherActionIcon from "application/components/DispatcherActionIcon";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";


const Attributes = {
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  modes: []
};

const operationURLS = {
  list: "agentStatus/list/userStatus",
  csvDownload: "/agentStatus/downloadCSV/userStatus",
};

const getColumns = () => {
  return [
    {
      Header: "User Id",
      accessor: "username",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      filterControlType: "select",
      options: {
        keyField: "username",
        keyDescription: "username",
        values: [],
        url: "/user/dropdownList",
      },
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "userFullname",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Extension No",
      accessor: "extensionNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Log Date",
      accessor: "date",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Log Time",
      accessor: "time",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Login / Logout",
      accessor: "id",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ row, value }) => (
        <DispatcherActionIcon row={row} value={value} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
