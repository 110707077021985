import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { useMaterialUIController, setIsAutoAnswer } from "context";

let AgentMenu = AuthenticationService.allowAgentMenus();
const Attributes = {
  formSize: "md",
  columns: 3,
  modes: [],
  settingsMode: true,
  enableExitButton: AgentMenu ? true : false,
};

const operationURLS = {
  list: "user/list",
  update: "user/update",
};

const getColumns = (onEdit) => {

    const [controller, dispatch] = useMaterialUIController();
    const { isAutoAnswer } = controller;

  
  const onChangeAutoAnswer= (event, formik) => {
    if (event.target.checked) {
      setIsAutoAnswer(dispatch, true);
    } else {
      setIsAutoAnswer(dispatch, false);
    }
  };

  return [
    {
      Header: "User ID",
      accessor: "username",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact No./Extension No.",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().emailOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "Active",
      accessor: "enabled",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Public Emergency Number",
      accessor: "publicEmergencyNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Broadcast Number",
      accessor: "broadcastNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Auto Answer",
      accessor: "dispatcherAutoAnswer",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangeAutoAnswer,
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Ring Answer Count",
      accessor: "ringAnswerCount",
      width: 6,
      displayInList: isAutoAnswer,
      allowInForm: isAutoAnswer,
      allowFilter: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      detailedExplanation:
        "Use this  configuration  to User Auto answer call after selected count ring",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "1", description: "1 Ring" },
          { id: "2", description: "2 Ring" },
          { id: "3", description: "3 Ring" },
          { id: "4", description: "4 Ring" },
          { id: "5", description: "5 Ring" },
        ],
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
