import CTLTable from "application/components/CTLTable";
import { getColumns, operationURLS } from "./model";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { setClickToCall } from "context";
import { setIsSOSCall } from "context";

export default function AlarmList({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const Attributes = {
    enableDownloadButton: false,
    enableAdvanceSearch: true,
    modes: [],
  };

  const [updateList, setUpdateList] = useState(Date.now());
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
   const [loading, setLoading] = useState(false);
     const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({ DownloadData: onDownload, openAdvancedFilter: onOpenFilter, });
  }, [activePage]);


     function onOpenFilter() {
       setOpenFilter(true);
     }

     function onCloseFilter() {
       setOpenFilter(false);
     }

     function onApplyFilter(advancedFilters) {
       setAdvancedFilters(advancedFilters);
       setOpenFilter(false);
     }

  const onDownload = () => {
    const requestBodyData = {
      sortBy: "createdOn",
      search: searchText,
      sortDataType: "string",
      sortDirection: "desc",
      columnNames: [
        "createdOn",
        "createdBy",
        "callType",
        "callingGroup",
        "callerNumber",
        "destinationNumber",
      ],
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(operationURLS.csvDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Alarm-Log.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: "Alarm Log",
            status: "CSV Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: "Alarm Log",
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const onClickToCall = (data) => {
    setIsSOSCall(dispatch ,true);
    setClickToCall(dispatch, data.extensionNumber);
  };

  
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(onClickToCall)}
        entriesPerPage={false}
        advancedFilters={advancedFilters}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="extensionNumber"
        list
        searchText={searchText}
        updateList={updateList}
        hasRowSelection={false}
        disablePagination={false}
        refetchInterval={5000}
      />
      <CTLAdvancedFilter
        entityName="Alert View"
        columns={getColumns(onClickToCall)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
