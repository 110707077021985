import {
  Avatar,
  Box,
  Card,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";
import { getCurrentStatus, getOnlineUsers, xmpp } from "../xmppClient";
import { useMaterialUIController } from "context";
import ClearIcon from "@material-ui/icons/Clear";

function UserGroupList({
  useStyles,
  selectUser,
  setSelectUser,
  setSelectGroup,
  setMessages,
  selectGroup,
  handleSelectList,
  joinGroup,
}) {
  const [userList, setUserList] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [presence, setPresence] = useState(getOnlineUsers());
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = useState(2);
  const [groupList, setGroupList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("User");
  const [status, setStatus] = useState(getCurrentStatus());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

   useEffect(() => {
     const handleStatusChange = () => {
       setStatus(getCurrentStatus());
     };
     if (xmpp) {
       xmpp.on("status", handleStatusChange);
       xmpp.on("online", handleStatusChange);
       xmpp.on("offline", handleStatusChange);
       return () => {
         xmpp.off("status", handleStatusChange);
         xmpp.off("online", handleStatusChange);
         xmpp.off("offline", handleStatusChange);
       };
     }
   }, [xmpp]);

  const fetchUserList = async () => {
    try {
      const response = await application.post("user/dropdownList");
      setUserList(response.data.data || []);

      const res = await application.post("/userGroupMaster/listAll");
      setGroupList(res.data.data);
    } catch (error) {
      loggerFactory.debug("Unable to get user list", error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleRefreshContact = () => {
    fetchUserList();
    setNotify({
      isOpen: true,
      message: "",
      type: "success",
      pagename: "Chat",
      status: "Contact Refresh Successfully",
    });
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fullName = AuthenticationService.getFullName();
  const filteredList = userList.filter((item) => {
    const searchTarget = item?.username;
    return (
      searchTarget.toLowerCase() !== fullName.toLowerCase() &&
      searchTarget.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const filteredGroupList = groupList.filter((item) => {
    const searchTarget = item?.description;
    return searchTarget.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: "10px" }}
      >
        <MDTypography variant="h4">Chat</MDTypography>
        <MDTypography variant="h6">
          {status}
          <Icon
            style={{ marginTop: "5px", marginLeft: "5px" }}
            color={status === "Online" ? "success" : "error"}
          >
            circle
          </Icon>
        </MDTypography>
      </Box>

      <Card
        style={{
          borderRadius: "20px",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#3492eb",
            },
            height: 50,
          }}
        >
          <Tab label="User" value="User" />
          <Tab label="Group" value="Group" />
        </Tabs>
      </Card>
      <Divider
        variant="inset"
        component="li"
        style={{ padding: "0px", margin: "10px" }}
      />
      <TextField
        label={`Search  ${selectedTab}`}
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchInputChange}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <>
              {searchQuery !== "" && (
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  >
                    <ClearIcon color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <InputAdornment position="end">
                <Tooltip title="Refresh Contact">
                  <IconButton onClick={handleRefreshContact}>
                    <Icon color="info">refresh_icon</Icon>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            </>
          ),
        }}
      />

      {selectedTab == "Group" ? (
        <List
          component="nav"
          aria-label="Group list"
          style={{
            height: "calc(80vh)",
            overflow: "auto",
            position: "relative",
          }}
        >
          {filteredGroupList &&
            filteredGroupList.map((group, index) => (
              <>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ padding: "0px", margin: "10px" }}
                />
                <ListItemButton
                  onClick={() => {
                    setSelectGroup(group.description);
                    setSelectUser("");
                    setMessages([]);
                    joinGroup(group.description);
                  }}
                  key={index}
                  selected={selectGroup === group.description}
                  style={{
                    backgroundColor:
                      selectGroup === group.description
                        ? darkMode
                          ? "#006699"
                          : "#c2c2a3"
                        : "transparent",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <GroupIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {group.description}
                  </MDTypography>
                </ListItemButton>
              </>
            ))}
        </List>
      ) : (
        <List
          component="nav"
          aria-label="user list"
          style={{
            height: "calc(80vh)",
            overflow: "auto",
            position: "relative",
          }}
        >
          {filteredList &&
            filteredList
              .sort((a, b) => {
                const isAOnline = presence.some(
                  ([username]) => username === a.username
                );
                const isBOnline = presence.some(
                  ([username]) => username === b.username
                );
                return isBOnline - isAOnline;
              })
              .map((user, index) => {
                const isOnline = presence.some(
                  ([username]) => username === user.username
                );

                return (
                  <>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ padding: "0px", margin: "10px" }}
                    />
                    <ListItemButton
                      key={index}
                      onClick={() => handleSelectList(user.username)}
                      selected={selectUser === user.username}
                      style={{
                        backgroundColor:
                          selectUser === user.username
                            ? darkMode
                              ? "#006699"
                              : "#c2c2a3"
                            : "transparent",
                        borderRadius: "8px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        style={{ flexGrow: 1 }}
                      >
                        {user.username}
                      </MDTypography>
                    </ListItemButton>
                  </>
                );
              })}
        </List>
      )}
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

export default UserGroupList;
