import { useState, useMemo } from "react";
import { Card } from "@mui/material";
import DataTable, { createTheme } from "react-data-table-component";
import predefinedFields from "../predefinedFields";
import addPredefinedFieldsTableColumns from "./columns";
import colors from "assets/theme-dark/base/colors";
import MDTypography from "components/MDTypography";
import getColumnsConfig from "./configs/getColumnsConfig";
import getColumnsDetails from "./configs/getColumnsDetails";
import getFilteredItems from "./configs/getFilteredItems";
import Filter from "./Filter";
import "./css/index.css";
import { useMaterialUIController } from "context";

function PredefinedFieldsTable({ handleSelectedPredefinedFields }) {
  const { darkMode } = useMaterialUIController()[0];
  const columnsDetails = getColumnsDetails(addPredefinedFieldsTableColumns);
  const renderTableColumns = getColumnsConfig(addPredefinedFieldsTableColumns);

  const predefinedFieldsTableData = predefinedFields.map((field) => {
    const newTransformedFieldData = {};

    Object.keys(field).forEach((fieldProp) => {
      if (columnsDetails[fieldProp] && columnsDetails[fieldProp].display)
        newTransformedFieldData[fieldProp] = field[fieldProp];
    });

    return newTransformedFieldData;
  });

  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark",
  );

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = getFilteredItems(
    predefinedFieldsTableData,
    columnsDetails,
    filterText,
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) =>
          setFilterText(() => {
            const text = e.target.value.toLowerCase();
            return text;
          })
        }
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const dataTable = (
    <DataTable
      data={filteredItems}
      columns={renderTableColumns}
      responsive
      pagination
      paginationPerPage={10}
      striped
      theme={darkMode ? "modifiedDarkTheme" : "default"}
      title={<MDTypography variant="h6">Predefined fields</MDTypography>}
      defaultSortFieldId="id"
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
      fixedHeader
      fixedHeaderScrollHeight="700px"
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      persistTableHead
      selectableRows
      onSelectedRowsChange={({ selectedRows }) =>
        handleSelectedPredefinedFields(selectedRows)
      }
    />
  );
  return (
    <Card
      sx={{
        padding: ".5em",
      }}
    >
      {dataTable}
    </Card>
  );
}

export default PredefinedFieldsTable;
