import { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "features/Sidenav";
import Configurator from "features/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

import aditionaltheme from "assets/aditional-theme";
import aditionalDarkRTL from "assets/aditional-theme/theme-rtl";

// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import Drawer from '@material-ui/core/Drawer';

// Material Dashboard 2 PRO React routes
import routes from "routes";

import menus from "menus";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setOpenPhone, setActivePage, setAuthentication } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import ManualDailingBox from "application/components/CTLAgentDashboard/manualDailingBox";
import SIPPhone from "application/components/SIPPhone";
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLSideBar from "application/components/CTLSideBar";
import Illustration from "application/modules/authentication/sign-in";
import EmbeddedLogin from "application/modules/authentication/EmbeddedLogin";
const useStyles = makeStyles({
  drawer: {
    width: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerPaper: {
    width: 480,
    left: "calc(50% - 240px)",
    background: "transparent"
  },
});

export default function App() {
  const queryClient = new QueryClient();
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    aditionalTheme,
    openPhone,
    activePage,
    authentication
  } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const classes = useStyles();
  const [displaySideNav, setDisplaySideNav] = useState(null);

  const Authenticated = AuthenticationService.isAuthenticated()
  const ChangePasswordRequired = AuthenticationService.getChangePasswordRequired();
  const enableCRM = AuthenticationService.allowExternalCRM();

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {

    document.body.setAttribute("dir", direction);
  }, [direction]);


  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setDisplaySideNav(authentication);
    if (!authentication && Authenticated) {
      setAuthentication(dispatch, Authenticated);
    }
  }, [authentication, layout])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const handlePhone = useCallback(() => {
    setOpenPhone(dispatch, false);
  }, [dispatch]);

  const navigateToHomePage = () => {
    activePage.key = "home.dashboard";
    activePage.name = "Dashboard";
    activePage.url = "home/app";
    activePage.description = "User Dashboard";
    setActivePage(dispatch, activePage);
    return true;
  }

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider
        theme={
          aditionalTheme ? aditionalDarkRTL : darkMode ? themeDarkRTL : themeRTL
        }
      >
        <CssBaseline />
        {authentication && layout === "dashboard" && (
          <>
            <QueryClientProvider client={queryClient}>
              {!ChangePasswordRequired && !enableCRM && (
                <CTLSideBar
                  color={sidenavColor}
                  brand={
                    (transparentSidenav && !darkMode) || whiteSidenav
                      ? brandDark
                      : brandWhite
                  }
                  brandName="Coral Telecom Ltd"
                  routes={menus}
                ></CTLSideBar>
              )}
            </QueryClientProvider>
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="/embedded" element={<EmbeddedLogin />} />
          <Route path="*" element={<Navigate to="/app/home" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider
      theme={aditionalTheme ? aditionaltheme : darkMode ? themeDark : theme}
    >
      <CssBaseline />
      {displaySideNav && layout === "dashboard" && (
        <>
          <QueryClientProvider client={queryClient}>
            {!ChangePasswordRequired && !enableCRM && (
              <CTLSideBar
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="Coral Telecom Ltd"
                routes={menus}
              />
            )}
          </QueryClientProvider>
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Drawer
        variant="temporary"
        anchor="top"
        open={openPhone}
        onClose={handlePhone}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawer}>
          <SIPPhone />
        </div>
      </Drawer>
      {enableCRM ? (
        <ManualDailingBox activePage={activePage} />
      ) : (
        <Routes>
          {getRoutes(routes)}
          <Route path="/embedded" element={<EmbeddedLogin />} />
          <Route path="*" element={<Illustration />} />
        </Routes>
      )}
      <Configurator />
    </ThemeProvider>
  );
}
