import React from "react";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function Filter({ filterText, onFilter, onClear }) {
  return (
    <MDBox container>
      <MDInput
        id="search"
        type="text"
        label="Search Extension"
        value={filterText}
        onChange={onFilter}
        size="small"
      />
      {/* <MDButton
        sx={{ borderRadius: "0px 6px 6px 0px", padding: "1.8em" }}
        iconOnly
        variant="gradient"
        color="info"
        onClick={onClear}
        size="small"
      >
        X
      </MDButton> */}
    </MDBox>
  );
}

Filter.propTypes = {
  filterText: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default Filter;
