import { useEffect, useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";
import { getColumns, Attributes, operationURLSS } from "./model";
import styled from "styled-components";
import { useMaterialUIController, setActivePage } from "context";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CustomTable from "./customTable";
import CTLTable from "application/components/CTLTable";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import DeleteConfirmation from "application/components/DeleteConfirmation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const LeftCard = styled(Card)`
  flex: 0 0 10%;
  margin-right: 10px;
`;

const RightCard = styled(Card)`
  flex-grow: 1;
`;

function InternalForward({ onLoad, pageId, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const [activeTab, setActiveTab] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [rowValue, setRowValue] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        onViewAll: onViewAll,
      });
  }, [activePage]);

  const daysOfWeekLower = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const daysOfWeekUpper = daysOfWeekLower.map((day) => day.toUpperCase());

  const operationURLS = {
    list: `extensions/listAll/${ivrExtension.code}`,
    tablelist: rowValue
      ? `fwdExtension/${daysOfWeekLower[activeTab]}/${rowValue}/list`
      : null,
  };

  function onViewAll() {
    activePage.key = "internalForwardReport";
    activePage.name = "Internal Forward Report";
    activePage.url = "home/app/internalForward";
    activePage.description = "Internal Forward";
    setActivePage(dispatch, activePage);
  }

  useEffect(() => {
    daysOfWeekLower[activeTab];
  }, [activeTab, rowValue]);

  useEffect(() => {
    getData();
  }, [ivrExtension.code]);

  const getData = () => {
    application
      .post(operationURLS.list)
      .then((response) => {
        let result = response.data.data;
        setTableData(result);
      })
      .catch((error) => {
        console.log("error while calling extensions api", error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setUpdateList(Date.now());
  };

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    application
      .post(operationURLSS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  const validateTimes = (fromTime, toTime) => {
    const fromDateTime = new Date(`2000-01-01T${fromTime}`);
    const toDateTime = new Date(`2000-01-01T${toTime}`);

    if (toTime && fromTime && toDateTime <= fromDateTime) {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Internal-Forward",
        status: "To Time must be greater than From Time",
      });
      return false;
    }
    return true;
  };

  const validateForm = () => {
    if (rowValue === null) {
      setNotify({
        isOpen: true,
        message: "Please select an extension number.",
        type: "error",
        pagename: pageName,
        status: "Error",
      });
      return false;
    }
    if (activeTab === null) {
      setNotify({
        isOpen: true,
        message: "Please select a day.",
        type: "error",
        pagename: pageName,
        status: "Error",
      });
      return false;
    }
    return true;
  };

  function onSaveForm(data) {
    if (!validateForm()) {
      return;
    }

    if (!validateTimes(data.fromTime, data.toTime)) {
      return;
    }

    let aditionalData = {
      ...data,
      extension: rowValue,
      [daysOfWeekLower[activeTab]]: 1,
    };

    if (data.id) {
      application
        .post(operationURLSS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    } else {
      application
        .post(operationURLSS.create, aditionalData)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    }
    setOpenForm(false);
  }

  return (
    <>
      <Container>
        <ContentWrapper>
          <LeftCard>
            {tableData && (
              <CustomTable
                data={tableData}
                setRowValue={setRowValue}
                setUpdateList={setUpdateList}
              />
            )}
          </LeftCard>

          <RightCard>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#49a3f1",
                },
                height: 50,
              }}
            >
              {daysOfWeekUpper.map((day, index) => (
                <Tab key={day} label={day} />
              ))}
            </Tabs>

            {activeTab !== null && (
              <CTLTable
                apiURL={operationURLS.tablelist}
                columns={getColumns(deleteRow, editRow)}
                canSearch
                defaultSortDirection=""
                disablePagination={true}
                defaultSortColumn=""
                pageId={pageId}
                updateList={updateList}
                list
              />
            )}
          </RightCard>
        </ContentWrapper>

        <DeleteConfirmation
          entityName="Internal-Forward"
          data={selectedRow}
          columns={getColumns(deleteRow, editRow)}
          confirmDelete={confirmDelete}
          onAgree={onAgree}
          onCancel={onCancel}
        />
        <CTLBaseDialogForm
          entityName="Internal-Forward"
          columns={getColumns(deleteRow, editRow)}
          openForm={openForm}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseForm}
          formData={formData}
          pageAttributes={Attributes}
        />
        <CTLNotification notify={notify} setNotify={setNotify} />
      </Container>
    </>
  );
}

export default InternalForward;
