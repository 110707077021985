import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useMaterialUIController } from "context";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { application } from "globals/endpoints";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import MDTypography from "components/MDTypography";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CTLDialogContent from "application/components/styles/CTLDialogContent";

export default function ViewTaskDetals({ row }) {
  const Id = row?.original?.id;
  const Code = row?.original?.tasksName;
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const darkModeContext = useMaterialUIController();
  const { darkMode } = controller;
  const [ViewData, setViewData] = useState([]);
  const URL = {
    list: `/taskDetails/listAll/${Id}`,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function GetData() {
    application
      .post(URL.list)
      .then((res) => {
        setViewData(res.data.data);
      })
      .catch((error) => {
        console.log("error while the call /taskdetails/listAll", error);
      });
  }

  useEffect(() => {
    if (open) {
      GetData();
    } else {
      setViewData([]);
    }
  }, [open]);

  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark"
  );
  const header = [
    {
      name: "taskStats",
      selector: (row) => row?.taskStats,
      sortable: true,
    },
    {
      name: "statusUpdatedBy",
      selector: (row) => row?.statusUpdatedBy,
      sortable: true,
    },
    {
      name: "statusUpdatedOn",
      selector: (row) => row?.statusUpdatedOn,
      sortable: true,
    },
    {
      name: "changedCompletionOn",
      selector: (row) => row?.changedCompletionOn,
      sortable: true,
    },
    {
      name: "remarks",
      selector: (row) => row?.remarks,
      sortable: true,
      cell: (row) => {
        const remarkText = row?.remarks || "";
        const wordCount = remarkText.split(" ").length;
        return wordCount > 15 ? (
          <Tooltip title={remarkText}>
            <MDTypography
              variant="body2"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {remarkText.substring(0, 50)}...
            </MDTypography>
          </Tooltip>
        ) : (
          <MDTypography variant="body2">{remarkText}</MDTypography>
        );
      },
    },
  ];
  return (
    <div>
      {Id ? (
        <Tooltip title="View">
          <IconButton
            color="info"
            onClick={() => handleClickOpen(row.original)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Not availble">
          <IconButton color="error">
            <NotInterestedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <MDTypography>Task Details:{Code}</MDTypography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "relative",
              top: "-8px",
            }}
          >
            <CloseIcon color="error" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          fullScreen
        >
          <DataTable
            columns={header}
            data={ViewData}
            canSearch
            responsive
            pagination
            striped
            paginationPerPage={20}
            paginationRowsPerPageOptions={[5, 10, 20]}
            theme={
              darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"
            }
            fixedHeader
            persistTableHead
            fixedHeaderScrollHeight={`${(window.innerHeight / 100) * 66}px`}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
