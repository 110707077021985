import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import ViewLeadSummary from "application/components/ViewLeadSummary";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";

const Attributes = {
  enableDownloadPdf: true,
  formSize: "md",
  columns: 3,
  settingsMode: false,
  enableAdvanceSearch: true,
  enableRefresh: true,
  
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
};

const operationURLS = {
  list: "/leadSummaryView/list",
  csvDownload: "/leadSummaryView/downloadCSV",
  pdfDownload: "/leadSummaryView/generateAndDownloadPdf",
};

const getColumns = (onDelete,onEdit) => {
  return [
    {
      Header: "Campaign",
      accessor: "campaign",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Lead Uploaded On",
      accessor: "leadUploadDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Upload",
      accessor: "upload",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total DMCL",
      accessor: "call",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Duplicate",
      accessor: "duplicate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Valid",
      accessor: "valid",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Lead Name",
      accessor: "fileName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Campaign Start Date",
      accessor: "campaignFromTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign End Date",
      accessor: "campaignEndTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign Status",
      accessor: "status",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Total Dialed",
      accessor: "dialed",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Pending",
      accessor: "pending",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Answered",
      accessor: "answered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Abandoned",
      accessor: "abandoned",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Lead Marked as Canceled",
      accessor: "markAsDelete",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File Path",
      accessor: "filepath",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => <ViewLeadSummary  onEdit={onEdit} row={row} value={value}  />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
