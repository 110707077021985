const realTimeMenu = [
    {
        icon: "blur_on",
        key: "realTimeData",
        name: "Real Time",
        url: "home/app/realTime",
        description: "Get The React Time Data for calling ",
        disabled: false, 
        productionReady : true
      },
  ];
  
  export { realTimeMenu };
  