import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
function AlertDelete({ isDelete, setIsDelete, handleConfirmDelete, name }) {
  const handleClose = () => {
    setIsDelete(false);
  };
  const { darkMode } = useMaterialUIController()[0];

  return (
    <Box>
      <Dialog
        // fullWidth={fullWidth}
        maxWidth="sm"
        open={isDelete}
        onClose={handleClose}
      >
        {/* <DialogTitle textAlign="center"> </DialogTitle> */}
        <DialogContent
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // width: "30rem",
            padding: "20px",
            gap: ".3em"
          }}
        >
          <Box sx={{ color: "red", display:"flex",alignItems:"center" }}>
            <WarningAmberRoundedIcon fontSize="medium" />
          </Box>
          <MDTypography variant="body1">
            {" "}
            Do you want to Delete this {name}?
          </MDTypography>
        </DialogContent>

        <DialogActions
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}
        >
          <MDBox pb={0} display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleConfirmDelete}
            >
              Confirm
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Close
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AlertDelete;
