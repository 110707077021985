import { telemetry } from "globals/endpoints";
import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import TextField from "@mui/material/TextField";
import loggerFactory from "globals/logger/logger-factory";
import { useMaterialUIController } from "context";
import { navbarIconButton } from "features/Navbars/DashboardNavbar/styles";
import MDButton from "components/MDButton";
import { application } from "globals/endpoints";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { setIsEmergencyCall } from "context";
import { setConferenceData } from "context";
import CTLNotification from "application/components/CTLNotification";
import { Icon, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { setBroadcastCall } from "context";
import { setIsBroadcastCall } from "context";
export default function DispatcherGroupCall({ event, icon, color }) {
  const [open, setOpen] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const getUserList = () => {
    application
      .get("extensions/listAllContacts")
      .then((response) => {
        setMenuItems(response.data.data.contacts);
      })
      .catch((error) => {
        loggerFactory.error("Unable to get participant list", error);
      });
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleGroupCall = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    setSelectedItems([]);
    setSearchInput("");
    setIsSelectAll(false);
  };

  const handleSelect = (code) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.number === code);

      if (isSelected) {
        return prevSelected.filter((item) => item.number !== code);
      } else {
        return [...prevSelected, { number: code }];
      }
    });
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedItems([]);
    } else {
      const allItems = filteredMenuItems.map((item) => ({ number: item.code }));
      setSelectedItems(allItems);
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleStartGroupCall = () => {
    const body = {
      roomName: AuthenticationService.getExtensionNumber(),
      didMapping: "121",
      deviceType: "Dispatcher",
      deviceNumber: AuthenticationService.getExtensionNumber(),
      meetingStatus: 1,
      type: "Group",
      participants: selectedItems,
    };

    application
      .post("conferenceRoom/createTo", body)
      .then((response) => {
        const data = response.data.data;
        setIsEmergencyCall(dispatch, true);
        setConferenceData(dispatch, data);
        handleClose();
      })
      .catch((error) => {
        handleClose();
        setNotify({
          isOpen: true,
          pagename: "Dispatcher",
          status: "Unable to start group call",
          type: "error",
        });
        loggerFactory.error("Unable to start group call", error);
      });
  };
  const handleStartBroadcastCall = () => {
    const broadcastNumber = AuthenticationService.getBroadcastNumber();
    if (broadcastNumber) {
      const userList = selectedItems.map((item) => item.number).join(",");
      setBroadcastCall(dispatch, {
        broadcastNumber: broadcastNumber,
        userList: userList,
      });
      setIsBroadcastCall(dispatch, true);
    } else {
      setNotify({
        isOpen: true,
        pagename: "Dispatcher",
        status: "Unable to start Broadcast call",
        message: "Please Define Broadcast Number",
        type: "info",
      });
    }
    handleClose();
  };

  const filteredMenuItems = menuItems.filter((item) => {
    const searchTerm = searchInput?.toLowerCase();
    return (
      item.description?.toLowerCase().includes(searchTerm) ||
      item.code?.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <>
      <tooltip title={event}>
        <MDButton
          size="medium"
          fullWidth
          disableRipple
          color={color}
          sx={navbarIconButton}
          variant="contained"
          style={{ minHeight: "55px" }}
          onClick={handleGroupCall}
          endIcon={<Icon>{icon}</Icon>}
        >
          {" "}
          {event}
        </MDButton>
      </tooltip>
      <Menu
        id="simple-menu"
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "530px",
            width: "300px",
            position: "relative",
            overflowY: "hidden",
          },
        }}
      >
        <MDBox p={2}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </MDBox>
        {filteredMenuItems ? (
          <div
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              marginBottom: "5px",
            }}
          >
            <MenuItem onClick={handleSelectAll}>
              <MDBox style={{ display: "flex", alignItems: "center" }}>
                <Checkbox checked={isSelectAll} />
              </MDBox>
              Select All
            </MenuItem>
            {Array.isArray(filteredMenuItems) &&
              filteredMenuItems.map((item, index) => (
                <MenuItem key={index}>
                  <MDBox style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectedItems.some(
                        (selected) => selected.number === item.code
                      )}
                      onChange={() => handleSelect(item.code)}
                    />
                    <MDBox style={{ marginLeft: "10px" }}>
                      <MDTypography variant="h6" display="block">
                        {`${item.description ? item.description : item.code}`
                          .length > 25
                          ? `${(item.description
                              ? item.description
                              : item.code
                            ).substring(0, 25)}...`
                          : item.description
                          ? item.description
                          : item.code}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MenuItem>
              ))}
          </div>
        ) : (
          <MenuItem>
            <MDBox style={{ display: "flex", alignItems: "center" }}></MDBox>
            No Participant found !
          </MenuItem>
        )}
        <MDBox
          display="flex"
          justifyContent="space-between"
          gap="8px"
          style={{ marginTop: "auto" }}
        >
          <MDButton color="secondary" onClick={handleClose} style={{ flex: 1 }}>
            Cancel
          </MDButton>
          {event === "Group Call" ? (
            <MDButton
              color="success"
              onClick={handleStartGroupCall}
              style={{ flex: 1 }}
              disabled={selectedItems.length === 0}
            >
              Group Call
            </MDButton>
          ) : (
            <MDButton
              color="warning"
              onClick={handleStartBroadcastCall}
              style={{ flex: 1 }}
              disabled={selectedItems.length === 0}
            >
              Broadcast
            </MDButton>
          )}
        </MDBox>
      </Menu>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
