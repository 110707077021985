import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { getUpdatedColumns, attributes, operationurls } from "./updateModel";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";

function InboundGateway({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    attributes && onLoad(attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        Single: onOpenUpdate,
        onRefresh: onRefresh,
      });
  }, [activePage]);

  // Refresh Function
  const onRefresh = () => {
    setUpdateList(Date.now);
  };

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }
  function onOpenUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenUpdate(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenUpdate(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    console.log(row);
    application
      .post(operationurls.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationurls.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            status:
              response.data.message + "" + response.data.message.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      application
        .post(operationurls.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            status: response.data.message + "" + response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status: error.response.data.message,
            type: "error",
            pagename: pageName,
          });
        });
    }
    setOpenForm(false);
    setOpenUpdate(false);
  }

  function onCloseUpdate() {
    setOpenUpdate(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="InboundGateway"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="InboundGateway"
        columns={getColumns(editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      {onOpenUpdate && (
        <CTLBaseDialogForm
          entityName="InboundGateway Update"
          columns={getUpdatedColumns(deleteRow, editRow)}
          openForm={openUpdate}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseUpdate}
          formData={formData}
          pageAttributes={attributes}
        />
      )}

      <CTLAdvancedFilter
        entityName="InboundGateway"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationurls.list}
        advancedFilters={advancedFilters}
        columns={getUpdatedColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="id"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        disablePagination={false}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default InboundGateway;
