const addPredefinedFieldsTableColumns = [
  {
    field: "dataType",
    heading: "Data type",
    type: "string",
    display: true,
  },
  {
    field: "accessor",
    heading: "Accessor",
    type: "string",
    display: true,
  },
  {
    field: "Label",
    heading: "Label",
    type: "string",
    display: true,
  },
];

export default addPredefinedFieldsTableColumns;