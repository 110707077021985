import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  List,
  ListItem,
  ListItemIcon,
  Fade,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  DialogContent,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AddForm from "./AddForm";
import { enqueueSnackbar } from "notistack";
import {
  CalendarViewMonth,
  LocalHospital,
  Paid,
  Phone,
  Close,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";
import MenuComponent from "./MenuComponent";
import MDBox from "components/MDBox";
import MoveExplorer from "./MoveExplorer";
import { useMaterialUIController } from "context";
import getIcon from "./Icons/getIcon";
import Draggable from "react-draggable";
import { useAddExtensionDataMutation } from "features/hospitalSlice";
//import { useChangeHospitalJSON } from "./useChangeHospitalJSON";
import FeaturesDialog from "./FeaturesDialog";
import ContextMenu from "./ContextMenu";
import { useGeometryDataJSON } from "./useGeometryDataJSON";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

const Folder = ({
  folder,
  onFolderClick,
  onFolderDelete,
  onFolderEdit,
  onFolderAdd,
  level,
  currentFolder,
  setCurrentFolder,
  handleSelected: parentHandleSelected,
  width,
  handleCurrentPage,
  featureList,
  featureListSelected,
  setFeatureListSelected,
  folders,
  setFolders,
  moveExplorerCurrentFolder,
  areaList,
  routeList,
  userList,
  onUserDrop,
  selectedFolders,
  setSelectedFolders,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [isOpen, setIsOpen] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(() => {
    if (folder.type === "building") {
      return "New Building";
    } else if (folder.type === "floor") {
      return "New Floor";
    }
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [addInput, setAddInput] = useState({
    name: "",
    from: "",
    to: "",
  });
  const [roomInputDialog, setRoomInputDialog] = useState(null);
  const [anchorSubMenu, setAnchorSubMenu] = useState(null);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(null);
  const [anchorContextMenu, setAnchorContextMenu] = useState(null);
  const [moveExplorer, setMoveExplorer] = useState(null);
  const [addExtensionData] = useAddExtensionDataMutation();
  const [pendingBackendUpdate, setPendingBackendUpdate] = useState(null);
  //const { changeHospitalJSON } = useChangeHospitalJSON();
  const { changeGeometryDataJSON } = useGeometryDataJSON();

  const [editableName, setEditableName] = useState({
    name: "",
    number: "",
  });

  const [dropConfirmation, setDropConfirmation] = useState({
    open: false,
    user: null,
    targetFolder: null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);

  const menuItemStyles = {
    padding: "1px 6px 0px 1.2em",
    gap: 2,
  };

  const iconStyles = {
    fontSize: "1.4em",
    color: darkMode ? "#ffffff" : "#000000",
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setNewName(folder.name);
    setEditableName({
      name: folder?.name,
      number: folder?.number,
    });

    // (folder?.name.match(/\d+$/) || [])[0] || ""
    setAnchorContextMenu(null);
  };

  const handleSaveEdit = (event) => {
    onFolderEdit(folder.id, newName);
    setIsEditing(false);
  };

  const handleEditchange = (e) => {
    const { value, name } = e.target;
    setEditableName({
      ...editableName,
      [name]: value,
    });
  };

  const handleEnterKeyPressedSaved = (event) => {
    const extensionName = editableName?.name + editableName?.number;
    if (event.keyCode === 13) {
      onFolderEdit(folder.id, extensionName);
      setIsEditing(false);
    }
  };

  const handleEditableSave = (event) => {
    const extensionName = editableName?.name;
    const extensionNumber = editableName?.number;
    if (extensionNumber === "" || extensionName === "") {
      enqueueSnackbar("Extension Name or Number can't be empty", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    onFolderEdit(folder.id, extensionName, extensionNumber);
    setIsEditing(false);

    // enqueueSnackbar("Successfully Updated", {
    //   variant: "success",
    //   anchorOrigin: {
    //     vertical: "top",
    //     horizontal: "right",
    //   },
    // });
  };

  const handleDeleteClick = () => {
    onFolderDelete(selectedFolders?.length > 0 ? [selectedFolders] : folder);
    setCurrentFolder(null);
    setAnchorContextMenu(null);
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorSubMenu(false);
  };

  const handleOpenSubMenu = (e) => {
    setAnchorSubMenu(e.currentTarget);
  };
  const openContextMenu = (event, folder) => {
    event.preventDefault();
    if (Boolean(moveExplorer)) {
      return;
    }
    handleSelected(event, folder);
    setAnchorContextMenu(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleAddFeatures = () => {
    handleCloseMenu();
    setIsOpen(true);
    // onFolderAdd(folder.id, openFeatureDialog);
    onFolderAdd(folder.id, openFeatureDialog);
    console.log("Hellllo", openFeatureDialog);
    //changeHospitalJSON(folders, `created ${openFeatureDialog}`);

    setNewName(openFeatureDialog);
    setAnchorContextMenu(null);
    setOpenFeatureDialog(null);
    setFeatureListSelected([]);
  };

  const handleAddFloor = () => {
    handleCloseMenu();
    setIsOpen(true);
    onFolderAdd(folder.id, "floor");
    setAnchorContextMenu(null);
  };

  const extractNumbers = (node, numbers = []) => {
    if (node?.type !== "building") {
      numbers.push(Number(node.name));
    }
    if (node?.children && node.children.length > 0) {
      node?.children?.forEach((child) => extractNumbers(child, numbers));
    }
    return numbers;
  };

  // Get sorted numbers
  const getSortedNumbers = (data) => {
    let numbers = extractNumbers(data[0]);
    return numbers.sort((a, b) => a - b);
  };

  // Check if a number exists within a range
  const isNumberInRange = (sortedNumbers, from, to) => {
    const fromNumber = Number(from);
    const toNumber = Number(to);
    return sortedNumbers.some(
      (number) => number >= fromNumber && number <= toNumber
    );
  };

  function createOrSelectChild(folder, entityType, name) {
    let newChild =
      folder.type === entityType
        ? folder
        : folder?.children?.find((child) => child.type === entityType);

    if (!newChild) {
      newChild = {
        id: uuidv4(),
        name: name,
        type: entityType,
        parentId: folder.id,
        children: [],
      };
      folder.children.unshift(newChild);
    }

    if (newChild.type === entityType) {
      setCurrentFolder(newChild);
      return newChild.id;
    }

    return folder.id;
  }

  const handleAddRoom = async (e, entityType, addInput) => {
    if (parseInt(addInput.from) > parseInt(addInput.to)) {
      enqueueSnackbar("Numeric Range Mismatch: From Greater Than To", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    let entityInput = {
      from:
        addInput.from === "" && addInput.to != "" ? addInput.to : addInput.from,
      to:
        addInput.from != "" && addInput.to === "" ? addInput.from : addInput.to,
    };

    // Here check that we can insert or not?
    const sortedNumbers = getSortedNumbers(folders);
    const numberInRange = isNumberInRange(
      sortedNumbers,
      entityInput.from,
      entityInput.to
    );

    if (numberInRange) {
      enqueueSnackbar("Extension Number already exist", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    if (entityInput.from != "" && entityInput.to != "") {
      handleCloseMenu();
      setIsOpen(true);
      let folderId = folder?.id;

      if (entityType === "number") {
        folderId = createOrSelectChild(folder, "extension", "Extension");
      }

      if (entityType === "room") {
        folderId = createOrSelectChild(folder, "rooms", "Rooms");
      }

      const dataTosendDb = {
        prefix: addInput.name,
        from: entityInput.from,
        to: entityInput.to,
        type: entityType,
      };
      console.log("dataTosendDb", dataTosendDb);

      try {
        const addExtensionResponse = await addExtensionData(
          dataTosendDb
        ).unwrap();
        console.log("addExtensionResponse", addExtensionResponse);
        if (addExtensionResponse?.status === "OK" || true) {
          for (
            let i = parseInt(entityInput.from);
            i <= parseInt(entityInput.to);
            i++
          ) {
            onFolderAdd(folderId, entityType, addInput.name + i);
          }
          setAnchorContextMenu(null);
          setRoomInputDialog(null);
          //await changeHospitalJSON(folders, `created ${entityType}`);
          enqueueSnackbar(
            addExtensionResponse?.messageDetail || "Successfully Created",
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        console.log("error", error);
        enqueueSnackbar(error?.messageDetail || "Something went wrong", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      alert(`Please Enter ${entityType} `);
    }
    setAddInput({
      name: "",
      from: "",
      to: "",
    });
  };

  const handleRoomChange = (e, type) => {
    if (type === "fromRoomNumbr") {
      setAddInput({
        ...addInput,
        fromRoomNumber: e.target.value,
      });
    }
    if (type === "ToRoomNumber") {
      setAddInput({
        ...addInput,
        toRoomNumber: e.target.value,
      });
    }
  };

  const handleRoomDialogClose = () => {
    setRoomInputDialog(null);
  };

  const handleOpenAddForm = (e, type) => {
    setRoomInputDialog(type);
  };

  const handleSetCurrentPage = (e, type) => {
    onFolderAdd(folder.id, type);
    // changeHospitalJSON(folders, `created ${type}`);
    setNewName(type);
    setAnchorContextMenu(null);
    handleCurrentPage(e, type);
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("application/json", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    if (folder.type === "geofence" || folder.type === "route") {
      e.preventDefault();
      e.currentTarget.style.backgroundColor = darkMode
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)";
    }
  };

  const handleDragLeave = (e) => {
    e.currentTarget.style.backgroundColor = "";
  };

  const isUserAlreadyAssigned = (targetFolder, user) => {
    // Check if the folder has a users subfolder
    const usersFolder = targetFolder.children?.find(
      (child) => child.type === "users"
    );
    if (!usersFolder) return false;

    // Get user identifier
    const userId =
      user?.userData?.id ||
      user?.userDetails?.id ||
      user?.id ||
      user?.name ||
      user?.username;

    // Check if user exists in the users folder
    return usersFolder.children?.some((existingUser) => {
      const existingUserId =
        existingUser?.userData?.id ||
        existingUser?.userDetails?.id ||
        existingUser?.id ||
        existingUser?.name ||
        existingUser?.username;

      return (
        existingUserId === userId ||
        existingUserId?.toString() === userId?.toString() ||
        existingUser.name === user.name
      );
    });
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = "";

    try {
      let droppedUsers = JSON.parse(e.dataTransfer.getData("application/json"));

      // Ensure droppedUsers is an array
      if (!Array.isArray(droppedUsers)) {
        droppedUsers = [droppedUsers];
      }

      console.log("droppedUsers", droppedUsers, selectedFolders);

      const selectedFoldersHasDroppedUser = checkSelectedFoldersHasDroppedUser(
        droppedUsers[0]?.userData?.id
      );
      droppedUsers = selectedFoldersHasDroppedUser
        ? selectedFolders
        : droppedUsers;

      if (selectedFolders.length > 0 && !selectedFoldersHasDroppedUser) {
        enqueueSnackbar(
          "Select users to drag and drop multiple users.",
          {
            variant: "error",
            anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }

      if (
        droppedUsers.every((user) => user.type === "user") &&
        (folder.type === "geofence" || folder.type === "route")
      ) {
        // Check if any user is already assigned
        const alreadyAssignedUsers = droppedUsers.filter((user) =>
          isUserAlreadyAssigned(folder, user)
        );
        if (alreadyAssignedUsers.length > 0) {
          enqueueSnackbar(
            `Some users are already assigned to this ${folder.type}`,
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
          return;
        }

        setDropConfirmation({
          open: true,
          users: droppedUsers,
          targetFolder: folder,
        });
      }
    } catch (error) {
      console.error("Error handling drop:", error);
      enqueueSnackbar("Error processing drag and drop", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const handleConfirmDrop = async () => {
    try {
      // Find or create users folder
      let usersFolder = dropConfirmation.targetFolder.children?.find(
        (child) => child.type === "users"
      );

      if (!usersFolder) {
        usersFolder = {
          id: uuidv4(),
          name: "Users",
          type: "users",
          parentId: dropConfirmation.targetFolder.id,
          children: [],
        };
        dropConfirmation.targetFolder.children.push(usersFolder);
      }

      // Add users to users folder
      dropConfirmation.users.forEach((user) => {
        const newUser = {
          ...user,
          id: uuidv4(),
          parentId: usersFolder.id,
        };
        usersFolder.children.push(newUser);
      });

      // Update backend
      await changeGeometryDataJSON(folders, "updated user assignment");

      // Close dialog
      setDropConfirmation({ open: false, users: null, targetFolder: null });
      setSelectedFolders([]);
      enqueueSnackbar(
        `Successfully assigned users to ${dropConfirmation.targetFolder.type}`,
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    } catch (error) {
      console.error("Error assigning users:", error);
      enqueueSnackbar("Failed to assign users", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setDropConfirmation({ open: false, users: null, targetFolder: null });
    }
  };

  const handleCancelDrop = () => {
    setDropConfirmation({ open: false, user: null, targetFolder: null });
  };

  const handleSelected = (e, folder) => {
    parentHandleSelected(e, folder);
  };

  // console.log("foldeeeer", folders)
  // console.log("foldersss", folder)

  const isUserAssignedAnywhere = (folders, user) => {
    const userId =
      user?.userData?.id ||
      user?.userDetails?.id ||
      user?.id ||
      user?.name ||
      user?.username;

    if (!userId) return false;

    const stack = [...folders];
    while (stack.length > 0) {
      const currentFolder = stack.pop();

      // Check if this is a geofence folder with a users child (changed from groups)
      if (
        (currentFolder.type === "geofence" || currentFolder.type === "route") &&
        currentFolder.children
      ) {
        // Look for the users folder (changed from groups)
        const usersFolder = currentFolder.children.find(
          (child) => child.type === "users"
        );
        if (usersFolder && usersFolder.children) {
          // Check if the user is in this users folder
          const userFound = usersFolder.children.some((groupUser) => {
            const groupUserId =
              groupUser?.userData?.id ||
              groupUser?.userDetails?.id ||
              groupUser?.id ||
              groupUser?.name ||
              groupUser?.username;

            return (
              groupUserId === userId ||
              groupUserId?.toString() === userId?.toString() ||
              groupUser.name === user.name
            );
          });

          if (userFound) return true;
        }
      }

      if (currentFolder.children) {
        stack.push(...currentFolder.children);
      }
    }
    return false;
  };

  const filterUsers = (users) => {
    if (!searchQuery || folder.name !== "Users List") return users;

    return users.filter((user) => {
      const searchTerm = searchQuery.toLowerCase();
      const userName = (user.name || user.username || "").toLowerCase();
      return userName.includes(searchTerm);
    });
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleSearchClose = () => {
    setShowSearchInput(false);
    setSearchQuery(""); // Clear search when closing
  };

  const handleCheckboxChange = (event, folder) => {
    event.stopPropagation();
    if (event.target.checked) {
      const newSelectedFolders = [...selectedFolders, folder];

      // Automatically select all child folders if the folder type is users, routes, or zones
      if (["users", "routes", "zones"].includes(folder.type)) {
        const selectAllChildren = (folder) => {
          if (folder.children && folder.children.length > 0) {
            folder.children.forEach((child) => {
              if (!newSelectedFolders.includes(child)) {
                newSelectedFolders.push(child);
                selectAllChildren(child);
              }
            });
          }
        };
        selectAllChildren(folder);
      }

      setSelectedFolders(newSelectedFolders);
    } else {
      const deselectAllChildren = (folder) => {
        if (folder.children && folder.children.length > 0) {
          folder.children.forEach((child) => {
            setSelectedFolders((prevSelected) =>
              prevSelected.filter((id) => id !== child)
            );
            deselectAllChildren(child);
          });
        }
      };

      setSelectedFolders((prevSelected) =>
        prevSelected.filter((id) => id !== folder)
      );
      deselectAllChildren(folder);
    }
  };

  const isFolderSelected = selectedFolders.includes(folder);

  // Determine the type of the first selected folder
  const firstSelectedFolderType =
    selectedFolders.length > 0 ? selectedFolders[0]?.type : null;
  // Define the selectable types based on the first selected folder type
  let selectableTypes = [];
  if (firstSelectedFolderType) {
    if (
      firstSelectedFolderType === "route" ||
      firstSelectedFolderType === "routes"
    ) {
      selectableTypes = ["route", "routes"];
    } else if (
      firstSelectedFolderType === "zones" ||
      firstSelectedFolderType === "geofence"
    ) {
      selectableTypes = ["zones", "geofence"];
    } else if (
      firstSelectedFolderType === "user" ||
      firstSelectedFolderType === "users"
    ) {
      selectableTypes = ["user", "users"];
    }
  } else {
    selectableTypes = ["zones", "geofence", "users", "user", "routes", "route"];
  }

  // Determine if the current folder type is selectable
  const isSelectable = selectableTypes.includes(folder.type);

  const checkSelectedFoldersHasDroppedUser = (droppedUserId) => {
    return selectedFolders.find((user) => user?.userData?.id === droppedUserId);
  };

  return (
    <>
      <Draggable axis="none">
        <tooltip title={folder?.type} disableInteractive>
          <ListItem
            draggable={true}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onDragStart={(e) => handleDragStart(e, folder)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              paddingLeft: `${level * 15}px`,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              color: darkMode ? "white" : "black",
              borderRadius: "0.375em",
              marginBottom: "0.1em",
              backgroundColor:
                isFolderSelected ||
                (currentFolder && currentFolder.id === folder.id)
                  ? darkMode
                    ? "rgba(255, 255, 255, 0.2)"
                    : "rgba(0,0,0,0.2)"
                  : "",
              ":hover": {
                backgroundColor: darkMode
                  ? "rgba(255, 255, 255, 0.2)"
                  : "rgba(0,0,0,0.2)",
                borderRadius: "0.375",
                cursor: "pointer",
              },
              transition: "background-color 0.2s",
              position: "relative",
            }}
            onClick={(e) => handleSelected(e, folder)}
            onContextMenu={(e) =>
              !Boolean(moveExplorer) && openContextMenu(e, folder)
            }
          >
            <ListItemIcon
              onClick={handleToggle}
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
                minWidth: "unset",
                color: darkMode ? "white" : "black",
              }}
            >
              {folder?.children?.length > 0 ? (
                isOpen ? (
                  <ExpandMoreIcon color={darkMode ? "white" : "black"} />
                ) : (
                  <ChevronRightIcon color={darkMode ? "white" : "black"} />
                )
              ) : (
                <div
                  style={{ marginLeft: "1em", backgroundColor: "orange" }}
                ></div>
              )}

              {getIcon(
                folder.type,
                darkMode,
                folder.type === "user" &&
                  isUserAssignedAnywhere(folders, folder)
              )}
              <MDTypography style={{ fontSize: "0.8em", marginLeft: "0.4em" }}>
                {folder.name}
              </MDTypography>
            </ListItemIcon>
            {(isHovering || isFolderSelected) && isSelectable && (
              <Checkbox
                checked={isFolderSelected}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  handleCheckboxChange(e, folder);
                }}
                sx={{ position: "absolute", right: "1em" }}
              />
            )}
            {folder.name === "Users List" && (
              <MDBox
                sx={{
                  width: "100%",
                  // mb: 1,
                  // mt: 1,
                  // px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {!showSearchInput ? (
                  <IconButton
                    onClick={handleSearchIconClick}
                    sx={{
                      color: darkMode ? "#fff" : "#000",
                    }}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <Fade in={showSearchInput}>
                    <MDBox
                      sx={{
                        ml: 2,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <MDInput
                        fullWidth
                        autoFocus
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "20px",
                          },
                        }}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <SearchIcon fontSize="small" />
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                      <IconButton
                        onClick={handleSearchClose}
                        sx={{
                          color: darkMode ? "#fff" : "#000",
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </MDBox>
                  </Fade>
                )}
              </MDBox>
            )}
            <Fade in={isHovering}>
              <div>
                <MenuComponent
                  currentFolder={currentFolder}
                  anchorEl={anchorEl}
                  handleCloseMenu={handleCloseMenu}
                  handleOpenAddForm={handleOpenAddForm}
                  marginLeft={"6.3em"}
                />
                <ContextMenu
                  currentFolder={currentFolder}
                  anchorContextMenu={anchorContextMenu}
                  closeContextMenu={closeContextMenu}
                  folder={folder}
                  handleAddClick={handleAddClick}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  setMoveExplorer={setMoveExplorer}
                  setOpenFeatureDialog={setOpenFeatureDialog}
                  handleSetCurrentPage={handleSetCurrentPage}
                  setFolders={setFolders}
                  areaList={areaList}
                  routeList={routeList}
                  userList={userList}
                  onUserDrop={onUserDrop}
                  selectedFolders={selectedFolders}
                />
                <Menu
                  anchorEl={anchorSubMenu}
                  open={Boolean(anchorSubMenu)}
                  onClose={handleCloseSubMenu}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    // onClick={handleDeleteClick}
                    sx={menuItemStyles}
                  >
                    <LocalHospital sx={{ ...iconStyles }} fontSize="1.4em" />
                    Genral OPD
                  </MenuItem>
                  <MenuItem
                    // onClick={handleDeleteClick}
                    sx={menuItemStyles}
                  >
                    <Paid sx={{ ...iconStyles }} fontSize="1.4em" />
                    Paid OPD
                  </MenuItem>
                </Menu>
              </div>
            </Fade>
          </ListItem>
        </tooltip>
      </Draggable>
      <FeaturesDialog
        currentFolder={currentFolder}
        openFeatureDialog={openFeatureDialog}
        setOpenFeatureDialog={setOpenFeatureDialog}
        featureListSelected={featureListSelected}
        setFeatureListSelected={setFeatureListSelected}
        handleAddFeatures={handleAddFeatures}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isEditing}
        onClose={(e) => setIsEditing(false)}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        ></DialogTitle>

        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gridTemplateColumns: `repeat(3, 1fr)`,
            gap: 8,
            padding: "10px 30px 20px 30px",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <MDTypography>Name</MDTypography>
            <MDInput
              name="name"
              value={editableName?.name}
              onChange={handleEditchange}
              size="small"
              onKeyDown={handleEnterKeyPressedSaved}
            />
          </MDBox>
          {/* <MDBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                    }}
                >
                    <MDTypography variant={"h4"} sx={{ marginTop: "5px" }}>
                        <Phone />
                    </MDTypography>
                    <MDInput
                        name="number"
                        value={editableName?.number}
                        onChange={handleEditchange}
                        size="small"
                        placeholder="number"
                        onKeyDown={handleEnterKeyPressedSaved}
                    />
                </MDBox> */}
        </MDBox>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleEditableSave}
          >
            Save
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={(e) => setIsEditing(false)}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>

      {isOpen && (
        <List component="div" disablePadding>
          {folder.name === "Users List"
            ? filterUsers(folder.children || []).map((child) => (
                <Folder
                  key={child.id}
                  folder={child}
                  onFolderClick={onFolderClick}
                  onFolderDelete={onFolderDelete}
                  onFolderEdit={onFolderEdit}
                  onFolderAdd={onFolderAdd}
                  level={level + 1}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  handleSelected={handleSelected}
                  darkMode={darkMode}
                  handleCurrentPage={handleCurrentPage}
                  featureListSelected={featureListSelected}
                  setFeatureListSelected={setFeatureListSelected}
                  folders={folders}
                  setFolders={setFolders}
                  featureList={featureList}
                  moveExplorerCurrentFolder={moveExplorerCurrentFolder}
                  areaList={areaList}
                  routeList={routeList}
                  userList={userList}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                />
              ))
            : (folder.children || []).map((child) => (
                <Folder
                  key={child.id}
                  folder={child}
                  onFolderClick={onFolderClick}
                  onFolderDelete={onFolderDelete}
                  onFolderEdit={onFolderEdit}
                  onFolderAdd={onFolderAdd}
                  level={level + 1}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  handleSelected={handleSelected}
                  darkMode={darkMode}
                  handleCurrentPage={handleCurrentPage}
                  featureListSelected={featureListSelected}
                  setFeatureListSelected={setFeatureListSelected}
                  folders={folders}
                  setFolders={setFolders}
                  featureList={featureList}
                  moveExplorerCurrentFolder={moveExplorerCurrentFolder}
                  areaList={areaList}
                  routeList={routeList}
                  userList={userList}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                />
              ))}
        </List>
      )}
      {roomInputDialog && (
        <AddForm
          addInput={addInput}
          setAddInput={setAddInput}
          entityType={roomInputDialog}
          handleRoomDialogClose={handleRoomDialogClose}
          handleRoomChange={handleRoomChange}
          handleAddRoom={handleAddRoom}
        />
      )}
      {moveExplorer && (
        <MoveExplorer
          folderToMove={currentFolder}
          setFolders={setFolders}
          setMoveExplorer={setMoveExplorer}
          folders={folders}
          handleSelected={handleSelected}
        />
      )}
      <Dialog
        open={dropConfirmation.open}
        onClose={handleCancelDrop}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}>
          <MDTypography variant="h4">Confirm Assignment</MDTypography>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF", pt: 2 }}
        >
          <MDTypography variant="p">
            Are you sure you want to assign user {' '}
            {dropConfirmation.user?.username || dropConfirmation.user?.name}
            to  {' '}
            {dropConfirmation.targetFolder?.type}
            {dropConfirmation.targetFolder?.name}
            ?
          </MDTypography>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}
        >
          <MDBox display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleConfirmDrop}
            >
              Confirm
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleCancelDrop}
            >
              Cancel
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Folder;
