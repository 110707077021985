import { Card, Tab, Tabs, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@material-ui/core";
import MDBox from "components/MDBox";
import AlertView from "./AlertView";
import LocationView from "./LocationView";
import TrainView from "./TrainView";
import DefaultView from "./DefaultView";
import EventList from "./EventList";
import AlarmList from "./AlarmList";
import { useMaterialUIController } from "context";
import CommunicationView from "./CommunicationView";
import UserActivityLog from "application/modules/userActivityLog";


const Attributes = {
  enableSelectDispatcherMenu: true,
  selectUrl: "extensions/listAllDepartment",
  keyField: "department",
  modes: [],
};

export default function Suraksha({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const [selectedTab, setSelectedTab] = useState("Default");
   const [controller] = useMaterialUIController();
   const { activePage } = controller;

    useEffect(() => {
      Attributes && onLoad(Attributes);
      registerCallBacks &&
        registerCallBacks({});
    }, [activePage]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card style={{ marginBottom: "10px", borderRadius: "10px" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#3492eb",
                },
                height: 50,
              }}
            >
              <Tab
                label="Default View"
                value="Default"
                icon={<Icon>view_cozy_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Train View"
                value="Train"
                icon={<Icon>train_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Location View"
                value="Location"
                icon={<Icon>place_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Alert View"
                value="Alert"
                icon={<Icon>warning_amber_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="SOS Alarm List"
                value="Alarm"
                icon={<Icon>access_alarm_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Event List"
                value="Event"
                icon={<Icon>event_icon</Icon>}
                iconPosition="start"
              />
              <Tab
                label="Communication View"
                value="Communication"
                icon={<Icon>phone_in_talk_icon</Icon>}
                iconPosition="start"
              />
            </Tabs>
          </Card>
        </Grid>
        {selectedTab == "Default" && (
          <Grid item xs={12}>
            <DefaultView
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Train" && (
          <Grid item xs={12}>
            <TrainView
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Location" && (
          <Grid item xs={12}>
            <LocationView
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Alert" && (
          <Grid item xs={12}>
            <AlertView
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Alarm" && (
          <Grid item xs={12}>
            <AlarmList
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Event" && (
          <Grid item xs={12}>
            {/* <EventList
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            /> */}
            <UserActivityLog
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
        {selectedTab == "Communication" && (
          <Grid item xs={12}>
            <CommunicationView
              onLoad={onLoad}
              pageId={pageId}
              searchText={searchText}
              registerCallBacks={registerCallBacks}
            />
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}
