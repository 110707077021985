import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import EmptyPage from "../EmptyPage";
import BasicCalendar from "./BasicCalendar";
import Calendar from "./Calendar";

const Schedule = ({
  folders,
  setFolders,
  currentFolder,
  schedules,
  setSchedules,
}) => {
  const [isScheduleExist, setIsScheduleExist] = useState(
    currentFolder?.scheduleIds?.length > 0
  );
  
  return (
    <>
      {isScheduleExist ? (
        <Calendar
          folders={folders}
          setFolders={setFolders}
          currentFolder={currentFolder}
          schedules={schedules}
          setSchedules={setSchedules}
        />
      ) : (
        <EmptyPage setIsScheduleExist={setIsScheduleExist} />
      )}
    </>
  );
};

export default Schedule;
