import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

class RealtimeUpdate {
  constructor() {
    this.services = null;
    this.telemetry = null;
    this.nms = null;
    this.mcx = null;

    this.connected = false;
    this.mcxConnected = false;

    this.nmsEvents = [];
    this.mcxEvents = [];
    this.serviceEvents = [];
    this.telemetryEvents = [];
  }

  connect() {
    if (AuthenticationService.allownmsMenus()) {
      this.nmsEvents.push(`/app/v2/messaging/topic/events`);
    }

    if (AuthenticationService.allowUCConferenceMenus()) {
      this.mcxEvents.push(
        `/app/v1/messaging/topic/user-${AuthenticationService.getUserName()}`
      );
    }

    this.serviceEvents.push(
      `/app/v2/messaging/topic/user-${AuthenticationService.getUserName()}`
    );

    this.telemetryEvents.push(
      `/app/v2/messaging/topic/user-${AuthenticationService.getUserName()}`
    );

    if (AuthenticationService.getAuthenticationToken()) {
      this.services = new Client({
        brokerURL: variables.webSocket.services,
        debug: function (str) {
          loggerFactory.debug(
            this.componentName,
            "Web Socket Event : Services",
            str
          );
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: {
          Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
        },
        webSocketFactory: () => {
          const sock = new SockJS(variables.webSocket.services);
          return sock;
        },
        onConnect: () => {
          this.services.subscribe(
            `/app/v2/messaging/topic/user-${AuthenticationService.getUserName()}`,
            this.onServiceUpdate
          );
        },
      });

      this.telemetry = new Client({
        brokerURL: variables.webSocket.telemetry,
        debug: function (str) {
          loggerFactory.debug(
            this.componentName,
            "Web Socket Event : telemetry",
            str
          );
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: {
          Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
        },
        webSocketFactory: () => {
          const sock = new SockJS(variables.webSocket.telemetry);
          return sock;
        },
        onConnect: () => {
          this.telemetry.subscribe(
            `/app/v2/messaging/topic/user-${AuthenticationService.getUserName()}`,
            this.onTelemetryUpdate
          );
        },
      });

      this.nms = new Client({
        brokerURL: variables.webSocket.telemetry,
        debug: function (str) {
          loggerFactory.debug(
            this.componentName,
            "Web Socket Event : NMS",
            str
          );
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: {
          Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
        },
        webSocketFactory: () => {
          const sock = new SockJS(variables.webSocket.telemetry);
          return sock;
        },
        onConnect: () => {
          this.nms.subscribe(
            `/app/v2/messaging/topic/events`,
            this.onNMSUpdate
          );
        },
      });

      this.mcx = this.createMCXClient(variables.webSocket.mcx);

      this.connected = true;

      if (this.serviceEvents.length > 0) {
        this.services.activate();
      }

      if (this.telemetryEvents.length > 0) {
        this.telemetry.activate();
      }

      if (this.nmsEvents.length > 0) {
        this.nms.activate();
      }

      if (this.mcxEvents.length > 0) {
        this.mcx.activate();
      }
    }
  }

  createMCXClient(brokerURL) {
    return new Client({
      brokerURL,
      debug: function (str) {
        loggerFactory.debug(this.componentName, "Web Socket Event : MCX", str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      connectHeaders: {
        Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
      },
      webSocketFactory: () => new SockJS(brokerURL),
      onConnect: () => {
        loggerFactory.debug("MCX", "STOMP connection established.");
        this.mcxEvents.forEach((event) => {
          this.mcx.subscribe(event, this.onMCXUpdate.bind(this));
        });
        this.mcxConnected = true;
      },
      onDisconnect: () => {
        this.mcxConnected = false;
        loggerFactory.debug("MCX", "STOMP connection closed.");
      },
    });
  }

  async connectMCX(server) {
    loggerFactory.info("MCX connecting with server", server);

    if (this.mcxConnected) {
      await this.mcx.deactivate();
      this.mcxConnected = false;
    }

    this.mcx = this.createMCXClient(
      server
        ? `${window.location.protocol}//${server}/mcx/app/v1/messaging/messages`
        : variables.webSocket.mcx
    );

    if (this.mcxEvents.length > 0) {
      this.mcx.activate();
    }
  }

  receiveMessageFor(topic, callback, callbackId) {
    if (!callbackId) {
      callbackId = topic;
    }

    if (topic === "events") {
      AuthenticationService.nmsCallBacks.set(callbackId, {
        eventTopic: `/app/v2/messaging/topic/${topic}`,
        eventCallback: callback,
      });
    }

    if (!this.connected) {
      this.connect();
    }

    return true;
  }

  receiveMessageForMCX(topic, callback, callbackId) {
    if (!callbackId) {
      callbackId = topic;
    }

    AuthenticationService.mcxCallBacks.set(callbackId, {
      eventTopic: `/app/v1/messaging/topic/${topic}`,
      eventCallback: callback,
    });

    if (!this.connected) {
      this.connect();
    }

    return true;
  }

  receiveMessageForService(topic, callback, callbackId) {
    if (!callbackId) {
      callbackId = topic;
    }

    AuthenticationService.serviceCallBacks.set(callbackId, {
      eventTopic: `/app/v2/messaging/topic/${topic}`,
      eventCallback: callback,
    });

    if (!this.connected) {
      this.connect();
    }

    return true;
  }

  receiveMessageForTelemetry(topic, callback, callbackId) {
    if (!callbackId) {
      callbackId = topic;
    }

    AuthenticationService.telemetryCallBacks.set(callbackId, {
      eventTopic: `/app/v2/messaging/topic/${topic}`,
      eventCallback: callback,
    });

    if (!this.connected) {
      this.connect();
    }

    return true;
  }

  releaseAllSubscriptions() {
    return true;
  }

  onNMSUpdate(message) {
    loggerFactory.debug(
      "NMS Message Received",
      `Message received on NMS Channel ${message.headers.destination}`
    );

    for (let [key, value] of AuthenticationService.nmsCallBacks) {
      if (message.headers.destination === value.eventTopic) {
        loggerFactory.debug(
          "Message Received ",
          `Message Received on NMS Channel for : events : forwarding message to : ${key}`,
          message.body
        );
        value.eventCallback(message);
      }
    }
  }

  onServiceUpdate(message) {
    loggerFactory.debug(
      "Service Message Received",
      `Message received on Service Channel ${message.headers.destination}`
    );

    for (let [key, value] of AuthenticationService.serviceCallBacks) {
      if (message.headers.destination === value.eventTopic) {
        loggerFactory.debug(
          "Message Received ",
          `Message Received on Service Channel for : events : forwarding message to : ${key}`,
          message.body
        );
        value.eventCallback(message);
      }
    }
  }

  onTelemetryUpdate(message) {
    loggerFactory.debug(
      "Telemetry Message Received",
      `Message received on telemetry Channel ${message.headers.destination}`
    );

    for (let [key, value] of AuthenticationService.telemetryCallBacks) {
      if (message.headers.destination === value.eventTopic) {
        loggerFactory.debug(
          "Message Received ",
          `Message Received on telemetry Channel for : events : forwarding message to : ${key}`,
          message.body
        );
        value.eventCallback(message);
      }
    }
  }

  onMCXUpdate(message) {
    loggerFactory.debug(
      "MCX Message Received",
      `Message received on MCX Channel ${message.headers.destination}`
    );
    if (AuthenticationService.mcxCallBacks) {
      for (let [key, value] of AuthenticationService.mcxCallBacks) {
        if (message.headers.destination === value.eventTopic) {
          loggerFactory.debug(
            "Message Received ",
            `Message Received on MCX Channel for : events : forwarding message to : ${key}`,
            message.body
          );

          value.eventCallback(message);
        }
      }
    }
  }
}

const NotificationManager = new RealtimeUpdate();

NotificationManager.connect();

export default NotificationManager;
