import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function UserMasterGroupCell({ row }) {
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    application.post("/userGroupMaster/listAll").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);

 const matchedGroups = listOptions && listOptions.filter((option) => {
   return (
     option.id !== null &&
     row.original.group &&
     row.original.group.includes(option.id)
   );
 });

  return (
    <>
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {matchedGroups &&
          matchedGroups.map((group) => group.description).join(", ")}
      </MDTypography>
    </>
  );
}

export default UserMasterGroupCell;
