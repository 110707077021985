import { useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import DownloadIcon from "@mui/icons-material/Download";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";
function DownloadTaskFile({ row }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const filePath = row?.original?.filePath;
  let extracted = "";
  if (filePath) {
    let index = filePath.lastIndexOf("/");
    extracted = filePath.slice(index + 1);
  }

  const operationURLSS = {
    fileDownload: `file/download?filePath=${filePath}`,
  };

  const onDownloadFile = () => {
    application
      .get(operationURLSS.fileDownload, { responseType: "blob" })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Chat",
          status: "File Download In Progress...",
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filePath.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        loggerFactory.debug("Error during file download:", error);
        setNotify({
          isOpen: true,
          message: "",
          type: "error",
          pagename: "Chat",
          status: "Unable To Download File",
        });
      });
  };

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <Tooltip title="DownloadFile">
        <IconButton
          color="error"
          onClick={() => onDownloadFile(row?.original?.filePath)}
        >
          {filePath ? <DownloadIcon /> : <DoNotDisturbAltOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDTypography>
  );
}

export default DownloadTaskFile;
