import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";

const AddForm = ({
  // addInput,
  setAddInput,
  entityType,
  handleRoomDialogClose,
  handleRoomChange,
  handleAddRoom,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [addForm, setAddForm] = useState({
    name: "",
    to: "",
    from: "",
  });

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = (e, entityType) => {
    handleAddRoom(e, entityType, addForm);
  };

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={Boolean(entityType)}
        onClose={handleRoomDialogClose}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        >
          {entityType.charAt(0).toUpperCase() + entityType.slice(1)}
        </DialogTitle>

        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
            padding: "10px 30px 20px 30px",
          }}
        >
          {
            <TextField
              fullWidth
              sx={{ textAlign: "center" }}
              size="large"
              value={addForm?.name}
              label={"Prefix"}
              placeholder="eg. A"
              variant="outlined"
              name="name"
              onChange={handleAddChange}
              inputProps={{ autoComplete: "off" }}
              keys={"prefix"}
            />
          }
          <MDInput
            fullWidth
            sx={{ textAlign: "center" }}
            size="large"
            label={`From ${
              entityType.charAt(0).toUpperCase() +
              entityType.substring(1).toLowerCase()
            }`}
            placeholder="eg. 10"
            variant="outlined"
            name="from"
            value={addForm?.from}
            onChange={handleAddChange}
            inputProps={{ autoComplete: "off" }}
          />
          <MDInput
            fullWidth
            sx={{ textAlign: "center" }}
            size="large"
            label={`To ${
              entityType.charAt(0).toUpperCase() +
              entityType.substring(1).toLowerCase()
            }`}
            placeholder="eg. 200"
            variant="outlined"
            name="to"
            value={addForm?.to}
            onChange={handleAddChange}
            inputProps={{ autoComplete: "off" }}
          />
          {/* <MDInput
        size="large"
        label="Row Name"
        variant="outlined"
        onChange={handleChangeGroupeName}
      /> */}
        </MDBox>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={(e) => submit(e, entityType)}
          >
            ADD
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleRoomDialogClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddForm;
