import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURL } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import { getUpdateColumns } from "./updateModel";

function UserShift({ onLoad, mode, pageId, searchText, registerCallBacks }) {
  const componentName = "User Shift";
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [formData, setFormData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenUpdate(true);
  };

  const onAgree = () => {
    const body = { intId: [selectedRow.id] };
    application
      .post(operationURL.delete, body)
      .then((response) => {
        setUpdateList(Date.now());
        setSelectedRow([]);
        setConfirmDelete(false);
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: componentName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setSelectedRow([]);
        setConfirmDelete(false);
        setNotify({
          isOpen: true,
          message: "",
          type: "error",
          pagename: componentName,
          status: error.response.data.message,
        });
      });
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  function onCloseForm() {
    setOpenForm(false);
    setOpenUpdate(false);
  }

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURL.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setOpenForm(false);
          setOpenUpdate(false);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: componentName,
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: componentName,
            status: error.response.data.messageDetail,
          });
        });
    } else {
      application
        .post(operationURL.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setOpenForm(false);
          setOpenUpdate(false);
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: componentName,
            status: response.data.message,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: componentName,
            status: error.response.data.messageDetail,
          });
        });
    }
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName={componentName}
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLBaseDialogForm
        entityName={componentName}
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLBaseDialogForm
        entityName={componentName}
        columns={getUpdateColumns(deleteRow, editRow)}
        openForm={openUpdate}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLTable
        apiURL={operationURL.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        updateList={updateList}
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
      />

      <CTLAdvancedFilter
        entityName={componentName}
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default UserShift;
