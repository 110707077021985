import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function DeleteGroup({
  groupType,
  openDeleteGroupConfirmation,
  handleDeleteGroupConfirmationClose,
  handleDeleteGroup,
}) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog
      maxWidth="xl"
      open={openDeleteGroupConfirmation}
      onClose={handleDeleteGroupConfirmationClose}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        confirm delete
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <DialogContentText>
          Do you want to delete this {groupType}?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton
          variant="gradient"
          color="success"
          onClick={handleDeleteGroup}
        >
          Yes
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={() => {
            handleDeleteGroupConfirmationClose();
          }}
        >
          No
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteGroup;
