import React, { useState, useEffect, useRef } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { v4 as uuidv4 } from "uuid";
import {
  ButtonGroup,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Icon,
  IconButton,
  Tooltip,
  Drawer,
  ListItemText,
  Divider,
  TextField,
  List,
  Snackbar,
  ListItem,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ring from "assets/sound/ringing.wav";
import ringBack from "assets/sound/ringback_tone.mp3";
import {
  useMaterialUIController,
  setCallState,
  setClickToCall,
  setIsPreRecordBroadcast,
  setBroadCastCallType,
  setConferenceData,
  setIsDssCall,
  setIsEmergencyCall,
  setIsSOSCall,
} from "context";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";
import MuiAlert from "@mui/material/Alert";
import { TouchApp } from "@material-ui/icons";
import { mcx } from "globals/endpoints";
import {
  Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  RegistererState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions,
  Web,
  InvitationRejectOptions,
  LogLevel,
} from "sip.js";
import MDTypography from "components/MDTypography";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import SIPPhoneService from "application/components/SIPPhone/SIPPhoneService";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import CTLNotification from "application/components/CTLNotification";
import NotificationManager from "globals/websocket/WebSocket";
import { setIsBroadcastCall } from "context";

function MAPPhone(props) {
  const componentName = "SIPPhone";
  const userName = AuthenticationService.getUserName();
  const UserNumber = AuthenticationService.getExtensionNumber();
  const [registration, setRegistration] = useState("Not Registered");
  const [inCall, setInCall] = useState(false);
  const [isSecondCall, setIsSecondCall] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isDialing, setIsDialing] = useState(false);
  const [dialNumber, setDialNumber] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [phoneDisplay, setPhoneDisplay] = useState("block");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [callType, setCallType] = useState();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [pttStatus, setPttStatus] = useState("PTT Available");
  const [showMergeButton, setShowMergeButton] = useState(false);
  const [consultationCall, setConsultationCall] = useState(null);
  const [isMargeCall, setIsMargeCall] = useState("");
  const [isActiveConferenceCall, setIsActiveConferenceCall] = useState(false);
  const [timer, setTimer] = useState("0:0:0");
  const timeoutRef = useRef(null);
  const isMissedCallRef = useRef(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  let talkStart = null;
  let timerThread = null;
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const {
    conferenceData,
    callState,
    clickToCall,
    isPreRecordBroadcast,
    broadcastCallType,
    isDssCall,
    isEmergencyCall,
    isSOSCall,
    broadcastCall,
    isBroadcastCall,
  } = controller;

  const updateCallState = (callInfo) => {
    loggerFactory.debug(componentName, "Dispatching call state", callInfo);
    setCallState(dispatch, callInfo);
  };

  const audioRinging = new Audio(ring);
  audioRinging.loop = true;

  const ringBackTone = new Audio(ringBack);
  ringBackTone.loop = true;

  useEffect(() => {
    const lastDialNumber = AuthenticationService.getLastDialNumber();
    if (lastDialNumber) {
      dial(false, lastDialNumber);
    }
  }, []);

  useEffect(() => {
    if (conferenceData) {
      if (!inCall) {
        dialConferenceCall(true, conferenceData.didMapping);
      }
    }
    if (broadcastCall) {
      if (!inCall) {
        dial(false, broadcastCall.broadcastNumber);
      }
    }
  }, [conferenceData, broadcastCall]);

  const handleMouseDown = () => {
    setIsMouseDown(true);
    unMute(); // Unmute immediately when mouse is down
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    mute(); // Mute when mouse is released
  };

  async function handlePttRequest(isMute) {
    try {
      const url = isMute
        ? `floor/request/${
            conferenceData.accessCode
          }/${AuthenticationService.getUserName()}`
        : `floor/release/${
            conferenceData.accessCode
          }/${AuthenticationService.getUserName()}`;

      const response = await mcx.post(url);

      if (isMute) {
        loggerFactory.info("successfully request ptt");
      } else {
        loggerFactory.info("successfully release ptt");
      }
    } catch (error) {
      if (isMute) {
        loggerFactory.debug("unable to request ptt", error);
      } else {
        loggerFactory.debug("unable to release ptt", error);
      }
    }
  }

  const joinPtt = () => {
    mcx
      .post(
        `floor/join/${
          conferenceData.accessCode
        }/${AuthenticationService.getUserName()}/M}`
      )
      .then((response) => {
        loggerFactory.info("Successfully join PTT.");
      })
      .catch((error) => {
        loggerFactory.debug("Failed to join PTT. Please refresh the page.");
      });
  };

  function handleChange(event) {
    setDialNumber(event.target.value);
  }

  const activityLog = async (
    event,
    eventCategory,
    eventStatus,
    callerNumber,
    destinationNumber,
    eventData
  ) => {
    const bodyData = {
      event: event,
      eventCategory: eventCategory,
      eventStatus: eventStatus,
      callerNumber: callerNumber,
      destinationNumber: destinationNumber,
      eventData: {
        msg: eventData,
      },
      userId: userName,
      deviceId: UserNumber,
      epochDate: Math.floor(Date.now() / 1000),
    };

    try {
      const response = await application.post("activityLog/create", bodyData);
      if (response && response.status === 200) {
        loggerFactory.debug("Activity log successfully created", bodyData);
      } else {
        loggerFactory.debug("Failed to create activity log", response);
      }
    } catch (error) {
      loggerFactory.debug("Error logging activity:", error);
    }
  };

  function onInvite(invitation) {
    const body = {
      phoneNumber:
        invitation.incomingInviteRequest.message.from.uri.normal.user,
      callType: "",
    };
    application
      .post("/leadUpload/update", body)
      .then((response) => {
        setCallType(response.data.data.agentInfo.queueStatusType);
        if (isMissedCallRef.current) {
          setIsSecondCall(true);
          openForIncomingSecondCall(invitation);
        } else {
          openForIncomingCall(invitation);
        }
        loggerFactory.debug(
          componentName,
          "Dispatcher call Data : ",
          response.data.data
        );
      })
      .catch((error) => {
        loggerFactory.debug(
          componentName,
          " unable to get dispatcher call data : ",
          error
        );
      });
  }

  function callIsRinging(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.play();
    callInfo.isRinging = true;
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsDialing(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    callInfo.isDialing = true;
    callInfo.dialNumber = "";
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsConnected(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    startTimer();

    audioRinging.pause();
    callInfo.inCall = true;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = callStatus;
    callInfo.dialNumber = "";

    updateCallState(callInfo);
  }

  function callIsTerminated() {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.pause();

    callInfo.inCall = false;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = "";
    callInfo.dialNumber = "";

    stopTimer();

    updateCallState(callInfo);
  }

  function openForIncomingCall(invitation) {
    const messageFrom = invitation.incomingInviteRequest.message.from;
    const user = messageFrom.uri.normal.user + " : " + messageFrom._displayName;
    const sdp = invitation.request.body;
    const isAudio = sdp?.includes("m=audio");
    const isVideo = sdp?.includes("m=video");

    const XDialType =
      invitation?.incomingInviteRequest?.message?.headers?.[
        "X-Coral-Dial-Type"
      ]?.[0]?.raw || null;

    if (XDialType === "ec") {
      const autoAnswer = AuthenticationService.getAutoAnswerRingCount() || 0;
      if (autoAnswer && autoAnswer > 0) {
        let autoAnswerDelay = autoAnswer * 2 * 1000;
        setTimeout(() => {
          if (inCall) {
            if (SIPPhoneService.inviteSession) {
              SIPPhoneService.inviteSession.bye();
            }
            answerCall(false);
          } else {
            answerCall(false);
          }
        }, autoAnswerDelay);
      }
    }

    if (callType === "ec" || XDialType === "ec") {
      setSnackbarMessage(user);
      callIsRinging(
        "Incoming call from : " +
          messageFrom.uri.normal.user +
          " : " +
          messageFrom._displayName
      );
    } else {
      callIsRinging(
        "Incoming call from : " +
          messageFrom.uri.normal.user +
          " : " +
          messageFrom._displayName
      );
    }
    activityLog(
      "call",
      `Incoming ${isAudio ? "Audio" : "Video"} Call`,
      "Ringing",
      messageFrom.uri.normal.user,
      UserNumber,
      `Incoming call from  ${messageFrom.uri.normal.user}`
    );

    SIPPhoneService.inviteSession = invitation;

    invitation.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          callIsRinging();
          break;
        case SessionState.Establishing:
          callIsRinging();
          break;
        case SessionState.Established:
          const messageTalk =
            SIPPhoneService.inviteSession.incomingInviteRequest.message.from;
          isMissedCallRef.current = true;
          setIsSecondCall(true);
          callIsConnected(
            "Talking : " +
              messageTalk.uri.normal.user +
              " : " +
              messageTalk._displayName
          );
          activityLog(
            "call",
            `Incoming ${isAudio ? "Audio" : "Video"} Call`,
            "Talking",
            messageFrom.uri.normal.user,
            UserNumber,
            `${userName} talking  to ${messageFrom.uri.normal.user}`
          );

          AuthenticationService.setLastDialNumber(messageFrom.uri.normal.user);
          const remoteAudioStream = new MediaStream();
          const remoteVideoStream = new MediaStream();
          const localVideoStream = new MediaStream();

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getSenders()
            .forEach((sender) => {
              if (sender.track) {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              }
            });

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                if (receiver.track.kind === "audio") {
                  remoteAudioStream.addTrack(receiver.track);
                }
                if (receiver.track.kind === "video") {
                  remoteVideoStream.addTrack(receiver.track);
                }
              }
            });
          SIPPhoneService.remoteAudioStream = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.play();

          SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
          SIPPhoneService.remoteVideoMedia.play();
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          callIsTerminated();
          setSnackbarMessage("");
          setIsSecondCall(false);
          if (isMissedCallRef.current) {
            activityLog(
              "call",
              `Incoming ${isAudio ? "Audio" : "Video"} Call`,
              "Disconnected",
              messageFrom.uri.normal.user,
              UserNumber,
              `Call Disconnected`
            );
          } else {
            activityLog(
              "call",
              `Incoming ${isAudio ? "Audio" : "Video"} Call`,
              "Missed Call",
              messageFrom.uri.normal.user,
              UserNumber,
              `Missed Call`
            );
          }

          isMissedCallRef.current = false;

          AuthenticationService.setLastDialNumber("");
          SIPPhoneService.remoteAudioMedia.srcObject = null;
          SIPPhoneService.remoteAudioMedia.pause();

          SIPPhoneService.remoteVideoMedia.srcObject = null;
          SIPPhoneService.remoteVideoMedia.pause();

          SIPPhoneService.localVideoMedia.srcObject = null;
          SIPPhoneService.localVideoMedia.pause();

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }

  function openForIncomingSecondCall(invitation) {
    const messageFrom = invitation.incomingInviteRequest.message.from;
    const user = messageFrom.uri.normal.user + " : " + messageFrom._displayName;
    const sdp = invitation.request.body;
    const isAudio = sdp?.includes("m=audio");
    audioRinging.play();
    setIsRinging(true);
    setIsMargeCall(
      "Incoming call from : " +
        messageFrom.uri.normal.user +
        " : " +
        messageFrom._displayName
    );
    activityLog(
      "call",
      `Incoming ${isAudio ? "Audio" : "Video"} Call`,
      "Ringing",
      messageFrom.uri.normal.user,
      UserNumber,
      `${userName} incoming call from ${messageFrom.uri.normal.user}`
    );

    const secondCallInvitation = invitation;
    setConsultationCall(secondCallInvitation);
    invitation.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          audioRinging.play();
          break;
        case SessionState.Establishing:
          audioRinging.play();
          break;
        case SessionState.Established:
          const messageTalk =
            secondCallInvitation.incomingInviteRequest.message.from;
          hold();
          audioRinging.pause();
          setIsRinging(false);
          setIsMargeCall(
            `Talking : ` +
              messageTalk.uri.normal.user +
              " : " +
              messageTalk._displayName
          );
          activityLog(
            "call",
            `Incoming ${isAudio ? "Audio" : "Video"} Call`,
            "Talking",
            messageFrom.uri.normal.user,
            UserNumber,
            `${userName} talking  to ${messageFrom.uri.normal.user}`
          );

          setShowMergeButton(true);
          const remoteAudioStream = new MediaStream();
          const remoteVideoStream = new MediaStream();
          const localVideoStream = new MediaStream();

          secondCallInvitation.sessionDescriptionHandler.peerConnection
            .getSenders()
            .forEach((sender) => {
              if (sender.track) {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              }
            });

          secondCallInvitation.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                if (receiver.track.kind === "audio") {
                  remoteAudioStream.addTrack(receiver.track);
                }
                if (receiver.track.kind === "video") {
                  remoteVideoStream.addTrack(receiver.track);
                }
              }
            });
          SIPPhoneService.remoteAudioStream = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.play();

          SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
          SIPPhoneService.remoteVideoMedia.play();
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          setIsMargeCall("");
          audioRinging.pause();
          setIsRinging(false);
          activityLog(
            "call",
            `Incoming ${isAudio ? "Audio" : "Video"} Call`,
            "Disconnected",
            messageFrom.uri.normal.user,
            UserNumber,
            `Call Disconnected`
          );

          unHold();
          setShowMergeButton(false);
          setConsultationCall(null);
          setIsSecondCall(false);

          SIPPhoneService.remoteAudioMedia.srcObject = null;
          SIPPhoneService.remoteAudioMedia.pause();

          SIPPhoneService.remoteVideoMedia.srcObject = null;
          SIPPhoneService.remoteVideoMedia.pause();

          SIPPhoneService.localVideoMedia.srcObject = null;
          SIPPhoneService.localVideoMedia.pause();

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }

  function updateTimer() {
    const total = Date.parse(new Date()) - Date.parse(talkStart);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setTimer(`${hours}:${minutes}:${seconds}`);
  }

  function startTimer() {
    talkStart = new Date();
    timerThread = setInterval(() => {
      updateTimer();
    }, 1000);
  }

  function stopTimer() {
    talkStart = null;

    if (timerThread) {
      clearInterval(timerThread);
      timerThread = null;
    }
  }

  function answerCall(asVideoCall) {
    SIPPhoneService.inviteSession.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: asVideoCall,
        },
      },
    });
  }

  function answerSecondCall(asVideoCall) {
    consultationCall.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: asVideoCall,
        },
      },
    });
  }

  function updateRegistrationStatus(status) {
    setRegistration(status);
  }

  function bye() {
    if (consultationCall) {
      if (SIPPhoneService.consultCallRinging) {
        consultationCall.cancel();
      } else {
        consultationCall.bye();
      }
    } else if (SIPPhoneService.inviteSession) {
      SIPPhoneService.inviteSession.bye();
    }
  }

  function secondCallCancel() {
    consultationCall.reject({
      statusCode: 486,
    });
  }

  function cancel() {
    SIPPhoneService.inviteSession.cancel();
  }

  function rejectCall() {
    SIPPhoneService.inviteSession.reject({
      statusCode: 486,
    });
  }

  function mute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = false;
    setIsMute(true);
  }

  function unMute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = true;
    setIsMute(false);
  }

  function hold() {
    const options = {
      sessionDescriptionHandlerModifiers: [Web.holdModifier],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(true);
  }

  function unHold() {
    const options = {
      sessionDescriptionHandlerModifiers: [],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(false);
  }

  function transfer() {
    const target = UserAgent.makeURI(
      "sip:" + dialNumber + "@" + variables.sip.domain
    );
    SIPPhoneService.inviteSession.refer(target);
    activityLog(
      "call",
      "Dial Blind transfer",
      "transfer",
      UserNumber,
      dialNumber,
      `${userName} make Blind transfer ${dialNumber}`
    );
    setDialNumber("");
  }

  function forceTransfer(number) {
    const target = UserAgent.makeURI(
      "sip:" + number + "@" + variables.sip.domain
    );
    SIPPhoneService.inviteSession.refer(target);
    setDialNumber("");
  }

  function keyClicked(key) {
    // if (inCall) {
    //   const options = {
    //     requestOptions: {
    //       body: {
    //         contentDisposition: "render",
    //         contentType: "application/dtmf-relay",
    //         content: `Signal=${key}\r\nDuration=1000`,
    //       },
    //     },
    //   };
    //   SIPPhoneService.inviteSession.info(options);
    // } else {
    setDialNumber(dialNumber + key);
    // }
  }

  function dialConferenceCall(videoCall, number) {
    let toDial = number;

    if (toDial) {
      SIPPhoneService.lastNumberDialed = toDial;

      const number = "sip:" + toDial + "@" + variables.sip.domain;
      const target = UserAgent.makeURI(number);
      setDialNumber("");

      SIPPhoneService.inviteSession = new Inviter(
        SIPPhoneService.userAgent,
        target
      );
      const outgoingRequestMessage =
        SIPPhoneService.inviteSession.outgoingRequestMessage;

      outgoingRequestMessage.setHeader(
        "X-Conferencecall",
        conferenceData.accessCode
      );
      SIPPhoneService.inviteSession.invite({
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: videoCall,
          },
        },
      });

      SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            if (toDial === SIPPhoneService.swipeCode) {
              callIsDialing("Call Swipe Initiated");
            } else {
              callIsDialing(
                "Dialing : " + conferenceData?.deviceNumber == UserNumber
                  ? "Group Call"
                  : conferenceData?.deviceNumber
              );
              {
                conferenceData.meetingStatus == 2 &&
                  application.post(
                    `conferenceRoom/joinDispatcher/${conferenceData.accessCode}`
                  );
              }
              activityLog(
                "event",
                `${conferenceData.type} Conference`,
                "Join",
                UserNumber,
                conferenceData?.deviceNumber == UserNumber
                  ? "Group Call"
                  : conferenceData?.deviceNumber,
                `${userName} join ${conferenceData.type} Conference`
              );
            }
            break;

          case SessionState.Established:
            ringBackTone.pause();
            // joinPtt();
            if (toDial === SIPPhoneService.swipeCode) {
              callIsConnected("Call Swiped");
            } else {
              callIsConnected(
                `Talking : ${
                  conferenceData?.deviceNumber == UserNumber
                    ? "Group Call"
                    : conferenceData?.deviceNumber
                }`
              );
              activityLog(
                "event",
                `${conferenceData.type} Conference`,
                "Talking",
                UserNumber,
                conferenceData?.deviceNumber == UserNumber
                  ? "Group Call"
                  : conferenceData?.deviceNumber,
                `${userName} Talking ${conferenceData.type} Conference`
              );
            }
            const remoteAudioStream = new MediaStream();
            const remoteVideoStream = new MediaStream();
            const localVideoStream = new MediaStream();

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getSenders()
              .forEach((sender) => {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);
                }

                SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                SIPPhoneService.localVideoMedia.play();
              });

            SIPPhoneService.currentSessionAudio.track.enabled = false;
            setIsMute(true);
            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getReceivers()
              .forEach((receiver) => {
                if (receiver.track) {
                  if (receiver.track.kind === "audio") {
                    remoteAudioStream.addTrack(receiver.track);
                  }
                  if (receiver.track.kind === "video") {
                    remoteVideoStream.addTrack(receiver.track);
                  }
                }
              });
            setIsMute(true);
            SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
            SIPPhoneService.remoteAudioMedia.play();

            SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
            SIPPhoneService.remoteVideoMedia.play();
            break;
          case SessionState.Terminated:
            ringBackTone.pause();
            callIsTerminated();
            activityLog(
              "event",
              `${conferenceData.type} Conference`,
              "Disconnected",
              UserNumber,
              conferenceData?.deviceNumber == UserNumber
                ? "Group Call"
                : conferenceData?.deviceNumber,
              `Conference Call Disconnected`
            );
            setIsEmergencyCall(dispatch, false);
            setConferenceData(dispatch, "");
            SIPPhoneService.remoteAudioMedia.srcObject = null;
            SIPPhoneService.remoteAudioMedia.pause();

            SIPPhoneService.remoteVideoMedia.srcObject = null;
            SIPPhoneService.remoteVideoMedia.pause();

            SIPPhoneService.localVideoMedia.srcObject = null;
            SIPPhoneService.localVideoMedia.pause();

            break;
          default:
            break;
        }
      });
    }
  }

  async function dial(videoCall, forceDial) {
    let toDial = forceDial ? forceDial : dialNumber;
    if (toDial) {
      if (isBroadcastCall) {
        activityLog(
          "event",
          "BroadCast Announcement",
          "Dialing",
          UserNumber,
          "Selected User",
          `${userName} dial broadcast call to ${broadcastCall.userList}`
        );
      } else if (isDssCall) {
        activityLog(
          "call",
          "Dial DSS Call",
          "Dialing",
          UserNumber,
          toDial,
          `${userName} dial dss call to ${toDial}`
        );
      } else if (isSOSCall) {
        activityLog(
          "call",
          "Dial SOS Call",
          "Dialing",
          UserNumber,
          toDial,
          `${userName} dial SOS call to ${toDial}`
        );
      } else {
        activityLog(
          "call",
          `Dial ${videoCall ? "Video" : "Audio" } Call`,
          "Dialing",
          UserNumber,
          toDial,
          `${userName} dial  call to ${toDial}`
        );
      }
      SIPPhoneService.lastNumberDialed = toDial;

      const number = "sip:" + toDial + "@" + variables.sip.domain;
      const target = UserAgent.makeURI(number);
      setDialNumber("");

      SIPPhoneService.inviteSession = new Inviter(
        SIPPhoneService.userAgent,
        target
      );

      if (isPreRecordBroadcast) {
        const outgoingRequestMessage =
          SIPPhoneService.inviteSession.outgoingRequestMessage;
        outgoingRequestMessage.setHeader("X-Ec-Broadcast", true);
        const generatedUUID = uuidv4();
        outgoingRequestMessage.setHeader("X-Ec-Callid", generatedUUID);
        {
          broadcastCallType && broadcastCallType.type == "zone"
            ? outgoingRequestMessage.setHeader("X-Zone", broadcastCallType.name)
            : outgoingRequestMessage.setHeader(
                "X-Dept",
                broadcastCallType.name
              );
        }
      }
      if (isBroadcastCall) {
        const outgoingRequestMessage =
          SIPPhoneService.inviteSession.outgoingRequestMessage;
        outgoingRequestMessage.setHeader("X-Pa-Broadcast", true);
        outgoingRequestMessage.setHeader("X-Pa-List", broadcastCall.userList);
      }
      const outgoingRequestMessage =
        SIPPhoneService.inviteSession.outgoingRequestMessage;
      outgoingRequestMessage.setHeader("X-mcxvideo", videoCall ? 1 : 0);

      SIPPhoneService.inviteSession.invite({
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: videoCall,
          },
        },
      });

      SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            if (isBroadcastCall) {
              activityLog(
                "event",
                "BroadCast Announcement",
                "Ringing",
                UserNumber,
                "Selected User",
                `${userName} dial broadcast call to ${broadcastCall.userList}`
              );
            } else if (isDssCall) {
              activityLog(
                "call",
                "Dial DSS Call",
                "Ringing",
                UserNumber,
                toDial,
                `${userName} dial dss call to ${toDial}`
              );
            } else if (isSOSCall) {
              activityLog(
                "call",
                "Dial SOS Call",
                "Ringing",
                UserNumber,
                toDial,
                `${userName} dial SOS call to ${toDial}`
              );
            } else {
              activityLog(
                "call",
                `Dial ${videoCall ? "Video" : "Audio"} Call`,
                "Ringing",
                UserNumber,
                toDial,
                `${userName} dial  call to ${toDial}`
              );
            }
            ringBackTone.play();
            callIsDialing("Dialing : " + toDial);
            break;

          case SessionState.Established:
            ringBackTone.pause();
            callIsConnected(`Talking : ${toDial}`);
            if (isBroadcastCall) {
              activityLog(
                "event",
                "BroadCast Announcement",
                "Talking",
                UserNumber,
                "Selected User",
                `${userName} dial broadcast call to ${broadcastCall.userList}`
              );
            } else if (isDssCall) {
              activityLog(
                "call",
                "Dial DSS Call",
                "Talking",
                UserNumber,
                toDial,
                `${userName} dial dss call to ${toDial}`
              );
            } else if (isSOSCall) {
              activityLog(
                "call",
                "Dial SOS Call",
                "Talking",
                UserNumber,
                toDial,
                `${userName} dial SOS call to ${toDial}`
              );
            } else {
              activityLog(
                "call",
                `Dial ${videoCall ? "Video" : "Audio"} Call`,
                "Talking",
                UserNumber,
                toDial,
                `${userName} dial  call to ${toDial}`
              );
            }
            AuthenticationService.setLastDialNumber(toDial);
            const remoteAudioStream = new MediaStream();
            const remoteVideoStream = new MediaStream();
            const localVideoStream = new MediaStream();

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getSenders()
              .forEach((sender) => {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);
                }
                if (SIPPhoneService.localVideoMedia.srcObject) {
                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              });

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getReceivers()
              .forEach((receiver) => {
                if (receiver.track) {
                  if (receiver.track.kind === "audio") {
                    remoteAudioStream.addTrack(receiver.track);
                  }
                  if (receiver.track.kind === "video") {
                    remoteVideoStream.addTrack(receiver.track);
                  }
                }
              });
            SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
            SIPPhoneService.remoteAudioMedia.play();

            SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
            SIPPhoneService.remoteVideoMedia.play();
            break;
          case SessionState.Terminated:
            ringBackTone.pause();
            callIsTerminated();
            AuthenticationService.setLastDialNumber("");
            setDialNumber("");
            if (isBroadcastCall) {
              activityLog(
                "event",
                "BroadCast Announcement",
                "Disconnected",
                UserNumber,
                "Selected User",
                `${userName} dial broadcast call to ${broadcastCall.userList}`
              );
            } else if (isDssCall) {
              activityLog(
                "call",
                "Dial DSS Call",
                "Disconnected",
                UserNumber,
                toDial,
                "DSS Call Disconnected"
              );
            } else if (isSOSCall) {
              activityLog(
                "call",
                "Dial SOS Call",
                "Disconnected",
                UserNumber,
                toDial,
                "SOS Call Disconnected"
              );
            } else {
              activityLog(
                "call",
                `Dial ${videoCall ? "Video" : "Audio"} Call`,
                "Disconnected",
                UserNumber,
                toDial,
                "Call Disconnected"
              );
            }
            if (clickToCall) {
              setClickToCall(dispatch, "");
              setBroadCastCallType(dispatch, { type: "", name: "" });
            }
            setIsPreRecordBroadcast(dispatch, false);
            setIsDssCall(dispatch, false);
            setIsSOSCall(dispatch, false);
            setIsBroadcastCall(dispatch, false);
            SIPPhoneService.remoteAudioMedia.srcObject = null;
            SIPPhoneService.remoteAudioMedia.pause();

            SIPPhoneService.remoteVideoMedia.srcObject = null;
            SIPPhoneService.remoteVideoMedia.pause();

            SIPPhoneService.localVideoMedia.srcObject = null;
            SIPPhoneService.localVideoMedia.pause();

            break;
          default:
            break;
        }
      });
    } else {
      if (SIPPhoneService.lastNumberDialed) {
        setDialNumber(SIPPhoneService.lastNumberDialed);
      }
    }
  }

  function addNewCall() {
    if (dialNumber != "") {
      if (SIPPhoneService.inviteSession) {
        let toDial = dialNumber;

        const target = UserAgent.makeURI(
          `sip:${dialNumber}@${variables.sip.domain}`
        );
        const consultationCall = new Inviter(SIPPhoneService.userAgent, target);
        setIsMargeCall("Dialing : " + toDial);
        setDialNumber("");
        activityLog(
          "call",
          "Dial Audio Call",
          "Dialing",
          UserNumber,
          toDial,
          `${userName} dial call to ${toDial}`
        );
        hold();
        consultationCall.invite({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: false,
            },
          },
        });
        consultationCall.stateChange.addListener((newState) => {
          switch (newState) {
            case SessionState.Establishing:
              {
                SIPPhoneService.consultCallRinging = true;
                ringBackTone.play();
              }
              break;

            case SessionState.Established:
              SIPPhoneService.consultCallRinging = false;
              ringBackTone.pause();
              setIsMargeCall(`Talking : ${toDial}`);
              activityLog(
                "call",
                "Dial Audio Call",
                "Talking",
                UserNumber,
                toDial,
                `${userName} dial call to ${toDial}`
              );
              setShowMergeButton(true);
              const remoteAudioStream = new MediaStream();

              consultationCall.sessionDescriptionHandler.peerConnection
                .getReceivers()
                .forEach((receiver) => {
                  if (receiver.track) {
                    if (receiver.track.kind === "audio") {
                      remoteAudioStream.addTrack(receiver.track);
                    }
                  }
                });
              SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.play();
              break;

            case SessionState.Terminated: {
              SIPPhoneService.consultCallRinging = false;
              ringBackTone.pause();
              setIsMargeCall("");
              activityLog(
                "call",
                "Dial Audio Call",
                "Disconnected",
                UserNumber,
                toDial,
                `${userName} dial call to ${toDial}`
              );
              SIPPhoneService.remoteAudioMedia.srcObject =
                SIPPhoneService.remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.play();
              unHold();
              setShowMergeButton(false);
              setConsultationCall(null);
              setIsActiveConferenceCall(false);
              break;
            }
          }
        });
        setConsultationCall(consultationCall);
      }
    }
  }

  function addNewVideoCall() {
    if (dialNumber !== "") {
      if (SIPPhoneService.inviteSession) {
        let toDial = dialNumber;

        const target = UserAgent.makeURI(
          `sip:${dialNumber}@${variables.sip.domain}`
        );
        const consultationCall = new Inviter(SIPPhoneService.userAgent, target);
        setIsMargeCall("Dialing : " + dialNumber);
        setDialNumber("");
        activityLog(
          "call",
          "Dial Video Call",
          "Dialing",
          UserNumber,
          toDial,
          `${userName} dial call to ${toDial}`
        );
        hold();
        consultationCall.invite({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: true,
            },
          },
        });
        consultationCall.stateChange.addListener((newState) => {
          switch (newState) {
            case SessionState.Establishing:
              SIPPhoneService.consultCallRinging = true;
              ringBackTone.play();
              break;

            case SessionState.Established:
              SIPPhoneService.consultCallRinging = false;
              ringBackTone.pause();
              setIsMargeCall(`Talking : ${toDial}`);
              setShowMergeButton(true);
              activityLog(
                "call",
                "Dial Video Call",
                "Talking",
                UserNumber,
                toDial,
                `${userName} dial call to ${toDial}`
              );
              const remoteAudioStream = new MediaStream();
              const remoteVideoStream = new MediaStream();

              consultationCall.sessionDescriptionHandler.peerConnection
                .getReceivers()
                .forEach((receiver) => {
                  if (receiver.track) {
                    if (receiver.track.kind === "audio") {
                      remoteAudioStream.addTrack(receiver.track);
                    } else if (receiver.track.kind === "video") {
                      remoteVideoStream.addTrack(receiver.track);
                    }
                  }
                });

              SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.play();

              SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
              SIPPhoneService.remoteVideoMedia.play();
              break;

            case SessionState.Terminated:
              SIPPhoneService.consultCallRinging = false;
              ringBackTone.pause();
              setIsMargeCall("");
              activityLog(
                "call",
                "Dial Video Call",
                "Disconnected",
                UserNumber,
                toDial,
                `${userName} dial call to ${toDial}`
              );
              SIPPhoneService.remoteAudioMedia.srcObject =
                SIPPhoneService.remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.play();
              unHold();
              setShowMergeButton(false);
              setConsultationCall(null);
              break;
          }
        });

        setConsultationCall(consultationCall);
      }
    }
  }

  function consultationCallTransfer() {
    if (SIPPhoneService.inviteSession && consultationCall) {
      const localSession = SIPPhoneService.inviteSession;
      const remoteSession = consultationCall;
      if (localSession && remoteSession) {
        localSession.refer(remoteSession);
        unHold();
        activityLog(
          "call",
          "Dial call Consult transfers",
          "Transfers",
          UserNumber,
          remoteSession?.outgoingRequestMessage?.to?.uri?.normal?.user,
          `${userName} make Consult transfers call`
        );
        setDialNumber("");
        setConsultationCall(null);
        setShowMergeButton(false);
      } else {
        loggerFactory.error(
          "Error in consultationCallsMerge: Local session or remote session is undefined"
        );
      }
    } else {
      loggerFactory.error(
        "Error in consultationCallsMerge: SIPPhoneService.inviteSession or consultationCall is undefined"
      );
    }
  }

  function thirdPartyConferenceCall() {
    if (SIPPhoneService.inviteSession && consultationCall) {
      if (consultationCall) {
        consultationCall.info({
          requestOptions: {
            body: {
              contentDisposition: "render",
              contentType: "application/dtmf-relay",
              content: "Signal=5\r\nDuration=1000",
            },
          },
        });
        unHold();
        activityLog(
          "call",
          "Dial call ThirdParty Conference",
          "thirdParty Conference",
          UserNumber,
          "Conference Call",
          `${userName} make thirdParty Conference call`
        );
        setIsMargeCall("");
      } else {
        loggerFactory.error(
          "Error in thirdPartyConferenceCall: Local session or remote session is undefined"
        );
      }
      setConsultationCall(null);
      callIsDialing("Conference Call");
    }
    setShowMergeButton(false);
    setIsActiveConferenceCall(true);
  }

  useEffect(() => {
    if (!SIPPhoneService.callbacks.invite) {
      SIPPhoneService.callbacks.invite = onInvite;
      SIPPhoneService.callbacks.registration = updateRegistrationStatus;
      SIPPhoneService.remoteAudioMedia = document.getElementById("remoteAudio");
      SIPPhoneService.remoteVideoMedia = document.getElementById("remoteVideo");
      SIPPhoneService.localVideoMedia = document.getElementById("localVideo");
    }
    setRegistration(SIPPhoneService.registeredState);
    loggerFactory.debug(componentName, "State Update", callState);
    if (callState) {
      setCallStatus(callState.callStatus);
      setDialNumber(callState.dialNumber);
      setInCall(callState.inCall);
      setIsDialing(callState.isDialing);
      setIsMute(callState.isMute);
      setIsOnHold(callState.isOnHold);
      setIsRinging(callState.isRinging);
    }
  }, [callState]);

  useEffect(() => {
    setDialNumber(clickToCall);
    dial(false, clickToCall);
    loggerFactory.debug(componentName, "Event clickToCall ", clickToCall);
  }, [clickToCall]);

  const [state, setState] = useState({ right: false });
  const [participantList, setParticipantList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [participantNumber, setParticipantNumber] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  function fetchData(data) {
    if (data) {
      application
        .post(`conferenceParticipant/confCallDetail/${data}`)
        .then((response) => {
          setParticipantList(response.data.data);
        })
        .catch((error) => {
          setParticipantList([]);
          loggerFactory.debug("Unable to get Conference Participant ", error);
        });
    }
  }

  useEffect(() => {
    if (participantList) {
      const filtered = participantList.filter(
        (item) =>
          item.contact?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.contact.includes(searchQuery)
      );
      setFilteredList(filtered);
    }
  }, [participantList, searchQuery]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const data = isEmergencyCall ? conferenceData.accessCode : clickToCall;
    fetchData(data);
    setState({ ...state, ["left"]: open });
  };

  const handleBard = (data) => {
    if (data) {
      const requestData = isEmergencyCall
        ? conferenceData.accessCode
        : clickToCall;
      application
        .post(
          `conferenceParticipant/${requestData}/kick/${data.id}/${data.contact}`
        )
        .then((response) => {
          fetchData(requestData);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: "Conference",
            status: response.data.messageDetail,
          });
          loggerFactory.info("successfully kick out user ", contactNumber);
        })
        .catch((error) => {
          loggerFactory.debug("Unable to kick out ", error);
        });
    }
  };

  const handleMarkerMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClickClose = () => {
    setOpen(false);
    setMessage("");
  };

  function handleBye() {
    if (conferenceData) {
      const body = {
        id: conferenceData.id,
        value: 2,
      };
      application.post("conferenceRoom/update/status", body);
      bye();
    }
  }

  function onMessageSend() {
    if (conferenceData) {
      const body = {
        accessCode: conferenceData.accessCode,
        remarks: "Acknowledged",
      };
      application.post("conferenceRoom/meetingAddressed", body);
      bye();
    }
  }

  const handleAddParticipant = () => {
    if (conferenceData && participantNumber) {
      application.post(
        `conferenceParticipant/dial/${participantNumber}/${conferenceData.accessCode}`
      );
    }
    setParticipantNumber("");
  };

  const handleNumberChange = (event) => {
    setParticipantNumber(event.target.value);
  };

  const list = (anchor) => (
    <MDBox
      sx={{ width: 400, overflow: "hidden" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ padding: "10px" }}
    >
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MDTypography variant="h6">Participant List</MDTypography>
      </MDBox>
      <Divider />
      <TextField
        label="Participant Number"
        variant="outlined"
        value={participantNumber}
        onChange={handleNumberChange}
        autoComplete="off"
      />
      &nbsp; &nbsp; &nbsp; &nbsp;
      <MDButton
        color="info"
        style={{ height: "45px" }}
        disabled={participantNumber == "" ? true : false}
        onClick={() => handleAddParticipant()}
      >
        Add Participant
      </MDButton>
      <Divider />
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchInputChange}
        autoComplete="off"
      />
      <Divider />
      <List fullWidth>
        {filteredList && filteredList.length === 0 ? (
          <MDTypography variant="h6" style={{ textAlign: "center" }}>
            No participant found
          </MDTypography>
        ) : (
          filteredList &&
          filteredList.map((item, index) => (
            <>
              <ListItem
                key={item.participantId}
                disablePadding
                sx={{ cursor: "auto" }}
              >
                <MDTypography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={"Remove"}>
                    <IconButton
                      variant="outlined"
                      onClick={() => handleBard(item)}
                    >
                      <Icon color="error">remove_circle_outline_icon</Icon>
                    </IconButton>
                  </Tooltip>

                  <ListItemText primary={item.contact} />
                </MDTypography>
              </ListItem>
              <Divider />
            </>
          ))
        )}
      </List>
    </MDBox>
  );

  return (
    <Card id="phone" style={{ height: "100%", width: "auto" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isRinging && snackbarMessage}
        onClose={!isRinging}
      >
        <MuiAlert variant="filled" severity={"error"}>
          Alert Emergency Call - {snackbarMessage}
          &nbsp;
          <Tooltip title="Reject Call">
            <MDButton
              color="info"
              variant="contained"
              id="call"
              size="medium"
              onClick={() => rejectCall()}
            >
              <Icon color="error">call_end</Icon>
            </MDButton>
          </Tooltip>
          &nbsp;
          <Tooltip title="Answer Call">
            <MDButton
              color="success"
              variant="contained"
              id="call"
              size="medium"
              onClick={() => answerCall(false)}
            >
              <Icon color="text">phone</Icon>
            </MDButton>
          </Tooltip>
        </MuiAlert>
      </Snackbar>
      {!AuthenticationService.getDeskPhoneStatus() && (
        <MDTypography
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
            minHeight: "40px",
          }}
        >
          <Icon
            style={{ marginTop: "5px", marginRight: "5px" }}
            color={registration === "Registered" ? "success" : "error"}
          >
            circle
          </Icon>
          {SIPPhoneService.userAgentOptions.authorizationUsername +
            " " +
            SIPPhoneService.userAgentOptions.displayName}
        </MDTypography>
      )}
      {AuthenticationService.getDeskPhoneStatus() && (
        <MDTypography
          style={{
            marginBottom: "5px",
            marginTop: "5px",
            textAlign: "center",
            minHeight: "20px",
          }}
        >
          <Icon
            style={{ marginTop: "5px", marginRight: "5px" }}
            color={registration === "Registered" ? "Desk Phone" : "Desk Phone"}
          >
            circle
          </Icon>
          {SIPPhoneService.userAgentOptions.authorizationUsername +
            " " +
            SIPPhoneService.userAgentOptions.displayName}
        </MDTypography>
      )}
      {/* {isEmergencyCall && inCall && (
        <MDTypography
          style={{
            textAlign: "center",
            minHeight: "10px",
            marginBottom: "10px",
          }}
          component="p"
          variant="caption"
          color="text"
        >
          {pttStatus}
        </MDTypography>
      )} */}
      <audio id="remoteAudio">
        <p>Your browser doesn't support HTML5 audio.</p>
      </audio>{" "}
      <MDBox id="video-block" style={{ width: "auto", height: "250px" }}>
        <video
          id="remoteVideo"
          style={{
            width: "100%",
            maxHeight: "250px",
            objectFit: "cover",
          }}
        ></video>
        <video id="localVideo" style={{ width: "0%", display: "none" }}></video>
      </MDBox>
      <MDBox style={{ maxWidth: "auto" }}>
        {!isMargeCall ? (
          <MDTypography
            style={{
              marginBottom: "20px",
              textAlign: "center",
              minHeight: "20px",
            }}
          >
            {callStatus}
          </MDTypography>
        ) : (
          <MDTypography
            style={{
              marginBottom: "20px",
              textAlign: "center",
              minHeight: "20px",
            }}
          >
            {isMargeCall}
          </MDTypography>
        )}
        {inCall && (
          <MDTypography
            variant="h6"
            style={{
              marginBottom: "5px",
              textAlign: "center",
              minHeight: "20px",
            }}
          >
            Duration : {timer}
          </MDTypography>
        )}
        <MDInput
          fullWidth
          style={{ marginBottom: "10px" }}
          value={dialNumber}
          onChange={handleChange}
        ></MDInput>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="1"
              color="dark"
              onClick={() => keyClicked("1")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              1
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="2"
              color="dark"
              onClick={() => keyClicked("2")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              2
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="3"
              color="dark"
              onClick={() => keyClicked("3")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              3
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="4"
              color="dark"
              onClick={() => keyClicked("4")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              4
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="5"
              color="dark"
              onClick={() => keyClicked("5")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              5
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="6"
              color="dark"
              onClick={() => keyClicked("6")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              6
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="7"
              color="dark"
              onClick={() => keyClicked("7")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              7
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="8"
              color="dark"
              onClick={() => keyClicked("8")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              8
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="9"
              color="dark"
              onClick={() => keyClicked("9")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              9
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="*"
              color="dark"
              onClick={() => keyClicked("*")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              *
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="0"
              color="dark"
              onClick={() => keyClicked("0")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              0
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="#"
              color="dark"
              onClick={() => keyClicked("#")}
              style={{ fontSize: "large", padding: "0px" }}
            >
              #
            </MDButton>
          </Grid>
        </Grid>
        <MDBox
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <ButtonGroup>
            {isRinging && !isSecondCall && (
              <Tooltip title="Answer Video Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(true)}
                >
                  <Icon color="success">videocam</Icon>
                </MDButton>
              </Tooltip>
            )}
            {isRinging && isSecondCall && (
              <Tooltip title="Answer Video Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerSecondCall(true)}
                >
                  <Icon color="text">videocam</Icon>
                </MDButton>
              </Tooltip>
            )}
            {isRinging && !isSecondCall && (
              <Tooltip title="Answer Audio Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(false)}
                >
                  <Icon color="success">phone</Icon>
                </MDButton>
              </Tooltip>
            )}
            {isRinging && isSecondCall && (
              <Tooltip title="Answer Audio Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerSecondCall(false)}
                >
                  <Icon color="text">phone</Icon>
                </MDButton>
              </Tooltip>
            )}
            {isRinging && !isSecondCall && (
              <Tooltip title="Reject Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => rejectCall()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {!inCall && !isRinging && !isDialing && (
              <Tooltip title="Video Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => dial(true)}
                >
                  <Icon color="success">videocam</Icon>
                </MDButton>
              </Tooltip>
            )}
            {!inCall && !isRinging && !isDialing && (
              <Tooltip title="Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => dial(false)}
                >
                  <Icon color="success">phone</Icon>
                </MDButton>
              </Tooltip>
            )}
            {/* {!inCall && !isRinging && !isDialing && (
              <Tooltip title="Swipe">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => dial(false, SIPPhoneService.swipeCode)}
                >
                  <Icon color="success">swap_horiz</Icon>
                </MDButton>
              </Tooltip>
            )} */}

            {isDialing && !inCall && (
              <Tooltip title="Cancel Call">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => cancel()}
                >
                  <Icon color="success">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}

            {!isEmergencyCall && inCall && !isRinging && (
              <Tooltip title="Disconnect">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => bye()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {!isEmergencyCall && inCall && isRinging && (
              <Tooltip title="Reject">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => secondCallCancel()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isEmergencyCall && (
              <Tooltip title="Disconnect">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => handleBye()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isEmergencyCall && (
              <Tooltip title="Disconnect with acknowledge">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => onMessageSend()}
                >
                  <Icon color="error">call_end</Icon>{" "}
                  <Icon color="error">done_icon</Icon>
                </MDButton>
              </Tooltip>
            )}

            {/* {inCall && (
              <Tooltip title="Call Park">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => forceTransfer(SIPPhoneService.callPark)}
                >
                  <Icon color="success">file_upload</Icon>
                </MDButton>
              </Tooltip>
            )}
            {!inCall && !isRinging && !isDialing && (
              <Tooltip title="Un Park">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => dial(false, SIPPhoneService.callUnPark)}
                >
                  <Icon color="success">file_download</Icon>
                </MDButton>
              </Tooltip>
            )} */}

            {inCall && !isEmergencyCall && !isOnHold && (
              <Tooltip title="Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => hold()}
                >
                  <Icon color="warning">phone_paused</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && !isEmergencyCall && isOnHold && (
              <Tooltip title="Un Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unHold()}
                >
                  <Icon color="success">phone_in_talk</Icon>
                </MDButton>
              </Tooltip>
            )}

            {isEmergencyCall && inCall && (
              <MDButton
                variant="contained"
                id="call"
                size="medium"
                color={isMute ? "error" : "success"}
                // onClick={() => handlePttRequest(isMute)}
                onMouseUp={() => handleMouseUp()}
                onMouseDown={() => handleMouseDown()}
              >
                <TouchApp> </TouchApp>
                <Typography variant="button">
                  {isMute ? "PTT-OFF" : "PTT-ON"}
                </Typography>
              </MDButton>
            )}
            {inCall && !isMute && !isEmergencyCall && (
              <Tooltip title="Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => mute()}
                >
                  <Icon color="error">mic_off</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isMute && !isEmergencyCall && (
              <Tooltip title="Un Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unMute()}
                >
                  <Icon color="error">mic</Icon>
                </MDButton>
              </Tooltip>
            )}
            {/* {inCall && (
              <Tooltip title="Transfer">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => transfer()}
                >
                  <Icon color="success">phone_forwarded</Icon>
                </MDButton>
              </Tooltip>
            )} */}
            {dialNumber.length > 0 && (
              <MDButton
                color="info"
                variant="contained"
                id="call"
                size="medium"
                onClick={() => setDialNumber("")}
              >
                <Icon color="warning">clear</Icon>
              </MDButton>
            )}
            {inCall && isEmergencyCall && (
              <Tooltip title="participant List">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={toggleDrawer(true)}
                >
                  <Icon color="text">group_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
          </ButtonGroup>
        </MDBox>

        <MDBox
          style={{
            textAlign: "center",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <ButtonGroup>
            {inCall && !showMergeButton && !isEmergencyCall && (
              <Tooltip title="Transfer">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => transfer()}
                >
                  <Icon color="success">phone_forwarded</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && !showMergeButton && !isEmergencyCall && (
              <Tooltip title="Add">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => addNewCall()}
                >
                  <Icon color="success">person_add_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && !showMergeButton && !isEmergencyCall && (
              <Tooltip title="Add">
                <MDButton
                  color="info"
                  variant="contained"
                  id="VideoCall"
                  size="medium"
                  onClick={() => addNewVideoCall()}
                >
                  <Icon color="success">video_call_icon</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showMergeButton && !isEmergencyCall && (
              <Tooltip title={"Consultation Transfer"}>
                <MDButton
                  color="success"
                  variant="contained"
                  id="merge-call"
                  size="medium"
                  onClick={() => consultationCallTransfer()}
                >
                  <Icon color="info">phone_forwarded</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showMergeButton && !isEmergencyCall && (
              <Tooltip title="Third Party Conference">
                <MDButton
                  color="success"
                  variant="contained"
                  id="merge-call"
                  size="medium"
                  onClick={() => thirdPartyConferenceCall()}
                >
                  <Icon color="info">merge_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
          </ButtonGroup>
        </MDBox>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer(false)}
          sx={{ "& .MuiDrawer-paper": { width: 400, overflow: "hidden" } }}
        >
          {list("left")}
        </Drawer>

        <Dialog open={open} maxWidth="sm">
          <DialogTitle
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            id="alert-dialog-title"
          >
            Remarks
          </DialogTitle>
          <DialogContent
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            style={{ paddingTop: "10px" }}
          >
            <MDBox sx={{ width: 500 }}>
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                variant="outlined"
                label="Type your message..."
                value={message}
                onChange={handleMarkerMessageChange}
                multiline
                rows={6}
              />
            </MDBox>
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={handleClickClose} color="secondary">
              Cancel
            </MDButton>
            <MDButton onClick={onMessageSend} color="success" autoFocus>
              Submit
            </MDButton>
          </DialogActions>
        </Dialog>
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>
    </Card>
  );
}

export default MAPPhone;
