import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RoomIcon from "@mui/icons-material/Room";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  useMaterialUIController,
  setActivePage,
  setConferenceData,
  setDialBroadcast,
  setActionButton,
} from "context";
import { useEffect, useState } from "react";
import CalendarRoot from "./CalendarRoot";
import PersonIcon from "@mui/icons-material/Person";
import TopicIcon from "@mui/icons-material/Topic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItemText,
  Stack,
  Typography,
  Switch,
  styled,
  Checkbox,
  ListItemButton,
} from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import MDButton from "components/MDButton";
import CTLConferenceDelete from "application/components/CTLConferenceDelete";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import CTLConferenceCancel from "application/components/CTLConferenceCancel";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ConferenceList from "../ConferenceList";
import PlayDistorsion from "application/components/playDistorsion";
import CTLConferenceUserList from "application/components/CTLConferenceUserList";
import NextEvents from "../NextEvents";
import loggerFactory from "globals/logger/logger-factory";
import { Campaign, Tune } from "@mui/icons-material";
import MDBadge from "components/MDBadge";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(22px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function Calendar({
  setOpen,
  setSelectedStartDate,
  setSelectedEndDate,
  setApiFormData,
  setCalendarKey,
  publicModeCount,
  localModeCount,
  calendarKey,
  openPopup,
  setConferenceMode,
  setOpenPopup,
  data,
  setSchedule,
  operationURLS,
  header,
  ...rest
}) {
  const currentDate = new Date();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [type, setType] = useState("dayGridMonth");
  const [eventData, setEventData] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [phoneType, setPhoneType] = useState("");
  const [conferenceType, setConferenceType] = useState(false);
  const [calendarType, setCalendarType] = useState("month");
  const [participantList, setParticipantList] = useState(null);
  const [disable, setDisable] = useState(false);
  const [openDistorsion, setOpenDistorsion] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    setCalendarKey(Date.now());
  }, [darkMode]);
  const classNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: classNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  const headerToolbar = {
    left: "today prev,next",
    center: "title",
    right: "dayButton,weekButton,monthButton",
  };

  const customButtons = {
    // syncButton: {
    //   text: "↻",
    //   click: () => {
    //     setCalendarKey(Date.now());
    //   },
    // },
    weekButton: {
      text: "Week",
      click: () => {
        setType("timeGridWeek");
        setCalendarType("week");
        setCalendarKey(Date.now());
      },
    },
    dayButton: {
      text: "Day",
      click: () => {
        setType("timeGridDay");
        setCalendarType("day");
        setCalendarKey(Date.now());
      },
    },
    monthButton: {
      text: "Month",
      click: () => {
        setType("dayGridMonth");
        setCalendarType("month");
        setCalendarKey(Date.now());
      },
    },
  };

  const handleDateClick = (info) => {
    const clickedDate = info.date;
    if (calendarType == "month") {
      clickedDate.setHours(currentDate.getHours());
      clickedDate.setMinutes(currentDate.getMinutes() + 1);
    }
    if (clickedDate >= currentDate) {
      setOpen(true);
      setSelectedStartDate(clickedDate);
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: "Conference",
        status: "Cannot create a conference with a past date.",
      });
    }
  };

  const handleDateSelect = (info) => {
    const StartDate = info.start;
    const EndDate = info.end;
    setSelectedDate(StartDate);
    if (StartDate >= currentDate) {
      setSelectedStartDate(StartDate);
      setSelectedEndDate(EndDate);
      setOpen(true);
    }
  };

  const handleDateUnselect = () => {
    setSelectedDate(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const handleCancel = () => {
    setConfirmCancel(true);
  };

  const handleAgreeCancel = () => {
    const body = {
      modId: eventData.modId,
    };
    application
      .post(operationURLS.cancel, body)
      .then((response) => {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
    setCalendarKey(Date.now());
    setConfirmCancel(false);
    setOpenPopup(false);
  };

  const onCancel = () => {
    setConfirmCancel(false);
    setConfirmDelete(false);
  };

  const handleUpdate = () => {
    setOpen(true);
    setApiFormData(eventData);
    setOpenPopup(false);
  };

  const handleSchedule = () => {
    setOpen(true);
    setSchedule(true);
    setApiFormData(eventData);
    setOpenPopup(false);
  };

  const handleEventClick = (info) => {
    const json = JSON.parse(info?.event?._def?.publicId);
    setEventData(json);
    setOpenPopup(true);
    fetchData(json?.modId);
    if (info.event.end < currentDate || info.event.start > currentDate) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const handleUserEventsClick = (item) => {
    setEventData(item);
    fetchData(item?.modId);

    const scheduledToDate = new Date(item.scheduledToDate);
    const scheduledFromDate = new Date(item.meetingScheduledDate);

    const timePartsTo = item.toTime.split(":");
    const timePartsFrom = item.fromTime.split(":");

    scheduledToDate.setHours(parseInt(timePartsTo[0], 10));
    scheduledToDate.setMinutes(parseInt(timePartsTo[1], 10));
    scheduledFromDate.setHours(parseInt(timePartsFrom[0], 10));
    scheduledFromDate.setMinutes(parseInt(timePartsFrom[1], 10));

    if (scheduledToDate < currentDate || scheduledFromDate > currentDate) {
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: "Conference",
        status:
          "The meeting has not started yet.Please wait until the meeting start",
      });
    } else {
      setConferenceData(dispatch, item);
      if (item.createdBy == AuthenticationService.getUserName()) {
        setPhoneType("moderator");
        setConferenceType();
      } else {
        setPhoneType("participant");
        setConferenceType();
      }
    }
  };

  const fetchData = async (modId) => {
    try {
      const response = await application.post(
        `conferenceParticipant/list/${modId}`
      );
      if (response.data) {
        setParticipantList(response.data.data);
      }
    } catch (error) {
      loggerFactory.debug("Failed to load Participant list-", error);
    }
  };

  const handleEventLeave = () => {
    setOpenPopup(false);
    setParticipantList(null);
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const onAgree = (data) => {
    application
      .post(operationURLS.delete, {
        intId: [data.id],
      })
      .then((response) => {
        setOpenPopup(false);
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.status,
          type: "error",
          pagename: "Conference",
          status: error.response.data.message,
        });
      });
    setOpenPopup(false);
    setConfirmDelete(false);
    setCalendarKey(Date.now());
  };

  const convertTimeTo12HourFormat = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleEventDrop = (info) => {
    const meetingDetails = JSON.parse(info?.event?._def?.publicId);
    const dropEventStartTime = new Date(info.event.start);
    if (dropEventStartTime) {
      if (meetingDetails.createdBy == AuthenticationService.getUserName()) {
        var DropStartDate = new Date(info?.event?.start);
        var DropEndDate = new Date(info?.event?.end);

        var formattedDate = DropStartDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        var formattedToDate = DropEndDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        const adjustedFromHour =
          DropStartDate.getHours() === 24 ? "00" : DropStartDate.getHours();

        const formattedFromTime = `${adjustedFromHour
          .toString()
          .padStart(2, "0")}:${DropStartDate.getMinutes()
          .toString()
          .padStart(2, "0")}`;

        const adjustedToHour =
          DropStartDate.getHours() === 24 ? "00" : DropEndDate.getHours();

        const formattedToTime = `${adjustedToHour
          .toString()
          .padStart(2, "0")}:${DropEndDate.getMinutes()
          .toString()
          .padStart(2, "0")}`;

        meetingDetails.meetingScheduledDate = formattedDate;
        meetingDetails.scheduledToDate = formattedToDate;
        meetingDetails.fromTime = formattedFromTime;
        meetingDetails.toTime = formattedToTime;
        application
          .post(operationURLS.update, meetingDetails)
          .then((response) => {
            setCalendarKey(Date.now());
            setNotify({
              isOpen: true,
              message: response?.data?.messageDetail,
              type: "success",
              pagename: "Conference Event",
              status: response?.data?.message,
            });
          })
          .catch((error) => {
            setCalendarKey(Date.now());
            setNotify({
              isOpen: true,
              message: error.response?.data?.messageDetail,
              type: "error",
              pagename: "Conference Event",
              status: error.response?.data?.message,
            });
          });
      } else {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: "",
          type: "info",
          pagename: "Conference Event",
          status: `This conference Created By ${meetingDetails.createdBy}, You can't modify`,
        });
      }
    } else {
      setCalendarKey(Date.now());
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: "Conference Event",
        status: `This conference can't drag on past day`,
      });
    }
  };

  const joinConference = (data, type) => {
    setConferenceData(dispatch, data);
    handleEventLeave();
    setPhoneType("participant");
    setConferenceType(type);
  };

  const startConference = (data, type) => {
    setConferenceData(dispatch, data);
    handleEventLeave();
    setPhoneType("moderator");
    setConferenceType(type);
  };

  function HandleOpenDistorsion() {
    setOpenDistorsion(true);
  }

  function HandleCloseDistorsion() {
    setOpenDistorsion(false);
  }

  const handleChangeMode = (e) => {
    setConferenceMode(e.target.checked);
  };

  const [openBroadcast, setOpenBroadcast] = useState(false);
  const [ActiveConferenceList, setActiveConferenceList] = useState([]);
  const [selectedConferences, setSelectedConferences] = useState([]);

  const handleOpenBroadcastList = () => {
    application
      .post("conferenceRoom/active/list")
      .then((response) => {
        setActiveConferenceList(response.data.data);
        setOpenBroadcast(true);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.status,
          type: "error",
          pagename: "Conference",
          status: error.response.data.message,
        });
      });
  };

  const handleBroadcastLeave = () => {
    setOpenBroadcast(false);
    setSelectedConferences([]);
  };

  const handleMulticastStart = () => {
     setActionButton(dispatch, "Multicast");
     setPhoneType("moderator");
     setDialBroadcast(dispatch, selectedConferences);
    loggerFactory.info(
      "selected conference for broadcast ",
      selectedConferences
    );
    setOpenBroadcast(false);
    setSelectedConferences([]);
  };

  const handleToggle = (item) => {
    const currentIndex = selectedConferences.indexOf(item);
    const newSelectedConferences = [...selectedConferences];
    if (currentIndex === -1) {
      newSelectedConferences.push(item);
    } else {
      newSelectedConferences.splice(currentIndex, 1);
    }
    setSelectedConferences(newSelectedConferences);
  };

  const handleBroadcast = () => {
    application
      .post("conferenceRoom/active/list")
      .then((response) => {
        const data = response.data.data;
        if (data.length !== 0) {
          setActionButton(dispatch, "Broadcast");
          setPhoneType("moderator");
          setDialBroadcast(dispatch, data);
        }
        
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.status,
          type: "error",
          pagename: "Conference",
          status: error.response.data.message,
        });
      });
  };

  return (
    <>
      {eventData && participantList && (
        <Dialog
          open={openPopup}
          onClose={handleEventLeave}
          fullWidth
          fullScreen
        >
          <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <Grid container spacing={1} direction="row">
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {eventData?.createdBy ==
                  AuthenticationService.getUserName() && (
                  <>
                    <tooltip title="Edit Conference">
                      <MDButton
                        color="info"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleUpdate}
                      >
                        <EditIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>
                    </tooltip>
                    <tooltip title="Re-Schedule Conference">
                      <MDButton
                        color="success"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleSchedule}
                      >
                        <ScheduleIcon
                          style={{ width: "25px", height: "25px" }}
                        />
                      </MDButton>{" "}
                    </tooltip>
                    <tooltip title="Cancel Conference">
                      <MDButton
                        color="warning"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleCancel}
                      >
                        <CancelIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>{" "}
                    </tooltip>
                    <tooltip title="Delete Conference">
                      <MDButton
                        color="error"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleDelete}
                      >
                        <DeleteIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>{" "}
                    </tooltip>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <Typography style={{ fontSize: "20px" }}>
              <TopicIcon sx={{ marginRight: 1 }} color="info" />
              Meeting Name : {eventData && eventData.roomName}
            </Typography>
            <Typography
              style={{ fontSize: "20px" }}
              display="flex"
              alignItems="center"
            >
              <CalendarTodayIcon sx={{ marginRight: 1 }} color="info" />
              Meeting Date :{" "}
              {eventData && formatDate(eventData.meetingScheduledDate)}
              &nbsp;- &nbsp;
              {eventData && formatDate(eventData.scheduledToDate)}
            </Typography>
            <Typography
              style={{ fontSize: "20px" }}
              display="flex"
              alignItems="center"
            >
              <AccessTimeIcon sx={{ marginRight: 1 }} color="info" />
              Meeting Time : {convertTimeTo12HourFormat(eventData?.fromTime)}
              &nbsp;- &nbsp;
              {convertTimeTo12HourFormat(eventData?.toTime)}
            </Typography>
            {eventData.passwordRequired && (
              <Typography
                style={{ fontSize: "20px" }}
                display="flex"
                alignItems="center"
              >
                <LockOpenIcon sx={{ marginRight: 1 }} color="info" />
                Meeting Pin - {eventData && eventData.conferencePassword}
              </Typography>
            )}
            {/* <Typography style={{ fontSize: "20px" }}>
              <RoomIcon sx={{ marginRight: 1 }} color="info" />
              {eventData?.didMapping}
            </Typography> */}
            <Typography style={{ fontSize: "20px" }}>
              <RadioButtonCheckedIcon
                sx={{ marginRight: 1 }}
                color={eventData.recording ? "success" : "error"}
              />
              Recording : {eventData.recording ? "On" : "Off"}
            </Typography>

            <Typography
              style={{ fontSize: "20px" }}
              display="flex"
              alignItems="center"
            >
              <AccountCircleIcon sx={{ marginRight: 1 }} color="info" />
              Meeting Create By -{" "}
              {eventData &&
              eventData.createdBy == AuthenticationService.getUserName()
                ? "You"
                : eventData.createdBy}
            </Typography>
            {AuthenticationService.allowConferenceMenus() ||
            AuthenticationService.allowUCMenus() ? (
              <CTLConferenceUserList
                conferenceData={eventData}
                phoneType={"moderator"}
                openPopup={openPopup}
              />
            ) : null}
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton color="secondary" onClick={handleEventLeave}>
              Close
            </MDButton>
            {eventData?.createdBy == AuthenticationService.getUserName() && (
              <>
                <MDButton
                  color="warning"
                  variant="contained"
                  id="call"
                  size="medium"
                  disabled={disable}
                  onClick={() => HandleOpenDistorsion()}
                >
                  <Icon color="text">campaign_icon</Icon> &nbsp; Play
                  Announcement
                </MDButton>
              </>
            )}

            {AuthenticationService.allowConferenceMenus() && (
              <>
                <MDButton
                  color={
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? "success"
                      : "info"
                  }
                  disabled={disable}
                  onClick={() => {
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? startConference(eventData, false)
                      : joinConference(eventData, false);
                  }}
                >
                  {eventData?.createdBy == AuthenticationService.getUserName()
                    ? "Join Conference"
                    : "Join Conference"}
                </MDButton>
              </>
            )}
            {AuthenticationService.allowUCMenus() && (
              <>
                <MDButton
                  color={
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? "success"
                      : "info"
                  }
                  disabled={disable}
                  onClick={() => {
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? startConference(eventData, false)
                      : joinConference(eventData, false);
                  }}
                >
                  {" "}
                  <Icon>call_icon</Icon> &nbsp;
                  {eventData?.createdBy == AuthenticationService.getUserName()
                    ? "Audio Conference"
                    : "Audio Conference"}
                </MDButton>
                <MDButton
                  color={
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? "success"
                      : "info"
                  }
                  disabled={disable}
                  onClick={() => {
                    eventData?.createdBy == AuthenticationService.getUserName()
                      ? startConference(eventData, true)
                      : joinConference(eventData, true);
                  }}
                >
                  <Icon>videocam_icon</Icon>&nbsp;
                  {eventData?.createdBy == AuthenticationService.getUserName()
                    ? "Video Conference"
                    : "Video Conference"}
                </MDButton>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={openBroadcast} onClose={handleBroadcastLeave} fullWidth>
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Select Conference To Multicast
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {ActiveConferenceList.length == 0 ? (
            <Box>
              <List>
                <ListItemText
                  sx={{ marginLeft: 2 }}
                  primary="No any active conference"
                />
              </List>
            </Box>
          ) : (
            <Box sx={{ maxHeight: 230, overflow: "auto" }}>
              <List>
                {ActiveConferenceList.map((item) => {
                  const labelId = `checkbox-list-label-${item}`;
                  return (
                    <ListItemButton
                      key={item.uuid}
                      onClick={() => handleToggle(item)}
                      alignItems="center"
                    >
                      <Checkbox
                        edge="end"
                        checked={selectedConferences.indexOf(item) !== -1}
                        onChange={() => handleToggle(item)}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={`${item.conferenceName}`}
                        sx={{ marginLeft: 2 }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton color="secondary" onClick={handleBroadcastLeave}>
            Close
          </MDButton>
          <MDButton
            color="success"
            disabled={ActiveConferenceList.length == 0 ? true : false}
            onClick={handleMulticastStart}
          >
            Start
          </MDButton>
        </DialogActions>
      </Dialog>

      <Grid container spacing={1}>
        <Grid item xs={9} sx={{ height: "max-content" }}>
          {AuthenticationService.allowConferenceMenus() ||
          AuthenticationService.allowUCMenus() ? (
            <Card
              style={{
                maxHeight: "calc(100vh - 150px)",
                minHeight: "calc(100vh - 150px)",
                overflow: "auto",
                position: "relative",
              }}
            >
              {AuthenticationService.allowConferenceMenus() &&
                window.conferenceMode && (
                  <>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item={8}>
                        <Stack
                          padding="20px"
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDBadge
                            color="error"
                            badgeContent={localModeCount}
                            max={99}
                            size="xs"
                            circular
                          >
                            <MDTypography variant="h6">
                              {window.conferenceMode1 ? window.conferenceMode1 : "Local Mode"}
                            </MDTypography>
                          </MDBadge>
                          &nbsp;&nbsp;
                          <AntSwitch
                            onChange={(e) => handleChangeMode(e)}
                            inputProps={{ "aria-label": "ant design" }}
                          />
                          <MDBadge
                            color="error"
                            badgeContent={publicModeCount}
                            max={99}
                            size="xs"
                            circular
                          >
                            <MDTypography variant="h6">
                              {window.conferenceMode2 ? window.conferenceMode2 : "Public Mode"}
                            </MDTypography>
                          </MDBadge>
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        {" "}
                        <IconButton
                          style={{ marginLeft: "50px" }}
                          onClick={() => handleBroadcast()}
                        >
                          <MDTypography variant="h6">Broadcast</MDTypography>{" "}
                          &nbsp;
                          <Campaign color="error"></Campaign>
                        </IconButton>
                        <IconButton
                          style={{ marginLeft: "30px" }}
                          onClick={() => handleOpenBroadcastList()}
                        >
                          <MDTypography variant="h6">Multicast</MDTypography>{" "}
                          &nbsp;
                          <Campaign color="warning"></Campaign>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              <CalendarRoot p={2} ownerState={{ darkMode }}>
                {type && (
                  <FullCalendar
                    {...rest}
                    initialDate={selectedDate || new Date()}
                    key={calendarKey}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    droppable={true}
                    eventDrop={handleEventDrop}
                    events={events}
                    eventClick={handleEventClick}
                    eventLeave={handleEventLeave}
                    initialView={type}
                    height="100%"
                    customButtons={customButtons}
                    headerToolbar={headerToolbar}
                    dateClick={handleDateClick}
                    select={handleDateSelect}
                    unselect={handleDateUnselect}
                    selectMirror={true}
                    nowIndicator={true}
                    selectOverlap={false}
                    selectMinDistance={2}
                    slotLabelFormat={{
                      hour: "numeric",
                      minute: "2-digit",
                      omitZeroMinute: false,
                      meridiem: "short",
                    }}
                    slotLabelDidMount={(args) => {
                      const label = args.el;
                      label.style.color = darkMode ? "white" : "black";
                    }}
                  />
                )}
              </CalendarRoot>
            </Card>
          ) : (
            <ConferenceList
              data={data}
              handleUserEventsClick={handleUserEventsClick}
              setConferenceMode={setConferenceMode}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <MDBox mb={3}>
            <NextEvents
              data={data}
              phoneType={phoneType}
              setPhoneType={setPhoneType}
              setConferenceType={setConferenceType}
              conferenceType={conferenceType}
              handleUserEventsClick={handleUserEventsClick}
            />
          </MDBox>
        </Grid>
      </Grid>

      <CTLConferenceDelete
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
        eventData={eventData}
      />
      <CTLConferenceCancel
        confirmCancel={confirmCancel}
        handleAgreeCancel={handleAgreeCancel}
        onCancel={onCancel}
        eventData={eventData}
      />

      <PlayDistorsion
        entityName="Play Distorsion"
        openDistorsion={openDistorsion}
        setOpenDistorsion={setOpenDistorsion}
        HandleCloseDistorsion={HandleCloseDistorsion}
        conferenceData={eventData}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
