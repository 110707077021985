import { forwardRef, useEffect, useState } from "react";
import {
  useGetDataQuery,
  useGetPopulatedWidgetListDataQuery,
} from "features/apiSlice";
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slide,
} from "@mui/material";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMaterialUIController } from "context";
import UI_CONFIGS from "../../configs/UIConfigs";
import { WIDGET_TYPES } from "../../configs/widgetsConfigs";
import AddWidgetTable from "./AddWidgetTable";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function SearchPopUp({
  setLayoutData,
  cellId,
  masterContainerId,
  setIsPopUpOpen,
  isPopUpOpen,
  setWidgetsDataObj2,
}) {
  const [isDummy, setIsDummy] = useState(true);

  const widgetListQuery = useGetDataQuery("/dashboard/widgets", {
    skip: isDummy,
    refetchOnReconnect: true,
  });

  const widgetListQueryData = widgetListQuery.data
    ? widgetListQuery.data.filter((obj, index, self) => {
        return self.findIndex((item) => item.id === obj.id) === index;
      })
    : [];

  const [chosenWidgetTypes, setChosenWidgetTypes] = useState([]);
  const [chosenWidgetIds, setChosenWidgetIds] = useState([]);
  const [populatedWidgetList, setPopulatedWidgetList] = useState([]);
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const newPopulatedWidgetList = useGetPopulatedWidgetListDataQuery(
    chosenWidgetIds.map((widget) => widget.label),
    { refetchOnReconnect: true },
  );

  useEffect(() => {
    if (newPopulatedWidgetList.data)
      setPopulatedWidgetList(newPopulatedWidgetList.data);
  }, [newPopulatedWidgetList]);

  const widgetTypesHandle = (event, selectedOptions, reason, details) =>
    setChosenWidgetTypes(selectedOptions);

  const widgetIdsHandle = (selectedOptions) =>
    setChosenWidgetIds(selectedOptions);

  const addWidgetsHandle = () => {
    if (chosenWidgetTypes.length > 0)
      setLayoutData((prevLayoutData) => {
        const masterContainer = prevLayoutData[masterContainerId];
        const childContainer = masterContainer.filter(
          (cell) => cell.celId === cellId,
        )[0];

        const oldWidgetIds = childContainer.widgetIds;
        const newWidgetIds = oldWidgetIds.concat(
          chosenWidgetTypes.map((option) => {
            switch (option.value) {
              case WIDGET_TYPES.calendar:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.calendar,
                };
              case WIDGET_TYPES.counter:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.counter,
                };
              case WIDGET_TYPES.multicounter:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.multicounter,
                };
              case WIDGET_TYPES.piechart:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.piechart,
                };
              case WIDGET_TYPES.doughnutchart:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.doughnutchart,
                };
              case WIDGET_TYPES.barchart:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.barchart,
                };
              case WIDGET_TYPES.linechart:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.linechart,
                };
              case WIDGET_TYPES.serverTable:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.serverTable,
                };
              case WIDGET_TYPES.UITable:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.UITable,
                };
              case WIDGET_TYPES.graphTopology:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.graphTopology,
                };
              case WIDGET_TYPES.preset:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.preset,
                };
              case WIDGET_TYPES.cose:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.cose,
                };
              case WIDGET_TYPES.locationGraph:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.locationGraph,
                };
              case WIDGET_TYPES.topology:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.topology,
                };
              case WIDGET_TYPES.locationTree:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.locationTree,
                };
              case WIDGET_TYPES.smartTopology:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.smartTopology,
                };
              case WIDGET_TYPES.iFrame:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.iFrame,
                };
              case WIDGET_TYPES.videoStream:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.videoStream,
                };
              case WIDGET_TYPES.speedometer:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.speedometer,
                };
              case WIDGET_TYPES.timeseries:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.timeseries,
                };
              case WIDGET_TYPES.multiseries:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.multiseries,
                };
              case WIDGET_TYPES.gisMap:
                return {
                  id: "Dummy-ID",
                  widgetType: WIDGET_TYPES.gisMap,
                };
              default:
                break;
            }
            return 0;
          }),
        );
        return {
          ...prevLayoutData,
          [masterContainerId]: masterContainer.map((cell) =>
            cell.celId === childContainer.celId
              ? {
                  ...childContainer,
                  widgetIds: newWidgetIds,
                }
              : cell,
          ),
        };
      });
    if (chosenWidgetIds.length > 0) {
      setWidgetsDataObj2((prevWidgetsDataObj2) => {
        const newObj = {};
        newPopulatedWidgetList.data.forEach((widget) => {
          newObj[widget.id] = widget.data;
        });
        return { ...prevWidgetsDataObj2, ...newObj };
      });
      setLayoutData((prevDashboardLayoutData) => {
        const masterContainer = prevDashboardLayoutData[masterContainerId];
        const childContainer = masterContainer.filter(
          (cell) => cell.celId === cellId,
        )[0];

        const oldWidgetIds = childContainer.widgetIds;
        let extraWidgetIds = null;
        if (populatedWidgetList)
          extraWidgetIds = populatedWidgetList.map((widget, index) => ({
            id: chosenWidgetIds[index].label,
            widgetType: widget.data.widgetType,
          }));

        const newWidgetIds = oldWidgetIds.concat(extraWidgetIds);
        return {
          ...prevDashboardLayoutData,
          [masterContainerId]: masterContainer.map((cell) =>
            cell.celId === childContainer.celId
              ? {
                  ...childContainer,
                  widgetIds: newWidgetIds,
                }
              : cell,
          ),
        };
      });
    }
    setIsPopUpOpen(false);
  };

  return (
    <Dialog
      open={isPopUpOpen}
      TransitionComponent={Transition}
      onClose={() => setIsPopUpOpen(false)}
      maxWidth="xl"
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <FormControl>
          <FormLabel
            sx={(theme) => ({
              color: theme.palette.text.main,
            })}
          >
            Add widgets
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel
              checked={isDummy}
              value="widgetType"
              control={<Radio />}
              onChange={() => {
                setChosenWidgetIds([]);
                setChosenWidgetTypes([]);
                setIsDummy((prevIsDummy) => !prevIsDummy);
              }}
              label="Add widget type"
            />
            <FormControlLabel
              checked={!isDummy}
              value="widgetId"
              control={<Radio />}
              onChange={() => {
                setChosenWidgetIds([]);
                setChosenWidgetTypes([]);
                setIsDummy((prevIsDummy) => !prevIsDummy);
              }}
              label="Add widget ID"
            />
          </RadioGroup>
        </FormControl>
        {isDummy ? (
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            getOptionLabel={(option) => option.label}
            multiple
            autoComplete
            filterSelectedOptions
            onChange={widgetTypesHandle}
            options={UI_CONFIGS}
            renderTags={(value, getTagProps) =>
              chosenWidgetTypes.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                color={isDarkMode ? "white" : "black"}
                sx={(theme) => ({
                  marginTop: ".5em",
                  backgroundColor: isDarkMode
                    ? theme.palette.dark.main
                    : theme.palette.white.main,
                  "& input::placeholder": {
                    color: isDarkMode ? theme.palette.text.main : "",
                  },
                })}
                {...params}
                variant="filled"
                placeholder="Please select widget type"
              />
            )}
          />
        ) : (
          <AddWidgetTable
            addWidgetTableData={widgetListQueryData ? widgetListQueryData : []}
            widgetIdsHandle={widgetIdsHandle}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton
          disabled={!isDummy && !widgetListQueryData}
          variant="gradient"
          color="info"
          onClick={() => addWidgetsHandle()}
        >
          Add Widgets
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={() => setIsPopUpOpen(false)}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default SearchPopUp;
