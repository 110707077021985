import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { extensionMenus } from "globals/configuration/extension";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : extensionMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      }),
  settingsMode: false,
};

const operationURLS = {
  create: "extensions/createExtension",
  update: "extensions/updateExtension",
  list: "extensions/list",
  delete: "/extensions/deleteExtension",
};

export { Attributes, operationURLS };
