import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  Tooltip,
  Switch,
  Box,
} from "@mui/material";
import { useMaterialUIController } from "context";
import IconButton from "@mui/material/IconButton";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDTypography from "components/MDTypography";

export default function AssignUser({
  row,
  Engineer,
  NewComplaint,
  ApprovedComplaint,
}) {
  const complaintNo = row?.original?.complaintNo;
  const AgentId = row?.original?.agentId;
  const Ext = row?.original?.ext;
  const [formData, setformData] = useState({
    messageValue: "",
  });
  const [showNotification, setShowNotification] = useState(false);
  const [selectedValues, setSelectedValues] = useState("");
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [close, setClose] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newData = value;
    setSelectedValues(newData);
  };

  const handleRemarksChange = (e) => {
    setformData({ ...formData, messageValue: e.target.value });
  };

  const operationURLS = {
    update: "",
    selectList: "/deptEngineerTagging/listAll",
    approved: "/complaintDetail/updateByComplaintNoApproved",
    EnginnerUpdate: "/complaintDetail/updateByComplaintNoEngineer",
  };

  if (NewComplaint) {
    operationURLS.update = "/complaintDetail/updateByComplaintNoAssignment";
  } else if (Engineer) {
    operationURLS.update = "/complaintDetail/updateByComplaintNoAdminAndHead";
  } else if (ApprovedComplaint) {
    operationURLS.update = "/complaintDetail/updateByComplaintNoAssignment";
  }
  // selected List
  const selectedlist = () => {
    application.post(operationURLS.selectList).then((response) => {
      setList(response.data.data);
    });
  };

  // Directy Update

  function Approved(NewComplaint) {
    let requestBodyData = {
      coralDialId: complaintNo,
    };
    application
      .post(operationURLS.approved, requestBodyData)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: response.data.messageDetail + " " + response.data.message,
          type: "success",
          pagename: "Pending Complaints For Approval",
        });
     
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response?.data?.messageDetail +
            " " +
            error.response?.data?.message,
          type: "error",
          pagename: "Pending Complaints For Approval",
        });
      });
  }

  useEffect(() => {
    selectedlist();
  }, []);

  const onSend = () => {
    application
      .post(operationURLS.update, {
        ccAgent: selectedValues,
        remarks: formData.messageValue,
        coralDialId: complaintNo,
      })
      .then((response) => {
        setSelectedValues([]);
        setformData({ messageValue: "" });
        setOpen(false);
        setShowNotification(true);
        setNotify({
          isOpen: true,
          status: response.data.messageDetail + "" + response.data.message,
          type: "success",
          pagename: "CMS Reports",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            "" +
            error.response.data.messageDetail,
          type: "error",
          pagename: "CMS Reports",
        });
      });
  };

  const EnginnerUpdate = () => {
    application
      .post(operationURLS.EnginnerUpdate, {
        ccAgent: AgentId,
        remarks: formData.messageValue,
        coralDialId: complaintNo,
        ext: Ext,
        markAsRead: close,
      })
      .then((response) => {
        setSelectedValues([]);
        setformData({ messageValue: "" });
        setOpen(false);
        setShowNotification(true);
        setNotify({
          isOpen: true,
          status: response.data.messageDetail + "" + response.data.message,
          type: "success",
          pagename: "CMS Reports",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            "" +
            error.response.data.messageDetail,
          type: "error",
          pagename: "CMS Reports",
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedValues([]);
    setformData({ messageValue: "" });
  };

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setClose(isChecked);
  };

  return (
    <>
      <tooltip title={"Assign To Other User"}>
        <IconButton color="primary" onClick={handleClickOpen}>
          <AssignmentIndIcon />
        </IconButton>
      </tooltip>

      <tooltip title={"Approved"}>
        {ApprovedComplaint && (
          <IconButton color="info" onClick={() => Approved()}>
            <CheckBoxIcon />
          </IconButton>
        )}
      </tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="forward-message-dialog-title"
        aria-describedby="forward-message-dialog-description"
        maxWidth="md"
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="forward-message-dialog-title"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            CMS Reports
          </div>
          {(AuthenticationService.allowAdminMenus() ||
            AuthenticationService.allowDepartmentHeadMenu()) && (
            <FormControl fullWidth>
              <InputLabel>Select-User</InputLabel>
              <Select
                sx={{ height: 50 }}
                value={selectedValues}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => selected || "Select User"}
                inputProps={{ "aria-label": "Without label" }}
              >
                {list.map((item) => (
                  <MenuItem key={item.userId} value={item.userId}>
                    <ListItemText primary={item.userId} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDBox sx={{ width: 400 }}>
            <MDInput
              rows={8}
              multiline
              fullWidth
              onChange={handleRemarksChange}
              value={formData.messageValue}
              placeholder="Enter Remarks..."
            />
            {AuthenticationService.allowEngineerMenu() && (
              <Box style={{ display: "flex" }}>
                <MDTypography>Mark as closed</MDTypography>
                <Switch
                  label="Gilad Gray"
                  size="large"
                  type="checkbox"
                  checked={close}
                  onChange={handleSwitchChange}
                  sx={{
                    "& .MuiSwitch-thumb": {
                      bgcolor: close ? "green" : "red",
                    },
                  }}
                />
              </Box>
            )}
          </MDBox>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClose} color="secondary">
            Cancel
          </MDButton>
          {AuthenticationService.allowEngineerMenu() ? (
            <MDButton
              color="success"
              autoFocus
              onClick={EnginnerUpdate}
              type="submit"
            >
              Apply
            </MDButton>
          ) : (
            <MDButton color="success" autoFocus onClick={onSend} type="submit">
              Apply
            </MDButton>
          )}
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
