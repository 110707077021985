const conferenceSettingMenus = [
  {
    icon: "preview_icon ",
    name: "Debriefing",
    key: "debriefing",
    url: "/app/Debriefing",
    description: "Debriefing",
  },
  {
    icon: "backup",
    name: "Backup Restore",
    key: "backupRestore",
    url: "/app/Backup-Restore",
    description: "Backup Restore",
  },
];

export { conferenceSettingMenus };
