import React, { useState, useEffect, useRef } from "react";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { application } from "globals/endpoints";
import MDTypography from "components/MDTypography";
import CTLNotification from "application/components/CTLNotification";
import { useMaterialUIController, setIvrExtension } from "context";
import EmptyImage from "../../../assets/images/mediacenter/emptyFolder.svg";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import ReactPlayer from "react-player";
import CTLBackupConfirm from "application/components/CTLBackupConfirm";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import variables from "globals/variables";
import { getColumns } from "./model";
import CTLTable from "application/components/CTLTable";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableSelectMenu: true,
  selectUrl: "conferenceRoom/list/pastMeeting",
  label: "Select Conference",
  keyField: "conferenceName",
  modes: [],
};

const Debriefing = ({
  onLoad,
  pageId,
  registerCallBacks,
  pageName,
}) => {
  const [participantList, setParticipantList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const darkModeContext = useMaterialUIController();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    if (ivrExtension?.code) {
      getParticipantList(ivrExtension.code.modId);
      getRecordingList(ivrExtension.code.accessCode);
      setAdvancedFilters([
        {
          direction: "from",
          dataType: "string",
          fieldName: "roomName",
          value: ivrExtension?.code?.conferenceName || "",
        },
        {
          direction: "to",
          dataType: "string",
          fieldName: "roomName",
          value: ivrExtension?.code?.conferenceName || "",
        },
      ]);
    } else {
      setParticipantList([]);
      setRecordingList([]);
    }
  }, [ivrExtension]);

  const getParticipantList = (value) => {
    if (value) {
      application
        .post(`conferenceParticipant/list/${value}`)
        .then((response) => {
          setParticipantList(response.data.data);
        })
        .catch((error) => {
          setParticipantList([]);
          console.log("Unable to load ParticipantList ", error);
        });
    }
  };

  const getRecordingList = (value) => {
    if (value) {
      application
        .post(`conferenceRoom/recordingList/${value}`)
        .then((response) => {
          setRecordingList(response.data.data);
        })
        .catch((error) => {
          setRecordingList([]);
          console.log("Unable to get recordingList ", error);
        });
    }
  };

  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark"
  );
  const header = [
    {
      name: "Participant Name",
      selector: (row) => row.participantName,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
  ];


  const onBackup = (row) => {
    setSelectedRow(row.accessCode);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (data, fileName, deleteFlag) => {
    application
      .post("conferenceRoom/backup", {
        accessCode: data,
        fileName: fileName,
        deleteFile: deleteFlag,
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: pageName,
          status: error.response.data.messageDetail,
        });
      });
    onCancel();
  };

  const onDownload = async (row) => {
    try {
      const operationURL = `${variables.app.services}auth/download/defaultTar?filePath=${row.backupPath}`;
      const response = await fetch(operationURL);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }
      setNotify({
        isOpen: true,
        message: "",
        type: "success",
        pagename: pageName,
        status: "Backup File Download In Progress...",
      });
      const link = document.createElement("a");
      link.href = operationURL;
      link.download = `${row.roomName}.tar`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: pageName,
        status: "Unable To Download Backup File",
      });
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          {participantList.length == 0 ? (
            <Card
              style={{
                height: "calc(100vh - 180px)",
                overflow: "auto",
                position: "relative",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <img src={EmptyImage} alt="Empty Folder" />
                <Typography variant="h5" fontFamily={600}>
                  Please Select Conference
                </Typography>
              </Box>
            </Card>
          ) : (
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="center">
                <Card
                  sx={{
                    backgroundColor: "info.main",
                    color: "info.contrastText",
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography align="center">
                    {ivrExtension?.code?.conferenceName?.toUpperCase()}
                  </Typography>
                </Card>
              </MDBox>

              {recordingList.length == 0 ? (
                <>
                  <Divider />
                  <Card>
                    <MDTypography align="center">
                      No Recording Available
                    </MDTypography>
                  </Card>
                </>
              ) : (
                <MDBox
                  style={{
                    maxHeight: "calc(50vh)",
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  <Divider />
                  {recordingList.map((recording, index) => (
                    <>
                      <MDTypography
                        align="center"
                        component="p"
                        variant="caption"
                        color="text"
                      >
                        Recording File Name - {recording.fileName}
                      </MDTypography>
                      <MDBox
                        display="flex"
                        style={{ margin: "5px" }}
                        key={index}
                      >
                        <ReactPlayer
                          url={`${variables.app.services}auth/playFile?fileName=${recording.filePath}`}
                          width="100%"
                          height="60px"
                          playing={false}
                          controls={true}
                        />
                      </MDBox>
                    </>
                  ))}
                </MDBox>
              )}
              <Divider />
              <MDBox>
                <CTLBackupConfirm
                  data={selectedRow}
                  confirmDelete={confirmDelete}
                  onAgree={onAgree}
                  onCancel={onCancel}
                />

                <CTLTable
                  apiURL="conferenceRoom/backup/list"
                  columns={getColumns(onBackup, onDownload)}
                  advancedFilters={advancedFilters}
                  canSearch
                  defaultSortDirection="desc"
                  defaultSortColumn={"roomName"}
                  pageId={pageId}
                  searchText={""}
                  updateList={updateList}
                  hasRowSelection={false}
                  refetchInterval={5000}
                />
              </MDBox>
              <Divider />
              <DataTable
                columns={header}
                data={participantList}
                responsive
                pagination
                striped
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10]}
                theme={
                  darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"
                }
                fixedHeader
                persistTableHead
                fixedHeaderScrollHeight={`${(window.innerHeight / 100) * 66}px`}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Debriefing;
