import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import VideocamIcon from "@mui/icons-material/Videocam";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import CTLNotification from "application/components/CTLNotification";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import variables from "globals/variables";

export default function PlaySingleFile({ row }) {
  const recordFilename = row?.original?.recordFilename;
  const id = row?.original?.id;
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleClickOpen = async () => {
    if (!recordFilename) {
      setNotify({
        isOpen: true,
        status: "Unable To Play File: File Not Exist",
        type: "error",
        pagename: "voice picker",
      });
      return;
    }

    // Determine URL based on file type (audio or video)
    const isVideoFile = recordFilename?.endsWith(".mp4");
    const fileUrl = isVideoFile
      ? `${variables.app.services}auth/voiceLogger/play/${id}`
      : `${variables.app.services}auth/playFile?fileName=${encodeURIComponent(
          recordFilename
        )}`;
    try {
      await fetch(fileUrl, { method: "GET" });
      setOpen(true);
    } catch (error) {
      console.error(
        "Error while playing the audio/video recording file in voice picker:",
        error
      );
      setNotify({
        isOpen: true,
        status: "Error Playing File",
        type: "error",
        pagename: "voice picker",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isVideoFile = recordFilename?.endsWith(".mp4");
  const fileUrl = isVideoFile
    ? `${variables.app.services}auth/voiceLogger/play/${id}`
    : `${variables.app.services}auth/playFile?fileName=${encodeURIComponent(
        recordFilename
      )}`;

  const renderIcon = () => {
    if (!recordFilename) {
      return <DoNotDisturbAltOutlinedIcon color="error" />;
    }
    return isVideoFile ? (
      <VideocamIcon color="info" />
    ) : (
      <PlayCircleIcon color="success" />
    );
  };

  return (
    <div>
      <Tooltip
        title={
          !recordFilename
            ? "File Not Exist"
            : isVideoFile
            ? "Video Play"
            : "Audio Play"
        }
      >
        <IconButton
          variant="contained"
          color={recordFilename ? (isVideoFile ? "info" : "success") : "error"}
          style={{ margin: "10px" }}
          onClick={handleClickOpen}
        >
          {renderIcon()}
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Play Recording File
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <ReactPlayer
            url={fileUrl}
            width={isVideoFile ? "100%" : "500px"}
            height={isVideoFile ? "100%" : "60px"}
            playing={true}
            controls={true}
          />
        </DialogContent>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}
