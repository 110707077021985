import RowAction from "application/components/RowAction";
import { useMaterialUIController } from "context";
import { setAssignTo } from "context";
import formControl from "globals/form-control";

const getUpdateColumns = (onDelete, onEdit, updateData) => {
  const [controller, dispatch] = useMaterialUIController();
  const { assignTo } = controller;
  const onChangeAssign = (event) => {
    let isChecked = event.target.value;
    if (isChecked === 1 || isChecked === 2) {
      setAssignTo(dispatch, isChecked);
    } else {
      setAssignTo(dispatch, "");
    }
  };
  return [
    {
      Header: "Task Name",
      accessor: "tasksName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      disabled: true,
      dataType: "string",
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Start Date",
      accessor: "assignedOn",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "dateTime",
      width: 6,
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Due Date",
      accessor: "expectedCompletionDate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "dateTime",
      isEndTime: true,
      width: 6,
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Priority",
      accessor: "taskPriority",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "select",
      options: {
        keyField: "taskPriority",
        keyDescription: "taskPriority",
        values: [],
        url: "/taskPriority/listAll",
      },
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },

    {
      Header: "Task Type",
      accessor: "taskType",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "select",
      options: {
        keyField: "taskType",
        keyDescription: "taskType",
        values: [],
        url: "/taskType/listAll",
      },
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Assign To",
      accessor: "assignedTo",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      downloadReports: false,
      width: 6,
      onChange: onChangeAssign,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: 1, description: "Task Group" },
          { id: 2, description: "Individual" },
        ],
      },
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Assignee Group",
      accessor: "assignedGroup",
      displayInList: true,
      allowInForm: assignTo === 1 ? true : false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "select",
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
      width: 6,
      options: {
        keyField: "code",
        keyDescription: "code",
        values: [],
        url: "/userGroup/listAll/uniqueGroup",
      },
      isUserGenerated: true,
    },
    {
      Header: "Assignee User",
      accessor: "assignedUser",
      displayInList: true,
      allowInForm: assignTo === 2 ? true : false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "select",
      options: {
        keyField: "user",
        keyDescription: "user",
        values: [],
        url: "/userRole/listAll/user",
      },
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "Reviewer",
      accessor: "reviewer",
      displayInList: true,
      allowInForm: assignTo === 2 ? true : false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "user",
        keyDescription: "user",
        values: [],
        url: "/userRole/listAll/taskManager",
      },
      validation: formControl.string().required("Required").nullable(),
      isUserGenerated: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Current Status",
      accessor: "currentStats",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      filterControlType: "select",
      dataType: "string",
      controlType: "select",
      width: 6,
      isUserGenerated: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      options: {
        keyField: "taskStatus",
        keyDescription: "taskStatus",
        values: [],
        url: "/taskStatus/listAll",
      },
    },
    {
      Header: "View",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 12,
      Cell: ({ value, row }) => <ViewTaskDetals value={value} row={row} />,
    },
    {
      Header: "Description",
      accessor: "taskDescription",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "textField",
      width: 12,
      isUserGenerated: true,
      Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    },
    {
      Header: "attached file",
      accessor: "filePath",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "fileUpload",
      sendFileInFormData: true,
      fileTypeAccept: ".mp3, .wav, .pdf, .txt, .csv",
      uploadUrl: "task/uploadFile",
      width: 6,
      isUserGenerated: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Closed Task",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 12,
      Cell: ({ value, row }) => <TaskStatusUpdate value={value} row={row} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getUpdateColumns };
