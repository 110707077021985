import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { useState } from "react";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableFileImportButton: true,
  enableFileExportButton: true,
  enableAddButton: true,
  enablePublishButton: true,
  enableFilePreViewButton: true,
  enableAdvanceSearch: false,
  enableGroupHeading: false,
  ButtonName:'Upload-File',
  tabSize: 5,
  modes: [
    {
      id: "configuration.routes",
      name: "Routes",
      iconName: "route",
      url: "home/app/configurations/routes",
      description: "Routes",
    },
    {
      id: "configuration.wans",
      name: "Wans",
      iconName: "wifi_channel",
      url: "home/app/configurations/wans",
      description: "Wans",
    },
  ],

  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },

  tabs: [
    {
      id: 0,
      name: "Node Settings",
    },
    {
      id: 1,
      name: "CSCF Settings",
    },
    {
      id: 2,
      name: "Media-GW Settings",
    },
    {
      id: 3,
      name: "Mobility Settings",
    },
    {
      id: 4,
      name: "Port Settings",
    },
  ],
};

const operationURLS = {
  list: "/configuration/list",
  update: "/configuration/update",
  register: "/configuration/register",
  delete: "/configuration/delete",
  publish: "/configuration/publish",
  fileExport: "/configuration/download",
  sytemReset: "/configuration/serverReboot",
};

const getColumns = (
  onDelete,
  onEdit,
  onNodeStatusOpen,
  formData,
  nmsSetting
) => {
  const [required, setRequired] = useState("");
  const [requiredwith, setRequiredwith] = useState("");

  const checkRequired = (event) => {
    if (event.target.checked === true) {
      setRequired(formControl.string().required("Required"));
      setRequiredwith(
        formControl.string().digitsOnly().required("Required").nullable()
      );
    } else {
      setRequired("");
      setRequiredwith("");
    }
  };

  return [
    {
      Header: "CSCF ",
      accessor: "nodeCscf",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Cluster Settings",
      onChange: checkRequired,
    },

    {
      Header: "Enable Billing",
      accessor: "enableBilling",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Enable Redis Pub Sub",
      accessor: "enableRedisPubSub",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Enable NMS",
      accessor: "enableNms",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Domain",
      accessor: "domain",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Cluster Key",
      accessor: "clusterKey",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "password",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Node Name",
      accessor: "nodeName",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Host IP",
      accessor: "hostIP0",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4().nullable(),
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Host Gateway IP",
      accessor: "hostGateway",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 0,
      groupHeading: "Cluster Settings",
    },

    {
      Header: "CIDR",
      accessor: "hostSubnet",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl
        .string()
        .digitsOnly()
        .required("Required")
        .lenthDefination31()
        .nullable(),
      defaultValue: 24,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Host IP1",
      accessor: "hostIP1",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "IP Recording Server",
      accessor: "ipRecordingServer",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 0,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "CSCF Name",
      accessor: "cscfName",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "NODES",
      accessor: "node",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },

    {
      Header: "Group Code",
      accessor: "groupCode",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Router Id",
      accessor: "routerId",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: requiredwith,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Enable Trunk-Gateway In Service",
      accessor: "enableTrunkGatewayInService",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Media Proxy ",
      accessor: "ipMediaProxy",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Servicing CSCF",
      accessor: "cscf",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Proxy CSCF",
      accessor: "ipProxyCscf",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Feature Server",
      accessor: "feature",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Trunk Gateway ",
      accessor: "ipTrunkGateway",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Trunk Gateway Proxy",
      accessor: "ipTrunkGatewayProxy",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Control IP",
      accessor: "controlIP",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },

    {
      Header: "Gateway",
      accessor: "gateway",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "CIDR",
      accessor: "cscfSubnet",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: requiredwith,
      defaultValue: 24,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Core IP",
      accessor: "mmeIP",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "IP Additional",
      accessor: "ipAdditional",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Media Proxy Port From ",
      accessor: "mediaProxyPortsFrom",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 16384,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Media Proxy Port To ",
      accessor: "mediaProxyPortsTo",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 32768,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Feature Media Port From",
      accessor: "featureMediaPortsFrom",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 16384,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Feature Media Port To",
      accessor: "featureMediaPortsTo",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 32768,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Trunk Media Port From ",
      accessor: "trunkMediaPortsFrom",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 16384,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Trunk Media Port To ",
      accessor: "trunkMediaPortsTo",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      defaultValue: 32768,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 2,
      width: 6,
      groupHeading: "CSCF Settings",
    },

    {
      Header: "CSCF Gateway ",
      accessor: "cscfGateway",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "Sticky IP",
      accessor: "stickyIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 3.9,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 1,
      groupHeading: "CSCF Settings",
    },
    {
      Header: "PSTN Gateway IP",
      accessor: "trunk",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().ipv4().nullable(),
      tabId: 1,
      groupHeading: "CSCF Settings",
    },

    {
      Header: "MCC",
      accessor: "mcc",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().intOnly3Digit().nullable(),
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "MNC",
      accessor: "mnc",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().intOnly3Digit().nullable(),
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "MME-MNC",
      accessor: "mmeMNC",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      groupHeading: "Mobility Settings",
    },

    {
      Header: "TAC",
      accessor: "tac",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "AMF",
      accessor: "amf",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().intOnly4Digit().nullable(),
      tabId: 2,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "SD",
      accessor: "sd",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "Pass Type",
      accessor: "passType",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "OP" }, { description: "OPC" }],
      },
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "Mode (volte/vonr/voip)",
      accessor: "mode",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "VoLTE" }, { description: "VoNR" }],
      },
      tabId: 3,
      groupHeading: "Mobility Settings",
    },
    {
      Header: "Scada Port",
      accessor: "scAdaPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      defaultValue: 162,
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      tabId: 3,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Scada Servers",
      accessor: "scadaServers",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      tabId: 3,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Server RLU Code",
      accessor: "serverRluCode",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      width: 6,
      tabId: 3,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Trap Receive IP",
      accessor: "snmpTrapReceiveIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Trap Receive-Port",
      accessor: "snmpTrapReceivePort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Trap Send IP",
      accessor: "snmpTrapSendIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Trap Send Port",
      accessor: "snmpTrapSendPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Event Send IP",
      accessor: "snmpEventSendIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().ipv4().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "SNMP Event Send Port",
      accessor: "snmpEventSendPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Central NMS Redis IP",
      accessor: "centralNmsRedisIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Central NMS Redis Port",
      accessor: "centralNmsRedisPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Domain/Virtual IP",
      accessor: "Domain_VirtualIP",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 6,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Discovery Duration",
      accessor: "discoveryDuration",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 60,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 2.9,
      groupHeading: "NMS Settings",
    },
    {
      Header: "Discovery Timeout",
      accessor: "discoveryTimeout",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 1000,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 2.9,
      groupHeading: "NMS Settings",
    },
    {
      Header: "ICMP Check Threads",
      accessor: "icmpCheckThreads",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 10,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 2.9,
      groupHeading: "NMS Settings",
    },
    {
      Header: "IP Count Each Thread",
      accessor: "ipCountEachThread",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 3,
      width: 2.9,
      groupHeading: "NMS Settings",
    },
    {
      Header: "TLS",
      accessor: "tls",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 3.9,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "SBC User Port ",
      accessor: "sbcUsersPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      defaultValue: 5060,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },

    {
      Header: " Feature User Port",
      accessor: "featureUsersPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5060,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: " Feature User Port Tls",
      accessor: "featureUsersPortTls",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5061,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: " Trunk Gateway Port",
      accessor: "trunkGatewayPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5060,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Trunk Gateway Port Tls",
      accessor: "trunkGatewayPortTls",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5061,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: " Trunk Proxy Port",
      accessor: "trunkProxyPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5060,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "Trunk Proxy Port Tls",
      accessor: "trunkProxyPortTls",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5061,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "SBC User Port Tls",
      accessor: "sbcUsersPortTls",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5061,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 4,
      groupHeading: "Cluster Settings",
    },
    {
      Header: "",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
          onNodeStatusOpen={onNodeStatusOpen}
          formData={formData}
          nmsSetting={nmsSetting}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
