import axios from "axios";
import variables from "globals/variables";
import SIPPhoneService from "application/components/SIPPhone/SIPPhoneService";
import loggerFactory from "globals/logger/logger-factory";
import CryptoJS from "crypto-js";
const componentName = "AuthenticationService";


const appLogWS = (message) => {
  console.log("Message Received at Application Endpoint");
  console.log(JSON.parse(message.body));
};

const telemetryLogWS = (message) => {
  console.log("Message Received at Telemetry Endpoint");
  console.log(JSON.parse(message.body));
};

class AuthenticationStatus {
  constructor() {
    loggerFactory.info("Authentication", "Page reloaded");
    this.currentUser = this.getUserName();

    if (this.currentUser) {
      this.startNotifications();
    }
  }

  loginTriggered() {
    loggerFactory.info("Authentication", "Login triggered");
    this.startNotifications();
  }

  getUserId() {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User-Id`
    );
  }

  getUserName() {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
  }

  startNotifications() {
    loggerFactory.info(
      "Authentication Manager",
      `Starting notifications for ${this.currentUser}`
    );
  }
}

const AuthenticationManager = new AuthenticationStatus();

const AuthenticationService = {
  nmsCallBacks: new Map(),
  serviceCallBacks: new Map(),
  telemetryCallBacks: new Map(),
  mcxCallBacks: new Map(),

  storeAuthenticationDetails: function (
    sipPhoneButton,
    token,
    refreshToken,
    userName,
    fullName,
    roles,
    menus,
    coralApplication,
    sipDetails,
    rluCode,
    rluIP,
    pwdComplication,
    userId,
    ringAnswerCount,
    publicEmergencyNumber
  ) {
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-SIP-PhoneButton`,
      JSON.stringify(sipPhoneButton)
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Token`,
      token
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Refresh-Token`,
      refreshToken
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-User-Id`,
      userId
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-UserName`,
      userName
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-FullName`,
      fullName
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Roles`,
      roles
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Menus`,
      JSON.stringify(menus)
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Apps`,
      JSON.stringify(coralApplication)
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-SIP`,
      JSON.stringify(sipDetails)
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-RLUCode`,
      rluCode
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-RLUIp`,
      rluIP
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-PasswordComplexity`,
      pwdComplication
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Change-Password-Required`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Answer-Ring-Count`,
      ringAnswerCount
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Public-Emergency-Number`,
      publicEmergencyNumber
    );

    AuthenticationManager.loginTriggered();

    this.updateSIP();
    loggerFactory.info(componentName, "login success");
  },

  getPasswordComplexity: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-PasswordComplexity`
    );
  },

  getSipPhoneButton: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-SIP-PhoneButton`
    );
  },

  getAuthenticationToken: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Token`
    );
  },
  getRefreshToken: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Refresh-Token`
    );
  },
  setRefreshedToken: function (token, refreshToken) {
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Token`,
      token
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Refresh-Token`,
      refreshToken
    );
  },
  setUserId: function (userId) {
    return window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-User-Id`,
      userId
    );
  },
  getUserId: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User-Id`
    );
  },

  setUserName: function (userName) {
    return window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-UserName`,
      userName
    );
  },
  getUserName: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
  },

  getUser: function () {
    return window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User`
    );
  },

  getAutoAnswerRingCount: function () {
    let AutoAnswerRingCount = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Answer-Ring-Count`
    );
    return AutoAnswerRingCount ? AutoAnswerRingCount : 0;
  },

  getPublicEmergencyNumber: function () {
    let publicEmergencyNumber = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Public-Emergency-Number`
    );
    return publicEmergencyNumber;
  },

  getBroadcastNumber: function () {
    let broadcastNumber = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Broadcast-Number`
    );
    return broadcastNumber;
  },

  getSIPDetails: function () {
    let sipDetails = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-SIP`
    );
    if (!sipDetails) {
      sipDetails = {
        extensionName: "",
        extension: "",
        sipPassword: "",
      };
    }
    return sipDetails;
  },
  getFullName: function () {
    let fullName = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-FullName`
    );
    return fullName ? fullName : "";
  },
  getExtensionNumber: function () {
    let sipDetails = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-SIP`
    );

    return sipDetails ? JSON.parse(sipDetails).extension : "";
  },
  getGroupMenus: function () {
    let returnValue = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Menus`
    );

    if (returnValue) {
      return JSON.parse(returnValue);
    } else {
      return [];
    }
  },

  getApps: function () {
    let returnValue = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Apps`
    );
    if (returnValue) {
      return JSON.parse(returnValue);
    } else {
      return [];
    }
  },

  logout: function () {
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-SIP-PhoneButton`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-User`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Token`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Refresh-Token`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-User-Id`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-UserName`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-FullName`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Roles`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Menus`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Apps`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-SIP`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-RLUCode`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-RLUIp`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-PasswordComplexity`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Change-Password-Required`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Answer-Ring-Count`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Public-Emergency-Number`,
      ""
    );
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Broadcast-Number`,
      ""
    );

    SIPPhoneService.isInitDone = false;
    SIPPhoneService.userAgentOptions.displayName = "";
    SIPPhoneService.userAgentOptions.authorizationUsername = "";
    SIPPhoneService.userAgentOptions.authorizationPassword = "";
  },
  login: function (userName, password) {
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-User`,
      userName
    );
    return axios.post(variables.app.services + "auth/login", {
      username: userName,
      password: password,
    });
  },
  ChangePassword: function (data) {
    return axios.post(variables.app.services + "auth/changePassword", data);
  },
  isAuthenticated: function () {
    let currentUser = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User`
    );

    return currentUser ? true : false;
  },
  allowApps() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles.includes("ROLE_PROVISIONING");
  },

  allowConferenceMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_UC_CONFERENCE_MODERATOR");
  },

  allowUCConferenceMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_CONFERENCE");
  },

  allowGroupAdmin() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_GROUP_ADMIN");
  },

  allowUCMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_USERS");
  },

  allowRailwayDispatcher() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_RAILWAY_DISPATCHER");
  },
  allowExternalCRM() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_EXTERNAL_CRM");
  },

  allowChat() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_CHAT");
  },

  allowhmsAdminMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_HOTEL");
  },
  allowkmAdminMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_KMADMIN");
  },
  allownmsMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_NMS");
  },
  allownmsViewerMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_NMS_VIEWER");
  },

  allownmsAdminMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_NMSADMIN");
  },
  allowsurakshaMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_SURAKSHA");
  },
  allowAdminMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_ADMIN");
  },
  allowTaskUserManager() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_TASKUSER");
  },
  allowTaskManager() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_TASKMANAGER");
  },
  allowVoicePickerMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_PICKER");
  },
  allowDashboardConfigMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_ANALYTICS");
  },
  allowkanAdminMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_KANADMIN");
  },

  allowAgentMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );

    return roles && roles.includes("ROLE_AGENT");
  },
  allowCallcenterTabMenus() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_TEAMLEADER");
  },
  allowPAMenu() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_PA");
  },
  allowEngineerMenu() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_ENGINEER");
  },
  allowDepartmentHeadMenu() {
    let roles = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Roles`
    );
    return roles && roles.includes("ROLE_DEPTHEAD");
  },
  allowPhone() {
    let sipDetails = JSON.parse(
      window.sessionStorage.getItem(`${window.location.hostname}-coral-X-SIP`)
    );

    return sipDetails.extension;
  },

  updateSIP() {
    if (this.isAuthenticated()) {
      let storedSIPDetails = window.sessionStorage.getItem(
        `${window.location.hostname}-coral-X-SIP`
      );
      let sipDetails = storedSIPDetails ? JSON.parse(storedSIPDetails) : null;

      const decryptData = (encryptedData, secretKey) => {
        const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedData);
        const iv = CryptoJS.lib.WordArray.create(
          encryptedWordArray.words.slice(0, 4),
          16
        );
        const ciphertext = CryptoJS.lib.WordArray.create(
          encryptedWordArray.words.slice(4),
          encryptedWordArray.sigBytes - 16
        );
        const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: ciphertext },
          CryptoJS.enc.Utf8.parse(secretKey),
          { iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.Pkcs7 }
        );
        const value = decrypted.toString(CryptoJS.enc.Utf8);
        loggerFactory.info("SIP Password ", value);
        return value;
      };

      if (sipDetails && sipDetails.extension) {
        SIPPhoneService.enabled = true;
        SIPPhoneService.isInitDone = false;
        SIPPhoneService.deskPhoneStatus = this.getDeskPhoneStatus();
        SIPPhoneService.userAgentOptions.displayName = sipDetails.extensionName;
        SIPPhoneService.userAgentOptions.authorizationUsername =
          sipDetails.extension;
        SIPPhoneService.userAgentOptions.authorizationPassword = decryptData(
          sipDetails.sipPassword,
          window.secretKey
        );
        SIPPhoneService.init();
      } else {
        SIPPhoneService.enabled = false;
      }
    }
  },
  getDeskPhoneStatus() {
    let currentUser = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User`
    );

    let deskPhoneEnabled = window.localStorage.getItem(
      `${window.location.hostname}-userPreference-${currentUser}-deskPhone`
    );

    let returnValue = deskPhoneEnabled == "Y";

    return returnValue;
  },
  setDeskPhoneStatus(status) {
    let currentUser = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-User`
    );

    let deskPhoneEnabled = window.localStorage.setItem(
      `${window.location.hostname}-userPreference-${currentUser}-deskPhone`,
      status ? "Y" : "N"
    );

    window.location.reload();

    return deskPhoneEnabled == "Y";
  },
  getLocation: function () {
    let storedLocation = window.localStorage.getItem(
      `${window.location.hostname}-coral-X-LOCATION`
    );

    if (storedLocation) {
      let returnValue = [
        storedLocation.split(",")[0] * 1,
        storedLocation.split(",")[1] * 1,
      ];
      return returnValue;
    } else {
      return window.mapCenter;
    }

    return null;
  },
  setLocation: function (xCoordinate, yCoordinate) {
    window.localStorage.setItem(
      `${window.location.hostname}-coral-X-LOCATION`,
      [xCoordinate, yCoordinate]
    );
  },

  setChangePasswordRequired: function (changePasswordRequired) {
    window.sessionStorage.setItem(
      `${window.location.hostname}-coral-X-Change-Password-Required`,
      changePasswordRequired
    );
  },

  getLastViewDashboardId: function () {
    let user = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
    return window.localStorage.getItem(
      `${window.location.hostname}-${user}-LastViewed-DashboardId`
    );
  },

  setLastViewDashboardId: function (dashboardId) {
    let user = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
    window.localStorage.setItem(
      `${window.location.hostname}-${user}-LastViewed-DashboardId`,
      dashboardId
    );
  },

  getLastDialNumber: function () {
    let user = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
    return window.localStorage.getItem(
      `${window.location.hostname}-${user}-LastDial-Number`
    );
  },

  setLastDialNumber: function (number) {
    let user = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-UserName`
    );
    window.localStorage.setItem(
      `${window.location.hostname}-${user}-LastDial-Number`,
      number
    );
  },

  getRLUIpAddress: function () {
    let returnValue = "";
    returnValue = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-RLUIp`
    );

    if (!returnValue) {
      returnValue = window.callServer;
    }

    if (!returnValue) {
      returnValue = window.location.origin;
    }

    returnValue =
      window.location.protocol + "//" + returnValue + "/services/api/v2";

    return returnValue;
  },

  getChangePasswordRequired: function () {
    let returnValue = "";
    returnValue = window.sessionStorage.getItem(
      `${window.location.hostname}-coral-X-Change-Password-Required`
    );
    return returnValue;
  },
};

const idleTimeout = window.inactiveTime * 60000;

let idleTimer;

const resetIdleTimer = () => {
  if (typeof window.inactiveTime !== "undefined") {
    if (idleTimeout !== null && idleTimeout !== 0) {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        window.location = "/site/log-out";
      }, idleTimeout);
    }
  }
};

if (idleTimeout) {
  document.addEventListener("mousemove", resetIdleTimer);
  document.addEventListener("keydown", resetIdleTimer);
  document.addEventListener("scroll", resetIdleTimer);

  window.addEventListener("load", () => {
    resetIdleTimer();
  });
}

AuthenticationService.updateSIP();

export default AuthenticationService;
