import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  List,
  ListItem,
  ListItemIcon,
  Fade,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AddForm from "./AddForm";
import { enqueueSnackbar } from "notistack";
import {
  CalendarViewMonth,
  LocalHospital,
  Paid,
  Phone,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";
import MenuComponent from "./MenuComponent";
import MDBox from "components/MDBox";
import MoveExplorer from "./MoveExplorer";
import { useMaterialUIController } from "context";
import getIcon from "./Icons/getIcon";
import Draggable from "react-draggable";
import { useAddExtensionDataMutation } from "features/hospitalSlice";
import { useChangeHospitalJSON } from "./useChangeHospitalJSON";
import FeaturesDialog from "./FeaturesDialog";
import ContextMenu from "./ContextMenu";

const Folder = ({
  folder,
  onFolderClick,
  onFolderDelete,
  onFolderEdit,
  onFolderAdd,
  level,
  currentFolder,
  setCurrentFolder,
  handleSelected,
  width,
  handleCurrentPage,
  featureList,
  featureListSelected,
  setFeatureListSelected,
  folders,
  setFolders,
  moveExplorerCurrentFolder,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [isOpen, setIsOpen] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(() => {
    if (folder.type === "building") {
      return "New Building";
    } else if (folder.type === "floor") {
      return "New Floor";
    }
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [addInput, setAddInput] = useState({
    name: "",
    from: "",
    to: "",
  });
  const [roomInputDialog, setRoomInputDialog] = useState(null);
  const [anchorSubMenu, setAnchorSubMenu] = useState(null);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(null);
  const [anchorContextMenu, setAnchorContextMenu] = useState(null);
  const [moveExplorer, setMoveExplorer] = useState(null);
  const [addExtensionData] = useAddExtensionDataMutation();
  const { changeHospitalJSON } = useChangeHospitalJSON();

  const [editableName, setEditableName] = useState({
    name: "",
    number: "",
  });

  const menuItemStyles = {
    padding: "1px 6px 0px 1.2em",
    gap: 2,
  };

  const iconStyles = {
    fontSize: "1.4em",
    color: darkMode ? "#ffffff" : "#000000",
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setNewName(folder.name);
    setEditableName({
      name: folder?.name,
      number: folder?.number,
    });

    // (folder?.name.match(/\d+$/) || [])[0] || ""
    setAnchorContextMenu(null);
  };

  const handleSaveEdit = (event) => {
    onFolderEdit(folder.id, newName);
    setIsEditing(false);
  };

  const handleEditchange = (e) => {
    const { value, name } = e.target;
    setEditableName({
      ...editableName,
      [name]: value,
    });
  };

  const handleEnterKeyPressedSaved = (event) => {
    const extensionName = editableName?.name + editableName?.number;
    if (event.keyCode === 13) {
      onFolderEdit(folder.id, extensionName);
      setIsEditing(false);
    }
  };

  const handleEditableSave = (event) => {
    const extensionName = editableName?.name;
    const extensionNumber = editableName?.number;
    if (extensionNumber === "" || extensionName === "") {
      enqueueSnackbar("Extension Name or Number can't be empty", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    onFolderEdit(folder.id, extensionName, extensionNumber);
    setIsEditing(false);

    // enqueueSnackbar("Successfully Updated", {
    //   variant: "success",
    //   anchorOrigin: {
    //     vertical: "top",
    //     horizontal: "right",
    //   },
    // });
  };

  const handleDeleteClick = () => {
    onFolderDelete(folder.id);
    setCurrentFolder(null);
    setAnchorContextMenu(null);
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorSubMenu(false);
  };

  const handleOpenSubMenu = (e) => {
    setAnchorSubMenu(e.currentTarget);
  };
  const openContextMenu = (event, folder) => {
    event.preventDefault();
    if (Boolean(moveExplorer)) {
      return;
    }
    handleSelected(event, folder);
    setAnchorContextMenu(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleAddFeatures = () => {
    handleCloseMenu();
    setIsOpen(true);
    // onFolderAdd(folder.id, openFeatureDialog);
    onFolderAdd(folder.id, openFeatureDialog);
    console.log("Hellllo", openFeatureDialog);
    changeHospitalJSON(folders, `created ${openFeatureDialog}`);

    setNewName(openFeatureDialog);
    setAnchorContextMenu(null);
    setOpenFeatureDialog(null);
    setFeatureListSelected([]);
  };

  const handleAddFloor = () => {
    handleCloseMenu();
    setIsOpen(true);
    onFolderAdd(folder.id, "floor");
    setAnchorContextMenu(null);
  };

  const extractNumbers = (node, numbers = []) => {
    if (node?.type !== "building") {
      numbers.push(Number(node.name));
    }
    if (node?.children && node.children.length > 0) {
      node?.children?.forEach((child) => extractNumbers(child, numbers));
    }
    return numbers;
  };

  // Get sorted numbers
  const getSortedNumbers = (data) => {
    let numbers = extractNumbers(data[0]);
    return numbers.sort((a, b) => a - b);
  };

  // Check if a number exists within a range
  const isNumberInRange = (sortedNumbers, from, to) => {
    const fromNumber = Number(from);
    const toNumber = Number(to);
    return sortedNumbers.some(
      (number) => number >= fromNumber && number <= toNumber
    );
  };

  function createOrSelectChild(folder, entityType, name) {
    let newChild =
      folder.type === entityType
        ? folder
        : folder?.children?.find((child) => child.type === entityType);

    if (!newChild) {
      newChild = {
        id: uuidv4(),
        name: name,
        type: entityType,
        parentId: folder.id,
        children: [],
      };
      folder.children.unshift(newChild);
    }

    if (newChild.type === entityType) {
      setCurrentFolder(newChild);
      return newChild.id;
    }

    return folder.id;
  }

  const handleAddRoom = async (e, entityType, addInput) => {
    if (parseInt(addInput.from) > parseInt(addInput.to)) {
      enqueueSnackbar("Numeric Range Mismatch: From Greater Than To", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    let entityInput = {
      from:
        addInput.from === "" && addInput.to != "" ? addInput.to : addInput.from,
      to:
        addInput.from != "" && addInput.to === "" ? addInput.from : addInput.to,
    };

    // Here check that we can insert or not?
    const sortedNumbers = getSortedNumbers(folders);
    const numberInRange = isNumberInRange(
      sortedNumbers,
      entityInput.from,
      entityInput.to
    );

    if (numberInRange) {
      enqueueSnackbar("Extension Number already exist", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    if (entityInput.from != "" && entityInput.to != "") {
      handleCloseMenu();
      setIsOpen(true);
      let folderId = folder?.id;

      if (entityType === "number") {
        folderId = createOrSelectChild(folder, "extension", "Extension");
      }

      if (entityType === "room") {
        folderId = createOrSelectChild(folder, "rooms", "Rooms");
      }

      const dataTosendDb = {
        prefix: addInput.name,
        from: entityInput.from,
        to: entityInput.to,
        type: entityType,
      };
      console.log("dataTosendDb", dataTosendDb);

      try {
        const addExtensionResponse = await addExtensionData(
          dataTosendDb
        ).unwrap();
        console.log("addExtensionResponse", addExtensionResponse);
        if (addExtensionResponse?.status === "OK") {
          for (
            let i = parseInt(entityInput.from);
            i <= parseInt(entityInput.to);
            i++
          ) {
            onFolderAdd(folderId, entityType, addInput.name + i);
          }
          setAnchorContextMenu(null);
          setRoomInputDialog(null);
          await changeHospitalJSON(folders, `created ${entityType}`);
          enqueueSnackbar(
            addExtensionResponse?.messageDetail || "Successfully Created",
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        console.log("error", error);
        enqueueSnackbar(error?.messageDetail || "Error hello", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      alert(`Please Enter ${entityType} `);
    }
    setAddInput({
      name: "",
      from: "",
      to: "",
    });
  };

  const handleRoomChange = (e, type) => {
    if (type === "fromRoomNumbr") {
      setAddInput({
        ...addInput,
        fromRoomNumber: e.target.value,
      });
    }
    if (type === "ToRoomNumber") {
      setAddInput({
        ...addInput,
        toRoomNumber: e.target.value,
      });
    }
  };

  const handleRoomDialogClose = () => {
    setRoomInputDialog(null);
  };

  const handleOpenAddForm = (e, type) => {
    setRoomInputDialog(type);
  };

  const handleSetCurrentPage = (e, type) => {
    onFolderAdd(folder.id, type);
    changeHospitalJSON(folders, `created ${type}`);
    setNewName(type);
    setAnchorContextMenu(null);
    handleCurrentPage(e, type);
  };

  const handleDragStart = (e, item) => {
    console.log("item", item);
    e.dataTransfer.setData("application/json", JSON.stringify(item));
  };

  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  return (
    <>
      <Draggable axis="none" onDrop={handleDrop}>
        <tooltip title={folder?.type} disableInteractive>
          <ListItem
            draggable={true}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onDragStart={(e) => handleDragStart(e, folder)}
            sx={{
              paddingLeft: `${level * 15}px`,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",

              color: darkMode ? "white" : "black",
              // width: `${width - 10}px`,
              borderRadius: "0.375em",
              backgroundColor:
                currentFolder?.id === folder.id ||
                (moveExplorerCurrentFolder &&
                  moveExplorerCurrentFolder?.id === folder.id &&
                  !isEditing)
                  ? darkMode
                    ? "rgba(255, 255, 255, 0.2)"
                    : "rgba(0,0,0,0.2)"
                  : "",
              ":hover": {
                backgroundColor: darkMode
                  ? "rgba(255, 255, 255, 0.2)"
                  : "rgba(0,0,0,0.2)",
                borderRadius: "0.375",
                cursor: "pointer",
              },
            }}
            onClick={(e) => handleSelected(e, folder)}
            onContextMenu={(e) =>
              !Boolean(moveExplorer) && openContextMenu(e, folder)
            }
          >
            <ListItemIcon
              onClick={handleToggle}
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
                minWidth: "unset",
                color: darkMode ? "white" : "black",
              }}
            >
              {folder?.children?.length > 0 ? (
                isOpen ? (
                  <ExpandMoreIcon color={darkMode ? "white" : "black"} />
                ) : (
                  <ChevronRightIcon color={darkMode ? "white" : "black"} />
                )
              ) : (
                <div
                  style={{ marginLeft: "1em", backgroundColor: "orange" }}
                ></div>
              )}

              {getIcon(folder.type, darkMode)}
              <MDTypography style={{ fontSize: "0.8em", marginLeft: "0.4em" }}>
                {folder.name}
              </MDTypography>
            </ListItemIcon>
            {/* {isEditing ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              margin: "0px",
              padding: "0px",
              marginLeft: "0.2em",
            }}
            key={uuidv4()}
          >
            <MDInput
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              size="small"
              onKeyDown={handleEnterKeyPressedSaved}
              sx={{
                width: "70%",
              }}
              autoFocus
              inputProps={{
                style: {
                  padding: "1px 3px",
                  fontSize: "1.15em",
                },
              }}
            />
            
          </MDBox>
        ) : (
          <> */}
            <Fade in={isHovering}>
              <div>
                <MenuComponent
                  currentFolder={currentFolder}
                  anchorEl={anchorEl}
                  handleCloseMenu={handleCloseMenu}
                  handleOpenAddForm={handleOpenAddForm}
                  marginLeft={"6.3em"}
                />
                <ContextMenu
                  anchorContextMenu={anchorContextMenu}
                  closeContextMenu={closeContextMenu}
                  folder={folder}
                  handleAddClick={handleAddClick}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  setMoveExplorer={setMoveExplorer}
                  setOpenFeatureDialog={setOpenFeatureDialog}
                  handleSetCurrentPage={handleSetCurrentPage}
                />
                <Menu
                  anchorEl={anchorSubMenu}
                  open={Boolean(anchorSubMenu)}
                  onClose={handleCloseSubMenu}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    // onClick={handleDeleteClick}
                    sx={menuItemStyles}
                  >
                    <LocalHospital sx={{ ...iconStyles }} fontSize="1.4em" />
                    Genral OPD
                  </MenuItem>
                  <MenuItem
                    // onClick={handleDeleteClick}
                    sx={menuItemStyles}
                  >
                    <Paid sx={{ ...iconStyles }} fontSize="1.4em" />
                    Paid OPD
                  </MenuItem>
                </Menu>
              </div>
            </Fade>
            {/* </>
        )} */}
          </ListItem>
        </tooltip>
      </Draggable>
      <FeaturesDialog
        currentFolder={currentFolder}
        openFeatureDialog={openFeatureDialog}
        setOpenFeatureDialog={setOpenFeatureDialog}
        featureListSelected={featureListSelected}
        setFeatureListSelected={setFeatureListSelected}
        handleAddFeatures={handleAddFeatures}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isEditing}
        onClose={(e) => setIsEditing(false)}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        ></DialogTitle>

        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gridTemplateColumns: `repeat(3, 1fr)`,
            gap: 8,
            padding: "10px 30px 20px 30px",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <MDTypography>Name</MDTypography>
            <MDInput
              name="name"
              value={editableName?.name}
              onChange={handleEditchange}
              size="small"
              onKeyDown={handleEnterKeyPressedSaved}
            />
          </MDBox>
          {/* <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <MDTypography variant={"h4"} sx={{ marginTop: "5px" }}>
              <Phone />
            </MDTypography>
            <MDInput
              name="number"
              value={editableName?.number}
              onChange={handleEditchange}
              size="small"
              placeholder="number"
              onKeyDown={handleEnterKeyPressedSaved}
            />
          </MDBox> */}
        </MDBox>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleEditableSave}
          >
            Save
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={(e) => setIsEditing(false)}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>

      {isOpen && (
        <List component="div" disablePadding>
          {folder.children?.map((child) => (
            <Folder
              key={child.id}
              folder={child}
              onFolderClick={onFolderClick}
              onFolderDelete={onFolderDelete}
              onFolderEdit={onFolderEdit}
              onFolderAdd={onFolderAdd}
              level={level + 1}
              currentFolder={currentFolder}
              setCurrentFolder={setCurrentFolder}
              handleSelected={handleSelected}
              darkMode={darkMode}
              handleCurrentPage={handleCurrentPage}
              featureListSelected={featureListSelected}
              setFeatureListSelected={setFeatureListSelected}
              folders={folders}
              setFolders={setFolders}
              featureList={featureList}
              moveExplorerCurrentFolder={moveExplorerCurrentFolder}
            />
          ))}
        </List>
      )}
      {roomInputDialog && (
        <AddForm
          addInput={addInput}
          setAddInput={setAddInput}
          entityType={roomInputDialog}
          handleRoomDialogClose={handleRoomDialogClose}
          handleRoomChange={handleRoomChange}
          handleAddRoom={handleAddRoom}
        />
      )}
      {moveExplorer && (
        <MoveExplorer
          folderToMove={currentFolder}
          setFolders={setFolders}
          setMoveExplorer={setMoveExplorer}
          folders={folders}
          handleSelected={handleSelected}
        />
      )}
    </>
  );
};

export default Folder;
