const extensionMenus = [
  {
    icon: "fax",
    key: "extension.fax",
    name: "Fax",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "fax",
        key: "extension.fax",
        name: "Fax",
        description: "Configure Fax Type Extension",
        url: "home/app/fax",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "fax",
        key: "softFax",
        name: "SoftFax",
        description: "Soft-Fax",
        url: "home/app/softFax",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "phone_icon",
    key: "phone",
    name: "Phone",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "phone_icon",
        key: "Phone+VOIP",
        name: "Phone+VOIP",
        description: "Phone+VOIP",
        url: "home/app/Phone+VOIP",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "phone_icon",
        key: "Phone+VOLTE",
        name: "Phone+VOLTE",
        description: "Phone+VOLTE",
        url: "home/app/Phone+VOLTE",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "videocam_icon ",
        key: "phoneMCXVideo",
        name: "phoneMCXVideo",
        description: "Phone MCX Video",
        url: "home/app/Phone-MCX-Video",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "phone_iphone_icon",
        key: "phoneVONR",
        name: "phoneVONR",
        description: "Phone VONR",
        url: "home/app/Phone-VONR",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "emergency_icon",
        key: "EmergencyPhone",
        name: "Emergency Phone",
        description: "Emergency Phone",
        url: "home/app/emergencyPhone",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "call_icon",
        key: "tenant",
        name: "Tenant",
        description: "Tenant",
        url: "home/app/tenant",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "dns_icon",
        key: "phoneStatus",
        name: "Phone Status",
        description: "Phone-Status",
        url: "home/app/phoneStatus",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "model_training_icon",
        key: "model",
        name: "Model",
        description: "Extension Model",
        url: "home/app/model",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "manage_search_icon",
        key: "manufacturer",
        name: "ManuFacturer",
        description: "ManuFacturer",
        url: "home/app/manufacturer",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "navigation",
    key: "COS",
    name: "COS",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "call_icon",
        key: "inboundCOS",
        name: "Inbound COS",
        description: "Inbound COS",
        url: "home/app/inboundCOS",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "call_icon",
        key: "outboundCOS",
        name: "Outbound COS",
        description: "Outbound COS",
        url: "home/app/outboundCOS",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "call_icon",
    key: "gatewayController",
    name: "Gateway",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "security",
        key: "inboundGateway",
        name: "Inbound Gateway",
        url: "home/app/configurations/inboundGateway",
        description: "Inbound Gateway",
        productionReady: true,
      },
      {
        icon: "outbound",
        key: "outboundgateway",
        name: "OutboundGateway",
        url: "home/app/outboundgateway",
        description: "OutboundGateway",
        productionReady: true,
      },
    ],
  },
  {
    icon: "contact_phone",
    key: "ACD",
    name: "ACD",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "network_cell_icon",
        key: "ACDGroup",
        name: "ACD Group",
        description: "ACD Group",
        url: "home/app/acdGroup",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "interpreter_mode",
    key: "Conference",
    name: "Conference",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "store_icon",
        key: "meetMeConference",
        name: "Meet Me Conference",
        description: "Conference",
        url: "home/app/meetMeConference",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "store_icon",
        key: "dialAssist",
        name: "Dialer Assist",
        description: "Conference",
        url: "home/app/dialAssist",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "call_icon",
    key: "cms",
    name: "CMS",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "where_to_vote_icon",
        key: "cms",
        name: "CMS",
        description: "CMS",
        url: "home/app/cms",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "where_to_vote_icon",
        key: "cmsOther",
        name: "CMSOther",
        description: "CMSOther",
        url: "home/app/cmsOther",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "money_icon",
    key: "billing",
    name: "Billing",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "store_icon",
        key: "company",
        name: "Company",
        description: "Company",
        url: "home/app/company",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "border_all_icon",
        key: "extensiondepartment",
        name: "Department",
        description: "Department",
        url: "home/app/department",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "where_to_vote_icon",
        key: "designation",
        name: "Designation",
        description: "Designation",
        url: "home/app/designation",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "devices_other",
    key: "Others",
    name: "Others",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "extension_icon",
        key: "extension.ENM",
        name: "ENM",
        description: "ENM",
        url: "home/app/extensionENM",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "extension_icon",
        key: "extension.NGX",
        name: "NGX",
        description: "NGX",
        url: "home/app/extensionNGX",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "dns_icon",
        key: "memberGroup",
        name: "Member Group",
        description: "Member-Group",
        url: "home/app/memberGroup",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "phone_icon",
        key: "VoicePromptFeature",
        name: "Voice Prompt Feature",
        description: "Voice Prompt Feature",
        url: "home/app/VoicePromptFeature",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "network_ping_icon",
        key: "CBN",
        name: "CBN",
        description: "CBN",
        url: "home/app/cbn",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "network_check_icon",
        key: "CBNOther",
        name: "CBN Other",
        description: "CBN Other",
        url: "home/app/cbnOther",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "line_weight",
    key: "ha",
    name: "HA",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "call_icon",
        key: "gatewayController",
        name: "Gateway Controller",
        description: "Gateway-Controller",
        url: "home/app/gatewayController",
        disabled: false,
        productionReady: true,
      },
    ],
  },

  {
    icon: "account_circle_icon",
    key: "usergroup",
    name: "User Group",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "dns_icon",
        key: "ucAssignedUser",
        name: "UC User",
        description: "UC-User",
        url: "home/app/ucAssignedUser",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "sip_icon",
        key: "remoteusersip",
        name: "RemoteUser SIP",
        description: "Remote User SIP",
        url: "home/app/remoteusersip",
        disabled: false,
        productionReady: true,
      },
    ],
  },

  {
    icon: "podcasts",
    key: "callserver",
    name: "Call Server",
    disabled: false,
    productionReady: true,
    submenu: [
      {
        icon: "keyboard_icon",
        key: "DialPlan",
        name: "Dial plan",
        description: "Dial Plan",
        url: "home/app/dialplan",
        disabled: false,
        productionReady: true,
      },
    ],
  },
  {
    icon: "dns_icon",
    key: "controlrluserver",
    name: "Control RLU Server",
    description: "Control RLU Server",
    url: "home/app/controlrluserver",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "send_time_extension_icon",
    key: "Dispatcher",
    name: "Dispatcher",
    description: "Dispatcher",
    url: "home/app/dispatcher",
    disabled: false,
    productionReady: true,
  },
];
export { extensionMenus };
