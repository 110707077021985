export const WIDGET_TYPES = {
  piechart: "pie",
  doughnutchart: "doughnut",
  linechart: "line-chart",
  barchart: "bar",
  calendar: "calendar",
  counter: "counter",
  multicounter: "multicounter",
  serverTable: "table",
  UITable: "UITable",
  graphTopology: "graph-topology",
  preset: "preset",
  cose: "cose",
  locationGraph: "graph-topology-noleaf",
  topology: "topology",
  locationTree: "topology-noleaf",
  smartTopology: "smart-topology",
  iFrame: "iFrame",
  videoStream: "video-stream",
  speedometer: "speedo-meter",
  timeseries: "timeseries",
  multiseries: "multiseries",
  gisMap: "GIS",
};

export const WIDGET_OPTIONS = {
  calendar: "Calendar",
  counter: "Counter",
  multicounter: "Multicounter",
  piechart: "Piechart",
  doughnutchart: "Doughnutchart",
  barchart: "Barchart",
  linechart: "Linechart",
  serverTable: "Server table",
  UITable: "UI table",
  graphTopology: "Graph Topology",
  preset: "Preset",
  cose: "Cose",
  locationGraph: "Location Graph",
  topology: "Network Topology",
  locationTree: "Location Tree",
  smartTopology: "Smart Topology",
  iFrame: "iFrame",
  videoStream: "Video Stream",
  speedometer: "Speedometer",
  timeseries: "Timeseries",
  multiseries: "Multiseries",
  gisMap: "GIS",
};
