import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController , setIsVipUser } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import { getMultipleUpdateColumns, operationURLSS } from "./multpleUpdateModel";
import { getUpdatedColumns, operationurls } from "./updateModel";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";

function UserMaster({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openMultipleUpdate, setOpenMultipleUpdate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const selectedRowsRef = useRef([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        MultipleUpdate: onOpenMultiUpdate,
        openAdvancedFilter: onOpenFilter,
        Single: onOpenUpdate,
      });
  }, [activePage, tableValue]);

  const onSelectionChange = (ids) => {
    setSelectedRows(ids);
  };

  function onOpenFilter() {
    setOpenFilter(true);
  }
  
  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }


  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setIsVipUser(dispatch, false);
    setOpenForm(true);
  }

  function onOpenUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenUpdate(true);
  }

  function onOpenMultiUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    if (selectedRowsRef.current.length !== 0) {
      if (selectedRowsRef.current.length == 1) {
        const selectedRows = selectedRowsRef.current;
        tableValue.forEach((item) => {
          if (selectedRows.includes(item.id)) {
          
            setFormData(item);
          }
        });
        setOpenMultipleUpdate(true);
      } else {
        setOpenMultipleUpdate(true);
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Error",
        status: "Please select a user for update",
        type: "warning",
        pagename: pageName,
      });
    }
  }

  const editRow = (row) => {
    setFormData(row);
    setIsVipUser(dispatch, row.vip);
    setOpenUpdate(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const viewRow = (row) => {
    setFormData(row);
    setOpenView(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: pageName,
          status: response.data.message,
        });
      })
      .catch((error) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationurls.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setOpenForm(false);
          setOpenUpdate(false);
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setOpenForm(false);
          setOpenUpdate(false);
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
            status: response.data.message,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
  }

  const onMultipleData = (data) => {
    const Id = selectedRowsRef.current;
    const groupArray = Array.isArray(data.group)
      ? data.group : data.group === "" ||  data.group === null ? [] : [data.group];
    const AllData = { ...data, userId: Id, group: groupArray };
   
    if (Id) {
      application
        .post(operationURLSS.multiUpdate, AllData)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
    setOpenMultipleUpdate(false);
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function onCloseMultiUpdate() {
    setOpenMultipleUpdate(false);
  }

  function onCloseUpdate() {
    setOpenUpdate(false);
  }

  function onCloseView() {
    setOpenView(false);
  }

  return (
    <MDBox>
      <CTLBaseDialogForm
        entityName="Multiple User UserMaster View"
        columns={getMultipleUpdateColumns()}
        openForm={openView}
        onCloseForm={onCloseView}
        formData={formData}
        pageAttributes={Attributes}
        ActionButtonView={true}
      />

      <DeleteConfirmation
        entityName="UserMaster"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="UserMaster"
        columns={getColumns(deleteRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      {onOpenMultiUpdate && (
        <CTLBaseDialogForm
          entityName="Multiple User UserMaster Update"
          columns={getMultipleUpdateColumns()}
          openForm={openMultipleUpdate}
          onSaveForm={onMultipleData}
          onCloseForm={onCloseMultiUpdate}
          formData={formData}
          pageAttributes={Attributes}
        />
      )}

      {onOpenUpdate && (
        <CTLBaseDialogForm
          entityName="Multiple User UserMaster Update"
          columns={getUpdatedColumns(editRow)}
          openForm={openUpdate}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseUpdate}
          formData={formData}
          pageAttributes={Attributes}
        />
      )}

      <CTLTable
        onSelectionChange={onSelectionChange}
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow, viewRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="id"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateList}
      />

      <CTLAdvancedFilter
        entityName={"UserMaster"}
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default UserMaster;
