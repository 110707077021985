import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import { useMaterialUIController, setLayout } from "context";

import AuthenticationService from "application/modules/authentication/AuthenticationService";

import Illustration from "application/modules/authentication/sign-in";

function CTLDashboardLayout({ children }) {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, AuthenticationService.isAuthenticated() ? "dashboard" : "page");
    }, [pathname]);

    return AuthenticationService.isAuthenticated() ? (
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                pt:0,
                pl:1,
                pr:1,
                pb:3,
                position: "relative",

                [breakpoints.up("sm")]: {
                    marginLeft: miniSidenav ? pxToRem(100) : pxToRem(100),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            {children}
        </MDBox>
    ) : (
        <Illustration />
    );
}

CTLDashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CTLDashboardLayout;
