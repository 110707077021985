import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import QueueConfiguration from "./component/queueConfiguration";
import GeneralQueue from "./component/generalQueue";
import { Card, Menu, MenuItem, Link } from "@mui/material";
import AgentSetting from "./component/agent";
import Campaign from "./component/campaign";
import DayTimeSetting from "./component/day&timeSetting";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { useMaterialUIController, setUpdateListFlag } from "context";
import { operationURLS, getColumns } from "./model";
import { useState, useEffect } from "react";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import AllReports from "./component/allCallcenterRepots";
import MoreIcon from "@mui/icons-material/More";
import { callCenterConfigurationReports } from "./component/allCallcenterRepots/reportMenu";
import CMSReport from "../Reports/cms_Reports";
import CallDisposition from "../Reports/callDisposition";
import CampaignSummary from "../Reports/campaignSummary";
import UserLoginLogout from "../Reports/UserLoginLogout";
import OperatorSummery from "../Reports/operatorSummaryReport";
import GroupAbandonedCall from "../Reports/AgentGroupAbandonedCall";
import AgentDailyPerformance from "../Reports/AgentDailyPerformance";
import AgentDailyReport from "../Reports/agentDailyReport";
import CallCenterConfigurationIVR from "./component/callCenterConfigurationIVR";
import AgentCallDataAnalysis from "../Reports/agentCallDataAnalysis";
import CallerCallDataAnalysis from "../Reports/callerCallDataAnalysis";
import LastAbandonedRecords from "../Reports/lastAbandonedRecords";
import QueueAbandonedReport from "../Reports/queueAbandonedReport";
import DispositionAnalysisQueue from "../Reports/dispositionAnalysisQueue";
import AnsweredReport from "../Reports/answeredReport";
import MDTypography from "components/MDTypography";
import { setCallcenterReportsName } from "context";
import QueueTimeInterval from "../Reports/queueTimeInterval";
import LeadSummary from "../Reports/LeadSummary";
import AgentPerformanceSummary from "../Reports/AgentPerformanceSummary";
import QueuePerformance from "../Reports/queuePerformance";
import QueuePerformanceSummary from "../Reports/QueuePerformanceSummaryReports";

function CallCenterConfigurations({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, queueValue, ivrExtension, callcenterReportsName } =
    controller;
  const [menuValue, setMenuValue] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [additionalTabComponent, setAdditionalTabComponent] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenReport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Attributes = {
    formSize: "md",
    columns: 3,
    enableAddButton: true,
    enableAdvanceSearch: false,
    enableAcdDetails: true,
    selectUrl: "/queue/list/acdList",
    modes: callCenterConfigMenuMoreVert.map((item) => {
      return {
        id: item.key,
        name: item.name,
        iconName: item.icon,
        url: item.url,
        description: item.description,
      };
    }),
  };

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage, tabValue, ivrExtension, menuValue]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function CreateQueue(data) {
    application
      .post(operationURLS.queueCreate, data)
      .then((response) => {
        setUpdateList(Date.now());
        setUpdateListFlag(dispatch, true);
        setOpenForm(false);
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          message: error.response.data.message,
        });
      });
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setCallcenterReportsName(dispatch, "");

    setMenuValue("");
  };

  const groupMenu = AuthenticationService.getGroupMenus();
  let StaticTabs = AuthenticationService.allowAdminMenus();
  let isTeamLeader = AuthenticationService.allowCallcenterTabMenus();
  let tabName = [];

  if (StaticTabs) {
    tabName = [
      "GENERAL-SETTING",
      "ADVANCE-SETTING",
      "AGENT-SETTING",
      "CAMPAIGNS-SETTING",
      "DAY&TIME-SETTING",
      "IVR-FLOW",
      "REPORT",
    ];
  } else if (isTeamLeader === true || isTeamLeader === undefined || isTeamLeader === "") {
    tabName = ["AGENT-SETTING", "CAMPAIGNS-SETTING", "REPORT"];
  }
  const dynamicTabNames = tabName?.filter((menu) =>
    groupMenu.find(
      (DynamicTab) =>
        DynamicTab.name === "Call Center Tab" &&
        DynamicTab.menus.some((item) => item && item.menuName === menu)
    )
  );
  const tabComponents = (
    StaticTabs ? tabName : isTeamLeader === true || isTeamLeader === undefined || isTeamLeader === "" ? tabName : dynamicTabNames
  ).map((tabName, index) => {
    switch (tabName) {
      case "GENERAL-SETTING":
        return (
          <GeneralQueue
            key={index}
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "ADVANCE-SETTING":
        return (
          <QueueConfiguration
            key={index}
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "AGENT-SETTING":
        return (
          <AgentSetting
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "CAMPAIGNS-SETTING":
        return (
          <Campaign
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "DAY&TIME-SETTING":
        return (
          <DayTimeSetting
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "IVR-FLOW":
        return (
          <CallCenterConfigurationIVR
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      case "REPORT":
        return (
          <AllReports
            onLoad={onLoad}
            registerCallBacks={registerCallBacks}
            pageId={pageId}
            searchText={searchText}
            pageName={pageName}
          />
        );
      default:
        return null;
    }
  });

  const handleMenuChange = (newValue) => {
    setMenuValue(newValue);
    setCallcenterReportsName(dispatch, newValue.name);
    handleClose();
  };

  // For Only All Callcenter Reports
  useEffect(() => {
    if (menuValue?.key === "csm_reports") {
      setAdditionalTabComponent(
        <CMSReport
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
          pageName={pageName}
        />
      );
    } else if (menuValue?.key === "callDisposition") {
      setAdditionalTabComponent(
        <CallDisposition
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
          pageName={pageName}
        />
      );
    } else if (menuValue?.key === "campaignSummary") {
      setAdditionalTabComponent(
        <CampaignSummary
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
          pageName={pageName}
        />
      );
    } else if (menuValue?.key === "user-login-logout") {
      setAdditionalTabComponent(
        <UserLoginLogout
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "queueTimeInterval") {
      setAdditionalTabComponent(
        <QueueTimeInterval
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "Operator.Summary") {
      setAdditionalTabComponent(
        <OperatorSummery
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "agent-performance") {
      setAdditionalTabComponent(
        <AgentDailyPerformance
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "agent-performance-summary") {
      setAdditionalTabComponent(
        <AgentPerformanceSummary
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "queuePerformance") {
      setAdditionalTabComponent(
        <QueuePerformance
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "queuePerformanceSummary") {
      setAdditionalTabComponent(
        <QueuePerformanceSummary
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "leadSummary") {
      setAdditionalTabComponent(
        <LeadSummary
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "group-abandoned-call") {
      setAdditionalTabComponent(
        <GroupAbandonedCall
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "agentCallDataAnalysis") {
      setAdditionalTabComponent(
        <AgentCallDataAnalysis
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "callerCallDataAnalysis") {
      setAdditionalTabComponent(
        <CallerCallDataAnalysis
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "lastAbandonedRecords") {
      setAdditionalTabComponent(
        <LastAbandonedRecords
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "answeredReport") {
      setAdditionalTabComponent(
        <AnsweredReport
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "dispositionAnalysisQueue") {
      setAdditionalTabComponent(
        <DispositionAnalysisQueue
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "queueAbandonedReport") {
      setAdditionalTabComponent(
        <QueueAbandonedReport
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else if (menuValue?.key === "agent-DailyReport") {
      setAdditionalTabComponent(
        <AgentDailyReport
          onLoad={onLoad}
          registerCallBacks={registerCallBacks}
          pageId={pageId}
          searchText={searchText}
        />
      );
    } else {
      setAdditionalTabComponent(null);
    }
  }, [menuValue, onLoad, registerCallBacks, pageId, searchText, pageName]);

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#49a3f1",
          },
          marginBottom: 2,
          height: 50,
        }}
      >
        {(StaticTabs ? tabName : isTeamLeader === true || isTeamLeader === undefined || isTeamLeader === "" ? tabName : dynamicTabNames).map(
          (name, index) => (
            <Tab
              key={index}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{name}</span>
                  {index ===
                    (StaticTabs
                      ? tabName
                      : isTeamLeader === true || isTeamLeader === undefined || isTeamLeader === ""
                      ? tabName
                      : dynamicTabNames
                    ).length -
                      1 && (
                    <div
                      style={{
                        marginLeft: "28px",
                        alignItems: "center",
                      }}
                    >
                      <MoreIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenReport}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      />
                    </div>
                  )}
                </div>
              }
            />
          )
        )}
      </Tabs>
      <Card>{additionalTabComponent || tabComponents[tabValue]}</Card>
      <CTLBaseDialogForm
        entityName="Call-Center:Queue-Configurations"
        columns={getColumns(null, null)}
        openForm={openForm}
        onSaveForm={CreateQueue}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {callCenterConfigurationReports.map((menuItem) => (
          <MenuItem key={menuItem.key}>
            <Link
              to="javascript:void(0)"
              onClick={() => handleMenuChange(menuItem)}
            >
              {menuItem.name}
            </Link>
          </MenuItem>
        ))}
      </Menu>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CallCenterConfigurations;
