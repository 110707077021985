import React, { useState, useRef, useEffect } from "react";
import L, { divIcon } from "leaflet"; // Import Leaflet for 'L' references
import axios from "axios";

import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useMap } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import {
  Box,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import { useMaterialUIController } from "context";

import SearchOnMap from "./SearchOnMap";
import { v4 as uuidv4 } from "uuid";
import ShapeNameDialog from "./AlertsDialogs/ShapeNameDialog";
import { useFolderStructure } from "./hooks/useFolderStructure";
import { enqueueSnackbar } from "notistack";
import {
  useCreatePolygonMutation,
  useCreateRouteMutation,
  useGetAllPolygonUsersMutation,
} from "features/geofenceSlice";
import MDTypography from "components/MDTypography";
import * as turf from "@turf/turf";

function MapController({ bounds, polygonData, type }) {
  const map = useMap();

  useEffect(() => {
    let newBounds;

    if (type === "geojson" && polygonData?.coordinates) {
      // Extract coordinates from GeoJSON format
      const coordinates = polygonData.coordinates[0].map(coord => [coord[1], coord[0]]);
      newBounds = L.latLngBounds(coordinates);
    } else if (type === "polygonData" && polygonData?.length > 0) {
      // Extract coordinates from non-GeoJSON format
      const coordinates = polygonData.flatMap(item =>
        item.coordinates.map(coord => [parseFloat(coord.latitude), parseFloat(coord.longitude)])
      );
      newBounds = L.latLngBounds(coordinates);
    }

    if (newBounds && newBounds.isValid()) {
      map.fitBounds(newBounds, {
        padding: [0, 0],
        maxZoom: 22,
      });
    }
  }, [map, polygonData, type]);

  return null;
}

const MapComponent = ({
  onSavePolygon,
  defaultLocation,
  onFolderAdd,
  currentFolder,
  setCurrentFolder,
  polygonList,
  setPolygonList,
  folders,
  setFolders,
  folderPath,
  handleFolderClick,
  areaList,
  routeList,
}) => {
  const [polygons, setPolygons] = useState([]); // Store drawn polygons
  const featureGroupRef = useRef(null); // Reference for FeatureGroup
  const mapRef = useRef(null); // Map reference for view management
  const currentFolderRef = useRef(currentFolder);

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const [drawingColor, setDrawingColor] = useState("#0000FF"); // Default blue color
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [activeDrawingTool, setActiveDrawingTool] = useState(true);

  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [tempShapeData, setTempShapeData] = useState(null);
  const [shapeName, setShapeName] = useState("");
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(13);
  //P_2
  const [showUserLocations, setShowUserLocations] = useState(true);
  const [markersLayer, setMarkersLayer] = useState(null);

  const { handleNewFolderStructure } = useFolderStructure(setFolders);

  const [createPolygon] = useCreatePolygonMutation();
  const [createRoute] = useCreateRouteMutation();
  //P_2
  const [getAllPolygonUsers] = useGetAllPolygonUsersMutation();

  const [bounds, setBounds] = useState(L.latLngBounds([]));
  const [showOverlapping, setShowOverlapping] = useState(false);

  const [geoLocationJSONData, setGeoLocationJSONData] = useState(null); // Initialize state for GeoJSON data

  // Update the ref whenever currentFolder changes
  useEffect(() => {
    currentFolderRef.current = currentFolder;
  }, [currentFolder]);

  const [currentLocation, setCurrentLocation] = useState(
    defaultLocation || [28.6139, 77.209, "Delhi,+India"] // Default to New Delhi if no location is provided
  );

  const collectShapesIds = (folder) => {
    if (!folder) return [];

    let ids = [];

    // Get shapes from current folder
    if (folder.shapesId) {
      ids = ids.concat(folder.shapesId);
    }

    // Recursively get shapes from children
    if (folder.children) {
      folder.children.forEach((child) => {
        ids = ids.concat(collectShapesIds(child));
      });
    }

    return ids;
  };

  // Update the getShapesIdsForFolder function
  const getShapesIdsForFolder = (folder) => {
    if (!folder) return [];

    let ids = [];

    // For project type, get shapes from both zones and routes
    if (folder.type === "project") {
      const zonesFolder = folder.children?.find(
        (child) => child.type === "zones"
      );
      const routesFolder = folder.children?.find(
        (child) => child.type === "routes"
      );

      if (zonesFolder) {
        ids = ids.concat(collectShapesIds(zonesFolder));
      }
      if (routesFolder) {
        ids = ids.concat(collectShapesIds(routesFolder));
      }
    }
    // For zones or routes type, get shapes from all children
    else if (folder.type === "zones" || folder.type === "routes") {
      folder.children?.forEach((child) => {
        if (child.shapesId) {
          ids = ids.concat(child.shapesId);
        }
      });
    }
    // For individual zone/route, get its own shapesId
    else if (folder.shapesId) {
      ids = ids.concat(folder.shapesId);
    }

    return ids;
  };
  //P_2
  function getMarkerIcon() {
    const customIcon = divIcon({
      html: `<svg class="MuiSvgIcon-root MuiSvgIcon-colorSuccess MuiSvgIcon-fontSizeLarge css-quk7e7-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FmdGoodIcon"><path fill="#4CAF50" d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2m0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2"></path></svg>`,
    });
    customIcon.options.iconSize = [24, 24];
    customIcon.options.iconAnchor = [12, 24];
    return customIcon;
  }

  const getAllShapeIds = (structureType) => {
    let shapeIds = [];

    const traverse = (item) => {
      // If this is a geofence type and has shapeIds, add them to our collection
      if (item.type === structureType && item.shapesId) {
        shapeIds = [...shapeIds, ...item.shapesId];
      }

      // If the item has children, recursively process them
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => traverse(child));
      }
    };

    // Process each top-level item
    folders.forEach((item) => traverse(item));

    return shapeIds;
  };

  const getAllStructures = (structureList, shapeIds) => {
    return structureList.filter((structure) =>
      shapeIds.includes(structure.shapeId)
    );
  };

  const findIntersectingRoutes = (polygons, routes) => {
    // Convert coordinates to GeoJSON format
    const convertToGeoJSON = (shape, type) => {
      const coordinates = shape.coordinates.map((coord) => [
        parseFloat(coord.longitude),
        parseFloat(coord.latitude),
      ]);

      // For polygons, we need to close the ring by adding the first point at the end
      const finalCoords = type === "Polygon" ? [coordinates] : coordinates;

      return turf.feature({
        type,
        coordinates: finalCoords,
      });
    };

    // Convert all polygons to GeoJSON
    const polygonFeatures = polygons.map((poly) =>
      convertToGeoJSON(poly, "Polygon")
    );

    // Find intersecting routes
    return routes.filter((route) => {
      const routeLine = convertToGeoJSON(route, "LineString");

      // Check if the route intersects with any polygon
      return polygonFeatures.some((polygon) => {
        try {
          const intersection = turf.booleanIntersects(routeLine, polygon);
          return intersection;
        } catch (error) {
          console.error(
            `Error checking intersection for route ${route.id}:`,
            error
          );
          return false;
        }
      });
    });
  };

  const findIntersectingPolygons = (polygons, routes) => {
    const convertToGeoJSON = (shape, type) => {
      const coordinates = shape.coordinates.map((coord) => [
        parseFloat(coord.longitude),
        parseFloat(coord.latitude),
      ]);

      const finalCoords = type === "Polygon" ? [coordinates] : coordinates;

      return turf.feature({
        type,
        coordinates: finalCoords,
      });
    };

    const routeFeatures = routes.map((route) =>
      convertToGeoJSON(route, "LineString")
    );

    return polygons.filter((polygon) => {
      const polygonFeature = convertToGeoJSON(polygon, "Polygon");

      return routeFeatures.some((route) => {
        try {
          return turf.booleanIntersects(route, polygonFeature);
        } catch (error) {
          console.error(
            `Error checking intersection for polygon ${polygon.id}:`,
            error
          );
          return false;
        }
      });
    });
  };

  // Update the useEffect that handles map display
  //P_2
  // useEffect(async () => {
  //   if (
  //     !currentFolder ||
  //     !featureGroupRef.current ||
  //     currentFolder.type === "users"
  //   )
  //     return;

  //   const featureGroup = featureGroupRef.current;
  //   featureGroup.clearLayers();
  //   //P_2
  //   if (markersLayer) {
  //     mapRef.current.removeLayer(markersLayer);
  //     setMarkersLayer(null);
  //   }

  //   const shapesIds = getShapesIdsForFolder(currentFolder);
  //   let polygonData = polygonList.filter((item) =>
  //     shapesIds.includes(item.shapeId),
  //   );

  //   // Logic for overlapping
  //   if (showOverlapping) {
  //     const geofenceShapeIds = getAllShapeIds("geofence");
  //     const routesShapeIds = getAllShapeIds("route");

  //     const allPolygons = getAllStructures(areaList, geofenceShapeIds);
  //     const allRoutes = getAllStructures(routeList, routesShapeIds);

  //     const intersectingRoutes = findIntersectingRoutes(polygonData, allRoutes);
  //     const intersectingPolygons = findIntersectingPolygons(
  //       allPolygons,
  //       polygonData,
  //     );

  //     if (currentFolder.type === "project") {
  //       polygonData = [
  //         ...findIntersectingPolygons(allPolygons, allRoutes),
  //         ...findIntersectingRoutes(allPolygons, allRoutes),
  //       ];
  //     } else if (
  //       currentFolder.type === "zones" ||
  //       currentFolder.type === "geofence"
  //     ) {
  //       polygonData = [...polygonData, ...intersectingRoutes];
  //     } else if (
  //       currentFolder.type === "routes" ||
  //       currentFolder.type === "route"
  //     ) {
  //       polygonData = [...polygonData, ...intersectingPolygons];
  //     }
  //   }

  //   //P_2
  //   const allPolygonUsers = (
  //     await Promise.all(
  //       shapesIds.map((shapeId) =>
  //         getAllPolygonUsers(shapeId)
  //           .unwrap()
  //           .catch(() => []),
  //       ),
  //     )
  //   ).flat();

  //   // const allPolygonUsers = [
  //   //   {
  //   //     user: "uc1",
  //   //     userLat: "28.61971",
  //   //     userLong: "77.38458",
  //   //     areaData: null,
  //   //   },
  //   //   {
  //   //     user: "uc4",
  //   //     userLat: "28.61971",
  //   //     userLong: "77.38358",
  //   //     areaData: null,
  //   //   },
  //   // ];
  //   //P_2
  //   if (showUserLocations) {
  //     const markers = L.layerGroup().addTo(mapRef.current);
  //     setMarkersLayer(markers);

  //     allPolygonUsers.forEach((user) => {
  //       if (user.userLat && user.userLong) {
  //         const markerLocation = [
  //           parseFloat(user.userLat),
  //           parseFloat(user.userLong),
  //         ];
  //         const customIcon = getMarkerIcon();
  //         const customMarker = L.marker(markerLocation, {
  //           icon: customIcon,
  //           draggable: false,
  //         });

  //         const popupContent = `
  //         <div>
  //           <h3 style="color: black;">${user.user}</h3>
  //           <p style="color: black;">
  //             <hr />
  //             <strong>Lat Long:</strong> ${user.userLat}, ${user.userLong}
  //           </p>
  //         </div>`;

  //         customMarker.bindPopup(popupContent);
  //         customMarker.addTo(markers);
  //       }
  //     });
  //   }

  //   const newBounds = L.latLngBounds([]);

  //   // FA
  //   polygonData.forEach((item) => {
  //     const latLngs = item.coordinates.map((coord) => [
  //       coord.latitude,
  //       coord.longitude,
  //     ]);

  //     if (item.areaType === "polyline" || item.areaType === "route") {
  //       // For routes: always red color, no label
  //       const polyline = L.polyline(latLngs, {
  //         color: "red", // Force red color
  //         weight: 3,
  //       }).addTo(featureGroup);

  //       newBounds.extend(polyline.getBounds());
  //     } else if (item.areaType === "polygon" || item.areaType === "circle") {
  //       if (item.areaType === "circle" && item.coordinates[0].radius) {
  //         const circle = L.circle(latLngs[0], {
  //           color: item.color || "#0000FF",
  //           fillColor: item.color || "#0000FF",
  //           fillOpacity: 0.2,
  //           radius: item.coordinates[0].radius,
  //         }).addTo(featureGroup);

  //         // Add label for circle
  //         if (item.name) {
  //           circle.bindTooltip(item.name, {
  //             permanent: true,
  //             direction: "center",
  //             className: "custom-label",
  //             style: {
  //               background: "none",
  //               border: "none",
  //               boxShadow: "none",
  //               color: item.color || "#0000FF",
  //               fontWeight: "bold",
  //             },
  //           });
  //         }

  //         newBounds.extend(circle.getBounds());
  //       } else {
  //         const polygon = L.polygon(latLngs, {
  //           color: item.color || "#0000FF",
  //           fillColor: item.color || "#0000FF",
  //           fillOpacity: 0.2,
  //         }).addTo(featureGroup);

  //         // Add label for polygon
  //         if (item.name) {
  //           polygon.bindTooltip(item.name, {
  //             permanent: true,
  //             direction: "center",
  //             className: "custom-label",
  //             style: {
  //               background: "none",
  //               border: "none",
  //               boxShadow: "none",
  //               color: item.color || "#0000FF",
  //               fontWeight: "bold",
  //             },
  //           });
  //         }

  //         newBounds.extend(polygon.getBounds());
  //       }
  //     }
  //   });

  //   setBounds(newBounds);
  // }, [currentFolder, polygonList, showUserLocations, showOverlapping]);

  useEffect(() => {
    const updateMap = async () => {
      if (
        !currentFolder ||
        !featureGroupRef.current ||
        currentFolder.type === "users"
      )
        return;

      const featureGroup = featureGroupRef.current;
      featureGroup.clearLayers();

      if (markersLayer) {
        mapRef.current.removeLayer(markersLayer);
        setMarkersLayer(null);
      }

      // Define shapesIds here
      const shapesIds = getShapesIdsForFolder(currentFolder);

      let polygonData = polygonList.filter((item) =>
        shapesIds.includes(item.shapeId)
      );

      console.log("polygonData", polygonData);
      // Logic for overlapping
      if (showOverlapping) {
        const geofenceShapeIds = getAllShapeIds("geofence");
        const routesShapeIds = getAllShapeIds("route");

        const allPolygons = getAllStructures(areaList, geofenceShapeIds);
        const allRoutes = getAllStructures(routeList, routesShapeIds);

        const intersectingRoutes = findIntersectingRoutes(
          polygonData,
          allRoutes
        );
        const intersectingPolygons = findIntersectingPolygons(
          allPolygons,
          polygonData
        );

        if (currentFolder.type === "project") {
          polygonData = [
            ...findIntersectingPolygons(allPolygons, allRoutes),
            ...findIntersectingRoutes(allPolygons, allRoutes),
          ];
        } else if (
          currentFolder.type === "zones" ||
          currentFolder.type === "geofence"
        ) {
          polygonData = [...polygonData, ...intersectingRoutes];
        } else if (
          currentFolder.type === "routes" ||
          currentFolder.type === "route"
        ) {
          polygonData = [...polygonData, ...intersectingPolygons];
        }
      }

      // Fetch allPolygonUsers using shapesIds
      const allPolygonUsers = (
        await Promise.all(
          shapesIds.map((shapeId) =>
            getAllPolygonUsers(shapeId)
              .unwrap()
              .catch(() => [])
          )
        )
      ).flat();

      if (showUserLocations) {
        const markers = L.layerGroup().addTo(mapRef.current);
        setMarkersLayer(markers);

        allPolygonUsers.forEach((user) => {
          if (user.userLat && user.userLong) {
            const markerLocation = [
              parseFloat(user.userLat),
              parseFloat(user.userLong),
            ];
            const customIcon = getMarkerIcon();
            const customMarker = L.marker(markerLocation, {
              icon: customIcon,
              draggable: false,
            });

            const popupContent = `
           <div>
             <h3 style="color: black;">${user.user}</h3>
             <p style="color: black;">
               <hr />
               <strong>Lat Long:</strong> ${user.userLat}, ${user.userLong}
             </p>
           </div>`;

            customMarker.bindPopup(popupContent);
            customMarker.addTo(markers);
          }
        });
      }

      const newBounds = L.latLngBounds([]);

      polygonData.forEach((item) => {
        const latLngs = item.coordinates.map((coord) => [
          coord.latitude,
          coord.longitude,
        ]);

        if (item.areaType === "polyline" || item.areaType === "route") {
          const polyline = L.polyline(latLngs, {
            color: "red",
            weight: 3,
          }).addTo(featureGroup);
          if (item.name) {
            polyline.bindTooltip(item.name, {
              permanent: true,
              direction: "center",
              className: "custom-label",
              style: {
                background: "none",
                border: "none",
                boxShadow: "none",
                color: item.color || "#0000FF",
                fontWeight: "bold",
              },
            });
          }

          newBounds.extend(polyline.getBounds());
        } else if (item.areaType === "polygon" || item.areaType === "circle") {
          if (item.areaType === "circle" && item.coordinates[0].radius) {
            const circle = L.circle(latLngs[0], {
              color: item.color || "#0000FF",
              fillColor: item.color || "#0000FF",
              fillOpacity: 0.2,
              radius: item.coordinates[0].radius,
            }).addTo(featureGroup);

            if (item.name) {
              circle.bindTooltip(item.name, {
                permanent: true,
                direction: "center",
                className: "custom-label",
                style: {
                  background: "none",
                  border: "none",
                  boxShadow: "none",
                  color: item.color || "#0000FF",
                  fontWeight: "bold",
                },
              });
            }

            newBounds.extend(circle.getBounds());
          } else {
            const polygon = L.polygon(latLngs, {
              color: item.color || "#0000FF",
              fillColor: item.color || "#0000FF",
              fillOpacity: 0.2,
            }).addTo(featureGroup);

            if (item.name) {
              polygon.bindTooltip(item.name, {
                permanent: true,
                direction: "center",
                className: "custom-label",
                style: {
                  background: "none",
                  border: "none",
                  boxShadow: "none",
                  color: item.color || "#0000FF",
                  fontWeight: "bold",
                },
              });
            }

            newBounds.extend(polygon.getBounds());
          }
        }
      });

      setBounds(newBounds);

      const handleZoomChange = () => {
        const currentZoom = mapRef.current.getZoom();
        const showLabels = currentZoom >= 13; // Set your desired zoom level here

        featureGroup.eachLayer((layer) => {
          if (layer.getTooltip()) {
            if (showLabels) {
              layer.openTooltip();
            } else {
              layer.closeTooltip();
            }
          }
        });
      };

      if (mapRef.current) {
        mapRef.current.on("zoomend", handleZoomChange);
      }

      // Initial call to set the correct state
      handleZoomChange();
    };

    updateMap();
  }, [currentFolder, polygonList, showUserLocations, showOverlapping, folders]);

  // Add these new handlers inside MapComponent
  const handleColorPickerOpen = (event) => {
    setColorPickerAnchor(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchor(null);
  };

  useEffect(() => {
    // Modify L.drawLocal to change toolbar tooltip
    L.drawLocal.draw.toolbar.buttons.polyline = "Create route";
    L.drawLocal.draw.toolbar.buttons.polygon = "Create geofence";
  }, []);

  const handleColorChange = (color) => {
    setDrawingColor(color.hex);
    if (featureGroupRef.current) {
      const drawControl = featureGroupRef.current._map.drawControl;
      if (drawControl) {
        // Update the drawing color for both polygon and polyline
        if (activeDrawingTool === "polygon") {
          drawControl.setDrawingOptions({
            polygon: { shapeOptions: { color: color.hex } },
          });
        } else if (activeDrawingTool === "polyline") {
          drawControl.setDrawingOptions({
            polyline: { shapeOptions: { color: color.hex } },
          });
        }
      }
    }
  };

  const handleColorDoubleClick = (e, color) => {
    setColorPickerAnchor(null);
    setDrawingColor(color.hex);
  };

  // Add this effect to track the active drawing tool
  useEffect(() => {
    if (featureGroupRef.current) {
      const map = featureGroupRef.current._map;
      map.on("draw:drawstart", (e) => {
        setActiveDrawingTool(e.layerType);
      });
      map.on("draw:drawstop", () => {
        setActiveDrawingTool(null);
      });
    }
  }, []);

  // Handle polygon or polyline creation
  const handleCreate = async (e) => {
    const { layer } = e;

    let shapeId = uuidv4();
    let shapeData = {
      id: shapeId,
      shapeName: "",
      description: "",
      coordinates: [],
      color: drawingColor,
    };

    if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
      // Handle polyline (route)
      const latLngs = layer.getLatLngs();
      shapeData.coordinates = latLngs.map((point) => ({
        lat: point.lat,
        lng: point.lng,
      }));
      shapeData.areaType = "polyline"; // Keep this as "polyline"

      // Set the polyline color to red
      layer.setStyle({ color: "red" }); // Apply red color to the polyline
    } else if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
      const latLngs = layer.getLatLngs();
      shapeData.coordinates = Array.isArray(latLngs[0])
        ? latLngs[0].map((point) => ({ lat: point.lat, lng: point.lng }))
        : latLngs.map((point) => ({ lat: point.lat, lng: point.lng }));

      shapeData.areaType = "polygon";

      // Add temporary label until name is set
      layer.bindTooltip("Unnamed Zone", {
        permanent: true,
        direction: "center",
        className: "custom-label",
        style: {
          background: "none",
          border: "none",
          boxShadow: "none",
          color: drawingColor,
          fontWeight: "bold",
        },
      });
    } else if (layer instanceof L.Circle) {
      const center = layer.getLatLng();
      shapeData.coordinates = [
        { lat: center.lat, lng: center.lng, radius: layer.getRadius() },
      ];
      shapeData.areaType = "circle";
    }

    const currentFolder = currentFolderRef.current;

    if (
      ((currentFolder.type === "route" && shapeData.areaType === "polyline") ||
        (currentFolder.type === "geofence" &&
          (shapeData.areaType === "polygon" ||
            shapeData.areaType === "circle"))) &&
      currentFolder?.shapesId?.length <= 0
    ) {
      try {
        const payload = {
          name: currentFolder.name,
          shapeId: shapeId,
          coordinates: shapeData.coordinates.map((coord) => ({
            longitude: coord.lng,
            latitude: coord.lat,
          })),
        };

        const response = await (shapeData.areaType === "polyline"
          ? createRoute(payload)
          : createPolygon(payload)
        ).unwrap();

        if (response?.status === 0) {
          setPolygonList((prev) => [...prev, shapeData]);
          currentFolder.shapesId.push(shapeId);
          enqueueSnackbar(
            response?.messageDetail
              ? response.messageDetail
              : `${
                  shapeData.areaType === "polygon" ? "Geofence" : "Route"
                } created successfully`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        console.log("error Failed to create shape", error);
        enqueueSnackbar(error.data?.message || "Failed to create shape", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if (featureGroupRef.current) {
          featureGroupRef.current.clearLayers();
        }
      }
    } else {
      setTempShapeData(shapeData);
      setOpenNameDialog(true);
    }
  };

  // Handle polygon deletion
  const handleDelete = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      const points = layer.getLatLngs()[0]?.map((point) => ({
        lat: point.lat,
        lng: point.lng,
      }));
      setPolygons((prev) =>
        prev.filter((p) => JSON.stringify(p) !== JSON.stringify(points))
      );
      h;
    });
  };

  // Save polygons and pass them to the parent component
  const handleSave = () => {
    onSavePolygon(polygons);
  };

  // Update the handleNameSubmit function
  const handleNameSubmit = async () => {
    if (tempShapeData && shapeName.trim()) {
      try {
        const payload = {
          name: shapeName.trim(),
          shapeId: tempShapeData.id,
          coordinates: tempShapeData.coordinates.map((coord) => ({
            longitude: coord.lng,
            latitude: coord.lat,
          })),
        };

        const response = await (tempShapeData.areaType === "polyline"
          ? createRoute(payload)
          : createPolygon(payload)
        ).unwrap();

        if (response?.status === 0) {
          const finalShapeData = {
            ...tempShapeData,
            name: shapeName.trim(),
            shapeId: tempShapeData.id,
            // color: tempShapeData.color,
            coordinates: tempShapeData.coordinates.map((coord) => ({
              latitude: coord.lat,
              longitude: coord.lng,
              // ...(coord.radius && { radius: coord.radius }),
            })),
            areaType:
              tempShapeData.areaType === "polyline" ? "route" : "polygon",
          };

          // Update folder structure
          handleNewFolderStructure(finalShapeData);

          // Update polygon list while preserving existing polygons
          setPolygonList((prevList) => {
            const newList = prevList.filter(
              (p) => p.shapeId !== finalShapeData.shapeId
            );
            return [...newList, finalShapeData];
          });

          setCurrentFolderId(finalShapeData.id);

          // Force re-render of the map
          if (featureGroupRef.current) {
            const map = featureGroupRef.current._map;
            if (map) {
              setTimeout(() => {
                map.invalidateSize();
              }, 100);
            }
          }

          enqueueSnackbar(
            response?.messageDetail
              ? response.messageDetail
              : `${
                  tempShapeData.areaType === "polyline" ? "Route" : "Geofence"
                } created successfully`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        console.error("Error creating shape:", error);
        enqueueSnackbar(error.data?.message || "Failed to create shape", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if (featureGroupRef.current) {
          featureGroupRef.current.clearLayers();
        }
      }

      setOpenNameDialog(false);
      setShapeName("");
      setTempShapeData(null);
    }
  };

  useEffect(() => {
    if (currentFolderId) {
      const foundFolder = findFolderById(folders, currentFolderId);
      if (foundFolder) {
        setCurrentFolder(foundFolder);
      }
    }
  }, [currentFolderId]);

  const handleNameDialogClose = () => {
    // If user cancels, remove the drawn shape
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
    }
    setOpenNameDialog(false);
    setShapeName("");
    setTempShapeData(null);
  };

  // Recursive function to find a folder by id
  const findFolderById = (folders, id) => {
    for (const folder of folders) {
      if (folder.id === id) {
        return folder;
      }
      if (folder.children) {
        const found = findFolderById(folder.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  // Function to fetch geojson data based on latitude and longitude
  const fetchGeoJSON = async (query) => {
    // const url = `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=5&polygon_geojson=1&lat=${lat}&lon=${lon}`;
    // const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json&addressdetails=1`;
    const url = `https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=5&polygon_geojson=1`;
    const { data } = await axios.get(url);
    return data;
  };

  useEffect(() => {
    const updateGeoJSON = async () => {
      if (currentLocation) {
        const [lat, lon, query] = currentLocation;
        const geoJSONData = await fetchGeoJSON(query);
        const currentLocationGeoJSON = geoJSONData[0]?.geojson; // Assuming the first result is the desired one
        setGeoLocationJSONData(currentLocationGeoJSON); // Set the GeoJSON data
        // Use currentLocationGeoJSON as needed in your application
        console.log("Fetched GeoJSON:", currentLocationGeoJSON);
        if (mapRef.current) {
          const map = mapRef.current;

          // Remove any existing border layer
          if (map.currentLocationBorder) {
            map.removeLayer(map.currentLocationBorder);
          }
          console.log("helloooo", currentLocationGeoJSON);

          // Add the GeoJSON layer for the region
          map.currentLocationBorder = L.geoJSON(currentLocationGeoJSON, {
            style: {
              color: "black", // Border color
              weight: 2, // Border width
              fillColor: "blue", // Interior fill color
              fillOpacity: 0.01, // Fill transparency
            },
          }).addTo(map);
        }
      }
    };

    updateGeoJSON();
  }, [currentLocation]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 55px)",
      }}
    >
      {/* Header section with title and search bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "col",
            justifyContent: "start",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          {folderPath?.map((folder, index) => (
            <Box
              key={folder.id}
              onClick={() => handleFolderClick(folder)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // gap: "4px",
              }}
            >
              <MDTypography
                sx={{
                  borderRadius: "0.375em",
                  cursor: "pointer",
                  // backgroundColor: "green",
                  fontSize: "0.8em",
                  // paddingInline: "0px",
                  ":hover": {
                    bgcolor: "rgba(255,255,255,0.2)",
                  },
                }}
              >
                {folder.name}
              </MDTypography>
              {index !== folderPath.length - 1 && (
                <MDTypography>{"/"}</MDTypography>
              )}
            </Box>
          ))}
        </Box>
        <Box sx={{ width: "auto" }}>
          <SearchOnMap
            currentPage={"Map"}
            setCurrentLocation={setCurrentLocation}
            mapRef={mapRef}
          />
        </Box>
      </Box>

      {/* Map container */}
      <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
        {/* P_2 */}
        <FormControlLabel
          checked={showUserLocations}
          onChange={() => setShowUserLocations(!showUserLocations)}
          control={<Checkbox />}
          label="Users"
          sx={{
            position: "absolute",
            top: 10,
            right: 110,
            zIndex: 1000,
            backgroundColor: "#4a4e69",
            borderRadius: 2,
            pr: 1,
          }}
        />
        <FormControlLabel
          checked={showOverlapping}
          onChange={() => setShowOverlapping(!showOverlapping)}
          control={<Checkbox />}
          label="Overlapping"
          sx={{
            position: "absolute",
            top: 10,
            right: 210,
            zIndex: 1000,
            backgroundColor: "#4a4e69",
            borderRadius: 2,
            pr: 1,
          }}
        />
        {activeDrawingTool && (
          <Button
            startIcon={<PaletteIcon />}
            onClick={handleColorPickerOpen}
            variant="contained"
            sx={{
              position: "absolute",
              top: 10,
              right: 50,
              zIndex: 1000,
              padding: "0px",
              backgroundColor: drawingColor,
              "&:hover": {
                backgroundColor: drawingColor,
                filter: "brightness(0.9)",
              },
            }}
          ></Button>
        )}

        <Popover
          open={Boolean(colorPickerAnchor)}
          anchorEl={colorPickerAnchor}
          onClose={handleColorPickerClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <SketchPicker
            color={drawingColor}
            onChange={handleColorChange}
            disableAlpha
            onChangeComplete={handleColorChange}
          />
        </Popover>

        <MapContainer
          key={currentLocation.toString()}
          center={currentLocation}
          zoom={zoomLevel}
          style={{ height: "100%", width: "100%", zIndex: 1 }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          <FeatureGroup ref={featureGroupRef}>
            <EditControl
              key={drawingColor}
              position="topright"
              onCreated={handleCreate}
              onDeleted={handleDelete}
              draw={{
                polyline: {
                  shapeOptions: { color: drawingColor },
                  showLength: true,
                },
                polygon: {
                  shapeOptions: { color: drawingColor },
                  showArea: false,
                  showLength: true,
                },
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
                markerOptions: {
                  icon: L.icon({
                    iconUrl: "/path/to/marker-icon.png",
                    iconSize: [32, 48],
                  }),
                },
              }}
            />
          </FeatureGroup>

          <MapController
            bounds={bounds}
            polygonData={
              currentFolder?.type === "project" ||
              currentFolder?.type === "zones" ||
              currentFolder?.type === "routes"
                ? geoLocationJSONData
                : polygonList.filter((item) =>
                    getShapesIdsForFolder(currentFolder).includes(item.shapeId)
                  )
            }
            type={
              currentFolder?.type === "project" ||
              currentFolder?.type === "zones" ||
              currentFolder?.type === "routes"
                ? "geojson"
                : "polygonData"
            }
          />
        </MapContainer>

        {/* Save Button */}
        {/* <Button
          variant="contained"
          onClick={handleSave}
          sx={{ position: "absolute", top: 10, right: 50, zIndex: 1000 }}
        >
          Save Geofence
        </Button> */}
      </Box>

      <ShapeNameDialog
        open={openNameDialog}
        onClose={handleNameDialogClose}
        onSubmit={handleNameSubmit}
        shapeName={shapeName}
        setShapeName={setShapeName}
        shapeType={tempShapeData?.areaType === "polygon" ? "Geofence" : "Route"}
      />
    </Box>
  );
};

export default MapComponent;
