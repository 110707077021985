const callCenterConfigurationReports = [
  {
    icon: "calendar_view_week",
    key: "queueTimeInterval",
    name: "Queue Time Interval",
    url: "home/app/QueueTimeInterval",
    productionReady: true,
  },

  {
    icon: "analytics_icon  ",
    key: "queueAbandonedReport",
    name: "UnAnswered Report",
    url: "home/app/Unanswered_Report",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "dispositionAnalysisQueue",
    name: "DispositionAnalysisQueue",
    url: "home/app/billing/DispositionAnalysisQueue",
    productionReady: true,
  },
  {
    icon: "article",
    key: "answeredReport",
    name: "Answered Report",
    url: "home/app/AnsweredReport",
    productionReady: true,
  },
  {
    icon: "article",
    key: "lastAbandonedRecords",
    name: "Last Abandoned Records",
    url: "home/app/LastAbandonedRecords",
    productionReady: true,
  },
  // {
  //   icon: "calendar_view_week",
  //   key: "group-abandoned-call",
  //   name: "Group Abandoned Call",
  //   url: "home/app/configurations/report/GroupAbandonedCall",
  //   productionReady: true,
  // },
  {
    icon: "article",
    key: "agentCallDataAnalysis",
    name: "Agent Call Data Analysis",
    url: "home/app/AgentCallDataAnalysis",
    productionReady: true,
  },
  {
    icon: "article",
    key: "callerCallDataAnalysis",
    name: "Caller Call Data Analysis",
    url: "home/app/CallerCallDataAnalysis",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Agent Performance Daily Report",
    url: "home/app/billing/agent-performance",
    productionReady: true,
  },
  {
    icon: "assignment",
    key: "agent-performance-summary",
    name: "Agent Performance Summary Report",
    url: "home/app/billing/agent-performance-summary",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "queuePerformance",
    name: "Queue-Performance Daily Report",
    url: "home/app/configurations/report/queuePerformanceDailyReport",
    productionReady: true,
    disabled:true
  },
  {
    icon: "article",
    key: "queuePerformanceSummary",
    name: "Queue-Performance-Summary Report",
    url: "home/app/configurations/report/queuePerformanceSummary",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "calendar_view_week",
    key: "user-login-logout",
    name: "Agent-LogIn-LogOut",
    url: "home/app/configurations/user-login-logout",
    productionReady: true,
  },
  {
    icon: "analytics_icon  ",
    key: "campaignSummary",
    name: "Campaign Summary",
    url: "home/app/campaignSummary",
    productionReady: true,
  },
  {
    icon: "article",
    key: "callDisposition",
    name: "Disposition Analysis-Campaign",
    url: "home/app/callDisposition",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "leadSummary",
    name: "Lead Summary",
    url: "home/app/leadSummary",
    productionReady: true,
  },
  // {
  //   icon: "summarize",
  //   key: "agent-DailyReport",
  //   name: "Daily Report",
  //   url: "home/app/billing/agent-dailyReport",
  //   productionReady: true,
  // },
];

export { callCenterConfigurationReports };
