import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useState } from "react";
import getIcon from "../Icons/getIcon";
import MDTypography from "components/MDTypography";
import AlertSaveCancel from "../AlertsDialogs/AlertSaveCancel";
import { enqueueSnackbar } from "notistack";
import {
  Autocomplete,
  Badge,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import colors from "assets/theme/base/colors";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import COSExtensionTable from "./COSExtensionTable";

const DropZone = ({ onDrop, folders, expanded, handleChange, currentFolder }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [isOver, setIsOver] = useState(false);
  const [assignedEntity, setAssignedEntity] = useState([]);
  const [openDialog, setOpenDialog] = useState(null);
  const [selectedInput, setSelectedInput] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = () => {
    setIsOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsOver(false);
    const data = JSON.parse(e.dataTransfer.getData("application/json"));

    //this is the data which is always droppen in the box
    // setOpenDialog(data);
    // onDrop(data); // Call the parent callback with dropped data
    handleConfirmSave(data);
  };

  const assignExtension = () => {
    if (selectedInput?.id) {
      const { label, ...rest } = selectedInput;
      const selectedData = { ...rest, name: label };
      console.log("selectedInput", selectedData);
      handleConfirmSave(selectedData);
    }
  };
  const handleConfirmSave = (droppedItem) => {
    const filterId = assignedEntity.filter(
      (item) => item.id === droppedItem?.id
    );
    console.log("ankit", filterId);
    if (filterId.length > 0) {
      enqueueSnackbar(`${droppedItem?.name} is already Exist`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setOpenDialog(null);
      return;
    }
    setAssignedEntity([...assignedEntity, droppedItem]);
    enqueueSnackbar("Successfully Added", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setOpenDialog(null);
  };

  const flattenData = (data) => {
    let flattened = [];
    data.forEach((item) => {
      const { name, ...rest } = item;

      flattened.push({ ...rest, label: name });
      if (item.children && item.children.length > 0) {
        flattened = flattened.concat(flattenData(item.children));
      }
    });
    return flattened;
  };

  const flattenedData = flattenData(folders);

  const handleDelete = (folder) => {
    setOpenDialog(folder);
  };

  const handleConfirmDelete = () => {
    setAssignedEntity((prevList) =>
      prevList.filter((item) => item.id !== openDialog?.id)
    );
    enqueueSnackbar("Successfully Removed", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });

    setOpenDialog(null);
  };

  console.log("assigned---", assignedEntity);

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "end",
          position: "absolute",
          top: "1.5em",
          right: "1em",
          zIndex: 100,
        }}
      >
        <Autocomplete
          id="combo-box-demo"
          options={flattenedData}
          sx={{ width: 200 }}
          inputValue={selectedInput?.label}
          onChange={(e, newInputValue) => setSelectedInput(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Extension"
              size="small"
              //  InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={assignExtension}
          sx={{ padding: "0px 50px " }}
        >
          Add
        </MDButton>
      </MDBox>

      <div
        style={styles.dropZone}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          minHeight: "321px",
        }}
      >
        <MDBox sx={{ padding: "10px 10px" }}>
          Assign Extension by drag and drop
        </MDBox>
      </div>

      <Accordion
        sx={{ bgcolor: "#202940", marginBottom: "10px" }}
        expanded={expanded === "extension"}
        onChange={handleChange("extension")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            color: "#fff",
            flexDirection: "row-reverse",
            gap: 1,
            fontSize: "16px",
          }}
        >
          Assigned Extensions List
        </AccordionSummary>
        <AccordionDetails>
          {/* <div
            style={styles.dropZone}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              minHeight: "321px",
            }}
          >
            {assignedEntity.length === 0 ? (
              <MDBox sx={{ padding: "10px 10px" }}>
                Assign Extension by drag and drop
              </MDBox>
            ) : (
              <MDBox
                sx={{
                  backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                  // display: "grid",
                  // gridTemplateColumns: `repeat(auto-fill, minmax(110px, 1fr))`,

                  // gridTemplateColumns: {
                  //   xs: "repeat(1, 1fr)", // 1 column on extra small screens
                  //   sm: "repeat(2, 1fr)", // 2 columns on small screens
                  //   md: "repeat(8, 1fr)", // 4 columns on medium screens
                  //   lg: "repeat(8, 1fr)", // 5 columns on large screens
                  // },
                  gap: 1,
                  padding: "10px 10px",
                }}
              >
                {assignedEntity?.map((folder) => (
                  <MDBox
                    key={folder?.id}
                    sx={{
                      // textAlign: "center",
                      // marginLeft: "0.5em",
                      // color: "white",
                      borderRadius: "8px",
                      backgroundColor: darkMode ? "#2c3e50" : "#C0C2C4",
                      // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.7)",
                      // padding: "0px 5px",
                    }}
                  >
                    <Chip
                      icon={
                        <MDBox sx={{ fontSize: "1.4em" }}>
                          {" "}
                          {getIcon(folder.type, darkMode)}{" "}
                        </MDBox>
                      }
                      label={folder.name}
                      color={darkMode ? "info" : "default"}
                      onDelete={() => handleDelete(folder)}
                      size="large"
                      sx={{
                        color: darkMode ? "#fff" : "",
                        bgcolor: darkMode ? "#2c3e50" : "#C0C2C4",
                      }}
                    />
                  </MDBox>
                ))}
              </MDBox>
            )}
          </div> */}
          <COSExtensionTable
            assignedEntity={assignedEntity}
            handleDelete={handleDelete}
          />
        </AccordionDetails>
      </Accordion>

      {openDialog && (
        <AlertSaveCancel
          isSaving={openDialog}
          setIsSaving={setOpenDialog}
          handleConfirmSave={handleConfirmDelete}
          name={`remove ${openDialog?.name} from ${currentFolder?.name} `}
          buttonText={"Remove"}
        />
      )}
    </>
  );
};

const styles = {
  dropZone: {
    width: "100%",
    minheight: "150px",
    textAlign: "center",
    margin: "20px 0px",
    border: "2px dashed #ccc",
  },
};

export default DropZone;
