const reportMenus = [
  {
    icon: "edit",
    key: "configuration.billing-cdr",
    name: "Billing CDR",
    url: "home/app/billing/Cdr",
    description: "Billing-CDR",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "billingcdrsummary",
    name: "Billing CDR Summary",
    url: "home/app/billingcdrSummary",
    description: "Billing Cdr Summary",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "details",
    key: "billingcdrdetails",
    name: "Billing CDR Details",
    url: "home/app/billingcdrdetails",
    description: "Billing CDR Details",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "request_quote",
    key: "billingsummary",
    name: "Call Billing Summary",
    url: "home/app/callbillingsummary",
    description: "",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "summarize_icon",
    key: "csm_reports",
    name: "CMS Reports",
    url: "home/app/cms-reports",
    description: "CMS Reports",
    productionReady: true,
  },
  {
    icon: "article",
    key: "callDisposition",
    name: "Disposition Analysis-Campaign",
    url: "home/app/callDisposition",
    description: "CallDisposition Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "analytics_icon  ",
    key: "campaignSummary",
    name: "Campaign Summary",
    url: "home/app/campaignSummary",
    description: "Campaign Summary",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "crisis_alert",
    key: "configuration.nms-alert-history",
    name: "Nms-Alert-History",
    url: "home/app/configurations/nms-alert-history",
    description: "Nms-Alert-History",
    productionReady: true,
  },
  {
    icon: "warning_icon",
    key: "configuration.eams",
    name: "EAMS",
    url: "home/app/configurations/EAMS",
    description: "EAMS",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "user-login-logout",
    name: "Agent-LogIn-LogOut",
    url: "home/app/configurations/user-login-logout",
    description: "Agent-Login-Logout Report",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "summarize_icon",
    key: "Customer.CDRreport",
    name: "Customer CDR Rreport",
    url: "home/app/configurations/CustomerCDRreport",
    description: "Customer CDR report",
    productionReady: true,
  },
  {
    icon: "folder_copy",
    key: "Operator.Summary",
    name: "Operator Summary",
    url: "home/app/configurations/Operator Summary",
    description: "Operator.Summary Report",
    productionReady: true,
  },
  {
    icon: "receipt",
    key: "pareport",
    name: "PA Reports",
    url: "home/app/configurations/PAreport",
    description: "PA-Reports",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Agent Performance Report",
    url: "home/app/billing/agent-performance",
    description: "Agent Performance Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "summarize",
    key: "agent-performance-summary",
    name: "Agent Performance Summary",
    url: "home/app/billing/agent-performance-summary",
    description: "Agent Performance Summary",
    productionReady: true,
    disabled: true,
  },
  // {
  //   icon: "summarize",
  //   key: "agent-DailyReport",
  //   name: "Daily Report",
  //   url: "home/app/billing/agent-dailyReport",
  //   description: "Agent Daily Report",
  //   productionReady: true,
  //   disabled: false,
  // },
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Daily Report",
    url: "home/app/billing/agent-performance",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "group-abandoned-call",
    name: "Group Abandoned Call",
    url: "home/app/configurations/report/GroupAbandonedCall",
    description: "Group Abandoned Call",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "call_received_icon",
    key: "inboundReport",
    name: "Last 10 - Inbound Call",
    url: "home/app/configurations/report/inboundReport",
    description: "Agent Reports",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "tty_icon",
    key: "outboundReport",
    name: "Last 10 - Outbound Call",
    url: "home/app/configurations/report/outboundReport",
    description: "Agent Reports",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "call_missed_icon",
    key: "missedCallReport",
    name: "Last 10 - Missed Call",
    url: "home/app/configurations/report/missedCallReport",
    description: "Agent Reports",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "summarize_icon",
    key: "agentcdrreport",
    name: "Agent CDR Report",
    url: "home/app/configurations/report/agentcdrreport",
    description: "Agent CDR Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "summarize",
    key: "queuePerformance",
    name: "Queue-Performance",
    url: "home/app/configurations/report/queuePerformance",
    description: "Queue-Performance",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "article",
    key: "queuePerformanceSummary",
    name: "Queue-Performance-Summary",
    url: "home/app/configurations/report/queuePerformanceSummary",
    description: "Queue-Performance-Summary",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "folder_copy",
    key: "consolidatedExtension",
    name: "Extension Properties",
    url: "home/app/configurations/consolidatedExtension",
    description: "STD/ISD Extension",
    productionReady: true,
  },
  {
    icon: "folder_copy",
    key: "internalForwardReport",
    name: "Internal Forward Report",
    url: "home/app/configurations/internalForward",
    description: "Internal-Forward",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "article",
    key: "agentCallDataAnalysis",
    name: "Agent Call Data Analysis",
    url: "home/app/AgentCallDataAnalysis",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "article",
    key: "callerCallDataAnalysis",
    name: "Caller Call Data Analysis",
    url: "home/app/CallerCallDataAnalysis",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "article",
    key: "lastAbandonedRecords",
    name: "Last Abandoned Records",
    url: "home/app/LastAbandonedRecords",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "article",
    key: "answeredReport",
    name: "Answered Report",
    url: "home/app/AnsweredReport",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  // {
  //   icon: "summarize",
  //   key: "queueAbandonedTransferCalls",
  //   name: "QueueAbandonedTransferCalls",
  //   url: "home/app/billing/QueueAbandonedTransferCalls",
  //   description: "Report",
  //   productionReady: true,
  // },
  {
    icon: "summarize",
    key: "dispositionAnalysisQueue",
    name: "DispositionAnalysisQueue",
    url: "home/app/billing/DispositionAnalysisQueue",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "analytics_icon  ",
    key: "queueAbandonedReport",
    name: "Queue Abandoned",
    url: "home/app/QueueAbandonedReport",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "calendar_view_week",
    key: "queueTimeInterval",
    name: "Queue Time Interval",
    url: "home/app/QueueTimeInterval",
    description: "Report",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "summarize",
    key: "leadSummary",
    name: "Lead Summary",
    url: "home/app/leadSummary",
    description: "Summary",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "summarize",
    key: "ViewleadSummary",
    name: "View Lead Summary",
    url: "home/app/viewleadSummary",
    description: "Summary",
    productionReady: true,
    disabled: true,
  },
];

export { reportMenus };
