import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function Filter({ filterText, onFilter, onClear }) {
  return (
    <MDBox container>
      <MDInput
        id="search"
        type="text"
        label="Filter table data"
        value={filterText}
        onChange={onFilter}
        size="small"
      />
      <MDButton
        sx={{ borderRadius: "0px 6px 6px 0px" }}
        iconOnly
        variant="gradient"
        color="info"
        onClick={onClear}
      >
        X
      </MDButton>
    </MDBox>
  );
}

export default Filter;
