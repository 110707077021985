import CTLTable from "application/components/CTLTable";
import { getColumns, operationURLS } from "./model";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import { setClickToCall } from "context";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import MDButton from "components/MDButton";
import dialog from "assets/theme/components/dialog";
import MDBox from "components/MDBox";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

const Attributes = {
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  modes: [],
};

export default function EventList({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  url,
}) {
  const userName = AuthenticationService.getUserName();
  const UserNumber = AuthenticationService.getExtensionNumber();
  const componentName = `${url} Log`;
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [message, setMessage] = useState("");
  const [updateList, setUpdateList] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  // Getting the From Current Time
  const FromCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T00:01`;
    return isoString;
  };

  // Getting the To Current Time
  const ToCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T23:59`;
    return isoString;
  };
  const [advancedFilters, setAdvancedFilters] = useState([
    {
      direction: "from",
      dataType: "date",
      fieldName: "createdOn",
      value: FromCurrentDateTime(),
    },
    {
      direction: "to",
      dataType: "date",
      fieldName: "createdOn",
      value: ToCurrentDateTime(),
    },
  ]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        DownloadData: onDownload,
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const onDownload = () => {
    const requestBodyData = {
      sortBy: "createdOn",
      search: searchText,
      sortDataType: "string",
      sortDirection: "desc",
      columnNames: [
        "createdBy",
        "createdOn",
        "eventCategory",
        "callingGroup",
        "eventStatus",
        "callerNumber",
        "destinationNumber",
      ],
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(`activityLog/downloadCSV/${url}`, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${url}-Log.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: `${url}-Log`,
            status: "CSV Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: `${url}-Log`,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickToCall = (data) => {
    if (data.callingGroup == "Dial Call" || data.callingGroup == "DSS Call") {
      setClickToCall(dispatch, data.destinationNumber);
    } else {
      setClickToCall(dispatch, data.callerNumber);
    }
  };

  const onClickToMessage = (data) => {
    if (data.callingGroup == "Dial Call" || data.callingGroup == "DSS Call") {
      setSelectedRow(data.destinationNumber);
    } else {
      setSelectedRow(data.callerNumber);
    }
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setMessage("");
  };

  const activityLog = (
    event,
    eventCategory,
    eventStatus,
    callerNumber,
    destinationNumber,
    eventData
  ) => {
    const bodyData = {
      event: event,
      eventCategory: eventCategory,
      eventStatus: eventStatus,
      callerNumber: callerNumber,
      destinationNumber: destinationNumber,
      eventData: {
        msg: eventData,
      },
      userId: userName,
      deviceId: UserNumber,
      epochDate: Math.floor(Date.now() / 1000),
    };
    application.post("activityLog/create", bodyData);
  };

  const onMessageSend = () => {
    if (selectedRow) {
      const body = {
        extension: selectedRow,
        messageDetail: message,
      };
      application
        .post("chatMessage/sendMessageByExtension", body)
        .then((response) => {
          activityLog(
            "message",
            "Alert Message",
            "Send",
            UserNumber,
            selectedRow,
            `${userName} send message to ${selectedRow}, message: ${message}`
          );
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: componentName,
            status: response.data.message,
          });
          setOpen(false);
          setMessage("");
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "",
            type: "error",
            pagename: componentName,
            status: error.response.data.message,
          });
          setOpen(false);
          setMessage("");
        });
    }
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <CTLTable
        apiURL={`activityLog/list/${url}`}
        columns={getColumns(onClickToCall, onClickToMessage)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="createdOn"
        updateList={updateList}
        list
        searchText={searchText}
        pageId={pageId}
        hasRowSelection={false}
        disablePagination={false}
        refetchInterval={5000}
      />

      <CTLAdvancedFilter
        entityName="Event View"
        columns={getColumns(onClickToCall, onClickToMessage)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />

      <Dialog open={open} maxWidth="sm" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Send Message
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          style={{ paddingTop: "10px" }}
        >
          <MDBox sx={{ width: 500 }}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              variant="outlined"
              label="Type Message"
              value={message}
              onChange={handleMessageChange}
              multiline
              rows={6}
            />
          </MDBox>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={onMessageSend} color="success" autoFocus>
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
