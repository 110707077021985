import { Height } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EmptyImage from "../../../assets/images/mediacenter/emptyFolder.svg";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import HospitalComponent from "./HospitalComponent";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { v4 as uuidv4 } from "uuid";
import SearchOnMap from "./SearchOnMap";
import ContextMenu from "./ContextMenu";
import { useGetGeometryDataQuery } from "features/geofenceSlice";
import { useGeometryDataJSON } from "./useGeometryDataJSON";
import { enqueueSnackbar } from "notistack";
import { useGetAllUserMutation } from "features/geofenceSlice";

const GeoFenceConfiguration = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [entityName, setEntityName] = useState("");
  const [openCreateTableDiologue, setOpenCreateTableDiologue] = useState(false);
  const [createGroup, setCreateGroup] = useState("");
  const [navigateToMain, setNavigatetoMain] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const { data, isLoading, isSuccess, isError, refetch } =
    useGetGeometryDataQuery();
  const [getAllUser] = useGetAllUserMutation();
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [groupsFolders, setGroupsFolders] = useState([]);

  const refetchData = async () => {
    try {
      const geometryData = await refetch();
      const userResponse = await getAllUser().unwrap();
      console.log("userResponse", userResponse);
      const userList = userResponse.data.currentPageData;
      setUserList(userList);

      if (geometryData.data && userResponse) {
        const newFolders = JSON.parse(JSON.stringify(geometryData.data));
        setFolders(newFolders);
      }
    } catch (error) {
      console.error("Failed to refetch data:", error);
    }
  };

  const { changeGeometryDataJSON } = useGeometryDataJSON(refetchData);

  const handleClose = () => {
    setOpenCreateTableDiologue(false);
  };
  const handleAddFolderAtParent = async (type, name) => {
    const parentId = uuidv4();
    const projectFolder = {
      id: parentId,
      name: type === "project" ? name : "Project",
      type: type,
      location: currentLocation,
      parentId: "",
      children: [
        {
          id: uuidv4(),
          name: "Zones",
          type: "zones",
          parentId: parentId,
          children: [],
        },
        {
          id: uuidv4(),
          name: "Routes",
          type: "routes",
          parentId: parentId,
          children: [],
        },
      ],
    };

    try {
      setIsAPILoading(true);
      await changeGeometryDataJSON([projectFolder], `created ${type}`);

      // Add new project to folders without creating additional Groups
      setFolders((prevFolders) => {
        // Filter out any Groups folders that might be inside projects
        const cleanedFolders = prevFolders.map((folder) => {
          if (folder.type === "project") {
            return {
              ...folder,
              children: folder.children.filter(
                (child) => child.type !== "users"
              ),
            };
          }
          return folder;
        });

        return [...cleanedFolders, projectFolder];
      });

      setIsAPILoading(false);
    } catch (error) {
      console.error("Error creating folders:", error);
      setIsAPILoading(false);
    }
  };

  const handleChangeEntityName = (e) => {
    setCreateGroup(e.target.value);
  };

  const handleNewData = () => {
    setOpenCreateTableDiologue(true);
  };

  const handleSaveGroup = () => {
    if (createGroup !== "") {
      setEntityName(createGroup);
      handleAddFolderAtParent("project", createGroup);
      setCreateGroup("");
      setOpenCreateTableDiologue(false);
      setNavigatetoMain(true);
      setMiniSidenav(dispatch, true);
    } else {
      alert("Please fill the input fields");
    }
  };

  // const data = [];

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess && folders.length === 0) {
        try {
          // Get the project folders
          const newFolders = JSON.parse(JSON.stringify(data));

          // Only create Groups folder if there are projects
          const projectFolders = newFolders.filter(
            (folder) =>
              folder.type === "project" ||
              (folder.type !== "users" &&
                folder.children?.some((child) => child.type === "project"))
          );

          if (projectFolders.length > 0) {
            // Fetch users for the Groups folder
            const userResponse = await getAllUser().unwrap();
            const users = userResponse.data.currentPageData;
            console.log("hiii", users);
            setUserList(users);

            // Create Groups folder only if there are projects
            const groupsFolder = {
              id: uuidv4(),
              name: "Users List",
              type: "users",
              parentId: "",
              children: users.map((user) => ({
                id: uuidv4(),
                name: user.username || "Unknown User",
                type: "user",
                parentId: "",
                userData: user,
              })),
            };

            setFolders([...projectFolders, groupsFolder]);
          } else {
            // If no projects, set empty folders
            setFolders([]);
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setFolders([]);
        }
      }
    };

    fetchData();
  }, [isSuccess, data, folders.length, getAllUser]);

  const handleUserDrop = (draggedUser, targetFolder) => {
    setFolders((prevFolders) => {
      return prevFolders.map((folder) => {
        const updateChildren = (children) => {
          return children.map((child) => {
            if (child.id === targetFolder.id) {
              // Directly add the user to the target folder
              const newUser = {
                ...draggedUser,
                parentId: targetFolder.id,
                id: uuidv4(),
              };

              return {
                ...child,
                children: [...(child.children || []), newUser],
              };
            }
            if (child.children) {
              return {
                ...child,
                children: updateChildren(child.children),
              };
            }
            return child;
          });
        };

        if (folder.children) {
          return {
            ...folder,
            children: updateChildren(folder.children),
          };
        }
        return folder;
      });
    });
  };

  return (
    <>
      {isAPILoading ? (
        <Box
          sx={{
            width: "full",
            height: "93vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0",
            padding: "0",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {folders.some((folder) => folder.type === "project") ? (
            <HospitalComponent
              entityName={entityName}
              folders={folders}
              setFolders={setFolders}
              userList={userList}
              onUserDrop={handleUserDrop}
            />
          ) : (
            <Box
              sx={{
                width: "full",
                height: "93vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0",
                padding: "0",
              }}
            >
              {isLoading && userList.length === 0 ? (
                <CircularProgress />
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <img src={EmptyImage} alt="Empty Folder" />
                  </Box>
                  <MDTypography
                    variant="h4"
                    sx={{
                      fontWeight: "400",
                    }}
                    textAlign="center"
                  >
                    You haven't configured anything yet
                  </MDTypography>
                  <MDTypography variant="p">
                    Click on the button below to add a new Project{" "}
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{ marginTop: "3em" }}
                    onClick={handleNewData}
                  >
                    New Project
                  </MDButton>
                </>
              )}
            </Box>
          )}
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openCreateTableDiologue}
            onClose={handleClose}
          >
            <DialogTitle
              textAlign="center"
              sx={{
                color: darkMode ? "#fff" : "#000",
                backgroundColor: darkMode ? "#202940" : "#FFFFFF",
              }}
            >
              {/* Add Details */}
            </DialogTitle>

            <MDBox
              sx={{
                backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
                padding: "10px 30px 20px 30px",
              }}
            >
              <MDInput
                fullWidth
                sx={{ textAlign: "center" }}
                size="small"
                label="Project Name"
                autoFocus
                // placeholder="eg. St. Louis Hospital"
                variant="outlined"
                onChange={handleChangeEntityName}
                inputProps={{ autoComplete: "off" }}
              />
              <SearchOnMap
                currentPage={"Home"}
                setCurrentLocation={setCurrentLocation}
              />
              {/* <MDInput
                size="large"
                label="Row Name"
                variant="outlined"
                onChange={handleChangeGroupeName}
              /> */}
            </MDBox>

            <DialogActions
              sx={{
                backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                textAlign: "center",
              }}
            >
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleSaveGroup}
              >
                Save
              </MDButton>

              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleClose}
              >
                Close
              </MDButton>
            </DialogActions>
          </Dialog>
          <ContextMenu
            // ... other props
            setFolders={setFolders} // Make sure this prop is being passed
          />
        </>
      )}
    </>
  );
};

export default GeoFenceConfiguration;
