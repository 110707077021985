// Sample user journey data showing movement between different locations in Indore
export const userJourneyData = {
  user1: {
    name: "John Doe",
    journeys: [
      {
        // Today's journey
        date: new Date().toISOString().split("T")[0], // Today's date
        points: [
          {
            timestamp: `${new Date().toISOString().split("T")[0]}T09:00:00`,
            location: { lat: 25.435801, lng: 81.846311 }, // Location 1
            speed: 0,
            status: "Start",
            address: "Location 1, Prayagraj",
          },
          {
            timestamp: `${new Date().toISOString().split("T")[0]}T09:15:00`,
            location: { lat: 25.437801, lng: 81.848311 }, // Location 2
            speed: 25,
            status: "Moving",
            address: "Location 2, Prayagraj",
          },
          {
            timestamp: `${new Date().toISOString().split("T")[0]}T09:30:00`,
            location: { lat: 25.439801, lng: 81.850311 }, // Location 3
            speed: 30,
            status: "Moving",
            address: "Location 3, Prayagraj",
          },
          {
            timestamp: `${new Date().toISOString().split("T")[0]}T09:45:00`,
            location: { lat: 25.441801, lng: 81.852311 }, // Location 4
            speed: 0,
            status: "Stop",
            address: "Location 4, Prayagraj",
          },
        ],
      },
      {
        // Yesterday's journey
        date: new Date(Date.now() - 86400000).toISOString().split("T")[0],
        points: [
          {
            timestamp: `${
              new Date(Date.now() - 86400000).toISOString().split("T")[0]
            }T14:00:00`,
            location: { lat: 25.443801, lng: 81.854311 }, // Location 5
            speed: 0,
            status: "Start",
            address: "Location 5, Prayagraj",
          },
          {
            timestamp: `${
              new Date(Date.now() - 86400000).toISOString().split("T")[0]
            }T14:20:00`,
            location: { lat: 25.445801, lng: 81.856311 }, // Location 6
            speed: 20,
            status: "Moving",
            address: "Location 6, Prayagraj",
          },
          {
            timestamp: `${
              new Date(Date.now() - 86400000).toISOString().split("T")[0]
            }T14:40:00`,
            location: { lat: 25.447801, lng: 81.858311 }, // Location 7
            speed: 0,
            status: "Stop",
            address: "Location 7, Prayagraj",
          },
        ],
      },
    ],
  },
};

// Function to get user journey data for a specific time range
export const getUserJourneyData = (userId, startDate, endDate) => {
  const userData = userJourneyData[userId];
  if (!userData) return [];

  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  // Flatten all points from all journeys within the date range
  const filteredPoints = userData.journeys
    .filter((journey) => {
      const journeyDate = new Date(journey.date).getTime();
      return journeyDate >= start && journeyDate <= end;
    })
    .flatMap((journey) => journey.points)
    .filter((point) => {
      const pointTime = new Date(point.timestamp).getTime();
      return pointTime >= start && pointTime <= end;
    });

  return filteredPoints;
};

// Function to get all points for a specific date
export const getJourneyForDate = (userId, date) => {
  const userData = userJourneyData[userId];
  if (!userData) return [];

  const journey = userData.journeys.find((j) => j.date === date);
  return journey ? journey.points : [];
};
