import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import noList from "../../../assets/images/noList.png";
import { Call as CallIcon } from '@mui/icons-material';

const Attributes = {
  modes: [],
};

const groupCallInQueue = ({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) => {
  const [controller] = useMaterialUIController();
  const { callInWaiting, activePage } = controller;


     useEffect(() => {
       Attributes && onLoad(Attributes);
       registerCallBacks && registerCallBacks({});
     }, [activePage]);


  const [filteredExtensionList, setFilteredExtensionList] = useState([]);

  useEffect(() => {
    if (searchText) {
      setFilteredExtensionList(
        callInWaiting.filter((item) =>
          item.cidName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredExtensionList(callInWaiting);
    }
  }, [searchText, callInWaiting]);

  return (
    <MDBox
      style={{
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      {filteredExtensionList?.length !== 0 ? (
        filteredExtensionList.map((item, index) => (
          <Grid
            container
            spacing={3}
            direction="column"
            key={index}
            sx={{ marginBottom: "16px" }}
          >
            <Grid item xs={12}>
              <Card
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton>
                      <CallIcon color="info" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {item.cidNumber} &nbsp;&nbsp; {item.cidName}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        ))
      ) : (
        <Card>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "80vh" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <img src={noList} height={400} alt="Empty List" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography variant="h5">
                There are no waiting calls at the moment
              </MDTypography>
            </Box>
          </Box>
        </Card>
      )}
    </MDBox>
  );
};
export default groupCallInQueue;
