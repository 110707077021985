import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";
import { useMaterialUIController } from "context";
import { setAuthType } from "context";

function smdpCredentials({ onLoad, pageName, registerCallBacks }) {
  const componentName = "SMDP Credentials";
  const [controller, dispatch] = useMaterialUIController();
  const [formData, setFormData] = useState({});
  const { activePage } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);


  const authTypeFields = {
    "No Auth": [],
    "API Key": ["key", "value", "addTo"],
    "Bearer Token": ["token"],
    "Basic Auth": ["username", "password"],
    "Digest Auth": ["username", "password"],
    "OAuth 1.0": [
      "consumerKey",
      "consumerSecret",
      "token",
      "tokenSecret",
      "signatureMethod",
    ],
    "OAuth 2.0": ["accessToken", "grantType", "clientId", "clientSecret"],
    "Hawk Authentication": ["hawkId", "hawkKey", "algorithm"],
    "AWS Signature": ["accessKey", "secretKey", "awsRegion", "serviceName"],
    "NTLM Authentication": ["username", "password", "domain"],
    "Akamai EdgeGrid": [
      "clientToken",
      "clientSecret",
      "accessToken",
      "baseUrl",
    ],
  };


    useEffect(() => {
      application
        .get(operationURLS.list)
        .then((response) => {
          let dataToDisplay = response.data.data || {};
          const { domain, authType, keyValue = {} } = dataToDisplay;
          dataToDisplay = {
            domain,
            authType,
            id : 1,
            ...keyValue,
          };
          loggerFactory.debug(
            componentName,
            "Restructured Data",
            dataToDisplay
          );
          setAuthType(dispatch, authTypeFields[authType] || []);
          setFormData(dataToDisplay);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status: error.response.status,
            message: response.data.message,
            type: "error",
            pagename: pageName,
          });
        });
    }, []);

  function onSaveForm(data) {
    loggerFactory.debug(componentName, "Data to update", data);
    const { domain, authType, id, ...rest } = data;
    const transformedData = {
      domain,
      authType,
      id,
      keyValue: rest,
    };
    application
      .post(operationURLS.update, transformedData)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          loggerFactory.debug(componentName, "Unable to save data.", error);
        }
        setNotify({
          isOpen: true,
          status: "No File Found!",
          message: "Error",
          type: "error",
          pagename: pageName,
        });
      });
  }




  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="SMDP Credentials"
        columns={getColumns()}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default smdpCredentials;
