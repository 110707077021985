import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const conferenceQuickMenus = [
 
  {
    icon: (
      <Tooltip title="Default Dashboard">
        <Icon fontSize="medium">dashboard</Icon>
      </Tooltip>
    ),
    name: "Default Dashboard",
    key: "auth-default",
    url: "/app/home",
    description: "Dashboard",
  },
];

export { conferenceQuickMenus };
