import MDBox from "components/MDBox";
import { application } from "globals/endpoints";
import React, { useEffect, useState } from "react";
import AuthenticationService from "../../authentication/AuthenticationService";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import loggerFactory from "globals/logger/logger-factory";

const Contact = ({ searchText }) => {
  const [contactList, setContactList] = useState([]);
  const [filteredContactList, setFilteredContactList] = useState([]);
  const fetchContactList = async () => {
    try {
      const response = await application.post(`uc/phoneBook/listAll`);
      setContactList(response.data.data);
    } catch (error) {
      loggerFactory.debug("Unable to get Contact List ", error);
    }
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  useEffect(() => {
    if (searchText) {
      setFilteredContactList(
        contactList.filter(
          (contact) =>
            contact.offEXtn &&
            contact.offEXtn.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredContactList(contactList);
    }
  }, [searchText, contactList]);

  const handleCheckboxChange = async (contact) => {
    if (!contact.blf) {
      try {
        const body = {
          sourceExtension: AuthenticationService.getExtensionNumber(),
          destinationExtension: [contact.offEXtn],
        };
        await application.post(`blfExtension/create`, body);
      } catch (error) {
        loggerFactory.debug("Unable to create BLF extension", error);
      }
    } else {
      try {
        await application.post(`blfExtension/delete/${contact.offEXtn}`);
      } catch (error) {
        loggerFactory.debug("Unable to delete BLF extension", error);
      }
    }

    fetchContactList();
  };

  return (
    <div>
      <MDBox
        style={{
          position: "sticky",
          top: 60,
          zIndex: 1,
          paddingBottom: "10px",
        }}
      >
        <Card style={{ backgroundColor: "gray", padding: "10px" }}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <MDTypography variant="h6">DSS </MDTypography>
            </Grid>
            <Grid item xs={4}>
              <MDTypography variant="h6">Contact Number</MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="h6">Contact Name</MDTypography>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox
        style={{
          height: "65vh",
          overflowY: "auto",
        }}
      >
        {filteredContactList?.length == 0 ? (
          <Card  style={{ height: "100%"}} >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="info" />
              &nbsp;&nbsp;
              <Typography variant="h6" color="text">
                Loading Contact List...
              </Typography>
            </Box>
          </Card>
        ) : (
          <Grid container spacing={2}>
            {filteredContactList &&
              filteredContactList.map((contact) => (
                <Grid item xs={12} key={contact.id}>
                  <Card>
                    <Box p={1}>
                      <Grid container alignItems="center">
                        <Grid item xs={2}>
                          <Checkbox
                            checked={contact.blf}
                            onChange={() => handleCheckboxChange(contact)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="caption text">
                            {contact.offEXtn}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="caption text">
                            {contact.name}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </MDBox>
    </div>
  );
};

export default Contact;
