import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/billingCDRSummary/list",
  pdfDownload: "/billingCDRSummary/generateAndDownloadPdf",
  csvDownload: "/billingCDRSummary/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Date",
      accessor: "startDayEpoch",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime:true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Extension",
      accessor: "extension",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Internal Received",
      accessor: "internalReceived",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Internal Received Answered",
      accessor: "internalReceivedAnswered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Internal Received Abandoned",
      accessor: "internalReceivedAbandoned",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Internal Dialed",
      accessor: "internalDialed",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "External Received",
      accessor: "externalReceived",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "External Received Answered",
      accessor: "externalReceivedAnswered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dateTime: true,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "External Received Abandoned",
      accessor: "externalReceivedAbandoned",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "External Dialed",
      accessor: "externalDialed",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
