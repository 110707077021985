import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,

  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/extensionView/list",
  pdfDownload: "/extensionView/generateAndDownloadPdf",
  csvDownload: "extensionView/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Extension Number",
      accessor: "extension",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension Type",
      accessor: "extensionType",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Intercom",
      accessor: "intercom",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Local",
      accessor: "local",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "STD",
      accessor: "std",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ISD",
      accessor: "isd",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DND",
      accessor: "dnd",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Voice Mail",
      accessor: "vm",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      filterControlType: "boolean",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Forward All",
      accessor: "fwdAll",
      displayInList: true,
      allowFilter: true,
      dataType: "long",
      filterControlType: "boolean",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
