import React from "react";
import {
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import FlareIcon from "@mui/icons-material/Flare";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CalendarViewMonth from "@mui/icons-material/CalendarViewMonth";
import { useMaterialUIController } from "context";

const ContextMenu = ({
  anchorContextMenu,
  closeContextMenu,
  folder,
  handleAddClick,
  handleEditClick,
  handleDeleteClick,
  setMoveExplorer,
  setOpenFeatureDialog,
  handleSetCurrentPage,
}) => {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const menuItemStyles = {
    padding: "1px 6px 0px 1.2em",
    gap: 2,
  };

  const iconStyles = {
    fontSize: "1.4em",
    color: darkMode ? "#ffffff" : "#000000",
  };

  return (
    <Menu
      anchorEl={anchorContextMenu}
      open={Boolean(anchorContextMenu)}
      onClose={closeContextMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        disableRipple
        sx={{
          display: "flex",
          gap: 1,
          borderBottom: darkMode
            ? "0.5px solid rgba(255,255,255,0.1)"
            : "0.5px solid rgba(0,0,0,0.2)",
          marginBottom: "5px",
          paddingBottom: "1px",
          "&:hover": { backgroundColor: "transparent !important" },
        }}
      >
        {folder.type !== "extension" && folder.type !== "features" && (
          <Tooltip title="Add">
            <IconButton sx={{ padding: "0px" }}>
              <AddIcon
                onClick={handleAddClick}
                sx={{
                  ...iconStyles,
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.2)"
                      : "rgba(0,0,0,0.4)",
                    borderRadius: "0.35em",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Edit">
          <IconButton>
            <EditIcon
              onClick={handleEditClick}
              sx={{
                ...iconStyles,
                padding: "2px",
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: "0.35em",
                },
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon
              onClick={handleDeleteClick}
              sx={{
                ...iconStyles,
                padding: "2px",
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: "0.35em",
                },
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Move">
          <IconButton>
            <ZoomOutMapIcon
              onClick={() => {
                setMoveExplorer(true);
                closeContextMenu();
              }}
              sx={{
                ...iconStyles,
                padding: "2px",
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: "0.35em",
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </MenuItem>
      <MenuItem
        onClick={() => setOpenFeatureDialog("features")}
        sx={menuItemStyles}
      >
        <FlareIcon sx={{ ...iconStyles }} fontSize="1.4em" />
        Add Features
      </MenuItem>
      <MenuItem
        onClick={(e) => handleSetCurrentPage(e, "services")}
        sx={menuItemStyles}
      >
        <MedicalServicesIcon sx={{ ...iconStyles }} fontSize="1.4em" />
        Class of Services
      </MenuItem>
      <MenuItem
        onClick={(e) => handleSetCurrentPage(e, "Calendar")}
        sx={menuItemStyles}
      >
        <CalendarViewMonth sx={{ ...iconStyles }} fontSize="1.4em" />
        Schedule
      </MenuItem>
    </Menu>
  );
};

export default ContextMenu;