import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;

  const savedAdditionalMode = localStorage.getItem("aditionalTheme");

  const sidebarWidth = 100;
  const { transparent, gradients, white, background } = palette;
  const { pxToRem, linearGradient } = functions;
  let backgroundValue = darkMode
  ? background.sidenav
  : (savedAdditionalMode === "true"
    ? linearGradient("#66A9E8")
    : linearGradient(gradients.dark.main, gradients.dark.state));

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue = white.main;
  }


  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("sm")]: {
      marginBottom: transparentSidenav ? 0 : 0,
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("sm")]: {
      width: pxToRem(100),
      height: "100vh",
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      margin: 0,
      padding: 0,
      boxShadow: "none",
      border: "none",
      marginBottom : 0,
      position: "fixed",
      borderRadius : "0",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
