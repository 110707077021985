import { useEffect, useRef } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import theme from "assets/theme-dark";

// Template context
import { useMaterialUIController } from "context";

// Customer chat support configs
import MESSAGE_TAIL_POSITIONS from "../configs/messageTailPositions";
import MESSAGE_AUTHOR_TYPES from "../configs/messageAuthorTypes.js";

// Customer chat support utilities
import getTimeFromTimestamp from "../utils/getClockTime";
import getMessageTailPosition from "../utils/getMessageTailPosition";

function ChatWindow({ messages }) {
  const chatWindowRef = useRef(null);
  const { darkMode } = useMaterialUIController()[0];
  const { palette, borders, functions } = theme;
  const { pxToRem } = functions;

  const { agent, customer } = MESSAGE_AUTHOR_TYPES;
  const { left, right } = MESSAGE_TAIL_POSITIONS;

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    chatWindow.scrollTop = chatWindow.scrollHeight;
  }, [messages]);

  return (
    <MDBox
      ref={chatWindowRef}
      bgColor={darkMode ? palette.background.default : palette.light.main}
      borderRadius={borders.borderRadius.xl}
      height="100%"
      p={2}
      sx={{
        overflowY: "auto",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23344767' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        "::-webkit-scrollbar": {
          width: pxToRem(2.5),
        },
        "::-webkit-scrollbar-thumb": {
          background: "#555",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-track": {
          display: "none",
        },
      }}
    >
      {messages
        .filter(({ message }) => message)
        .map(
          ({ agentId, customerNumber, type, messageEpoch, message }, index) => {
            const time = getTimeFromTimestamp(messageEpoch);

            const messageTailPosition =
              index === 0
                ? left
                : getMessageTailPosition(type, messages[index - 1].type);

            return (
              <MDBox
                key={uuidv4()}
                display="flex"
                justifyContent={type === agent && "flex-end"}
              >
                <MDTypography
                  bgcolor={palette.gradients.secondary.state}
                  variant="subtitle2"
                  position="relative"
                  borderRadius={borders.borderRadius.lg}
                  maxWidth={pxToRem(500)}
                  minWidth={pxToRem(100)}
                  my={0.1}
                  px={1}
                  pt={messageTailPosition ? 2.6 : 1}
                  pb={2.2}
                  sx={{
                    borderTopLeftRadius: messageTailPosition === left && 0,
                    borderTopRightRadius: messageTailPosition === right && 0,
                  }}
                >
                  <MDBox
                    position="absolute"
                    top="0"
                    left={messageTailPosition === left && "-.6em"}
                    right={messageTailPosition === right && "-.6em"}
                    display={!messageTailPosition && "none"}
                    width=".7em"
                    height=".7em"
                    bgColor={palette.gradients.secondary.state}
                    sx={{
                      clipPath: `polygon(100% 0, 0 0, ${
                        messageTailPosition === left ? "100%" : "0"
                      } 80%)`,
                    }}
                  />
                  {message}
                  <MDTypography
                    variant="caption"
                    color="light"
                    position="absolute"
                    right={3}
                    bottom={3}
                  >
                    {time}
                  </MDTypography>
                  {messageTailPosition && (
                    <MDTypography
                      variant="overline"
                      color={type === customer ? "error" : "success"}
                      position="absolute"
                      left={8}
                      top={-1}
                    >
                      {type === customer ? customerNumber : agentId}
                    </MDTypography>
                  )}
                </MDTypography>
              </MDBox>
            );
          },
        )}
    </MDBox>
  );
}

export default ChatWindow;
