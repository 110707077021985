import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import CTLMeetingType from "application/components/CTLMeetingType";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
const groupAdmin = AuthenticationService.allowGroupAdmin();

const Attributes = {
  formSize: "lg",
  columns: 1,
  enableAddButton: false,
  settingsMode: true,
    modes: groupAdmin ? groupAdminMenu.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
   }): [],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/conferenceSettings/listAll",
  register: "conferenceSettings/create",
};

const getColumns = () => {
  return [
    {
      Header: "Conference Mode",
      accessor: "conferenceType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "a", description: "audio" },
          { id: "v", description: "video" },
        ],
      },
      validation: formControl.string().required("Conference Mode Is Required"),
      Cell: ({ value }) => <CTLMeetingType value={value} />,
    },
    {
      Header: "Conference type",
      accessor: "meetingType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { description: "Dial Assisted" },
          { description: "Meet Me Conference" },
        ],
      },
      validation: formControl.string().required("Conference Type Is Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Maximum Participant Allowed",
      accessor: "maxParticipants",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().required('Enter Only Digit Value').digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Beep Intervel",
      accessor: "beepInterval",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().required('Enter Only Digit Value').digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Retry in Numbers",
      accessor: "retryCount",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().required('Enter Only Digit Value').digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: " Retry After(MS)",
      accessor: "retryDelay",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().required('Enter Only Digit Value').digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "AuthenticationRetry",
      accessor: "authenticationRetry",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().required('Enter Only Digit Value').digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Required Permission To Talk",
      accessor: "rptt",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Voice Reconization",
      accessor: "voiceRecognition",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefauploadultCell value={value} />,
    },
    {
      Header: "Password Required",
      accessor: "passwordRequired",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Name Announcement Required",
      accessor: "nameAnnouncementRequired",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "recording",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Conference Greetings File",
      accessor: "coralConfGreetingsContent",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      type: "file",
      controlType: "upload",
      width: 8,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
