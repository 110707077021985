import React from "react";
import MapComponent from "./MapComponent";
import HistoryPathMap from "./components/HistoryPathMap";

const GeoFence = ({
  onFolderAdd,
  currentFolder,
  setCurrentFolder,
  defaultLocation,
  polygonList,
  setPolygonList,
  folders,
  setFolders,
  folderPath,
  handleFolderClick,
  areaList,
  routeList,
}) => {
  const handleSavePolygon = async (polygons) => {
    const blob = new Blob([JSON.stringify(polygons, null, 2)], {
      type: "application/json",
    });

    saveAs(blob, "geofences.json");

    try {
      const response = await fetch("http://localhost:3000/polygons", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(polygons),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("Polygons saved:", data);
    } catch (error) {
      console.error("Error saving polygons:", error);
    }
  };

  // Check if we should show history view
  const isHistoryView = currentFolder?.type === 'user' || currentFolder?.type === 'group';

  return (
    <>
      {isHistoryView ? (
        <HistoryPathMap 
          defaultLocation={defaultLocation}
          folderPath={folderPath}
          handleFolderClick={handleFolderClick}
        />
      ) : (
        <MapComponent
          onSavePolygon={handleSavePolygon}
          defaultLocation={defaultLocation}
          onFolderAdd={onFolderAdd}
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          polygonList={polygonList}
          setPolygonList={setPolygonList}
          folders={folders}
          setFolders={setFolders}
          folderPath={folderPath}
          handleFolderClick={handleFolderClick}
          areaList={areaList}
          routeList={routeList}
        />
      )}
    </>
  );
};

export default GeoFence;
