import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import EmptyImage from "../../../../assets/images/mediacenter/emptyFolder.svg";
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography';

function EmptyContainer({handleNewData}) {
    return (
        <Container sx={{width:'100vw'}} >
            <Box  display="flex" flexDirection='column' justifyContent="center" alignItems="center" sx={{height:'90vh'}}>
                <Box  display="flex" flexDirection='column' justifyContent="center" alignItems="center" sx={{width:'100%', }}>
                    <img src={EmptyImage} alt='Empty Folder' />
                </Box>
                <Box display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                    <MDTypography variant='h5' fontFamily={600}>No Media Found</MDTypography>
                    <MDTypography variant='p'mt={3} >Looks like the Media library is currently on mute! Add new Media by clicking the button below.</MDTypography>
                    <MDButton variant='gradient' color="info" sx={{marginTop:'20px'}} onClick={handleNewData}>New Folder</MDButton>
                </Box>
            </Box>
        </Container>
    )
}

export default EmptyContainer