import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import BedIcon from "@mui/icons-material/Bed";
import colors from "assets/theme-dark/base/colors";
import { Phone } from "@mui/icons-material";

const iconstyles = {
  color: colors.info.main,
  fontSize: "1.4em",
};

export const BuildingIcon = () => <AccountBalanceIcon sx={{ ...iconstyles, }} />;
export const FloorIcon = () => <ViewDayIcon sx={{ ...iconstyles,  color:colors.primary.main }} />;
export const RoomIcon = () => <BedIcon sx={{ ...iconstyles, color:colors.success.main }} />;
export const PhoneIcon = () => <Phone sx={{ ...iconstyles, color:colors.info.main }} />;
