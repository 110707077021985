import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { taskManagementSystem } from "globals/configuration/taskManagementSystem";
import formControl from "globals/form-control";
import { useEffect, useState } from "react";
import ViewTaskDetals from "../../taskManager/taskViewDetals";
import { useMaterialUIController } from "context";
import { setTaskIsDelay } from "context";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import RowColorCell from "application/components/RowColorCell";
import PlayTaskFile from "../../filePlay";
import DownloadTaskFile from "../../downloadFile";

const Attributes = {
  formSize: "lg",
  enableAddButton: false,
  enableRefresh: true,
  settingsMode: false,
  enableAdvanceSearch: true,
  modes: taskManagementSystem.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  create: "task/create",
  update: "task/update",
  list: "task/list",
  delete: "task/delete",
};

const getColumns = (onDelete, onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, taskIsDelay } = controller;

  const onChangeIsDelay = (event, formik) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setTaskIsDelay(dispatch, isChecked);
    } else {
      setTaskIsDelay(dispatch, false);
    }
  };
  return [
    // {
    //   Header: "Status Indicator",
    //   accessor: "",
    //   displayInList: true,
    //   width: 6,
    //   Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    //   isUserGenerated: true,
    // },
    {
      Header: "Task Name",
      accessor: "tasksName",
      displayInList: true,
      allowInForm: true,
      disabled: true,
      allowFilter: true,
      disabled: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Current Status",
      accessor: "currentStats",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      filterControlType: "select",
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      options: {
        keyField: "taskStatus",
        keyDescription: "taskStatus",
        values: [],
        url: "/taskStatus/listAll",
      },
    },
    {
      Header: "Start Date",
      accessor: "assignedOn",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      controlType: "dateTime",
      dateTime: true,
      disabled: true,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Due date",
      accessor: "expectedCompletionDate",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      controlType: "dateTime",
      disabled: true,
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Task Complete Date",
      accessor: "taskCompletedOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      controlType: "futureDateTime",
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Task Priority",
      accessor: "taskPriority",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      disabled: true,
      dataType: "string",
      width: 6,
      controlType: "",
      options: {
        keyField: "taskPriority",
        keyDescription: "taskPriority",
        values: [],
        url: "/taskPriority/listAll",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Task Type",
      accessor: "taskType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      filterControlType: "select",
      dataType: "string",
      width: 6,
      disabled: true,
      options: {
        keyField: "taskType",
        keyDescription: "taskType",
        values: [],
        url: "/taskType/listAll",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Task Reviewer",
      accessor: "reviewer",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      filterControlType: "select",
      dataType: "string",
      width: 6,
      controlType: "select",
      options: {
        keyField: "userName",
        keyDescription: "description",
        values: [],
        url: "/queueTL/listTL",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IsDelay",
      accessor: "isDelay",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      onChange: onChangeIsDelay,
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Change Due Date",
      accessor: "changedCompletionOn",
      displayInList: true,
      dateTime: true,
      allowInForm: taskIsDelay ? true : false,
      allowFilter: true,
      dataType: "date",
      isEndTime: true,
      controlType: "dateTime",
      width: 6,
      validation: taskIsDelay
        ? formControl.string().required("Required").nullable()
        : "",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "taskDescription",
      disabled: true,
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "textField",
      width: 12,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Remark",
      accessor: "remarks",
      displayInList: true,
      allowInForm: taskIsDelay ? true : false,
      allowFilter: false,
      controlType: "textField",
      width: 12,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "attached file",
      accessor: "filePath",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "fileUpload",
      sendFileInFormData: true,
      fileTypeAccept: ".mp3, .wav, .pdf, .txt, .csv",
      uploadUrl: "task/uploadFile",
      width: 6,
      isUserGenerated: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "View",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 12,
      Cell: ({ value, row }) => <ViewTaskDetals value={value} row={row} />,
    },
    {
      Header: "Play/&/Download",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      sortDisable: true,
      Cell: ({ row, value }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlayTaskFile row={row} value={value} />
          <DownloadTaskFile row={row} value={value} />
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          // onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
