import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { useState } from "react";

const UpdateAttributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  modes: [],
  settingsMode: false,
};

const getUpdateColumns = (onEdit) => {
  const [validation, setValidation] = useState("");
  const [groupField, setGroupField] = useState(false);

  const [formField, setFormField] = useState(false);
  const [required, setRequired] = useState(
    formControl.string().required("Required")
  );
  const checkselect = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "sip") {
      setValidation(formControl.string().ipv4());
    } else if (selectedValue === "pri") {
      setValidation(formControl.string().required("Required").digitsOnly());
    } else {
      setValidation("");
    }
  };

  const NewSelect = (e) => {
    const value = e.target.value;
    if (value === "customGroup") {
      setGroupField(true);
    } else {
      setGroupField(false);
    }
  };

  const customSelect = (e) => {
    const customValue = e.target.value;
    if (customValue === "customType") {
      setFormField(true);

      setRequired(formControl.string().required("Required"));
    } else if (customValue === "sip") {
      setFormField(false);

      setRequired(formControl.string().required("Required"));
    } else if (customValue === "pri") {
      setFormField(false);
    } else if (customValue === "internal") {
      setFormField(false);

      setRequired(formControl.string().required("Required"));
    } else if (customValue === "ngx1") {
      setFormField(false);

      setRequired(formControl.string().required("Required"));
    } else if (customValue === "rlu") {
      setFormField(false);

      setRequired(formControl.string().required("Required"));
    } else {
      setFormField(false);
    }
  };
  return [
    {
      Header: "Gateway Code",
      accessor: "gwCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Gateway Name",
      accessor: "gwName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Select Gateway Type",
      accessor: "gwType",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      onChange: customSelect,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "sip", description: "SIP" },
          { id: "pri", description: "PRI" },
          { id: "internal", description: "INTERNAL" },
          { id: "ngx1", description: "NGX1" },
          { id: "rlu", description: "RLU" },
          { id: "customType", description: "Custom" },
        ],
      },
    },
    {
      Header: "Custom Gateway Type",
      accessor: "gwTypeCustom",
      displayInList: false,
      allowInForm: formField,
      allowFilter: false,
      dataType: "string",
      validation: required,
      validation: formControl.string().nullable().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Range",
      accessor: "gwRange",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required").customFormat(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dial Plan List ",
      accessor: "dialPlan",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
      onChange: checkselect,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "sip", description: "SIP" },
          { id: "pri", description: "PRI" },
          { id: "custom", description: "CUSTOM" },
        ],
      },
    },
    {
      Header: "Enter IP(SIP)/Value(PRI)",
      accessor: "dialValue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: validation,
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Max Channel",
      accessor: "maxChannel",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Time Out (in sec)",
      accessor: "callTimeout",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Strip",
      accessor: "callerStrip",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().validatePlusSymbolsOnly(),
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Destination Strip",
      accessor: "numberStrip",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().validatePlusSymbolsOnly(),
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Group",
      accessor: "gwGroup",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      custom: "NewGroup",
      onChange: NewSelect,
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "gwGroup",
        keyDescription: "gwGroup",
        values: [],
        url: "/gateway/list/gwGroup",
      },
    },
    {
      Header: "Custom Group",
      accessor: "gwGroupCustom",
      displayInList: false,
      allowInForm: groupField,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Codec Provision",
      accessor: "codec",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "multipleSelectWithString",
      selectTypeValue: "string",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "PCMU", description: "PCMU" },
          { id: "PCMA", description: "PCMA" },
          { id: "G722", description: "G722" },
          { id: "G723", description: "G723" },
          { id: "G726", description: "G726" },
          { id: "H323", description: "H323" },
        ],
      },
    },
    {
      Header: "Pulse In Second)",
      accessor: "pulseInSecond",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Pulse Rate",
      accessor: "pulseRate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Minimum Duration",
      accessor: "minDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      validation: formControl.string().nullable().digitsOnly(),
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Enhanced Duration",
      accessor: "enhancedDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      validation: formControl.string().nullable().digitsOnly(),
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Beep Interval(in sec)",
      accessor: "beepInterval",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      validation: formControl.string().nullable().digitsOnly(),
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Efficiency Percentage",
      accessor: "efficiencyPer",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Fail Over Max try",
      accessor: "failoverMaxTry",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Fail Over Try",
      accessor: "failOverTry",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Priority",
      accessor: "priority",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Out Digit",
      accessor: "maxOutDigit",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Failover Number",
      accessor: "failOverNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Fail Over Timeout",
      accessor: "extFailoverTimeout",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CAC Bandwidth",
      accessor: "cacBandwidth",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Fail Over Gateway",
      accessor: "failOverGateway",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "multipleSelectWithString",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "gwCode",
        keyDescription: "gwCode",
        values: [],
        url: "/gateway/list/gwCode",
      },
    },
    {
      Header: "Fail Over ISDN Cause",
      accessor: "failoverIsDnCause",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "PreFix",
      accessor: "prefix",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tenant Code",
      accessor: "tenantCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLSelectCell value={value} columns={column} />,
      width: 4,
      options: {
        keyField: "code",
        keyDescription: "code",
        values: [],
        url: "/tenantMaster/list",
      },
    },
    {
      Header: "Gateway Controller Code",
      accessor: "gatewayControllerCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLSelectCell value={value} columns={column} />,
      width: 4,
      options: {
        keyField: "gatewayCode",
        keyDescription: "description",
        values: [],
        url: "/gatewayController/listAll",
      },
    },
    {
      Header: "Gateway Testing Number",
      accessor: "gatewayTestingNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Gateway Testing CLI",
      accessor: "gatewayTestingCli",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Failover Number",
      accessor: "failOverNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CLI Prefix",
      accessor: "cliPrefix",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Allow Extend Time",
      accessor: "allowExtendTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "boolean",
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "NMS Controlled CAC",
      accessor: "nmsCac",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "isRecording",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "QSIG",
      accessor: "qsig",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Peer To Peer",
      accessor: "peerToPeer",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Handle ISDN Cause",
      accessor: "handleIsDnCause",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Toll Free",
      accessor: "tollFree",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "DTMF Tone",
      accessor: "allowDTmfTone",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",

      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getUpdateColumns, UpdateAttributes };
