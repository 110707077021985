import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { userMenus } from "globals/configuration/user-management";
import formControl from "globals/form-control";
import RoleIdCell from "../roleId";
import UserIdCell from "../userId";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : userMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
          disabled: item.disabled,
          productionReady: item.productionReady,
        };
      }),
  settingsMode: false,
};

const operationURLS = {
  list: "userRole/list",
  create: "userRole/multiCreate",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "User ID",
      accessor: "userId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "multipleSelect",
      width: 6,
      Cell: ({ value }) => <UserIdCell value={value} />,
      // validation: formControl.string().required("Required"),
      options: {
        keyField: "id",
        keyDescription: "description",
        url: "user/dropdownList",
      },
    },
    {
      Header: "Role",
      accessor: "roleId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "multipleSelect",
      width: 6,
      Cell: ({ value }) => <RoleIdCell value={value} />,
      // validation: formControl.string().required("Required"),
      options: {
        keyField: "id",
        keyDescription: "description",
        url: "role/dropdownList",
      },
    },
  ];
};

export { getColumns, Attributes, operationURLS };
