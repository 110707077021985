const GISMarkersData = [
  {
    id: "001010123452007",
    tagType: "imsi",
    name: "null - 2007",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2007",
  },
  {
    id: "001010123452008",
    tagType: "imsi",
    name: "null - 2008",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2008",
  },
  {
    id: "001010123452009",
    tagType: "imsi",
    name: "null - 2009",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2009",
  },
  {
    id: "001010123452010",
    tagType: "imsi",
    name: "null - 2010",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2010",
  },
  {
    id: "001010123452011",
    tagType: "imsi",
    name: "null - 2011",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2011",
  },
  {
    id: "001010123452012",
    tagType: "imsi",
    name: "null - 2012",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2012",
  },
  {
    id: "001010123452013",
    tagType: "imsi",
    name: "null - 2013",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2013",
  },
  {
    id: "001010123452014",
    tagType: "imsi",
    name: "null - 2014",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2014",
  },
  {
    id: "001010123452015",
    tagType: "imsi",
    name: "null - 2015",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2015",
  },
  {
    id: "001010123452016",
    tagType: "imsi",
    name: "null - 2016",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2016",
  },
  {
    id: "001010123452017",
    tagType: "imsi",
    name: "null - 2017",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2017",
  },
  {
    id: "001010123452018",
    tagType: "imsi",
    name: "null - 2018",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2018",
  },
  {
    id: "001010123452019",
    tagType: "imsi",
    name: "null - 2019",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2019",
  },
  {
    id: "001010123452020",
    tagType: "imsi",
    name: "null - 2020",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2020",
  },
  {
    id: "001010123452021",
    tagType: "imsi",
    name: "null - 2021",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2021",
  },
  {
    id: "001010123452022",
    tagType: "imsi",
    name: "null - 2022",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2022",
  },
  {
    id: "001010123452023",
    tagType: "imsi",
    name: "null - 2023",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2023",
  },
  {
    id: "001010123452024",
    tagType: "imsi",
    name: "null - 2024",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2024",
  },
  {
    id: "001010123452025",
    tagType: "imsi",
    name: "null - 2025",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2025",
  },
  {
    id: "001010123452026",
    tagType: "imsi",
    name: "null - 2026",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2026",
  },
  {
    id: "001010123452027",
    tagType: "imsi",
    name: "null - 2027",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2027",
  },
  {
    id: "001010123452028",
    tagType: "imsi",
    name: "null - 2028",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2028",
  },
  {
    id: "001010123452029",
    tagType: "imsi",
    name: "null - 2029",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2029",
  },
  {
    id: "001010123452030",
    tagType: "imsi",
    name: "null - 2030",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2030",
  },
  {
    id: "001010123452031",
    tagType: "imsi",
    name: "null - 2031",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2031",
  },
  {
    id: "001010123452032",
    tagType: "imsi",
    name: "null - 2032",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2032",
  },
  {
    id: "001010123452033",
    tagType: "imsi",
    name: "null - 2033",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2033",
  },
  {
    id: "001010123452034",
    tagType: "imsi",
    name: "null - 2034",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2034",
  },
  {
    id: "001010123452035",
    tagType: "imsi",
    name: "null - 2035",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2035",
  },
  {
    id: "001010123452036",
    tagType: "imsi",
    name: "null - 2036",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2036",
  },
  {
    id: "001010123452037",
    tagType: "imsi",
    name: "null - 2037",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2037",
  },
  {
    id: "001010123452038",
    tagType: "imsi",
    name: "null - 2038",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2038",
  },
  {
    id: "001010123452039",
    tagType: "imsi",
    name: "null - 2039",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2039",
  },
  {
    id: "001010123452040",
    tagType: "imsi",
    name: "null - 2040",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2040",
  },
  {
    id: "001010123452041",
    tagType: "imsi",
    name: "null - 2041",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2041",
  },
  {
    id: "001010123452042",
    tagType: "imsi",
    name: "null - 2042",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2042",
  },
  {
    id: "001010123452043",
    tagType: "imsi",
    name: "null - 2043",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2043",
  },
  {
    id: "001010123452044",
    tagType: "imsi",
    name: "null - 2044",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2044",
  },
  {
    id: "001010123452045",
    tagType: "imsi",
    name: "null - 2045",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2045",
  },
  {
    id: "001010123452046",
    tagType: "imsi",
    name: "null - 2046",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2046",
  },
  {
    id: "001010123452047",
    tagType: "imsi",
    name: "null - 2047",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2047",
  },
  {
    id: "001010123452048",
    tagType: "imsi",
    name: "null - 2048",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2048",
  },
  {
    id: "001010123452049",
    tagType: "imsi",
    name: "null - 2049",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2049",
  },
  {
    id: "001010123452050",
    tagType: "imsi",
    name: "null - 2050",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2050",
  },
  {
    id: "001010123452051",
    tagType: "imsi",
    name: "null - 2051",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2051",
  },
  {
    id: "001010123452052",
    tagType: "imsi",
    name: "null - 2052",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2052",
  },
  {
    id: "001010123452053",
    tagType: "imsi",
    name: "null - 2053",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2053",
  },
  {
    id: "001010123452054",
    tagType: "imsi",
    name: "null - 2054",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2054",
  },
  {
    id: "001010123452055",
    tagType: "imsi",
    name: "null - 2055",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2055",
  },
  {
    id: "001010123452056",
    tagType: "imsi",
    name: "null - 2056",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2056",
  },
  {
    id: "001010123452057",
    tagType: "imsi",
    name: "null - 2057",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2057",
  },
  {
    id: "001010123452058",
    tagType: "imsi",
    name: "null - 2058",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2058",
  },
  {
    id: "001010123452059",
    tagType: "imsi",
    name: "null - 2059",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2059",
  },
  {
    id: "001010123452060",
    tagType: "imsi",
    name: "null - 2060",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2060",
  },
  {
    id: "001010123452061",
    tagType: "imsi",
    name: "null - 2061",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2061",
  },
  {
    id: "001010123452062",
    tagType: "imsi",
    name: "null - 2062",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2062",
  },
  {
    id: "001010123452063",
    tagType: "imsi",
    name: "null - 2063",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2063",
  },
  {
    id: "001010123452064",
    tagType: "imsi",
    name: "null - 2064",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2064",
  },
  {
    id: "001010123452065",
    tagType: "imsi",
    name: "null - 2065",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2065",
  },
  {
    id: "001010123452066",
    tagType: "imsi",
    name: "null - 2066",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2066",
  },
  {
    id: "001010123452067",
    tagType: "imsi",
    name: "null - 2067",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2067",
  },
  {
    id: "001010123452068",
    tagType: "imsi",
    name: "null - 2068",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2068",
  },
  {
    id: "001010123452069",
    tagType: "imsi",
    name: "null - 2069",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2069",
  },
  {
    id: "001010123452070",
    tagType: "imsi",
    name: "null - 2070",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2070",
  },
  {
    id: "001010123452071",
    tagType: "imsi",
    name: "null - 2071",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2071",
  },
  {
    id: "001010123452072",
    tagType: "imsi",
    name: "null - 2072",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2072",
  },
  {
    id: "001010123452073",
    tagType: "imsi",
    name: "null - 2073",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2073",
  },
  {
    id: "001010123452074",
    tagType: "imsi",
    name: "null - 2074",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2074",
  },
  {
    id: "001010123452075",
    tagType: "imsi",
    name: "null - 2075",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2075",
  },
  {
    id: "001010123452076",
    tagType: "imsi",
    name: "null - 2076",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2076",
  },
  {
    id: "001010123452077",
    tagType: "imsi",
    name: "null - 2077",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2077",
  },
  {
    id: "001010123452078",
    tagType: "imsi",
    name: "null - 2078",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2078",
  },
  {
    id: "001010123452079",
    tagType: "imsi",
    name: "null - 2079",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2079",
  },
  {
    id: "001010123452080",
    tagType: "imsi",
    name: "null - 2080",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2080",
  },
  {
    id: "001010123452081",
    tagType: "imsi",
    name: "null - 2081",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2081",
  },
  {
    id: "001010123452082",
    tagType: "imsi",
    name: "null - 2082",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2082",
  },
  {
    id: "001010123452083",
    tagType: "imsi",
    name: "null - 2083",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2083",
  },
  {
    id: "001010123452084",
    tagType: "imsi",
    name: "null - 2084",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2084",
  },
  {
    id: "001010123452085",
    tagType: "imsi",
    name: "null - 2085",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2085",
  },
  {
    id: "001010123452086",
    tagType: "imsi",
    name: "null - 2086",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2086",
  },
  {
    id: "001010123452087",
    tagType: "imsi",
    name: "null - 2087",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2087",
  },
  {
    id: "001010123452088",
    tagType: "imsi",
    name: "null - 2088",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2088",
  },
  {
    id: "001010123452089",
    tagType: "imsi",
    name: "null - 2089",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2089",
  },
  {
    id: "001010123452090",
    tagType: "imsi",
    name: "null - 2090",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2090",
  },
  {
    id: "001010123452091",
    tagType: "imsi",
    name: "null - 2091",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2091",
  },
  {
    id: "001010123452092",
    tagType: "imsi",
    name: "null - 2092",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2092",
  },
  {
    id: "001010123452093",
    tagType: "imsi",
    name: "null - 2093",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2093",
  },
  {
    id: "001010123452094",
    tagType: "imsi",
    name: "null - 2094",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2094",
  },
  {
    id: "001010123452095",
    tagType: "imsi",
    name: "null - 2095",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2095",
  },
  {
    id: "001010123452096",
    tagType: "imsi",
    name: "null - 2096",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2096",
  },
  {
    id: "001010123452097",
    tagType: "imsi",
    name: "null - 2097",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2097",
  },
  {
    id: "001010123452098",
    tagType: "imsi",
    name: "null - 2098",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2098",
  },
  {
    id: "001010123452099",
    tagType: "imsi",
    name: "null - 2099",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2099",
  },
  {
    id: "001010123452100",
    tagType: "imsi",
    name: "null - 2100",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2100",
  },
  {
    id: "001010123452001",
    tagType: "imsi",
    name: "2001 - 2001",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2001",
  },
  {
    id: "001010123452002",
    tagType: "imsi",
    name: "null - 2002",
    geoX: "28.61855",
    geoY: "77.38588",
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2002",
  },
  {
    id: "001010123452003",
    tagType: "imsi",
    name: "null - 2003",
    geoX: "28.61861",
    geoY: "77.38658",
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2003",
  },
  {
    id: "001010123452004",
    tagType: "imsi",
    name: "null - 2004",
    geoX: "28.61866",
    geoY: "77.38758",
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2004",
  },
  {
    id: "001010123452005",
    tagType: "imsi",
    name: "null - 2005",
    geoX: "28.61871",
    geoY: "77.38858",
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "2005",
  },
  {
    id: "001010123456917",
    tagType: "imsi",
    name: "null - 1004",
    geoX: "1233333",
    geoY: "1223333",
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "1004",
  },
  {
    id: "001010123456919",
    tagType: "imsi",
    name: "null - 1006",
    geoX: null,
    geoY: null,
    status: true,
    data: null,
    emergencyStatus: null,
    extensionNumber: "1006",
  },
  {
    id: "001010123452006",
    tagType: "imsi",
    name: "null - 2006",
    geoX: "28.61971",
    geoY: "77.38958",
    status: true,
    data: null,
    emergencyStatus: "",
    extensionNumber: "2006",
  },
  {
    id: "1111",
    tagType: "imsi",
    name: " - 111",
    geoX: "2.50",
    geoY: "1.23",
    status: null,
    data: null,
    emergencyStatus: "",
    extensionNumber: "111",
  },
  {
    id: "451455522",
    tagType: "imsi",
    name: "Pawan Extension - 7876",
    geoX: "",
    geoY: "",
    status: true,
    data: null,
    emergencyStatus: "",
    extensionNumber: "7876",
  },
];

export default GISMarkersData;
