import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import React, { useEffect, useState } from "react";
import CTLNotification from "../CTLNotification";
import CTLDialogContent from "../styles/CTLDialogContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import CTLBaseDialogForm from "../CTLBaseDialogForm";
import { getColumns, Attributes } from "./model";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { axiosInstance } from "../CTLAxiosInstance";
import loggerFactory from "globals/logger/logger-factory";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { PersonOutlineRounded, PersonOutlined } from "@material-ui/icons";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(22px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const operationURL = {
  groupListAll: "/userGroup/listAll",
  groupListLocal: "/userGroup/list/local",
};

const initialValue = {
  id: 0,
  roomName: "",
  didMapping: "",
  meetingScheduledDate: "",
  scheduledToDate: "",
  fromTime: "",
  toTime: "",
  detail: "",
  participantList: [],
  externalParticipant: [],
  conferenceType: "",
  maxParticipants: 0,
  beepInterval: 0,
  retryCount: 0,
  retryDelay: 0,
  rptt: false,
  voiceRecognition: false,
  passwordRequired: false,
  nameAnnouncementRequired: false,
  recording: false,
  createdBy: "",
  createdOn: "",
  meetingType: "",
  conferenceMode: "I",
};
function CTLConferenceForm({
  open,
  setOpen,
  operationURLS,
  selectedStartDate,
  selectedEndDate,
  createConference,
  apiFormData,
  setApiFormData,
  schedule,
  setSchedule,
  filterConferencesGroupList,
}) {
  const [loading, setLoading] = useState(false);
  const [maxWidth, setMaxWidth] = useState("lg");
  const [isShow, setIsShow] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [conferenceSettingData, setConferenceSettingData] = useState();
  const [formData, setFormData] = useState(initialValue);
  const [formDataExternal, setFormDataExternal] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [conferenceNumberList, setConferenceNumberList] = useState();
  const [guest, setGuest] = useState([]);
  const [meetingNameError, setMeetingNameError] = useState(false);
  const [meetingLocationError, setMeetingLocationError] = useState(false);
  const [meetingParticipantError, setMeetingParticipantError] = useState(false);
  const [confMode, setConfMode] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });



  useEffect(() => {
    const fetchConferenceSettingData = async () => {
      try {
        const response = await application.post(operationURLS.settingList);
        const dataToDisplay = response.data?.data[0];
        setConferenceSettingData(dataToDisplay);
      } catch (error) {
        loggerFactory.debug(
          "Failed to load conference advance settings data-",
          error
        );
      }
    };

    const fetchConferenceLocationData = async () => {
      try {
        const response = await application.post(
          operationURLS.conferenceNumberList
        );
        const confNumber = response.data?.data;
        setConferenceNumberList(confNumber);
      } catch (error) {
        loggerFactory.debug(
          "Failed to load conference conference Location Number data-",
          error
        );
      }
    };
    fetchConferenceSettingData();
    fetchConferenceLocationData();
  }, [selectedStartDate, apiFormData]);

useEffect(() => {
  const fetchUserListData = async () => {
    setLoading(true);
    try {
      let response;
      if (window.conferenceMode) {
        if (confMode) {
          response = await application.post(operationURL.groupListAll);
        } else {
          response = await application.post(`${operationURL.groupListLocal}`);
        }
      } else {
        response = await application.post(operationURL.groupListAll);
      }

      const fetchedData = {
        group: response.data.data.group || [],
        userList: response.data.data.userList || [],
      };

      if (confMode) {
        const filterGroup = filterConferencesGroupList();
        setGuest({
          group: [...fetchedData.group, ...filterGroup],
          userList: fetchedData.userList,
        });
      } else {
        setGuest(fetchedData);
      }
    } catch (error) {
      loggerFactory.debug(`Error fetching user list`, error);
      setGuest({ group: [], userList: [] });
    } finally {
      setLoading(false);
    }
  };

  fetchUserListData();
}, [selectedStartDate, apiFormData, confMode]);

  useEffect(() => {
    if (apiFormData) {
      if (schedule) {
        setFormData(() => ({
          modId: apiFormData.modId,
          meetingScheduledDate: apiFormData.meetingScheduledDate,
          scheduledToDate: apiFormData.scheduledToDate,
          fromTime: apiFormData.fromTime,
          toTime: apiFormData.toTime,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          id: apiFormData.id,
          roomName: apiFormData.roomName,
          detail: apiFormData.detail,
          didMapping: apiFormData.didMapping,
          meetingScheduledDate: apiFormData.meetingScheduledDate,
          scheduledToDate: apiFormData.scheduledToDate,
          fromTime: apiFormData.fromTime,
          toTime: apiFormData.toTime,
          participantList: apiFormData.participantList,
          externalParticipant: apiFormData.externalParticipant,
          conferenceType: apiFormData.conferenceType,
          beepInterval: apiFormData.beepInterval,
          maxParticipants: apiFormData.maxParticipants,
          retryCount: apiFormData.retryCount,
          retryDelay: apiFormData.retryDelay,
          rptt: apiFormData.rptt,
          voiceRecognition: apiFormData.voiceRecognition,
          passwordRequired: apiFormData.passwordRequired,
          nameAnnouncementRequired: apiFormData.nameAnnouncementRequired,
          recording: apiFormData.recording,
          meetingType: apiFormData.meetingType,
          createdBy: apiFormData.createdBy,
          createdOn: apiFormData.createdOn,
          coralConfGreetingsPath: apiFormData.coralConfGreetingsPath,
          participantName: apiFormData.participantName,
          emailId: apiFormData.emailId,
          mobileNumber: apiFormData.mobileNumber,
          extensions: apiFormData.mobileNumber,
          accessCode: apiFormData.accessCode,
          status: apiFormData.status,
          modId: apiFormData.modId,
          pid: apiFormData.pid,
          conferenceMode: apiFormData.conferenceMode,
        }));
      }
    } else if (conferenceSettingData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: 0,
        conferenceType: conferenceSettingData.conferenceType,
        beepInterval: conferenceSettingData.beepInterval,
        maxParticipants: conferenceSettingData.maxParticipants,
        retryCount: conferenceSettingData.retryCount,
        retryDelay: conferenceSettingData.retryDelay,
        rptt: conferenceSettingData.rptt,
        voiceRecognition: conferenceSettingData.voiceRecognition,
        passwordRequired: conferenceSettingData.passwordRequired,
        nameAnnouncementRequired:
          conferenceSettingData.nameAnnouncementRequired,
        recording: conferenceSettingData.recording,
        meetingType: conferenceSettingData.meetingType,
        createdBy: conferenceSettingData.createdBy,
        createdOn: conferenceSettingData.createdOn,
      }));
    }
  }, [selectedStartDate, apiFormData]);

  useEffect(() => {
    if (apiFormData) {
      setConfMode(apiFormData.conferenceMode == "I" ? false : true);
    }
  }, [apiFormData]);

  useEffect(() => {
    if (selectedStartDate && selectedStartDate !== "") {
      var originalStartDate = new Date(selectedStartDate);
      var toTime;

      if (selectedEndDate && selectedEndDate !== null) {
        var originalEndDate = new Date(selectedEndDate);
        toTime = originalEndDate;
      } else {
        toTime = new Date(originalStartDate.getTime() + 30 * 60000);
      }
      var formattedDate = originalStartDate.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      var formattedFromTime = originalStartDate.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      var formattedToTime = toTime.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        meetingScheduledDate: formattedDate,
        scheduledToDate: formattedDate,
        fromTime: formattedFromTime,
        toTime: formattedToTime,
      }));
    }
  }, [selectedStartDate, selectedEndDate]);

const handleSelectAll = (value) => {
  const filterGroup = filterConferencesGroupList();
  const selectedGroup = filterGroup.find((group) => group.groupName === value);
  if (selectedGroup) {
    const newParticipants = selectedGroup.userList;
    setFormData((prevFormData) => {
      const currentParticipants = prevFormData.participantList || [];
      const participantMap = new Map();
      currentParticipants.forEach((user) => participantMap.set(user.username, user));
      newParticipants.forEach((user) => participantMap.set(user.username, user));
      const combinedParticipants = Array.from(participantMap.values());
      return {
        ...prevFormData,
        participantList: combinedParticipants,
      };
    });
  }
};

  const handleChange = (fieldName, value) => {
    if (fieldName === "participantList") {
      setFormData((prevFormData) => {
        let participantList = value.map((user) => ({
          id: user.id,
          memberType: user.memberType || user.userType,
          username: user.username,
          extension: user.extension,
        }));
        return {
          ...prevFormData,
          participantList: participantList,
        };
      });
    } else if (fieldName == "meetingScheduledDate") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]:
          value !== undefined && value !== null
            ? value
            : prevFormData[fieldName],
        ["scheduledToDate"]:
          value !== undefined && value !== null
            ? value
            : prevFormData["scheduledToDate"],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]:
          value !== undefined && value !== null
            ? value
            : prevFormData[fieldName],
      }));
    }
  };

  const handleClose = () => {
    setIsShow(false);
    setOpen(false);
    setApiFormData();
    setFormData(initialValue);
    setSchedule(false);
    setMeetingLocationError(false);
    setMeetingNameError(false);
    setMeetingParticipantError(false);
    setConfMode(false);
  };

  const handleSave = () => {
    const scheduledFromTime = new Date(`2000-01-01T${formData.fromTime}`);
    const scheduledToTime = new Date(`2000-01-01T${formData.toTime}`);
    if (schedule) {
      if (formData.meetingScheduledDate === formData.scheduledToDate) {
        if (scheduledToTime <= scheduledFromTime) {
          setNotify({
            isOpen: true,
            message: "",
            type: "warning",
            pagename: "Conference",
            status:
              "Conference To Time must be greater than Conference From Time",
          });
        } else {
          createConference(formData);
          setOpen(false);
          setSchedule(false);
          setApiFormData();
          setFormData(initialValue);
        }
      } else {
        createConference(formData);
        setOpen(false);
        setSchedule(false);
        setApiFormData();
        setFormData(initialValue);
      }
    } else {
      if (formData.roomName.trim() === "") {
        setMeetingNameError(true);
        return;
      } else if (formData.didMapping.trim() === "") {
        setMeetingNameError(false);
        setMeetingLocationError(true);
        return;
      } else if (formData.participantList.length === 0) {
        setMeetingLocationError(false);
        setMeetingNameError(false);
        setMeetingParticipantError(true);
      } else {
        setMeetingLocationError(false);
        setMeetingNameError(false);
        setMeetingParticipantError(false);

        if (formData.meetingScheduledDate === formData.scheduledToDate) {
          if (scheduledToTime <= scheduledFromTime) {
            setNotify({
              isOpen: true,
              message: "",
              type: "warning",
              pagename: "Conference",
              status:
                "Conference To Time must be greater than Conference From Time",
            });
          } else {
            createConference(formData);
            setOpen(false);
            setSchedule(false);
            setApiFormData();
            setFormData(initialValue);
          }
        } else {
          createConference(formData);
          setOpen(false);
          setSchedule(false);
          setApiFormData();
          setFormData(initialValue);
        }
      }
    }
  };

  const handleChangeMode = (e) => {
    setConfMode(e.target.checked);
    setLoading(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ["conferenceMode"]: e.target.checked ? "C" : "I",
    }));
  };

  const handleUserCheckboxChange = (user, isChecked) => {
    let participantList = [...formData.participantList];

    if (
      isChecked &&
      !participantList.some(
        (participant) => participant.extension === user.extension
      )
    ) {
      participantList.push({
        id: user.id,
        memberType: user.userType,
        username: user.username,
        extension: user.extension,
      });
    } else if (!isChecked) {
      participantList = participantList.filter(
        (participant) => participant.extension !== user.extension
      );
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      participantList: participantList,
    }));
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCurrentToDate = () => {
    const now = new Date(formData.meetingScheduledDate);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const scheduledToDate = new Date(formData.scheduledToDate);
  const scheduledFromDate = new Date(formData.meetingScheduledDate);
  const scheduledFromTimeParts = formData.fromTime.split(":");
  const scheduledToTimeParts = formData.toTime.split(":");
  scheduledFromDate.setHours(parseInt(scheduledFromTimeParts[0], 10));
  scheduledFromDate.setMinutes(parseInt(scheduledFromTimeParts[1], 10));
  scheduledToDate.setHours(parseInt(scheduledToTimeParts[0], 10));
  scheduledToDate.setMinutes(parseInt(scheduledToTimeParts[1], 10));

  const timeDifferenceMs = scheduledToDate - scheduledFromDate;
  let timeDifferenceDays = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  const remainingMs = timeDifferenceMs % (1000 * 60 * 60 * 24);
  const timeDifferenceHours = Math.floor(remainingMs / (1000 * 60 * 60));
  const remainingMsAfterHours = remainingMs % (1000 * 60 * 60);
  const timeDifferenceMinutes = Math.floor(remainingMsAfterHours / (1000 * 60));
  let timeDifferenceStr = "";
  if (timeDifferenceDays > 0) {
    timeDifferenceStr += `${timeDifferenceDays} d `;
  }
  if (timeDifferenceHours > 0) {
    timeDifferenceStr += `${timeDifferenceHours} h `;
  }
  if (timeDifferenceMinutes > 0) {
    timeDifferenceStr += `${timeDifferenceMinutes} m`;
  }

  const handleAddParticipant = () => {
    setOpenForm(true);
    setFormDataExternal({
      eventTime: Date.now(),
    });
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function handleParticipantDelete(participant) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      externalParticipant: prevFormData.externalParticipant.filter(
        (item) => item.extension !== participant.extension
      ),
    }));
  }

  function onSaveForm(data) {
    setOpenForm(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      externalParticipant: [
        ...(prevFormData.externalParticipant || []),
        {
          memberType: "O",
          extension: data.extensions,
          username: data.participantName,
        },
      ],
    }));
  }

  const groupUserOption = (guest?.group || []).map((group) => ({
    ...group,
    title: group.groupName,
    type: "group",
    users: group.userList.map((user) => ({
      ...user,
      groupName: group.groupName,
      type: "user",
    })),
  }));

  const groupOptions = groupUserOption.flatMap((group) => group.users);

  const userOptions = (guest?.userList || []).map((user) => ({
    ...user,
    type: "user",
  }));

  const groupedOptions = [
    {
      title: "Group List",
      options: groupOptions,
    },
    {
      title: "User List",
      options: userOptions,
    },
  ];

  const renderOption = (props, option) => {
    if (option.type === "group") {
      return (
        <ListItem {...props} key={option.groupName} disablePadding>
          <ListItemText primary={option.groupName} />
        </ListItem>
      );
    } else {
      return (
        <ListItem {...props} key={option.id} disablePadding>
          <ListItemIcon>
            <Checkbox
              icon={<PersonOutlined />}
              checkedIcon={<PersonOutlined />}
              checked={formData?.participantList.some(
                (u) => u.extension === option.extension
              )}
              onChange={(e) =>
                handleUserCheckboxChange(option, e.target.checked)
              }
            />
          </ListItemIcon>
          <ListItemText primary={getOptionLabel(option)} />
        </ListItem>
      );
    }
  };

  const getOptionLabel = (option) => {
    if (option.extension) {
      if (option.username !== null && option.username.trim() !== "") {
        return `${option.extension} - ${option.username}`;
      } else {
        return option.extension;
      }
    } else if (option.groupName) {
      return option.groupName;
    } else {
      return "";
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        maxWidth={maxWidth}
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {schedule
            ? `Re-Schedule Conference - ${apiFormData.roomName}`
            : "Create New Meeting"}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {loading && (
            <Backdrop
              open={loading}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ m: 2 }}>
                Data loading...
              </Typography>
            </Backdrop>
          )}
          <Grid sx={{ p: 1 }} container spacing={2} direction="row">
            {schedule ? (
              <>
                {" "}
                <Grid item xs={3}>
                  <TextField
                    label="Meeting From Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    fullWidth
                    variant="standard"
                    value={formData.meetingScheduledDate}
                    onChange={(event) =>
                      handleChange("meetingScheduledDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentDate(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="From Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.fromTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("fromTime", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Meeting To Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="standard"
                    fullWidth
                    value={formData.scheduledToDate}
                    onChange={(event) =>
                      handleChange("scheduledToDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentToDate(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="To Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.toTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("toTime", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="  "
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={timeDifferenceStr}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                {AuthenticationService.allowConferenceMenus() &&
                  window.conferenceMode && (
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControl>
                        <Stack
                          padding="5px"
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MDTypography variant="h6">
                            {" "}
                            {window.conferenceMode1
                              ? window.conferenceMode1
                              : "Local Mode"}
                          </MDTypography>
                          &nbsp;&nbsp;
                          <AntSwitch
                            checked={confMode}
                            onChange={(e) => handleChangeMode(e)}
                            inputProps={{ "aria-label": "ant design" }}
                          />
                          <MDTypography variant="h6">
                            {" "}
                            {window.conferenceMode2
                              ? window.conferenceMode2
                              : "Public Mode"}
                          </MDTypography>
                        </Stack>
                      </FormControl>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <TextField
                    label="Meeting Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formData.roomName}
                    onChange={(event) =>
                      handleChange("roomName", event.target.value)
                    }
                    required
                    error={meetingNameError}
                    helperText={meetingNameError ? "required" : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Meeting From Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    fullWidth
                    variant="standard"
                    value={formData.meetingScheduledDate}
                    onChange={(event) =>
                      handleChange("meetingScheduledDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentDate(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="From Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.fromTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("fromTime", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentTime(),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Meeting To Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="standard"
                    fullWidth
                    value={formData.scheduledToDate}
                    onChange={(event) =>
                      handleChange("scheduledToDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentToDate(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="To Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.toTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("toTime", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentTime(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="  "
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={timeDifferenceStr}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Add Location *</InputLabel>
                    <Select
                      style={{ height: "55px" }}
                      label="Add Location*"
                      value={formData.didMapping}
                      onChange={(event) =>
                        handleChange("didMapping", event.target.value)
                      }
                      fullWidth
                      MenuProps={MenuProps}
                      required
                      error={meetingLocationError}
                      helperText={meetingLocationError ? "required" : ""}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {conferenceNumberList &&
                        conferenceNumberList.map((item, index) => (
                          <MenuItem key={index} value={item.extension}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={groupedOptions.flatMap((group) => group.options)}
                      groupBy={(option) =>
                        option.type === "group"
                          ? "Group List"
                          : option.type === "user" && option.groupName
                          ? option.groupName
                          : "User List"
                      }
                      getOptionLabel={(option) => getOptionLabel(option)}
                      value={formData?.participantList || []}
                      onChange={(event, newValue) => {
                        handleChange("participantList", newValue || []);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.extension === value.extension
                      }
                      renderOption={renderOption}
                      renderGroup={(params) => [
                        <ListSubheader
                          key={params.key}
                          disableSticky
                          sx={{ color: "blue", cursor: "pointer" }}
                          onClick={() => handleSelectAll(params.group)}
                        >
                          {params.group.toUpperCase()}
                        </ListSubheader>,
                        params.children,
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Add participants *"
                          error={meetingParticipantError}
                          helperText={meetingParticipantError ? "required" : ""}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                {AuthenticationService.allowUCMenus() && (
                  <>
                    <Grid item xs={0.8}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        iconOnly
                        onClick={() => handleAddParticipant()}
                      >
                        <Tooltip title="Add External participant">
                          <Icon color="info" sx={{ fontWeight: "bold" }}>
                            add
                          </Icon>
                        </Tooltip>
                      </MDButton>
                    </Grid>
                    {formData.externalParticipant &&
                      formData.externalParticipant.map((participant, index) => (
                        <Card
                          style={{ margin: "5px", padding: "8px" }}
                          key={index}
                        >
                          <MDTypography variant="h6" color="text">
                            {`${participant.username}-${participant.extension}`}
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() =>
                                handleParticipantDelete(participant)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </MDTypography>
                        </Card>
                      ))}
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    label="Details"
                    fullWidth
                    type="text"
                    placeholder="Type details for this new meeting"
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    minRows={4}
                    value={formData.detail}
                    onChange={(event) =>
                      handleChange("detail", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDTypography variant="caption">Recording</MDTypography>
                  <Switch
                    checked={formData && formData["recording"]}
                    onChange={(event) =>
                      handleChange("recording", event.target.checked)
                    }
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor:
                          formData && formData["recording"]
                            ? "#4CAF50"
                            : "#F44335",
                      },
                    }}
                  />
                </Grid>
                {isShow ? (
                  <>
                    <Grid item xs={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Conference Mode
                        </InputLabel>
                        <Select
                          label="Conference Mode"
                          value={formData && formData.conferenceType}
                          onChange={(event) =>
                            handleChange("conferenceType", event.target.value)
                          }
                          fullWidth
                          style={{ height: "55px" }}
                        >
                          {" "}
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="a">audio</MenuItem>
                          <MenuItem value="v">video</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Conference Type
                        </InputLabel>
                        <Select
                          label="Conference Type"
                          value={formData && formData.meetingType}
                          onChange={(event) =>
                            handleChange("meetingType", event.target.value)
                          }
                          fullWidth
                          style={{ height: "55px" }}
                        >
                          {" "}
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="Dial Assisted">
                            Dialer Assisted
                          </MenuItem>
                          <MenuItem value="Meet Me Conference">
                            Meet Me Conference
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {[
                      {
                        label: "Maximum Participant Allowed",
                        fieldName: "maxParticipants",
                      },
                      { label: "Beep Interval", fieldName: "beepInterval" },
                      { label: "Retry In Number", fieldName: "retryCount" },
                      { label: "Retry After MS", fieldName: "retryDelay" },
                      {
                        label: "Required Permission To Talk",
                        fieldName: "rptt",
                        type: "switch",
                      },
                      {
                        label: "Voice Recognition",
                        fieldName: "voiceRecognition",
                        type: "switch",
                      },
                      {
                        label: "Password Required",
                        fieldName: "passwordRequired",
                        type: "switch",
                      },
                      {
                        label: "Name Announcement Required",
                        fieldName: "nameAnnouncementRequired",
                        type: "switch",
                      },
                      /* {
                        label: "Recording",
                        fieldName: "recording",
                        type: "switch",
                      }, */
                    ].map(
                      (field, index) =>
                        field && (
                          <Grid
                            key={index}
                            item
                            xs={field.type === "switch" ? 4 : 4}
                          >
                            {field.type === "switch" ? (
                              <>
                                <MDTypography variant="caption">
                                  {field.label}
                                </MDTypography>
                                <Switch
                                  checked={
                                    formData && formData[field.fieldName]
                                  }
                                  onChange={(event) =>
                                    handleChange(
                                      field.fieldName,
                                      event.target.checked
                                    )
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor:
                                        formData && formData[field.fieldName]
                                          ? "#4CAF50"
                                          : "#F44335",
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <TextField
                                label={field.label}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type={field.type || "text"}
                                value={formData[field.fieldName]}
                                fullWidth
                                onChange={(event) =>
                                  handleChange(
                                    field.fieldName,
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </Grid>
                        )
                    )}
                  </>
                ) : null}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {/* {!schedule && (
            <MDButton
              color={isShow ? "secondary" : "info"}
              onClick={isShow ? handleCloseMore : handleMore}
            >
              {isShow ? "Close options" : "More options"}
            </MDButton>
          )} */}
          <MDButton color="secondary" onClick={handleClose}>
            Close
          </MDButton>
          <MDButton color="success" onClick={handleSave}>
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLBaseDialogForm
        entityName="Add External participant in conference"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formDataExternal}
        pageAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CTLConferenceForm;
