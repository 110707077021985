import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";
import loggerFactory from "globals/logger/logger-factory";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARK_MODE": {
      return { ...state, darkMode: action.value };
    }
    case "OPEN_PHONE": {
      return { ...state, openPhone: action.value };
    }
    case "ACTIVE_PAGE": {
      return { ...state, activePage: action.value };
    }
    case "CALL_STATE": {
      loggerFactory.debug("Context", "Updating Call State", action.value);
      return { ...state, callState: action.value };
    }
    case "CLICK_TO_CALL": {
      loggerFactory.debug("Context", "Click to call", action.value);
      return { ...state, clickToCall: action.value };
    }
    case "AUTHENTICATION": {
      loggerFactory.debug("Context", "Authentication", action.value);
      return { ...state, authentication: action.value };
    }
    case "SET_VALUE": {
      return { ...state, value: action.value };
    }
    case "SET_CALL_CENTER_REPORTS": {
      return { ...state, callcenterReportsName: action.value };
    }
    case "SET_ACTION": {
      return { ...state, action: action.value };
    }
    case "SET_TABLE_VALUE": {
      return { ...state, tableValue: action.value };
    }
    case "SET_QUEUE_VALUE": {
      return { ...state, queueValue: action.value };
    }
    case "SET_SEARCHTEXT_VALUE": {
      return { ...state, searchText: action.value };
    }
    case "SET_ID": {
      return { ...state, confId: action.value };
    }
    case "SET_PROFILE_STATUS": {
      return { ...state, profileStatus: action.value };
    }
    case "SET_IVR_EXT": {
      return { ...state, ivrExtension: action.value };
    }
    case "SET_UPDATE_LIST_FLAG": {
      return { ...state, updateListFlag: action.value };
    }
    case "SET_CATEGORY_EMAIL": {
      return { ...state, showCategoryEmail: action.value };
    }
    case "SET_CATEGORY_SMS": {
      return { ...state, showCategorySMS: action.value };
    }
    case "SET_AGENT": {
      return { ...state, showAgent: action.value };
    }
    case "SET_DISABLED_PASSWORDFIELD": {
      return { ...state, disbledPasswordField: action.value };
    }

    case "SET_SIP_SELECT": {
      return { ...state, sipTrunkSelect: action.value };
    }
    case "SET_AGENT_DATA": {
      return { ...state, AgentData: action.value };
    }
    case "SET_IVR_PLAY_FILE": {
      return { ...state, showIVRPlayFile: action.value };
    }
    case "SET_USERNAME_DATA": {
      return { ...state, userName: action.value };
    }
    case "SET_PASSWORD_DATA": {
      return { ...state, passWord: action.value };
    }
    case "SET_TABLE_SORT_COLUMN": {
      return { ...state, tableSortColumn: action.value };
    }
    case "SET_TABLE_COLUMN_DATA_TYPE": {
      return { ...state, tableColumnDataType: action.value };
    }
    case "SET_CONFERENCE_DATA": {
      return { ...state, conferenceData: action.value };
    }
    case "SET_SUNDAY": {
      return { ...state, sundayDisabled: action.value };
    }
    case "SET_MONDAY": {
      return { ...state, mondayDisabled: action.value };
    }
    case "SET_TUESDAY": {
      return { ...state, tuesdayDisabled: action.value };
    }
    case "SET_WEDNESDAY": {
      return { ...state, wednesdayDisabled: action.value };
    }
    case "SET_THURSDAY": {
      return { ...state, thursdayDisabled: action.value };
    }
    case "SET_FRIDAY": {
      return { ...state, fridayDisabled: action.value };
    }
    case "SET_SATURDAY": {
      return { ...state, saturdayDisabled: action.value };
    }
    case "SET_TOGGLE_STATUS": {
      return { ...state, toggleButtonStatus: action.value };
    }
    case "SET_FILENAME": {
      return { ...state, fileName: action.value };
    }
    case "SET_LEAD_VIEW": {
      return { ...state, leadView: action.value };
    }
    case "SET_DIAL_BROADCAST": {
      return { ...state, dialBroadcast: action.value };
    }
    case "SET_AGENT_STATUS_DATA": {
      return { ...state, agentstatusData: action.value };
    }
    case "SET_CALL_IN_WAITING": {
      return { ...state, callInWaiting: action.value };
    }
    case "CONFERENCE_ACTION_BUTTON": {
      return { ...state, actionButton: action.value };
    }
    case "NOTIFICATION_RIGHT_ALIGNED": {
      return { ...state, isNotificationRightAligned: action.value };
    }
    case "IS_VIP_USER": {
      return { ...state, isVipUser: action.value };
    }
    case "IS_PRE_RECORD_BROADCAST": {
      return { ...state, isPreRecordBroadcast: action.value };
    }
    case "BROADCAST_CALL_TYPE": {
      return { ...state, broadcastCallType: action.value };
    }
    case "IS_AUTO_ANSWER": {
      return { ...state, isAutoAnswer: action.value };
    }
    case "ADITIONAL_THEME": {
      return { ...state, aditionalTheme: action.value };
    }
    case "IS_DSS_CALL": {
      return { ...state, isDssCall: action.value };
    }
    case "IS_EMERGENCY_CALL": {
      return { ...state, isEmergencyCall: action.value };
    }
    case "IS_SOS_CALL": {
      return { ...state, isSOSCall: action.value };
    }
    case "IS_BROADCAST_CALL": {
      return { ...state, isBroadcastCall: action.value };
    }
    case "SET_CHAT_NOTIFICATION": {
      return { ...state, chatNotification: action.value };
    }
    case "SET_ISTASKDELAY": {
      return { ...state, taskIsDelay: action.value };
    }
    case "SET_BROADCAST_CALL": {
      return { ...state, broadcastCall: action.value };
    }
    case "SET_ASSIGN_TO": {
      return { ...state, assignTo: action.value };
    }
    case "SET_TASK_FILE_DATA": {
      return { ...state, taskFileData: action.value };
    }
    case "SET_TASK_STATUS": {
      return { ...state, taskStatus: action.value };
    }
    case "SET_AUTH_TYPE": {
      return { ...state, authType: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    taskStatus:"",
    taskFileData:"",
    assignTo:"",
    taskIsDelay:false,
    aditionalTheme: false,
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "success",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: true,
    openPhone: false,
    searchText: "",
    isNotificationRightAligned: true,
    profileStatus: [],
    agentstatusData: {},
    activePage: {
      name: "",
      key: "",
      url: "",
      description: "",
      props: "",
      authenticationStatus: false,
    },
    fileName: {},
    callcenterReportsName: "",
    leadView: {},
    callState: {
      inCall: false,
      isOnHold: false,
      isMute: false,
      isRinging: false,
      isDialing: false,
      dialNumber: "",
      callStatus: "",
    },
    clickToCall: "",
    authentication: false,
    value: null,
    action: "",
    tableValue: [],
    queueValue: [],
    confId: "",
    ivrExtension: "",
    updateListFlag: false,
    showCategoryEmail: false,
    showCategorySMS: false,
    showAgent: false,
    disbledPasswordField: false,
    sipTrunkSelect: "",
    AgentData: "",
    showIVRPlayFile: false,
    userName: "",
    passWord: "",
    tableSortColumn: "",
    tableColumnDataType: "",
    conferenceData: "",
    sundayDisabled: true,
    mondayDisabled: true,
    tuesdayDisabled: true,
    wednesdayDisabled: true,
    thursdayDisabled: true,
    fridayDisabled: true,
    saturdayDisabled: true,
    toggleButtonStatus: "Disposition",
    dialBroadcast: [],
    callInWaiting: [],
    actionButton: "",
    isVipUser: false,
    isPreRecordBroadcast: false,
    broadcastCallType: {
      type: "",
      name: "",
    },
    isAutoAnswer: false,
    isDssCall: false,
    isEmergencyCall: false,
    isSOSCall: false,
    isBroadcastCall: false,
    chatNotification: {},
    broadcastCall: {},
    authType:[],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  // Load dark mode value from local storage on component mount
  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    const savedAdditionalMode = localStorage.getItem("aditionalTheme");
    if (savedAdditionalMode === "true") {
      dispatch({ type: "ADITIONAL_THEME", value: true });
      dispatch({ type: "DARK_MODE", value: null });
    } else {
      dispatch({ type: "ADITIONAL_THEME", value: false });
      dispatch({ type: "DARK_MODE", value: savedDarkMode === "true" || savedDarkMode === null });
    }


    const savedTransparentSidenav = localStorage.getItem("transparentSidenav");
    if (savedTransparentSidenav !== null) {
      dispatch({
        type: "TRANSPARENT_SIDENAV",
        value: savedTransparentSidenav === "true",
      });
    }

    const savedWhiteSidenav = localStorage.getItem("whiteSidenav");
    if (savedWhiteSidenav !== null) {
      dispatch({ type: "WHITE_SIDENAV", value: savedWhiteSidenav === "true" });
    }
  }, []);

  useEffect(() => {
    if (controller.darkMode !== null) {
      localStorage.setItem("darkMode", controller.darkMode.toString());
    } else {
      localStorage.removeItem("darkMode");
    }
    localStorage.setItem("aditionalTheme", controller.aditionalTheme.toString());
    localStorage.setItem( "transparentSidenav",  controller.transparentSidenav.toString());
    localStorage.setItem("whiteSidenav", controller.whiteSidenav.toString());
  }, [
    controller.darkMode,
    controller.aditionalTheme,
    controller.transparentSidenav,
    controller.whiteSidenav,
  ]);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => {
  console.log("dispatch transparent sidenav", value);
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
};
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARK_MODE", value });
const setOpenPhone = (dispatch, value) =>
  dispatch({ type: "OPEN_PHONE", value });
const setActivePage = (dispatch, value) =>
  dispatch({ type: "ACTIVE_PAGE", value });
const setCallState = (dispatch, value) =>
  dispatch({ type: "CALL_STATE", value });
const setClickToCall = (dispatch, value) =>
  dispatch({ type: "CLICK_TO_CALL", value });
const setAuthentication = (dispatch, value) =>
  dispatch({ type: "AUTHENTICATION", value });
const setValue = (dispatch, value) => dispatch({ type: "SET_VALUE", value });
const setAction = (dispatch, value) => dispatch({ type: "SET_ACTION", value });
const setTableValue = (dispatch, value) =>
  dispatch({ type: "SET_TABLE_VALUE", value });
const setQueueValue = (dispatch, value) =>
  dispatch({ type: "SET_QUEUE_VALUE", value });
const setConfId = (dispatch, value) => dispatch({ type: "SET_ID", value });
const setSearchText = (dispatch, value) =>
  dispatch({ type: "SET_SEARCHTEXT_VALUE", value });
const setProfileStatus = (dispatch, value) =>
  dispatch({ type: "SET_PROFILE_STATUS", value });
const setIvrExtension = (dispatch, value) =>
  dispatch({ type: "SET_IVR_EXT", value });
const setUpdateListFlag = (dispatch, value) =>
  dispatch({ type: "SET_UPDATE_LIST_FLAG", value });
const setShowCategoryEmail = (dispatch, value) =>
  dispatch({ type: "SET_CATEGORY_EMAIL", value });
const setShowCategorySMS = (dispatch, value) =>
  dispatch({ type: "SET_CATEGORY_SMS", value });
const setShowAgent = (dispatch, value) =>
  dispatch({ type: "SET_AGENT", value });
const setDisablePass = (dispatch, value) =>
  dispatch({ type: "SET_DISABLED_PASSWORDFIELD", value });
const setSipTrunkSelect = (dispatch, value) =>
  dispatch({ type: "SET_SIP_SELECT", value });
const setAgentData = (dispatch, value) =>
  dispatch({ type: "SET_AGENT_DATA", value });
const setShowIVRPlayFile = (dispatch, value) =>
  dispatch({ type: "SET_IVR_PLAY_FILE", value });
const setUserName = (dispatch, value) =>
  dispatch({ type: "SET_USERNAME_DATA", value });
const setPassWord = (dispatch, value) =>
  dispatch({ type: "SET_PASSWORD_DATA", value });
const setTableSortColumn = (dispatch, value) =>
  dispatch({ type: "SET_TABLE_SORT_COLUMN", value });
const setTableColumnDataType = (dispatch, value) =>
  dispatch({ type: "SET_TABLE_COLUMN_DATA_TYPE", value });
const setConferenceData = (dispatch, value) =>
  dispatch({ type: "SET_CONFERENCE_DATA", value });
const setSundayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_SUNDAY", value });
const setMondayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_MONDAY", value });
const setTuesdayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_TUESDAY", value });
const setWednesdayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_WEDNESDAY", value });
const setThursdayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_THURSDAY", value });
const setFridayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_FRIDAY", value });
const setSaturdayDisabled = (dispatch, value) =>
  dispatch({ type: "SET_SATURDAY", value });
const setToggleButtonStatus = (dispatch, value) =>
  dispatch({ type: "SET_TOGGLE_STATUS", value });
const setLeadViewData = (dispatch, value) =>
  dispatch({ type: "SET_LEAD_VIEW", value });
const setfileName = (dispatch, value) =>
  dispatch({ type: "SET_FILENAME", value });
const setCallcenterReportsName = (dispatch, value) =>
  dispatch({ type: "SET_CALL_CENTER_REPORTS", value });
const setAgentStatusData = (dispatch, value) =>
  dispatch({ type: "SET_AGENT_STATUS_DATA", value });
const setDialBroadcast = (dispatch, value) =>
  dispatch({ type: "SET_DIAL_BROADCAST", value });
const setCallInWaiting = (dispatch, value) =>
  dispatch({ type: "SET_CALL_IN_WAITING", value });
const setActionButton = (dispatch, value) =>
  dispatch({ type: "CONFERENCE_ACTION_BUTTON", value });
const setIsNotificationRightAligned = (dispatch, value) =>
  dispatch({ type: "NOTIFICATION_RIGHT_ALIGNED", value });
const setIsVipUser = (dispatch, value) =>
  dispatch({ type: "IS_VIP_USER", value });
const setIsPreRecordBroadcast = (dispatch, value) =>
  dispatch({ type: "IS_PRE_RECORD_BROADCAST", value });
const setBroadCastCallType = (dispatch, value) =>
  dispatch({ type: "BROADCAST_CALL_TYPE", value });
const setIsAutoAnswer = (dispatch, value) =>
  dispatch({ type: "IS_AUTO_ANSWER", value });
const setAditionalTheme = (dispatch, value) =>
  dispatch({ type: "ADITIONAL_THEME", value });
const setIsDssCall = (dispatch, value) =>
  dispatch({ type: "IS_DSS_CALL", value });
const setIsEmergencyCall = (dispatch, value) =>
  dispatch({ type: "IS_EMERGENCY_CALL", value });
const setIsSOSCall = (dispatch, value) =>
  dispatch({ type: "IS_SOS_CALL", value });
const setIsBroadcastCall = (dispatch, value) =>
  dispatch({ type: "IS_BROADCAST_CALL", value });
const setChatNotification = (dispatch, value) =>
  dispatch({ type: "SET_CHAT_NOTIFICATION", value });
  const setTaskIsDelay = (dispatch, value) =>
  dispatch({ type: "SET_ISTASKDELAY", value });
const setBroadcastCall = (dispatch, value) =>
  dispatch({ type: "SET_BROADCAST_CALL", value });
  const setAssignTo = (dispatch, value) =>
  dispatch({ type: "SET_ASSIGN_TO", value });
  const setTaskFileData = (dispatch, value) =>
  dispatch({ type: "SET_TASK_FILE_DATA", value });
  const setTaskStatus = (dispatch, value) =>
  dispatch({ type: "SET_TASK_STATUS", value });
  const setAuthType = (dispatch, value) =>
    dispatch({ type: "SET_AUTH_TYPE", value });





export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setOpenPhone,
  setActivePage,
  setCallState,
  setClickToCall,
  setAuthentication,
  setValue,
  setAction,
  setTableValue,
  setQueueValue,
  setConfId,
  setSearchText,
  setProfileStatus,
  setIvrExtension,
  setUpdateListFlag,
  setShowCategoryEmail,
  setShowCategorySMS,
  setShowAgent,
  setDisablePass,
  setSipTrunkSelect,
  setAgentData,
  setShowIVRPlayFile,
  setUserName,
  setPassWord,
  setTableSortColumn,
  setTableColumnDataType,
  setConferenceData,
  setSundayDisabled,
  setMondayDisabled,
  setTuesdayDisabled,
  setWednesdayDisabled,
  setThursdayDisabled,
  setFridayDisabled,
  setSaturdayDisabled,
  setToggleButtonStatus,
  setfileName,
  setCallcenterReportsName,
  setLeadViewData,
  setDialBroadcast,
  setAgentStatusData,
  setCallInWaiting,
  setActionButton,
  setIsNotificationRightAligned,
  setIsVipUser,
  setIsPreRecordBroadcast,
  setBroadCastCallType,
  setIsAutoAnswer,
  setAditionalTheme,
  setIsDssCall,
  setIsEmergencyCall,
  setIsSOSCall,
  setIsBroadcastCall,
  setChatNotification,
  setTaskIsDelay,
  setBroadcastCall,
  setAssignTo,
  setTaskFileData,
  setTaskStatus,
  setAuthType,
};