import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController , setUpdateListFlag } from "context";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLTree from "application/components/CTLTree";
import CTLNotification from "application/components/CTLNotification";
import loggerFactory from "globals/logger/logger-factory";
function IVR({ onLoad, registerCallBacks, pageId, searchText }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [openFilter, setOpenFilter] = useState(false);
  const [ivrDetails, setIvrDetails] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });


  const onPrint = () => {
    window.print();
  };

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onPrint: onPrint,
      });
  }, [activePage]);

  useEffect(() => {
    ivrData(ivrExtension);
  }, [ivrExtension]);


  const deleteRow = (row) => { };

  const editRow = (row) => { };

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function ivrData(ivr) {
    if (ivr) {
      const detailUrl = `/autoAttendant/ivrDetails/${ivr.code}`;
      application.get(detailUrl).then((response) => {
        if (response.data.data) {
          setIvrDetails(response.data.data);
        } else {
          setIvrDetails({});
        }
      })
        .catch((error) => {
          setIvrDetails({});
          loggerFactory.error("Error fetching IVR data:", error);
        });
    }
  }
function onSaveForm(data) {
  application
    .post(operationURLS.register, data)
    .then((response) => {
      setNotify({
        isOpen: true,
        message: response.data.messageDetail,
        type: "success",
        pagename: "IVR",
        status: response.data.status,
      });
      setUpdateList(Date.now());
    })
    .catch((error) => {
      setNotify({
        isOpen: true,
        message: error.response.data.message,
        type: "error",
        pagename: "IVR",
        status: error.response.data.messageDetail,
      });
    })
    .finally(() => {
      setOpenForm(false);
      setUpdateListFlag(dispatch, true);
    });
}


  function onCloseForm() {
    setOpenForm(false);
  }

  return (
    <>
      <MDBox>
        <CTLBaseDialogForm
          entityName="Ivr"
          columns={getColumns(deleteRow, editRow)}
          openForm={openForm}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseForm}
          formData={formData}
          pageAttributes={Attributes}
        />
        <CTLTree ivrDetails={ivrDetails} />
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>
    </>
  );
}

export default IVR;
