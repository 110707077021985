import { divIcon } from "leaflet";
import { Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import AuthenticationService from "../../../../../application/modules/authentication/AuthenticationService";
import "../css/gisMarker.css";

function GISRoot({ markersData }) {
  const mapRef = useRef();
  const mapContainerRef = useRef(null);
  const homeLocation = AuthenticationService.getLocation() || [0, 0];

  useEffect(() => {
    if (!mapRef.current) {
      console.log("Initializing map with homeLocation:", homeLocation);
      const map = L.map(mapContainerRef.current).setView(
        homeLocation,
        window.mapDefaultZoom || 13, // Provide a default zoom level
      );
      const osm = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          errorTileUrl: "path/to/error/tile.png", // Optional: Add a fallback tile URL
        },
      );
      osm.addTo(map);
      mapRef.current = map;
    }
  }, [homeLocation, window.mapDefaultZoom]);

  useEffect(() => {
    if (mapRef.current && markersData) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });

      const markers = L.layerGroup().addTo(mapRef.current);

      markersData.forEach((marker) => {
        if (marker.geoX && marker.geoY) {
          const markerLocation = [
            parseFloat(marker.geoX),
            parseFloat(marker.geoY),
          ];
          const customIcon = getMarkerIcon(marker);
          const customMarker = L.marker(markerLocation, {
            icon: customIcon,
            draggable: false,
          });

          let popupContent = "";

          if (marker.data) {
            popupContent = `
            <div>
              <h3 style="color: black;">${marker.data.name}</h3>
              <p style="color: black;">
                <hr />
                <strong>Lat Long:</strong> ${marker.geoX}, ${marker.geoY}<br />
                <strong>Blood Group:</strong> ${
                  marker.data.bloodGroup || "N/A"
                }<br /> <hr />
                <strong>Medical Condition:</strong> ${
                  marker.data.medicalDetails || "N/A"
                }<br /> <hr />
                <strong>Home Address:</strong> ${
                  marker.data.homeAddress || "N/A"
                }<br /> <hr />
                <strong>Office Address:</strong> ${
                  marker.data.officeAddress || "N/A"
                }<br /> <hr />
                <strong>Primary Contact:</strong> ${
                  marker.data.primaryEmergencyContactName || "N/A"
                } ${
              marker.data.primaryEmergencyContactNumber || "N/A"
            }<br /> <hr />
                <strong>Secondary Contact:</strong> ${
                  marker.data.secondaryEmergencyContactName || "N/A"
                } ${marker.data.secondaryEmergencyContactNumber || "N/A"}<br />
                <hr />
               ${
                 marker.emergencyStatus
                   ? `<button style="background-color: green; color: white; padding:5px; margin: auto; display: block; 
                border-radius: 10px; cursor: pointer; margin-top: 10px;"  onclick="window.handleClearEmergency('${marker.id}')">Clear Emergency</button>`
                   : ""
               }
              </p>
               ${
                 marker.tagType !== "imsi"
                   ? `<button style="background-color: red; color: white; padding:5px; margin: auto; display: block; border-radius: 10px; cursor: pointer;" onclick="window.handlePinDelete('${marker.id}')">
                    Delete
                </button>`
                   : ""
               }
            </div>`;
          } else {
            popupContent = `
            <div>
              <h3 style="color: black;">${marker.name}</h3>
              <p style="color: black;">
                <hr />
                <strong>Lat Long:</strong> ${marker.geoX}, ${marker.geoY}
              </p>
            </div>`;
          }

          customMarker.bindPopup(popupContent);
          customMarker.addTo(markers);
        }
      });
    }
  }, [markersData]);

  const iconIMSIActive = `<svg class="MuiSvgIcon-root MuiSvgIcon-colorSuccess MuiSvgIcon-fontSizeLarge css-quk7e7-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FmdGoodIcon"><path fill="#4CAF50" d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2m0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2"></path></svg>`;

  const iconIMSIEmergency = `<svg class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeLarge css-5r0asf-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalHospitalIcon"><path fill="#F44335" d="M19 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-1 11h-4v4h-4v-4H6v-4h4V6h4v4h4z"></path></svg>`;

  const iconIMSIInActive = `<svg class="MuiSvgIcon-root MuiSvgIcon-colorWarning MuiSvgIcon-fontSizeLarge  css-vojx2p-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FmdGoodIcon"><path fill="#fb8c00" d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2m0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2"></path></svg>`;

  const iconEnemy = `<svg class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeLarge  css-5r0asf-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FmdBadIcon"><path fill="#F44335" d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2m1 13h-2v-2h2zm0-4h-2V6h2z"></path></svg>`;

  let icon = iconIMSIActive;

  const markerIMSIActive = divIcon({
    html: icon,
  });

  const markerIMSIInActive = divIcon({
    html: iconIMSIInActive,
  });

  const markerEnemy = divIcon({
    html: iconEnemy,
  });

  const markerEmergency = divIcon({
    html: iconIMSIEmergency,
  });

  function getMarkerIcon(marker) {
    let customIcon;

    if (marker.tagType === "imsi") {
      if (marker.emergencyStatus) {
        customIcon = markerEmergency;
      } else {
        customIcon = marker.status ? markerIMSIActive : markerIMSIInActive;
      }
    } else {
      customIcon = markerEnemy;
    }
    customIcon.options.iconSize = [24, 24];
    customIcon.options.iconAnchor = [12, 24];
    return customIcon;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            height: "70vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {homeLocation && (
            <div
              id="map"
              ref={mapContainerRef}
              style={{ height: "100%", zIndex: "1" }}
              center={homeLocation}
              zoom={window.mapDefaultZoom || 13} // Provide a default zoom level
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GISRoot;
