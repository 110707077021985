const taskManagementSystem = [
    {
      icon: "map",
      key: "taskManager",
      name: "Task Management",
      url: "home/app/taskManager",
      description: "Allows you to create, assign, prioritize, and track tasks.",
      disabled: false,
      productionReady : true
    },
    {
      icon: "AccountCircle",
      key: "userTask",
      name: "User Control Task",
      url: "home/app/taskManager",
      description: "User View Task Details, and update their status",
      disabled: true,
      productionReady : true
    },
    {
      icon: "person",
      key: "taskType",
      name: "Task Type",
      url: "home/app/taskManager",
      description: "Task Type details",
      disabled: false,
      productionReady : true
      },
      {
      icon: "candlestick_chart",
      key: "taskPriority",
      name: "Task Priority",
      url: "home/app/taskManager",
      description: "Task Priority details",
      disabled: false,
      productionReady : true
      },
      {
      icon: "person_pin_icon",
      key: "taskStatus",
      name: "Task Status",
      url: "home/app/taskManager",
      description: "Task Status details",
      disabled: false,
      productionReady : true
      },
  ];
  
  export { taskManagementSystem };
  