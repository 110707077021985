import React, { useState } from "react";
import { Box, Tabs, Tab, TextField, Button, IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useMaterialUIController } from "context";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";

const HistoryFilter = ({ onFilterChange }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [selectedTab, setSelectedTab] = useState(0);
  const [customDateRange, setCustomDateRange] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    const today = dayjs();
    let filterDates = {
      startDate: today.startOf("day"),
      endDate: today.endOf("day"),
    };

    switch (newValue) {
      case 1: // Yesterday
        filterDates = {
          startDate: today.subtract(1, "day").startOf("day"),
          endDate: today.subtract(1, "day").endOf("day"),
        };
        break;
      case 2: // Last Week
        filterDates = {
          startDate: today.subtract(7, "days").startOf("day"),
          endDate: today.endOf("day"),
        };
        break;
      case 3: // Custom
        // No immediate filter change, wait for user to press search
        return;
      default: // Today
        break;
    }

    onFilterChange(filterDates);
  };

  const handleCustomDateChange = (type, value) => {
    setCustomDateRange((prevRange) => ({
      ...prevRange,
      [type]: value,
    }));
  };

  const handleSearch = () => {
    onFilterChange(customDateRange);
  };

  const tabStyle = {
    marginInline: "0.1em",
    padding: "0.5em",
    minWidth: "100px", // Adjust this value to match your design
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      {selectedTab === 3 ? (
        <IconButton onClick={() => setSelectedTab(0)}>
          <ArrowBackIcon
            sx={{
              color: darkMode ? "#ffffff" : "#000000",
              fontSize: "1.5em",
              marginInline: "0.5em",
            }}
          />
        </IconButton>
      ) : (
        <FilterAltIcon
          sx={{
            color: darkMode ? "#ffffff" : "#000000",
            fontSize: "1.5em",
            marginInline: "0.5em",
          }}
        />
      )}

      {selectedTab === 3 ? (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={customDateRange.startDate}
              onChange={(newValue) =>
                handleCustomDateChange("startDate", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{
                    minWidth: "6.7em",
                    maxWidth: "6.7em",
                    svg: {
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  }}
                />
              )}
            />
            <DatePicker
              label="To Date"
              value={customDateRange.endDate}
              onChange={(newValue) =>
                handleCustomDateChange("endDate", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{
                    minWidth: "6.7em",
                    maxWidth: "6.7em",
                    svg: {
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{
              padding: "0px",
              margin: "0px",
              maxWidth: "35px",
              minWidth: "35px",
            }}
          >
            <SearchIcon
              sx={{
                fontSize: "2em",
                padding: "0px",
                color: darkMode ? "#ffffff" : "#000000",
              }}
            />
          </Button>
        </Box>
      ) : (
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="history filter tabs"
          sx={{
            ".MuiTab-root": {
              color: "text.primary",
              ":hover": {
                color: "primary.main",
              },
            },
            ".Mui-selected": {
              backgroundColor: darkMode
                ? "rgba(255, 255, 255, 0.2)"
                : "rgba(0,0,0,0.2)",
              color: "primary.main",
            },
          }}
        >
          <Tab label="Today" sx={tabStyle} />
          <Tab label="Yesterday" sx={tabStyle} />
          <Tab label="Last 5 days" sx={tabStyle} />
          <Tab label="Custom" sx={tabStyle} />
        </Tabs>
      )}
    </Box>
  );
};

export default HistoryFilter;
