import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDTypography from "components/MDTypography";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function CTLMultipleSelect({
  label,
  name,
  value = [],
  onChange,
  url,
  options = [],
  keyField,
  keyDescription,
}) {
  const [listOptions, setListOptions] = useState(options);
  const [selected, setSelected] = useState(value);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (onChange) {
      onChange({
        target: {
          value: newValue.map((option) => option[keyField]),
          name,
        },
      });
    }
  };

  // Fetch options from the URL if provided
  useEffect(() => {
    if (url) {
      application.post(url).then((response) => {
        const data = response.data.data || [];
        setListOptions(data);
      });
    }
  }, [url]);

  // Update selected values when the `value` prop changes
  useEffect(() => {
    if (Array.isArray(value)) {
      setSelected(
        listOptions.filter((option) => value.includes(option[keyField]))
      );
    }
  }, [value, listOptions, keyField]);

  return (
    <>
      <MDTypography variant="caption">{label}</MDTypography>
      <Autocomplete
        multiple
        fullWidth
        options={listOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option[keyDescription] || ""}
        value={selected}
        isOptionEqualToValue={(option, value) =>
          option[keyField] === value[keyField]
        }
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[keyDescription]}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Multiple Select"
            fullWidth
          />
        )}
        style={{ marginTop: "8px" }}
      />
    </>
  );
}

export default CTLMultipleSelect;
