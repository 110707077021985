import React, { useState } from "react";
import { Menu, MenuItem, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import PentagonIcon from "@mui/icons-material/Pentagon";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CalendarViewMonth from "@mui/icons-material/CalendarViewMonth";
import RouteIcon from "@mui/icons-material/Route";
import UserIcon from "@mui/icons-material/Person";
import { v4 as uuidv4 } from "uuid";

import { useMaterialUIController } from "context";
import { useFolderStructure } from "./hooks/useFolderStructure";
import AssignmentDialog from "./AlertsDialogs/AssignmentDialog";
import { useGeometryDataJSON } from "./useGeometryDataJSON";

const ContextMenu = ({
  currentFolder,
  anchorContextMenu,
  closeContextMenu,
  folder,
  handleAddClick,
  handleEditClick,
  handleDeleteClick,
  setMoveExplorer,
  setOpenFeatureDialog,
  handleSetCurrentPage,
  setFolders,
  areaList,
  routeList,
  userList,
  onUserDrop,
  selectedFolders,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { handleNewFolderStructure } = useFolderStructure(setFolders);
  const { changeGeometryDataJSON } = useGeometryDataJSON();

  const menuItemStyles = {
    padding: "1px 6px 0px 1.2em",
    gap: 2,
  };

  const iconStyles = {
    fontSize: "1.4em",
    color: darkMode ? "#ffffff" : "#000000",
  };

  const [assignmentDialog, setAssignmentDialog] = useState({
    open: false,
    type: null,
    options: [],
  });

  const handleCreateZone = () => {
    if (!setFolders) {
      console.error("setFolders is not available");
      return;
    }

    let shapeId = uuidv4();
    let shapeData = {
      name: "New Zone",
      description: "",
      coordinates: [],
      color: "blue",
      areaType: "polygon",
    };
    handleNewFolderStructure(shapeData);
    closeContextMenu();
  };

  const handleCreateRoute = () => {
    if (!setFolders) {
      console.error("setFolders is not available");
      return;
    }

    let shapeId = uuidv4();
    let shapeData = {
      name: "New Route",
      description: "",
      coordinates: [],
      color: "blue",
      areaType: "polyline",
    };
    handleNewFolderStructure(shapeData);
    closeContextMenu();
  };

  const handleAssignment = (type) => {
    let options = [];
    switch (type) {
      case "assignArea":
        options = areaList || [];
        break;
      case "assignRoute":
        options = routeList || [];
        break;
      case "assignUser":
        options = userList || [];
        break;
      default:
        options = [];
    }

    setAssignmentDialog({
      open: true,
      type: type || null,
      options: options || [],
    });
    closeContextMenu();
  };

  const handleAssignmentConfirm = (selectedUser) => {
    if (!selectedUser) return;

    setFolders((prevFolders) => {
      // Deep clone the folders to avoid direct state mutation
      const newFolders = JSON.parse(JSON.stringify(prevFolders));

      // Find and update the current folder in the cloned structure
      const updateFolderStructure = (folders) => {
        for (let folder of folders) {
          if (folder.id === currentFolder.id) {
            // Create the new user object
            const newUserObj = {
              id: uuidv4(),
              name: selectedUser.username,
              type: "user",
              parentId: folder.id,
              userId: selectedUser.id,
              userDetails: selectedUser,
              children: [],
            };

            // Check if folder has children
            if (folder.children && folder.children.length > 0) {
              // Check if first child is a users group
              if (folder.children[0].type === "users") {
                // Add user to existing users group
                folder.children[0].children.push(newUserObj);
              } else {
                // Create new users group and add user
                const newUsersGroup = {
                  id: uuidv4(),
                  name: "Users",
                  type: "users",
                  parentId: folder.id,
                  children: [newUserObj],
                };
                folder.children.unshift(newUsersGroup);
              }
            } else {
              // Create new children array with users group
              folder.children = [
                {
                  id: uuidv4(),
                  name: "Users",
                  type: "users",
                  parentId: folder.id,
                  children: [newUserObj],
                },
              ];
            }

            // Call API to update backend
            changeGeometryDataJSON(
              newFolders,
              `assigned user ${selectedUser.username}`
            );
            return true;
          }

          if (folder.children) {
            const found = updateFolderStructure(folder.children);
            if (found) return true;
          }
        }
        return false;
      };

      updateFolderStructure(newFolders);
      return newFolders;
    });

    // Close the assignment dialog
    setAssignmentDialog({ open: false, type: null, options: [] });
  };

  return (
    <>
      <Menu
        key={currentFolder?.id}
        anchorEl={anchorContextMenu}
        open={Boolean(anchorContextMenu)}
        onClose={closeContextMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {selectedFolders?.length > 0 &&
        (selectedFolders[0]?.type === "zones" ||
          selectedFolders[0]?.type === "routes" ||
          selectedFolders[0]?.type === "geofence" ||
          selectedFolders[0]?.type === "route") ? (
          <MenuItem
            key="assignArea"
            onClick={handleDeleteClick}
            sx={menuItemStyles}
          >
            <DeleteIcon sx={{ ...iconStyles }} fontSize="1.4em" />
            Delete All selected{" "}
            {selectedFolders?.length > 0 && selectedFolders[0]?.type}
          </MenuItem>
        ) : (
          <div>
            <MenuItem
              disableRipple
              sx={{
                display: "flex",
                gap: 1,
                borderBottom: darkMode
                  ? "0.5px solid rgba(255,255,255,0.1)"
                  : "0.5px solid rgba(0,0,0,0.2)",
                marginBottom: "5px",
                paddingBottom: "1px",
                "&:hover": { backgroundColor: "transparent !important" },
              }}
            >
              <Tooltip title="Edit">
                <IconButton>
                  <EditIcon
                    onClick={handleEditClick}
                    sx={{
                      ...iconStyles,
                      padding: "2px",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.2)",
                        borderRadius: "0.35em",
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon
                    onClick={handleDeleteClick}
                    sx={{
                      ...iconStyles,
                      padding: "2px",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.2)",
                        borderRadius: "0.35em",
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            {currentFolder?.type === "zones" && (
              <MenuItem
                key="createZone"
                onClick={handleCreateZone}
                sx={menuItemStyles}
              >
                <PentagonIcon sx={{ ...iconStyles }} fontSize="1.4em" />
                Create New Zones
              </MenuItem>
            )}
            {currentFolder?.type === "routes" && (
              <MenuItem
                key="createRoute"
                onClick={handleCreateRoute}
                sx={menuItemStyles}
              >
                <RouteIcon sx={{ ...iconStyles }} fontSize="1.4em" />
                Create New Routes
              </MenuItem>
            )}
            {(currentFolder?.type === "route" ||
              currentFolder?.type === "geofence") && (
              <MenuItem
                onClick={() => handleAssignment("assignUser")}
                sx={menuItemStyles}
              >
                <UserIcon sx={{ ...iconStyles }} fontSize="1.4em" />
                Assign User
              </MenuItem>
            )}
          </div>
        )}
      </Menu>

      <AssignmentDialog
        open={assignmentDialog.open}
        onClose={() =>
          setAssignmentDialog({ open: false, type: null, options: [] })
        }
        title={
          assignmentDialog.type === "assignArea"
            ? "Assign Area for " + currentFolder?.name
            : assignmentDialog.type === "assignRoute"
            ? "Assign Route for " + currentFolder?.name
            : "Assign User for " + currentFolder?.name
        }
        options={assignmentDialog.options}
        onConfirm={handleAssignmentConfirm}
      />
    </>
  );
};

export default ContextMenu;
