import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableAdvanceSearch: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/alertHistory/nonWarning/list",
};

const getColumns = (onAssignedAgent) => {
  return [
    {
      Header: "Alert Generated On",
      accessor: "alertGeneratedOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Alert Close On",
      accessor: "alertClosedOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Service Code",
      accessor: "serviceCode",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Asset Name ",
      accessor: "assetName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category Code",
      accessor: "categoryCode",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Severity",
      accessor: "severity",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Severity Name",
      accessor: "severityName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "status",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value, row }) => (
        <RowAction onAssignedAgent={onAssignedAgent} value={value} row={row} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
