import MDTypography from "components/MDTypography";
import { Icon, IconButton } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import DoneIcon from "@mui/icons-material/Done";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import ChatIcon from "@mui/icons-material/Chat";


function DispatcherRowAction({
  row,
  value,
  onClickToCall,
  onClickToMessage,
  onClickToEmergencyConfJoin,
  onClickToConference,
}) {
  const isAnnouncement = (row) =>
    row.callingGroup?.includes("Announcement") ||
    row.callingGroup?.includes("Drag And Drop") ||
    row.callingGroup?.includes("Conference");

     const isEndConference = (row) => row?.meetingStatus == 3; 

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <tooltip title="Call">
        {!isAnnouncement(row.original) && onClickToCall && (
          <IconButton color="info" onClick={() => onClickToCall(row.original)}>
            <CallIcon></CallIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Call">
        {!isEndConference(row.original) && onClickToConference && (
          <IconButton
            color="info"
            onClick={() => onClickToConference(row.original)}
          >
            <VideocamRoundedIcon></VideocamRoundedIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Call">
        {isEndConference(row.original) && onClickToConference && (
          <Icon
            color="success"
            fontSize="medium"
            style={{ paddingLeft: "5px" }}
          >
            <DoneIcon></DoneIcon>
          </Icon>
        )}
      </tooltip>
      <tooltip title="Message">
        {!isAnnouncement(row.original) && onClickToMessage && (
          <IconButton
            color="success"
            onClick={() => onClickToMessage(row.original)}
          >
            <MessageIcon></MessageIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Emergency Call">
        {!isAnnouncement(row.original) && onClickToEmergencyConfJoin && (
          <IconButton
            color="primary"
            onClick={() => onClickToEmergencyConfJoin(row.original)}
          >
            <CallIcon></CallIcon>
          </IconButton>
        )}
      </tooltip>
    </MDTypography>
  );
}

export default DispatcherRowAction;
