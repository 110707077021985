import { useState, useEffect } from "react";

// MUI components
import { Card, Backdrop, CircularProgress, Button } from "@mui/material";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import theme from "assets/theme-dark";

// RTK-Query hooks
import {
  useGetWhatsAppChatHistoryQuery,
  useSaveAgentMessageMutation,
  useCloseChatMutation,
} from "features/customerSupportApiSlice";

// Notistack snackbar hook
import { useSnackbar } from "notistack";

// Customer support components
import ChatWindow from "./ChatWindow";
import ChatActions from "./ChatActions";
import CloseChat from "./CloseChat";
import Confirmation from "./Confirmation";

function AgentChatPanel({ agentId, customerNumber }) {
  const [messages, setMessages] = useState(null);
  const [isCloseChatConfirmationOpen, setIsCloseChatConfirmationOpen] =
    useState(false);
  const [isChatClosed, setIsChatClosed] = useState(false);
  const [isChatClosing, setIsChatClosing] = useState(false);

  const { data, error } = useGetWhatsAppChatHistoryQuery(customerNumber);
  const [saveAgentMessage] = useSaveAgentMessageMutation();
  const [closeChat] = useCloseChatMutation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { borders } = theme;

  useEffect(async () => {
    if (data) setMessages(data);
  }, [data]);

  // Handler for sending the agent message
  const handleSendAgentMessage = async (event, message) => {
    event.preventDefault();
    if (message.length === 0) return;

    const agentMessagePayload = {
      agentId,
      message,
    };

    try {
      await saveAgentMessage(agentMessagePayload).unwrap();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        action: (key) => (
          <Button
            sx={{ color: "#FFF" }}
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Dismiss
          </Button>
        ),
      });
      setIsChatClosed(true);
    }
  };

  // Handler for opening close chat confirmation dialog box
  const handleCloseChatConfirmationOpen = () =>
    setIsCloseChatConfirmationOpen(true);

  // Handler for closing close chat confirmation dialog box
  const handleCloseChatConfirmationClose = () =>
    setIsCloseChatConfirmationOpen(false);

  // Handler for closing the chat
  const handleCloseChat = async () => {
    handleCloseChatConfirmationClose();
    setIsChatClosing(true);

    let closeChatStatusMessage = null;
    try {
      const response = await closeChat(agentId).unwrap();
      closeChatStatusMessage = response.message;
    } catch (error) {
      console.error(error);
      closeChatStatusMessage = error.message;
    }

    enqueueSnackbar(closeChatStatusMessage, {
      variant: "error",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      action: (key) => (
        <Button
          sx={{ color: "#FFF" }}
          size="small"
          onClick={() => closeSnackbar(key)}
        >
          Dismiss
        </Button>
      ),
    });

    setIsChatClosing(false);
    setIsChatClosed(true);
  };

  // Rendering logic for customer chat support UI
  let renderChatBox = null;
  if (messages) {
    if (messages.length === 0) {
      renderChatBox = (
        <>
          <img
            src="./images/agent.jpg"
            alt="agent"
            width="360px"
            style={{ borderRadius: ".5em", alignSelf: "center" }}
          />
          <MDTypography mt={1} textAlign="center" color="light">
            No chat history found!
          </MDTypography>
        </>
      );
    } else {
      renderChatBox = (
        <>
          <ChatWindow messages={messages} />
          {!isChatClosed && (
            <ChatActions
              isChatClosed={isChatClosed}
              handleSendAgentMessage={handleSendAgentMessage}
            />
          )}
          <CloseChat
            isChatClosed={isChatClosed}
            handleCloseChatConfirmationOpen={handleCloseChatConfirmationOpen}
          />
          <Confirmation
            confirmationTitle="Close chat"
            confirmationText="Do you want to close this chat?"
            isConfirmationOpen={isCloseChatConfirmationOpen}
            handleConfirmationClose={handleCloseChatConfirmationClose}
            handleConfirm={handleCloseChat}
          />
        </>
      );
    }
  } else if (error) {
    renderChatBox = (
      <>
        <img
          src="./images/agent.jpg"
          alt="agent"
          width="360px"
          style={{ borderRadius: ".5em", alignSelf: "center" }}
        />
        <MDTypography mt={1} textAlign="center" color="light">
          {error.message}
        </MDTypography>
      </>
    );
  }

  return (
    <MDBox display="flex" flexDirection="column" height="calc(100vh - 50px)">
      <Card sx={{ flex: 1, overflow: "auto" }}>
        <MDBox
          p={2}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          {renderChatBox}
          <Backdrop
            open={(!messages && !error) || isChatClosing}
            sx={{
              position: "absolute",
              borderRadius: borders.borderRadius.xl,
              backgroundColor: "rgba(0,0,0,.35)",
            }}
          >
            <CircularProgress color="info" />
          </Backdrop>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default AgentChatPanel;
