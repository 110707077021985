import React, { useEffect, useState } from "react";
import Footer from "features/Footer";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLDashboardLayout from "../CTLDashboardLayout";
import {
  useMaterialUIController,
  setLayout,
  setActivePage,
  setClickToCall,
  setOpenPhone,
  setSearchText,
} from "context";
import CTLNavbar from "../CTLNavBar";
import layouts from "globals/layouts";
import loggerFactory from "globals/logger/logger-factory";
import { QueryClient, QueryClientProvider } from "react-query";
import { application, externalCallServer } from "globals/endpoints";
import MDBox from "components/MDBox";
import SIPPhoneService from "../SIPPhone/SIPPhoneService";
import { Grid } from "@mui/material";
import MAPPhone from "../Map/MAPPhone";

const queryClient = new QueryClient();

const registeredCallbacks = {
  openAdvancedFilter: null,
};

function DefaultAuthorisedPage() {
  const componentName = "DefaultAuthorisedPage";
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, searchText, AgentData } = controller;
  const [mode, setMode] = useState("0");

  const [pageAttributes, setPageAttributes] = useState({
    enableAddButton: false,
    enableSendButton: false,
    enableSyncButton: false,
    enableSipSelect: false,
    enableNMSSelect: false,
    enableAddAgentButton: false,
    enableDefaulComparisontButton: false,
    enableNoDefaultComparisonButton: false,
    enableAllList: false,
    enablePrintButton: false,
    enableFileExportButton: false,
    enableFileImportButton: false,
    enableFilePreViewButton: false,
    enablePriPublish: false,
    enablePaste: false,
    enableChatButton: false,
    enableMarkasReadButton: false,
    enableMultipleDeleteButton: false,
    enableDownloadButton: false,
    enableUploadFaxButton: false,
    enableCollapsibleMenu: false,
    enableDownloadPdf: false,
    enableUpdateTableRecords: false,
    enableMultipleUpdate: false,
    enableApiAccess: false,
    enableExitButton: false,
    enableExternalApp: false,
    enableRefresh: false,
    enableViewAll: false,
    modes: [],
  });

  useEffect(() => {
    setLayout(
      dispatch,
      AuthenticationService.isAuthenticated() ? "dashboard" : "page"
    );
  }, []);
  function onLoad(attributes) {
    setPageAttributes(attributes);
  }

  function handleModeChange(mode) {
    setMode(mode);
  }

  function ExitPage() {
    if (registeredCallbacks.ExitPage) {
      registeredCallbacks.ExitPage();
    }
  }

  function handleSearchTextChange(text) {
    setSearchText(dispatch, text);
  }

  function CollapsibleMenuOpen() {
    if (registeredCallbacks.CollapsibleMenuOpen) {
      registeredCallbacks.CollapsibleMenuOpen();
    }
  }

  function PriPublish() {
    if (registeredCallbacks.PriPublish) {
      registeredCallbacks.PriPublish();
    }
  }
  function onRefresh() {
    if (registeredCallbacks.onRefresh) {
      registeredCallbacks.onRefresh();
    }
  }
  function onViewAll() {
    if (registeredCallbacks.onViewAll) {
      registeredCallbacks.onViewAll();
    }
  }

  function Paste() {
    if (registeredCallbacks.Paste) {
      registeredCallbacks.Paste();
    }
  }

  function MultipleUpdate() {
    if (registeredCallbacks.MultipleUpdate) {
      registeredCallbacks.MultipleUpdate();
    }
  }
  function DownloadPdf() {
    if (registeredCallbacks.DownloadPdf) {
      registeredCallbacks.DownloadPdf();
    }
  }

  function UpdateTableRecords() {
    if (registeredCallbacks.UpdateTableRecords) {
      registeredCallbacks.UpdateTableRecords();
    }
  }
  function DownloadData() {
    if (registeredCallbacks.DownloadData) {
      registeredCallbacks.DownloadData();
    }
  }

  function DownloadSelected() {
    if (registeredCallbacks.DownloadSelected) {
      registeredCallbacks.DownloadSelected();
    }
  }
  function onAdvancedFilter() {
    if (registeredCallbacks.openAdvancedFilter) {
      registeredCallbacks.openAdvancedFilter();
    }
  }

  function onOpenForm() {
    if (registeredCallbacks.openForm) {
      registeredCallbacks.openForm();
    }
  }

  function onPublish() {
    if (registeredCallbacks.onPublish) {
      registeredCallbacks.onPublish();
    }
  }
  function onChat() {
    if (registeredCallbacks.onChat) {
      registeredCallbacks.onChat();
    }
  }

  function onUploadFax() {
    if (registeredCallbacks.onUploadFax) {
      registeredCallbacks.onUploadFax();
    }
  }

  function onFilePreView() {
    if (registeredCallbacks.onFilePreView) {
      registeredCallbacks.onFilePreView();
    }
  }

  function onFileExport() {
    if (registeredCallbacks.onFileExport) {
      registeredCallbacks.onFileExport();
    }
  }

  function onFileImport() {
    if (registeredCallbacks.onFileImport) {
      registeredCallbacks.onFileImport();
    }
  }

  function onSendMessage() {
    if (registeredCallbacks.onSendMessage) {
      registeredCallbacks.onSendMessage();
    }
  }
  function onMarkasRead() {
    if (registeredCallbacks.onMarkasRead) {
      registeredCallbacks.onMarkasRead();
    }
  }
  function onMultipleDelete() {
    if (registeredCallbacks.onMultipleDelete) {
      registeredCallbacks.onMultipleDelete();
    }
  }
  function onSync() {
    if (registeredCallbacks.onSync) {
      registeredCallbacks.onSync();
    }
  }

  function onNMSSelect() {
    if (registeredCallbacks.onNMSSelect) {
      registeredCallbacks.onNMSSelect();
    }
  }

  function onAgent() {
    if (registeredCallbacks.onAgent) {
      registeredCallbacks.onAgent();
    }
  }
  function onAllList() {
    if (registeredCallbacks.onAllList) {
      registeredCallbacks.onAllList();
    }
  }
  function onDefaultComparison() {
    if (registeredCallbacks.onDefaultComparison) {
      registeredCallbacks.onDefaultComparison();
    }
  }
  function onNoDefaultComparison() {
    if (registeredCallbacks.onNoDefaultComparison) {
      registeredCallbacks.onNoDefaultComparison();
    }
  }

  function onPrint() {
    if (registeredCallbacks.onPrint) {
      registeredCallbacks.onPrint();
    }
  }
  function onEnableApiAccess() {
    if (registeredCallbacks.onEnableApiAccess) {
      registeredCallbacks.onEnableApiAccess();
    }
  }

  function onCall(dialNumber) {
    if (dialNumber) {
      if (
        AuthenticationService.getDeskPhoneStatus() ||
        !SIPPhoneService ||
        !SIPPhoneService.enabled
      ) {
        if (window.callServer) {
          externalCallServer.post("/externalApi/call", {
            caller: AuthenticationService.getExtensionNumber(),
            callee: dialNumber,
            predefinedAction: window.callServerPredefinedAction,
            callerUser: AuthenticationService.getFullName(),
          });
        } else {
          application.post("/externalApi/call", {
            caller: AuthenticationService.getExtensionNumber(),
            callee: dialNumber,
            predefinedAction: window.callServerPredefinedAction,
            callerUser: AuthenticationService.getFullName(),
          });
        }
      } else {
        {
          !AuthenticationService.allowAgentMenus() &&
            !AuthenticationService.allowUCConferenceMenus() &&
            setOpenPhone(dispatch, true);
        }
        setClickToCall(dispatch, dialNumber);
        loggerFactory.debug(componentName, "Click to call : ", dialNumber);
      }
    }
  }

  function registerCallBacks(callbacks) {
    registeredCallbacks.openAdvancedFilter = callbacks.openAdvancedFilter;
    registeredCallbacks.openForm = callbacks.openForm;
    registeredCallbacks.onPublish = callbacks.onPublish;
    registeredCallbacks.onChat = callbacks.onChat;
    registeredCallbacks.onFileExport = callbacks.onFileExport;
    registeredCallbacks.onFileImport = callbacks.onFileImport;
    registeredCallbacks.onFilePreView = callbacks.onFilePreView;
    registeredCallbacks.onSendMessage = callbacks.onSendMessage;
    registeredCallbacks.onMultipleDelete = callbacks.onMultipleDelete;
    registeredCallbacks.onMarkasRead = callbacks.onMarkasRead;
    registeredCallbacks.onSync = callbacks.onSync;
    registeredCallbacks.onAgent = callbacks.onAgent;
    registeredCallbacks.onAllList = callbacks.onAllList;
    registeredCallbacks.onDefaultComparison = callbacks.onDefaultComparison;
    registeredCallbacks.onNoDefaultComparison = callbacks.onNoDefaultComparison;
    registeredCallbacks.onPrint = callbacks.onPrint;
    registeredCallbacks.PriPublish = callbacks.PriPublish;
    registeredCallbacks.Paste = callbacks.Paste;
    registeredCallbacks.onRefresh = callbacks.onRefresh;
    registeredCallbacks.onViewAll = callbacks.onViewAll;
    registeredCallbacks.DownloadPdf = callbacks.DownloadPdf;
    registeredCallbacks.DownloadData = callbacks.DownloadData;
    registeredCallbacks.CollapsibleMenuOpen = callbacks.CollapsibleMenuOpen;
    registeredCallbacks.DownloadSelected = callbacks.DownloadSelected;
    registeredCallbacks.onUploadFax = callbacks.onUploadFax;
    registeredCallbacks.MultipleUpdate = callbacks.MultipleUpdate;
    registeredCallbacks.onEnableApiAccess = callbacks.onEnableApiAccess;
    registeredCallbacks.ExitPage = callbacks.ExitPage;
    registeredCallbacks.UpdateTableRecords = callbacks.UpdateTableRecords;
  }
  const renderComponent = (currentPage) => {
    let returnValue = null;

    layouts.map((layout) => {
      if (layout.pageId == currentPage) {
        returnValue = layout.component;
      }
    });

    if (!returnValue) {
      if (AuthenticationService.allowAdminMenus()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowhmsAdminMenus()) {
        return null;
      } else if (AuthenticationService.allowkmAdminMenus()) {
        return null;
      } else if (
        AuthenticationService.allownmsMenus() &&
        !AuthenticationService.allowRailwayDispatcher()
      ) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowkanAdminMenus()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowsurakshaMenus()) {
        activePage.key = "nms.maps";
        activePage.name = "Suraksha";
        activePage.url = "home/app/nms/map";
        activePage.description = "MAP";
      } else if (AuthenticationService.allowUCMenus()) {
        activePage.key = "menu-dashboard";
        activePage.name = "Menu's Dashboard";
        activePage.url = "/app/menudashboard/home";
        activePage.description = "Menu Dashboard";
      } else if (AuthenticationService.allowVoicePickerMenus()) {
        activePage.key = "menu-dashboard";
        activePage.name = "Menu's Dashboard";
        activePage.url = "/app/menudashboard/home";
        activePage.description = "Menu Dashboard";
      } else if (AuthenticationService.allowAgentMenus()) {
        activePage.key = "agentDashboard";
        activePage.name = "Agent Dashboard";
        activePage.url = "home/app/agent/dashboard";
        activePage.description = "Agent Dashboard";
      } else if (AuthenticationService.allowDashboardConfigMenus()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowCallcenterTabMenus()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowGroupAdmin()) {
        activePage.key = "menu-dashboard";
        activePage.name = "Menu's Dashboard";
        activePage.url = "home/app/home";
        activePage.description = "Menu Dashboard";
      } else if (AuthenticationService.allowUCConferenceMenus()) {
        activePage.key = "uc.conference.calendar";
        activePage.name = "Conference Calendar";
        activePage.url = "home/app/Conference Calendar";
        activePage.description = "Conference Calendar";
      } else if (AuthenticationService.allowPAMenu()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else if (AuthenticationService.allowEngineerMenu()) {
        activePage.key = "PendingComplaintEngineer";
        activePage.name = "Pending Complaints with Engineer";
        activePage.url = "home/app/cms-reports/pending-complaint-with-engineer";
        activePage.description = "Pending Complaint with Engineer";
      } else if (AuthenticationService.allowRailwayDispatcher()) {
        activePage.key = "nms.maps";
        activePage.name = "Suraksha";
        activePage.url = "home/app/map";
        activePage.description = "MAP";
      } else if (AuthenticationService.allowDepartmentHeadMenu()) {
        return null;
      } else if (AuthenticationService.allowTaskUserManager()) {
        activePage.key = "userTask";
        activePage.name = "User Control Task Manager";
        activePage.url = "home/app/taskManagementSystem";
        activePage.description =
          "User View Task Details, and update their status";
      } else if (AuthenticationService.allowTaskManager()) {
        activePage.key = "dashboard.controller";
        activePage.name = "dashboardController";
        activePage.url = "/app/dashboardController/home";
        activePage.description = "Dashboard Controller";
      } else {
        activePage.key = "uc.missed-calls";
        activePage.name = "Missed calls";
        activePage.url = "home/app/uc/missed-calls";
        activePage.description = "Missed incoming calls";
      }

      setActivePage(dispatch, activePage);
    }

    return returnValue;
  };

  let componentToRender = renderComponent(activePage.key);

  return (
    <CTLDashboardLayout>
      <QueryClientProvider client={queryClient}>
        <>
          <CTLNavbar
            pageAttributes={pageAttributes}
            onModeSelected={handleModeChange}
            onSearchTextChange={handleSearchTextChange}
            onAdvancedFilter={onAdvancedFilter}
            onOpenForm={onOpenForm}
            onPublish={onPublish}
            onChat={onChat}
            onFileExport={onFileExport}
            onFileImport={onFileImport}
            onSendMessage={onSendMessage}
            onMultipleDelete={onMultipleDelete}
            onMarkasRead={onMarkasRead}
            onSync={onSync}
            onNMSSelect={onNMSSelect}
            onAgent={onAgent}
            onAllList={onAllList}
            onDefaultComparison={onDefaultComparison}
            onNoDefaultComparison={onNoDefaultComparison}
            onPrint={onPrint}
            onFilePreView={onFilePreView}
            PriPublish={PriPublish}
            Paste={Paste}
            onRefresh={onRefresh}
            onViewAll={onViewAll}
            DownloadPdf={DownloadPdf}
            DownloadData={DownloadData}
            CollapsibleMenuOpen={CollapsibleMenuOpen}
            DownloadSelected={DownloadSelected}
            onUploadFax={onUploadFax}
            UpdateTableRecords={UpdateTableRecords}
            MultipleUpdate={MultipleUpdate}
            onEnableApiAccess={onEnableApiAccess}
            ExitPage={ExitPage}
          />
          {AuthenticationService.allowRailwayDispatcher() ? (
            <MDBox sx={{ mt: 3 }}>
              <Grid container spacing={1}>
                <Grid item xs={9.5}>
                  {componentToRender &&
                    React.createElement(componentToRender, {
                      id: activePage.appId,
                      onLoad: onLoad,
                      mode: mode,
                      pageId: activePage.key,
                      searchText: searchText,
                      registerCallBacks: registerCallBacks,
                      onCall: onCall,
                      pageName: activePage.name,
                    })}
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  style={{
                    minHeight: "80vh",
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  <MAPPhone />
                </Grid>
              </Grid>
            </MDBox>
          ) : (
            <MDBox sx={{ mt: 3 }}>
              {componentToRender &&
                React.createElement(componentToRender, {
                  id: activePage.appId,
                  onLoad: onLoad,
                  mode: mode,
                  pageId: activePage.key,
                  searchText: searchText,
                  registerCallBacks: registerCallBacks,
                  onCall: onCall,
                  pageName: activePage.name,
                })}
            </MDBox>
          )}
          <Footer />
        </>
      </QueryClientProvider>
    </CTLDashboardLayout>
  );
}

export default DefaultAuthorisedPage;
