import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import { setTaskIsDelay } from "context";
import TaskManagementDashboard from "../taskManagementDashboard";
import { Divider } from "@mui/material";

function UserTask({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue, taskIsDelay, taskFileData, taskStatus } =
    controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onRefresh: onRefresh,
      });
  }, [activePage]);

  useEffect(() => {
    if (taskStatus) {
      setAdvancedFilters([
        {
          direction: "from",
          dataType: "string",
          fieldName: "currentStats",
          value: taskStatus,
        },
        {
          direction: "to",
          dataType: "string",
          fieldName: "currentStats",
          value: taskStatus,
        },
      ]);
      setUpdateList(Date.now());
    } else {
      setAdvancedFilters([]);
    }
  }, [taskStatus]);

  function onRefresh() {
    setUpdateList(Date.now());
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    const taskStatus = row.currentStats;
    const taskCompletedDate = row.taskCompletedOn;
    const parseDateString = (dateString) => {
      const dateRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/;
      const match = dateString.match(dateRegex);
      if (!match) return null;

      const [_, year, month, day, hour, minute] = match;
      const isoString = `${year}-${month}-${day}T${hour}:${minute}:00`;
      return isoString;
    };
    const changedCompletionOn = row.changedCompletionOn
      ? parseDateString(row.changedCompletionOn)
      : null;

    if (row?.isDelay !== undefined) {
      setTaskIsDelay(dispatch, row.isDelay);
    }
    setFormData({
      ...row,
      changedCompletionOn: changedCompletionOn,
    });
    if (taskStatus === "Closed" && taskCompletedDate) {
      setNotify({
        isOpen: true,
        status: `This task was completed on ${taskCompletedDate}`,
        message: `Its status is "Closed" You cannot update a closed task`,
        type: "error",
        pagename: "Task Management System",
      });
    } else {
      setOpenForm(true);
    }
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    console.log(row);
    application
      .post(operationURLS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.tasksName) {
      let changedCompletionOn = "";
      let expectedCompletionDate = "";

      if (data.changedCompletionOn) {
        const dateParts = data.changedCompletionOn.split("T");
        expectedCompletionDate = `${dateParts[0]} ${dateParts[1].slice(0, 5)}`;
        changedCompletionOn = formData.expectedCompletionDate || "";
      }

      const modifiedData = {
        ...data,
        changedCompletionOn: changedCompletionOn || "",
        expectedCompletionDate:
          expectedCompletionDate || formData.expectedCompletionDate,
        filePath: taskFileData,
        isDelay: data.isDelay === true,
      };

      if (data.currentStats === "Closed") {
        modifiedData.currentStats = "Closed";
      }
      application
        .post(operationURLS.update, modifiedData)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            status: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
          setOpenForm(false);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      setOpenForm(false);
    }
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  return (
    <MDBox>
      <TaskManagementDashboard entityName="User Task Management" />
      <Divider />
      <DeleteConfirmation
        entityName="User Task Management"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="User Task Management"
        entityDiscription={formData.tasksName}
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="createdOn"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        disablePagination={false}
      />
      <CTLAdvancedFilter
        entityName="User Task Management"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default UserTask;
