import { createApi } from "@reduxjs/toolkit/query/react";
import { application } from "globals/endpoints";

export const dynamicFormApi = createApi({
  reducerPath: "dynamicFormApi",
  baseQuery: async (args) => {
    try {
      const result = await application(args);
      return result;
    } catch (axiosError) {
      return { error: axiosError.response?.data || axiosError.message };
    }
  },
  tagTypes: ["saveForm"],
  endpoints: (builder) => ({
    getFormData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["saveForm"],
    }),
    saveFormData: builder.mutation({
      query: ({ content, path }) => ({
        url: path,
        method: "POST",
        data: {
          content,
        },
        headers: {
          "Content-type": "application/json;",
        },
      }),
      invalidatesTags: ["saveForm"],
    }),
    getDropdownData: builder.mutation({
      query: (path) => {
        return {
          url: path,
          method: "POST",
          headers: {
            "Content-type": "application/json;",
          },
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
    }),
  }),
});

export const {
  useGetFormDataQuery,
  useSaveFormDataMutation,
  useGetDropdownDataMutation,
} = dynamicFormApi;
