import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";
import { setTaskStatus } from "context";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

export default function TaskManagementDashboard({ entityName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { taskStatus } = controller;

  const options = [
    { taskStatus: "Pending", value: "Pending" },
    { taskStatus: "InProgress", value: "inProgress" },
    { taskStatus: "PendingForApproval", value: "pendingForApproval" },
    { taskStatus: "Closed", value: "closed" },
    { taskStatus: "Delayed", value: "Delayed" },
  ];

  useEffect(() => {
    setTaskStatus(dispatch, "");
  }, [dispatch]);

  const handleChange = (event, value) => {
    if (value) {
      setTaskStatus(dispatch, value.taskStatus);
    } else {
      setTaskStatus(dispatch, null);
    }
  };

  let Dispatcher = AuthenticationService.allowRailwayDispatcher();
  let TaskLeader = AuthenticationService.allowTaskManager();

  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        <MDTypography
          variant="h3"
          sx={{ fontWeight: "bold", color: "error.main", whiteSpace: "nowrap" }}
        >
          {Dispatcher
            ? "Dispatcher Task Management"
            : TaskLeader
            ? "TeamLeader Task Management"
            : entityName}
        </MDTypography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 2, height: "2rem", backgroundColor: "gray" }}
        />
      </MDBox>
      <Autocomplete
        disablePortal
        options={options}
        getOptionLabel={(option) => option.taskStatus || "Select"}
        isOptionEqualToValue={(option, value) => option.taskStatus === value}
        sx={{ width: 300 }}
        value={
          taskStatus
            ? options.find((option) => option.taskStatus === taskStatus)
            : null
        }
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Task Status" />}
      />
    </MDBox>
  );
}
