import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  enableAddButton: true,
  refetchInterval: 5000,
  enableMultipleDeleteButton: true,
  enableAdvanceSearch: false,
  ButtonNames: "Multiple Delete",
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "userLocation/list",
  delete: "userLocation/delete",
  update: "userLocation/update",
  create: "userLocation/create",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "User",
      accessor: "username",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Location Name",
      accessor: "locationName",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Location Code ",
      accessor: "locationCodes",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        accessorKeyField: "id",
        accessorValueField: "description",
        keyField: "locationCode",
        keyDescription: "locationName",
        values: [],
        url: "location/dropdown/list",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          // onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
