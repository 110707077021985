import React, { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import { Box, Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@material-ui/core";
import EmptyImage from "../../../../src/assets/images/mediacenter/emptyFolder.svg";
import {
  xmpp,
  domain,
  getCurrentStatus,
  getOnlineUsers,
  uniqueResource,
} from "./xmppClient";
import { xml } from "@xmpp/client";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";
import CTLNotification from "application/components/CTLNotification";
import AuthenticationService from "../authentication/AuthenticationService";
import Conversation from "./conversation";
import UserGroupList from "./userGroupList";

const Attributes = {
  modes: [],
};

const ChatComponent = ({ onLoad, pageId, searchText, registerCallBacks }) => {
  const [messages, setMessages] = useState([]);
  const [selectUser, setSelectUser] = useState("");
  const [selectGroup, setSelectGroup] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode, activePage, chatNotification } = controller;

  
  const [presence, setPresence] = useState(getOnlineUsers());
  const fileRef = useRef(null);
  const fileNameRef = useRef(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  useEffect(() => {
    handleSelectList(chatNotification.userName);
  }, [chatNotification]);

  useEffect(() => {
    const updatePresence = () => {
      setPresence(getOnlineUsers());
    };
    updatePresence();
    const intervalId = setInterval(updatePresence, 1000);
    return () => clearInterval(intervalId);
  }, []);

 

  const fetchChatHistory = async (selectedUser) => {
    const queryId = "mam-query";
    const mamQueryXML = xml(
      "iq",
      { type: "set", id: queryId },
      xml(
        "query",
        { xmlns: "urn:xmpp:mam:2" },
        xml(
          "x",
          { xmlns: "jabber:x:data", type: "submit" },
          xml(
            "field",
            { var: "FORM_TYPE", type: "hidden" },
            xml("value", {}, "urn:xmpp:mam:2")
          ),
          xml(
            "field",
            { var: "with" },
            xml("value", {}, `${selectedUser}@${domain}`)
          )
        )
      )
    );
    if (xmpp) {
      xmpp.send(mamQueryXML);
    }
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const parts = date.toLocaleDateString([], options).split(" ");
    return `${parts[1]} ${parts[0]} ${parts[2]}`;
  };

  useEffect(() => {
    const handleMessage = (stanza) => {
      if (stanza.is("message")) {
        const resultElement = stanza.getChild("result", "urn:xmpp:mam:2");
        if (resultElement) {
          const forwardedElement = resultElement.getChild(
            "forwarded",
            "urn:xmpp:forward:0"
          );
          if (forwardedElement) {
            const messageElement = forwardedElement.getChild(
              "message",
              "jabber:client"
            );
            if (messageElement) {
              const bodyElement = messageElement.getChild("body");
              if (bodyElement) {
                const bodyContent = bodyElement.getText();
                if (bodyContent) {
                  try {
                    const sanitizedContent = bodyContent.replace(
                      /[\x00-\x1F\x7F]/g,
                      ""
                    );
                    const data = JSON.parse(sanitizedContent);
                    if (
                      data &&
                      data.type &&
                      data.text &&
                      data.from &&
                      data.id &&
                      data.time &&
                      data.date &&
                      data.contentType
                    ) {
                      const newMessage = {
                        type: data.type,
                        text: data.text,
                        from: data.from,
                        id: data.id,
                        time: data.time,
                        date: data.date,
                        contentType: data.contentType,
                      };

                      setMessages((prevMessages) => {
                        const isDuplicate = prevMessages.some(
                          (msg) => msg.id === newMessage.id
                        );
                        if (!isDuplicate) {
                          return [...prevMessages, newMessage];
                        }
                        return prevMessages;
                      });
                    }
                  } catch (error) {
                    loggerFactory.debug(
                      "Failed to parse message body as JSON:",
                      error
                    );
                  }
                }
              }
            }
          }
        }
      }
      if (stanza.is("message")) {
        const messageBody = stanza.getChildText("body");
        const messageId = stanza.attrs.id;
        if (messageBody) {
          const data = JSON.parse(messageBody);
          const newMessage = {
            type: data.type,
            text: data.text,
            from: data.from,
            id: data.id || messageId,
            time: data.time,
            date: data.date,
            contentType: data.contentType,
          };
          setMessages((prevMessages) => {
            const isDuplicate = prevMessages.some(
              (msg) => msg.id === newMessage.id
            );
            if (!isDuplicate) {
              return [...prevMessages, newMessage];
            }
            return prevMessages;
          });
        }
      }
    };

    if (xmpp) {
      xmpp.on("stanza", handleMessage);
    }

    return () => {
      if (xmpp) {
        xmpp.removeListener("stanza", handleMessage);
      }
    };
  }, [xmpp]);

  const joinGroup = async (groupName) => {
    if (xmpp && xmpp.status === "online") {
      try {
        const presence = xml(
          "presence",
          { to: `${groupName}@conference.coraltele.com/${uniqueResource}` },
          xml("x", { xmlns: "http://jabber.org/protocol/muc" })
        );
        await xmpp.send(presence);
        loggerFactory.info(
          `Joined xmpp group ${groupName} successfully.`,
          AuthenticationService.getFullName()
        );
      } catch (error) {
        loggerFactory.error(`Failed to join xmpp group ${groupName}:`, error);
      }
    } else {
      loggerFactory.error("XMPP is not connected.");
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await application.post("file/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setNotify({
        isOpen: true,
        message: "",
        type: "success",
        pagename: "Chat",
        status: "File Sent Successfully",
      });
      return response.data;
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: "Chat",
        status: "Unable to Send File",
      });
      loggerFactory.debug("File upload failed:", error);
      return null;
    }
  };

  const downloadFile = async (filePath) => {
    try {
      const url = `file/download?filePath=${filePath}`;
      const response = await application.get(url, {
        responseType: "blob",
      });

      if (response.status !== 200) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }
      setNotify({
        isOpen: true,
        message: "",
        type: "success",
        pagename: "Chat",
        status: "File Download In Progress...",
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filePath.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      loggerFactory.debug("Error during file download:", error);
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: "Chat",
        status: "Unable To Download File",
      });
    }
  };

  const sendMessage = async () => {
    if (messageInput.trim() === "" && !fileRef.current) return;
    const messageId = Math.random().toString(36).substring(7);
    const jid = `${AuthenticationService.getFullName()}@${domain}`;
    try {
      if (fileRef.current) {
        const filePath = await uploadFile(fileRef.current);
        if (filePath) {
          const fileName = filePath;

          const fileData = {
            id: messageId,
            text: fileName,
            from: jid,
            time: formatTime(new Date()),
            date: formatDate(new Date()),
            type: selectUser ? "file" : "groupchat",
            contentType: "groupfile",
          };
          const json = JSON.stringify(fileData);
          const fileXML = xml(
            "message",
            {
              type: selectUser ? "file" : "groupchat",
              to: selectUser
                ? `${selectUser}@${domain}`
                : `${selectGroup}@conference.coraltele.com`,
              id: messageId,
              contentType: "groupfile",
              from: jid,
            },
            xml("body", {}, json)
          );
          await xmpp.send(fileXML);
          const newMessage = {
            id: messageId,
            type: selectUser ? "file" : "groupchat",
            text: fileName,
            from: jid,
            contentType: "groupfile",
            time: formatTime(new Date()),
            date: formatDate(new Date()),
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      }

      if (messageInput.trim() !== "") {
        const messageData = {
          id: messageId,
          text: messageInput,
          from: jid,
          time: formatTime(new Date()),
          date: formatDate(new Date()),
          type: selectUser ? "chat" : "groupchat",
          contentType: "groupchat",
        };
        const json = JSON.stringify(messageData);
        const messageXML = xml(
          "message",
          {
            type: selectUser ? "chat" : "groupchat",
            to: selectUser
              ? `${selectUser}@${domain}`
              : `${selectGroup}@conference.coraltele.com`,
            id: messageId,
            contentType: "groupchat",
            from: jid,
          },
          xml("body", {}, json)
        );

        await xmpp.send(messageXML);
        const newMessage = {
          type: selectUser ? "chat" : "groupchat",
          id: messageId,
          text: messageInput,
          from: jid,
          contentType: "groupchat",
          time: formatTime(new Date()),
          date: formatDate(new Date()),
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
      setMessageInput("");
      fileRef.current = null;
      fileNameRef.current = null;
    } catch (error) {
      loggerFactory.debug("Failed to send message:", error);
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: "Chat",
        status: "Failed to send message",
      });
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      fileNameRef.current = file.name;
      fileRef.current = file;
      await sendMessage();
    }
  };

  const handleBack = () => {
    setSelectUser("");
    setSelectGroup("");
  };

  const handleSelectList = (name) => {
    setSelectGroup("");
    setMessages([]);
    fetchChatHistory(name);
    setSelectUser(name);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(80vh)",
      overflow: "auto",
      position: "relative",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
    },
    messageContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      marginBottom: "10px",
    },
    senderMessage: {
      alignSelf: "flex-end",
      textAlign: "right",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "5px 10px",
      marginBottom: "5px",
      background: darkMode ? "#1e81b0" : "#b7c4e8",
    },
    receiverMessage: {
      alignSelf: "flex-start",
      textAlign: "left",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "5px 10px",
      marginBottom: "5px",
      background: darkMode ? "#063970" : "#76b5c5",
    },
    time: {
      fontSize: "0.8rem",
      color: "#999",
      marginTop: "5px",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "auto",
    },
    textField: {
      flexGrow: 1,
      marginRight: "10px",
    },
  }));
  const classes = useStyles();

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <UserGroupList
            useStyles={useStyles}
            selectUser={selectUser}
            selectGroup={selectGroup}
            setSelectGroup={setSelectGroup}
            setSelectUser={setSelectUser}
            setMessages={setMessages}
            handleSelectList={handleSelectList}
            joinGroup={joinGroup}
          />
        </Grid>
        <Grid item xs={9}>
          {selectUser || selectGroup ? (
            <Conversation
              useStyles={useStyles}
              messages={messages}
              selectUser={selectUser}
              selectGroup={selectGroup}
              messageInput={messageInput}
              setMessageInput={setMessageInput}
              handleBack={handleBack}
              handleFileSelect={handleFileSelect}
              sendMessage={sendMessage}
              downloadFile={downloadFile}
            />
          ) : (
            <Card className={classes.root}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "89vh" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <img src={EmptyImage} alt="Empty Folder" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDTypography variant="h5">
                    Select a user to start chatting
                  </MDTypography>
                </Box>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
};

export default ChatComponent;
