import { closeSnackbar, SnackbarProvider, useSnackbar } from "notistack";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import NotificationManager from "globals/websocket/WebSocket";
import { useEffect, useRef, useCallback, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import { navbarIconButton } from "features/Navbars/DashboardNavbar/styles";
import { setTransparentNavbar } from "context";
import { useMaterialUIController } from "context";
import { telemetry } from "globals/endpoints";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CTLDate from "../CTLDate";
import { application } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";

const SocketEventNotification = ({ light }) => {
  const notificationSound = useRef(new Audio("/sounds/notificationSound.wav"));
  const { enqueueSnackbar } = useSnackbar();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [alert, setAlert] = useState(null);

  const playNotificationSound = () => {
    notificationSound.current.play().catch((err) => {
      console.error("Error playing notification sound:", err);
    });
  };

  const fetchNotificationList = useCallback(() => {
    application
      .post("eventNotify/list")
      .then((response) => {
        setMenuItems(response.data.data || []);
        loggerFactory.debug("Successfully get notification list");
      })
      .catch((error) => {
        setMenuItems([]);
        loggerFactory.debug("Unable to get Notification list", error);
      });
  }, []);

  const notificationUpdate = (data) => {
    const requestBody = { intId: [data.id] };
    application
      .post("eventNotify/update", requestBody)
      .then((response) => {
        fetchNotificationList();
        loggerFactory.debug("Successfully update notification clear", response);
      })
      .catch((error) => {
        loggerFactory.error("Unable to update notification clear", error);
      });
  };

  useEffect(() => {
    fetchNotificationList();
    const intervalId = setInterval(() => {
      fetchNotificationList();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [fetchNotificationList]);

  const UpdateMessage = useCallback((message) => {
    if (message) {
      const Event = JSON.parse(message.body);
      const notificationText = Event.eventMessage;
      const severityVariantMap = {
        0: "success",
        1: "info", 
        2: "warning", 
        3: "error", 
        4: "error", 
      };
      const variant = severityVariantMap[Event.eventSeverity] || "info";
      enqueueSnackbar(notificationText, {
        variant,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      playNotificationSound();
    }
  });


  useEffect(() => {
    const username = AuthenticationService.getUserName();

    NotificationManager.receiveMessageForService(
      `user-${username}`,
      UpdateMessage,
      "services-events"
    );

    NotificationManager.receiveMessageForTelemetry(
      `user-${username}`,
      UpdateMessage,
      "telemetry-events"
    );
  }, []);

  const clearAllNotification = () => {
    const listAllIds = menuItems && menuItems.map((item) => item.id);
    application
      .post("eventNotify/update", { intId: listAllIds })
      .then((response) => {
        fetchNotificationList();
        loggerFactory.debug("Successfully update notification clear", response);
      })
      .catch((error) => {
        loggerFactory.error("Unable to update notification clear", error);
      });
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const clearNotification = () => {
    notificationUpdate(selectedItem);
    setSelectedItem(null);
  };

  const iconsStyle = {
    color: setTransparentNavbar
      ? darkMode
        ? "rgba(255, 255, 255, 0.6)"
        : "rgba(0, 0, 0, 0.6)"
      : light || darkMode
      ? "#ffffff"
      : "#000000",
  };

  const handleClick = (event) => {
    fetchNotificationList();
    setAlert(event.currentTarget);
  };

  const handleCloseAlert = () => {
    setAlert(null);
    setSelectedItem(null);
  };

  return (
    <>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <Tooltip title="Notifications">
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            onClick={handleClick}
          >
            <MDBadge
              color="error"
              badgeContent={menuItems.length}
              max={99}
              size="xs"
              circular
            >
              <Icon sx={iconsStyle}>notifications_active_icon</Icon>
            </MDBadge>
          </IconButton>
        </Tooltip>
        <Menu
          id="styled-menu"
          anchorEl={alert}
          keepMounted
          open={Boolean(alert)}
          onClose={handleCloseAlert}
          PaperProps={{
            style: {
              maxHeight: "500px",
              minWidth: "400px",
              background: "#f5f5f5",
            },
          }}
        >
          {selectedItem == null ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  padding: "10px",
                  backdropFilter: "blur(10px)",
                }}
              >
                <MDTypography
                  variant="h4"
                  sx={{
                    flexGrow: 1,
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  Notification List
                </MDTypography>
                {menuItems.length != 0 && (
                  <IconButton
                    color="warning"
                    size="small"
                    onClick={() => clearAllNotification()}
                  >
                    Clear All
                  </IconButton>
                )}
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    onClick={() => handleCloseAlert()}
                    style={{
                      marginLeft: "20px",
                      cursor: "pointer",
                      zIndex: 3,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {menuItems.length != 0 ? (
                menuItems.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: "100%",
                      marginBottom: "8px",
                      boxShadow: 3,
                      position: "relative",
                      "&:hover": {
                        boxShadow: 6,
                      },
                    }}
                  >
                    {" "}
                    <Tooltip title="Clear Notification">
                      <DeleteForeverIcon
                        onClick={() => notificationUpdate(item)}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: 8,
                          transform: "translateY(-50%)",
                          color: "red",
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        size="large"
                      />
                    </Tooltip>
                    <MenuItem
                      onClick={() => handleItemClick(item)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        sx={{
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                          borderRadius: "50%",
                          boxShadow: 3,
                          "&:hover": {
                            boxShadow: 6,
                          },
                        }}
                      >
                        <Icon fontSize="large" color="warning">
                          circle_notifications_icon
                        </Icon>
                      </Card>
                      <Typography variant="h6">
                        {" "}
                        {item.eventMessage.length > 30
                          ? item.eventMessage.substring(0, 30) + "..."
                          : item.eventMessage}
                      </Typography>
                    </MenuItem>
                  </Card>
                ))
              ) : (
                <MenuItem>
                  <Typography variant="subtitle2">
                    No Notifications Available
                  </Typography>
                </MenuItem>
              )}
            </>
          ) : (
            <div
              style={{
                position: "relative",
                border: "2px solid gray",
                borderRadius: "4px",
                padding: "16px",
                boxShadow: 5,
                "&:hover": {
                  boxShadow: 6,
                },
              }}
            >
              <Tooltip title="Close">
                <IconButton
                  color="error"
                  onClick={() => setSelectedItem(null)}
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>

              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                <MDTypography variant="h5" gutterBottom>
                  {selectedItem.eventMessage}
                </MDTypography>
                <Divider />
                <MDTypography variant="caption text">
                  Sent By - {selectedItem.eventSource}
                </MDTypography>
                <Divider />
                <MDTypography variant="caption text">
                  Message - {selectedItem.eventData}
                </MDTypography>
                <Divider />
                <MDTypography variant="caption text">
                  <CTLDate value={selectedItem.eventEpoch} />
                </MDTypography>
              </div>

              <MDButton
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  zIndex: 1,
                }}
                color="error"
                onClick={() => clearNotification()}
              >
                Clear
              </MDButton>
            </div>
          )}
        </Menu>
      </SnackbarProvider>
    </>
  );
};

export default SocketEventNotification;
