import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";
import { useState } from "react";

const Attributes = {
  formSize: "md",
  columns: 2,
  enableAddButton: true,
  settingsMode: false,
  enableRefresh: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  update: "inboundGateway/update",
  create: "inboundGateway/create",
  delete: "inboundGateway/delete",
};

const getColumns = (onDelete, onEdit) => {
  const [formField, setFormField] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const [required, setRequired] = useState("");

  const customSelect = (e) => {
    const customValue = e.target.value;
    if (customValue === "customType") {
      setFormField(true);
      setNewValue(true);
      setRequired("");
    } else if (customValue === "sip") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "pri") {
      setFormField(false);
      setNewValue(false);
    } else if (customValue === "internal") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "ngx1") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "rlu") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else {
      setFormField(false);
      setNewValue(false);
    }
  };
  return [
    {
      Header: "Gateway Code",
      accessor: "gwCode",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Gateway Name",
      accessor: "gwName",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Param",
      accessor: "gwParam",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Select Gateway Type",
      accessor: "gwType",
      displayInList: true,
      allowInForm: true,
      onChange: customSelect,
      dataType: "string",
      controlType: "select",
      validation: required,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "pri", description: "PRI" },
          { id: "internal", description: "INTERNAL" },
          { id: "ngx1", description: "NGX" },
          { id: "sip", description: "SIP" },
          { id: "rlu", description: "RLU" },
          { id: "customType", description: "Custom" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Custom Gateway Type",
      accessor: "gwTypeCustom",
      displayInList: false,
      allowInForm: formField,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};
export { getColumns, Attributes, operationURLS };
