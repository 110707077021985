import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { userMenus } from "globals/configuration/user-management";
import { useMaterialUIController, setDisablePass, setIsVipUser } from "context";
import UserMasterCell from "application/components/userMastercell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import UserMasterGroupCell from "application/components/userMasterGroupCell";
import { useState } from "react";

const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  enableMultipleUpdate: true,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : userMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
          disabled: item.disabled,
          productionReady: item.productionReady,
        };
      }),
  settingsMode: false,
};

const operationURLS = {
  list: "user/list",
  update: "user/update",
  create: "user/register",
  delete: "user/delete",
};

const getColumns = (onDelete, onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { disbledPasswordField, isVipUser } = controller;

  const onChangePassword = (event, formik) => {
    setDisablePass(dispatch, event.target.checked);
  };

  const onChangeVip = (event, formik) => {
    if (event.target.checked) {
      setIsVipUser(dispatch, true);
    } else {
      setIsVipUser(dispatch, false);
    }
  };

  return [
    {
      Header: "User ID",
      accessor: "username",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact No./Extension No.",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Active",
      accessor: "enabled",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Is Default Password",
      accessor: "defaultPassword",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangePassword,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Password",
      accessor: "password",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "password",
      disabled: disbledPasswordField,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "Group",
      accessor: "group",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value, row }) => <UserMasterGroupCell value={value} row={row} />,
    },
    {
      Header: "Group",
      accessor: "userGroup",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      sortDisable: true,
      filterControlType: "select",
      filterMode: "stringArray",
      Cell: ({ value, row }) => <UserMasterGroupCell value={value} row={row} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "/userGroupMaster/listAll",
      },
    },
    {
      Header: "Role",
      accessor: "role",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      sortDisable: true,
      controlType: "multipleSelect",
      filterControlType: "select",
      Cell: ({ value, row }) => <UserMasterCell value={value} row={row} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "role/dropdownList",
      },
    },
    {
      Header: "Is VIP",
      accessor: "vip",
      width: 6,
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangeVip,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "VIP Name",
      accessor: "vipName",
      width: 6,
      displayInList: true,
      allowInForm: isVipUser,
      allowFilter: false,
      sortDisable: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Designation",
      accessor: "designation",
      width: 6,
      displayInList: false,
      allowInForm: isVipUser,
      allowFilter: false,
      sortDisable: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Rank",
      accessor: "rank",
      width: 6,
      displayInList: true,
      allowInForm: isVipUser,
      allowFilter: false,
      sortDisable: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      sortDisable: true,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
