import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const groupQuickMenus = [
  {
    icon: (
      <Tooltip title="Menu's Dashboard">
        <Icon fontSize="medium">view_carousel</Icon>
      </Tooltip>
    ),
    name: "Menu's Dashboard",
    key: "menu-dashboard",
    url: "/app/home",
    description: "Menu Dashboard",
  },
  
];

export { groupQuickMenus };
