import DefaultCell from "application/components/DefaultCell";

const Attributes = {
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableExitButton: true,
  enableRefresh: true,
  modes: [],
};

const operationURLS = {
  list: "/fwdExtension/list",
  pdfDownload: "/fwdExtension/generateAndDownloadPdf",
  csvDownload: "/fwdExtension/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Extension",
      accessor: "extension",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "To Time",
      accessor: "toTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Forward Extension Number",
      accessor: "forwardExtension",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Sunday",
      accessor: "sunday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Monday",
      accessor: "monday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tuesday",
      accessor: "tuesday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Wednesday",
      accessor: "wednesday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Thursday",
      accessor: "thursday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Friday",
      accessor: "friday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Saturday",
      accessor: "saturday",
      displayInList: true,
      allowFilter: true,
      dataType: "integer",
      controlType: "boolean",
      filterControlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
