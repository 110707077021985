import DefaultCell from "application/components/DefaultCell";
import DownloadFile from "../../DownloadFile";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAdvanceSearch: true,
  settingsMode: false,
  enableRefresh: true,
  modes: [
    {
      id: "backuprecordingfile",
      name: " Backup And Download",
      url: "home/app/configurations/backup-download-file",
      description: "User Can Backup file download in this  Page ",
    },
  ],
};

const operationURLS = {
  list: "/pdfReport/list",
  update: "monitorService/update",
  create: "monitorService/create",
  delete: "monitorService/delete",
};

const getColumns = (onDelete) => {
  return [
    {
      Header: "Date",
      accessor: "startEpoch",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File Name",
      accessor: "reportName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File Location",
      accessor: "pdfLocation",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => <DownloadFile row={row} value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
