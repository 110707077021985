import { useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import PredefinedFieldsTable from "./PredefinedFieldsTable";

function PredefinedFields({
  openPredefinedFieldsDialog,
  handlePredefinedFieldsDialogClose,
  targetTabId,
  targetHeadingGroup,
  handleAddPredefinedFields,
}) {
  const { darkMode } = useMaterialUIController()[0];
  const [selectedPredefinedFields, setSelectedPredefinedFields] = useState([]);

  const handleSelectedPredefinedFields = (newPredefinedFields) =>
    setSelectedPredefinedFields(newPredefinedFields);

  const handleResetSelectedPredefinedFields = () =>
    setSelectedPredefinedFields([]);

  return (
    <Dialog
      open={openPredefinedFieldsDialog}
      onClose={handlePredefinedFieldsDialogClose}
      fullWidth
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        Select predefined fields
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        {useMemo(
          () => (
            <PredefinedFieldsTable
              handleSelectedPredefinedFields={handleSelectedPredefinedFields}
            />
          ),
          [],
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton
          variant="gradient"
          color="success"
          onClick={() => {
            const reversedPredefinedFields = selectedPredefinedFields.toReversed();
            handleAddPredefinedFields(
              targetTabId,
              targetHeadingGroup,
              reversedPredefinedFields,
            );
            handleResetSelectedPredefinedFields();
            handlePredefinedFieldsDialogClose();
          }}
        >
          Add
        </MDButton>
        <MDButton
          variant="gradient"
          color="error"
          onClick={handlePredefinedFieldsDialogClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PredefinedFields;
