import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import DispatcherRowAction from "application/components/DispatcherRowAction";


const operationURLS = {
  list: "imsi/emergencyList",
  csvDownload: "eventActivityLog/downloadCSV",
};

const getColumns = (onClickToCall) => {
  return [
    {
      Header: "created On",
      accessor: "createdOn",
      allowFilter: false,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Extension Name",
      accessor: "extensionName",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Extension Number",
      accessor: "extensionNumber",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Department",
      accessor: "department",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Zone",
      accessor: "zone",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },

    // {
    //   Header: "Department",
    //   accessor: "department",
    //   displayInList: true,
    //   allowFilter: true,
    //   dataType: "string",
    //   Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    // },
    {
      Header: "Action",
      accessor: "uuid",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => (
        <DispatcherRowAction
          value={value}
          row={row}
          onClickToCall={onClickToCall}
        />
      ),
    },
  ];
};

export { getColumns, operationURLS };
