import { Box, Dialog, DialogActions, DialogTitle, List } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useState } from "react";
import Folder from "./Folder";
import { v4 as uuidv4 } from "uuid";
import { enqueueSnackbar } from "notistack";

const MoveExplorer = ({
  folderToMove,
  setMoveExplorer,
  folders,
  setFolders,
  handleSelected,
  setCurrentFolder,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [moveExplorerCurrentFolder, setMoveExplorerCurrentFolder] = useState();

  const closeMoveExplorer = () => {
    setMoveExplorer(null);
  };

  const handleMoveExplorerState = (e, folder) => {
    setMoveExplorerCurrentFolder(folder);
  };
  const submitMove = () => {
    moveChild(folderToMove, moveExplorerCurrentFolder);
    setMoveExplorer(null);
  };
  const findNodeById = (nodes, id) => {
    for (let node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const childNode = findNodeById(node.children, id);
        if (childNode) return childNode;
      }
    }
    return null;
  };

  const removeChildById = (nodes, childId) => {
    for (let node of nodes) {
      if (node.children) {
        const index = node.children.findIndex((child) => child.id === childId);
        if (index !== -1) {
          return node.children.splice(index, 1)[0];
        }
        const child = removeChildById(node.children, childId);
        if (child) return child;
      }
    }
    return null;
  };

  const moveChild = (oldChild, newParent) => {
    setFolders((prevData) => {
      // Make a deep copy of the data
      const newData = JSON.parse(JSON.stringify(prevData));

      // Remove the child from its current location
      const child = removeChildById(newData, oldChild?.id);

      if (child) {
        // Find the new parent node
        const newParentNode = findNodeById(newData, newParent?.id);
        if (newParentNode) {
          // Add the child to the new parent's children array
          if (!newParentNode.children) {
            newParentNode.children = [];
          }

          // if (
          //   classOfServices.some(
          //     (service) =>
          //       service.text === child?.type &&
          //       newParentNode?.type !== "services"
          //   )
          // ) {
          //   const oldParentNode = findNodeById(newData, oldChild?.parentId);
          //   if (oldParentNode?.children.length < 1) {
          //     removeChildById(newData, oldParentNode?.id);
          //   }
          //   const featureId = uuidv4();
          //   const newclassOfServices = {
          //     id: featureId,
          //     name: "Class of services",
          //     type: "services",
          //     parentId: newParentNode?.id,
          //     scheduleIds: [...newParentNode?.scheduleIds] || [],
          //     children: [child],
          //   };
          //   newParentNode.children.push(newclassOfServices);
          // } else {
          newParentNode.children.push(child);
          // }
          enqueueSnackbar("Moved Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      }
      return newData;
    });
  };

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={Boolean(folderToMove)}
        onClose={closeMoveExplorer}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        >
          Move under
        </DialogTitle>

        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "30px",
            padding: "10px 30px 20px 30px",
          }}
        >
          <List
            sx={{
              bgcolor: darkMode ? "#202940" : "#FFFFFF",
              height: "40vh",
              width: "100%",
              overflowY: "scroll",
              border: "3px solid grey",
              borderRadius: "0.365em",
              padding: "0.5em 0em 0.5em 1em",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {folders.map((folder) => (
              <Folder
                key={uuidv4()}
                level={0}
                folder={folder}
                handleSelected={handleMoveExplorerState}
                moveExplorerCurrentFolder={moveExplorerCurrentFolder}
              />
            ))}
          </List>
        </MDBox>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={submitMove}
          >
            Move
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={closeMoveExplorer}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MoveExplorer;
