import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { Card, Grid, Icon, Tab, Tabs } from "@mui/material";
import DSS from "./DSS";
import Contact from "./Contact";
import { useMaterialUIController } from "context";

const Attributes = {
  modes: [],
};

const groupDashboard = ({ onLoad, pageId, searchText, registerCallBacks }) => {
  const [selectedTab, setSelectedTab] = useState("dss");
  const [controller] = useMaterialUIController();
  const { activePage } = controller;

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <MDBox sx={{ height: "80vh", overflow: "auto", position: "relative" }}>
      <Grid item xs={12}>
        <Card
          style={{
            marginBottom: "10px",
            borderRadius: "0px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#3492eb",
              },
              height: 50,
            }}
          >
            <Tab label="DSS" value="dss" />
            <Tab label="Contact" value="contact" />
            {/* <Tab label="Status" value="status" /> */}
          </Tabs>
        </Card>
        {selectedTab == "dss" && (
          <Grid item xs={12}>
            <DSS searchText={searchText} />
          </Grid>
        )}
        {selectedTab == "contact" && (
          <Grid item xs={12}>
            <Contact searchText={searchText} />
          </Grid>
        )}
        {selectedTab == "status" && <Grid item xs={12}></Grid>}
      </Grid>
    </MDBox>
  );
};
export default groupDashboard;
