import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";


const getUpdateColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Shift Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      width: 4,
      displayInList: true,
      disabled: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From Date",
      accessor: "fromDateTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      width: 4,
      displayInList: true,
      controlType: "dateTime",
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "To Date",
      accessor: "toDateTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      width: 4,
      controlType: "dateTime",
      displayInList: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInList: true,
      Cell: ({ value, row }) => (
        <RowAction
          value={value}
          row={row}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getUpdateColumns };
