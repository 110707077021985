import DefaultCell from "application/components/DefaultCell";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "customerCDRSecondary/queueAbandonedList",
  pdfDownload: "customerCDRSecondary/generateAndDownloadPdf",
  csvDownload: "customerCDRSecondary/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue ",
      accessor: "queue",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent ID",
      accessor: "ccAgent",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID",
      accessor: "callId",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date Time",
      accessor: "startTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "UUID",
    //   accessor: "uuid",
    //   displayInList: false,
    //   allowFilter: false,
    //   dataType: "string",
    //
    //   Cell: ({ value, row }) => <CopyContent value={value} row={row} />,
    // },
    // {
    //   Header: "Play",
    //   accessor: "",
    //   displayInList: true,
    //   Cell: ({ row }) => <PAPlayAudio row={row} />,
    // },
    // {
    //   Header: "Download File",
    //   accessor: "",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   allowInForm: false,
    //   Cell: ({ row, value }) =>
    //     <CTLDownloadFile row={row} value={value} paCdr="paCdr"/>,
    // },
  ];
};

export { getColumns, Attributes, operationURLS };
