import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import EmptyImage from "../../../assets/images/mediacenter/emptyFolder.svg";
import { Box, CircularProgress } from "@mui/material";

const EmptyPage = ({ setIsScheduleExist }) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadSchedule = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsScheduleExist(true);
      setIsLoading(false);
    }, 500);
  };
  return (
    <div>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "80vh",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <>
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <img src={EmptyImage} alt="Empty Folder" height={100} />
            <MDTypography variant="h4" textAlign="center">
              You haven't configured anything yet
            </MDTypography>
            <MDTypography variant="p">
              Click on the button below to schedule your unvavailability
            </MDTypography>

            <MDButton
              variant="gradient"
              color="info"
              sx={{ marginTop: "3em" }}
              onClick={loadSchedule}
            >
              Schedule
            </MDButton>
          </>
        )}
      </MDBox>
    </div>
  );
};

export default EmptyPage;
