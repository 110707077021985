import * as formControl from "yup";

// Function to validate numbers from 1 to 100
function numberFrom1to100(message = "Enter a number between 1 and 100") {
  return this.nullable().test("numberFrom1to100", message, function (value) {
    return !value || (value >= 1 && value <= 100);
  });
}

// Add the new validation method to formControl

// Only IP Field For Function
function ipv4(message = "Invalid IP address") {
  return this.matches(/^(all-ip|(\d{1,3}\.){3}(\d{1,3}))$/, {
    message,
    excludeEmptyString: true,
  })
    .test("ip", message, (value) => {
      if (value === "all-ip") {
        return true;
      }

      return (
        value === undefined ||
        value === null ||
        value.trim() === "" ||
        value.split(".").find((i) => parseInt(i, 10) > 255) === undefined
      );
    })
    .nullable();
}

// Only Multiple IP Field  For Function
function MultipleIP(message = "Invalid IP address") {
  return this.matches(/^(?:(?:\d{1,3}\.){3}\d{1,3}(?:,\s*)?)+$/, {
    message,
    excludeEmptyString: true,
  }).test("ip", message, (value) => {
    return value === undefined || value === null || value.trim() === ""
      ? true
      : value.split(",").every((ip) => {
          const octets = ip.split(".");
          return (
            octets.length === 4 &&
            octets.every((octet) => parseInt(octet, 10) <= 255)
          );
        });
  });
}
// only digit value in Field
function digitsOnly() {
  return this.matches(/^\d+(\.\d+)?$/, "The field should have digits only");
}

// only 3 Integet value like 0 to 9
function intOnly3Digit(message = "Only 3-digit are allowed") {
  return this.matches(/^[0-9]{3}$/, { message });
}

// only 4 Integet value like 0 to 9
function intOnly4Digit(message = "Only 4-digit are allowed") {
  return this.matches(/^[0-9]{4}$/, { message });
}

// only 50 character accept
function lenthDefination(message = "Invalid input") {
  return this.matches(/^[\w\W]{0,50}$/, { message }).test(
    "lenthDefination",
    message,
    (value) => {
      return (
        value === undefined ||
        value === null ||
        value.trim() === "" ||
        value.length <= 50
      );
    }
  );
}

// Password validation method
function passwordValidation(
  message = "Invalid password format Password must contain at least one uppercase letter, one digit, and one special character"
) {
  return this.matches(
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    {
      message,
      excludeEmptyString: true,
    }
  ).test(
    "passwordValidation",
    "Password must contain at least one uppercase letter, one digit, and one special character.",
    function (value) {
      return (
        value === undefined ||
        value === null ||
        value.trim() === "" ||
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)
      );
    }
  );
}

// Only  1 To 31 digit Accept
function lenthDefination31(message = "Enter Valid Number Less Than 32") {
  return this.matches(/^(?!33$)([1-2]?[0-9]|30|31|32)$/g, { message }).test(
    message,
    (value) => {
      return (
        value === undefined ||
        value === null ||
        value.trim() === "" ||
        parseInt(value) <= 32
      );
    }
  );
}

// Only  1 To 255 digit Accept
function lenthDefination255(message = "Invalid input") {
  return this.matches(/^[\w\W]{0,255}$/, { message }).test(
    "lenthDefination",
    message,
    (value) => {
      return (
        value === undefined ||
        value === null ||
        value.trim() === "" ||
        value.length <= 255
      );
    }
  );
}

// Only Email Formate Accepted For Function
function emailOnly(message = "Invalid email format") {
  return this.matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:,[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})*$/g,
    { message }
  );
}

// Custom validation function for the specific format
function customFormat(message = "Invalid format") {
  return this.matches(/^(\d+|[a-z]+|[A-Z]+)(,(\d+|[a-z]+|[A-Z]+))*$/g, {
    message,
  });
}

// Custom validation function for exactly 15 '+' characters
function validatePlusSymbolsOnly(message = "Enter only 15 characters") {
  return this.matches(/^.{1,15}$/, { message });
}


formControl.addMethod(
  formControl.string,
  "validatePlusSymbolsOnly",
  validatePlusSymbolsOnly
);
formControl.addMethod(formControl.string, "customFormat", customFormat);
formControl.addMethod(formControl.string, "emailOnly", emailOnly);
formControl.addMethod(formControl.string, "digitsOnly", digitsOnly);
formControl.addMethod(formControl.string, "intOnly3Digit", intOnly3Digit);
formControl.addMethod(formControl.string, "intOnly4Digit", intOnly4Digit);
formControl.addMethod(formControl.string, "ipv4", ipv4);
formControl.addMethod(formControl.string, "MultipleIP", MultipleIP);
formControl.addMethod(formControl.string, "lenthDefination", lenthDefination);
formControl.addMethod(formControl.number, "numberFrom1to100", numberFrom1to100);
formControl.addMethod(
  formControl.string,
  "lenthDefination31",
  lenthDefination31
);
formControl.addMethod(
  formControl.string,
  "lenthDefination255",
  lenthDefination255
);
formControl.addMethod(
  formControl.string,
  "passwordValidation",
  passwordValidation
);

export default formControl;
