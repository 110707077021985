import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import {
  Button,
  CardContent,
  DialogActions,
  Divider,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { IconButton, Box } from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import CTLNotification from "application/components/CTLNotification";

// Function to extract the name from the 'from' field
const extractName = (from) => {
  const match = from.match(/^(.*?)(?:\s<)/);
  return match ? match[1] : from;
};

// Function to extract the email from the 'from' field
const extractEmail = (from) => {
  const match = from.match(/<(.*?)>/);
  return match ? match[1] : from;
};

// Function to truncate the subject
const truncateSubject = (subject, maxLength = 50) => {
  return subject.length > maxLength
    ? `${subject.slice(0, maxLength)}...`
    : subject;
};

// Convert Email Body 
const extractBody = (body) => {
  const boundary = body.match(/--=_[a-z0-9-]+/g);
  if (!boundary) return body;

  const parts = body.split(new RegExp(`${boundary[0]}.*\r\n`));
  const plainTextPart = parts.find(part => part.includes('Content-Type: text/plain'));
  const htmlPart = parts.find(part => part.includes('Content-Type: text/html'));

  if (htmlPart) {
    const htmlBody = htmlPart.split('\r\n\r\n')[1];
    return htmlBody;
  } else if (plainTextPart) {
    const plainTextBody = plainTextPart.split('\r\n\r\n')[1];
    return plainTextBody.replace(/\r\n/g, '<br>');
  } else {
    return body;
  }
};

export default function EmailConfiguration() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [openMailBox, setOpenMailBox] = useState(false);
  const [fullScreenView, setfullScreenView] = useState(false);
  const [miniMizeScreen, setminiMizeScreen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showEmailList, setShowEmailList] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const [threads, setThreads] = useState({});
  const [selectedThread, setSelectedThread] = useState(null);

  const fetchEmails = async () => {
    const response = await axios.get(
      "http://192.168.10.109:4052/api/gui/receiveEmails"
    );
    const emails = response.data.emails.map((email) => ({
      uid: email.attributes.uid,
      date: email.parts[0].body.date[0],
      from: email.parts[0].body.from[0],
      subject: email.parts[0].body.subject[0],
      body: extractBody(email.parts[1].body),
    }));

    // Group emails by thread (using subject as a thread identifier here)
    const threads = emails.reduce((acc, email) => {
      const subject = email.subject.replace(/Re: /g, ""); // Normalize subject for grouping
      if (!acc[subject]) acc[subject] = [];
      acc[subject].push(email);
      return acc;
    }, {});

    return threads;
  };

  useEffect(() => {
    const getEmails = async () => {
      const emailThreads = await fetchEmails();
      setThreads(emailThreads || {});
    };

    getEmails();
  }, []);

  const handleThreadClick = (subject) => {
    setSelectedThread(threads[subject]);
    setSelectedEmail(null);
  };

  // Send Mail Reply
  const handleReplyClick = () => {
    if (selectedEmail) {
      setTo(extractEmail(selectedEmail.from));
      setSubject(`Re: ${selectedEmail.subject}`);
      setMessage("");
      setOpenMailBox(true);
    }
  };

  // Send Mail Reply All
  const handleReplyAllClick = () => {
    if (selectedThread) {
      const allRecipients = selectedThread
        .map((email) => extractEmail(email.from))
        .join(", ");
      setTo(allRecipients);
      setSubject(`Re: ${selectedThread[0].subject}`);
      setMessage("");
      setOpenMailBox(true);
    }
  };
  const handleToChange = (event) => {
    setTo(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleOpenMailBox = () => {
    setOpenMailBox(true);
    setTo("");
    setSubject("");
    setMessage("");
  };

  const handleCloseMailBox = () => {
    setOpenMailBox(false);
  };

  const MaximizeScreen = () => {
    setfullScreenView(true);
    setminiMizeScreen(false);
  };
  const MinimizeScreen = () => {
    setfullScreenView(false);
    setminiMizeScreen(true);
  };

  useEffect(() => {
    const getEmails = async () => {
      const emailData = await fetchEmails();
      setEmails(emailData || []);
    };

    getEmails();
  }, [selectedEmail]);

  const handleEmailClick = (email) => {
    setSelectedEmail(email);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const OperationURL = {
    sendEmail: "http://192.168.10.109:4052/api/gui/sendEmail",
    receivedEmail: "/api/gui/receiveEmails",
  };

  const SendMail = () => {
    let data = {
      to: to,
      subject: subject,
      text: message,
    };
    axios
      .post(OperationURL.sendEmail, data)
      .then((res) => {
        setNotify({
          isOpen: true,
          pagename: "Email Configuration",
          status: "Mail Sent Successfully",
          type: "success",
        });
        setOpenMailBox(false);
        setTo("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          pagename: "Email Configuration",
          status: "Unable To Send Mail",
          type: "error",
        });
      });
  };

  return (
    <MDBox display="flex">
      <MDBox
        display="flex"
        flexDirection="column"
        width={showEmailList ? "50%" : "auto"}
        maxWidth="350px"
        height="100vh"
        overflow="auto"
        padding="10px"
        borderRight={showEmailList ? "1px solid #ddd" : "none"}
      >
        <Box display="flex" direction="row">
          {showEmailList && (
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "success.main",
              }}
              onClick={handleOpenMailBox}
            >
              New Email
            </Button>
          )}
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
          >
            <IconButton
              color="info"
              sx={{
                width: 40,
                height: 40,
                backgroundColor: "success.main",
                marginLeft: "10px",
                borderRadius: 0,
              }}
              onClick={() => setShowEmailList(!showEmailList)}
            >
              {showEmailList ? <ChevronLeftIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </Box>
        </Box>
        {showEmailList &&
          Object.keys(threads).map((threadSubject) => (
            <MDBox
              key={threadSubject}
              onClick={() => handleThreadClick(threadSubject)}
              padding="10px"
              margin="5px 0"
              backgroundColor={
                selectedThread && selectedThread[0].subject === threadSubject
                  ? "white"
                  : "transparent"
              }
              color={
                selectedThread && selectedThread[0].subject === threadSubject
                  ? "white"
                  : "black"
              }
              border={
                selectedThread && selectedThread[0].subject === threadSubject
                  ? "1px solid white"
                  : "none"
              }
              cursor="pointer"
              borderRadius="1px solid gray"
            
            >
              <MDTypography display="flex" justifyContent="space-between">
                {extractName(threads[threadSubject][0].from)}{" "}
                <span>{formatDate(threads[threadSubject][0].date)}</span>
              </MDTypography>
              <Tooltip title={threadSubject} arrow>
                <MDTypography variant="body2">
                  {truncateSubject(threadSubject)}
                </MDTypography>
              </Tooltip>
              <Divider />
            </MDBox>
          ))}
      </MDBox>
      <MDBox
        width="90%"
        p={2}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.gray"
        display="flex"
        flexDirection="column"
        minHeight="100px"
      >
        {selectedThread ? (
          selectedThread.map((email) => (
            <Card
              key={email.uid}
              onClick={() => handleEmailClick(email)}
              sx={{
                cursor: "pointer",
                margin: "10px 0",
                padding: "10px",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <MDTypography variant="h6">
                    {extractName(email.from)}
                  </MDTypography>
                  <MDTypography variant="h6">
                    {formatDate(email.date)}
                  </MDTypography>
                </Box>
                <MDTypography variant="body2">{email.subject}</MDTypography>
                {selectedEmail && selectedEmail.uid === email.uid && (
                  <>
                    <Divider sx={{ margin: "10px 0" }} />
                    <MDTypography>
                      <strong>From:</strong> {extractName(email.from)} (
                      {extractEmail(email.from)})
                    </MDTypography>
                    <MDTypography>
                      <strong>Message:</strong>
                      <div
                        dangerouslySetInnerHTML={{ __html: email.body }}
                        style={{ marginTop: "10px" }}
                      />
                    </MDTypography>
                  </>
                )}
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "auto",
                  marginLeft: "15px",
                }}
              >
                <MDTypography
                  variant="contained"
                  color="error"
                  sx={{ marginRight: "15px" }}
                  onClick={() => handleReplyClick(email)}
                >
                  Reply
                </MDTypography>
                <MDTypography
                  variant="contained"
                  color="error"
                  onClick={handleReplyAllClick}
                >
                  Reply-To-All
                </MDTypography>
              </Box>
            </Card>
          ))
        ) : (
          <MDTypography style={{display:"flex",justifyContent:"center"}}>No emails selected</MDTypography>
        )}
      </MDBox>
      <Dialog
        fullScreen={fullScreenView}
        onClose={handleCloseMailBox}
        open={openMailBox}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        maxWidth="lg"
        PaperProps={{
          sx: fullScreenView
            ? {}
            : {
                width: "100%",
                height: "65%",
              },
        }}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={fullScreenView ? MinimizeScreen : MaximizeScreen}
              sx={{
                position: "relative",
                top: "-8px",
              }}
            >
              {fullScreenView ? (
                <FilterNoneIcon color="error" />
              ) : (
                <CropSquareIcon color="error" />
              )}
            </IconButton>
            <IconButton
              onClick={handleCloseMailBox}
              sx={{
                position: "relative",
                top: "-8px",
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          fullScreen
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              label="To"
              variant="outlined"
              fullWidth
              value={to}
              onChange={handleToChange}
            />
            <TextField
              label="Subject"
              variant="outlined"
              fullWidth
              value={subject}
              onChange={handleSubjectChange}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={10}
              value={message}
              onChange={handleMessageChange}
            />
            <label htmlFor="fileinput">
              <Tooltip title="Attach File">
                <AttachFileIcon
                  color="info"
                  fontSize="large"
                  style={{ display: "flex", justifyContent: "start" }}
                />
              </Tooltip>
            </label>
            <input
              type="file"
              id="fileinput"
              style={{ display: "none" }}
              // onChange={(e) => handlefileChange(e)}
            />
            {/* <Media/> */}
          </Box>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton color="success" type="button" onClick={SendMail}>
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}
