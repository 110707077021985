import { useUpdateGeometryDataMutation } from "features/geofenceSlice";
import { enqueueSnackbar } from "notistack";

export const useGeometryDataJSON = (refetchCallback) => {
  const [updateGeometryData] = useUpdateGeometryDataMutation();

  const changeGeometryDataJSON = async (newData, message = "") => {
    console.log("Data before processing:", newData);

    // Modified to preserve user assignments in groups
    const processData = (items) => {
      return items.map((item) => {
        const processedItem = { ...item };
        
        if (item.children) {
          processedItem.children = processData(item.children);
        }
        
        // Only clear children for the root-level Users
        if (item.type === "users" && (!item.parentId || item.parentId === "")) {
          processedItem.children = [];
        }
        
        return processedItem;
      });
    };

    try {
      // Ensure we're sending an array
      const dataToSend = Array.isArray(newData) ? newData : [newData];
      const processedData = processData(dataToSend);
      
      console.log("Processed data being sent to backend:", processedData);

      await updateGeometryData({ data: processedData }).unwrap();
      
      enqueueSnackbar(`Successfully ${message}`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });

      if (refetchCallback) {
        await refetchCallback();
      }

      return true; // Return success status
    } catch (error) {
      console.error("Failed to update geometry data:", error);
      enqueueSnackbar(error?.data?.message || "Failed to update", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return false; // Return failure status
    }
  };

  return { changeGeometryDataJSON };
};
