import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";
import CTLBooleanCell from "application/components/CTLBooleanCell";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "paCDR/list",
  pdfDownload: "/agentSummary/generateAndDownloadPdf",
  csvDownload: "paCDR/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "Direction",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID Name",
      accessor: "paerticipantType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID Number",
      accessor: "staretTime",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Destination",
      accessor: "answeerTime",
      displayInList: true,
      allowFilter: false,
      dateTime: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Stamp",
      accessor: "calelStatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Answer Stamp",
      accessor: "Dierectiokkn",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Stamp",
      accessor: "paerticipakkkkntType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "steartTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Duration",
      accessor: "answearTime",
      displayInList: true,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "callStsatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    // {
    //   Header: "UUID",
    //   accessor: "uuid",
    //   displayInList: false,
    //   allowFilter: false,
    //   dataType: "string",
    //
    //   Cell: ({ value, row }) => <CopyContent value={value} row={row} />,
    // },
    // {
    //   Header: "Play",
    //   accessor: "",
    //   displayInList: true,
    //   Cell: ({ row }) => <PAPlayAudio row={row} />,
    // },
    {
      Header: "Recording File",
      accessor: "",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) => (
        <CTLDownloadFile row={row} value={value} paCdr="paCdr" />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
