import AuthenticationService from "application/modules/authentication/AuthenticationService";
import loggerFactory from "globals/logger/logger-factory";
import variables from "globals/variables";

import {
    Registerer,
    RegistererState,
    UserAgent,
} from "sip.js";

const SIPPhoneService = {
  enabled: false,
  lastNumberDialed: null,
  swipeCode: "13",
  callPark: "5",
  callUnPark: "38",
  isInitDone: false,
  isRinging: false,
  remoteAudioMedia: null,
  remoteVideoMedia: null,
  localVideoMedia: null,
  inviteSession: null,
  deskPhoneStatus: null,
  userAgentOptions: {
    uri: null,
    transportOptions: {
      server: variables.sip.webRTCServer,
    },
    displayName: "",
    authorizationUsername: "",
    authorizationPassword: "",
    logConfiguration: false,
    logLevel: "debug",
  },
  userAgent: null,
  registerer: null,
  currentSessionAudio: null,
  currentSessionVideo: null,
  registeredState: "",
  callbacks: {
    invite: null,
    registration: null,
  },
  onInvite: function (invitation) {
    if (SIPPhoneService.callbacks.invite) {
      SIPPhoneService.callbacks.invite(invitation);
    }
  },

  onRegistrationUpdate: function (registrationState) {
    SIPPhoneService.registeredState = registrationState;
    if (SIPPhoneService.callbacks.registration) {
      SIPPhoneService.callbacks.registration(registrationState);
    }
  },

  onRegistrationUpdate: function (registrationState) {
    SIPPhoneService.registeredState = registrationState;
    if (SIPPhoneService.callbacks.registration) {
      SIPPhoneService.callbacks.registration(registrationState);
    }

    if (registrationState === RegistererState.Registered) {
      if (SIPPhoneService.registrationRetryTimeout) {
        clearTimeout(SIPPhoneService.registrationRetryTimeout);
        SIPPhoneService.registrationRetryTimeout = null;
      }
      loggerFactory.info("Phone Registration successful.", registrationState);
    } else if (
      registrationState === RegistererState.Terminated ||
      registrationState === RegistererState.Unregistered
    ) {
      loggerFactory.info(
        "Phone Registration failed. Retrying in 2 seconds...",
        registrationState
      );
      SIPPhoneService.registrationRetryTimeout = setTimeout(() => {
        SIPPhoneService.registerer.register();
      }, 2000);
    }
  },

  init() {
    if (!this.deskPhoneStatus) {
      SIPPhoneService.userAgentOptions.uri = UserAgent.makeURI(
        "sip:" +
          SIPPhoneService.userAgentOptions.authorizationUsername +
          "@" +
          variables.sip.domain
      );

      SIPPhoneService.userAgentOptions.delegate = {
        onInvite: SIPPhoneService.onInvite,
      };

      SIPPhoneService.userAgentOptions.sessionDescriptionHandlerFactoryOptions =
        {
          iceGatheringTimeout: 500,
          peerConnectionConfiguration: {
            iceServers: [],
          },
        };

      SIPPhoneService.userAgent = new UserAgent(
        SIPPhoneService.userAgentOptions
      );

      SIPPhoneService.registerer = new Registerer(SIPPhoneService.userAgent, {
        logConfiguration: false,
      });

      SIPPhoneService.registerer.stateChange.addListener((state) => {
        SIPPhoneService.onRegistrationUpdate(state);
      });

      SIPPhoneService.userAgent.start().then(() => {
        SIPPhoneService.registerer.register();
      });

      SIPPhoneService.isInitDone = true;
    }
  },
};

export default SIPPhoneService;