const ucMenus = [
  {
    icon: "list",
    key: "uc.history",
    name: "CDR",
    description: "Last 50 calls",
    url: "home/app/uc/cdr",
  },
  {
    icon: "phone_missed",
    key: "uc.missed-calls",
    name: "Missed calls",
    description: "Missed incoming calls",
    url: "home/app/uc/missed-calls",
  },
  {
    icon: "voicemail",
    key: "uc.voicemails",
    name: "Voicemail",
    description: "Voice mails",
    url: "home/app/uce/voicemails",
    disabled: true,
  },
  {
    icon: "phone_callback",
    key: "uc.call-back",
    name: "Call Back",
    description: "Callbacks set for you by different users",
    url: "home/app/uc/callback",
  },
  {
    key: "d1",
    type: "divider",
  },
  {
    icon: "contacts",
    key: "uc.contacts",
    name: "Contacts",
    description: "Phone Book",
    url: "home/app/uc/contacts",
  },
  {
    icon: "contact_phone",
    key: "uc.personal.phoneBook",
    name: "Personal Contacts",
    description: "Personal Contacts",
    url: "home/app/personalPhonBook",
  },
  {
    key: "d2",
    type: "divider",
  },
  {
    icon: "message",
    key: "uc.messages",
    name: "Messages",
    description: "Messages sent / received",
    url: "home/app/uc/messages",
  },

  {
    icon: "message",
    key: "uc.broadcasts.message",
    name: " Broadcasts Message",
    description: "Broadcasts Message",
    url: "home/app/broadcastsMessage",
  },
  {
    key: "d3",
    type: "divider",
  },
  {
    icon: "fax",
    key: "uc.fax",
    name: "Fax",
    description: "Fax",
    url: "home/app/uc/Fax",
  },
  {
    icon: "fax",
    key: "uc.sendFax.details",
    name: "Sent-Fax-Status",
    description: "Sent-Fax-Status",
    url: "home/app/Sent-Fax-Status",
  },
  {
    key: "d4",
    type: "divider",
  },
  {
    icon: "groups",
    key: "uc.conference.calendar",
    name: "Conference Calendar",
    description: "conference Calendar",
    url: "home/app/uc/ConferenceCalendar",
  },
  {
    icon: "settings",
    key: "uc.conference.setting",
    name: "Conference Setting",
    description: "conference Setting",
    url: "home/app/uc/ConferenceSetting",
  },
  {
    icon: "campaign_icon",
    name: "Conference Announcement",
    key: "distorsion",
    url: "/app/conference-Announcement",
    description: "Conference Announcement",
  },
  {
    key: "d4",
    type: "divider",
  },
  {
    icon: "link",
    key: "uc.conference.portal",
    name: "Conference Portal",
    description: "conference portal",
    url: "home/app/uc/ConferencePortal",
  },
  {
    key: "d4",
    type: "divider",
  },
  {
    icon: "account_box",
    key: "uc.settings",
    name: "User Profile",
    description: "User Profile",
    url: "home/app/uc/settings",
  },

  {
    icon: "message",
    key: "uc.conference.addParticipant",
    name: "Meeting Participant",
    url: "home/app/uc/MeetingParticipant",
    disabled: true,
  },
  {
    icon: "lockReset",
    name: "Change Password",
    key: "Change-password",
    url: "/app/ChangePassword",
    description: "Change Password",
  },
];

export { ucMenus };
