import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import CTLBaseForm from "application/components/CTLBaseForm";

function ConferenceSetting({
  onLoad,
  pageName,
}) {
  const [controller] = useMaterialUIController(); 
  const [formData, setFormData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  function onSaveForm(data) {
    application
      .post(operationURLS.register, data)
      .then((response) => {
        setNotify({
          isOpen: true,
        
          status: response.data.messageDetail,
          pagename: pageName, 
          type: "success",
          
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
  }

  useEffect(() => {
    if (onLoad) { 
      onLoad(Attributes);
    }
    application.post(operationURLS.list).then((response) => {
      let dataToDisplay = response.data.data[0];
      if(dataToDisplay){
        setFormData(dataToDisplay);
      }else{
        setFormData({eventTime: Date.now()});
      }
    });
  }, [onLoad]); 

  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Create New Meeting"
        columns={getColumns(null, null)}
        openForm={true} 
        onSaveForm={onSaveForm}
        onCloseForm={null} 
        formData={formData}
        formAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}
export default ConferenceSetting;
