import CTLUnReadCell from "application/components/CTLUnReadCell";
import { reportMenus } from "globals/configuration/reports";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";
import ReportPlayAudio from "application/components/CTLPlayAudioCell";
import DefaultCell from "application/components/DefaultCell";

const getAgent = AuthenticationService.allowAgentMenus();
const Attributes = {
  enableDownloadButton: true,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableExitButton: getAgent === true || getAgent === undefined || getAgent === "" ? true : false,
  enableRefresh: true,
  modes: getAgent === true || getAgent === undefined || getAgent === ""
    ? agentReportMoreVert.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : reportMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      }),
};
const operationURLS = {
  list: "/agentCDR/list",
  pdfDownload: "/agentCDR/generateAndDownloadPdf",
  csvDownload: "/agentCDR/agentCdr/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue Id",
      accessor: "queue",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Agent id",
      accessor: "agentName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "fullName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Campaign Name",
      accessor: "campaignName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Customer Number",
      accessor: "agentTalkedTo",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "talkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Lead Name",
      accessor: "leadName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Call Direction",
      accessor: "callDirection",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Missed Call",
      accessor: "isMissed",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Record Type",
      accessor: "recordType",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 10",
      accessor: "second10",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 20",
      accessor: "second20",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 30",
      accessor: "second30",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 40",
      accessor: "second40",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 50",
      accessor: "second50",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Second 60",
      accessor: "second60",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording File",
      accessor: "recordingFile",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <ReportPlayAudio row={row} value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
