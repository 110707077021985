import RowAction from "application/components/RowAction";
import { taskManagementSystem } from "globals/configuration/taskManagementSystem";
import formControl from "globals/form-control";
import ViewTaskDetals from "../taskViewDetals";
import TaskStatusUpdate from "../taskStatusUpdate";
import RowColorCell from "application/components/RowColorCell";
import { useMaterialUIController } from "context";
import { setAssignTo } from "context";
import DefaultCell from "application/components/DefaultCell";
import PlayTaskFile from "../../filePlay";
import DownloadTaskFile from "../../downloadFile";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import ChangeDateCell from "../../changeDateCell";

const Attributes = {
  formSize: "lg",
  enableAddButton: true,
  enableRefresh: true,
  enableUpdateTableRecords: false,
  enableDownloadButton: true,
  enableDownloadPdf: false,
  settingsMode: false,
  enableAdvanceSearch: true,
  keyField: "tasksName",
  modes: taskManagementSystem.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "task/list",
  create: "task/create",
  update: "task/update",
  delete: "task/delete",
  downloadCSV: "task/downloadCSV",
  pdfDownload: "task/generateAndDownloadPdf",
};

const getColumns = (onDelete, onEdit, onRefresh) => {
  const [controller, dispatch] = useMaterialUIController();
  const { assignTo } = controller;

  const onChangeAssign = (event) => {
    let isChecked = event.target.value;

    if (isChecked === 1 || isChecked === 2) {
      setAssignTo(dispatch, isChecked);
    } else {
      setAssignTo(dispatch, "");
    }
  };
  return [
    // {
    //   Header: "Status Indicator",
    //   accessor: "",
    //   displayInList: true,
    //   width: 6,
    //   Cell: ({ value, row }) => <RowColorCell value={value} row={row} />,
    //   isUserGenerated: true,
    // },
    {
      Header: "Task Name",
      accessor: "tasksName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
      isUserGenerated: true,
    },
    {
      Header: "Start date",
      accessor: "assignedOn",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      controlType: "dateTime",
      dateTime: true,
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Due date",
      accessor: "expectedCompletionDate",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      isEndTime: true,
      controlType: "dateTime",
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <ChangeDateCell value={value} row={row} />,
    },
    {
      Header: "Task Complete Date",
      accessor: "taskCompletedOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      controlType: "futureDateTime",
      width: 6,
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Priority",
      accessor: "taskPriority",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "taskPriority",
        keyDescription: "taskPriority",
        values: [],
        url: "/taskPriority/listAll",
      },
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },

    {
      Header: "Task Type",
      accessor: "taskType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "taskType",
        keyDescription: "taskType",
        values: [],
        url: "/taskType/listAll",
      },
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Assign To",
      accessor: "assignedTo",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      width: 6,
      onChange: onChangeAssign,
      downloadReports: false,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: 1, description: "Task Group" },
          { id: 2, description: "Individual" },
        ],
      },
      isUserGenerated: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Assignee Group",
      accessor: "assignedGroup",
      displayInList: true,
      allowInForm: assignTo === 1 ? true : false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "select",
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
      width: 6,
      options: {
        keyField: "code",
        keyDescription: "code",
        values: [],
        url: "/userGroup/listAll/uniqueGroup",
      },
      isUserGenerated: true,
    },

    {
      Header: "Assignee User",
      accessor: "assignedUser",
      displayInList: true,
      allowInForm: assignTo === 2 ? true : false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "user",
        keyDescription: "user",
        values: [],
        url: "/userRole/listAll/user",
      },
      isUserGenerated: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Reviewer",
      accessor: "reviewer",
      displayInList: true,
      allowInForm: assignTo === 2 ? true : false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "user",
        keyDescription: "user",
        values: [],
        url: "/userRole/listAll/taskManager",
      },
      validation: formControl.string().required("Required").nullable(),
      isUserGenerated: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Current Status",
      accessor: "currentStats",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      filterControlType: "select",
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      options: {
        keyField: "taskStatus",
        keyDescription: "taskStatus",
        values: [],
        url: "/taskStatus/listAll",
      },
      isUserGenerated: true,
    },
    {
      Header: "Description",
      accessor: "taskDescription",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 12,
      isUserGenerated: true,
      controlType: "textField",
      Cell: ({ value, row }) => <CTLUnReadCell value={value} row={row} />,
    },
    {
      Header: "CreatedOn",
      accessor: "createdOn",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 12,
      isUserGenerated: true,
      controlType: "textField",
      Cell: ({ value, row }) => <CTLUnReadCell value={value} row={row} />,
    },
    {
      Header: "attached file",
      accessor: "filePath",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "fileUpload",
      sendFileInFormData: true,
      fileTypeAccept: ".mp3, .wav, .pdf, .txt, .csv",
      uploadUrl: "task/uploadFile",
      width: 6,
      isUserGenerated: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "View",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 12,
      isUserGenerated: true,
      Cell: ({ value, row }) => <ViewTaskDetals value={value} row={row} />,
    },
    {
      Header: "Closed Task",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      isUserGenerated: true,
      width: 12,
      Cell: ({ value, row }) => (
        <TaskStatusUpdate value={value} row={row} onRefresh={onRefresh} />
      ),
    },
    {
      Header: "Play/&/Download",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      sortDisable: true,
      Cell: ({ row, value }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlayTaskFile row={row} value={value} />
          <DownloadTaskFile row={row} value={value} />
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
