import Divider from "@mui/material/Divider";
import { Switch } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/pages/account/components/FormField";

import React, { useEffect, useState } from "react";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";
import CTLNotification from "application/components/CTLNotification";

import { useMaterialUIController } from "context";

function BasicInfo() {
  const [controller] = useMaterialUIController();
  const { profileStatus } = controller;
  const digitsOnly = (value) => {
    return !value || /^\d+$/.test(value);
  };

  const componentName = "uc.settings";
  const [tempData, setTempData] = useState("");
  const initialValues = {};
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    faxNumber: Yup.string().test("Digits only", "digits only", digitsOnly),
    phoneOffice: Yup.string()
      .test("Digits only", "digits only", digitsOnly)
      .required("Required"),
    extension: Yup.string()
      .test("Digits only", "digits only", digitsOnly)
      .required("Required"),
    phoneHome: Yup.string().test("Digits only", "digits only", digitsOnly),
    mobileNumber: Yup.string().test("Digits only", "digits only", digitsOnly),
    cfa: Yup.string().test("Digits only", "digits only", digitsOnly),
    cfb: Yup.string().test("Digits only", "digits only", digitsOnly),
    cfna: Yup.string().test("Digits only", "digits only", digitsOnly),
    fm: Yup.string().test("Digits only", "digits only", digitsOnly),

    ecfa: Yup.string().test("Digits only", "digits only", digitsOnly),
    ecfb: Yup.string().test("Digits only", "digits only", digitsOnly),
    ecfna: Yup.string().test("Digits only", "digits only", digitsOnly),
    vm: Yup.string().test("Digits only", "digits only", digitsOnly),
  });

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    if (profileStatus.profileStatus === 1) {
      setNotify({
        isOpen: true,
        message: "Click On Save Button To Save The Profile ",
      });
    }
  }, [profileStatus]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      let dataToSubmit = {
        customerAccount: {
          id: tempData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          emailId: values.emailId,
          faxNumber: values.faxNumber,
          phoneOffice: values.phoneOffice,
          extension: values.extension,
          phoneHome: values.phoneHome,
          mobileNumber: values.mobileNumber,
          company: values.company,
          designation: values.designation,
          addressOne: values.addressOne,
          addressTwo: values.addressTwo,
        },
        extensionRange: {
          dnd: values.dnd,
          local: values.local,
          intercom: values.intercom,
          std: values.std,
          isd: values.isd,
          cfa: values.cfa,
          cfb: values.cfb,
          cfna: values.cfna,
          fm: values.fm,

          ecfa: values.ecfa,
          ecfb: values.ecfb,
          ecfna: values.ecfna,
          vm: values.vm,
        },
      };
      loggerFactory.debug(componentName, "Data To Submit", dataToSubmit);
      handleSaveClick(dataToSubmit);
    },
  });

  const GetUserUrl = "/customerAccount/";
  const UpdateUrl = "/customerAccount/update";

  function UserData() {
    application
      .post(GetUserUrl)
      .then((response) => {
        loggerFactory.debug(
          componentName,
          "Get Customer Data",
          response.data.data
        );
        let customerAccount = response.data.data.customerAccount;

        if (customerAccount.id) {
          response.data.data.serviceCodeModel.map((service) => {
            switch (service.serviceCode) {
              case "LOCAL":
                customerAccount.local = service.value;
                break;
              case "Intercom":
                customerAccount.intercom = service.value;
                break;
              case "STD":
                customerAccount.std = service.value;
                break;
              case "ISD":
                customerAccount.isd = service.value;
                break;
              case "DND":
                customerAccount.dnd = service.value;
                break;
              case "FM":
                customerAccount.fm = service.data;
                break;
              case "CFA":
                customerAccount.cfa = service.data;
                break;
              case "CFB":
                customerAccount.cfb = service.data;
                break;
              case "CFNA":
                customerAccount.cfna = service.data;
                break;

              case "ECFA":
                customerAccount.ecfa = service.data;
                break;
              case "ECFB":
                customerAccount.ecfb = service.data;
                break;
              case "ECFNA":
                customerAccount.ecfna = service.data;
                break;
              case "VM":
                customerAccount.vm = service.data;
                break;
            }
          });
        } else {
          customerAccount = {
            id: 0,
            firstName: AuthenticationService.getFullName(),
            lastName: AuthenticationService.getFullName(),
            emailId: "",
            faxNumber: "",
            phoneOffice: AuthenticationService.getExtensionNumber(),
            extension: AuthenticationService.getExtensionNumber(),
            phoneHome: "",
            mobileNumber: "",
            company: "",
            designation: "",
            addressOne: "",
            addressTwo: "",
            intercom: true,
            local: false,
            std: false,
            isd: false,
            dnd: false,
            cfa: "",
            cfb: "",
            cfna: "",

            ecfa: "",
            ecfb: "",
            ecfna: "",
            vm: "",
          };
        }
        setTempData(customerAccount);
      })
      .catch((response) => {
        if (response.code === "ERR_BAD_REQUEST") {
          let customerAccount = {
            id: 0,
            firstName: AuthenticationService.getFullName(),
            lastName: AuthenticationService.getFullName(),
            emailId: "",
            faxNumber: "",
            phoneOffice: AuthenticationService.getExtensionNumber(),
            extension: AuthenticationService.getExtensionNumber(),
            phoneHome: "",
            mobileNumber: "",
            company: "",
            designation: "",
            addressOne: "",
            addressTwo: "",
            intercom: true,
            local: false,
            std: false,
            isd: false,
            dnd: false,
            cfa: "",
            cfb: "",
            cfna: "",

            ecfa: "",
            ecfb: "",
            ecfna: "",
            vm: "",
          };
          setTempData(customerAccount);
        }
      });
  }
  useEffect(() => {
    UserData();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="User Profile Update"
      content="User Profile Updated successfully"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="User Profile Update"
      content="Unable to update User Profile Updated. Contact Administrator"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  function handleSwitchChange(event) {
    formik.setFieldValue(event.target.name, event.target.checked);
    loggerFactory.debug(componentName, "Switch Event", {
      name: event.target.name,
      checked: event.target.checked,
    });
  }

  function handleSaveClick(dataToSubmit) {
    application
      .post(UpdateUrl, dataToSubmit)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: "Successfully Save UC User Profile",
          type: "success",
          pagename: "UC Profile Setting",
        });
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          loggerFactory.debug(
            componentName,
            "Unable to save the user profile data.",
            error
          );
        }
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            "" +
            error.response.data.messageDetail,
          type: "error",
          pagename: "UC Profile Setting",
        });
      });
  }

  function getValues(fieldName, dataType) {
    let returnValue = null;

    if (formik.values[fieldName] != undefined) {
      returnValue = formik.values[fieldName];
    } else {
      if (tempData[fieldName]) {
        returnValue = tempData[fieldName];
        formik.setFieldValue(fieldName, returnValue);
      } else {
        switch (dataType) {
          case "boolean":
            returnValue = false;
            break;
          default:
            returnValue = "";
            break;
        }
      }
    }

    loggerFactory.debug(componentName, "Form Get Field Value ", {
      fieldName: fieldName,
      value: returnValue,
    });

    return returnValue;
  }

  return (
    <>
      {tempData && (
        <form onSubmit={formik.handleSubmit}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox pb={3} px={3} sx={{ marginTop: "40px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    name="firstName"
                    label="First Name"
                    value={getValues("firstName")}
                    onChange={formik.handleChange}
                    error={formik.errors.firstName && formik.touched.firstName}
                    success={!formik.errors.firstName}
                  />
                  {formik.touched.firstName && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.firstName}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Last Name"
                    name="lastName"
                    value={getValues("lastName")}
                    onChange={formik.handleChange}
                    error={formik.errors.lastName && formik.touched.lastName}
                    success={!formik.errors.lastName}
                  />
                  {formik.touched.lastName && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.lastName}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Email"
                    name="emailId"
                    value={getValues("emailId")}
                    onChange={formik.handleChange}
                    inputProps={{ type: "email" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Fax Number"
                    name="faxNumber"
                    value={getValues("faxNumber")}
                    onChange={formik.handleChange}
                    error={formik.errors.faxNumber && formik.touched.faxNumber}
                    success={!formik.errors.faxNumber}
                  />
                  {formik.touched.faxNumber && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.faxNumber}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Office Phone Number"
                    name="phoneOffice"
                    value={getValues("phoneOffice")}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.phoneOffice && formik.touched.phoneOffice
                    }
                    success={!formik.errors.phoneOffice}
                  />

                  {formik.touched.phoneOffice && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.phoneOffice}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Office Extension"
                    name="extension"
                    value={getValues("extension")}
                    onChange={formik.handleChange}
                    error={formik.errors.extension && formik.touched.extension}
                    success={!formik.errors.extension}
                  />
                  {formik.touched.extension && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.extension}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Resident Number"
                    name="phoneHome"
                    value={getValues("phoneHome")}
                    onChange={formik.handleChange}
                    error={formik.errors.phoneHome && formik.touched.phoneHome}
                    success={!formik.errors.phoneHome}
                  />
                  {formik.touched.phoneHome && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.phoneHome}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Mobile Number"
                    name="mobileNumber"
                    value={getValues("mobileNumber")}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.mobileNumber && formik.touched.mobileNumber
                    }
                    success={!formik.errors.mobileNumber}
                  />
                  {formik.touched.mobileNumber && (
                    <MDTypography color="error" variant="caption">
                      {formik.errors.mobileNumber}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Company Name"
                    name="company"
                    value={getValues("company")}
                    onChange={formik.handleChange}
                    inputProps={{ type: "text" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Designation"
                    name="designation"
                    value={getValues("designation")}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Home Address"
                    name="addressOne"
                    value={getValues("addressOne")}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Office Address"
                    name="addressTwo"
                    value={getValues("addressTwo")}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <Card id="2fa" sx={{ overflow: "visible", marginTop: "40px" }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <MDTypography variant="h5">Configuration</MDTypography>
            </MDBox>
            <MDBox p={3}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Do Not Disturb
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {getValues("dnd", "boolean") ? "Enabled" : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    size="large"
                    name="dnd"
                    checked={getValues("dnd", "boolean")}
                    onChange={handleSwitchChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: getValues("dnd", "boolean")
                          ? "#4CAF50"
                          : "#F44335",
                      },
                    }}
                  />
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Dial Intercom
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {getValues("intercom", "boolean")
                        ? "Enabled"
                        : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    size="large"
                    name="intercom"
                    checked={getValues("intercom", "boolean")}
                    onChange={handleSwitchChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: getValues("intercom", "boolean")
                          ? "#4CAF50"
                          : "#F44335",
                      },
                    }}
                  />
                </MDBox>
              </MDBox>
              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Dial Local
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {getValues("local", "boolean") ? "Enabled" : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    size="large"
                    name="local"
                    checked={getValues("local", "boolean")}
                    onChange={handleSwitchChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: getValues("local", "boolean")
                          ? "#4CAF50"
                          : "#F44335",
                      },
                    }}
                  />
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Dial STD
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {getValues("std", "boolean") ? "Enabled" : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    size="large"
                    name="std"
                    checked={getValues("std", "boolean")}
                    onChange={handleSwitchChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: getValues("std", "boolean")
                          ? "#4CAF50"
                          : "#F44335",
                      },
                    }}
                  />
                </MDBox>
              </MDBox>
              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Dial ISD
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {getValues("isd", "boolean") ? "Enabled" : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    size="large"
                    name="isd"
                    checked={getValues("isd", "boolean")}
                    onChange={handleSwitchChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: getValues("isd", "boolean")
                          ? "#4CAF50"
                          : "#F44335",
                      },
                    }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>

          <Card id="2fa" sx={{ overflow: "visible", marginTop: "40px" }}>
            <MDBox p={3}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Call Forward Always
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="cfa"
                      value={getValues("cfa")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.cfa && formik.touched.cfa}
                      success={!formik.errors.cfa}
                    />
                    {formik.touched.cfa && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.cfa}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Call Forward Busy
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="cfb"
                      value={getValues("cfb")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.cfb && formik.touched.cfb}
                      success={!formik.errors.cfb}
                    />
                    {formik.touched.cfb && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.cfb}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Call Forward No Answer
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="cfna"
                      value={getValues("cfna")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.cfna && formik.touched.cfna}
                      success={!formik.errors.cfna}
                    />
                    {formik.touched.cfna && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.cfna}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Follow Me
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="fm"
                      value={getValues("fm")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.fm && formik.touched.fm}
                      success={!formik.errors.fm}
                    />
                    {formik.touched.fm && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.fm}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

              <Divider />

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Extension Forward All
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="ecfa"
                      value={getValues("ecfa")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.ecfa && formik.touched.ecfa}
                      success={!formik.errors.ecfa}
                    />
                    {formik.touched.ecfa && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.ecfa}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Extension Forward Busy
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="ecfb"
                      value={getValues("ecfb")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.ecfb && formik.touched.ecfb}
                      success={!formik.errors.ecfb}
                    />
                    {formik.touched.ecfb && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.ecfb}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Extension Forward No Answer
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="ecfna"
                      value={getValues("ecfna")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.ecfna && formik.touched.ecfna}
                      success={!formik.errors.ecfna}
                    />
                    {formik.touched.ecfna && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.ecfna}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  VoiceMail
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <FormField
                      name="vm"
                      value={getValues("vm")}
                      onChange={formik.handleChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      error={formik.errors.vm && formik.touched.vm}
                      success={!formik.errors.vm}
                    />
                    {formik.touched.vm && (
                      <MDTypography color="error" variant="caption">
                        {formik.errors.vm}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>

              <Divider />

              <MDButton type="submit" color="success" autoFocus>
                Save{" "}
              </MDButton>
            </MDBox>
          </Card>
        </form>
      )}
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default BasicInfo;
