import AuthenticationService from "application/modules/authentication/AuthenticationService";
import axios from "axios";

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (request) => {
      request.headers.Authorization = `Bearer ${AuthenticationService.getAuthenticationToken()}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error("Request failed:", error);
      return Promise.reject(error);
    }
  );

export { axiosInstance };
