
import StaticDropDownCell from "application/components/CTLStaticDropDownCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";


const Attributes = {
  formSize: "md",
  columns: 3,
  enableAcdDetails: true,
  selectUrl: "/queue/list/acdList",
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  agentList: "/agent/listQueue",
  agentCreate: "/agent/create",
  delete:"agent/delete",
};
const getColumns = (onDelete, onEdit) => {
  return [
       {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent To",
      accessor: "to",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Manual Queue",
      accessor: "defaultQueue",
      displayInList: true,
      allowInForm: true,
      controlType: "select",
       width: 4,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "51", description: "Acd Queue 51" },
          { id: "52", description: "Acd Queue 52" },
          { id: "53", description: "Acd Queue 53" },
          { id: "54", description: "Acd Queue 54" },
          { id: "55", description: "Acd Queue 55" },
          { id: "56", description: "Acd Queue 56" },
          { id: "57", description: "Acd Queue 57" },
          { id: "58", description: "Acd Queue 58" },
          { id: "59", description: "Acd Queue 59" },
          { id: "60", description: "Acd Queue 60" },
          { id: "61", description: "Acd Queue 61" },
          { id: "62", description: "Acd Queue 62" },
          { id: "63", description: "Acd Queue 63" },
          { id: "64", description: "Acd Queue 64" },
        ],
      },
      Cell: ({row ,value }) => <StaticDropDownCell row={row} value={value} />,
    },
    {
      Header: "Agent/User ID",
      accessor: "name",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header:"Agent/User Name",
      accessor: "agentFullName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Phone Number",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Position",
      accessor: "position",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
