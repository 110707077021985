import React, { useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";


const handleReceivedData = (data) => {
  try {
    console.log("Received data from C#:", data);
   
  } catch (error) {
    console.error("Error processing received data:", error);
  }
};

const receivedDataIncoming = (callData) => {
  try {
    console.log("Incoming call data:", callData);
   
  } catch (error) {
    console.error("Error processing incoming call data:", error);
  }
};

const EmbeddedLogin = () => {
  useEffect(() => {
  
    window.receiveData = handleReceivedData;
    window.receivedDataIncoming = receivedDataIncoming;

    
    return () => {
      delete window.receiveData;
      delete window.receivedDataIncoming;
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={60} thickness={4} />
      <Typography
        variant="h6"
        sx={{ marginTop: 2, color: "text", fontSize: "25px" }}
      >
        Please wait, we are authenticating you...
      </Typography>
    </Box>
  );
};

export default EmbeddedLogin;
