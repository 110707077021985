import React from "react";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import { IconButton } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { application } from "globals/endpoints";
import { errorMonitor } from "stream";
import CTLNotification from "../CTLNotification";
import variables from "globals/variables";
import axios from "axios";

export default function ViewLeadSummary({ row, onEdit }) {
  let markAsDelete = row?.original?.markAsDelete;
  let uniqueLeadUpload = row?.original?.uniqueLeadUpload;
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const OperationalUrl = {
    list: "/leadSummaryView/list",
    cancel: "/leadSummaryView/update",
    fileDownload: `${variables.app.services}auth/downloadCSVFile/${uniqueLeadUpload}`,
  };
  const onCancel = (data) => {
    let requestBodyData = {
      uniqueLeadUpload: data?.uniqueLeadUpload,
    };
    application
      .post(OperationalUrl.cancel, requestBodyData)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: response.data.message + "" + response.data.messageDetail,
          type: "success",
          pagename: "Lead Summary",
        });
      })
      .catch((error) => {
        console.log("Error While the call Cancel", error);
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            "" +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Lead Summary",
        });
      });
  };

  const filename = row?.original?.fileName;
  let str = filename;
  let index = str.lastIndexOf("/");
  let extracted = str.slice(index + 1, str.length);

  // Download Voice Picker MP3 File
  function onDownloadFile() {
    axios
      .get(OperationalUrl.fileDownload, { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const blobData = new Blob([response.data]);
          const url = window.URL.createObjectURL(blobData);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${extracted}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setNotify({
            isOpen: true,
            status: "File Download Successful",
            type: "success",
            pagename: "Lead Summary",
          });
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotify({
          isOpen: true,
          status: "Unable To Download File",
          type: "error",
          pagename: "Lead Summary",
        });
      });
  }

  return (
    <div>
      <tooltip title={"View"}>
        <IconButton color="primary" onClick={() => onEdit(row)}>
          <PreviewIcon color="error" />
        </IconButton>
      </tooltip>
      {markAsDelete === "N" ? (
        <tooltip title={"Cancel"}>
          <IconButton color="success" onClick={() => onCancel(row.original)}>
            <CancelIcon color="success" />
          </IconButton>
        </tooltip>
      ) : (
        <tooltip title={"Cancel"}>
          <IconButton color="success">
            <DoNotDisturbIcon color="white" />
          </IconButton>
        </tooltip>
      )}
      {filename ?  <tooltip title={"Download"}>
        <IconButton color="info" onClick={() => onDownloadFile()}>
          <DownloadIcon></DownloadIcon>
        </IconButton>
      </tooltip> : <tooltip title={"File Not Present"}>
          <IconButton color="success">
            <DoNotDisturbIcon color="white" />
          </IconButton>
        </tooltip>}
     
      <Dialog
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          View Lead Summary
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
        ></DialogContent>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}
