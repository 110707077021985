import CTLStatus from "application/components/CTLStatus";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";

const Attributes = {
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURL = {
  list: "shiftTime/list",
  create: "shiftTime/create",
  delete: "shiftTime/delete",
  update: "shiftTime/update",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Shift Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      width: 4,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "shiftName",
        keyDescription: "shiftDescription",
        values: [
          { shiftName: "Morning Shift", shiftDescription: "Morning Shift" },
          { shiftName: "Evening Shift", shiftDescription: "Evening Shift" },
          { shiftName: "Night Shift", shiftDescription: "Night Shift" },
          { shiftName: "General Shift", shiftDescription: "General Shift" },
        ],
        url: "",
      },
    },
    {
      Header: "From Date",
      accessor: "fromDateTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      width: 4,
      controlType: "dateTime",
      sortDisable: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => (
        <CTLStatus value={value} row={row} isFromDate={true} />
      ),
    },
    {
      Header: "To Date",
      accessor: "toDateTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      width: 4,
      controlType: "dateTime",
      sortDisable: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value, row }) => (
        <CTLStatus value={value} row={row} isToDate={true} />
      ),
    },
    {
      Header: "Status",
      accessor: "uuid",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value, row }) => <CTLStatus value={value} row={row} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value, row }) => (
        <RowAction
          value={value}
          row={row}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURL };
