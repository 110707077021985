import React, { useContext, useEffect, useState } from "react";
import { kaReducer, Table } from "ka-table";
import { updateCellValue, deleteRow } from "ka-table/actionCreators";
import { DataType, EditingMode, PagingPosition } from "ka-table/enums";
import { FaUpload, FaTrash, FaCheck } from "react-icons/fa";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import MediacenterDialogueConfig from "./MediaCenterDiologueConfig";
import AlertDelete from "./AlertDelete";
import EmptyContainer from "../mediaCenter/emptyContainer/EmptyContainer";
import { useSnackbar } from "notistack";
import { search } from "ka-table/actionCreators";
import SearchBar from "./seacrhComponent/SearchBar";
import { IoFunnel } from "react-icons/io5";
import { Delete, PlayArrow, Pause, Edit, Save, Star, StarBorder } from "@mui/icons-material";
import "../mediaCenter/Demo.scss";
import { useMaterialUIController } from "context";
import InputBox from "./InputBox";
import DropDown from "./DropDown";
import { useGetLanguagesMutation } from "features/mediaCenterApiSlice";
import { v4 as uuidv4 } from "uuid";
import { useGetAllMediaCenterJsonMutation } from "features/mediaCenterApiSlice";
import { useSaveMediaCenterJsonMutation } from "features/mediaCenterApiSlice";
import { useSaveMP3FileMutation } from "features/mediaCenterApiSlice";
import { useGetMP3FileQuery } from "features/mediaCenterApiSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { useDeleteAudioFileMutation } from "features/mediaCenterApiSlice";




const CustomUploader = ({ column, rowKeyValue, dispatch, rowData, currentlyPlayingId, onAudioPlay, isRowEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const [saveMP3File] = useSaveMP3FileMutation();
  const [saveMediaCenterJson] = useSaveMediaCenterJsonMutation();
  const [getAllMediaCenterJson] = useGetAllMediaCenterJsonMutation();
  const [audioFilePath, setAudioFilePath] = useState("")
  const [isPlaying, setIsPlaying] = useState(false)
  const [audio, setAudio] = useState(null);
  const [audioId, setAudioId] = useState(null);
  const [localAudioFileId, setLocalAudioFileId] = useState("");
  const [localAudioFileName, setLocalAudioFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [deleteAudioFile] = useDeleteAudioFileMutation();

  const getMp3FileQuery = useGetMP3FileQuery(audioFilePath, {
    skip: audioFilePath.length === 0
  })

  // Find the corresponding property for the current column
  const property = rowData.properties.find(prop => prop.language === column.key);
  const audioFileId = property ? property.audioFileId : "";
  const audioFileName = property ? property.audioFileName : "";

  useEffect(() => {
    setLocalAudioFileId(audioFileId);
    setLocalAudioFileName(audioFileName);
  }, [audioFileId, audioFileName]);

  const handleFileUpload = async (event) => {
    if (audio) {
      setIsPlaying(false);
      audio.pause();
      audio.currentTime = 0;
      setAudio(null);
    }
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setIsUploading(true);

      // Generate a unique ID for the file
      const uniqueFileId = uuidv4();
      const newAudioFileId = `${column.key}_${uniqueFileId}`;

      // Create FormData and append the file, language, and new audio file ID
      const formData = new FormData();
      formData.append('file', uploadedFile);
      formData.append('language', newAudioFileId);
      try {
        // Delete the previous audio file if it exists
        if (localAudioFileId) {
          console.log("localAudioFileId", localAudioFileId)
          console.log("localAudioFileName", localAudioFileName)
          try {
            await deleteAudioFile({
              path: "/ivr/deleteFile",
              content: {
                language: localAudioFileId,
                fileName: localAudioFileName
              }
            });
            enqueueSnackbar("Previous audio file deleted successfully", {
              variant: "info",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
              autoHideDuration: 3000,
            });
          } catch (deleteError) {
            console.error('Error deleting previous audio file:', deleteError);
          }
        }

        // Save the new MP3 file
        const saveMP3Response = await saveMP3File({
          content: formData,
          path: '/ivr/upload'
        });
        if (saveMP3Response.data.status === 'OK') {
          // Get the current MediaCenter JSON
          const allMediaCenterJson = await getAllMediaCenterJson({
            getDataUrl: "/ivr/get/mediaCenterJson"
          });

          if (allMediaCenterJson.data) {
            const { headerItems, languageSelected } = allMediaCenterJson.data.data;

            // Update the specific item in headerItems
            const updatedHeaderItems = headerItems.map(item => {
              if (item.id === rowKeyValue) {
                return {
                  ...item,
                  properties: item.properties.map(prop =>
                    prop.language === column.key
                      ? { ...prop, audioFileId: newAudioFileId, audioFileName: saveMP3Response.data.data }
                      : prop
                  )
                };
              }
              return item;
            });
            // Save the updated MediaCenter JSON
            await saveMediaCenterJson({
              path: "/ivr/save/mediaCenterJson",
              content: {
                languageSelected,
                headerItems: updatedHeaderItems
              }
            });

            // Update the local state
            dispatch(updateCellValue(rowKeyValue, column.key, newAudioFileId));
            setLocalAudioFileId(newAudioFileId);
            setLocalAudioFileName(saveMP3Response.data.data);

            // Show success notification
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
              autoHideDuration: 3000,
            });
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // Show error notification
        enqueueSnackbar("Error uploading file", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          autoHideDuration: 3000,
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handlePlayPauseAudio = async () => {
    if (localAudioFileId) {
      if (!audio) {
        setIsLoadingAudio(true);
        setAudioFilePath(`/auth/mediaCenter/play/${localAudioFileId}/${localAudioFileName}`);
        setAudioId(localAudioFileId);
        onAudioPlay(localAudioFileId);
      } else {
        if (isPlaying) {
          audio.pause();
          setIsPlaying(false);
        } else {
          audio.play();
          setIsPlaying(true);
          onAudioPlay(localAudioFileId);
        }
      }
    }
  }

  useEffect(() => {
    if (audioFilePath.length > 0 && getMp3FileQuery.data) {
      const audioBlob = new Blob([getMp3FileQuery.data], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(audioBlob);
      const audioObject = new Audio(url);
      audioObject.setAttribute('data-audio-id', audioId);
      setAudio(audioObject);
      audioObject.play();
      setIsPlaying(true);
      setIsLoadingAudio(false);

      audioObject.addEventListener('ended', () => {
        setIsPlaying(false);
      });

      return () => {
        audioObject.pause();
        URL.revokeObjectURL(url);
      };
    }
  }, [audioFilePath, getMp3FileQuery, audioId]);

  useEffect(() => {
    if (currentlyPlayingId !== audioId && isPlaying) {
      audio?.pause();
      setIsPlaying(false);
    }
  }, [currentlyPlayingId, audioId, isPlaying, audio]);

  const handleDeleteAudio = async () => {
    if (audio) {
      setIsPlaying(false);
      audio.pause();
      audio.currentTime = 0;
      setAudio(null);
    }

    try {
      // Delete the audio file
      await deleteAudioFile({
        path: "/ivr/deleteFile",
        content: {
          language: localAudioFileId,
          fileName: localAudioFileName
        }
      });

      // Get current MediaCenter JSON
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      const { headerItems, languageSelected } = allMediaCenterJson.data.data;

      // Update the specific item in headerItems
      const updatedHeaderItems = headerItems.map(item => {
        if (item.id === rowKeyValue) {
          return {
            ...item,
            properties: item.properties.map(prop =>
              prop.language === column.key
                ? { ...prop, audioFileId: '', audioFileName: '' }
                : prop
            )
          };
        }
        return item;
      });

      // Save the updated MediaCenter JSON
      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected,
          headerItems: updatedHeaderItems
        }
      });

      // Update local state
      setLocalAudioFileId('');
      setLocalAudioFileName('');
      setAudioFilePath('');

      enqueueSnackbar("Audio file deleted successfully", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error deleting audio file:', error);
      enqueueSnackbar("Error deleting audio file", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <div className="custom-editor">
      <div className="uploaded-file" style={{ display: "flex", gap: "10px", alignItems: "center", paddingLeft: "10px" }}>
        <div className="upload-container" >
          <input
            type="file"
            id={`fileInput-${rowKeyValue}-${column.key}`}
            accept="audio/mp3, audio/wav"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <Tooltip title={localAudioFileId ? "Re-Upload Audio" : "Upload Audio"}>
            <label
              htmlFor={`fileInput-${rowKeyValue}-${column.key}`}
              className="upload-button"
              style={{ cursor: "pointer" }}
            >
              {isUploading ? (
                <CircularProgress size={20} />
              ) : (
                <FaUpload  style={{ color: localAudioFileId ? "green" : "inherit" }} />
              )}
            </label>
          </Tooltip>
        </div>

        {localAudioFileId && (
          <>
            {isLoadingAudio ? (
              <CircularProgress size={20} />
            ) : (
              <Tooltip title={isPlaying ? "Pause Audio" : "Play Audio"}>
                {isPlaying ? (
                  <Pause fontSize="small" style={{ cursor: "pointer", color: "green",marginBottom:"3px" }} onClick={handlePlayPauseAudio} />
                ) : (
                  <PlayArrow fontSize="small" style={{ cursor: "pointer", color: "green" ,marginBottom:"3px" }} onClick={handlePlayPauseAudio} />
                )}
              </Tooltip>
            )}
            {isRowEditing && (
              <Tooltip title="Delete Audio">
                <Delete
                  fontSize="small"
                  style={{ cursor: "pointer", color: "red",marginBottom:"5px" }}
                  onClick={() => setShowDeleteConfirm(true)}
                />
              </Tooltip>
            )}
          </>
        )}
      </div>
      {showDeleteConfirm && (
        <AlertDelete
          name="audio file"
          handleConfirmDelete={() => {
            handleDeleteAudio();
            setShowDeleteConfirm(false);
          }}
          isDelete={showDeleteConfirm}
          setIsDelete={setShowDeleteConfirm}
        />
      )}
    </div>
  );
};

const DeleteRowComponent = ({
  rowKeyValue,
  deleteItemName,
  isEditing,
  onEditClick,
  onSaveClick,
  onDeleteClick
}) => {
  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {isEditing ? (
        <Tooltip title="Save">
          <Save
            fontSize="small"
            sx={{ cursor: "pointer", color: "green" }}
            onClick={() => onSaveClick(rowKeyValue)}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Edit">
          <Edit
            fontSize="small"
            sx={{ cursor: "pointer", color: "blue" }}
            onClick={() => onEditClick(rowKeyValue)}
          />
        </Tooltip>
      )}
      <Tooltip title="Delete">
        <Delete
          fontSize="small"
          sx={{ cursor: "pointer", color: "red" }}
          onClick={() => onDeleteClick(rowKeyValue, deleteItemName)}
        />
      </Tooltip>
    </div>
  );
};

const MediaCenterComponent = () => {
  const [columnName, setColumnName] = useState("");
  const [columns, setColumns] = useState([
    { key: "type", title: "", width: 90 },
    {
      key: "name",
      title: "Word/Phrase",
      width: 80,
      isEditable: true,
      dataType: DataType.String
    },
    { key: "ENG", title: "ENG (Default)", width: 90 },
    { key: "Hindi", width: 90, title: "Hindi" },
    { key: ":delete", width: 90, title: "Action" },
  ]);
  const [rows, setRows] = useState([]);
  const [createName, setCreateName] = useState("");
  const [createGroup, setCreateGroup] = useState("");
  const [openCreateTableDiologue, setOpenCreateTableDiologue] = useState(false);
  const [closeColumnDiologueBox, setCloseColumnDiologueBox] = useState(false);
  const [isSelectExistingRow, setIsSelectExistingRow] = React.useState(false);
  const [isSelectNewRow, setIsSelectNewRow] = React.useState(false);
  const [isShowSearchBox, setIsShowSearchBox] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [deleteItemTitle, setDeleteItemTitle] = React.useState("");
  const [isEditColumnName, setIsEditColumnName] = React.useState(false);
  const { darkMode } = useMaterialUIController()[0];
  const [isConfirmDeleteColumn, setIsConfirmDeleteColumn] =
    React.useState(false);
  const [isConfirmDeleteRow, setIsConfirmDeleteRow] = React.useState(false);
  const [deleteRowDetails, setDeleteRowDetails] = React.useState({});
  const [editedColumnName, setEditedColumnName] = React.useState("");
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);
  const [languageList, setLanguageList] = useState([])


  const [getLanguages] = useGetLanguagesMutation()
  const [getAllMediaCenterJson] = useGetAllMediaCenterJsonMutation()
  const [saveMediaCenterJson] = useSaveMediaCenterJsonMutation()
  const [deleteAudioFile] = useDeleteAudioFileMutation();

  const [editingRowId, setEditingRowId] = useState(null);
  const [editedName, setEditedName] = useState("");

  const handleEditClick = (rowId) => {
    const row = rows.find(r => r.id === rowId);
    setEditedName(row.name);
    setEditingRowId(rowId);
  };

  const handleSaveClick = async (rowId) => {
    try {
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      const { headerItems, languageSelected } = allMediaCenterJson.data.data;

      // Update the name in headerItems
      const updatedHeaderItems = headerItems.map(item => {
        if (item.id === rowId) {
          return {
            ...item,
            name: editedName
          };
        }
        return item;
      });

      // Save the updated JSON
      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected,
          headerItems: updatedHeaderItems
        }
      });

      // Update local state
      setRows(prevRows =>
        prevRows.map(row =>
          row.id === rowId ? { ...row, name: editedName } : row
        )
      );

      dispatch(updateCellValue(rowId, 'name', editedName));
      setEditingRowId(null);
      setEditedName("");

      enqueueSnackbar("Name updated successfully", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error updating name:', error);
      enqueueSnackbar("Error updating name", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      if (allMediaCenterJson.data) {
        const { headerItems, languageSelected } = allMediaCenterJson.data.data;

        const newRows = headerItems.map(item => ({
          id: item.id,
          type: item.type,
          name: item.name,
          properties: item.properties
        }));

        setRows(newRows);

        const newColumns = [
          { key: "type", title: "", width: 90, dataType: DataType.String },
          { dataType: DataType.String, key: "name", title: "Word/Phrase", width: 80 },
          ...languageSelected.map(lang => ({
            key: lang.language,
            title: lang.language,
            width: 90
          })),
          { key: ":delete", width: 90, title: "Actions" }
        ];

        // Add dropdown to columns
        const columnsWithDropdown = newColumns.map((item) => {
          if (item.key !== "name" && item.key !== ":delete" && item.key !== "type") {
            const isDefaultLanguage = languageSelected.find(lang =>
              lang.language === item.key
            )?.isDefault;

            return {
              ...item,
              title: (
                <Box key={item.key} sx={{
                  display: "flex",
                  gap: "5px",
                  textAlign: "center",
                  padding: '4px',
                  borderRadius: '4px'
                }}>
                  {isDefaultLanguage && <Star fontSize="small" sx={{ color: 'gold' }} />}
                  <Typography variant="p">{item.title}</Typography>
                  <DropDown
                    handleDelete={() => handleDeleteColumn(item.key, item.title)}
                    handleSetDefault={() => handleSetDefaultLanguage(item.key)}
                    isDefault={isDefaultLanguage}
                  />
                </Box>
              ),
            };
          }
          return item;
        });

        setColumns(columnsWithDropdown);

        changeTableProps(prevState => ({
          ...prevState,
          data: newRows,
          columns: columnsWithDropdown,
          editableCells: newRows.flatMap(row =>
            columnsWithDropdown
              .filter(col => col.key !== "type" && col.key !== "name" && col.key !== ":delete")
              .map(col => ({ columnKey: col.key, rowKeyValue: row.id }))
          )
        }));
      }
      return allMediaCenterJson;
    }

    async function getLanguageList() {
      const masterData = await fetchData();
      const { languageSelected } = masterData.data.data;

      const getLanguageList = await getLanguages({
        getDataUrl: "/ivr/languages"
      });

      // Filter out languages that are already in the columns
      const filteredLanguageList = getLanguageList.data.filter(item =>
        !languageSelected.some(lang => lang.language === item.language)
      );

      setLanguageList(filteredLanguageList);
    }

    getLanguageList();
  }, []);

  useEffect(() => {
    // This effect can be used for any actions that need to happen when rows change
  }, [rows]);

  const [tableProps, changeTableProps] = useState({
    data: rows,
    columns: columns.map((item, idx) => {
      if (
        item.key !== "name" &&
        item.key !== ":delete" &&
        item.key !== "type" &&
        item.key !== "type"
      ) {
        return {
          ...item,
          title: (
            <Box
              key={item.key}
              sx={{ display: "flex", gap: "5px", textAlign: "center" }}
            >
              <Typography variant="p">{item.title}</Typography>
              <DropDown
                handleDelete={() => handleDeleteColumn(item.key, item.title)}
                handleEditColumn={() =>
                  handleEditColumnName(item.key, item.title)
                }
              />
            </Box>
          ),
        };
      }
      return item;
    }),
    editableCells: rows.flatMap((item) => {
      return [
        { columnKey: "HIN", rowKeyValue: item.id },
        { columnKey: "ENG", rowKeyValue: item.id },
      ];
    }),
    editingMode: EditingMode.None, // Change this from EditingMode.Cell
    rowKeyField: "id",

    search: ({ searchText, rowData, column }) => {
      if (column.key === "passed") {
        return (
          (searchText === "false" && !rowData.passed) ||
          (searchText === "true" && rowData.passed)
        );
      }
    },
    searchText: "",
  });
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  // Notistack Configuration
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDeleteColumn = (itemToDelete, titleOfItem) => {
    setIsConfirmDeleteColumn(true);
    setDeleteItemTitle({ titleOfItem, itemToDelete });
  };

  const handleDeleteColumnConf = async () => {
    const allMediaCenterJson = await getAllMediaCenterJson({
      getDataUrl: "/ivr/get/mediaCenterJson"
    });
    const { languageSelected, headerItems } = allMediaCenterJson.data.data;
    const filteredLanguageSelected = languageSelected.filter((item) => item.language !== deleteItemTitle.itemToDelete)

    // Collect all audio files to be deleted
    const audioFilesToDelete = headerItems.flatMap(item =>
      item.properties.filter(prop => prop.language === deleteItemTitle.itemToDelete)
    );

    // Delete audio files
    for (const audioFile of audioFilesToDelete) {
      if (audioFile.audioFileId && audioFile.audioFileName) {
        try {
          await deleteAudioFile({
            path: "/ivr/deleteFile",
            content: {
              language: audioFile.audioFileId,
              fileName: audioFile.audioFileName
            }
          });
        } catch (error) {
          console.error('Error deleting audio file:', error);
          // Consider how you want to handle errors (e.g., continue with other deletions, show a notification)
        }
      }
    }

    const filteredHeaderItems = headerItems.map((item) => ({
      ...item,
      properties: item.properties.filter((prop) => prop.language !== deleteItemTitle.itemToDelete)
    }));

    await saveMediaCenterJson({
      path: "/ivr/save/mediaCenterJson",
      content: {
        languageSelected: filteredLanguageSelected,
        headerItems: filteredHeaderItems
      }
    });

    setColumns((prevColumns) =>
      prevColumns.filter((item) => item.key !== deleteItemTitle.itemToDelete)
    );

    // Fetch the full list of languages
    const getLanguageList = await getLanguages({
      getDataUrl: "/ivr/languages"
    });

    // Update the languageList to include only languages not in use
    const updatedLanguageList = getLanguageList.data.filter(lang =>
      !filteredLanguageSelected.some(selected => selected.language === lang.language)
    );
    setLanguageList(updatedLanguageList);

    setDeleteItemTitle("");
    setIsConfirmDeleteColumn(false);
    enqueueSnackbar("Column and associated audio files deleted successfully", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      action: (key) => (
        <Button
          sx={{ color: "#FFF" }}
          size="small"
          onClick={() => closeSnackbar(key)}
        >
          Dismiss
        </Button>
      ),
    });
  };

  const handleEditColumnName = (itemToEdit, titleOfItem) => {
    setDeleteItemTitle({ titleOfItem, itemToEdit });
    setIsEditColumnName(true);
  };
  const handleInputColumnName = (e) => {
    setEditedColumnName(e.currentTarget.value);
  };

  const handleSaveColumn = async (selectedLanguages) => {
    const allMediaCenterJson = await getAllMediaCenterJson({
      getDataUrl: "/ivr/get/mediaCenterJson"
    });
    const { headerItems, languageSelected } = allMediaCenterJson.data.data;

    // Add new languages to languageSelected
    const updatedLanguageSelected = [
      ...languageSelected,
      ...selectedLanguages.map(lang => ({ id: uuidv4(), language: lang.language }))
    ];

    // Update headerItems to include new language properties
    const updatedHeaderItems = headerItems.map(item => ({
      ...item,
      properties: [
        ...item.properties,
        ...selectedLanguages.map(lang => ({
          id: uuidv4(),
          language: lang.language,
          audioFileId: '',
          audioFileName: ''
        }))
      ]
    }));

    // Save updated JSON
    await saveMediaCenterJson({
      path: "/ivr/save/mediaCenterJson",
      content: {
        languageSelected: updatedLanguageSelected,
        headerItems: updatedHeaderItems
      }
    });

    // Update columns state
    const newColumns = selectedLanguages.map(lang => ({
      key: lang.language,
      title: lang.language,
      width: 90
    }));

    setColumns(prevColumns => [
      ...prevColumns.slice(0, -1), // Remove the last column (delete column)
      ...newColumns,
      prevColumns[prevColumns.length - 1] // Add back the delete column
    ]);

    // Update rows state
    setRows(prevRows => prevRows.map(row => ({
      ...row,
      properties: [
        ...row.properties,
        ...selectedLanguages.map(lang => ({
          id: uuidv4(),
          language: lang.language,
          audioFileId: '',
          audioFileName: ''
        }))
      ]
    })));

    // Update tableProps
    changeTableProps(prevState => ({
      ...prevState,
      columns: [
        ...prevState.columns.slice(0, -1),
        ...newColumns,
        prevState.columns[prevState.columns.length - 1]
      ],
      editableCells: [
        ...prevState.editableCells,
        ...rows.flatMap(row =>
          newColumns.map(col => ({ columnKey: col.key, rowKeyValue: row.id }))
        )
      ]
    }));

    // Update languageList
    setLanguageList(prevList =>
      prevList.filter(lang => !selectedLanguages.some(selected => selected.language === lang.language))
    );

    enqueueSnackbar("Languages added successfully", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: 3000,
    });
  };

  const handleSaveGroup = async () => {
    const allMediaCenterJson = await getAllMediaCenterJson({
      getDataUrl: "/ivr/get/mediaCenterJson"
    });
    const { headerItems, languageSelected } = allMediaCenterJson.data.data;
    const columnsAccordingToSelectedLanguage = languageSelected.map((item) => ({
      id: item.id,
      language: item.language,
    }));

    if (createName !== "" && createGroup !== "") {
      const newRow = {
        id: uuidv4(),
        type: createGroup,
        name: createName, // Use 'name' instead of 'rowName'
        properties: columnsAccordingToSelectedLanguage.map(lang => ({
          ...lang,
          audioFileId: ''
        }))
      };

      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected: languageSelected,
          headerItems: [...headerItems, newRow]
        }
      });

      setRows([...rows, newRow]);

      const newEditableCells = columns
        .filter(column => column.key !== ":delete" && column.key !== "name" && column.key !== "type")
        .map((column) => ({
          columnKey: column.key,
          rowKeyValue: newRow.id,
        }));

      changeTableProps((prevState) => ({
        ...prevState,
        data: [...prevState.data, newRow],
        editableCells: [...prevState.editableCells, ...newEditableCells],
      }));

      // Reset the input fields
      setCreateGroup("");
      setCreateName("");
      setOpenCreateTableDiologue(false);
      setIsSelectExistingRow(false);
      setIsSelectNewRow(false);
      enqueueSnackbar("Row created successfully", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        action: (key) => (
          <Button
            sx={{ color: "#FFF" }}
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Dismiss
          </Button>
        ),
      });
    } else {
      enqueueSnackbar("Please fill the input fields", {
        variant: "warning",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        action: (key) => (
          <Button
            sx={{ color: "#FFF" }}
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Dismiss
          </Button>
        ),
      });
    }
  };
  const handleNewData = () => {
    setIsSelectNewRow(true);
    setOpenCreateTableDiologue(true);
  };
  const handleClickOpen = () => {
    setOpenCreateTableDiologue(true);
  };
  const handleSearchText = (e) => {
    dispatch(search(e.currentTarget.value));
    setSearchText(e.currentTarget.value);
  };
  const handleOpenSearchDiologue = (e) => {
    setIsShowSearchBox(true);
  };
  const handleCloseSearchDiologue = (e) => {
    setIsShowSearchBox(false);
  };
  const handleClearSearchText = () => {
    setSearchText("");
    dispatch(search(""));
  };

  const handleAudioPlay = (audioId) => {
    if (currentlyPlayingId && currentlyPlayingId !== audioId) {
      // Stop the currently playing audio
      const audioElement = document.querySelector(`audio[data-audio-id="${currentlyPlayingId}"]`);
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    }
    setCurrentlyPlayingId(audioId);
  };

  const handleNameCellEdit = async (rowKeyValue, newValue) => {
    try {
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      const { headerItems, languageSelected } = allMediaCenterJson.data.data;

      // Update the name in headerItems
      const updatedHeaderItems = headerItems.map(item => {
        if (item.id === rowKeyValue) {
          return {
            ...item,
            name: newValue
          };
        }
        return item;
      });

      // Save the updated JSON
      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected,
          headerItems: updatedHeaderItems
        }
      });

      // Update local state
      setRows(prevRows =>
        prevRows.map(row =>
          row.id === rowKeyValue ? { ...row, name: newValue } : row
        )
      );

      dispatch(updateCellValue(rowKeyValue, 'name', newValue));

      enqueueSnackbar("Name updated successfully", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error updating name:', error);
      enqueueSnackbar("Error updating name", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  };

  const handleConfirmDeleteRow = async () => {
    try {
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      const { headerItems, languageSelected } = allMediaCenterJson.data.data;

      // Find the row to delete
      const rowToDelete = headerItems.find(item => item.id === deleteRowDetails.rowKeyValue);

      // Delete associated audio files
      if (rowToDelete) {
        for (const property of rowToDelete.properties) {
          if (property.audioFileId && property.audioFileName) {
            try {
              await deleteAudioFile({
                path: "/ivr/deleteFile",
                content: {
                  language: property.audioFileId,
                  fileName: property.audioFileName
                }
              });
            } catch (error) {
              console.error('Error deleting audio file:', error);
            }
          }
        }
      }

      // Filter out the deleted row
      const updatedHeaderItems = headerItems.filter(
        item => item.id !== deleteRowDetails.rowKeyValue
      );

      // Save the updated JSON
      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected,
          headerItems: updatedHeaderItems
        }
      });

      // Update local state
      setRows(prevRows =>
        prevRows.filter(row => row.id !== deleteRowDetails.rowKeyValue)
      );

      dispatch(deleteRow(deleteRowDetails.rowKeyValue));
      setIsConfirmDeleteRow(false);
      setDeleteRowDetails({});

      enqueueSnackbar("Row deleted successfully", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error deleting row:', error);
      enqueueSnackbar("Error deleting row", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  };

  // console.log("columns", columns)
  // console.log("rows", rows);

  const handleSetDefaultLanguage = async (languageKey) => {
    try {
      const allMediaCenterJson = await getAllMediaCenterJson({
        getDataUrl: "/ivr/get/mediaCenterJson"
      });

      const { headerItems, languageSelected } = allMediaCenterJson.data.data;

      // Update languageSelected to set the new default and remove old default
      const updatedLanguageSelected = languageSelected.map(lang => ({
        ...lang,
        isDefault: lang.language === languageKey
      }));

      // Save the updated JSON
      await saveMediaCenterJson({
        path: "/ivr/save/mediaCenterJson",
        content: {
          languageSelected: updatedLanguageSelected,
          headerItems
        }
      });

      // Update all columns immediately to reflect the change
      setColumns(prevColumns => {
        return prevColumns.map(col => {
          const isCurrentLanguage = col.key === languageKey;

          if (col.key !== "name" && col.key !== ":delete" && col.key !== "type") {
            return {
              ...col,
              title: (
                <Box sx={{
                  display: "flex",
                  gap: "5px",
                  textAlign: "center",
                  padding: '4px',
                  borderRadius: '4px'
                }}>
                  {isCurrentLanguage && <Star fontSize="small" sx={{ color: 'gold' }} />}
                  <Typography variant="p">{col.key}</Typography>
                  <DropDown
                    handleDelete={() => handleDeleteColumn(col.key, col.key)}
                    handleSetDefault={() => handleSetDefaultLanguage(col.key)}
                    isDefault={isCurrentLanguage}
                  />
                </Box>
              )
            };
          }
          return col;
        });
      });

      // Update the languageSelected state if you're maintaining it separately
      // setLanguageSelected(updatedLanguageSelected);

      enqueueSnackbar(`${languageKey} set as default language`, {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error setting default language:', error);
      enqueueSnackbar("Error setting default language", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div style={{ position: "relative", fontFamily: "Roboto" }}>
      {/* Move toolbar icons to align with column headers */}
      <div style={{
        position: "absolute",
        top: "0",  // Changed from "12"
        left: "0",
        height: "57px", // Match header height
        display: "flex",
        alignItems: "center",
        zIndex: "1000",
        gap: "8px",
        paddingLeft: "8px"
      }}>
        <MediacenterDialogueConfig
          handleClickOpen={handleClickOpen}
          isSelectNewRow={isSelectNewRow}
          setIsSelectNewRow={setIsSelectNewRow}
          setIsSelectExistingRow={setIsSelectExistingRow}
          isSelectExistingRow={isSelectExistingRow}
          createGroup={createGroup}
          rows={rows}
          setCloseColumnDiologueBox={setCloseColumnDiologueBox}
          closeColumnDiologueBox={closeColumnDiologueBox}
          handleSaveGroup={handleSaveGroup}
          handleSaveColumn={handleSaveColumn}
          setCreateGroup={setCreateGroup}
          setCreateName={setCreateName}
          openCreateTableDiologue={openCreateTableDiologue}
          setOpenCreateTableDiologue={setOpenCreateTableDiologue}
          setColumnName={setColumnName}
          languageList={languageList}
          setLanguageList={setLanguageList}
        />

        {/* Filter/Funnel icon */}
        {rows.length > 0 && (
          <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
            {searchText !== "" ? (
              <Tooltip title="Clear Search" arrow placement="right">
                <div style={{ cursor: "pointer" }}>
                  <IoFunnel
                    color="green"
                    fill="lightGreen"
                    onClick={handleClearSearchText}
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Search" arrow placement="right">
                <div style={{ cursor: "pointer" }}>
                  <IoFunnel
                    style={{
                      color: darkMode ? "#fff" : "#202940",
                    }}
                    onClick={handleOpenSearchDiologue}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {/* Search box */}
      {isShowSearchBox && (
        <SearchBar
          handleSearchText={handleSearchText}
          onClose={handleCloseSearchDiologue}
          searchText={searchText}
          isShowSearchBox={isShowSearchBox}
          setIsShowSearchBox={setIsShowSearchBox}
        />
      )}

      {rows.length > 0 && (
        <>
          <div className={darkMode ? "darkTheme" : "lightTheme"}>
            <Table
              {...tableProps}
              dispatch={dispatch}
              columns={columns}
              rowKeyField="id"
              groups={[{ columnKey: "type" }]}
              childComponents={{
                cell: {
                  content: (props) => {
                    if (props.column.key === "name") {
                      const isEditing = editingRowId === props.rowData.id;
                      return isEditing ? (
                        <input
                          style={{
                            width: '100%',
                            backgroundColor: darkMode ? '#202940' : '#FFFFFF',
                            border: '1px solid #1A73E8',
                            borderRadius: '4px',
                            color: darkMode ? 'white' : 'black',
                            padding: '5px',
                            outline: 'none',
                            boxShadow: '0 0 0 2px rgba(26, 115, 232, 0.1)',
                            transition: 'all 0.2s ease-in-out'
                          }}
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          autoFocus
                        />
                      ) : (
                        <div style={{
                          padding: '5px',
                          width: '100%',
                          height: '100%'
                        }}>
                          {props.value}
                        </div>
                      );
                    }
                    if (props.column.key !== "type" && props.column.key !== ":delete") {
                      return (
                        <CustomUploader
                          {...props}
                          rowData={props.rowData}
                          currentlyPlayingId={currentlyPlayingId}
                          onAudioPlay={handleAudioPlay}
                          isRowEditing={editingRowId === props.rowData.id}
                        />
                      );
                    }
                    return props.value;
                  },
                },
                noDataRow: {
                  content: () =>
                    rows.length === 0 ? (
                      "No Data Found"
                    ) : (
                      <h2 style={{ color: "#fff" }}>No Data Found</h2>
                    ),
                },
                dataRow: {
                  elementAttributes: ({ rowData }) => ({
                    style: {
                      backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                      color: darkMode ? "white" : "black",
                    },
                  }),
                },
                cellText: {
                  content: (props) => {
                    switch (props.column?.key) {
                      case ":delete":
                        return (
                          <DeleteRowComponent
                            rowKeyValue={props.rowKeyValue}
                            deleteItemName={props.rowData.name}
                            isEditing={editingRowId === props.rowKeyValue}
                            onEditClick={handleEditClick}
                            onSaveClick={handleSaveClick}
                            onDeleteClick={(rowKeyValue, deleteItemName) => {
                              setDeleteRowDetails({ rowKeyValue, deleteItemName });
                              setIsConfirmDeleteRow(true);
                            }}
                          />
                        );
                      default:
                        return props.value;
                    }
                  },
                  elementAttributes: ({ rowData }) => ({
                    style: {
                      color: darkMode ? "white" : "black",
                    },
                  }),
                },
                groupCell: {
                  elementAttributes: () => ({
                    style: {
                      backgroundColor: darkMode ? "#1a2035" : "#FAFAFA",
                      color: darkMode ? "#fff" : "#000",
                      border: darkMode
                        ? "2px solid #202940"
                        : "2px solid #f1f1f1",
                    },
                  }),
                },

                groupExpandButton: {
                  elementAttributes: () => ({
                    style: {
                      fill: !darkMode ? "#000" : "white",
                    },
                  }),
                },
                headCell: {
                  elementAttributes: () => ({
                    style: {
                      backgroundColor: darkMode ? "#1a2035" : "#ffffff",
                      color: darkMode ? "#fff" : "#000",
                    },
                  }),
                },
              }}
            />
            {isConfirmDeleteColumn && (
              <AlertDelete
                name={deleteItemTitle.titleOfItem}
                handleConfirmDelete={handleDeleteColumnConf}
                isDelete={isConfirmDeleteColumn}
                setIsDelete={setIsConfirmDeleteColumn}
              />
            )}
            {isConfirmDeleteRow && (
              <AlertDelete
                name={deleteRowDetails.deleteItemName}
                handleConfirmDelete={handleConfirmDeleteRow}
                isDelete={isConfirmDeleteRow}
                setIsDelete={setIsConfirmDeleteRow}
              />
            )}
            {isEditColumnName && (
              <InputBox
                changeTableProps={changeTableProps}
                columns={columns}
                setColumns={setColumns}
                deleteItemTitle={deleteItemTitle}
                handleInputColumnName={handleInputColumnName}
                editedColumnName={editedColumnName}
                isEditColumnName={isEditColumnName}
                setEditedColumnName={setEditedColumnName}
                setIsEditColumnName={setIsEditColumnName}
                rows={rows}
                setRows={setRows}
              />
            )}
          </div>
        </>
      )}
      {rows.length === 0 && <EmptyContainer handleNewData={handleNewData} />}
    </div>
  );
};

export default MediaCenterComponent;