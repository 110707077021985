import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import RestoreIcon from "@mui/icons-material/Restore";
import DownloadIcon from "@mui/icons-material/Download";

function BackupRestoreAction({
  row,
  onBackup,
  onRestore,
  onDownload,
}) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">

      <tooltip title="Backup">
        {onBackup && (
          <IconButton
            color="success"
            disabled={
              row.original.backupPath !== null && row.original.backupPath !== ""
            }
            onClick={() => onBackup(row.original)}
          >
            <BackupIcon></BackupIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Restore">
        {onRestore && (
          <IconButton
            color="info"
            disabled={
              row.original.backupPath === null || row.original.backupPath === ""
            }
            onClick={() => onRestore(row.original)}
          >
            <RestoreIcon></RestoreIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Download">
        {onDownload && (
          <IconButton
            color="error"
            disabled={
              row.original.backupPath === null || row.original.backupPath === ""
            }
            onClick={() => onDownload(row.original)}
          >
            <DownloadIcon></DownloadIcon>
          </IconButton>
        )}
      </tooltip>

     
    </MDTypography>
  );
}

export default BackupRestoreAction;
