import DefaultCell from "application/components/DefaultCell";
import CTLDownloadFile from "application/components/CTLDownloadFile";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import PAPlayAudio from "application/components/PAPlayAudio";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "customerCDR/callerCall/list",
  pdfDownload: "customerCDR/generateAndDownloadPdf",
  csvDownload: "customerCDR/callerCall/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent ID",
      accessor: "ccAgent",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "agentFullName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign Name",
      accessor: "campaign",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "direction",
      accessor: "callDirection",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID",
      accessor: "agentTalkedTo",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "hangupCause",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Stamp",
      accessor: "startTime",
      displayInList: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Stamp",
      accessor: "answerTime",
      displayInList: false,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Stamp",
      accessor: "endTime",
      displayInList: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "agentTalkTime",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Status",
      accessor: "status",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Play",
      accessor: "",
      displayInList: true,
      Cell: ({ row }) => <PAPlayAudio row={row} callerCallDataAnalysis={"callerCallDataAnalysis"}/>,
    },
    {
      Header: "Recording File",
      accessor: "recordingFile",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <CTLDownloadFile row={row} value={value} callerCallDataAnalysis={"callerCallDataAnalysis"}/>
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
