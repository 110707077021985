import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SaveIcon from "@mui/icons-material/Save";

function AlertSaveCancel({
  isSaving,
  setIsSaving,
  handleConfirmSave,
  buttonText,
  name,
}) {
  const { darkMode } = useMaterialUIController()[0];

  const handleClose = () => {
    setIsSaving(false);
  };

  return (
    <Box>
      <Dialog
        // fullWidth={fullWidth}
        maxWidth="sm"
        open={Boolean(isSaving)}
        onClose={handleClose}
      >
        {/* <DialogTitle textAlign="center"> </DialogTitle> */}
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            width: "30rem",
            paddingBottom: "20px",
          }}
        >
          <Box
            sx={{
              fontSize: "2rem",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <MDTypography
              variant="h3"
              sx={{ fontWeight: "400", margin: "10px" }}
            >
              Confirmation
            </MDTypography>
            {/* <SaveIcon /> */}
          </Box>

          <MDTypography variant="h5" sx={{ fontWeight: "400", margin: "10px" }}>
            {" "}
            Do you really want to {name}?
          </MDTypography>
        </MDBox>

        <DialogActions
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}
        >
          <MDBox mr={1} display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleConfirmSave}
            >
              {buttonText}
            </MDButton>
            <MDButton
               variant="gradient"
               color="error"
               size="small"
              onClick={handleClose}
            >
              Close
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AlertSaveCancel;
