import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function AgentDetails({
  iconColor,
  details,
  detailsDescription,
  light,
  tooltipDescription
}) {

  return (
    <MDBox mr={{ xs: 0, xl: 8 }} display="flex" flexDirection="row">
      <MDBox display="flex">
        <tooltip title={tooltipDescription}>
        <Icon color={iconColor}>{details}</Icon>
        </tooltip>
        <MDTypography
          ml={1}
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={light ? "white" : "dark"}
          noWrap
        >
          : {detailsDescription}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default AgentDetails;
