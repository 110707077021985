import CTLACDCell from "application/components/CTLACDCell";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { useState } from "react";
import formControl from "globals/form-control";
import { useMaterialUIController, setShowAgent } from "context";

const operationURLS = {
  queueCreate: "/queue/create",
};

const getColumns = (onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { showAgent } = controller;

  const onChangeAgent = (event, formik) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setShowAgent(dispatch, isChecked);
    } else {
      setShowAgent(dispatch, "");
    }
  };

  // const [requireddidNumber, setRequireddidNumber] = useState("");
  // const [requiredodNumber, setRequireddodNumber] = useState("");
  // const checkselect = (e) => {
  //   const selectedValue = e.target.value;

  //   if (selectedValue === "0") {
  //     setRequireddidNumber(formControl.string().required("Required"));
  //     setRequireddodNumber("");
  //   } else if (selectedValue === "1") {
  //     setRequireddidNumber("");
  //     setRequireddodNumber(formControl.string().required("Required"));
  //   } else if (selectedValue === "2") {
  //     setRequireddidNumber(formControl.string().required("Required"));
  //     setRequireddodNumber(formControl.string().required("Required"));
  //   } else {
  //     setRequireddidNumber("");
  //     setRequireddodNumber("");
  //   }
  // };

  return [
    {
      Header: "ACD Type",
      accessor: "acdType",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      width: 6,
      Cell: ({ value }) => <CTLACDCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "InBound" },
          { id: "1", description: "OutBound" },
          { id: "2", description: "Blended" },
          { id: "3", description: "WhatsApp" },
          { id: "4", description: "ApplicationChat" },
        ],
      },
      validation: formControl.string().required("Required").nullable(true),
      // onChange: checkselect,
    },
    {
      Header: "ACD Number",
      accessor: "acdNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Shortcode",
      accessor: "shortCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DidNumber",
      accessor: "didNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      // validation: requireddidNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DodNumber",
      accessor: "dodNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      // validation: requiredodNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },
    {
      Header: "CreateAgent",
      accessor: "createAgent",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangeAgent,
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      allowInForm: showAgent,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Agent To",
      accessor: "to",
      allowInForm: showAgent,
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Manual Queue",
      accessor: "defaultQueue",
      displayInList: false,
      allowInForm: true,
      controlType: "select",
      allowInForm: showAgent,
      width: 6,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          // { id: "1", description: "Acd Queue 1" },
          // { id: "2", description: "Acd Queue 2" },
          // { id: "3", description: "Acd Queue 3" },
          // { id: "4", description: "Acd Queue 4" },
          // { id: "5", description: "Acd Queue 5" },
          // { id: "6", description: "Acd Queue 6" },
          // { id: "7", description: "Acd Queue 7" },
          // { id: "8", description: "Acd Queue 8" },
          // { id: "9", description: "Acd Queue 9" },
          // { id: "10", description: "Acd Queue 10" },
          // { id: "11", description: "Acd Queue 11" },
          // { id: "12", description: "Acd Queue 12" },
          // { id: "13", description: "Acd Queue 13" },
          // { id: "14", description: "Acd Queue 14" },
          // { id: "15", description: "Acd Queue 15" },
          // { id: "16", description: "Acd Queue 16" },
          // { id: "17", description: "Acd Queue 17" },
          // { id: "18", description: "Acd Queue 18" },
          // { id: "19", description: "Acd Queue 19" },
          // { id: "20", description: "Acd Queue 20" },
          // { id: "21", description: "Acd Queue 21" },
          // { id: "22", description: "Acd Queue 22" },
          // { id: "23", description: "Acd Queue 23" },
          // { id: "24", description: "Acd Queue 24" },
          // { id: "25", description: "Acd Queue 25" },
          // { id: "26", description: "Acd Queue 26" },
          // { id: "27", description: "Acd Queue 27" },
          // { id: "28", description: "Acd Queue 28" },
          // { id: "29", description: "Acd Queue 29" },
          // { id: "30", description: "Acd Queue 30" },
          // { id: "31", description: "Acd Queue 31" },
          // { id: "32", description: "Acd Queue 32" },
          // { id: "33", description: "Acd Queue 33" },
          // { id: "34", description: "Acd Queue 34" },
          // { id: "35", description: "Acd Queue 35" },
          // { id: "36", description: "Acd Queue 36" },
          // { id: "37", description: "Acd Queue 37" },
          // { id: "38", description: "Acd Queue 38" },
          // { id: "39", description: "Acd Queue 39" },
          // { id: "40", description: "Acd Queue 40" },
          // { id: "41", description: "Acd Queue 41" },
          // { id: "42", description: "Acd Queue 42" },
          // { id: "43", description: "Acd Queue 43" },
          // { id: "44", description: "Acd Queue 44" },
          // { id: "45", description: "Acd Queue 45" },
          // { id: "46", description: "Acd Queue 46" },
          // { id: "47", description: "Acd Queue 47" },
          // { id: "48", description: "Acd Queue 48" },
          // { id: "49", description: "Acd Queue 49" },
          // { id: "50", description: "Acd Queue 50" },
          { id: "51", description: "Acd Queue 51" },
          { id: "52", description: "Acd Queue 52" },
          { id: "53", description: "Acd Queue 53" },
          { id: "54", description: "Acd Queue 54" },
          { id: "55", description: "Acd Queue 55" },
          { id: "56", description: "Acd Queue 56" },
          { id: "57", description: "Acd Queue 57" },
          { id: "58", description: "Acd Queue 58" },
          { id: "59", description: "Acd Queue 59" },
          { id: "60", description: "Acd Queue 60" },
          { id: "61", description: "Acd Queue 61" },
          { id: "62", description: "Acd Queue 62" },
          { id: "63", description: "Acd Queue 63" },
          { id: "64", description: "Acd Queue 64" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Disposition Form",
      accessor: "dispositionForm",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "formCode",
        keyDescription: "formCode",
        values: [],
        url: "form/listAll",
      },
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MOH Sound",
      accessor: "mohSound",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "fileUpload",
      fileTypeAccept: ".mp3, .wav",
      uploadUrl: "queue/upload",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getColumns, operationURLS };
