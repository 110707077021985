import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import CTLSelectedDownload from "application/components/CTLSelectDownload";
import { application } from "globals/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getAssignedColumns } from "./assignedModel";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
function EAMS({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  onCall,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue, tableSortColumn, tableColumnDataType } =
    controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [formData, setFormData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [columns, setColumns] = useState(getColumns());
  const [loading, setLoading] = useState(false);
  const filteredList = getColumns().filter(
    (item) => item.displayInList === true
  );
  const selectedColumnDownload = useRef(
    filteredList && filteredList.map((item) => item.accessor)
  );
  const [sortColumn, setSortColumn] = useState("alertGeneratedOn");
  const [columnDataType, setColumnDataType] = useState("date");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    {
      tableSortColumn != "" && setSortColumn(tableSortColumn);
    }
    {
      tableColumnDataType != "" && setColumnDataType(tableColumnDataType);
    }
  }, [tableSortColumn, tableColumnDataType]);

  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage, tableValue]);

  function onCloseForm() {
    setOpenForm(false);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

const onAssignedAgent = (row) => {
  setFormData(row);
  setOpenForm(true);
};


  const OnSave = (data) => {
    const body = {
      assetId: data.assetName,
      ipAddress: data.ipAddress,
      category: data.category,
      severity: data.severityName,
      userId: data.userId,
    };
      application
        .post("eams/create", body)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
            status: response.data.message,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
      setOpenForm(false);
  };
  
  return (
    <MDBox>
      <CTLBaseDialogForm
        entityName="EAMS"
        columns={getAssignedColumns()}
        openForm={openForm}
        onCloseForm={onCloseForm}
        formData={formData}
        onSaveForm={OnSave}
        pageAttributes={Attributes}
      />

      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(onAssignedAgent)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="alertGeneratedOn"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
        onCall={onCall}
      />
      <CTLAdvancedFilter
        entityName="EAMS"
        columns={getColumns(onAssignedAgent)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
      <MDBox>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </MDBox>
    </MDBox>
  );
}

export default EAMS;
