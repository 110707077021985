import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
// import { WithContext as ReactTags } from "react-tag-input";
import { v4 as uuidV4 } from "uuid";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function DropdownPopup({
  openDropdownPopup,
  handleDropdownPopupClose,
  handleDelete,
  handleDrag,
  handleDropdownOptionsChange,
  handleTagKeyInputChange,
  handleTagTextInputChange,
  handleAddTag,
  handleApiUrlChange,
  handleApiUrlSubmit,
  handleSelectKeyFieldChange,
  handleSelectKeyDescriptionChange,
  isStatic,
  tags,
  tagKey,
  tagText,
  apiURL,
  isLoading,
  apiData,
  selectedKeyField,
  selectedKeyDescription,
}) {
  const isDarkMode = useMaterialUIController()[0].darkMode;

  return (
    <Dialog
      open={openDropdownPopup}
      onClose={handleDropdownPopupClose}
      fullWidth
      minWidth="sm"
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
          padding: "1em",
        })}
      >
        <FormControl>
          <FormLabel
            sx={(theme) => ({
              color: theme.palette.text.main,
            })}
          >
            Dropdown options type
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel
              checked={isStatic}
              value="Static"
              control={<Radio />}
              onChange={() => handleDropdownOptionsChange()}
              label="Static"
            />
            <FormControlLabel
              checked={!isStatic}
              value="API URL"
              control={<Radio />}
              onChange={() => handleDropdownOptionsChange()}
              label="API URL"
            />
          </RadioGroup>
        </FormControl>
        {isStatic ? (
          <>
            {/* <ReactTags
              tags={tags}
              classNames={{ remove: "tag-remove" }}
              inline
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleDrag={handleDrag}
              inputFieldPosition="bottom"
              autocomplete
              placeholder="Add new dropdown value"
              removeComponent={({ className, onRemove }) => (
                <button onClick={onRemove} className={className}>
                  x
                </button>
              )}
            /> */}
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <TextField
                value={tagKey}
                onChange={handleTagKeyInputChange}
                id="key"
                label="key"
                variant="outlined"
                size="small"
              />
              <MDTypography variant="h5">:</MDTypography>
              <TextField
                value={tagText}
                onChange={handleTagTextInputChange}
                id="text"
                label="text"
                variant="outlined"
                size="small"
              />
              <MDButton
                onClick={handleAddTag}
                variant="gradient"
                color="primary"
              >
                Add option
              </MDButton>
            </MDBox>
          </>
        ) : (
          <>
            <form onSubmit={handleApiUrlSubmit}>
              <TextField
                fullWidth
                value={apiURL}
                onChange={handleApiUrlChange}
                id="API-URL"
                label="API-URL"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: isLoading,
                }}
              />
            </form>
            {isLoading && (
              <MDBox display="flex" justifyContent="center" mt={3}>
                <CircularProgress color="info" size="2rem" />
              </MDBox>
            )}
            {apiData.length > 0 && (
              <>
                <MDTypography color="primary" my={1}>
                  Binding fields
                </MDTypography>
                <FormControl sx={{ width: "40%" }} size="large">
                  <InputLabel
                    id="select-key-field-label"
                    sx={{ color: isDarkMode && "#FFF" }}
                  >
                    Select key field
                  </InputLabel>
                  <Select
                    labelId="select-key-field-label"
                    id="select-key-field"
                    value={selectedKeyField}
                    label="Select key field"
                    onChange={handleSelectKeyFieldChange}
                    defaultValue="Select key field"
                    sx={{
                      color: isDarkMode && "#FFF",
                      padding: ".5em",
                    }}
                  >
                    <MenuItem selected value="Select key field">
                      Select key field
                    </MenuItem>
                    {apiData.map((key) => (
                      <MenuItem key={uuidV4()} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ width: "40%", marginLeft: "1em" }}
                  size="large"
                >
                  <InputLabel
                    id="select-key-description-label"
                    sx={{ color: isDarkMode && "#FFF" }}
                  >
                    Select key description
                  </InputLabel>
                  <Select
                    labelId="select-key-description-label"
                    id="select-key-description"
                    value={selectedKeyDescription}
                    label="Select key description"
                    onChange={handleSelectKeyDescriptionChange}
                    defaultValue="Select key description"
                    sx={{
                      color: isDarkMode && "#FFF",
                      padding: ".5em",
                    }}
                  >
                    <MenuItem selected value="Select key description">
                      Select key description
                    </MenuItem>
                    {apiData.map((key) => (
                      <MenuItem key={uuidV4()} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton
          variant="gradient"
          color="success"
          onClick={handleDropdownPopupClose}
        >
          OK
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DropdownPopup;