import React, { useEffect, useState } from "react";

import { styled } from "@mui/system";

import { v4 as uuidv4 } from "uuid";

import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";

import { useMaterialUIController } from "context";

import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";

import AlertDelete from "./AlertsDialogs/AlertDelete";

import FileViewer from "./FileViewer/FileViewer";

import { enqueueSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { List, IconButton, Tooltip } from "@mui/material";

import Folder from "./Folder";

import optionsData from "./options.json";

import GeoFence from "./GeoFence";
import { useGeometryDataJSON } from "./useGeometryDataJSON";
import { useGetAllPolygonMutation } from "features/geofenceSlice";
import { useGetAllRouteMutation } from "features/geofenceSlice";
import booleanIntersects from "@turf/boolean-intersects";
import { polygon, lineString } from "@turf/helpers";
import { useDeleteRouteMutation } from "features/geofenceSlice";
import { useDeletePolygonMutation } from "features/geofenceSlice";

const FileExplorerWrapper = styled("div")({
  display: "flex",
  position: "relative",
  height: "calc(100vh - 20px)",
  overflow: "hidden",
});

const FileNavigator = styled("div")(({ theme }) => ({
  width: "333px",
  height: "100%",
  overflowY: "auto",
  position: "sticky",
  top: 0,
  left: 0,
  zIndex: 1,
  backgroundColor: "inherit",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
}));

const ResizableSidebar = styled("div")({
  minWidth: "333px",
  height: "100%",
  flexShrink: 0,
  position: "relative",
  backgroundColor: "inherit",
});

const Resizer = styled("div")({
  width: "5px",

  top: 0,

  right: 0,

  cursor: "col-resize",

  height: "95%",

  position: "absolute",

  backgroundColor: "#7d7d7d",
});

const FileViewerStyle = styled("div")({
  flex: 1,
  height: "100%",
  overflowY: "auto",
  padding: "16px",
  marginTop: "-15px",
  position: "relative",
});

// const featureList = [

//   {

//     icon: <MarkunreadMailboxIcon />,

//     text: "Code Blue",

//   },

//   {

//     icon: <MarkunreadMailboxIcon />,

//     text: "IVR",

//   },

//   {

//     icon: <MarkunreadMailboxIcon />,

//     text: "Call Center",

//   },

// ];

const FileExplorer = ({
  entityName,
  folders,
  setFolders,
  userList,
  onUserDrop,
}) => {
  const [controller, dispatch] = useMaterialUIController();

  const { darkMode } = controller;

  const [width, setWidth] = useState(333);

  const [currentFolder, setCurrentFolder] = useState(
    folders?.length > 0 ? folders[0] : null
  );

  const [isHovering, setisHovering] = useState(false);

  const [splitterPosition, setSplitterPosition] = useState(20);

  const [anchorEl, setAnchorEl] = useState(null);

  const [folderPath, setFolderPath] = useState(null);

  const [isDelete, setIsDelete] = React.useState(false);

  const [currentPage, setCurrentPage] = useState("Home");

  const [path, setPath] = useState([]);

  const [featureListSelected, setFeatureListSelected] = useState([]);

  const [polygonList, setPolygonList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [routeList, setRouteList] = useState([]);

  const [schedules, setSchedules] = useState([]);

  const [featureList, setFeatureList] = useState(optionsData?.features || []);

  const { changeGeometryDataJSON } = useGeometryDataJSON();
  const [getAllPolygon] = useGetAllPolygonMutation();
  const [getAllRoute] = useGetAllRouteMutation();
  const [deletePolygon] = useDeletePolygonMutation();
  const [deleteRoute] = useDeleteRouteMutation();

  //const { changeHospitalJSON } = useChangeHospitalJSON();

  const [selectedFolders, setSelectedFolders] = useState([]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const fetchPolygons = async () => {
      try {
        const response = await getAllPolygon();
        if (response?.data) {
          const filteredAreaList = response?.data?.data.map((item) => ({
            ...item,
            areaType: "polygon",
          }));
          setAreaList(filteredAreaList);
        }
      } catch (error) {
        console.error("Error fetching polygons:", error);
      }
    };

    fetchPolygons();
  }, [getAllPolygon]);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await getAllRoute();
        if (response?.data) {
          const filteredRouteList = response?.data?.data.map((item) => ({
            ...item,
            areaType: "route",
          }));
          setRouteList(filteredRouteList);
        }
      } catch (error) {
        console.error("Error fetching routes:", error);
      }
    };
    fetchRoutes();
  }, [getAllRoute]);

  const handleFolderEdit = (folderId, newName, newNumber, foldersToUpdate) => {
    return foldersToUpdate.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, name: newName, number: newNumber };
      }

      if (folder.children && folder.children.length > 0) {
        return {
          ...folder,

          children: handleFolderEdit(
            folderId,

            newName,

            newNumber,

            folder.children
          ),
        };
      }

      return folder;
    });
  };

  const handleFolderEditAndSave = (folderId, newName, newNumber) => {
    const updatedFolder = handleFolderEdit(
      folderId,

      newName,

      newNumber,

      folders
    );

    if (updatedFolder) {
      setFolders(updatedFolder);
      changeGeometryDataJSON(updatedFolder, "updated");
    }
  };

  const handleFolderDelete = async (folderOrFolders) => {
    // Convert input to array if it's a single folder
    console.log("folderOrFolders", folderOrFolders);
    const foldersToDelete = (
      Array.isArray(folderOrFolders) ? folderOrFolders : [folderOrFolders]
    ).filter((folder) => folder.type !== "zones" && folder.type !== "routes");

    console.log("foldersToDelete", foldersToDelete);
    const deleteFromFolders = (folders, foldersToDelete) => {
      return folders.filter((folder) => {
        // If this folder should be deleted, return false to filter it out
        if (foldersToDelete.some((f) => f.id === folder.id)) {
          return false;
        }

        // If folder has children, recursively filter them
        if (folder.children && folder.children.length > 0) {
          folder.children = deleteFromFolders(folder.children, foldersToDelete);
        }

        return true;
      });
    };

    try {
      // Check if any folder has children
      const hasChildren = foldersToDelete.some(
        (folder) => folder.children && folder.children.length > 0
      );

      if (hasChildren) {
        enqueueSnackbar(`Cannot delete folders that have children`, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }

      // Group folders by type for batch deletion
      const polygonIds = [];
      const routeIds = [];
      const userIds = [];

      foldersToDelete.forEach((folder) => {
        if (folder.shapesId && folder.shapesId.length > 0) {
          if (folder.type === "geofence" || folder.type === "polygon") {
            polygonIds.push(...folder.shapesId);
          } else if (folder.type === "route" || folder.type === "polyline") {
            routeIds.push(...folder.shapesId);
          }
        }
        if (folder.type === "user" && folder.userId) {
          userIds.push(folder.userId);
        }
      });

      // Make API calls in parallel if needed
      const deletePromises = [];

      if (polygonIds.length > 0) {
        deletePromises.push(deletePolygon({ stringId: polygonIds }));
      }

      if (routeIds.length > 0) {
        deletePromises.push(deleteRoute({ stringId: routeIds }));
      }

      // Wait for all delete operations to complete
      await Promise.all(deletePromises);

      // Update local state
      const newFolders = deleteFromFolders([...folders], foldersToDelete);
      setFolders(newFolders);

      // Update the current folder if it was deleted
      // if (foldersToDelete.some((f) => f.id === currentFolder?.id)) {
      //   setCurrentFolder(newFolders[0]);
      // }

      // Update backend JSON
      await changeGeometryDataJSON(newFolders, "deleted multiple items");
      setCurrentFolder(newFolders[0]);
      setIsDelete(false);
      setSelectedFolders([]); // Clear selection after deletion

      enqueueSnackbar(
        `Successfully deleted ${foldersToDelete.length} item(s)`,
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    } catch (error) {
      console.error("Error deleting items:", error);
      enqueueSnackbar(
        `Failed to delete items: ${error.message || "Unknown error"}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
  };

  const findFolderById = (folders, id) => {
    if (!folders) {
      folders = folders;
    }
    for (let folder of folders) {
      if (folder.id === id) {
        return folder;
      }

      if (folder?.children?.length > 0) {
        const result = findFolderById(folder.children, id);

        if (result) return result;
      }
    }

    return null;
  };

  const handleMouseDown = (e) => {
    const initialX = e.clientX;

    const handleMouseMove = (event) => {
      const dx = event.clientX - initialX;

      const newWidth = width + dx;

      if (newWidth >= 100) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);

      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);

    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleConfirmDelete = () => {
    setIsDelete(true);
  };

  const handleCurrentPage = (e, type) => {
    // alert("How Are yuou?")

    setCurrentPage(type);

    // setAnchorContextMenu(null);
  };

  // const handleAddParent = (event) => {

  //   setisHovering(true);

  //   setAnchorEl(event.currentTarget);

  // };

  // const handleAddBuilding = () => {

  //   handleCloseMenu();

  //   handleAddFolderAtParent("building");

  // };

  // const handleAddFloor = () => {

  //   handleCloseMenu();

  //   handleAddFolderAtParent("floor");

  // };

  // const handleAddRoom = () => {

  //   handleCloseMenu();

  //   handleAddFolderAtParent("room");

  // };

  const handleSelected = (e, folder) => {
    setCurrentFolder(folder);

    if (folder?.type === "services") {
      setCurrentPage(folder?.type);
    }
  };

  const getChildren = (currentFolder) => {
    const res =
      currentFolder?.children?.length > 0
        ? currentFolder?.children
        : currentFolder;

    return res;
  };

  useEffect(() => {
    const getPath = async (folder, currentPath) => {
      if (!folder) return; // Folder not found, exit recursion

      currentPath.unshift(folder.name); // Add current folder to the beginning of the path

      if (folder.parentFolderId) {
        // If the current folder has a parent folder, recursively get its path

        await getPath(folder.parentFolderId, currentPath);
      } else {
        // If no parent folder, we've reached the root folder (Home)

        setPath(currentPath);
      }
    };

    getPath(currentFolder, []);
  }, [currentFolder]);

  useEffect(() => {
    setCurrentPage(currentFolder?.type);
  }, [currentFolder, currentPage]);

  useEffect(() => {
    if (
      currentFolder?.type === "geofence" ||
      currentFolder?.type === "zones" ||
      currentFolder?.type === "users"
    ) {
      setPolygonList((prevList) => {
        const existingPolygons = new Set(prevList.map((p) => p.shapeId));
        const newPolygons = areaList.filter(
          (p) => !existingPolygons.has(p.shapeId)
        );
        return [...prevList, ...newPolygons];
      });
    } else if (
      currentFolder?.type === "route" ||
      currentFolder?.type === "routes"
    ) {
      setPolygonList((prevList) => {
        const existingRoutes = new Set(prevList.map((p) => p.shapeId));
        const newRoutes = routeList.filter(
          (p) => !existingRoutes.has(p.shapeId)
        );
        return [...prevList, ...newRoutes];
      });
    } else if (currentFolder?.type === "project") {
      setPolygonList((prevList) => {
        const existingShapes = new Set(prevList.map((p) => p.shapeId));
        const newAreas = areaList.filter((p) => !existingShapes.has(p.shapeId));
        const newRoutes = routeList.filter(
          (p) => !existingShapes.has(p.shapeId)
        );
        return [...prevList, ...newAreas, ...newRoutes];
      });
    }
  }, [currentFolder, areaList, routeList]);

  console.log("folders", folders);

  useEffect(() => {
    if (folders?.length > 0 && !currentFolder) {
      const projectFolder = folders.find((folder) => folder.type === "project");
      if (projectFolder) {
        setCurrentFolder(projectFolder);
        setPolygonList([...areaList, ...routeList]);
      }
    }
  }, [folders, areaList, routeList]);

  const handleFolderAdd = (parentId, type, roomNumber, shapesIdArray) => {
    const newFolder = {
      id: uuidv4(),
      name:
        roomNumber ||
        (type === "geofence"
          ? "Geofence"
          : type === "route"
          ? "New Route"
          : type === "features"
          ? "Features"
          : roomNumber),

      // number: (roomNumber?.match(/\d+$/) || [])[0] || "",

      type: type,
      parentId: parentId,
      shapesId: shapesIdArray || [],
      children: [],
    };

    const featureId = uuidv4();

    const newFeature = {
      id: featureId,

      name: "Features",

      type: type,

      parentId: parentId,
      children: featureList

        ?.filter((child) => featureListSelected?.[child.name])

        .map((selectedChild) => ({
          id: uuidv4(),

          name: selectedChild.name,

          type: selectedChild.name,

          parentId: featureId,
          children: [],
        })),
    };

    const newclassOfServices = {
      id: featureId,

      name: "Class of Services",

      type: type,

      parentId: parentId,

      COSid: "17254684354", // this will be the default id from the database table, so here the  developer can fetch default id and save it
    };

    const addChildFolder = (folders) => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === parentId) {
          if (type === "geofence" || type === "route") {
            folders[i].children.push(newFolder);
          } else if (type === "features") {
            if (currentFolder?.type === "features") {
              currentFolder.children.push(...newFeature.children);
            } else {
              // check that parent has features if not then add it if yes then get that previous features and add the selected features to that

              const parentFolder = findFolderById(folders, parentId);

              const existingFeature = parentFolder.children.find(
                (child) => child.type === "features"
              );

              if (!existingFeature) {
                folders[i].children.unshift({ ...newFeature });
              } else {
                existingFeature.children.push(...newFeature.children);
              }
            }
          } else if (type === "services") {
            folders[i].children.unshift({ ...newclassOfServices });
          } else {
            folders[i].children.push({ ...newFolder, children: [] });
          }

          return true;
        }

        if (folders[i].children) {
          if (addChildFolder(folders[i].children)) return true;
        }
      }

      return false;
    };

    setFolders((prevFolders) => {
      const newFolders = [...prevFolders];

      addChildFolder(newFolders);

      // api for saving file to the database ..
      return newFolders;
    });
  };

  const findFolderPath = (folders, targetId, currentPath = []) => {
    for (const folder of folders) {
      // Add the current folder to the path

      const newPath = [...currentPath, folder];

      // If the current folder matches the target ID, return its path

      if (folder.id === targetId) {
        return newPath;
      }

      // If the current folder has children, recursively search through them

      if (folder.children && folder.children.length > 0) {
        const foundPath = findFolderPath(folder.children, targetId, newPath);

        // If the folder is found in the children, return its path

        if (foundPath) {
          return foundPath;
        }
      }
    }

    // If the target folder is not found in the current level or its children, return null

    return null;
  };

  useEffect(() => {
    if (folders && currentFolder) {
      const path = findFolderPath(folders, currentFolder?.id);

      setFolderPath(path);
    }
  }, [currentFolder]);

  const handleFolderClick = (folder) => {
    setCurrentFolder(folder);
  };

  const handleAddBuilding = () => {
    handleCloseMenu();

    // setIsOpen(true);

    handleFolderAdd(currentFolder.id, "building");
  };

  const handleAddFloor = () => {
    handleCloseMenu();

    handleFolderAdd(currentFolder.id, "floor");
  };

  const handleOpenAddForm = (e, type) => {
    setRoomInputDialog(type);
  };

  const pageRender = (type) => {
    const commonProps = {
      currentFolder,

      folderPath,

      isDelete,

      handleFolderClick,

      getChildren,

      setAnchorEl,

      handleSelected,

      findFolderById,

      folders,
    };

    switch (currentPage) {
      case "Home":
        return <FileViewer {...commonProps} />;

      case "project":
      case "geofence":
      case "zones":
      case "route":
      case "routes":
      case "user":
      case "group":
        return (
          <GeoFence
            onFolderAdd={handleFolderAdd}
            currentFolder={currentFolder}
            setCurrentFolder={setCurrentFolder}
            defaultLocation={folders[0].location}
            polygonList={polygonList}
            setPolygonList={setPolygonList}
            folders={folders}
            setFolders={setFolders}
            folderPath={folderPath}
            handleFolderClick={handleFolderClick}
            areaList={areaList}
            routeList={routeList}
            userList={userList}
          />
        );

      default:
        return <FileViewer {...commonProps} />;
    }
  };

  const handleBackButton = () => {
    const backFolder = findFolderById(folders, currentFolder?.parentId);

    setCurrentFolder(backFolder);

    if (backFolder?.type === "services") {
      setCurrentPage(backFolder.type);
    } else {
      setCurrentPage("Home");
    }
  };

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <FileExplorerWrapper>
        <ResizableSidebar style={{ width }}>
          <FileNavigator>
            <List component="nav" aria-label="main mailbox folders">
              {folders?.map((folder) => (
                <Folder
                  key={folder.id}
                  folder={folder}
                  onFolderDelete={handleConfirmDelete}
                  onFolderEdit={handleFolderEditAndSave}
                  onFolderAdd={handleFolderAdd}
                  level={0}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  handleSelected={handleSelected}
                  width={width}
                  darkMode={darkMode}
                  handleCurrentPage={handleCurrentPage}
                  featureListSelected={featureListSelected}
                  setFeatureListSelected={setFeatureListSelected}
                  folders={folders}
                  setFolders={setFolders}
                  featureList={featureList}
                  areaList={areaList}
                  routeList={routeList}
                  userList={userList}
                  onUserDrop={onUserDrop}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                />
              ))}
            </List>
          </FileNavigator>
        </ResizableSidebar>

        <FileViewerStyle>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {(currentPage === "services" || currentPage === "Calendar") && (
              <>
                <Tooltip title="Back">
                  <IconButton
                    onClick={handleBackButton}
                    sx={{ color: darkMode && "#fff" }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                <MDTypography>
                  {currentPage === "services"
                    ? "Class of Services"
                    : currentPage}
                </MDTypography>
              </>
            )}
          </MDBox>

          {pageRender()}
        </FileViewerStyle>
      </FileExplorerWrapper>

      {isDelete && (
        <AlertDelete
          name={
            selectedFolders?.length > 0
              ? ` all ${selectedFolders[0]?.type}`
              : currentFolder?.name
          }
          handleConfirmDelete={() =>
            handleFolderDelete(
              selectedFolders?.length > 0 ? selectedFolders : currentFolder
            )
          }
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          setCurrentFolder={setCurrentFolder}
          currentFolder={currentFolder}
        />
      )}
    </div>
  );
};

export default FileExplorer;
