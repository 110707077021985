import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import CTLSelectedDownload from "application/components/CTLSelectDownload";
import { application } from "globals/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function CampaignSummary({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  onCall,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue, tableSortColumn, tableColumnDataType } =
    controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [formData, setFormData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [columns, setColumns] = useState(getColumns());
  const [loading, setLoading] = useState(false);
 const filteredList = getColumns().filter(
   (item) => item.displayInList === true
 );
 const selectedColumnDownload = useRef(
   filteredList && filteredList.map((item) => item.accessor)
 );
    const [sortColumn, setSortColumn] = useState("leadUploadDate");
    const [columnDataType, setColumnDataType] = useState("date");

    useEffect(() => {
      {
        tableSortColumn != "" && setSortColumn(tableSortColumn);
      }
      {
        tableColumnDataType != "" && setColumnDataType(tableColumnDataType);
      }
    }, [tableSortColumn, tableColumnDataType]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const isMount=useRef(tableValue);

  // Getting the From Current Time
  const FromCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T00:01`;
    return isoString;
  };

  // Getting the To Current Time
  const ToCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isoString = `${year}-${month}-${day}T23:59`;
    return isoString;
  };
  const [advancedFilters, setAdvancedFilters] = useState([
    {
      direction: "from",
      dataType: "date",
      fieldName: "leadUploadDate",
      value: FromCurrentDateTime(),
    },
    {
      direction: "to",
      dataType: "date",
      fieldName: "leadUploadDate",
      value: ToCurrentDateTime(),
    },
  ]);


  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        DownloadData: onUpdateDataDownload,
        UpdateTableRecords: DownloadData,
        DownloadPdf: DownloadPdf,
        onRefresh:onRefresh
      });
  }, [activePage, tableValue]);

    // Refresh Function
    const onRefresh = () => {
      setUpdateList(Date.now);
    };

  function DownloadData() {
    setOpenForm(true);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const onAgree = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const updateColumns = (selectedItems) => {
    const updatedColumns = getColumns().map((column) => {
      return {
        ...column,
        displayInList: selectedItems.includes(column.accessor),
      };
    });
    setColumns(updatedColumns);
    selectedColumnDownload.current = selectedItems;
  };
// for Download CSV Report
  function onUpdateDataDownload() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
    ) {
      return setNotify({
        isOpen: true,
        pagename: pageName,
        message: "Warning !",
        type: "warning",
        status: "You cann't download more that 10 column in pdf mode",
        message:"Please select 10 relevant column using Coumn Filer option"
      });
    }
    setLoading(true);
    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
    };
    application
      .post(operationURLS.csvDownload, requestBodyData, requestConfig) 
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "text/csv" }); 
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Campaign-Summary.csv"; 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
  
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "CSV Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // for Download pdf Report
  function DownloadPdf() {
    if (
      !selectedColumnDownload.current ||
      selectedColumnDownload.current.length === 0
  ) {
      return setNotify({
          isOpen: true,
          pagename: pageName,
          message: "Warning !",
          type: "warning",
          status: "You cann't download more that 10 column in pdf mode",
          message:"Please select 10 relevant column using Coumn Filer option"
      });
  } else if (selectedColumnDownload.current.length > 10) {
      return setNotify({
          isOpen: true,
          pagename: pageName,
          type: "error",
          status: "Select maximum of 10 columns",
      });
  }
    setLoading(true);
    const requestBodyData = {
      sortDirection: "desc",
      sortBy: sortColumn,
      search: searchText,
      sortDataType: columnDataType,
      columnNames: selectedColumnDownload.current,
      advancedFilters: advancedFilters,
    };
    const requestConfig = {
      responseType: "arraybuffer",
      params: {
        reportType:"campaignSummaryPDF"
      },
    };
    application
      .post(operationURLS.pdfDownload, requestBodyData, requestConfig)
      .then((response) => {
        if (response) {
          setLoading(false);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Campaign-Summary.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "PDF Download successful",
            type: "success",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setNotify({
            isOpen: true,
            pagename: pageName,
            status: "Unable To Download File",
            type: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <MDBox>
      <CTLSelectedDownload
        openForm={openForm}
        columns={getColumns(editRow)}
        pageName={pageName}
        onCloseForm={onCloseForm}
        onUpdate={updateColumns}
      />
      <DeleteConfirmation
         entityName={pageName}
        data={selectedRow}
        columns={getColumns(editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLTable
        apiURL={operationURLS.list}
        columns={columns}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="leadUploadDate"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
        onCall={onCall}
      />
      <CTLAdvancedFilter
          entityName={pageName}
        columns={getColumns(editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
      <MDBox>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </MDBox>
    </MDBox>
  );
}

export default CampaignSummary;

