import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Icon,
  IconButton,
  Tooltip,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  ListItemText,
  Avatar,
  TextField,
  Typography,
  Badge,
} from "@mui/material";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "../CTLBaseDialogForm";
import { getColumns, Attributes, operationURLS } from "../CTLConferencePhone/model";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLNotification from "../CTLNotification";
import loggerFactory from "globals/logger/logger-factory";

function CTLConferenceUserList({ conferenceData, phoneType, openPopup }) {
  const [state, setState] = useState({ right: false });
  const [participantList, setParticipantList] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const [callFunction, setCallFunction] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [raiseHand, setRaiseHand] = useState("info");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (callFunction) {
        fetchData(conferenceData);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [callFunction]);

  useEffect(() => {
    if (participantList) {
      const filtered = participantList.filter((item) =>
        item.participantName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.extensions.includes(searchQuery)
      );
      setFilteredList(filtered);
    }
  }, [participantList, searchQuery, activeUser]);

  useEffect(() => {
   setCallFunction(openPopup);
  }, [openPopup]);


  useEffect(() => {
    fetchData(conferenceData);
  }, [conferenceData]);

  function fetchData(data) {
    if (data) {
      application
        .post(`conferenceParticipant/list/${data.modId}`)
        .then((response) => {
          setParticipantList(response.data.data);
        })
        .catch((error) => {
           setParticipantList([]);
           loggerFactory.debug("Unable to load ParticipantList ");
        });

      application
        .post(`conferenceParticipant/confCallDetail/${data.accessCode}`)
        .then((response) => {
          setActiveUser(response.data.data);
        })
        .catch((error) => {
           setActiveUser();
          loggerFactory.debug("Unable to load active Users ");
        });
    }
  }


  useEffect(() => {
    if (activeUser) {
      const number = AuthenticationService.getExtensionNumber();
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === number);
      if (matchedContact && matchedContact.isHandRaised == 0) {
        setRaiseHand("info");
      } else {
        setRaiseHand;
        ("success");
      }
    }
  }, [activeUser]);

  const handleRaiseHand = () => {
    fetchData(conferenceData);
    const number = AuthenticationService.getExtensionNumber();
    const matchedContact = activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      setRaiseHand("success");
      application
        .post(`conferenceParticipant/updateHandRaise/${matchedContact.uuid}`)
        .then((response) => {
          setRaiseHand("success");
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          loggerFactory.debug("Unable to update handRaise ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to get ${AuthenticationService.getExtensionNumber()} data for hand raise update `,
      });
    }
  };

  const handleUnMute = (number) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/unmute/${conferenceData.accessCode}/${matchedContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
           loggerFactory.debug("Unable to UnMute participant ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to UnMute participant no match user found ! `,
      });
    }
  };

  const handleMute = (number) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === number);
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/mute/${conferenceData.accessCode}/${matchedContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          setNotify({
            isOpen: true,
            message: "",
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          loggerFactory.debug("Unable to Mute participant ", error);
        });
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "warning",
        pagename: "Conference",
        status: `Unable to Mute participant no match user found !`,
      });
    }
  };
  const handleAddParticipant = () => {
    setOpenForm(true);
    setFormData({
      eventTime: Date.now(),
    });
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function onSaveForm(data) {
    const dataToSave = {
      configureConferrerId: conferenceData.modId,
      ...data,
    };
    application
      .post(operationURLS.create, dataToSave)
      .then((response) => {
        fetchData(conferenceData);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
        setOpenForm(false);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  }

  const getColorForContact = (contactNumber) => {
    if (activeUser) {
      const activeContacts = activeUser.map((user) => user.contact);
      if (activeContacts.includes(contactNumber)) {
        return "success";
      }
    }
    return "error";
  };

  const getHandRaise = (contactNumber) => {
    if (activeUser) {
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === contactNumber);
      if (matchedContact) {
        return matchedContact.isHandRaised;
      } else {
        return 0;
      }
    }
  };

  const getMuteStatus = (contactNumber) => {
    if (activeUser) {
      const matchedContact =
        activeUser && activeUser.find((item) => item.contact === contactNumber);
      if (matchedContact && matchedContact.canSpeak == "false") {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleAccessRaiseHand = (contactNumber) => {
    const matchedContact =
      activeUser && activeUser.find((item) => item.contact === contactNumber);
      const adminContact = AuthenticationService.getExtensionNumber(); 
    if (matchedContact) {
      application
        .post(
          `conferenceParticipant/unMuteRaisedUser/${conferenceData.didMapping}/${conferenceData.accessCode}/${matchedContact.id}/${adminContact.id}`
        )
        .then((response) => {
          fetchData(conferenceData);
          loggerFactory.info("successfully unMuteRaiseUser ", contactNumber);
        })
        .catch((error) => {
          loggerFactory.debug("Unable to unMuteRaiseUser ", error);
        });
    }
  };

    const handleParticipantDelete = (data) => {
      const body = {
        intId: [data.id],
      };
      application
        .post("conferenceParticipant/delete", body)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: "Conference",
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: "Conference",
            status: error.response.data.status,
          });
        });
      fetchData(conferenceData);
    };


  return (
    <>
      <MDBox role="presentation">
        <MDTypography variant="h6" style={{ textAlign: "center" }}>
          Participant List
        </MDTypography>
        {/* {phoneType == "moderator" && (
             <MDButton
              variant="outlined"
              color="info"
              iconOnly
              onClick={() => handleAddParticipant()}
            >
              <Tooltip title="Add participant">
                <Icon color="info" sx={{ fontWeight: "bold" }}>
                  add
                </Icon>
              </Tooltip>
            </MDButton>
          )} */}
        <Divider />
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchInputChange}
          autoComplete="off"
        />
        <Divider />
        <List
          fullWidth
          style={{
            height: "calc(45vh - 120px)",
            overflow: "auto",
            position: "relative",
          }}
        >
          {filteredList && filteredList.length === 0 ? (
            <MDTypography variant="h6" style={{ textAlign: "center" }}>
              No participant found
            </MDTypography>
          ) : (
            filteredList &&
            filteredList.map((item, index) => (
              <>
                <ListItem
                  key={item.participantId}
                  disablePadding
                  sx={{ cursor: "auto" }}
                >
                  <Icon
                    style={{
                      marginBottom: "-4px",
                      marginRight: "10px",
                      marginLeft: "30px",
                    }}
                    color={getColorForContact(item.mobileNumber)}
                  >
                    circle
                  </Icon>{" "}
                  &nbsp;
                  <MDTypography>
                    {" "}
                    <ListItemText
                      primary={item.participantName || item.extensions || item.mobileNumber}
                    />
                  </MDTypography>
                  &nbsp;
                  {phoneType == "moderator" &&
                    conferenceData?.rptt &&
                    getColorForContact(item.mobileNumber) == "success" &&
                    getHandRaise(item.mobileNumber) == 1 && (
                      <IconButton
                        onClick={() => handleAccessRaiseHand(item.mobileNumber)}
                      >
                        <Badge
                          badgeContent={getHandRaise(item.mobileNumber)}
                          color="error"
                        >
                          <Icon color="warning"> pan_tool_icon</Icon>{" "}
                        </Badge>
                      </IconButton>
                    )}
                  {phoneType == "participant" &&
                    conferenceData?.rptt &&
                    getColorForContact(item.mobileNumber) == "success" &&
                    getHandRaise(item.mobileNumber) == 1 && (
                      <>
                        &nbsp; &nbsp;
                        <Badge
                          badgeContent={getHandRaise(item.mobileNumber)}
                          color="error"
                        >
                          <Icon color="warning"> pan_tool_icon</Icon>
                        </Badge>
                      </>
                    )}
                  &nbsp;
                  {phoneType == "moderator" &&
                    !conferenceData?.rptt &&
                    getColorForContact(item.mobileNumber) == "success" && (
                      <>
                        {getMuteStatus(item.mobileNumber) ? (
                          <IconButton
                            variant="outlined"
                            onClick={() => handleMute(item.mobileNumber)}
                          >
                            <Icon color="success">mic</Icon>
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleUnMute(item.mobileNumber)}
                          >
                            <Icon color="error">mic_off</Icon>
                          </IconButton>
                        )}
                      </>
                    )}
                  {/* {item.createdBy == AuthenticationService.getUserName() && (
                    <Tooltip title="Delete Participant">
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => handleParticipantDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )} */}
                </ListItem>
                <Divider />
              </>
            ))
          )}
        </List>
      </MDBox>

      <CTLBaseDialogForm
        entityName="Add participant in conference"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CTLConferenceUserList;
