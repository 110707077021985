import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import { getUpdatedColumns, operationurls } from "./model/update";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";

function IMSIConfiguration({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openMultipleUpdate, setOpenMultipleUpdate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const selectedRowsRef = useRef([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        Single: onOpenUpdate,
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage, tableValue]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onOpenUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenUpdate(true);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenUpdate(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };
  const viewRow = (row) => {
    setFormData(row);
    setOpenView(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        stringId: [row.extensionNumber],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    application
      .post(operationURLS.create, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setOpenForm(false);
    setOpenUpdate(false);
  }

  function onUpdateForm(data) {
    application
      .post(operationurls.update, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setOpenForm(false);
    setOpenUpdate(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function onCloseUpdate() {
    setOpenUpdate(false);
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="IMSI Configuration"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="IMSI Configuration"
        columns={getColumns(deleteRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      {onOpenUpdate && (
        <CTLBaseDialogForm
          entityName="IMSI Configuration"
          columns={getUpdatedColumns(editRow)}
          openForm={openUpdate}
          onSaveForm={onUpdateForm}
          onCloseForm={onCloseUpdate}
          formData={formData}
          pageAttributes={Attributes}
        />
      )}

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow, viewRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="extensionNumber"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
      <CTLAdvancedFilter
        entityName="IMSI Configuration"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default IMSIConfiguration;
