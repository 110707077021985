import {
  Box,
  Card,
  Divider,
  Grid,
  Icon,
  Stack,
  Typography,
  styled,
  Switch,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EmptyImage from "../../../../../assets/images/mediacenter/emptyFolder.svg";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(22px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
function ConferenceList({ data, handleUserEventsClick }) {
  const currentDate = new Date();

  const [mode, setMode] = useState(false);

  const getLocalSortedData = () => {
    let filteredData = data.filter((item) =>item.conferenceMode == "I"
    );

    return filteredData
      .filter((item) => {
        const scheduledEndTime = new Date(
          `${item.scheduledToDate}T${item.toTime}`
        );
        return scheduledEndTime >= currentDate;
      })
      .sort((a, b) => {
        const dateA = new Date(a.meetingScheduledDate);
        const dateB = new Date(b.meetingScheduledDate);
        return dateA - dateB;
      });
  };
   const getPublicSortedData = () => {
     let filteredData = data.filter((item) => item.conferenceMode == "C" 
     );

     return filteredData
       .filter((item) => {
         const scheduledEndTime = new Date(
           `${item.scheduledToDate}T${item.toTime}`
         );
         return scheduledEndTime >= currentDate;
       })
       .sort((a, b) => {
         const dateA = new Date(a.meetingScheduledDate);
         const dateB = new Date(b.meetingScheduledDate);
         return dateA - dateB;
       });
   };

  const convertTimeTo12HourFormat = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleChangeMode = (e) => {
    setMode(e.target.checked);
  };

    const filteredAndSortedData = mode
      ? getPublicSortedData()
      : getLocalSortedData();
    const localSortedData = getLocalSortedData();
    const publicSortedData = getPublicSortedData();

  return (
    <>
      {window.conferenceMode && (
        <Stack
          padding="5px"
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <MDBadge
            color="error"
            badgeContent={localSortedData.length}
            max={99}
            size="xs"
            circular
          >
            {" "}
            <MDTypography variant="h6"> {window.conferenceMode1 ? window.conferenceMode1 : "Local Mode"}</MDTypography>
          </MDBadge>
          &nbsp;&nbsp;
          <AntSwitch
            onChange={(e) => handleChangeMode(e)}
            inputProps={{ "aria-label": "ant design" }}
          />
          <MDBadge
            color="error"
            badgeContent={publicSortedData.length}
            max={99}
            size="xs"
            circular
          >
            {" "}
            <MDTypography variant="h6"> {window.conferenceMode2 ? window.conferenceMode2 : "Public Mode"}</MDTypography>
          </MDBadge>
        </Stack>
      )}
      {filteredAndSortedData.length == 0 ? (
        <Card
          style={{
            height: "calc(100vh - 200px)",
            overflow: "auto",
            position: "relative",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <img src={EmptyImage} alt="Empty Folder" />
            <Typography variant="h5" fontFamily={600}>
              No Meeting Found
            </Typography>
          </Box>
        </Card>
      ) : (
        <MDBox
          spacing={2}
          style={{
            height: "calc(100vh - 200px)",
            overflow: "auto",
            position: "relative",
          }}
        >
          {filteredAndSortedData.map((item, index) => {
            let color = "dark";
            const currentDateFormatted = currentDate.toISOString().slice(0, 10);
            const eventDateFormatted = new Date(item.scheduledToDate)
              .toISOString()
              .slice(0, 10);
            if (eventDateFormatted === currentDateFormatted) {
              color = "success";
            } else if (eventDateFormatted > currentDateFormatted) {
              const oneWeekLater = new Date();
              oneWeekLater.setDate(oneWeekLater.getDate() + 7);
              if (new Date(eventDateFormatted) <= oneWeekLater) {
                color = "warning";
              } else {
                color = "info";
              }
            } else if (eventDateFormatted < currentDateFormatted) {
              color = "error";
            }

            return (
              <Grid item xs={12}>
                <MDBox mb={1.5}>
                  <Card>
                    <MDBox display="flex" pt={1} px={1}>
                      <MDBox
                        variant="gradient"
                        bgColor={color}
                        color={color === "light" ? "dark" : "white"}
                        coloredShadow={color}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="2rem"
                        height="2rem"
                      >
                        <Icon fontSize="medium" color="inherit">
                          notifications
                        </Icon>
                      </MDBox>
                      <MDTypography variant="h5" color="text" px={2}>
                        {item.roomName}
                      </MDTypography>
                      <div style={{ flexGrow: 1 }}></div>
                      <MDTypography variant="button" color="text" px={2}>
                        From {formatDate(item.meetingScheduledDate)} -
                        {convertTimeTo12HourFormat(item.fromTime)}
                      </MDTypography>
                      <MDTypography variant="button" color="text" px={2}>
                        To {formatDate(item.scheduledToDate)} -
                        {convertTimeTo12HourFormat(item.toTime)}
                      </MDTypography>
                    </MDBox>
                    <Divider />
                    <MDBox
                      m={2}
                      mt={-1}
                      style={{ height: "auto" }}
                      display="flex"
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{ margin: "16px -8px" }}
                      >
                        <Grid
                          item
                          xs={8}
                          container
                          direction="column"
                          spacing={2}
                          style={{ paddingRight: "16px" }}
                        >
                          <Grid item>
                            <MDTypography
                              component="p"
                              variant="caption"
                              color="text"
                            >
                              {`Meeting Created By - ${item.createdBy}`}
                            </MDTypography>
                          </Grid>
                          <Grid item>
                            <MDTypography
                              component="p"
                              variant="caption"
                              color="text"
                            >
                              {`Participant List - `}
                              {item.participantList &&
                                item.participantList
                                  .map(
                                    (user) =>
                                      `${user.username || user.extension}`
                                  )
                                  .join(", ")}
                            </MDTypography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <MDButton
                            color={"info"}
                            onClick={() => handleUserEventsClick(item)}
                          >
                            Join Conference
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            );
          })}
        </MDBox>
      )}
    </>
  );
}

export default ConferenceList;
