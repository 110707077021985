import React, { useCallback } from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ucMenus } from "globals/configuration/uc";
import { application } from "globals/endpoints";
import SidebarCollapse from "./SidebarCollapse";
import SidebarList from "./SidebarList";
import SideBarItem from "./SideBarItem";
import SidebarRoot from "./SidebarRoot";
import sidebarLogoLabel from "./styles/sidebar";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { ivrMenus } from "globals/configuration/ivr/menu";
import { callCenterMenus } from "globals/configuration/call-center/menu";
import { kmAdminMenus } from "globals/configuration/kmAdmin";
import { Menus } from "globals/configuration/changePass";
import { quickMenus } from "globals/configuration/quickMenus";
import { ucNotificationMenus } from "globals/configuration/ucNotification";
import { nmsQuickMenus } from "globals/configuration/nmsQuickMenus";
import { configurationMenus } from "globals/configuration/configurationMenu";
import CTLUserStatus from "../CTLUserStatus";
import CryptoJS from "crypto-js";
import CloseIcon from "@mui/icons-material/Close";

import {
  useMaterialUIController,
  setMiniSidenav,
  setActivePage,
  setSearchText,
  setProfileStatus,
  setManualCall,
} from "context";
import { adminMenus } from "globals/configuration/admin";
import { groupQuickMenus } from "globals/configuration/groupQuickMenus";
import { conferenceQuickMenus } from "globals/configuration/ConferenceQuickMenu";
import loggerFactory from "globals/logger/logger-factory";
import { dispatcherQuickMenus } from "globals/configuration/RailwayDispatcherQuickMenus";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDInput from "components/MDInput";
import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";
import MDButton from "components/MDButton";
import CTLNotification from "../CTLNotification";
import MDBadge from "components/MDBadge";
import { setCallInWaiting } from "context";
import { chatMenus } from "globals/configuration/chatMenus";
import { emailMenus } from "globals/configuration/emailMenu";

function CTLSideBar({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    activePage,
    profileStatus,
    manualCall,
  } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = activePage.url.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const [Password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    status: "",
    type: "",
  });

  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  function LoginStatus() {
    application
      .post("/customerAccount/status")
      .then((response) => {
        const responseData = response.data.data;
        setProfileStatus(dispatch, responseData);
      })
      .catch((error) => {
        setProfileStatus(dispatch, error.response.data.data);
        if (error.response.status === 400) {
          navigateToHomePage();
        }
      });
  }

  useEffect(() => {
    if (AuthenticationService.allowUCMenus()) {
      LoginStatus();
    }
  }, []);

  const navigateToHomePage = () => {
    (activePage.key = "uc.settings"),
      (activePage.name = "User Profile"),
      (activePage.description = "User Profile"),
      (activePage.url = "home/app/uc/settings"),
      setActivePage(dispatch, activePage);
    return true;
  };
  const [openMessage, setOpenMessage] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState({
    isOpen: false,
    type: "success",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClick = () => {
    setOpenMessage(true);
  };

  // Close Snackbar
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
    setNotify({ ...notify, isOpen: false });
  };


  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
    // LoginStatus();
  }, []);

  //handle Notification Bar
  function handleNotificationBar() {
    setMiniSidenav(dispatch, true);
  }

  useEffect(() => {
    handleNotificationBar();
    return () => {};
  }, [dispatch, location]);

  //Link to Page clicked
  const menuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setRefreshComponent(true);
    setSearchText(dispatch, "");
  };

  const handleClose = () => {
    setOpen(false);
    setPassword("");
  };

  const handleLogoutClick = () => {
    if (AuthenticationService.allowRailwayDispatcher()) {
      setOpen(true);
    } else {
      const payload = { status: "Logout" };
      application
        .post("/agentStatus/create", payload)
        .then((response) => {
          AuthenticationService.logout();
          loggerFactory.info(
            "user logout status update successfully",
            response
          );
        })
        .catch((error) => {
          AuthenticationService.logout();
          loggerFactory.debug("unable to update user logout status", error);
        })
        .finally(() => {
          AuthenticationService.logout();
        });
    }
  };

  const encryptData = (data, secretKey) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const ciphertext = CryptoJS.AES.encrypt(
      data,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return iv.concat(ciphertext.ciphertext).toString(CryptoJS.enc.Base64);
  };

  async function handlePasswordConfirmation(data) {
    const encryptedUsername = AuthenticationService.getUser();
    const encryptedPassword = encryptData(data, window.secretKey);
    try {
      const response = await AuthenticationService.login(
        encryptedUsername,
        encryptedPassword
      );
      const user = response.data.messageDetail;
      if (user == "User has been successfully authenticated") {
        const payload = { status: "Logout" };
        application
          .post("/agentStatus/create", payload)
          .then((response) => {
            loggerFactory.info(
              "user logout status update successfully",
              response
            );
            AuthenticationService.logout();
          })
          .catch((error) => {
            loggerFactory.debug("unable to update user logout status", error);
          });
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        status: "Please enter valid password",
        message:
          error.response?.status === 400
            ? "wrong password ! try again.."
            : error.message,
        type: "error",
      });
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePasswordConfirmation(Password);
    }
  };
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [counts, setCounts] = useState([]);
  const [groupCallList, setGroupCallList] = useState([]);
  const GetNotifications = "/uc/countNotification/list";

  function GetNotification() {
    application
      .post(GetNotifications)
      .then((res) => {
        const response = res.data.data;
        setCounts(response);
      })
      .catch((error) => {
        loggerFactory.debug(
          "error getting UC-count notification list :{}",
          error
        );
      });
  }

  const groupCallWaitingList = useCallback(async () => {
    try {
      const response = await application.post(`agent/checkCallStatus`);
      const data = response.data.data || [];

      setGroupCallList((prevList) => {
        if (JSON.stringify(prevList) !== JSON.stringify(data)) {
          return data; 
        }
        return prevList;
      });

      setCallInWaiting(dispatch, data);
    } catch (error) {
      setGroupCallList((prevList) => {
        if (prevList.length > 0) {
          setCallInWaiting(dispatch, []);
        }
        return [];
      });
    } finally {
      setTimeout(groupCallWaitingList, 1500); 
    }
  }, [dispatch]);

  useEffect(() => {
    if (AuthenticationService.allowRailwayDispatcher()) {
      groupCallWaitingList();
    }
  }, [groupCallWaitingList]);

  useEffect(() => {
    if (AuthenticationService.allowUCMenus()) {
      const interval = setInterval(() => {
        GetNotification();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [counts]);

  const renderNotifications = useMemo(() => {
    const countsByMessageType = (counts || []).reduce((acc, notification) => {
      acc[notification.messageType] = notification.count;
      return acc;
    }, {});

    return ucNotificationMenus.map((menu) => {
      if (menu.type === "divider") {
        return null;
      }
      const count = countsByMessageType[menu.messageType] || 0;
      if (count) {
        return (
          <Link
            key={menu.key}
            onClick={() => menuClicked(menu)}
            to="javascript:void(0)"
          >
            <SidebarCollapse
              name={menu.name}
              icon={menu.icon}
              badge={count}
              count={count}
            />
          </Link>
        );
      } else {
        return null;
      }
    });
  }, [counts, ucNotificationMenus, refreshComponent]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SideBarItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SideBarItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SideBarItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SideBarItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SideBarItem
              color={color}
              name={name}
              active={key === activePage.key}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SideBarItem
              color={color}
              name={name}
              active={key === activePage.key}
            />
          </NavLink>
        );
      }
      return <SidebarList key={key}>{returnValue}</SidebarList>;
    });

  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      status,
    }) => {
      let returnValue;
      if (type === "collapse") {
        if (status === "Logout") {
          returnValue = (
            <Link
              onClick={() => handleLogoutClick()}
              key={key}
              sx={{ textDecoration: "none" }}
            >
              <SidebarCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidebarCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidebarCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidebarCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidebarCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  let renderIvrMenu = null;

  renderIvrMenu = adminMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderCallCenterMenu = null;

  renderCallCenterMenu = callCenterMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderAdminMenu = null;

  renderAdminMenu = ivrMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderquickMenu = null;

  renderquickMenu = quickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderChatMenu = null;

  renderChatMenu = chatMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderConfigurationMenu = null;

  renderConfigurationMenu = configurationMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production")
    ) {
      return (
        <Link
          key={menu.key}
          onClick={() => menuClicked(menu)}
          to="javascript:void(0)"
        >
          <SidebarCollapse name={menu.name} icon={menu.icon} />
        </Link>
      );
    } else {
      return null;
    }
  });

  let renderEmailMenu = null;
  renderEmailMenu = emailMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production")
    ) {
      return (
        <Link
          key={menu.key}
          onClick={() => menuClicked(menu)}
          to="javascript:void(0)"
        >
          <SidebarCollapse name={menu.name} icon={menu.icon} />
        </Link>
      );
    } else {
      return null;
    }
  });

  let renderMenu = null;

  renderMenu = Menus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let rendernmsMenu = null;

  rendernmsMenu = nmsQuickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderGroupQuickMenus = null;

  renderGroupQuickMenus = groupQuickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderConferenceMenus = null;

  renderConferenceMenus = conferenceQuickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  const memoizedDispatcherQuickMenus = useMemo(
    () => dispatcherQuickMenus,
    [dispatcherQuickMenus]
  );

  const renderDispatcherMenus = useMemo(() => {
    return memoizedDispatcherQuickMenus.map((menu) => {
      const badgeCount =
        menu.key === "groupCallInQueue" ? groupCallList.length : undefined;

      return (
        <Link
          key={menu.key}
          onClick={() => menuClicked(menu)}
          to="javascript:void(0)"
        >
          <SidebarCollapse
            badge={badgeCount}
            count={badgeCount}
            noHide={true}
            name={menu.name}
            icon={menu.icon}
          />
        </Link>
      );
    });
  }, [memoizedDispatcherQuickMenus, groupCallList.length]); 

  return (
    <>
      <SidebarRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={1} textAlign="center">
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && (
              <MDBox
                component="img"
                src={
                  (darkMode && !whiteSidenav) ||
                  (!darkMode && !whiteSidenav && !transparentSidenav)
                    ? "/logoDark.png"
                    : "/logoLight.png"
                }
                alt="Brand"
                width="100%"
              />
            )}
            <MDBox
              width={!brandName && "100%"}
              sx={(theme) => sidebarLogoLabel(theme, { miniSidenav })}
            ></MDBox>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        {AuthenticationService.allowUCMenus() && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CTLUserStatus />
          </MDBox>
        )}

        <MDTypography variant="h6" color="info" sx={{ textAlign: "center" }}>
          {AuthenticationService.getUserName()}
        </MDTypography>

        {AuthenticationService.getExtensionNumber() && (
          <MDTypography
            variant="body2"
            color="info"
            sx={{ textAlign: "center" }}
          >
            {AuthenticationService.getExtensionNumber()}
          </MDTypography>
        )}
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        {AuthenticationService.allowUCMenus() && (
          <List>{renderNotifications}</List>
        )}
        {!AuthenticationService.allowAgentMenus() &&
          !AuthenticationService.allowGroupAdmin() &&
          !AuthenticationService.allowUCConferenceMenus() &&
          !AuthenticationService.allowRailwayDispatcher() && (
            <List>{renderquickMenu}</List>
          )}
        {AuthenticationService.allowGroupAdmin() && (
          <List>{renderGroupQuickMenus}</List>
        )}
        {AuthenticationService.allowUCConferenceMenus() &&
          !AuthenticationService.allowRailwayDispatcher && (
            <List>{renderConferenceMenus}</List>
          )}
        {AuthenticationService.allowRailwayDispatcher() && (
          <List>{renderDispatcherMenus}</List>
        )}
        {AuthenticationService.allowAdminMenus() && (
          <List>{renderConfigurationMenu}</List>
        )}
        {AuthenticationService.allowAdminMenus() && (
          <List>{renderEmailMenu}</List>
        )}
        {AuthenticationService.allowChat() && <List>{renderChatMenu}</List>}

        <List>{renderMenu}</List>
        <List>{renderRoutes}</List>
      </SidebarRoot>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDBox
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={6}
                mt={-2}
                py={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Secure User Logout with Password Confirmation
                </MDTypography>
              </MDBox>
              <MDBox pt={6} pb={4} px={2}>
                <MDBox>
                  <MDBox mb={2}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      variant="standard"
                      fullWidth
                      value={Password}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onMouseDown={handleShowPassword}
                              onMouseUp={handleHidePassword}
                              edge="end"
                              style={{ margin: "1px" }}
                            >
                              {showPassword ? (
                                <Visibility color="success" />
                              ) : (
                                <VisibilityOff color="info" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mt={6}>
                  <MDButton
                    variant="gradient"
                    fullWidth
                    color="error"
                    onClick={() => handlePasswordConfirmation(Password)}
                  >
                    Logout Confirm
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </DialogContent>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    
    </>
  );
}

// Setting default values for the props of CTLSideBar
CTLSideBar.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the CTLSideBar
CTLSideBar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CTLSideBar;
