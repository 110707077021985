import DefaultCell from "application/components/DefaultCell";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableSelectIntervalTime: true,
  label: "Select Time Intervel",
  enableDownloadButton: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/queueTimeInterval/sixtyList",
  pdfDownload: "queueTimeInterval/generateAndDownloadPdf",
  csvDownload: "/queueTimeInterval/sixty/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value}/>,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Interval",
      accessor: "st",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Interval",
      accessor: "et",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Call Offered",
      accessor: "callOffered",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Call Answered",
      accessor: "answered",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Call Abandoned",
      accessor: "noAnswer",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "Download File",
    //   accessor: "",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   allowInForm: false,
    //   Cell: ({ row, value }) =>
    //     <CTLDownloadFile row={row} value={value} paCdr="paCdr"/>,
    // },
  ];
};

export { getColumns, Attributes, operationURLS };
