import React, { useState, useEffect } from "react";
import CTLNotification from "application/components/CTLNotification";
import { useMaterialUIController } from "context";
import { getColumns } from "./model";
import CTLTable from "application/components/CTLTable";
import CSVTable from "./csvTable";

const Attributes = {
  enableAcdDetails: true,
  modes: [],
};

function LeadUpload({
  onLoad,
  registerCallBacks,
  pageId,
  searchText,
  campaignId,
  viewClick,
  updateLeadUpload,
}) {
  const [controller] = useMaterialUIController();
  const { ivrExtension, activePage } = controller;

  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [open, setOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage, updateLeadUpload]);

  const operationURLS = {
    list: `/leadMaster/listAllLeadMaster/${campaignId}`,
  };

  useEffect(() => {
    setUpdateList(Date.now());
  }, [ivrExtension, campaignId]);


  function onView(row) {
    setOpen(true);
    setUuid(row?.uniqueLeadUpload);
  }

  function onCloseCSVTable() {
    setOpen(false);
  }

  return (
    <>
      <CSVTable
        openCSVTable={open}
        onCloseCSVTable={onCloseCSVTable}
        LeadUUID={uuid}
      />
      {viewClick && updateLeadUpload && (
        <CTLTable
          apiURL={operationURLS.list}
          advancedFilters={advancedFilters}
          columns={getColumns(onView)}
          entriesPerPage={false}
          canSearch
          defaultSortDirection="asc"
          defaultSortColumn=""
          pageId={pageId}
          list
          searchText={searchText}
          hasRowSelection={false}
          updateList={updateList}
        />
      )}

      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default LeadUpload;
