import React, { useState } from "react";
import DefaultCell from "application/components/DefaultCell";
import { adminMenus } from "globals/configuration/admin";
import { setAuthType } from "context";
import { useMaterialUIController } from "context";

const Attributes = {
  formSize: "lg",
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "smdp/getData",
  update: "smdp/create",
  register: null,
  delete: null,
  publish: null,
};

const authTypeFields = {
  "No Auth": [],
  "API Key": ["key", "value", "addTo"],
  "Bearer Token": ["token"],
  "Basic Auth": ["username", "password"],
  "Digest Auth": ["username", "password"],
  "OAuth 1.0": [
    "consumerKey",
    "consumerSecret",
    "token",
    "tokenSecret",
    "signatureMethod",
  ],
  "OAuth 2.0": ["accessToken", "grantType", "clientId", "clientSecret"],
  "Hawk Authentication": ["hawkId", "hawkKey", "algorithm"],
  "AWS Signature": ["accessKey", "secretKey", "awsRegion", "serviceName"],
  "NTLM Authentication": ["username", "password", "domain"],
  "Akamai EdgeGrid": ["clientToken", "clientSecret", "accessToken", "baseUrl"],
};

const getColumns = () => {
 const [controller, dispatch] = useMaterialUIController();
  const { authType } = controller;
  const onChangeSelect = (event) => {
    const selectedValue = event.target.value;
    setAuthType( dispatch, authTypeFields[selectedValue] || []);
  };
  const isFieldEnabled = (field) => authType.includes(field);

  return [
    {
      Header: "Server Domain",
      accessor: "domain",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Auth Type",
      accessor: "authType",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "String",
      onChange: onChangeSelect,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "shiftName",
        keyDescription: "shiftDescription",
        values: [
          { shiftName: "No Auth", shiftDescription: "No Auth" },
          { shiftName: "API Key", shiftDescription: "API Key" },
          { shiftName: "Bearer Token", shiftDescription: "Bearer Token" },
          { shiftName: "Basic Auth", shiftDescription: "Basic Auth" },
          { shiftName: "Digest Auth", shiftDescription: "Digest Auth" },
          { shiftName: "OAuth 1.0", shiftDescription: "OAuth 1.0" },
          { shiftName: "OAuth 2.0", shiftDescription: "OAuth 2.0" },
          {
            shiftName: "Hawk Authentication",
            shiftDescription: "Hawk Authentication",
          },
          { shiftName: "AWS Signature", shiftDescription: "AWS Signature" },
          {
            shiftName: "NTLM Authentication",
            shiftDescription: "NTLM Authentication",
          },
          {
            shiftName: "Akamai EdgeGrid",
            shiftDescription: "Akamai EdgeGrid",
          },
        ],
        url: "",
      },
    },
    {
      Header: "Key",
      accessor: "key",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("key"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Value",
      accessor: "value",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("value"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Username",
      accessor: "username",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("username"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Password",
      accessor: "password",
      displayInList: true,
      allowFilter: true,
      controlType : "password",
      allowInForm: isFieldEnabled("password"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Token",
      accessor: "token",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("token"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Consumer Key",
      accessor: "consumerKey",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("consumerKey"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Consumer Secret",
      accessor: "consumerSecret",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("consumerSecret"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Token Secret",
      accessor: "tokenSecret",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("tokenSecret"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Signature Method",
      accessor: "signatureMethod",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("signatureMethod"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Algorithm",
      accessor: "algorithm",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("algorithm"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "AWS Region",
      accessor: "awsRegion",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("awsRegion"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Service Name",
      accessor: "serviceName",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("serviceName"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Domain",
      accessor: "domain",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("domain"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Client Token",
      accessor: "clientToken",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("clientToken"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Client Secret",
      accessor: "clientSecret",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("clientSecret"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Base URL",
      accessor: "baseUrl",
      displayInList: true,
      allowFilter: true,
      allowInForm: isFieldEnabled("baseUrl"),
      dataType: "String",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
