import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import { getUpdateColumns, updateAttributes } from "./updateModel";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLNotification from "application/components/CTLNotification";
import LeadUpload from "../leadUpload";
import LeadUploadDialogue from "./LeadUploadDialogue";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton, Card, Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import MDTypography from "components/MDTypography";
import CloseIcon from "@material-ui/icons/Close";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import FilterNoneIcon from '@mui/icons-material/FilterNone';

function Campaign({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension, darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [formData, setFormData] = useState({});
  const [openView, setOpenView] = useState(false);
  const [viewLead, setViewLead] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [campid, setCampId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [selectDelete, setSelectDelete] = useState([]);
  const [copyData, setCopyData] = useState(null);
  const [copyContent, setCopyContent] = useState(false);
  const [updateLeadUpload, setUpdateLeadUpload] = useState(Date.now());
  const [openLeadUpload, setopenLeadUpload] = useState(false);
  const [fullScreenView, setfullScreenView] = useState(false);
  const [miniMizeScreen, setminiMizeScreen] = useState(false);
  const [compaignData, setcompaignData] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        Paste: Paste,
        Single: onOpenUpdate,
      });
  }, [activePage]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [ivrExtension]);

  const operationURLSS = {
    list: `/campaign/listAll/${ivrExtension?.code?.id}`,
  };

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenUpdate(true);
  };

  const deleteRow = (row) => {
    setSelectDelete(row.id);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectDelete([]);
    setConfirmDelete(false);
  };

  // table click
  const viewRow = (row) => {
    setCampaignId(row.id);
    setcompaignData(row);
    setViewLead(true);
    setUpdateLeadUpload(Date.now);
    setopenLeadUpload(true);
  };

  function onOpenUpdate() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenUpdate(true);
  }

  const onSelectionChange = (rowId) => {
    setSelectedRow(rowId);
  };

  const copyRow = (row) => {
    setCopyContent(true);
    const { id, ...rowData } = row;

    setCopyData(rowData);
    const textarea = document.createElement("textarea");
    textarea.value = JSON.stringify(rowData);
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setNotify({
      isOpen: true,
      status: "Data is copied",
      type: "success",
      pagename: pageName,
    });
  };

  function Paste() {
    setCopyContent(false);
    navigator.clipboard.readText().then((pastedData) => {
      try {
        const parsedData = JSON.parse(pastedData);
        if (parsedData) {
          parsedData.description += " - Copy";

          application
            .post(operationURLS.paste, parsedData)
            .then((response) => {
              setUpdateList(Date.now());
              setNotify({
                isOpen: true,
                type: "success",
                pagename: pageName,
                status: response.data.messageDetail,
              });
            })
            .catch((error) => {
              setNotify({
                isOpen: true,
                status: error.response.data.messageDetail,
                type: "error",
                pagename: pageName,
              });
            });
        }
      } catch (error) {
        setNotify({
          isOpen: true,
          status: "First Copy data ",
          type: "error",
          pagename: pageName,
        });
      }
    });
  }
  const onAgree = () => {
    const newData = {
      id: [selectDelete],
      systemName: ivrExtension?.code?.id,
    };
    application
      .post(operationURLS.delete, newData)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: pageName,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      const newData = {
        ...data,
        queue: ivrExtension?.code?.id,
      };
      application
        .post(operationURLS.update, newData)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            status: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      const newData = {
        ...data,
        queue: ivrExtension?.code?.id,
      };
      application
        .post(operationURLS.create, newData)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            status: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    }
    setOpenForm(false);
    setOpenUpdate(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function onCloseView() {
    setOpenView(false);
  }

  function onCloseUpdate() {
    setOpenUpdate(false);
  }

  function onUploadLead(row) {
    setOpen(true);
    setCampId(row.id);
  }

  function onCloseLeadUpload() {
    setOpen(false);
  }

  const handleClose = () => {
    setopenLeadUpload(false);
  };

  const MaximizeScreen = () => {
    setfullScreenView(true);
    setminiMizeScreen(false);
  };
  const MinimizeScreen = () => {
    setfullScreenView(false);
    setminiMizeScreen(true);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginRight: "45px",
        }}
      >
        <tooltip title="Create Campaign">
          <IconButton color="error" onClick={() => onOpenForm()}>
            <AddCircleOutlineIcon />
          </IconButton>
        </tooltip>
        <tooltip title="Pest">
          {copyContent && (
            <IconButton color="success" onClick={() => Paste()}>
              <ContentPasteIcon />
            </IconButton>
          )}
        </tooltip>
      </div>
      <MDBox>
        <DeleteConfirmation
          entityName="Campaign"
          data={selectedRow}
          columns={getColumns(onUploadLead, deleteRow, editRow, copyRow)}
          confirmDelete={confirmDelete}
          onAgree={onAgree}
          onCancel={onCancel}
        />
        <LeadUploadDialogue
          openLeadUpload={open}
          onCloseLeadUpload={onCloseLeadUpload}
          campaignId={campid}
        />
        <CTLBaseDialogForm
          entityName="General Setting"
          columns={getColumns(onUploadLead, deleteRow, editRow, copyRow)}
          openForm={openForm}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseForm}
          formData={formData}
          pageAttributes={Attributes}
        />
        <CTLBaseDialogForm
          entityName="Update Campaign"
          columns={getUpdateColumns(editRow)}
          openForm={openUpdate}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseUpdate}
          formData={formData}
          pageAttributes={updateAttributes}
        />

        <CTLBaseDialogForm
          entityName="VIEW"
          columns={getUpdateColumns(editRow)}
          openForm={openView}
          onCloseForm={onCloseView}
          formData={formData}
          pageAttributes={updateAttributes}
          ActionButtonView={true}
        />
        <CTLTable
          onSelectionChange={onSelectionChange}
          apiURL={operationURLSS.list}
          advancedFilters={advancedFilters}
          onPaste={Paste}
          columns={getColumns(
            onUploadLead,
            deleteRow,
            editRow,
            copyRow,
            viewRow
          )}
          entriesPerPage={false}
          canSearch
          defaultSortDirection="desc"
          defaultSortColumn="shortCode"
          pageId={pageId}
          searchText={searchText}
          hasRowSelection={false}
          updateList={updateList}
        />
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>

      <Dialog
        fullScreen={fullScreenView}
        onClose={handleClose}
        open={openLeadUpload}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        maxWidth="lg"
        PaperProps={{
          sx: fullScreenView
            ? {}
            : {
                width: "100%",
                height: "60%",
              },
        }}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <MDTypography>CompaignName :{compaignData?.description}</MDTypography>
          <MDTypography>
            Compaign Sort Code :{compaignData?.shortCode}
          </MDTypography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={fullScreenView ? MinimizeScreen : MaximizeScreen}
              sx={{
                position: "relative",
                top: "-8px",
              }}
            >
              {fullScreenView ? (
                <FilterNoneIcon color="error" />
              ) : (
                <CropSquareIcon color="error" />
              )}
            </IconButton>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "relative",
                top: "-8px",
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          fullScreen
        >
          {viewLead && (
            <LeadUpload
              onLoad={onLoad}
              registerCallBacks={registerCallBacks}
              pageId={pageId}
              searchText={searchText}
              pageName={pageName}
              campaignId={campaignId}
              viewClick={viewRow}
              updateLeadUpload={updateLeadUpload}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Campaign;
