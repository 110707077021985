import MDBox from "components/MDBox";
import { application } from "globals/endpoints";
import React, { useEffect, useState } from "react";
import AuthenticationService from "../../authentication/AuthenticationService";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setClickToCall } from "context";
import loggerFactory from "globals/logger/logger-factory";
import { setIsDssCall } from "context";

const DSS = ({ searchText }) => {
  const [controller, dispatch] = useMaterialUIController();
  const [extensionList, setExtensionList] = useState([]);
  const [filteredExtensionList, setFilteredExtensionList] = useState([]);

  const fetchExtensionList = async () => {
    if (AuthenticationService.getExtensionNumber()) {
      try {
        const response1 = await application.post(
          `blfExtension/${AuthenticationService.getExtensionNumber()}`
        );
        setExtensionList(response1.data.data);
      } catch (error) {
        loggerFactory.debug("Unable to get blf extension List ", error);
        setExtensionList([]);
      }
      setTimeout(fetchExtensionList, 1000);
    }
  };

  useEffect(() => {
    fetchExtensionList();
  }, []);

  const handelClickToCall = (number) => {
    setIsDssCall(dispatch, true);
    setClickToCall(dispatch, number);
  };

  useEffect(() => {
    if (searchText) {
      setFilteredExtensionList(
        extensionList.filter((extension) =>
          extension.destinationExtension
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredExtensionList(extensionList);
    }
  }, [searchText, extensionList]);

  const getExtensionStatus = (extension) => {
    if (extension && extension.status) {
      const extData = extension.status[0];
      if (extData?.registrationStatus === "UnRegistered")
        return "Not Registered";
      if (extData?.registrationStatus === "Registered") {
        switch (extData.callStatus) {
          case "":
            return "Idle  -  Available";
          case "dialing":
            return "Dialing";
          case "ringing":
            return `Ringing - ${extData.remoteParty}`;
          case "confirmed":
            if (
              extData?.remoteParty == AuthenticationService.getExtensionNumber()
            ) {
              return "Talking Self";
            } else {
              return `Talking - ${extData.remoteParty}`;
            }
          default:
            return "Not Registered";
        }
      }
    }
    return "Not Registered";
  };

  const getCardColor = (data) => {
    if (data && data.status) {
      const extData = data.status[0];
      if (extData?.registrationStatus === "UnRegistered") {
        return "#FF0000";
      }

      if (extData?.registrationStatus === "Registered") {
        switch (extData.callStatus) {
          case "":
            if (data.type === "ec") {
              if (data.isActive) {
                return "#964B00";
              } else if (data.shuntingMode === 1) {
                return "#6b5b95";
              } else if (data.shuntingMode === 2) {
                return "#09c0e0";
              } else {
                return "#FFFFFF";
              }
            } else {
              return "#1E90FF";
            }

          case "dialing":
            return "#edab2f";

          case "ringing":
            return "#FFA500";

          case "confirmed":
            if (
              extData?.remoteParty ===
              AuthenticationService.getExtensionNumber()
            ) {
              return "#3db366";
            } else {
              return "#FFFF00";
            }

          default:
            return "#808080";
        }
      }
    }

    return "#808080";
  };

  const getTextColor = (backgroundColor) => {
    if (backgroundColor === "#FFFFFF" || backgroundColor === "#FFFF00") {
      return "#000000";
    }
    return "#FFFFFF";
  };

  return (
    <MDBox>
      <Card
        style={{
          height: "72vh",
          overflow: "auto",
          position: "relative",
        }}
      >
        {filteredExtensionList?.length == 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress color="info" />
            &nbsp;&nbsp;
            <Typography variant="h6" color="text">
              Loading DSS Data...
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {filteredExtensionList &&
              filteredExtensionList.map((item, index) => (
                <Grid item xs={2} key={index}>
                  <Card
                    style={{
                      height: "150px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "auto",
                      backgroundColor: getCardColor(item),
                    }}
                    onClick={() => handelClickToCall(item.destinationExtension)}
                  >
                    <MDTypography
                      variant="h6"
                      style={{ color: getTextColor(getCardColor(item)) }}
                    >
                      {item.destinationExtension}
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      style={{ color: getTextColor(getCardColor(item)) }}
                    >
                      {item.name}
                    </MDTypography>
                    <MDTypography
                      variant="subtitle2"
                      style={{ color: getTextColor(getCardColor(item)) }}
                    >
                      {getExtensionStatus(item)}
                    </MDTypography>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </Card>
    </MDBox>
  );
};

export default DSS;
