import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  keyField:"extensionNumber",
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "imsi/list",
  create: "imsi/create",
  delete: "imsi/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Extension No",
      accessor: "extensionNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      isUserGenerated: true,
      validation: formControl.string().required("Required"),
      controlType: "select",
      filterControlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "extension",
        keyDescription: "extension",
        values: [],
        url: "/extensions/listAllExtensionPhone",
      },
    },
    {
      Header: "Extension Name",
      accessor: "extensionName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Assets",
      accessor: "asset",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "long",
      isUserGenerated: true,
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "id",
        keyDescription: "nodeName",
        values: [],
        url: "/SCADAAsset/listAll",
      },
    },
    {
      Header: "Assets Name",
      accessor: "assetName",
      displayInList: true,
      sortDisable: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "long",
      isUserGenerated: true,
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Cell ID",
      accessor: "cellId",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IMSI No",
      accessor: "imsINumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Geo Cordinate X",
      accessor: "geoX",
      displayInList: true,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Geo Cordinate - Y",
      accessor: "geoY",
      displayInList: true,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Blood Group",
      accessor: "bloodGroup",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Home Address",
      accessor: "haddredd",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Office Address",
      accessor: "oaddress",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Primary Emergency Contact Person",
      accessor: "pecName",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Primary Emergency Contact No",
      accessor: "pecNumber",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Secondary Emergency Contact Person",
      accessor: "secName",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Secondary Emergency Contact No",
      accessor: "secNumber",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Medical Details",
      accessor: "medDetails",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Zone",
      accessor: "zone",
      displayInList: true,
      allowInForm: true,
      sortDisable: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/userZone/listAll",
      },
    },
    {
      Header: "Department",
      accessor: "department",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/department/listAll",
      },
    },
    {
      Header: "Device Type",
      accessor: "deviceType",
      displayInList: true,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Connected",
      accessor: "connected",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      isUserGenerated: true,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
