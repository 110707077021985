import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const configurationMenus = [
  {
    icon: (
      <Tooltip title="Configurations">
        <Icon fontSize="medium">account_tree_icon</Icon>
      </Tooltip>
    ),
    name: "Configurations",
    key: "configurations",
    url: "/app/home/configurations",
    description: "Configurations Dashboard",
    productionReady: false,
  },
  {
    icon: (
      <Tooltip title="WhatsApp">
        <WhatsAppIcon fontSize="medium" />
      </Tooltip>
    ),
    name: "WhatsApp",
    key: "whatsapp",
    url: "/app/WhatsApp",
    description: "WhatsApp",
    productionReady: false,
  },
];

export { configurationMenus };
