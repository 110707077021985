import React, { useState, useEffect, useRef } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import CTLBaseForm from "../CTLBaseForm";
import { agentAttributes, agentOperationURLS } from "./AgentForm/model";
import { application } from "globals/endpoints";
import ChatWindow from "./ChatBox/chatWindow";
import ManualDailingBox from "./manualDailingBox";
import CTLAgentMessageSend from "../CTLAgentMessageSend";
import { useMaterialUIController, setAgentData } from "context";
import CTLNotification from "application/components/CTLNotification";
import formControl from "globals/form-control";
export default function CTLAgentDashboard({
  onLoad,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const [responseData, setResponseData] = useState(null);
  const [openMessageForm, setOpenMessageForm] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [checkSaveForm, setCheckSaveForm] = useState(false);
  const [Status, setUserStatus] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const buttonClick = useRef("");
  const { activePage, AgentData } = controller;


  const handleSend = (messageType) => {
    setOpenMessageForm(true);
    buttonClick.current = messageType;
  };

  useEffect(() => {
    agentAttributes && onLoad(agentAttributes);
    registerCallBacks({
      onChat: onChat,
    });
  }, [activePage]);

  const onChat = () => {
    setOpenChat(true);
  };

  const onCloseChat = () => {
    setOpenChat(false);
  };

  function saveForm(data) {
    if (!checkSaveForm) {
      setNotify({
        isOpen: true,
        type: "error",
        pagename: pageName,
        message: "error",
        status: " Please disconnect call to save data",
      });
    } else if (
      data.disposition === "" ||
      data.disposition === null ||
      data.disposition == undefined
    ) {
      setNotify({
        isOpen: true,
        type: "warning",
        pagename: pageName,
        message: "Warning",
        status: "Please select disposition to save data",
      });
    } else {
      const customerData = {
        dialId: responseData.dialId,
        phoneNumber: responseData.formData.phoneNumber,
        formType: responseData.formType,
        sessionUuid: responseData.agentInfo.sessionUuid,
        uuid: responseData.agentInfo.uuid,
        valueType: responseData.formData.valueType,
        queueName:responseData.agentInfo.queueSystemName,
        campaignId: responseData.campaignId,
      };

      let formData = data;
      let customerInfoData = customerData;

      let APIData = { formData, customerInfoData };
      application
        .post(agentOperationURLS.create, APIData)
        .then((response) => {
          setNotify({
            isOpen: true,
            type: "success",
            pagename: pageName,
            message: response.data.message,
            status: response.data.status,
          });
          setResponseData(null);
          setAgentData(dispatch, "");
          const payload = { status: "Start Call" };
          {
            Status != "On Break" &&
              Status != "Available for manual" &&
              Status != "Available (On Demand) for manual" &&
              application.post("/agentStatus/create", payload);
          }
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: pageName,
          });
        });
    }
  }

  useEffect(() => {
    if (responseData) {
      if (responseData.dispositionUrl !== "") {
        window.open(responseData.dispositionUrl, "_blank");
      }
    }
  }, [responseData]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={9.5}>
          <Card
            style={{
              minHeight: "calc(100vh - 150px)",
            }}
          >
            {responseData && (
              <MDBox
                style={{
                  maxHeight: "calc(100vh - 150px)",
                  overflow: "auto",
                  position: "relative",
                }}
              >
                {responseData.dispositionUrl == "" && (
                    <CTLBaseForm
                      enableAgentFormButtons={true}
                      handleSend={handleSend}
                      entityName="Agent"
                      columns={responseData.form}
                      openForm={true}
                      onSaveForm={saveForm}
                      onCloseForm={null}
                      formData={responseData.formData}
                      formAttributes={agentAttributes}
                    />
                  )}
              </MDBox>
            )}
          </Card>
        </Grid>
        <Grid item xs={2.5}>
          <Card
            style={{
              minHeight: "calc(100vh - 150px)",
            }}
          >
            <MDBox
              style={{
                maxHeight: "calc(100vh - 150px)",
                overflow: "auto",
                position: "relative",
              }}
            >
              <ManualDailingBox
                setResponseData={setResponseData}
                setCheckSaveForm={setCheckSaveForm}
                setUserStatus={setUserStatus}
                activePage={activePage}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <ChatWindow openChat={openChat} onCloseChat={onCloseChat} />
      <CTLAgentMessageSend
        onSendClick={""}
        onSendMessage={openMessageForm}
        onCloseMessageBox={() => setOpenMessageForm(false)}
        buttonClick={buttonClick.current}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
