import React, { useState } from "react";
import { Icon, IconButton, Input } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

function LeadUploadDialogue({ openLeadUpload, onCloseLeadUpload, campaignId }) {
  const [darkMode] = useMaterialUIController();
  const [selectedFile, setSelectedFile] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  // Get Dropdown value or Discription by api
  const operationURLS = {
    Upload: "/leadUpload/upload",
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setNotify({
        isOpen: true,
        message: "Please select a CSV file.",
        type: "error",
        pagename: "Lead Upload",
        status: "error",
      });
    }
  };

  function handleUpload() {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("campaignId", campaignId);

    application
      .post(operationURLS.Upload, formData)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "File Uploaded Successfully",
          status: response.data.message,
        });
        onCloseLeadUpload();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: "Lead Upload",
          status: error.response.data.message,
        });
        console.error("Error uploading file:", error);
      });
  }

  return (
    <>
      <Dialog
        open={openLeadUpload}
        onClose={onCloseLeadUpload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ dialog }}
        fullWidth
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <Typography style={{ fontSize: "20px" }}>Lead Upload</Typography>
          <IconButton
            onClick={onCloseLeadUpload}
            sx={{
              position: "relative",
              top: "-8px",
            }}
          >
            <CloseIcon color="error" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <Input type="file" accept=".csv" onChange={handleFileChange} />
        </DialogContent>

        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton
            variant="contained"
            color="success"
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            Upload <Icon>cloud_upload</Icon>
          </MDButton>
        </DialogActions>
      </Dialog>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default LeadUploadDialogue;
