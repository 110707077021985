import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import { userMenus } from "globals/configuration/user-management";
import formControl from "globals/form-control";
const groupAdmin = AuthenticationService.allowGroupAdmin();
const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
   modes: groupAdmin ?   groupAdminMenu.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
   }) : userMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "role/list",
  update: "role/update",
  create: "role/create",
  delete: "role/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Role Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      width: 6,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Role",
      accessor: "roles",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Active ",
      accessor: "enabled",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "boolean",
      dataType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    // {
    //   Header: "Star",
    //   accessor: "star",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: false,
    //   dataType: "boolean",
    //   controlType:"boolean",
    //   width : 6,
    //   Cell: ({ value }) => <CTLBooleanCell value={value} />,
    // },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
