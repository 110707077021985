import { createApi } from "@reduxjs/toolkit/query/react";
import { application } from "globals/endpoints";
import { telemetry } from "globals/endpoints";
import NotificationManager from "globals/websocket/WebSocket";

export const hospitalapi = createApi({
  reducerPath: "hospitalapi",
  baseQuery: async (args) => {
    try {
      if (typeof args == "string") {
        const result = await application(args);
        return result;
      } else {
        const { url, method, body } = args;
        const result = await application({ url, method, data: body });
        return result;
      }
    } catch (axiosError) {
      return { error: axiosError.response?.data || axiosError.message };
    }
  },
  tagTypes: ["extensionRefetch"],
  endpoints: (builder) => ({
    getHospitalJSON: builder.query({
      query: () => ({
        url: "configJson/getConfigFile",
        method: "GET",
      }),
      transformResponse: (res) => res.data.data,
      providesTags: ["extensionRefetch"],
    }),
    updateHopsitalJSON: builder.mutation({
      query: (payload) => ({
        url: "configJson/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["extensionRefetch"],
    }),
    addExtensionData: builder.mutation({
      query: (payload) => ({
        url: "extensions/dynamicCreate",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["extensionRefetch"],
    }),
  }),
});

export const {
  useGetHospitalJSONQuery,
  useUpdateHopsitalJSONMutation,
  useAddExtensionDataMutation,
} = hospitalapi;
