import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BuildingIcon, FloorIcon, RoomIcon } from "./Icons";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Icon, IconButton } from "@mui/material";
import { Phone } from "@mui/icons-material";
import FlareIcon from "@mui/icons-material/Flare";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PentagonIcon from "@mui/icons-material/Pentagon";
import RouteIcon from "@mui/icons-material/Route";
import TableChartIcon from "@mui/icons-material/TableChart";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import data from "../options.json";
import colors from "assets/theme-dark/base/colors";

const getIcon = (type, darkMode, isAssigned = false) => {
  const getIconText = (type) => {
    const optionsData = data?.options;
    const option = optionsData.find((item) => item.type === type);
    return option?.icon;
  };

  switch (type) {
    case "geofence":
      return <PentagonIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "project":
      return (
        <TableChartIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />
      );
    case "route":
      return <RouteIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "zones":
      return <PentagonIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "routes":
      return <RouteIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "groups":
      return <GroupsIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "group":
      return <GroupsIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    case "user":
      return (
        <PersonIcon
          sx={{
            color: isAssigned ? "#4CAF50" : darkMode ? "#ffffff" : "#000000",
            transition: "color 0.3s ease", // Smooth color transition
          }}
        />
      );
    case "users":
      return <GroupsIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />;
    // case "extension":
    //   return <AccountBalanceIcon />;
    case "room":
      return (
        <div
          style={{
            paddingLeft: "0em",
            display: "flex",
            alignItems: "center",
          }}
        >
          <RoomIcon />
        </div>
      );
    case "rooms":
      return (
        <div
          style={{
            paddingLeft: "0em",
            display: "flex",
            alignItems: "center",
          }}
        >
          <RoomIcon />
        </div>
      );
    case "company":
      return (
        <IconButton>
          <ApartmentIcon color={darkMode ? "white" : "grey"} />
        </IconButton>
      );
    case "number":
      return <Phone color={darkMode ? "white" : "grey"} />;
    case "features":
      return <FlareIcon color={darkMode ? "white" : "grey"} />;
    case "Code Blue":
      return (
        <MarkunreadMailboxIcon
          color={darkMode ? "white" : "grey"}
          fontSize="0.4em"
        />
      );
    case "IVR":
      return (
        <RecordVoiceOverIcon
          color={darkMode ? "white" : "grey"}
          fontSize="0.1em"
        />
      );
    case "CallCenter":
      return (
        <WifiCalling3Icon
          color={darkMode ? "white" : "grey"}
          fontSize="0.1em !important"
        />
      );
    case "extension":
      return (
        <DragIndicatorIcon
          color={darkMode ? "white" : "grey"}
          fontSize="0.1em !important"
        />
      );
    case "services":
      return <MedicalServicesIcon color={darkMode ? "white" : "grey"} />;
    case "Departments":
      return <Grid3x3Icon color={darkMode ? "white" : "grey"} />;
    case "Zones":
      return <LocationOnIcon color={darkMode ? "white" : "grey"} />;
    default:
      return (
        <Icon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
          {getIconText(type)}
        </Icon>
      );
  }
};

export default getIcon;
