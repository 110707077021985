import DefaultCell from "application/components/DefaultCell";


const getAssignedColumns = () => {
  return [
    {
      Header: "Asset Name ",
      accessor: "assetName",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
     
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
     
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
     
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Severity Name",
      accessor: "severityName",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
     
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Assign/To",
      accessor: "userId",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      isMandatory:true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "username",
        values: [],
        url: "user/engineerUsersList",
      },
    },
  ];
};

export { getAssignedColumns };
