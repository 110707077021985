import { createApi } from "@reduxjs/toolkit/query/react";
import { application } from "globals/endpoints";
import { telemetry } from "globals/endpoints";

export const geofenceApi = createApi({
  reducerPath: "geofenceApi",
  baseQuery: async (args) => {
    try {
      if (typeof args == "string") {
        const result =
          args?.url === "configJson/getGeometryData" ||
          args?.url === "configJson/saveGeometryData" ||
          args?.url === "user/list"
            ? await application(args)
            : await telemetry(args);
        return result;
      } else {
        const { url, method, body } = args;
        const result =
          args?.url === "configJson/getGeometryData" ||
          args?.url === "configJson/saveGeometryData" ||
          args?.url === "user/list"
            ? await application({ url, method, data: body })
            : await telemetry({ url, method, data: body });
        return result;
      }
    } catch (axiosError) {
      return { error: axiosError.response?.data || axiosError.message };
    }
  },
  tagTypes: ["fenceRefetch"],
  endpoints: (builder) => ({
    getGeometryData: builder.query({
      query: () => ({
        url: "configJson/getGeometryData",
        method: "GET",
      }),
      transformResponse: (res) => res.data.data,
      providesTags: ["fenceRefetch"],
    }),
    updateGeometryData: builder.mutation({
      query: (payload) => ({
        url: "configJson/saveGeometryData",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    createPolygon: builder.mutation({
      query: (payload) => ({
        url: "area/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    updatePolygon: builder.mutation({
      query: (payload) => ({
        url: "area/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    getAllPolygon: builder.mutation({
      query: (payload) => ({
        url: "area/listAll",
        method: "POST",
        body: payload,
      }),

      invalidatesTags: ["fenceRefetch"],
    }),
    getAllPolygonUsers: builder.mutation({
      query: (shapeId) => ({
        url: `area/listOfUserByShapeId/${shapeId}`,
        method: "POST",
      }),
    }),
    createRoute: builder.mutation({
      query: (payload) => ({
        url: "route/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    updateRoute: builder.mutation({
      query: (payload) => ({
        url: "route/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    getAllRoute: builder.mutation({
      query: (payload) => ({
        url: "route/listAll",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["fenceRefetch"],
    }),
    getAllUser: builder.mutation({
      query: (payload) => ({
        url: "user/list",
        method: "POST",
        body: {
          pageSize: 20,
          sortDirection: "asc",
          sortBy: "id",
          search: "",
          sortDataType: "integer",
          advancedFilters: [],
        },
      }),
    }),
    deletePolygon: builder.mutation({
      query: (payload) => ({
        url: "/area/delete/shapeId",
        method: "POST",
        body: payload,
      }),
    }),
    deleteRoute: builder.mutation({
      query: (payload) => ({
        url: "/route/delete/shapeId",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetGeometryDataQuery,
  useUpdateGeometryDataMutation,
  useCreatePolygonMutation,
  useUpdatePolygonMutation,
  useGetAllPolygonMutation,
  useGetAllPolygonUsersMutation,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useGetAllRouteMutation,
  useGetAllUserMutation,
  useDeletePolygonMutation,
  useDeleteRouteMutation,
} = geofenceApi;
