import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";
import { adminMenus } from "globals/configuration/admin";
import { useEffect, useState } from "react";

const Attributes = {
  formSize: "md",
  columns: 3,
  keyField: "extension",
  enableAdvanceSearch: true,
  enableAddButton: true,
  tabSize: 4,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "/extensions/list/extensions",
  update: "/extensions/update/extension",
  delete: "/extensions/update/zoneAndDepartment",
};

const getColumns = (onDelete, onEdit, updateForm) => {
    const [Type, setType] = useState("");
    const [ExtensionURL, setExtensionURL] = useState("");
  
    const onChangeExtensionType = (event, formik) => {
      const isChecked = event.target.value;
      setType(isChecked);
      const ExtensionURL = `/extensions/listExtensionType/${isChecked}`;
      setExtensionURL(ExtensionURL);
    };

    useEffect(() => {
        if(!updateForm){
            setExtensionURL("")
        }
        setExtensionURL(ExtensionURL);
      }, [Type]);

  return [
    {
      Header: "Extension Type",
      accessor: "extensionType",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      disabled: updateForm ? true : false,
      controlType: updateForm ? "" : "select",
      width: 6,
      onChange: onChangeExtensionType,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      dataType: "string",
      options: {
        keyField: "extensionType",
        keyDescription: "extensionType",
        values: [],
        url: "/extensions/listAllExtensionType",
      },
    },
    {
      Header: "Extension Number",
      accessor: "extension",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      isUserGenerated: true,
      disabled: updateForm ? true : false,
      dataType: "string",
      filterControlType: "select",
      controlType: updateForm ? "" : "select",
      dynamicSelectData:Type,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      options: {
        keyField:"extension",
        keyDescription: "extension",
        values: [],
        url:ExtensionURL,
      },
    },

    {
      Header: "Zone",
      accessor: "zone",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/userZone/listAll",
      },
    },
    {
      Header: "Department",
      accessor: "department",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/department/listAll",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
