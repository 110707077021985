import CTLUnReadCell from "application/components/CTLUnReadCell";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";


const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  
  modes: callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/agentStatus/list",
  pdfDownload: "/agentStatus/generateAndDownloadPdf",
  csvDownload: "/agentStatus/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "User Id",
      accessor: "username",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      filterControlType: "select",
      options: {
        keyField: "loginId",
        keyDescription: "loginId",
        values: [],
        url: "userInfoView/listAll",
      },
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "userFullname",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Extension No",
      accessor: "extensionNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Log Date",
      accessor: "date",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Log Time",
      accessor: "time",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
