import React from "react";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  enableAddButton: true,
  enableSelecStatictMenu: true,
  enableViewAll: true,
  label: "Select Extension Type",
  options: {
    keyField: "id",
    keyDescription: "description",
    values: [],
  },
  modes: [],
};

const operationURLSS = {
  update: "fwdExtension/update",
  create: "fwdExtension/create",
  delete: "fwdExtension/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      setChange: "toTime",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "To Time",
      accessor: "toTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Forward Extension Number",
      accessor: "forwardExtension",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().digitsOnly().required("Required"),
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLSS };
