import CTLUnReadCell from "application/components/CTLUnReadCell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { callCenterConfigurationReports } from "application/modules/configuration/callCenterConfiguration/component/allCallcenterRepots/reportMenu";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";


const getAgent = AuthenticationService.allowAgentMenus();
const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh:true,
  enableExitButton: getAgent ? true : false,
  modes: getAgent ?  agentReportMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  })    
  :
  callCenterConfigurationReports.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/agentSummary/dailyReportList",
  pdfDownload: "/agentSummary/generateAndDownloadPdf",
  csvDownload: "/agentSummary/dailyReportDownloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Agent Id",
      accessor: "agentName",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "fullName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Start Date",
      accessor: "startDate",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Call Offered",
      accessor: "totalOffered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total No Answered",
      accessor: "totalNoAnswered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Answered",
      accessor: "totalAnswered",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Net Login Time",
      accessor: "totalLoginDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Login Time",
      accessor: "netLoginTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Break Time",
      accessor: "totalBreakDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Ring Time",
      accessor: "totalRingDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "totalTalkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Avg Talk Time",
      accessor: "averageTalkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "ACW Time",
      accessor: "totalAcwDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total Unservice Time",
      accessor: "unServiceDuration",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Idle Time",
      accessor: "totalIdleDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
