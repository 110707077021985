import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { BuildingIcon, FloorIcon, RoomIcon } from "../Icons/Icons";
import EmptyImage from "../../../../assets/images/mediacenter/emptyFolder.svg";
import { useMaterialUIController } from "context";
import FlareIcon from "@mui/icons-material/Flare";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { Phone } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import getIcon from "../Icons/getIcon";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import MDInput from "components/MDInput";
// import GetComponent from "features/GetComponent";

const isUserAssignedAnywhere = (folders, user) => {
  const userId = user?.userData?.id ||
    user?.userDetails?.id ||
    user?.id ||
    user?.name ||
    user?.username;

  if (!userId) return false;

  const stack = [...folders];
  while (stack.length > 0) {
    const currentFolder = stack.pop();

    if ((currentFolder.type === "geofence" || currentFolder.type === "route") &&
      currentFolder.children) {
      const usersFolder = currentFolder.children.find(child => child.type === "users");
      if (usersFolder && usersFolder.children) {
        const userFound = usersFolder.children.some(assignedUser => {
          const assignedUserId = assignedUser?.userData?.id ||
            assignedUser?.userDetails?.id ||
            assignedUser?.id ||
            assignedUser?.name ||
            assignedUser?.username;

          return assignedUserId === userId ||
            assignedUserId?.toString() === userId?.toString() ||
            assignedUser.name === user.name;
        });

        if (userFound) return true;
      }
    }

    if (currentFolder.children) {
      stack.push(...currentFolder.children);
    }
  }
  return false;
};

const FileViewer = ({
  folders,
  currentFolder,
  folderPath,
  isDelete,
  findFolderById,
  handleFolderClick,
  getChildren,
  handleSelected,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const [roomInputDialog, setRoomInputDialog] = useState(null);
  const [parentFolderType, setParentFolderType] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenAddForm = (e, type) => {
    setRoomInputDialog(type);
  };

  const getParentFoldertype = () => {
    const parentFolder = findFolderById(folders, currentFolder?.parentId);
    setParentFolderType(parentFolder?.type);
  };

  const filterUsers = (users) => {
    if (!searchQuery) return users;

    return users.filter(user => {
      const searchTerm = searchQuery.toLowerCase();
      const userName = (user.name || user.username || "").toLowerCase();
      return userName.includes(searchTerm);
    });
  };

  useEffect(() => { }, [isDelete]);
  useEffect(() => {
    getParentFoldertype();
  }, [currentFolder]);

  return (
    <>
      {currentFolder && (
        <>
          <>
            <div>
              {folderPath ? (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mb: 2
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "col",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "col",
                        justifyContent: "start",
                        alignItems: "center",
                        flexGrow: 1,
                        // gap: "0.5em",
                      }}
                    >
                      {folderPath.map((folder, index) => (
                        <MDBox
                          key={folder.id}
                          onClick={() => handleFolderClick(folder)}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.2m",
                          }}
                        >
                          <MDTypography
                            sx={{
                              borderRadius: "0.375em",
                              cursor: "pointer",
                              // // paddingInline: "0.3em",
                              // backgroundColor: "green",
                              fontSize: "0.8em",
                              ":hover": {
                                bgcolor: "rgba(255,255,255,0.2)",
                              },
                            }}
                          >
                            {folder.name}
                          </MDTypography>
                          {index !== folderPath.length - 1 && (
                            <MDTypography>{"/"}</MDTypography>
                          )}
                        </MDBox>
                      ))}
                    </MDBox>
                    {/* <MDTypography
                      sx={{
                        fontSize: "0.8em",
                        flexGrow: 1,
                        ":hover": {
                          bgcolor: "rgba(255,255,255,0.2)",
                        },
                      }}
                    >
                      Configuration : {currentFolder?.type}
                    </MDTypography> */}
                    {currentFolder.type === "users" && (
                      <MDInput
                        fullWidth
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "40px",
                          },
                          width: "30%",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="medium" color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </MDBox>


                </MDBox>
              ) : (
                <p>Folder not found.</p>
              )}
            </div>

            {currentFolder?.children?.length > 0 ||
              currentFolder?.length > 0 ? (
              <List
                sx={{
                  display: "grid",
                  gridTemplateColumns: `repeat(auto-fill, minmax(100px, 1fr))`,
                  marginTop: "1em",
                }}
              >
                {currentFolder.type === "users"
                  ? filterUsers(getChildren(currentFolder)).map((child) => (
                    <ListItem
                      key={child.id}
                      draggable={child.type === "user"}
                      onDragStart={(e) => {
                        e.dataTransfer.setData("application/json", JSON.stringify(child));
                        e.dataTransfer.effectAllowed = "move";
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        width: "5em",
                        ":hover": {
                          backgroundColor: "rgba(255,255,255,0.2)",
                          borderRadius: "0.375em",
                          cursor: child.type === "user" ? "grab" : "pointer",
                        },
                      }}
                      onClick={(e) => handleSelected(e, child)}
                    >
                      <IconButton size="medium">
                        {getIcon(
                          child.type,
                          darkMode,
                          child.type === "user" && isUserAssignedAnywhere(folders, child)
                        )}
                      </IconButton>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "12px" }}
                        sx={{
                          color: darkMode ? "#fff" : "#000",
                        }}
                        primary={child.name}
                      />
                    </ListItem>
                  ))
                  : getChildren(currentFolder).map((child) => (
                    <ListItem
                      key={child.id}
                      draggable={child.type === "user"}
                      onDragStart={(e) => {
                        e.dataTransfer.setData("application/json", JSON.stringify(child));
                        e.dataTransfer.effectAllowed = "move";
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        width: "5em",
                        ":hover": {
                          backgroundColor: "rgba(255,255,255,0.2)",
                          borderRadius: "0.375em",
                          cursor: child.type === "user" ? "grab" : "pointer",
                        },
                      }}
                      onClick={(e) => handleSelected(e, child)}
                    >
                      <IconButton size="medium">
                        {getIcon(
                          child.type,
                          darkMode,
                          child.type === "user" && isUserAssignedAnywhere(folders, child)
                        )}
                      </IconButton>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "12px" }}
                        sx={{
                          color: darkMode ? "#fff" : "#000",
                        }}
                        primary={child.name}
                      />
                    </ListItem>
                  ))
                }
              </List>
            ) : (
              <>
                {currentFolder?.type === "extension" ||
                  currentFolder?.type === "number" ? (
                  <div>
                    {/* <GetComponent
                      currentFolder={currentFolder}
                      parentFolderType={parentFolderType}
                      pageId={pageId}
                      searchText={searchText}
                      registerCallBacks={registerCallBacks}
                      pageName={pageName}
                    /> */}
                  </div>
                ) : (
                  <>
                    {currentFolder?.type === "" ||
                      currentFolder?.type === "ivr" ||
                      currentFolder?.type === "CallCenter" ||
                      currentFolder?.type === "features" ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "80vh",
                          alignItems: "center",
                        }}
                      >
                        <img src={EmptyImage} alt="Empty Folder" height={100} />
                        <MDTypography variant="h4" textAlign="center">
                          You haven't configured anything yet
                        </MDTypography>
                        {/* <MDTypography variant="p">
                      Click on the button below to add{" "}
                    </MDTypography>

                    <MDButton
                      variant="gradient"
                      color="info"
                      sx={{ marginTop: "3em" }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      Add Data
                    </MDButton> */}
                        <MenuComponent
                          anchorEl={anchorEl}
                          handleCloseMenu={handleCloseMenu}
                          handleOpenAddForm={handleOpenAddForm}
                          marginLeft={"0em"}
                        />
                      </MDBox>
                    ) : (
                      <div style={{ maxWidth: "80vw" }}>
                        {/* <GetComponent
                          currentFolder={currentFolder}
                          parentFolderType={currentFolder?.type}
                          pageId={pageId}
                          searchText={searchText}
                          registerCallBacks={registerCallBacks}
                          pageName={pageName}
                        /> */}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

export default FileViewer;
