import React, { useRef, useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { Box } from "@mui/material";
import HistoryFilter from '../HistoryFilter';
import { filterHistoryData } from '../mockHistoryData';
import MDTypography from "components/MDTypography";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import 'leaflet-polylinedecorator';

const HistoryPathMap = ({ defaultLocation, folderPath, handleFolderClick }) => {
  const mapRef = useRef(null);
  const featureGroupRef = useRef(null);
  
  // Get today's date in the format used in your data
  const today = new Date().toLocaleDateString();
  
  // Fetch today's history data
  const initialHistoryData = filterHistoryData(today, today);
  console.log("Initial History Data for Today:", initialHistoryData);
  
  const [historyData, setHistoryData] = useState(initialHistoryData);

  useEffect(() => {
    if (!featureGroupRef.current) return;

    const featureGroup = featureGroupRef.current;
    featureGroup.clearLayers();

    if (historyData.length > 0) {
      console.log("Rendering History Data:", historyData);
      // Group points by date
      const journeysByDate = historyData.reduce((acc, point) => {
        const date = new Date(point.timestamp).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(point);
        return acc;
      }, {});

      // Create paths for each day
      Object.entries(journeysByDate).forEach(([date, points]) => {
        const pathPoints = points.map(point => [point.location.lat, point.location.lng]);
        
        if (pathPoints.length > 1) {
          // Create the path
          const path = L.polyline(pathPoints, {
            color: '#d40617',
            weight: 3,
            opacity: 0.8,
            dashArray: '10, 10',
            lineCap: 'round'
          }).addTo(featureGroup);

          // Add markers for each point
          points.forEach((point, index) => {
            const isEndPoint = index === 0 || index === points.length - 1;
            const marker = L.circleMarker([point.location.lat, point.location.lng], {
              radius: isEndPoint ? 12 : 8,
              fillColor: index === 0 ? '#4CAF50' : index === points.length - 1 ? '#f44336' : '#2196F3',
              color: '#fff',
              weight: 2,
              opacity: 1,
              fillOpacity: 0.8,
              zIndexOffset: isEndPoint ? 1000 : 500
            }).addTo(featureGroup);

            // Enhanced popup content
            const timestamp = new Date(point.timestamp).toLocaleString();
            const popupContent = `
              <div style="min-width: 200px;">
                <strong>Date:</strong> ${date}<br/>
                <strong>Time:</strong> ${new Date(point.timestamp).toLocaleTimeString()}<br/>
                ${point.speed ? `<strong>Speed:</strong> ${point.speed} km/h<br/>` : ''}
                ${point.address ? `<strong>Location:</strong> ${point.address}<br/>` : ''}
              </div>
            `;
            
            marker.bindPopup(popupContent);
            marker.on('mouseover', function() { this.openPopup(); });
            marker.on('mouseout', function() { this.closePopup(); });
          });

          // Add arrow decorations to show direction
          L.polylineDecorator(path, {
            patterns: [
              {
                offset: '5%',
                repeat: '10%',
                symbol: L.Symbol.arrowHead({
                  pixelSize: 15,
                  polygon: false,
                  pathOptions: {
                    color: '#d40617',
                    fillOpacity: 1,
                    weight: 2
                  }
                })
              }
            ]
          }).addTo(featureGroup);
        }
      });

      // Fit bounds to show all points
      const allPoints = historyData.map(point => [point.location.lat, point.location.lng]);
      const bounds = L.latLngBounds(allPoints);
      mapRef.current?.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [historyData]);

  const handleHistoryFilterChange = ({ startDate, endDate }) => {
    const filteredData = filterHistoryData(startDate, endDate);
    console.log("Filtered Data:", filteredData);
    setHistoryData(filteredData);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "96vh" }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        {/* Breadcrumb Navigation */}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1 }}>
          {folderPath?.map((folder, index) => (
            <Box
              key={folder.id}
              onClick={() => handleFolderClick(folder)}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <MDTypography
                sx={{
                  borderRadius: "0.375em",
                  cursor: "pointer",
                  // paddingInline: "0.3em",
                 
                  fontSize: "0.8em",
                  ":hover": {
                    bgcolor: "rgba(255,255,255,0.2)",
                  },
                }}
              >
                {folder.name}
              </MDTypography>
              {index !== folderPath.length - 1 && (
                <MDTypography>{"/"}</MDTypography>
              )}
            </Box>
          ))}
        </Box>
        
        {/* History Filter */}
        <Box sx={{ width: "auto" }}>
          <HistoryFilter onFilterChange={handleHistoryFilterChange} />
        </Box>
      </Box>

      {/* Map */}
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <MapContainer
          center={defaultLocation || [28.6139, 77.209]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
          whenCreated={(map) => { mapRef.current = map; }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <FeatureGroup ref={featureGroupRef} />
        </MapContainer>
      </Box>
    </Box>
  );
};

export default HistoryPathMap; 