import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useMaterialUIController } from "context";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { application } from "globals/endpoints";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import MDTypography from "components/MDTypography";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import CTLNotification from "application/components/CTLNotification";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MDBox from "components/MDBox";
import TaskIcon from "@mui/icons-material/Task";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

export default function TaskStatusUpdate({ row, onRefresh }) {
  const Id = row?.original?.id;
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const darkModeContext = useMaterialUIController();
  const { darkMode } = controller;
  const [ViewData, setViewData] = useState([]);
  const currentStats = row?.original?.currentStats;
  const taskCompletedOn = row?.original?.taskCompletedOn;
  const taskName = row?.original?.tasksName;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const URL = {
    list: `/taskDetails/listAll/${Id}`,
    update: "task/update",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function GetData() {
    application
      .post(URL.list)
      .then((res) => {
        setViewData(res.data.data);
      })
      .catch((error) => {
        console.log("error while the call /taskdetails/listAll", error);
      });
  }

  useEffect(() => {
    if (open) {
      GetData();
    } else {
      setViewData([]);
    }
  }, [open]);
  const username = AuthenticationService.getUserName();
  // Close Task
  function onUpdateStatus() {
    let now = new Date();
    let formattedDate =
      now.getFullYear() +
      "-" +
      String(now.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(now.getDate()).padStart(2, "0") +
      " " +
      String(now.getHours()).padStart(2, "0") +
      ":" +
      String(now.getMinutes()).padStart(2, "0");

    let data = {
      currentStats: "Closed",
      taskCompletedOn: formattedDate,
      isTaskCompleted: true,
      tasksName: taskName,
      remarks: `Task Approved by ${username}`,
    };
    application
      .post(URL.update, data)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: "Task Manager",
        });
        onRefresh();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Task Manager",
        });
      });
  }

  // ReOpen Task
  function onReOpenTask() {
    let now = new Date();
    let formattedDate =
      now.getFullYear() +
      "-" +
      String(now.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(now.getDate()).padStart(2, "0") +
      " " +
      String(now.getHours()).padStart(2, "0") +
      ":" +
      String(now.getMinutes()).padStart(2, "0");

    let data = {
      currentStats: "ReOpen",
      taskCompletedOn:"",
      tasksName: taskName,
    };
    application
      .post(URL.update, data)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: "Task Manager",
        });
        onRefresh();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Task Manager",
        });
      });
  }
  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark"
  );
  const header = [
    {
      name: "taskId",
      selector: (row) => row?.taskId,
      sortable: true,
    },
    {
      name: "taskStats",
      selector: (row) => row?.taskStats,
      sortable: true,
    },
    {
      name: "statusUpdatedBy",
      selector: (row) => row?.statusUpdatedBy,
      sortable: true,
    },
    {
      name: "statusUpdatedOn",
      selector: (row) => row?.statusUpdatedOn,
      sortable: true,
    },
    {
      name: "Is Delayed",
      selector: (row) => (row?.isDelay ? "True" : "False"),
      sortable: true,
    },

    {
      name: "changedCompletionOn",
      selector: (row) => row?.changedCompletionOn,
      sortable: true,
    },
    {
      name: "remarks",
      selector: (row) => row?.remarks,
      sortable: true,
    },
  ];
  return (
    <div>
      {currentStats === "Closed" && !taskCompletedOn ? (
        <Tooltip title="Approved Task">
          <IconButton
            color="primary"
            onClick={() => handleClickOpen(row.original)}
          >
            <TaskIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="">
          <IconButton color="error">
            <NotInterestedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <MDTypography>Task Details</MDTypography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "relative",
              top: "-8px",
            }}
          >
            <CloseIcon color="error" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          fullScreen
        >
          <DataTable
            columns={header}
            data={ViewData}
            canSearch
            responsive
            pagination
            striped
            paginationPerPage={20}
            paginationRowsPerPageOptions={[5, 10, 20]}
            theme={
              darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"
            }
            fixedHeader
            persistTableHead
            fixedHeaderScrollHeight={`${(window.innerHeight / 100) * 66}px`}
          />
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            {currentStats === "Closed" ? (
              <Tooltip title="Approved">
                <Button
                  onClick={() => onUpdateStatus(row.original)}
                  sx={{
                    backgroundColor: "#F44335",
                    "&:hover": {
                      backgroundColor: "#F44335",
                    },
                  }}
                  variant="contained"
                >
                  Closed Task
                </Button>
              </Tooltip>
            ) : (
              <IconButton color="error">
                <NotInterestedIcon />
              </IconButton>
            )}

            {currentStats === "" ? (
              <IconButton color="error" variant="contained">
                <NotInterestedIcon />
              </IconButton>
            ) : (
              <Tooltip title="Task ReOpen">
                <Button
                  onClick={() => onReOpenTask(row.original)}
                  color="primary"
                  variant="contained"
                >
                  Task ReOpen
                </Button>
              </Tooltip>
            )}
          </MDBox>
        </DialogContent>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}
