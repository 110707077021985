import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import CTLNotification from "application/components/CTLNotification";

function TeamleadNotAssociatedAgent({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  setSelectedNotAssociatTL,
  updateNotAssociateTL
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const [updateList, setUpdateList] = useState(Date.now());
  const [selectedRow, setSelectedRow] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  useEffect(() => {
    
  }, [ivrExtension,updateNotAssociateTL]);

  const QAgentoperationURLS = {
    NotAssociatedTeamleaderList: `/agent/list/agentWithoutTL`,
  };

  const onSelectionChange = (rowId) => {
    setSelectedNotAssociatTL(rowId);
  };


  return (
    <MDBox style={{ height: "590px", overflow: "auto" }}>
      <CTLTable
        apiURL={QAgentoperationURLS.NotAssociatedTeamleaderList}
        onSelectionChange={onSelectionChange}
        columns={getColumns()}
        entriesPerPage="false"
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="name"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateNotAssociateTL}
        disablePagination={true}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default TeamleadNotAssociatedAgent;
