const predefinedFields = [
  {
    dataType: "String",
    accessor: "state",
    Label: "State",
  },
  {
    dataType: "String",
    accessor: "city",
    Label: "City",
  },
  {
    dataType: "String",
    accessor: "address",
    Label: "Address",
  },
  {
    dataType: "String",
    accessor: "emailId",
    Label: "Email Id",
  },
  {
    dataType: "String",
    accessor: "phoneNumber",
    Label: "Phone Number",
  },
  {
    dataType: "Boolean",
    accessor: "isDisabled",
    Label: "Disabled",
  },
  {
    dataType: "String",
    accessor: "description",
    Label: "Description",
  },
  {
    dataType: "String",
    accessor: "nation",
    Label: "Nation",
  },
  {
    dataType: "String",
    accessor: "hPhone",
    Label: "Home Phone",
  },
  {
    dataType: "String",
    accessor: "hMode",
    Label: "Home Mode",
  },
  {
    dataType: "String",
    accessor: "ofAddr",
    Label: "Office Address",
  },
  {
    dataType: "String",
    accessor: "ofPhone",
    Label: "Office Phone",
  },
  {
    dataType: "String",
    accessor: "ofMob",
    Label: "Office Mobile",
  },
  {
    dataType: "String",
    accessor: "ofFax",
    Label: "Office Fax",
  },
  {
    dataType: "String",
    accessor: "suggestion",
    Label: "Suggestion",
  },
  {
    dataType: "String",
    accessor: "complaint",
    Label: "Complaint",
  },
  {
    dataType: "String",
    accessor: "branchCode",
    Label: "Branch Code",
  },
  {
    dataType: "String",
    accessor: "customerRelation",
    Label: "Customer Relation",
  },
  {
    dataType: "String",
    accessor: "name",
    Label: "Name",
  },
  {
    dataType: "String",
    accessor: "department",
    Label: "Department",
  },
  {
    dataType: "String",
    accessor: "purchaseDate",
    Label: "Purchase Date",
  },
  {
    dataType: "String",
    accessor: "amount",
    Label: "Amount",
  },
  {
    dataType: "Boolean",
    accessor: "isDnd",
    Label: "Dnd",
  },
  {
    dataType: "String",
    accessor: "gender",
    Label: "Gender",
  },
  {
    dataType: "String",
    accessor: "recordLastModifiedBy",
    Label: "Record Last Modified By",
  },
  {
    dataType: "LocalDateTime",
    accessor: "recordCreatedOn",
    Label: "Record Created On",
  },
  {
    dataType: "String",
    accessor: "recordCreatedBy",
    Label: "Record Created By",
  },
  {
    dataType: "String",
    accessor: "remarks",
    Label: "Remarks",
  },
  {
    dataType: "String",
    accessor: "accountNo",
    Label: "Account Number",
  },
  {
    dataType: "String",
    accessor: "secondAlternateNo",
    Label: "Second Alternate Nubmer",
  },
  {
    dataType: "String",
    accessor: "alternateNo",
    Label: "Alternate Number",
  },
  {
    dataType: "String",
    accessor: "disposition",
    Label: "Disposition",
  },
  {
    dataType: "String",
    accessor: "accountPoints",
    Label: "Account Points",
  },
  {
    dataType: "String",
    accessor: "customFldC01",
    Label: "Custom Field Character 01",
  },
  {
    dataType: "String",
    accessor: "customFldC02",
    Label: "Custom Field Character 02",
  },
  {
    dataType: "String",
    accessor: "customFldC03",
    Label: "Custom Field Character 03",
  },
  {
    dataType: "String",
    accessor: "customFldC04",
    Label: "Custom Field Character 04",
  },
  {
    dataType: "String",
    accessor: "customFldC05",
    Label: "Custom Field Character 05",
  },
  {
    dataType: "String",
    accessor: "customFldC06",
    Label: "Custom Field Character 06",
  },
  {
    dataType: "String",
    accessor: "customFldC07",
    Label: "Custom Field Character 07",
  },
  {
    dataType: "String",
    accessor: "customFldC08",
    Label: "Custom Field Character 08",
  },
  {
    dataType: "String",
    accessor: "customFldC09",
    Label: "Custom Field Character 09",
  },
  {
    dataType: "String",
    accessor: "customFldC10",
    Label: "Custom Field Character 10",
  },
  {
    dataType: "String",
    accessor: "customFldC11",
    Label: "Custom Field Character 11",
  },
  {
    dataType: "String",
    accessor: "customFldC12",
    Label: "Custom Field Character 12",
  },
  {
    dataType: "String",
    accessor: "customFldC13",
    Label: "Custom Field Character 13",
  },
  {
    dataType: "String",
    accessor: "customFldC14",
    Label: "Custom Field Character 14",
  },
  {
    dataType: "String",
    accessor: "customFldC15",
    Label: "Custom Field Character 15",
  },
  {
    dataType: "String",
    accessor: "customFldC16",
    Label: "Custom Field Character 16",
  },
  {
    dataType: "String",
    accessor: "customFldC17",
    Label: "Custom Field Character 17",
  },
  {
    dataType: "String",
    accessor: "customFldC18",
    Label: "Custom Field Character 18",
  },
  {
    dataType: "String",
    accessor: "customFldC19",
    Label: "Custom Field Character 19",
  },
  {
    dataType: "String",
    accessor: "customFldC20",
    Label: "Custom Field Character 20",
  },
  {
    dataType: "String",
    accessor: "customFldC21",
    Label: "Custom Field Character 21",
  },
  {
    dataType: "String",
    accessor: "customFldC22",
    Label: "Custom Field Character 22",
  },
  {
    dataType: "String",
    accessor: "customFldC23",
    Label: "Custom Field Character 23",
  },
  {
    dataType: "String",
    accessor: "customFldC24",
    Label: "Custom Field Character 24",
  },
  {
    dataType: "String",
    accessor: "customFldC25",
    Label: "Custom Field Character 25",
  },
  {
    dataType: "String",
    accessor: "customFldC26",
    Label: "Custom Field Character 26",
  },
  {
    dataType: "String",
    accessor: "customFldC27",
    Label: "Custom Field Character 27",
  },
  {
    dataType: "String",
    accessor: "customFldC28",
    Label: "Custom Field Character 28",
  },
  {
    dataType: "String",
    accessor: "customFldC29",
    Label: "Custom Field Character 29",
  },
  {
    dataType: "String",
    accessor: "customFldC30",
    Label: "Custom Field Character 30",
  },
  {
    dataType: "String",
    accessor: "customFldC31",
    Label: "Custom Field Character 31",
  },
  {
    dataType: "String",
    accessor: "customFldC32",
    Label: "Custom Field Character 32",
  },
  {
    dataType: "String",
    accessor: "customFldC33",
    Label: "Custom Field Character 33",
  },
  {
    dataType: "String",
    accessor: "customFldC34",
    Label: "Custom Field Character 34",
  },
  {
    dataType: "String",
    accessor: "customFldC35",
    Label: "Custom Field Character 35",
  },
  {
    dataType: "String",
    accessor: "customFldC36",
    Label: "Custom Field Character 36",
  },
  {
    dataType: "String",
    accessor: "customFldC37",
    Label: "Custom Field Character 37",
  },
  {
    dataType: "String",
    accessor: "customFldC38",
    Label: "Custom Field Character 38",
  },
  {
    dataType: "String",
    accessor: "customFldC39",
    Label: "Custom Field Character 39",
  },
  {
    dataType: "String",
    accessor: "customFldC40",
    Label: "Custom Field Character 40",
  },
  {
    dataType: "String",
    accessor: "customFldC41",
    Label: "Custom Field Character 41",
  },
  {
    dataType: "String",
    accessor: "customFldC42",
    Label: "Custom Field Character 42",
  },
  {
    dataType: "String",
    accessor: "customFldC43",
    Label: "Custom Field Character 43",
  },
  {
    dataType: "String",
    accessor: "customFldC44",
    Label: "Custom Field Character 44",
  },
  {
    dataType: "String",
    accessor: "customFldC45",
    Label: "Custom Field Character 45",
  },
  {
    dataType: "String",
    accessor: "customFldC46",
    Label: "Custom Field Character 46",
  },
  {
    dataType: "String",
    accessor: "customFldC47",
    Label: "Custom Field Character 47",
  },
  {
    dataType: "String",
    accessor: "customFldC48",
    Label: "Custom Field Character 48",
  },
  {
    dataType: "String",
    accessor: "customFldC49",
    Label: "Custom Field Character 49",
  },
  {
    dataType: "String",
    accessor: "customFldC50",
    Label: "Custom Field Character 50",
  },
  {
    dataType: "String",
    accessor: "customFldC51",
    Label: "Custom Field Character 51",
  },
  {
    dataType: "String",
    accessor: "customFldC52",
    Label: "Custom Field Character 52",
  },
  {
    dataType: "String",
    accessor: "customFldC53",
    Label: "Custom Field Character 53",
  },
  {
    dataType: "String",
    accessor: "customFldC54",
    Label: "Custom Field Character 54",
  },
  {
    dataType: "String",
    accessor: "customFldC55",
    Label: "Custom Field Character 55",
  },
  {
    dataType: "String",
    accessor: "customFldC56",
    Label: "Custom Field Character 56",
  },
  {
    dataType: "String",
    accessor: "customFldC57",
    Label: "Custom Field Character 57",
  },
  {
    dataType: "String",
    accessor: "customFldC58",
    Label: "Custom Field Character 58",
  },
  {
    dataType: "String",
    accessor: "customFldC59",
    Label: "Custom Field Character 59",
  },
  {
    dataType: "String",
    accessor: "customFldC60",
    Label: "Custom Field Character 60",
  },
  {
    dataType: "String",
    accessor: "fathHusbName",
    Label: "Fath Husb Name",
  },
  {
    dataType: "String",
    accessor: "lHouseNo",
    Label: "Local House Number",
  },
  {
    dataType: "String",
    accessor: "lstreet",
    Label: "Local Street",
  },
  {
    dataType: "String",
    accessor: "lLocality",
    Label: "Local Locality",
  },
  {
    dataType: "String",
    accessor: "lPin",
    Label: "Local Pin",
  },
  {
    dataType: "String",
    accessor: "pHouseNo",
    Label: "Personal House Number",
  },
  {
    dataType: "String",
    accessor: "pStreet",
    Label: "Personal Street",
  },
  {
    dataType: "String",
    accessor: "pLocality",
    Label: "Personal Locality",
  },
  {
    dataType: "String",
    accessor: "pCity",
    Label: "Personal City",
  },
  {
    dataType: "String",
    accessor: "pState",
    Label: "Personal State",
  },
  {
    dataType: "String",
    accessor: "pPin",
    Label: "Personal Pin",
  },
  {
    dataType: "String",
    accessor: "dataUploadSource",
    Label: "Data Upload Source",
  },
  {
    dataType: "String",
    accessor: "dataUploadedBy",
    Label: "Data Uploaded By",
  },
  {
    dataType: "String",
    accessor: "birthDate",
    Label: "Birth Date",
  },
  {
    dataType: "String",
    accessor: "assignedAgent",
    Label: "AssignedAgent",
  },
  {
    dataType: "String",
    accessor: "koId",
    Label: "Ko Id",
  },
  {
    dataType: "String",
    accessor: "promoCode",
    Label: "Promo Code",
  },
  {
    dataType: "String",
    accessor: "sessionUuid",
    Label: "Session Uuid",
  },
  {
    dataType: "String",
    accessor: "uuid",
    Label: "Uuid",
  },
  {
    dataType: "String",
    accessor: "valueType",
    Label: "Value Type",
  },
  {
    dataType: "LocalDateTime",
    accessor: "recordLastModifiedOn",
    Label: "Record Last Modified On",
  },
  {
    dataType: "LocalDateTime",
    accessor: "createdOn",
    Label: "Created On",
  },
  {
    dataType: "LocalDateTime",
    accessor: "lastModifiedOn",
    Label: "Last Modified On",
  },
  {
    dataType: "Long",
    accessor: "createdBy",
    Label: "Created By",
  },
  {
    dataType: "Long",
    accessor: "lastModifiedBy",
    Label: "Last Modified By",
  },
  {
    dataType: "Double",
    accessor: "customFldN01",
    Label: "Custom Field Number 01",
  },
  {
    dataType: "Double",
    accessor: "customFldN02",
    Label: "Custom Field Number 02",
  },
  {
    dataType: "Double",
    accessor: "customFldN03",
    Label: "Custom Field Number 03",
  },
  {
    dataType: "Double",
    accessor: "customFldN04",
    Label: "Custom Field Number 04",
  },
  {
    dataType: "Double",
    accessor: "customFldN05",
    Label: "Custom Field Number 05",
  },
  {
    dataType: "Double",
    accessor: "customFldN06",
    Label: "Custom Field Number 06",
  },
  {
    dataType: "Double",
    accessor: "customFldN07",
    Label: "Custom Field Number 07",
  },
  {
    dataType: "Double",
    accessor: "customFldN08",
    Label: "Custom Field Number 08",
  },
  {
    dataType: "Double",
    accessor: "customFldN09",
    Label: "Custom Field Number 09",
  },
  {
    dataType: "Double",
    accessor: "customFldN10",
    Label: "Custom Field Number 10",
  },
  {
    dataType: "Double",
    accessor: "customFldN11",
    Label: "Custom Field Number 11",
  },
  {
    dataType: "Double",
    accessor: "customFldN12",
    Label: "Custom Field Number 12",
  },
  {
    dataType: "Double",
    accessor: "customFldN13",
    Label: "Custom Field Number 13",
  },
  {
    dataType: "Double",
    accessor: "customFldN14",
    Label: "Custom Field Number 14",
  },
  {
    dataType: "Double",
    accessor: "customFldN15",
    Label: "Custom Field Number 15",
  },
  {
    dataType: "Double",
    accessor: "customFldN16",
    Label: "Custom Field Number 16",
  },
  {
    dataType: "Double",
    accessor: "customFldN17",
    Label: "Custom Field Number 17",
  },
  {
    dataType: "Double",
    accessor: "customFldN18",
    Label: "Custom Field Number 18",
  },
  {
    dataType: "Double",
    accessor: "customFldN19",
    Label: "Custom Field Number 19",
  },
  {
    dataType: "Double",
    accessor: "customFldN20",
    Label: "Custom Field Number 20",
  },
  {
    dataType: "Long",
    accessor: "memberId",
    Label: "Member Id",
  },
  {
    dataType: "Long",
    accessor: "referId",
    Label: "Refer Id",
  },
  {
    dataType: "Long",
    accessor: "_phoneNumber",
    Label: "Phone Number",
  },
  {
    dataType: "Long",
    accessor: "insertedReferenceId",
    Label: "Inserted Reference Id",
  },
  {
    dataType: "Long",
    accessor: "alternateContactNumber1",
    Label: "Alternate Contact Number 1",
  },
  {
    dataType: "Long",
    accessor: "alternateContactNumber2",
    Label: "Alternate Contact Number 2",
  },
  {
    dataType: "Date",
    accessor: "dob",
    Label: "Date Of Birth",
  },
  {
    dataType: "Date",
    accessor: "customFldD01",
    Label: "Custom Field Date 01",
  },
  {
    dataType: "Date",
    accessor: "customFldD02",
    Label: "Custom Field Date 02",
  },
  {
    dataType: "Date",
    accessor: "customFldD03",
    Label: "Custom Field Date 03",
  },
  {
    dataType: "Date",
    accessor: "customFldD04",
    Label: "Custom Field Date 04",
  },
  {
    dataType: "Date",
    accessor: "customFldD05",
    Label: "Custom Field Date 05",
  },
  {
    dataType: "Date",
    accessor: "customFldD06",
    Label: "Custom Field Date 06",
  },
  {
    dataType: "Date",
    accessor: "customFldD07",
    Label: "Custom Field Date 07",
  },
  {
    dataType: "Date",
    accessor: "customFldD08",
    Label: "Custom Field Date 08",
  },
  {
    dataType: "Date",
    accessor: "customFldD09",
    Label: "Custom Field Date 09",
  },
  {
    dataType: "Date",
    accessor: "customFldD10",
    Label: "Custom Field Date 10",
  },
  {
    dataType: "Date",
    accessor: "customFldD11",
    Label: "Custom Field Date 11",
  },
  {
    dataType: "Date",
    accessor: "customFldD12",
    Label: "Custom Field Date 12",
  },
  {
    dataType: "Date",
    accessor: "customFldD13",
    Label: "Custom Field Date 13",
  },
  {
    dataType: "Date",
    accessor: "customFldD14",
    Label: "Custom Field Date 14",
  },
  {
    dataType: "Date",
    accessor: "customFldD15",
    Label: "Custom Field Date 15",
  },
  {
    dataType: "Date",
    accessor: "customFldD16",
    Label: "Custom Field Date 16",
  },
  {
    dataType: "Date",
    accessor: "customFldD17",
    Label: "Custom Field Date 17",
  },
  {
    dataType: "Date",
    accessor: "customFldD18",
    Label: "Custom Field Date 18",
  },
  {
    dataType: "Date",
    accessor: "customFldD19",
    Label: "Custom Field Date 19",
  },
  {
    dataType: "Date",
    accessor: "customFldD20",
    Label: "Custom Field Date 20",
  },
  {
    dataType: "Boolean",
    accessor: "answer",
    Label: "Answer",
  },
  {
    dataType: "Boolean",
    accessor: "dispose",
    Label: "Dispose",
  },
  {
    dataType: "String",
    accessor: "customFldDT01",
    Label: "Custom Field Date Time 01",
  },
];

export default predefinedFields;
