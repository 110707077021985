import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "lg",
  enableAddButton: true,
  settingsMode: false,
  enableAdvanceSearch: true,

  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  create: "escalation/create",
  update: "escalation/update",
  list: "escalation/list",
  delete: "escalation/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Level 1 Hold Time (HH:MM)",
      accessor: "level1HoldTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "time",
      sortDisable: true,

      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Level 1 Email id",
      accessor: "level1MailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().emailOnly().nullable(),
      width: 6,

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Level 2 Hold Time (HH:MM)",
      accessor: "level2HoldTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "time",
      sortDisable: true,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Level 2 Email Id",
      accessor: "level2MailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().emailOnly().nullable(),
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "level 3 Hold Time (HH:MM)",
      accessor: "level3HoldTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      controlType: "time",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "level 3 Email Id",
      accessor: "level3MailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().emailOnly().nullable(),
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
