import { Box, Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material'
import React, { useContext } from 'react'
// import ThemeContext from '../../../context/Context'
import { useMaterialUIController } from 'context';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';

function SearchBar({ handleSearchText, handleCloseSearchDiologue, isShowSearchBox, setIsShowSearchBox }) {
    
    // const context = useContext(ThemeContext);
    // const { darkMode } = context
    const { darkMode } = useMaterialUIController()[0];


    const handleClose = () => {
        setIsShowSearchBox(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleClose();
        }
      };

    return (
        <Dialog
            // fullWidth={fullWidth}
            maxWidth='sm'
            open={isShowSearchBox}
            onClose={handleClose}
            onKeyDown={handleKeyDown} // Handle Enter key press

            
        >
            {/* <DialogTitle textAlign="center">Search Text</DialogTitle> */}
            <MDBox sx={{ pt:1,pb:1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', width: '25rem' ,backgroundColor: darkMode ? '#202940' : '#FFFFFF'  }}>
                <TextField size="large" variant="outlined"sx={{ width: '96.5%' }} onChange={handleSearchText} label="Search Text..." />
            </MDBox>
            {/* <DialogActions>
                <Button onClick={handleClose} variant='contained'>Search</Button>
                <Button variant='outlined' onClick={handleClose} >Close</Button>
            </DialogActions> */}
        </Dialog>
    )
}

export default SearchBar