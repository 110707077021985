import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import { useMaterialUIController } from "context";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";

function CSVTable({ openCSVTable, onCloseCSVTable, LeadUUID }) {
  const [darkMode] = useMaterialUIController();
  const [csvList, setcsvList] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await application.post(`leadMaster/list/${LeadUUID}`);
        setcsvList(response.data.data);
      } catch (error) {
        setNotify({
          isOpen: true,
          status:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      }
    };

    fetchData();
  }, [LeadUUID]);

 

  const renderTableHeaders = () => {
    if (!csvList || csvList.length === 0) return null;
    const firstRow = csvList[0];
    return Object.keys(firstRow).map((key) => (
      <TableCell key={key}>
        {key}
      </TableCell>
    ));
  };
  

  const renderTableRows = () => {
    return csvList && csvList.map((row, index) => (
      <TableRow key={index}>
        {Object.values(row).map((value, idx) => (
          <TableCell key={idx} >
            {value}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <>
      <Dialog
        open={openCSVTable}
        onClose={onCloseCSVTable}
        fullWidth
        maxWidth="lg"
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          <Typography variant="h6">Lead Upload</Typography>
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <table >
            <thead>
              <tr>{renderTableHeaders()}</tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </DialogContent>
      </Dialog>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CSVTable;
