import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";
import { useState } from "react";

const attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  settingsMode: false,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationurls = {
  list: "inboundGateway/list",
  update: "inboundGateway/update",
  create: "inboundGateway/create",
  delete: "inboundGateway/delete",
};

const getUpdatedColumns = (onDelete, onEdit) => {
  const [formField, setFormField] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const [required, setRequired] = useState("");

  const customSelect = (e) => {
    const customValue = e.target.value;
    if (customValue === "customType") {
      setFormField(true);
      setNewValue(true);
      setRequired("");
    } else if (customValue === "sip") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "pri") {
      setFormField(false);
      setNewValue(false);
    } else if (customValue === "internal") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "ngx1") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else if (customValue === "rlu") {
      setFormField(false);
      setNewValue(false);
      setRequired(formControl.string().required("Required"));
    } else {
      setFormField(false);
      setNewValue(false);
    }
  };
  return [
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
    {
      Header: "Gateway Code",
      accessor: "gwCode",
      displayInList: true,
      allowInForm: true,
      disabled: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Select Gateway Type",
      accessor: "gwType",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      onChange:customSelect,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "pri", description: "PRI" },
          { id: "internal", description: "INTERNAL" },
          { id: "ngx1", description: "NGX" },
          { id: "sip", description: "SIP" },
          { id: "customType", description: "Custom" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Custom Gateway Type",
      accessor: "gwTypeCustom",
      displayInList: false,
      allowInForm: formField,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Gateway Name",
      accessor: "gwName",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().nullable().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Param",
      accessor: "gwParam",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().nullable().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Cut Time (In Sec)",
      accessor: "cutTime",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().nullable().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Destination Strip",
      accessor: "numberStrip",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      defaultValue: "+++++++++++++++",
      validation: formControl.string().nullable().validatePlusSymbolsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Strip",
      accessor: "callerStrip",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      defaultValue: "+++++++++++++++",
      validation: formControl.string().nullable().validatePlusSymbolsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Outbound Gateway Group",
      accessor: "outboundGroupGw",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "gwGroup",
        keyDescription: "gwGroup",
        values: [],
        url: "gateway/list/gwGroup",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Channel",
      accessor: "maxChannel",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      defaultValue:"0",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "GW Outbound",
      accessor: "gwOutbound",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    
    {
      Header: "Tenant Code",
      accessor: "tenantCode",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "code",
        keyDescription: "code",
        values: [],
        url: "/tenantMaster/list",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DID Number",
      accessor: "did",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Inbound Cos",
      accessor: "inboundCos",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Outbound Cos",
      accessor: "outboundCos",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "cos",
        keyDescription: "cos",
        url: "outboundCos/list/cos",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Codec Provision",
      accessor: "codec",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType:"multipleSelectWithString",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "PCMU", description: "PCMU" },
          { id: "PCMA", description: "PCMA" },
          { id: "G722", description: "G722" },
          { id: "G723", description: "G723" },
          { id: "G726", description: "G726" },
          { id: "G729", description: "G729" },
          { id: "H323", description: "H323" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Custom Destination",
      accessor: "customDestination",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Ring Back Tone",
      accessor: "ringBackTone",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Gateway Controller Code",
      accessor: "gatewayControllerCode",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "gatewayCode",
        keyDescription: "gatewayCode",
        url: "gatewayController/listAll",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Caller Limit",
      accessor: "maxCallerDigit",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CLI Base Routing",
      accessor: "isCliBaseRouting",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Active",
      accessor: "isEnable",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },

    {
      Header: "Recording",
      accessor: "isRecording",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "QSIG",
      accessor: "qsig",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Peer To Peer",
      accessor: "peerToPeer",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Ring Ready",
      accessor: "ringReady",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "DNI Based Routing",
      accessor: "dniBasedRouting",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Handle ISDN Cause",
      accessor: "handleIsDnCause",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Anonymous Call Rejection",
      accessor: "anonymousCallRejection",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Missed Call Redial",
      accessor: "missCallRedial",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Name Replace with Caller",
      accessor: "nameReplaceWithCaller",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Restrict Feature Access",
      accessor: "restrictFeatureAccess",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
  ];
};

export { getUpdatedColumns, attributes, operationurls };
