import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  createTheme,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";

// Define the row background color
const rowBackgroundColor = "#212d41";
const activeRowBackgroundColor = "#49a3f1";

// Custom theme with dark mode and matching header and row colors
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#000000",
      default: rowBackgroundColor,
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          borderColor: "#7b809a",
          textAlign: "center",
        },
        head: {
          backgroundColor: rowBackgroundColor,
          color: "#FFFFFF",
          textAlign: "center",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:not(:last-child) td": {
            padding: "4px 8px",
          },
        },
      },
    },
  },
});

const tableContainerStyle = {
  height: "calc(100vh - 100px)",
  overflowY: "auto",
};

const CustomTable = ({ data, onCellClick, setRowValue, setUpdateList }) => {
  const [activeRow, setActiveRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (index, extension) => {
    setUpdateList(Date.now());
    setRowValue(extension);
    setActiveRow(index);
    onCellClick(extension);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter(
    (row) =>
      row.extension &&
      row.extension.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <TextField
          style={{ marginBottom: "3px", color: "#ffffffcc" }}
          variant="outlined"
          placeholder="Search Extension"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon style={{ color: "#1A73E8" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={tableContainerStyle}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Extension Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(index, row.extension)}
                  style={{
                    backgroundColor:
                      index === activeRow
                        ? activeRowBackgroundColor
                        : "inherit",
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{row.extension}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </ThemeProvider>
  );
};

CustomTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      extension: PropTypes.string,
    })
  ).isRequired,
  onCellClick: PropTypes.func,
  setRowValue: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

CustomTable.defaultProps = {
  onCellClick: () => {},
};

export default CustomTable;
