const userMenus = [
  {
    icon: "manage_accounts",
    key: "userManagement.roleMaster",
    name: "Role Master",
    url: "home/app/user/roleMaster",
    description: "Role Master",
    productionReady: false,
  },
  // {
  //   icon: "manage_accounts",
  //   key: "userManagement.UserRoleMaster",
  //   name: "User Role Master",
  //   url: "home/app/user/userRoleMaster",
  //   description: "User Role Master",
  //   productionReady: true,
  // },
  {
    icon: "management",
    key: "userMaster",
    name: "User Master",
    url: "home/app/userMaster",
    description: "User-Master",
    productionReady: false,
  },

  {
    icon: "group",
    key: "groupmaster",
    name: "Group Master",
    url: "home/app/groupmaster",
    description: "Group Master",
    productionReady: false,
  },
  {
    icon: "room",
    key: "roomMaster",
    name: "Room Master",
    url: "home/app/roomMaster",
    description: "Room Master",
    productionReady: false,
  },

  {
    icon: "key_icon",
    key: "userPassword",
    name: "Change Password",
    url: "home/app/roomMaster",
    description: "",
    productionReady: false,
  },

  // {
  //   icon: "groups2",
  //   key: "usergroupmaster",
  //   name: "User Group Master",
  //   url: "home/app/usergroupmaster",
  //   description: "User Group Master",
  //   productionReady: true,
  // },
];

export { userMenus };
