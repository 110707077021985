import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import KeyIcon from "@mui/icons-material/Key";
import MDButton from "components/MDButton";
import CTLNotification from "application/components/CTLNotification";
import {
  Autocomplete,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  ToggleButton,
  Box,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import { application } from "globals/endpoints";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CryptoJS from "crypto-js";

function UserPassword() {
  const pagename = "Change Password";
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selected, setSelected] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
  });

  const fetchUsers = async () => {
    try {
      const response = await application.post("userRole/listAll/users");
      if (response.data && response.data.status === "OK") {
        setUsers(response.data.data);
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      setNotify({
        pagename: pagename,
        isOpen: true,
        status: "Error fetching users. Please try again later.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const encryptData = (data, secretKey) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const ciphertext = CryptoJS.AES.encrypt(
      data,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return iv.concat(ciphertext.ciphertext).toString(CryptoJS.enc.Base64);
  };

  const passwordValidation = (password) => {
    const minLength = /.{8,}/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const upperCase = /[A-Z]/;
    const number = /\d/;

    return (
      minLength.test(password) &&
      specialChar.test(password) &&
      upperCase.test(password) &&
      number.test(password)
    );
  };

  const isNewPasswordValid =
    AuthenticationService.getPasswordComplexity() == "true"
      ? passwordValidation(newPassword)
      : true;
  const isPasswordsMatch = newPassword === confirmPassword;
  const isConfirmPasswordValid = isNewPasswordValid && isPasswordsMatch;

  const handleApplyClick = async () => {
    if (!selectedUser) {
      setNotify({
        pagename: pagename,
        isOpen: true,
        status: "Please select a user for change password.",
        type: "error",
      });
      return;
    }

      try {
        const requestBody = {
          username: encryptData(selectedUser.username, window.secretKey),
          defaultPassword: selected,
          ...(selected
            ? {}
            : {
                newPassword: encryptData(newPassword, window.secretKey),
                confirmPassword: encryptData(confirmPassword, window.secretKey),
              }),
        };
        const response = await application.post("user/updatePassword", requestBody);
        if (response.data && response.data.status === "OK") {
          setNotify({
            pagename: pagename,
            isOpen: true,
            status: "Password updated successfully!",
            type: "success",
          });
          setNewPassword("");
          setConfirmPassword("");
          setSelectedUser(null);
          setSelected(false);
        } else {
          throw new Error(response.data.message || "Password update failed.");
        }
      } catch (error) {
        setNotify({
          pagename: pagename,
          isOpen: true,
          status:
            error.message || "Failed to update password. Please try again.",
          type: "error",
        });
      }
  };

  return (
    <DashboardLayout>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Card sx={{ width: "40%" }}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="text"
                mx={2}
                mt={-3}
                py={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h6" fontWeight="medium">
                  Change Password
                </MDTypography>
              </MDBox>
              <Divider />
              <MDBox mb={4}>
                <Autocomplete
                  disablePortal
                  options={users}
                  getOptionLabel={(option) => option.username}
                  sx={{ marginBottom: "10px" }}
                  value={selectedUser}
                  onChange={(e, newValue) => setSelectedUser(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" />
                  )}
                />
              </MDBox>
              <ToggleButton
                value="check"
                color="success"
                fullWidth
                sx={{
                  fontWeight: "bold",
                  backgroundColor: selected ? "success.main" : "transparent",
                }}
                selected={selected}
                onChange={() => setSelected((prevSelected) => !prevSelected)}
              >
                <MDTypography
                  fontSize="medium"
                  color={selected ? "success" : "text"}
                >
                  <KeyIcon mt={1} color={selected ? "success" : "text"} />{" "}
                  &nbsp; Set Default Password
                </MDTypography>
              </ToggleButton>
              <Divider />
              {!selected && (
                <>
                  <MDBox mb={2}>
                    <MDInput
                      type={showNewPassword ? "text" : "password"}
                      label="New Password"
                      variant="standard"
                      fullWidth
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={() => setShowNewPassword(true)}
                              onMouseUp={() => setShowNewPassword(false)}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility color="success" />
                              ) : (
                                <VisibilityOff color="info" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!isNewPasswordValid && newPassword && (
                      <MDTypography variant="caption" color="error">
                        Password must be at least 8 characters, contain a
                        special character, a capital letter, and a number.
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm New Password"
                      variant="standard"
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={() => setShowConfirmPassword(true)}
                              onMouseUp={() => setShowConfirmPassword(false)}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility color="success" />
                              ) : (
                                <VisibilityOff color="info" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!isConfirmPasswordValid && confirmPassword && (
                      <MDTypography variant="caption" color="error">
                        Passwords do not match or are not valid.
                      </MDTypography>
                    )}
                  </MDBox>
                </>
              )}
              <MDBox mt={6} mb={1} display="flex" justifyContent="center">
                <MDButton
                  variant="gradient"
                  fullWidth
                  color="success"
                  onClick={handleApplyClick}
                  disabled={!(selected || (isPasswordsMatch && newPassword && confirmPassword))}
                >
                  Apply
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </DashboardLayout>
  );
}

export default UserPassword;
