import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { application } from "globals/endpoints";
import NotificationManager from "globals/websocket/WebSocket";

export const customerSupportApi = createApi({
  reducerPath: "customerSupportApi",
  baseQuery: async (args) => {
    try {
      if (typeof args == "string") {
        const result = await application(args);
        return result;
      } else {
        const { url, method, body } = args;
        const result = await application({ url, method, data: body });
        return result;
      }
    } catch (axiosError) {
      return { error: axiosError.response?.data || axiosError.message };
    }
  },
  tagTypes: ["messages"],
  endpoints: (builder) => ({
    getWhatsAppChatHistory: builder.query({
      query: (userPhoneNo) => `/whatsAppHistory/${userPhoneNo}`,
      transformResponse: (response) => response.data,
      providesTags: ["messages"],
    }),
    getIncomingUserMessage: builder.query({
      queryFn() {
        return { data: [] };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          setTimeout(() => {
            NotificationManager.releaseAllSubscriptions();
            NotificationManager.receiveMessageForService(
              `user-${AuthenticationService.getUserName()}`,
              getIncomingUserMessage,
              "service-events",
            );
          }, 3000);

          const getIncomingUserMessage = (message) => {
            console.log(message);
            // const outage = JSON.parse(message.body);
            // updateCachedData((draft) => {
            //   if (draft.length > 0) draft.pop();
            //   draft.push(outage);
            // });
          };
        } catch (error) {
          console.log(error);
        }
        await cacheEntryRemoved;
      },
    }),
    saveAgentMessage: builder.mutation({
      query: (payload) => ({
        url: "/sendMsg/byAgent",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["messages"],
    }),
    closeChat: builder.mutation({
      query: (agentId) => ({
        url: `/sendMsg/close/${agentId}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetWhatsAppChatHistoryQuery,
  useGetIncomingUserMessageQuery,
  useSaveAgentMessageMutation,
  useCloseChatMutation,
} = customerSupportApi;
