import { FormControlLabel, Switch } from "@mui/material";

function ShowArrowHeadsSwitch({ showArrowHeads, handleShowArrowHeads }) {
  return (
    <FormControlLabel
      control={
        <Switch checked={showArrowHeads} onChange={handleShowArrowHeads} />
      }
      label="Show arrows"
      sx={{
        position: "absolute",
        right: ".5em",
        top: "5em",
        zIndex: 2,
      }}
    />
  );
}

export default ShowArrowHeadsSwitch;
