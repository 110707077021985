import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";

import { taskManagementSystem } from "globals/configuration/taskManagementSystem";



const Attributes = {
formSize: "md",
enableAddButton: true,
settingsMode: false,
enableAdvanceSearch: true,

modes: taskManagementSystem.map((item) => {
return {
id: item.key,
name: item.name,
iconName: item.icon,
url: item.url,
description: item.description,
disabled: item.disabled,
productionReady: item.productionReady,
};
}),
};

const operationURLS = {
create: "taskStatus/create",
update: "taskStatus/update",
list: "taskStatus/list",
delete: "taskStatus/delete",
};

const getColumns = (onDelete, onEdit) => {
return [
{
Header: "taskStatus",
accessor: "taskStatus",
displayInList: true,
allowInForm: true,
allowFilter: true,
dataType: "string",
width: 12,
Cell: ({ value }) => <DefaultCell value={value} />,
},
{
Header: "createdBy",
accessor: "createdBy",
displayInList: false,
allowInForm: false,
allowFilter: false,
dataType: "string",
width: 6,
Cell: ({ value }) => <DefaultCell value={value} />,
},
{
Header: "createdOn",
accessor: "createdOn",
displayInList: true,
allowInForm: false,
allowFilter: false,
dataType: "string",
controlType: "dateTime",
width: 6,
Cell: ({ value }) => <DefaultCell value={value} />,
},
{
Header: "updatedBy",
accessor: "updatedBy",
displayInList: false,
allowInForm: false,
allowFilter: false,
dataType: "string",
width: 6,
Cell: ({ value }) => <DefaultCell value={value} />,
}, {
Header: "updatedOn",
accessor: "updatedOn",
displayInList: false,
allowInForm: false,
allowFilter: false,
dataType: "string",
controlType: "dateTime",
width: 6,
Cell: ({ value }) => <DefaultCell value={value} />,
},

{
Header: "Action",
accessor: "id",
displayInList: true,
allowInForm: false,
Cell: ({ row, value }) => (
<RowAction
row={row}
value={value}
onEdit={onEdit}
onDelete={onDelete}
/>
),
},
];
};

export { getColumns, Attributes, operationURLS };