import React, { useEffect, useState } from "react";
import { Checkbox, IconButton, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { cosAttributes } from "./cosAttributes";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";
import AlertSaveCancel from "../AlertsDialogs/AlertSaveCancel";

const TypeCOS = ({ type }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [typeList, setTypeList] = useState([]);
  const [prevTypeList, setPrevTypeList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState("");

  useEffect(() => {
    setTypeList(() => {
      return type === "inbound"
        ? cosAttributes.inbound
        : type === "outbound"
        ? cosAttributes?.outbound
        : [];
    });
  }, [cosAttributes]);

  const handleCheckBoxChange = (e) => {
    const { checked, name } = e.target;
    console.log("checked", e.target.checked);
    setTypeList((prevList) =>
      prevList?.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      )
    );
  };
  const handleTextFieldChange = (e) => {
    const { value, name } = e.target;
    console.log("name", name);
    setTypeList((prevList) =>
      prevList?.map((item) =>
        item.name === name ? { ...item, value: value } : item
      )
    );
  };

  const handleEditClick = () => {
    setPrevTypeList(typeList);
    setIsEditing(true);
  };

  const handleOpenSaveCancelDialog = (e, type) => {
    setIsSaving(type);
  };

  const handleConfirmSave = () => {
    if (isSaving === "Discard") {
      setTypeList(prevTypeList);
    }
    if (isSaving === "Save") {
      // call your api to save update the COS when use is going to click on the save Button with the type variable to identify which kind of COS is inbound or outbound
      enqueueSnackbar(`Successfully Saved Changes`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setIsEditing(false);
    setIsSaving("");
  };

  return (
    <div>
      {" "}
      <MDBox
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#fff",
            height: "25px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "5px",
            position: "absolute",
            top: "-3em",
          }}
        >
          {isEditing ? (
            <>
              <IconButton>
                <SaveIcon
                  onClick={(e) => handleOpenSaveCancelDialog(e, "Save")}
                  sx={{
                    fontSize: "1.4em",
                    color: darkMode ? "#ffffff" : "#000000",
                    padding: "1px",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.2)",
                      borderRadius: "0.35em",
                    },
                  }}
                />
              </IconButton>
              <IconButton>
                <CloseIcon
                  onClick={(e) => handleOpenSaveCancelDialog(e, "Discard")}
                  sx={{
                    fontSize: "1.4em",
                    color: darkMode ? "#ffffff" : "#000000",
                    padding: "1px",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.2)",
                      borderRadius: "0.35em",
                    },
                  }}
                />
              </IconButton>
            </>
          ) : (
            <IconButton>
              <EditIcon
                onClick={handleEditClick}
                sx={{
                  fontSize: "1.4em",
                  color: darkMode ? "#ffffff" : "#000000",
                  padding: "1px",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    borderRadius: "0.35em",
                  },
                }}
              />
            </IconButton>
          )}
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill,minmax(200px, 1fr))`,
          // gridTemplateColumns: {
          //   xs: "repeat(1, 1fr)", // 1 column on extra small screens
          //   sm: "repeat(2, 1fr)", // 2 columns on small screens
          //   md: "repeat(3, 1fr)", // 3 columns on medium screens
          //   lg: "repeat(4, 1fr)", // 4 columns on large screens
          // },
          gap: 2,
          padding: "0px 20px",
        }}
      >
        {typeList?.length > 0 &&
          typeList?.map((field, index) => (
            <MDBox
              key={index}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
                // padding: "10px",
                borderRadius: "8px",
                // backgroundColor: darkMode ? "#2c3e50" : "#f9f9f9",
                // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
              }}
            >
              {field.icon === "Checkbox" && (
                <Checkbox
                  name={field.name}
                  onChange={handleCheckBoxChange}
                  checked={field.value || false}
                  disabled={!isEditing}
                  // icon={<SvgIcon />}
                  // checkedIcon={<SvgIcon />}
                  sx={
                    !isEditing && {
                      "&.Mui-checked .MuiSvgIcon-root": {
                        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, #6d6d6d, #6d6d6d)`,
                        border: "none !important",
                      },
                    }
                  }
                />
              )}
              {field.icon === "TextField" && (
                <TextField
                  name={field.name}
                  variant="standard"
                  placeholder={field.name?.toUpperCase()}
                  onChange={(e) => handleTextFieldChange(e)}
                  value={field.value || ""}
                  disabled={!isEditing}
                  label={field.name}
                  sx={{
                    textAlign: "center",
                    width: "60%",
                    marginLeft: "0.5em",
                  }}
                  InputLabelProps={{
                    style: {
                      paddingTop: "8px",
                      color: darkMode
                        ? !isEditing
                          ? "#b0b0b0"
                          : "white"
                        : !isEditing
                        ? "#6d6d6d"
                        : "black",
                    },
                  }}
                />
              )}
              {field.icon === "Checkbox" && (
                <MDTypography
                  variant={"p"}
                  sx={{
                    fontSize: "0.7em",
                    color: darkMode
                      ? !isEditing
                        ? "#b0b0b0"
                        : "white"
                      : !isEditing
                      ? "#6d6d6d"
                      : "black",
                  }}
                >
                  {field?.name?.toUpperCase()}
                </MDTypography>
              )}
            </MDBox>
          ))}
      </MDBox>
      {isSaving && (
        <AlertSaveCancel
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          handleConfirmSave={handleConfirmSave}
          buttonText={isSaving}
          name={`${isSaving} Changes`}
        />
      )}
    </div>
  );
};

export default TypeCOS;
