import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";



const getUpdateColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "User Name",
      accessor: "userId",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      disabled: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Route",
      accessor: "routeId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      apiType: "telemetry",
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/route/listAll",
      },
    },
    {
      Header: "Shift",
      accessor: "shiftId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "shiftTime/listAll",
      },
    },
    {
      Header: "Route Name",
      accessor: "routeName",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Shift Name",
      accessor: "shiftName",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => (
        <RowAction
          value={value}
          row={row}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getUpdateColumns };
