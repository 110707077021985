import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";
import formControl from "globals/form-control";

const Attributes = {
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURL = {
  list: "userShift/list",
  create: "userShift/create",
  delete: "userShift/delete",
  update: "userShift/update",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "User Name",
      accessor: "userId",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "username",
        keyDescription: "username",
        values: [],
        url: "/user/dropdownList",
      },
    },
    {
      Header: "Route",
      accessor: "routeId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      // validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      apiType: "telemetry",
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/route/listAll",
      },
    },
    {
      Header: "Shift",
      accessor: "shiftId",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "shiftTime/listAll",
      },
    },
    {
      Header: "Route Name",
      accessor: "routeName",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      sortDisable: true,
      // validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Shift Name",
      accessor: "shiftName",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      sortDisable: true,
      validation: formControl.string().required("Required").nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value, row }) => (
        <RowAction
          value={value}
          row={row}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURL };
