import AuthenticationService from "application/modules/authentication/AuthenticationService";

const groupMenu = AuthenticationService.getGroupMenus();

const CallCenterMoreVert = [
  {
    icon: "connect_without_contact",
    key: "callcenter.configuration",
    name: "Call-Center-Configuration",
    url: "home/app/call-center-configuration",
    description: "Configure All Call Center Setting",
    productionReady: false,
  },
  {
    icon: "diversity_3_icon;",
    key: "callcenter.TeamLeadQueueAssosiation",
    name: "TeamLead Queue Assosiation",
    url: "home/app/call-center-TeamLeadQueueAssosiation",
    description: "Team Lead and Queue Assosiation",
    productionReady: false,
  },
  {
    icon: "list",
    key: "taxonomy",
    name: "Taxonomy",
    url: "home/app/taxonomy",
    description: "Taxonomy",
    productionReady: true,
  },
  {
    icon: "category_icon",
    key: "taxonomyCategory",
    name: "Taxonomy category",
    url: "home/app/taxonomyCategory",
    description: "Taxonomy category",
    productionReady: true,
  },
  {
    icon: "support_agent_icon",
    key: "AgentQueueAssociation",
    name: "Agent Queue Association",
    url: "home/app/AgentQueueAssociation",
    description: "Agent Queue Association",
    productionReady: true,
  },
  {
    icon: "list_alt_icon",
    key: "donotdiallist",
    name: "Do Not Dial List",
    url: "home/app/Do Not Dial List",
    description: "Do Not Dial List",
    productionReady: true,
  },
  {
    icon: "queue_icon",
    key: "CustomeQueue",
    name: "Queue Master",
    url: "home/app/Queue",
    description: "Queue",
    productionReady: true,
  },
  {
    icon: "dashboard_customize",
    key: "CustomeAgent",
    name: "Agent Master",
    url: "home/app/Agent",
    description: "Agent",
    productionReady: true,
  },
  {
    icon: "report",
    key: "CallCenterReport",
    name: "Call Center Report",
    url: "home/app/CallCenterReport",
    description: "CallCenterReport",
    productionReady: true,
  },
  {
    icon: "contact_phone_icon",
    key: "dispositionMaster",
    name: "Call Disposition Master",
    url: "home/app/CallDispositionMaster",
    description: "Call Disposition Master",
    productionReady: true,
  },
  {
    icon: "category_icon",
    key: "denyList",
    name: "Deny List",
    url: "home/app/DenyList",
    description: "Deny List",
    productionReady: true,
  },
  {
    icon: "diversity_2",
    key: "agentTLAssociation",
    name: "Agent TL Association",
    url: "home/app/call-center-agentTeamLeadAssociation",
    description: "Agent TeamLead Association",
    productionReady: true,
  },
];



const filteredCallCenterConfigMenuMoreVerts = CallCenterMoreVert.filter(
  (menu) =>
    groupMenu?.find(
      (groupMenu) =>
        groupMenu.name === "Call Center" &&
        groupMenu.menus.some(
          (item) =>
            item.menuName === menu.name &&
            (item.product === "more" || item.product === "both")
        )
    )
);

const callCenterConfigMenuMoreVert =
  filteredCallCenterConfigMenuMoreVerts?.length === 0 ||
  filteredCallCenterConfigMenuMoreVerts == null
    ? CallCenterMoreVert.map((menu) => ({
        icon: menu.icon,
        key: menu.key,
        name: menu.name,
        url: menu.url,
        description: menu.description,
        productionReady: menu.productionReady,
      }))
    : filteredCallCenterConfigMenuMoreVerts.map((menu) => ({
        icon: menu.icon,
        key: menu.key,
        name: menu.name,
        url: menu.url,
        description: menu.description,
        productionReady: menu.productionReady,
      }));

callCenterConfigMenuMoreVert.sort((a, b) => {
  const menu = groupMenu && groupMenu.find((menu) => menu.name === "Call Center");
  const sequenceA =
    menu?.menus.find((item) => item.menuName === a.name)?.sequence || 0;
  const sequenceB =
    menu?.menus.find((item) => item.menuName === b.name)?.sequence || 0;
  return sequenceA - sequenceB;
});

export { callCenterConfigMenuMoreVert };
