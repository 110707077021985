import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import CTLUnReadCell from "application/components/CTLUnReadCell";

const Attributes = {
  enableDownloadPdf: true,
  formSize: "md",
  columns: 3,
  settingsMode: false,
  modes: [],
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableExitButton: true,
};

const operationURLS = {
  list: "/leadSummaryView/leadDialingSummary/list",
  csvDownload: "/leadSummaryView/leadDialingSummary/downloadCSV",
  pdfDownload: "/leadSummaryView/generateAndDownloadPdf",
};

const getColumns = (onDelete) => {
  return [
    {
      Header: "Campaign",
      accessor: "campaign",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dialed No",
      accessor: "phoneNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dateTime: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Uploaded",
      accessor: "uploaded",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dialed",
      accessor: "dialed",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Redialed",
      accessor: "redialed",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Redialed On",
      accessor: "redialedOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Pending",
      accessor: "dialingPending",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Answered",
      accessor: "answered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Busy",
      accessor: "userBusy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Not Reachable",
      accessor: "notReachable",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Others",
      accessor: "others",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "hangupCause",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Called On",
      accessor: "coralDialId",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent",
      accessor: "ccAgent",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Duration (Sec)",
      accessor: "duration",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time (Sec)",
      accessor: "billSec",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Bridge Uuid",
      accessor: "bridgeUuid",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "Action",
    //   accessor: "",
    //   displayInList: true,
    //   allowInForm: false,
    //   Cell: ({ row, value }) => <ViewLeadSummary row={row} value={value} />,
    // },
  ];
};

export { getColumns, Attributes, operationURLS };
