import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Tooltip,
  IconButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import {
  useMaterialUIController,
  setConferenceData,
  setIsEmergencyCall,
} from "context";
import NotificationManager from "globals/websocket/WebSocket";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import notificationSound from "assets/sound/beep.wav";

export default function ActiveConferenceAlert({ light }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;
  const [menuItems, setMenuItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const audioRingingRef = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });



    const playRing = () => {
      if (!audioRingingRef.current) {
        audioRingingRef.current = new Audio(notificationSound);
        audioRingingRef.current.loop = false;
        const audioWithDelay =()=>{
          if(audioRingingRef.current){
          audioRingingRef.current.play();
          audioRingingRef.current.onended=()=>{
            setTimeout(()=>{
              audioWithDelay();
            }, 1000)
          };
        };
        };
        audioWithDelay();
      }
    };


      const stopRing = () => {
        if (audioRingingRef.current) {
          audioRingingRef.current.pause();
          audioRingingRef.current.currentTime = 0;
          audioRingingRef.current.onended = null;
          audioRingingRef.current= null;
        }
      };


  const conferenceUpdate = (message) => {
    if (message) {
      const serviceEvent = JSON.parse(message.body);
      if (serviceEvent?.eventData?.description != "disconnected") {
        if (!openDialog) {
          setOpenDialog(true);
          playRing();
        }
      }
    }
    application
      .post("conferenceRoom/list/runningMeeting")
      .then((response) => {
        setMenuItems(response.data.data);
      })
      .catch((error) => {
        console.error("Unable to fetch Alert list", error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (openDialog) {
        conferenceUpdate();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [openDialog]);


useEffect(() => {
  if (menuItems && menuItems.length > 0) {
    if (menuItems.length == 1 && menuItems[0].meetingStatus == 1) {
      setIsEmergencyCall(dispatch, true);
      setConferenceData(dispatch, menuItems[0]);
      setIsFirstRender(false);
    } else if (menuItems.length > 1 && isFirstRender) {
      setOpenDialog(true);
      setIsFirstRender(false);
    }
  }
}, [menuItems, isFirstRender, dispatch]);



  useEffect(() => {
    NotificationManager.receiveMessageForService(
      `user-${AuthenticationService.getUserName()}`,
      conferenceUpdate,
      "services-events"
    );
    conferenceUpdate();
  }, []);

  const handleIconClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    stopRing();
  };

  const handleJoinCall = (data) => {
    stopRing();
    const dataWithUUID = {
      ...data,
      uuid: uuidv4(),
    };
    loggerFactory.debug("click to join conference data", dataWithUUID);
    setIsEmergencyCall(dispatch, true);
    setConferenceData(dispatch, dataWithUUID);
    setOpenDialog(false);
  };


  return (
    <>
      <tooltip title="Alerts">
        <IconButton
          size="small"
          disableRipple
          color="inherit"
          onClick={handleIconClick}
        >
          <MDBadge
            color="error"
            badgeContent={menuItems?.length}
            max={999}
            size="xs"
            circular
          >
            <Icon sx={iconsStyle}>notifications</Icon>
          </MDBadge>
        </IconButton>
      </tooltip>

      <Dialog
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Emergency Alert
          <IconButton
            style={{
              marginLeft: "550px",
              position: "absolute",
            }}
            onClick={handleDialogClose}
          >
            <CloseIcon color="error" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            maxHeight: "300px",
            overflowY: "auto",
          })}
        >
          {Array.isArray(menuItems) && menuItems.length > 0 ? (
            menuItems.map((item, index) => (
              <MenuItem key={index}>
                <MDBox
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleJoinCall(item)}
                >
                  <Tooltip title="Click to join call">
                    <Card
                      style={{
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          item.meetingStatus == "0" ? "#3492eb" : "green",
                      }}
                    >
                      <VideocamRoundedIcon color="text" fontSize="medium" />
                    </Card>
                  </Tooltip>
                  <MDBox style={{ marginLeft: "30px" }}>
                    <MDTypography variant="h6" display="block">
                      {item.roomName} / {item.zone} / {item.deviceType} /{" "}
                      {item.type} / {item.createdOn}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MenuItem>
            ))
          ) : (
            <MDTypography variant="body2">No active alerts.</MDTypography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}