import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/viewChannels/list/billing",
  pdfDownload: "/viewChannels/generateAndDownloadPdf",
  csvDownload: "/viewChannels/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Caller Number",
      accessor: "callerIdNumber",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      dateTime: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Name",
      accessor: "callerIdName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "did Number",
      accessor: "didNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "dod Number",
      accessor: "dodNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "direction",
      accessor: "direction",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "destination Number",
      accessor: "destinationNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "start Time",
      accessor: "startStamp",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "answer Time",
      accessor: "answerStamp",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "end Time",
      accessor: "endStamp",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "TalkTime",
      accessor: "billSec",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total BillSec",
      accessor: "totalBillSec",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Total CallCost",
      accessor: "totalCallCost",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      filterControlType: "select",
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { keyField: "answered", description: "answered" },
          { keyField: "unanswered", description: "unanswered" },
        ],
      },
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Duration (HH:MM:SS)",
      accessor: "formattedDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "call Type",
      accessor: "callType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      filterControlType: "select",
      dataType: "string",
      controlType: "select",
      // options: {
      //   keyField: "callType",
      //   keyDescription: "callType",
      //   values: [],
      //   url: "/viewChannels/listAll",
      // },
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { keyField: "LOCAL", description: "LOCAL" },
          { keyField: "STD", description: "STD" },
          { keyField: "ISD", description: "ISD" },
        ],
      },
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "pulse",
      accessor: "pulse",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "call Cost",
      accessor: "callCost",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "billing Uuid",
      accessor: "billingUuid",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
