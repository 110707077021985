import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import optionsData from "./options.json";
import { useMaterialUIController } from "context";

const FeaturesDialog = ({
  currentFolder,
  openFeatureDialog,
  setOpenFeatureDialog,
  featureListSelected,
  setFeatureListSelected,
  handleAddFeatures,
}) => {
  const [featureList, setFeatureList] = useState(optionsData?.features || []);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const closeFeatureDialog = () => {
    setOpenFeatureDialog(null);
  };

  const dialogList = openFeatureDialog === "features" ? featureList : [];

  const changeFeature = (e) => {
    const { checked, name } = e.target;
    setFeatureListSelected((prevList) => {
      if (checked) {
        return { ...prevList, [name]: true };
      } else {
        const { [name]: _, ...rest } = prevList;
        return rest;
      }
    });
  };

  const tabStyles = useMemo(
    () => ({
      backgroundColor: darkMode ? "#202940" : "#f9f9f9",
      color: darkMode ? "#ecf0f1" : "#2c3e50",
      "& .Mui-selected": {
        color: darkMode ? "#3498db" : "#e74c3c",
        backgroundColor: darkMode ? "#484D5D" : "#C0C2C4",
      },
      "& .MuiTabs-root css-s6fz1z-MuiTabs-root": {
        bgColor: "red",
      },
    }),
    [darkMode]
  );

  const existingFeatures = currentFolder?.type === "features"
    ? currentFolder?.children || []
    : currentFolder?.children?.find((child) => child.type === "features")?.children || [];

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(openFeatureDialog)}
      onClose={closeFeatureDialog}
    >
      <DialogTitle
        textAlign="center"
        sx={{
          color: darkMode ? "#fff" : "#000",
          backgroundColor: darkMode ? "#202940" : "#FFFFFF",
        }}
      >
        Add {openFeatureDialog}
      </DialogTitle>
      <Box sx={{ width: "100%", ...tabStyles }}>
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)", // 1 column on extra small screens
              sm: "repeat(2, 1fr)", // 2 columns on small screens
              md: "repeat(3, 1fr)", // 3 columns on medium screens
              lg: "repeat(4, 1fr)", // 4 columns on large screens
            },
            gap: 2,
            padding: "10px 20px",
          }}
        >
          {dialogList?.length > 0 &&
            dialogList?.map((feature, index) => {
              const isExistingFeature = existingFeatures.some(
                (item) => item.name === feature.name
              );
              return (
                <MDBox
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 1,
                    borderRadius: "8px",
                  }}
                >
                  <Checkbox
                    name={feature.name}
                    onChange={changeFeature}
                    checked={
                      isExistingFeature
                        ? true
                        : featureListSelected[feature.name] || false
                    }
                    disabled={isExistingFeature}
                    sx={
                      isExistingFeature && {
                        "&.Mui-checked .MuiSvgIcon-root": {
                          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, #6d6d6d, #6d6d6d)`,
                          border: "none !important",
                        },
                      }
                    }
                  />
                  <MDTypography
                    variant={"body1"}
                    sx={{
                      fontSize: "0.7em",
                      color: darkMode
                        ? isExistingFeature
                          ? "#b0b0b0"
                          : "white"
                        : isExistingFeature
                        ? "#6d6d6d"
                        : "black",
                    }}
                  >
                    {feature?.name}
                  </MDTypography>
                </MDBox>
              );
            })}
        </MDBox>
      </Box>

      <DialogActions
        sx={{
          backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          textAlign: "center",
        }}
      >
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleAddFeatures}
        >
          Save
        </MDButton>

        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={closeFeatureDialog}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default FeaturesDialog;
