import * as React from "react";
import Box from "@mui/material/Box";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Filter from "./Filter";
import { useMemo } from "react";
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";

// Creating custom dark theme
createTheme(
  "modifiedDarkTheme",
  {
    striped: {
      default: colors.background.default,
    },
    selected: {
      default: colors.background.default,
    },
    background: {
      default: colors.background.card,
    },
  },
  "dark"
);

const NoDataMessage = () => (
  <MDTypography
    variant={"h4"}
    style={{
      margin: "10px",
      textAlign: "center",
      fontSize: "16px",
      color: "#888",
    }}
  >
    No records to display
  </MDTypography>
);

export default function COSExtensionTable({ assignedEntity, handleDelete }) {
  const darkModeContext = useMaterialUIController();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = assignedEntity.filter(
    (record) =>
      record.name &&
      record.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <IconButton
          onClick={() => handleDelete(row)}
          aria-label="delete"
          sx={{ color: "gray" }}
        >
          <DeleteIcon />
        </IconButton>
      ),
      width: "7em",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "id",
      selector: (row) => row.id,
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <MDBox
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#fff",
            height: "25px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "5px",
            position: "absolute",
            top: "-2.5em",
          }}
        >
          <Filter
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </MDBox>
      </MDBox>
      <DataTable
        data={filteredItems}
        columns={columns}
        responsive
        pagination
        paginationPerPage={[5]}
        striped
        theme={darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"}
        // selectableRows
        // selectableRowsVisibleOnly
        // selectableRowsHighlight
        defaultSortFieldId="id"
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
        fixedHeader
        fixedHeaderScrollHeight={"300px"}
        //  actions={dropdown}
        // subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        noDataComponent={<NoDataMessage />}
      />
    </Box>
  );
}
