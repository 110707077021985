import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import Calendar from "./Calendar";
import { application } from "globals/endpoints";
import CTLConferenceForm from "application/components/CTLConferenceForm";
import CTLNotification from "application/components/CTLNotification";
import { axiosInstance } from "application/components/CTLAxiosInstance";
import loggerFactory from "globals/logger/logger-factory";

const Attributes = {
  enableAdvanceSearch: false,
  modes: [],
};

const operationURLS = {
  settingList: "conferenceSettings/listAll",
  nodeList: "/configuration/list/nodes",
  update: "conferenceRoom/update",
  create: "conferenceRoom/create",
  delete: "conferenceRoom/delete",
  reSchedule: "conference/update/reSchedule",
  cancel: "conference/update/cancel",
  conferenceNumberList: "extensions/conferenceNumber",
  groupList: "userGroup/listAll",
  conferenceParticipant: "conferenceParticipant/list",
  participantDelete: "conferenceParticipant/delete",
  listOfMeetings: "conferenceRoom/listOfMeetings",
};

function CTLMeetingCalendar({ onLoad, registerCallBacks }) {
  const [controller] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [calendarKey, setCalendarKey] = useState(Date.now());
  const [data, setData] = useState([]);
  const [apiFormData, setApiFormData] = useState();
  const [schedule, setSchedule] = useState(false);
  const [conferenceMode, setConferenceMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLocalMeetings, setListOfLocalMeetings] = useState([]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (window.conferenceMode) {
        try {
          const conferenceData = await application.post(
            "conferenceRoom/listAll"
          );
          const activeMeeting = conferenceData?.data?.data.activeMeeting || [];
          const meetingList = conferenceData?.data?.data.meetingList || [];

          const serverUrl = window.location.hostname;
          const localServerData = [
            ...meetingList.map((item) => ({ ...item, serverName: serverUrl })),
            ...activeMeeting.map((item) => ({
              ...item,
              serverName: serverUrl,
            })),
          ];

          const serverData = await application.post(operationURLS.nodeList);
          const nodeList = serverData?.data?.data || [];

          const allServerData = await fetchConferenceRoomData(nodeList);
          const {
            meetingList: serverMeetingList,
            activeMeeting: serverActiveMeeting,
          } = processConferenceRoomData(allServerData);
          const combinedServerData = [
            ...serverMeetingList,
            ...serverActiveMeeting,
          ];

          const combinedData = combineAndDeduplicateData(
            localServerData,
            combinedServerData
          );
          setData(combinedData);
          setLoading(false);
        } catch (error) {
          loggerFactory.debug(
            "unable to load multiple server conference list data:",
            error
          );
        }
      } else {
        try {
          const conferenceData = await application.post(
            "conferenceRoom/listAll"
          );
          const activeMeeting = conferenceData?.data?.data.activeMeeting || [];
          const meetingList = conferenceData?.data?.data.meetingList || [];
          const serverUrl = window.location.hostname;
          const localServerData = [
            ...meetingList.map((item) => ({ ...item, serverName: serverUrl })),
            ...activeMeeting.map((item) => ({
              ...item,
              serverName: serverUrl,
            })),
          ];
          setData(localServerData);
        } catch (error) {
          loggerFactory.debug("unable to load conference  list data:", error);
          setData([]);
        }
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, [calendarKey, conferenceMode]);

  useEffect(() => {
    const fetchLocalData = async () => {
      if (window.conferenceMode) {
        try {
          const serverData = await application.post(operationURLS.nodeList);
          const nodeList = serverData?.data?.data || [];
          const allServerData = await fetchConferenceRoomLocalData(nodeList);
          const { meetingList: serverMeetingList } =
            processConferenceRoomLocalData(allServerData);
          const combinedServerData = [...serverMeetingList];
          setListOfLocalMeetings(combinedServerData);
        } catch (error) {
          loggerFactory.debug(
            "unable to load multiple server Local conference list data:"
          );
        }
      }
    };

    fetchLocalData();
    const intervalId = setInterval(fetchLocalData, 10000);
    return () => clearInterval(intervalId);
  }, [calendarKey, conferenceMode]);

  const urlScheme = window.location.protocol;
  const isDomain = window.location.hostname.toLowerCase().match(/[a-z]/i);
  const domainParts = window.location.hostname.split(".");
  domainParts.shift();

  const fetchConferenceRoomData = async (serverList) => {
    const conferenceRoomPromises = serverList.map(async (server) => {
      try {
        const currentDomain = server.nodeName + "." + domainParts.join(".");
        const currentIp = `${server.ipAddress}`;
        const targetUrl = `${urlScheme}//${
          isDomain ? currentDomain : currentIp
        }/services/api/v2/conferenceRoom/listAll`;

        const response = await axiosInstance.post(targetUrl);
        const data = response.data.data;

        const serverUrl = isDomain ? currentDomain : currentIp;
        if (data) {
          data.activeMeeting = data.activeMeeting.map((item) => ({
            ...item,
            serverName: serverUrl,
          }));
          data.meetingList = data.meetingList.map((item) => ({
            ...item,
            serverName: serverUrl,
          }));
        }
        return data;
      } catch (error) {
        loggerFactory.debug(
          `Error fetching conference list data for server name: ${server.nodeName}, server IP: ${server.ipAddress}`,
          error
        );
        return { meetingList: [], activeMeeting: [] };
      }
    });
    return await Promise.all(conferenceRoomPromises);
  };

  const processConferenceRoomData = (conferenceRoomData) => {
    let allMeetingList = [];
    let allActiveMeeting = [];
    conferenceRoomData.forEach((serverData) => {
      if (serverData) {
        const activeMeetings = serverData.activeMeeting || [];
        const meetingList = serverData.meetingList || [];
        allMeetingList = [...allMeetingList, ...meetingList];
        allActiveMeeting = [...allActiveMeeting, ...activeMeetings];
      }
    });
    return {
      meetingList: allMeetingList,
      activeMeeting: allActiveMeeting,
    };
  };

  const combineAndDeduplicateData = (localData, serverData) => {
    const combinedData = [...localData];
    const seenIds = new Set(
      localData.map(
        (item) => `${item.modId}_${item.roomName}_${item.accessCode}`
      )
    );

    serverData.forEach((item) => {
      const itemKey = `${item.modId}_${item.roomName}_${item.accessCode}`;
      if (!seenIds.has(itemKey)) {
        seenIds.add(itemKey);
        combinedData.push(item);
      }
    });

    return combinedData;
  };

  const fetchConferenceRoomLocalData = async (serverList) => {
    const conferenceRoomPromises = serverList.map(async (server) => {
      try {
        const currentDomain = server.nodeName + "." + domainParts.join(".");
        const currentIp = `${server.ipAddress}`;
        const targetUrlLocal = `${urlScheme}//${
          isDomain ? currentDomain : currentIp
        }/services/api/v2/conferenceRoom/listOfMeetings`;
        const responseLocal = await axiosInstance.post(targetUrlLocal);
        const dataLocal = responseLocal.data.data;
        return dataLocal;
      } catch (error) {
        loggerFactory.debug("Unable to get local conference list");
        return { meetingList: [], activeMeeting: [] };
      }
    });
    return await Promise.all(conferenceRoomPromises);
  };

  const processConferenceRoomLocalData = (conferenceRoomData) => {
    let allMeetingList = [];
    conferenceRoomData.forEach((serverData) => {
      if (serverData) {
        const meetingList = serverData.meetingList || [];
        allMeetingList = [...allMeetingList, ...meetingList];
      }
    });
    return {
      meetingList: allMeetingList,
    };
  };

  const createConference = (formData) => {
    setOpenPopup(false);
    if (formData.id) {
      application
        .post(operationURLS.update, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: "Conference",
            status: error.response.data.message,
          });
        });
    } else if (formData.modId) {
      application
        .post(operationURLS.reSchedule, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: "Conference",
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: "Conference",
            status: error.response.data.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: "Conference",
            status: error.response.data.messageDetail,
          });
        });
    }
    setCalendarKey(Date.now());
  };

  const currentDate = new Date();
  const calendarEventsData = data
    ? data
        .filter((item) => {
          if (
            window.conferenceMode === "undefined" ||
            window.conferenceMode == false
          ) {
            return true;
          } else {
            return conferenceMode
              ? item.conferenceMode === "C"
              : item.conferenceMode === "I";
          }
        })
        .map((item) => {
          const currentDateFormatted = currentDate.toISOString().slice(0, 10);
          const eventDateFormatted = new Date(item.scheduledToDate)
            .toISOString()
            .slice(0, 10);

          const currentTime = currentDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          const eventTime = item.toTime;

          let className = "";

          if (
            eventDateFormatted === currentDateFormatted &&
            eventTime >= currentTime
          ) {
            className = "success";
          } else if (
            eventDateFormatted > currentDateFormatted ||
            (eventDateFormatted === currentDateFormatted &&
              eventTime >= currentTime)
          ) {
            const oneWeekLater = new Date();
            oneWeekLater.setDate(oneWeekLater.getDate() + 7);
            if (new Date(eventDateFormatted) <= oneWeekLater) {
              className = "warning";
            } else {
              className = "info";
            }
          } else if (
            eventDateFormatted < currentDateFormatted ||
            (eventDateFormatted === currentDateFormatted &&
              eventTime < currentTime)
          ) {
            className = "error";
          }
          return {
            id: JSON.stringify(item),
            title: item.roomName,
            start: `${item.meetingScheduledDate}T${item.fromTime}`,
            end: `${item.scheduledToDate}T${item.toTime}`,
            className: className,
          };
        })
    : [];

  const localModeCount =
    data && data.filter((item) => item.conferenceMode === "I").length;
  const publicModeCount =
    data && data.filter((item) => item.conferenceMode === "C").length;

  function filterConferencesGroupList() {
    const filteredMeetings =
      listOfLocalMeetings &&
      listOfLocalMeetings.filter((meeting) => meeting.conferenceMode === "I");
    const result = filteredMeetings.map((meeting) => {
      return {
        groupName: meeting.roomName,
        userList: meeting.participantList,
      };
    });
    return result || [];
  }

  return (
    <MDBox>
      <CTLConferenceForm
        open={open}
        setOpen={setOpen}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        operationURLS={operationURLS}
        createConference={createConference}
        apiFormData={apiFormData}
        setApiFormData={setApiFormData}
        schedule={schedule}
        setSchedule={setSchedule}
        filterConferencesGroupList={filterConferencesGroupList}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ height: "max-content" }}>
          <MDBox sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            {data && calendarKey && (
              <Calendar
                setOpen={setOpen}
                data={data}
                localModeCount={localModeCount}
                publicModeCount={publicModeCount}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                calendarKey={calendarKey}
                setConferenceMode={setConferenceMode}
                setLoading={setLoading}
                loading={loading}
                setCalendarKey={setCalendarKey}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                events={calendarEventsData.flat()}
                setApiFormData={setApiFormData}
                setSchedule={setSchedule}
                operationURLS={operationURLS}
                selectable
                editable
              />
            )}
          </MDBox>
        </Grid>
      </Grid>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default CTLMeetingCalendar;
