import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";
import { application } from "globals/endpoints";
import { telemetry } from "globals/endpoints";

function CTLSelect({
  label,
  name,
  value,
  onChange,
  error,
  column,
  success,
  disabled,
  url,
  options,
  keyField,
  keyDescription,
  accessorKeyField,
  accessorValueField,
}) {
  const [listOptions, setListOptions] = useState(options);

  useEffect(() => {
    if (url) {
      if (column?.apiType === "telemetry") {
        telemetry.post(url).then((response) => {
          setListOptions(response.data.data);
        });
      } else {
        application.post(url).then((response) => {
          setListOptions(response.data.data);
        });
      }
    }
  }, [column?.dynamicSelectData]);

  return (
    <>
      <MDTypography variant="caption" >
        {label}
      </MDTypography>
      <Autocomplete
        fullWidth
        options={listOptions}
        getOptionLabel={(option) => option[keyDescription] || ""}
        value={listOptions.find((opt) => opt[keyField] === value) || ""}
        onChange={(event, newValue) => {
          onChange({
            target: {
              name,
              value: newValue ? newValue[keyField] : "",
            },
          });
        }}
        clearOnEscape
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select"
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              style: {
                backgroundColor: disabled ? "lightgrey" : "inherit",
              },
            }}
          />
        )}
        sx={{
          marginBottom: "4px",
          "& .MuiAutocomplete-listbox": {
            maxHeight: 200,
          },
        }}
      />
    </>
  );
}

export default CTLSelect;
