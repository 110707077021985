import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/billingCDR/list",
  pdfDownload: "/billingCDR/generateAndDownloadPdf",
  csvDownload: "/billingCDR/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Start Time",
      accessor: "startEpoch",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Call Direction",
      accessor: "coralCallDirection",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Caller No",
      accessor: "callerIdNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Destination No",
      accessor: "destinationNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Call Answered",
      accessor: "callConnected",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      controlType:"boolean",
      dataType: "boolean",
      filterControlType:"boolean",
      StringValue:true,
      sortDisable:true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Date",
      accessor: "startDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerEpoch",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dateTime: false,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endEpoch",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "date",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Call Duration (HH:MM:SS)",
      accessor: "duration",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "MOS",
      accessor: "mos",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
