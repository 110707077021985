import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import DropZone from "./DropZone";
import AlertSaveCancel from "../AlertsDialogs/AlertSaveCancel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TypeCOS from "./TypeCOS";

const ClassOfService = ({ setCurrentFolder, currentFolder, folders }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // instead of this ,  hard code value of 0  will be change later
    if (
      currentFolder?.type != "services" &&
      currentFolder?.children[0]?.type === "services"
    ) {
      setCurrentFolder(currentFolder.children[0]);
    }
  }, []);

  const handleDrop = (data) => {
    console.log("Dropped item:", data);
  };

  return (
    <MDBox sx={{ width: "auto" }}>
      <DropZone
        onDrop={handleDrop}
        folders={folders}
        expanded={expanded}
        handleChange={handleChange}
        currentFolder={currentFolder}
      />
      <Accordion
        sx={{ bgcolor: "#202940", marginBottom: "10px" }}
        expanded={expanded === "inbound"}
        onChange={handleChange("inbound")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            color: "#fff",
            flexDirection: "row-reverse",
            gap: 1,
            fontSize: "16px",
          }}
        >
          Inbound Class of Services
        </AccordionSummary>
        <AccordionDetails>
          <TypeCOS type={"inbound"} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ bgcolor: "#202940", marginBottom: "10px" }}
        expanded={expanded === "outbound"}
        onChange={handleChange("outbound")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            color: "#fff",
            flexDirection: "row-reverse",
            gap: 1,
            fontSize: "16px",
          }}
        >
          Outbound Class of Services
        </AccordionSummary>
        <AccordionDetails>
          <TypeCOS type={"outbound"} />
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
};

export default ClassOfService;
