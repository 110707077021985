import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import MDTypography from "components/MDTypography";

import CTLDialogContent from "../styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import variables from "globals/variables";
import CTLNotification from "application/components/CTLNotification";

export default function PlayDistorsion({
  openDistorsion,
  setOpenDistorsion,
  HandleCloseDistorsion,
  entityName,
  conferenceData,
  setActiveStopDistortion,
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    setOpen(openDistorsion);
    getList();
  }, [openDistorsion]);

  const handleClose = () => {
    setOpen(false);
    HandleCloseDistorsion();
    setSelect("");
  };

  const [select, setSelect] = useState("");
  const [fileData, setFileData] = useState([]);

  const URL = {
    list: "/distortionFile/listAll",
    play: "distortionFile/playDistortion/loop",
  };

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const getList = () => {
    application
      .post(URL.list)
      .then((res) => {
        let response = res.data.data;
        setFileData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  function PlayDistorsion() {
    let distorsionData = {
      accessCode: conferenceData?.accessCode,
      path: select,
    };
    application
      .post(URL.play, distorsionData)
      .then((res) => {
        setOpen(false);
        HandleCloseDistorsion();
        setSelect("");
        setActiveStopDistortion(true);
        setNotify({
          isOpen: true,
          status: "Announcement File Play Successfully",
          type: "success",
          pagename: "Confrence",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error,
          type: "error",
          pagename: "Confrence",
        });
      });
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
          })}
        >
          {/* {entityName} */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Select Announcement File
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={select}
              label="Select Announcement File"
              onChange={handleChange}
              style={{ height: "55px" }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {fileData.map((item) => (
                <MenuItem key={item.id} value={item.path}>
                  {item.audioName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogTitle>

        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
        ></DialogContent>

        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClose} color="secondary">
            Cancel
          </MDButton>

          <MDButton
            color="success"
            onClick={() => PlayDistorsion(Select)}
            endIcon={<PlayCircleIcon />}
            disabled={!select}
          >
            Play
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}
