import CallBack from "application/modules/uc/call-back";
import CallHistory from "application/modules/uc/call-history";
import Extension from "application/modules/pbx/extension";
import Files from "application/modules/uc/files";
import MissedCall from "application/modules/uc/missed-call";
import Contacts from "application/modules/uc/contacts";
import Voicemail from "application/modules/uc/voicemail";
import ChatMessage from "application/modules/uc/ChatMessage";
import Alerts from "application/modules/nms/alerts";
import Recording from "application/modules/video/recording";
import PhoneBook from "application/modules/uc/phonebook";
import Dashboard from "layouts/nms/dashboard";
import Cluster from "application/modules/configuration/cluster";
import FaxDetails from "application/modules/uc/fax-details";
import Settings from "layouts/pages/account/settings";
import BroadcastsMessage from "application/modules/uc/broadcasts/message";
import ExternalAPI_Call from "application/modules/External-API-Call-log";
import Timer from "application/modules/configuration/timer";
import ConferencePortal from "application/modules/uc/conferencePortal";
import ConferenceSetting from "application/modules/uc/conferenceSetting";
import IVR from "application/modules/configuration/ivr";
import Map from "application/components/Map";
import PbxConfiguration from "application/modules/configuration/pbx_configuration";
import Hotel from "application/modules/configuration/hotel";
import CallCenter from "application/modules/callCenter";
import ivrList from "globals/configuration/ivr";
import Comparison from "application/modules/configuration/comparison";
import PriConfiguration from "application/modules/configuration/pri_configuration";
import CTLPassword from "application/modules/authentication/sign-in/CTLPassword";
import SipTrunk from "application/modules/configuration/sipTrunk";
import ChangePassword from "application/modules/authentication/reset-password/cover";
import Category from "application/modules/configuration/category";
import CTLLocation from "application/modules/configuration/location";
import ServiceMaster from "application/modules/configuration/service_Master";
import CategoryService from "application/modules/configuration/category_service";
import Wans from "application/modules/nmsConfiguration/wans";
import Fax from "application/modules/uc/fax";
import Routes from "application/modules/nmsConfiguration/routes";
import CTLMenuDashboard from "application/components/CTLMenuDashboard";
import HostNameConfiguration from "application/modules/configuration/hostName";
import userLocation from "application/modules/nmsConfiguration/userLocation";
import nmsMenuDashboard from "application/components/nmsMenuDashboard";
import SubnetMaster from "application/modules/configuration/subnet_Master";
import dashboardController from "application/components/DashboardController";
import BillingCDR from "application/modules/configuration/billingCDR";
import NmsAlertHistory from "application/modules/nmsConfiguration/nmsAlertHistory";
import MyConference from "application/modules/uc/my-conference";
import ParticipantAdd from "application/modules/uc/ParticipantAdd";
import TelephonyDeployment from "application/modules/nmsConfiguration/telephonyDeployment";
import NodeConfiguration from "application/modules/nmsConfiguration/callServerProgramming";
import VoicePicker from "application/modules/configuration/voicePicker";
import MonitorService from "application/modules/nmsConfiguration/monitorService";
import SeverityMaster from "application/modules/nmsConfiguration/severity_Master";
import WidgetMenuGroup from "application/modules/nmsConfiguration/widgetMenuGroup";
import ConferenceMailText from "application/modules/nmsConfiguration/emailBodyConfiguration";
import SystemEmailConfiguration from "application/modules/nmsConfiguration/systemEmailConfiguration";
import NmsDashboard from "application/modules/nmsConfiguration/systemDashboard";
import UserZoneConfiguration from "application/modules/configuration/userZoneConfiguration";
import UserZone from "application/components/CTLUserZone";
import SmsConfiguration from "application/modules/nmsConfiguration/systemSmsConfiguration";
import LineChart from "application/modules/nmsConfiguration/lineChart";
import BarChart from "application/modules/nmsConfiguration/barChart";
import Topology from "application/modules/nmsConfiguration/topology";
import DashBoard from "application/modules/nmsConfiguration/Dashboard";
import IFrame from "application/modules/nmsConfiguration/iFrame";
import Calendar from "application/modules/nmsConfiguration/calendar";
import GraphTopologyNoLeaf from "application/modules/nmsConfiguration/graphTopologyNoLeaf";
import TopologyNoLeaf from "application/modules/nmsConfiguration/topologyNoLeaf";
import VideoStream from "application/modules/nmsConfiguration/videoStream";
import GraphTopology from "application/modules/nmsConfiguration/graphTopology";
import SmartTopology from "application/modules/nmsConfiguration/smartTopology";
import SpeedoMeter from "application/modules/nmsConfiguration/speedoMeter";
import Table from "application/modules/nmsConfiguration/table";
import PieChart from "application/modules/nmsConfiguration/pieChart";
import DoughnutChart from "application/modules/nmsConfiguration/doughnutChart";
import ServerPaginatedTable from "application/modules/nmsConfiguration/serverSidePaginated";
import CMSReport from "application/modules/configuration/Reports/cms_Reports";
import Asset from "application/modules/configuration/scada_Asset";
import LocationType from "application/modules/nmsConfiguration/locationType";
import CTLAgentDashboard from "application/components/CTLAgentDashboard";
import UserStatus from "application/modules/configuration/userStatus";
import CallDisposition from "application/modules/configuration/Reports/callDisposition";
import CampaignSummary from "application/modules/configuration/Reports/campaignSummary";
import UserMaster from "application/modules/userManagement/userMaster";
import RoleMaster from "application/modules/userManagement/roleMaster";
import UserRoleMaster from "application/modules/userManagement/userRoleMaster";
import GroupMaster from "application/modules/userManagement/groupMaster";
import UserGroupMaster from "application/modules/userManagement/userGroupMaster";
import RoomMaster from "application/modules/userManagement/roomMaster";
import cdrs from "application/modules/cdrs";
import cdrsGroup from "application/modules/cdrs/cdrsGroup";
import SecurityConfiguration from "application/modules/configuration/Security Configuration";
import UserDashboard from "application/modules/nmsConfiguration/UserDashboard";
import snmpRules from "application/modules/snmpRules";
import WidgetConfiguration from "application/modules/nmsConfiguration/widget Configuration";
import AgentDailyPerformance from "application/modules/configuration/Reports/AgentDailyPerformance";
import AgentDailyReport from "application/modules/configuration/Reports/agentDailyReport";
import UserLoginLogout from "application/modules/configuration/Reports/UserLoginLogout";
import GroupAbandonedCall from "application/modules/configuration/Reports/AgentGroupAbandonedCall";
import CDRReport from "application/modules/configuration/Reports/customerCDR";
import CTLMeetingCalendar from "application/modules/uc/conferenceCalendar";
import ConferenceParticipant from "application/modules/uc/conferenceParticipant";
import OperatorSummery from "application/modules/configuration/Reports/operatorSummaryReport";
import Campaign from "application/modules/configuration/callCenterConfiguration/component/campaign";
import CallCenterConfigurations from "application/modules/configuration/callCenterConfiguration";
import LeadUpload from "application/modules/configuration/callCenterConfiguration/component/leadUpload";
import TeamLeadQueueAssosiation from "application/modules/configuration/callCenterConfiguration/component/teamLeadQueueAssosiation";
import InboundReport from "application/modules/configuration/Reports/inboundReport";
import OutboundReport from "application/modules/configuration/Reports/outboundReport";
import MissedCallReport from "application/modules/configuration/Reports/missedCallReport";
import DayTimeSetting from "application/modules/configuration/callCenterConfiguration/component/day&timeSetting";
import ChangeUserProfile from "application/modules/configuration/changeAgentProfile";
import taxonomyCategory from "application/modules/configuration/texanomyCategory";
import taxonomy from "application/modules/configuration/texanomy";
import JSONFileCreation from "application/modules/configuration/ JSONfileCreation";
import AgentQueueAssociation from "application/modules/configuration/callCenterConfiguration/component/AgentQueueAssociation";
import CustomeQueue from "application/modules/configuration/callCenterConfiguration/component/customeQueue";
import CustomeAgent from "application/modules/configuration/callCenterConfiguration/component/customeAgent";
import CallCenterReport from "application/modules/configuration/callCenterReport";
import PAReport from "application/modules/configuration/Reports/PA Report";
import CallDispositionMaster from "application/modules/configuration/callCenterConfiguration/callDispositionMaster";
import DenyList from "application/modules/configuration/callCenterConfiguration/denyList";
import DoNotDialList from "application/modules/configuration/callCenterConfiguration/component/DoNotDialList";
import deviceConfiguration from "application/modules/configuration/deviceConfiguration";
import ENM from "application/modules/extensionConfiguration/enm";
import InboundCos from "application/modules/extensionConfiguration/inboundCos";
import OutboundCos from "application/modules/extensionConfiguration/outboundCos";
import NGX from "application/modules/extensionConfiguration/ngx";
import GatewayController from "application/modules/extensionConfiguration/gatewayController";
import Tenant from "application/modules/extensionConfiguration/tenant";
import OutboundGateway from "application/modules/extensionConfiguration/OutboundGateway";
import ControlRLUServer from "application/modules/extensionConfiguration/controlRLUServer";
import Model from "application/modules/extensionConfiguration/Model";
import ManuFacturer from "application/modules/extensionConfiguration/manuFacturer";
import PhoneStatus from "application/modules/extensionConfiguration/phoneStatus";
import MemberGroup from "application/modules/extensionConfiguration/memberGroup";
import UCAssignedUser from "application/modules/extensionConfiguration/UCAssignedUser";
import Company from "application/modules/extensionConfiguration/Company";
import Department from "application/modules/extensionConfiguration/Department";
import Designation from "application/modules/extensionConfiguration/Designation";
import CMS from "application/modules/extensionConfiguration/cms";
import CMSOther from "application/modules/extensionConfiguration/cmsOther";
import Dispatcher from "application/modules/extensionConfiguration/Dispatcher";
import CBN from "application/modules/extensionConfiguration/cbn";
import CBNOther from "application/modules/extensionConfiguration/cbnOther";
import DialPlan from "application/modules/extensionConfiguration/dialPlan";
import EmergencyPhone from "application/modules/extensionConfiguration/emergencyPhone";
import PhoneVOIP from "application/modules/extensionConfiguration/phone+VOIP";
import VoicePromptFeature from "application/modules/extensionConfiguration/VoicePromptFeature";
import PhoneVOLTE from "application/modules/extensionConfiguration/phone+VOLTE";
import phoneVONR from "application/modules/extensionConfiguration/phoneVONR";
import phoneMCXVideo from "application/modules/extensionConfiguration/phoneMCXVideo";
import SoftFax from "application/modules/extensionConfiguration/softFax";
import ACDGroup from "application/modules/extensionConfiguration/acdGroup";
import ExtensionFax from "application/modules/extensionConfiguration/fax";
import RemoteuserSIP from "application/modules/extensionConfiguration/remoteuserSIP";
import MeetMeConference from "application/modules/extensionConfiguration/meetMeConference";
import DialAssist from "application/modules/extensionConfiguration/dailAssist";
import ConfrenceDistorsion from "application/modules/uc/confrenceDistorsion";
import AgentCDRReport from "application/modules/configuration/Reports/agentCDRReport";
import ConsolidatedExtension from "application/modules/configuration/Reports/ConsolidatedISDSTD";
import Debriefing from "application/modules/debriefing";
import BackupRestore from "application/modules/backupRestore";
import BackupRecordingFile from "application/modules/ backup&RestoreFile/BackupRecordingFile";
import BackupDownload from "application/modules/ backup&RestoreFile/BackupDownload";
import InternalForward from "application/modules/configuration/internalForward";
import NewComplaint from "application/modules/configuration/Reports/module/newComplaint";
import PendingApprovalComplaint from "application/modules/configuration/Reports/module/pendingApprovalComplaint";
import CloseComplaint from "application/modules/configuration/Reports/module/closeComplaint";
import PendingComplaintEngineer from "application/modules/configuration/Reports/module/pendingComplaintEnginner";
import InternalForwardReport from "application/modules/configuration/Reports/internalForwardReport";
import AgentCallDataAnalysis from "application/modules/configuration/Reports/agentCallDataAnalysis";
import CallerCallDataAnalysis from "application/modules/configuration/Reports/callerCallDataAnalysis";
import LastAbandonedRecords from "application/modules/configuration/Reports/lastAbandonedRecords";
import AnsweredReport from "application/modules/configuration/Reports/answeredReport";
import QueueAbandonedTransferCalls from "application/modules/configuration/Reports/queueAbandonedTransferCalls";
import DispositionAnalysisQueue from "application/modules/configuration/Reports/dispositionAnalysisQueue";
import QueueAbandonedReport from "application/modules/configuration/Reports/queueAbandonedReport";
import QueueTimeInterval from "application/modules/configuration/Reports/queueTimeInterval";
import LeadSummary from "application/modules/configuration/Reports/LeadSummary";
import ViewLeadSummary from "application/components/ViewLeadSummary";
import ViewLeadSummaryData from "application/modules/configuration/Reports/LeadSummary/Component/LeadsummaryView";
import InboundGateway from "application/modules/configuration/inboundGateway";
import OutBoundGateway from "application/modules/configuration/SystemConfiguration/outBoundGateway";
import chat from "application/modules/chat";
import groupDashboard from "application/modules/groupDashboard";
import Billing from "layouts/pages/account/billing";
import BillingCDRSummary from "application/modules/configuration/Reports/billingCdrSummary";
import Folder from "layouts/nms/Hospital/Folder";
import FileExplorer from "layouts/nms/Hospital/HospitalComponent";
import MenuComponent from "layouts/nms/Hospital/MenuComponent";
import HospitalComponentIndex from "layouts/nms/Hospital";
import BillingCDRDetails from "application/modules/configuration/Reports/billingCdrDetails";
import groupCallInQueue from "application/modules/groupCallInQueue";
import AgentPerformanceSummary from "application/modules/configuration/Reports/AgentPerformanceSummary";
import QueuePerformanceSummary from "application/modules/configuration/Reports/QueuePerformanceSummaryReports";
import QueuePerformance from "application/modules/configuration/Reports/queuePerformance";
import EAMS from "application/modules/configuration/Reports/EAMS";
import EmialConfiguration from "application/modules/configuration/EmailConfiguration";
import whatsapp from "application/modules/whatsapp";
import IMSIConfiguration from "application/modules/configuration/SystemConfiguration/IMSIConfiguration";
import MediaCenterComponent from "layouts/nms/mediaCenter/MediaCenterComponent";
import TeamleadAgentAssociation from "application/modules/configuration/callCenterConfiguration/component/TeamLeadAgentAssociation";
import ZoneDepartmentConfiguration from "application/modules/configuration/SystemConfiguration/Zone&Department";
import DepartmentConfiguration from "application/modules/configuration/SystemConfiguration/DepartmentConfiguration";
import CompanyConfiguration from "application/modules/configuration/SystemConfiguration/CompanyConfiguration";
import BillingSummary from "application/modules/configuration/Reports/billingSummary";
import RealTimeDataConfiguration from "application/modules/configuration/RealTimeData/RealTimeDataConfiguration";
import TaskManager from "application/modules/taskManagementSystem/taskManager";
import UserTask from "application/modules/taskManagementSystem/userTask";
import UserActivityLog from "application/modules/userActivityLog";
import UserShift from "application/modules/userShiftConfiguration/userShift";
import UserShiftTime from "application/modules/userShiftConfiguration/userShiftTime";
import TaskType from "application/modules/taskManagementSystem/taskTypeMaster";
import TaskPriority from "application/modules/taskManagementSystem/taskPriority";
import TaskStatus from "application/modules/taskManagementSystem/taskStatus";
import EscalationDetails from "application/modules/configuration/escalationDetails";
import GeoFenceConfiguration from "layouts/nms/GeoLocation/GeoFenceConfiguration";
import UserPassword from "application/modules/userManagement/changePassword";
import smdpCredentials from "application/modules/smdpCredentials";

export default [
  {
    pageId: "configuration.leadUpload",
    component: LeadUpload,
  },
  {
    pageId: "extension.NGX",
    component: NGX,
  },
  {
    pageId: "extension.fax",
    component: ExtensionFax,
  },
  { pageId: "model", component: Model },
  {
    pageId: "manufacturer",
    component: ManuFacturer,
  },
  {
    pageId: "controlrluserver",
    component: ControlRLUServer,
  },
  {
    pageId: "dispositionMaster",
    component: CallDispositionMaster,
  },
  {
    pageId: "donotdiallist",
    component: DoNotDialList,
  },
  {
    pageId: "pareport",
    component: PAReport,
  },
  {
    pageId: "CustomeAgent",
    component: CustomeAgent,
  },
  {
    pageId: "CustomeQueue",
    component: CustomeQueue,
  },
  {
    pageId: "AgentQueueAssociation",
    component: AgentQueueAssociation,
  },
  {
    pageId: "configuration.JSONFileCreation",
    component: JSONFileCreation,
  },
  {
    pageId: "callcenter.TeamLeadQueueAssosiation",
    component: TeamLeadQueueAssosiation,
  },
  {
    pageId: "widgetConfiguration",
    component: WidgetConfiguration,
  },
  {
    pageId: "callcenter.configuration",
    component: CallCenterConfigurations,
  },
  {
    pageId: "Operator.Summary",
    component: OperatorSummery,
  },
  {
    pageId: "group-abandoned-call",
    component: GroupAbandonedCall,
  },
  {
    pageId: "user-login-logout",
    component: UserLoginLogout,
  },
  {
    pageId: "agent-performance",
    component: AgentDailyPerformance,
  },
  {
    pageId: "agent-DailyReport",
    component: AgentDailyReport,
  },
  {
    pageId: "usergroupmaster",
    component: UserGroupMaster,
  },
  {
    pageId: "security.configuration",
    component: SecurityConfiguration,
  },
  {
    pageId: "groupmaster",
    component: GroupMaster,
  },
  {
    pageId: "campaignSummary",
    component: CampaignSummary,
  },
  {
    pageId: "callDisposition",
    component: CallDisposition,
  },
  {
    pageId: "uc.call-back",
    component: CallBack,
  },
  {
    pageId: "uc.fax",
    component: Fax,
  },
  {
    pageId: "uc.file",
    component: Files,
  },
  {
    pageId: "uc.messages",
    component: ChatMessage,
  },
  {
    pageId: "uc.missed-calls",
    component: MissedCall,
  },
  {
    pageId: "uc.contacts",
    component: Contacts,
  },
  {
    pageId: "uc.voicemails",
    component: Voicemail,
  },
  {
    pageId: "default.extension.services",
    component: PbxConfiguration,
  },
  {
    pageId: "uc.history",
    component: CallHistory,
  },
  {
    pageId: "pbx.extension",
    component: Extension,
  },
  {
    pageId: "nms.alerts",
    component: Alerts,
  },
  {
    pageId: "video.recording",
    component: Recording,
  },
  {
    pageId: "home.dashboard",
    component: Dashboard,
  },
  {
    pageId: "dashboard.controller",
    component: dashboardController,
  },
  {
    pageId: "nms.maps",
    component: Map,
  },
  {
    pageId: "configuration.cluster",
    component: Cluster,
  },
  {
    pageId: "configuration.routes",
    component: Routes,
  },
  {
    pageId: "configuration.wans",
    component: Wans,
  },
  {
    pageId: "configuration.telephonyDeployment",
    component: TelephonyDeployment,
  },
  {
    pageId: "configuration.SeverityMaster",
    component: SeverityMaster,
  },
  {
    pageId: "configuration.monitorService",
    component: MonitorService,
  },
  {
    pageId: "configuration.changepassword",
    component: CTLPassword,
  },
  {
    pageId: "callcenter",
    component: CallCenter,
  },
  {
    pageId: "configuration.ivr",
    component: IVR,
  },
  {
    pageId: "configuration.hotel",
    component: Hotel,
  },
  {
    pageId: "configuration.comparison",
    component: Comparison,
  },
  {
    pageId: "configuration.priconfiguration",
    component: PriConfiguration,
  },
  {
    pageId: "configuration.WidgetMenuGroup",
    component: WidgetMenuGroup,
  },
  {
    pageId: "configuration.timer",
    component: Timer,
  },
  {
    pageId: "configuration.UserZoneConfiguration",
    component: UserZoneConfiguration,
  },
  {
    pageId: "uc.personal.phoneBook",
    component: PhoneBook,
  },
  {
    pageId: "uc.sendFax.details",
    component: FaxDetails,
  },
  {
    pageId: "uc.broadcasts.message",
    component: BroadcastsMessage,
  },
  {
    pageId: "uc.settings",
    component: Settings,
  },
  {
    pageId: "uc.External.api.call.log",
    component: ExternalAPI_Call,
  },
  {
    pageId: "uc.conference.portal",
    component: ConferencePortal,
  },
  {
    pageId: "uc.conference.setting",
    component: ConferenceSetting,
  },
  {
    pageId: "uc.conference.addParticipant",
    component: ParticipantAdd,
  },
  {
    pageId: "configuration.ivrList",
    component: ivrList,
  },
  {
    pageId: "configuration.siptrunk",
    component: SipTrunk,
  },
  {
    pageId: "Asset",
    component: Asset,
  },
  {
    pageId: "category",
    component: Category,
  },
  {
    pageId: "Location",
    component: CTLLocation,
  },
  {
    pageId: "Service.Master",
    component: ServiceMaster,
  },
  {
    pageId: "Category.Service",
    component: CategoryService,
  },
  {
    pageId: "menu-dashboard",
    component: CTLMenuDashboard,
  },
  {
    pageId: "Change-password",
    component: ChangePassword,
  },
  {
    pageId: "configuration.system-configuration",
    component: HostNameConfiguration,
  },
  {
    pageId: "nmsmenu-dashboard",
    component: nmsMenuDashboard,
  },
  {
    pageId: "configuration.subnet-master",
    component: SubnetMaster,
  },
  {
    pageId: "configuration.billing-cdr",
    component: BillingCDR,
  },

  {
    pageId: "configuration.nms-alert-history",
    component: NmsAlertHistory,
  },
  {
    pageId: "uc.Myconference",
    component: MyConference,
  },

  {
    pageId: "uc.conference.calendar",
    component: CTLMeetingCalendar,
  },
  {
    pageId: "configuration.node",
    component: NodeConfiguration,
  },
  {
    pageId: "conference.participant",
    component: ConferenceParticipant,
  },

  {
    pageId: "nms.userLocation",
    component: userLocation,
  },
  {
    pageId: "configuration.voicePicker",
    component: VoicePicker,
  },
  {
    pageId: "configuration.SystemEmailConfiguration",
    component: SystemEmailConfiguration,
  },
  {
    pageId: "configuration.email-body-configuration",
    component: ConferenceMailText,
  },

  {
    pageId: "configuration.dashboard",
    component: NmsDashboard,
  },
  {
    pageId: "user.zone",
    component: UserZone,
  },
  {
    pageId: "configuration.system-sms-configuration",
    component: SmsConfiguration,
  },
  {
    pageId: "lineChart",
    component: LineChart,
  },
  {
    pageId: "barChart",
    component: BarChart,
  },
  {
    pageId: "Topology",
    component: Topology,
  },
  {
    pageId: "Graph Topology",
    component: GraphTopology,
  },
  {
    pageId: "Smart Topology",
    component: SmartTopology,
  },
  {
    pageId: "DashBoard",
    component: DashBoard,
  },
  {
    pageId: "IFrame",
    component: IFrame,
  },
  {
    pageId: "Calendar",
    component: Calendar,
  },
  {
    pageId: "SpeedoMeter",
    component: SpeedoMeter,
  },
  {
    pageId: "GraphTopologyNoLeaf",
    component: GraphTopologyNoLeaf,
  },
  {
    pageId: "TopologyNoLeaf",
    component: TopologyNoLeaf,
  },
  {
    pageId: "VideoStream",
    component: VideoStream,
  },
  {
    pageId: "table",
    component: Table,
  },
  {
    pageId: "pieChart",
    component: PieChart,
  },
  {
    pageId: "doughnutChart",
    component: DoughnutChart,
  },
  {
    pageId: "serverPaginatedTable",
    component: ServerPaginatedTable,
  },
  {
    pageId: "csm_reports",
    component: CMSReport,
  },
  {
    pageId: "locationType",
    component: LocationType,
  },
  {
    pageId: "agentDashboard",
    component: CTLAgentDashboard,
  },
  {
    pageId: "userStatus",
    component: UserStatus,
  },
  {
    pageId: "userMaster",
    component: UserMaster,
  },
  {
    pageId: "roomMaster",
    component: RoomMaster,
  },
  {
    pageId: "userManagement.roleMaster",
    component: RoleMaster,
  },
  {
    pageId: "userManagement.UserRoleMaster",
    component: UserRoleMaster,
  },
  {
    pageId: "cdrs",
    component: cdrs,
  },
  {
    pageId: "cdrsGroup",
    component: cdrsGroup,
  },
  {
    pageId: "user.dashboard",
    component: UserDashboard,
  },
  {
    pageId: "snmp.rules",
    component: snmpRules,
  },
  {
    pageId: "Customer.CDRreport",
    component: CDRReport,
  },
  {
    pageId: "Campaign",
    component: Campaign,
  },
  {
    pageId: "inboundReport",
    component: InboundReport,
  },
  {
    pageId: "outboundReport",
    component: OutboundReport,
  },
  {
    pageId: "missedCallReport",
    component: MissedCallReport,
  },
  {
    pageId: "change-Usert-Profile",
    component: ChangeUserProfile,
  },
  {
    pageId: "DayTimeSetting",
    component: DayTimeSetting,
  },
  {
    pageId: "taxonomyCategory",
    component: taxonomyCategory,
  },
  {
    pageId: "taxonomy",
    component: taxonomy,
  },
  {
    pageId: "denyList",
    component: DenyList,
  },
  {
    pageId: "CallCenterReport",
    component: CallCenterReport,
  },
  {
    pageId: "deviceConfiguration",
    component: deviceConfiguration,
  },
  {
    pageId: "extension.ENM",
    component: ENM,
  },
  {
    pageId: "inboundCOS",
    component: InboundCos,
  },
  {
    pageId: "outboundCOS",
    component: OutboundCos,
  },
  {
    pageId: "gatewayController",
    component: GatewayController,
  },
  {
    pageId: "tenant",
    component: Tenant,
  },
  {
    pageId: "outboundGateway",
    component: OutboundGateway,
  },
  {
    pageId: "company",
    component: Company,
  },
  {
    pageId: "extensiondepartment",
    component: Department,
  },
  {
    pageId: "designation",
    component: Designation,
  },
  {
    pageId: "memberGroup",
    component: MemberGroup,
  },
  {
    pageId: "phoneStatus",
    component: PhoneStatus,
  },
  {
    pageId: "ucAssignedUser",
    component: UCAssignedUser,
  },
  {
    pageId: "cms",
    component: CMS,
  },
  {
    pageId: "cmsOther",
    component: CMSOther,
  },
  {
    pageId: "DialPlan",
    component: DialPlan,
  },
  {
    pageId: "Dispatcher",
    component: Dispatcher,
  },
  {
    pageId: "CBN",
    component: CBN,
  },
  {
    pageId: "CBNOther",
    component: CBNOther,
  },
  {
    pageId: "ACDGroup",
    component: ACDGroup,
  },
  {
    pageId: "EmergencyPhone",
    component: EmergencyPhone,
  },
  {
    pageId: "Phone+VOIP",
    component: PhoneVOIP,
  },
  {
    pageId: "VoicePromptFeature",
    component: VoicePromptFeature,
  },
  {
    pageId: "Phone+VOLTE",
    component: PhoneVOLTE,
  },
  {
    pageId: "phoneVONR",
    component: phoneVONR,
  },
  {
    pageId: "phoneMCXVideo",
    component: phoneMCXVideo,
  },
  {
    pageId: "softFax",
    component: SoftFax,
  },
  {
    pageId: "remoteusersip",
    component: RemoteuserSIP,
  },
  {
    pageId: "remoteuserpri",
    component: RemoteuserSIP,
  },
  {
    pageId: "meetMeConference",
    component: MeetMeConference,
  },
  {
    pageId: "dialAssist",
    component: DialAssist,
  },
  {
    pageId: "distorsion",
    component: ConfrenceDistorsion,
  },
  {
    pageId: "agentcdrreport",
    component: AgentCDRReport,
  },
  {
    pageId: "queuePerformance",
    component: QueuePerformance,
  },
  {
    pageId: "consolidatedExtension",
    component: ConsolidatedExtension,
  },
  {
    pageId: "debriefing",
    component: Debriefing,
  },
  {
    pageId: "backupRestore",
    component: BackupRestore,
  },
  {
    pageId: "backuprecordingfile",
    component: BackupRecordingFile,
  },
  {
    pageId: "backupdownloadfile",
    component: BackupDownload,
  },
  {
    pageId: "internalForward",
    component: InternalForward,
  },
  { pageId: "newComplaint", component: NewComplaint },
  {
    pageId: "PendingComplaintEngineer",
    component: PendingComplaintEngineer,
  },
  {
    pageId: "pendingComplaintApproval",
    component: PendingApprovalComplaint,
  },
  {
    pageId: "ClosedComplaints",
    component: CloseComplaint,
  },
  {
    pageId: "internalForwardReport",
    component: InternalForwardReport,
  },
  {
    pageId: "agentCallDataAnalysis",
    component: AgentCallDataAnalysis,
  },
  {
    pageId: "callerCallDataAnalysis",
    component: CallerCallDataAnalysis,
  },
  {
    pageId: "lastAbandonedRecords",
    component: LastAbandonedRecords,
  },
  {
    pageId: "answeredReport",
    component: AnsweredReport,
  },
  {
    pageId: "queueAbandonedTransferCalls",
    component: QueueAbandonedTransferCalls,
  },
  {
    pageId: "dispositionAnalysisQueue",
    component: DispositionAnalysisQueue,
  },
  {
    pageId: "queueAbandonedReport",
    component: QueueAbandonedReport,
  },
  {
    pageId: "queueTimeInterval",
    component: QueueTimeInterval,
  },
  {
    pageId: "leadSummary",
    component: LeadSummary,
  },
  {
    pageId: "ViewleadSummary",
    component: ViewLeadSummaryData,
  },
  {
    pageId: "inboundGateway",
    component: InboundGateway,
  },
  {
    pageId: "outboundgateway",
    component: OutBoundGateway,
  },
  {
    pageId: "chat",
    component: chat,
  },
  {
    pageId: "groupDashboard",
    component: groupDashboard,
  },
  {
    pageId: "groupCallInQueue",
    component: groupCallInQueue,
  },
  {
    pageId: "billingcdrsummary",
    component: BillingCDRSummary,
  },
  {
    pageId: "configurations",
    component: HospitalComponentIndex,
  },
  {
    pageId: "billingcdrdetails",
    component: BillingCDRDetails,
  },
  {
    pageId: "agent-performance-summary",
    component: AgentPerformanceSummary,
  },
  {
    pageId: "queuePerformanceSummary",
    component: QueuePerformanceSummary,
  },
  {
    pageId: "configuration.eams",
    component: EAMS,
  },
  {
    pageId: "whatsapp",
    component: whatsapp,
  },
  {
    pageId: "emailConfiguration",
    component: EmialConfiguration,
  },
  {
    pageId: "imsiconfiguration",
    component: IMSIConfiguration,
  },
  {
    pageId: "mediaCenter",
    component: MediaCenterComponent,
  },
  {
    pageId: "agentTLAssociation",
    component: TeamleadAgentAssociation,
  },
  {
    pageId: "zone&department",
    component: ZoneDepartmentConfiguration,
  },
  {
    pageId: "departmentconfiguration",
    component: DepartmentConfiguration,
  },
  {
    pageId: "companyconfiguration",
    component: CompanyConfiguration,
  },
  {
    pageId: "billingsummary",
    component: BillingSummary,
  },
  {
    pageId: "realTimeData",
    component: RealTimeDataConfiguration,
  },
  {
    pageId: "taskManager",
    component: TaskManager,
  },
  {
    pageId: "userTask",
    component: UserTask,
  },
  {
    pageId: "userActivityLog",
    component: UserActivityLog,
  },
  {
    pageId: "userShift",
    component: UserShift,
  },
  {
    pageId: "userShiftTime",
    component: UserShiftTime,
  },
  {
    pageId: "taskType",
    component: TaskType,
  },
  {
    pageId: "taskPriority",
    component: TaskPriority,
  },
  {
    pageId: "taskStatus",
    component: TaskStatus,
  },
  {
    pageId: "escalationDetails",
    component: EscalationDetails,
  },
  {
    pageId: "geoFence",
    component: GeoFenceConfiguration,
  },
  {
    pageId: "userPassword",
    component: UserPassword,
  },
  {
    pageId: "smdpCredentials",
    component: smdpCredentials,
  },
];
