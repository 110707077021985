
import DefaultCell from "application/components/DefaultCell";
import BackupRestoreAction from "application/components/BackupRestoreAction";


const getColumns = (onBackup, onDownload) => {
  return [
    {
      Header: "Conference Name",
      accessor: "roomName",
      displayInList: true,
      sortDisable: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      displayInList: true,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From Date",
      accessor: "meetingScheduledDate",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "To Date",
      accessor: "scheduledToDate",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "To Time",
      accessor: "toTime",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "modId",
      displayInList: true,
      allowFilter: false,
      sortDisable: true,
      dataType: "string",
      Cell: ({ value, row }) => (
        <BackupRestoreAction
          row={row}
          value={value}
          onBackup={onBackup}
          onDownload={onDownload}
        />
      ),
    },
  ];
};

export { getColumns };
