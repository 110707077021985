import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  enableMultipleUpdate: false,
  keyField:"extensionNumber",
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
      }),
  settingsMode: false,
};

const operationurls = {
  update: "imsi/update",
  list: "imsi/list",
};

const getUpdatedColumns = (onEdit) => {
  return [
    {
      Header: "Extension No",
      accessor: "extensionNumber",
      isUserGenerated: true,
      disabled: true,
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension Name",
      accessor: "extensionName",
      isUserGenerated: true,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      isUserGenerated: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Assets",
      accessor: "asset",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "nodeName",
        values: [],
        url: "/SCADAAsset/listAll",
      },
    },
    {
      Header: "Cell ID",
      accessor: "cellId",
      isUserGenerated: true,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IMSI No",
      accessor: "imsINumber",
      disabled: true,
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Geo Cordinate X",
      accessor: "geoX",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Geo Cordinate - Y",
      accessor: "geoY",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Blood Group",
      accessor: "bloodGroup",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Home Address",
      accessor: "haddredd",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Office Address",
      accessor: "oaddress",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Primary Emergency Contact Person",
      accessor: "pecName",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Primary Emergency Contact No",
      accessor: "pecNumber",
      displayInList: false,
      allowInForm: true,
      isUserGenerated: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Secondary Emergency Contact Person",
      accessor: "secName",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Secondary Emergency Contact No",
      accessor: "secNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Medical Details",
      accessor: "medDetails",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Zone",
      accessor: "zone",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/userZone/listAll",
      },
    },
    {
      Header: "Department",
      accessor: "department",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      isUserGenerated: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "description",
        keyDescription: "description",
        values: [],
        url: "/department/listAll",
      },
    },
    {
      Header: "Device Type",
      accessor: "deviceType",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Connected",
      accessor: "connected",
      displayInList: false,
      isUserGenerated: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      isUserGenerated: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getUpdatedColumns, Attributes, operationurls };
