import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/billingSummary/list",
  pdfDownload: "/billingSummary/generateAndDownloadPdf",
  csvDownload: "/billingSummary/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Start Date",
      accessor: "startStamp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      dateTime: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total",
      accessor: "total",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "LOCAL",
      accessor: "local",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "STD",
      accessor: "std",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "integer",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "ISD",
      accessor: "isd",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      sortDisable: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    // {
    //   Header: "NoCallType",
    //   accessor: "noCallType",
    //   displayInList: true,
    //   allowInForm: false,
    //   allowFilter: false,
    //   dataType: "integer",
    //   sortDisable: true,
    //   Cell: ({ value }) => <CTLUnReadCell value={value} />,
    // },
  ];
};

export { getColumns, Attributes, operationURLS };
