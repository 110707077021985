import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";

import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function CTLSelectedDownload({
  openForm,
  onCloseForm,
  pageName,
  columns,
  onUpdate,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  const [left, setLeft] = useState(
    columns
      .map((_, index) => index)
      .filter(
        (index) =>
          columns[index].accessor !== "" &&
          columns[index].accessor !== "id" &&
          columns[index].accessor !== "uuid"&&
          columns[index].accessor !== "recordEpoch"&&
          columns[index].accessor !== "endTime"&&
          columns[index].accessor !== "recordFilename"&&
          columns[index].accessor !== "status" && 
          columns[index].downloadReports !== false
      )
  );


  const handleToggle = (side, value) => () => {
    if (side === "left") {
      const currentIndex = leftChecked.indexOf(value);
      const newChecked = [...leftChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setRightChecked([]);
      setLeftChecked(newChecked);
    } else {
      const currentIndex = rightChecked.indexOf(value);
      const newChecked = [...rightChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setLeftChecked([]);
      setRightChecked(newChecked);
    }
  };

  useEffect(() => {
    const selectedItemsFromRight = right.map(
      (index) => columns[index].accessor
    );
    setSelectedItems(selectedItemsFromRight);
  }, [right]);

  const handleCheckedLeft = () => {
    setRight(not(right, rightChecked));
    setRightChecked([]);
  };

  const handleAllRight = () => {
    const uniqueItems = not(left, right);
    setRight([...right, ...uniqueItems]);
  };

  const handleCheckedRight = () => {
    const uniqueLeftChecked = not(leftChecked, right);
    setRight([...right, ...uniqueLeftChecked]);
    setRightChecked([]);
    setLeftChecked([]);
  };

  const handleAllLeft = () => {
    setRight([]);
  };

  const handleClose = () => {
    onCloseForm();
  };

  const handleUpdate = () => {
    onUpdate(selectedItems);
    onCloseForm();
  };

  const handleMoveUp = () => {
    if (rightChecked.length === 1) {
      const selectedItem = rightChecked[0];
      const updatedRight = [...right];
      const selectedIndex = updatedRight.indexOf(selectedItem);
      if (selectedIndex > 0) {
        [updatedRight[selectedIndex - 1], updatedRight[selectedIndex]] = [
          updatedRight[selectedIndex],
          updatedRight[selectedIndex - 1],
        ];

        setRight(updatedRight);
      }
    }
  };

  const handleMoveDown = () => {
    if (rightChecked.length === 1) {
      const selectedItem = rightChecked[0];
      const updatedRight = [...right];
      const selectedIndex = updatedRight.indexOf(selectedItem);
      if (selectedIndex < updatedRight.length - 1) {
        [updatedRight[selectedIndex], updatedRight[selectedIndex + 1]] = [
          updatedRight[selectedIndex + 1],
          updatedRight[selectedIndex],
        ];

        setRight(updatedRight);
      }
    }
  };

  const handleMoveTop = () => {
    if (rightChecked.length === 1) {
      const selectedItem = rightChecked[0];
      const updatedRight = [...right];
      const selectedIndex = updatedRight.indexOf(selectedItem);
      if (selectedIndex > 0) {
        updatedRight.splice(selectedIndex, 1);
        updatedRight.unshift(selectedItem);

        setRight(updatedRight);
      }
    }
  };

  const handleMoveBottom = () => {
    if (rightChecked.length === 1) {
      const selectedItem = rightChecked[0];
      const updatedRight = [...right];
      const selectedIndex = updatedRight.indexOf(selectedItem);
      if (selectedIndex < updatedRight.length - 1) {
        updatedRight.splice(selectedIndex, 1);
        updatedRight.push(selectedItem);

        setRight(updatedRight);
      }
    }
  };

  const customList = (items, listType) => (
    <>
      <MDTypography style={{ textAlign: "center" }}>
        {listType == "left" ? "Available Columns" : "Selected Columns"}
      </MDTypography>
      <Paper
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        style={{ width: "400px", minHeight: "400px" }}
      >
        <List
          dense
          component="div"
          role="list"
          style={{ maxHeight: "400px", overflowY: "auto" }}
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
        >
          {items.map((value, index) => {
            return (
              <ListItem
                sx={(theme) => CTLDialogContent(theme, { darkMode })}
                key={index}
                role="listitem"
                button
                onClick={handleToggle(listType, value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      listType === "left"
                        ? leftChecked.indexOf(value) !== -1
                        : rightChecked.indexOf(value) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": `transfer-list-item-${index}-label`,
                    }}
                  />
                </ListItemIcon>
                <MDTypography>
                  {columns[value].Header !== "id" &&
                    columns[value].Header !== "" &&
                    columns[value].Header !== "uuid" &&(
                      <ListItemText
                        id={`transfer-list-item-${index}-label`}
                        primary={columns[value].Header}
                      />
                    )}
                </MDTypography>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </>
  );

  return (
    <Dialog
      sx={(theme) => CTLDialogContent(theme, { darkMode })}
      open={open}
      onClose={onCloseForm}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        Select {pageName} Table Column :{" "}
        {rightChecked.length || leftChecked.length}
      </DialogTitle>
      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item>{customList(left, "left")}</Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              whiteSpace={4}
              alignItems="center"
            >
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMoveTop}
                  disabled={rightChecked.length !== 1}
                  style={{ height: "40px", width: "100px" }}
                >
                  Move-Top
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMoveUp}
                  disabled={rightChecked.length !== 1}
                  style={{ height: "40px", width: "100px" }}
                >
                  Up
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAllRight}
                  disabled={leftChecked.length !== 0}
                  style={{ height: "40px", width: "100px" }}
                >
                  Select-All
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  style={{ height: "40px", width: "100px" }}
                >
                  Selected-Only
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  style={{ height: "40px", width: "100px" }}
                >
                  Remove-Selected
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  style={{ height: "40px", width: "100px" }}
                >
                  Remove-All
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMoveDown}
                  disabled={rightChecked.length !== 1}
                  style={{ height: "40px", width: "100px" }}
                >
                  Down
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMoveBottom}
                  disabled={rightChecked.length !== 1}
                  style={{ height: "40px", width: "100px" }}
                >
                  Move-Down
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Grid item>{customList(right, "right")}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <MDButton onClick={handleClose} color="error" autoFocus>
          Close
        </MDButton>
        <MDButton onClick={handleUpdate} color="success" autoFocus>
          Apply Filter
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
