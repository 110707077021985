import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { Card } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import CTLBackupConfirm from "application/components/CTLBackupConfirm";
import variables from "globals/variables";

function BackupRestore({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const onBackup = (row) => {
    setSelectedRow(row.accessCode);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onRestore = (row) => {
    application
      .post("conferenceRoom/restore", {
        accessCode: row.accessCode,
        fileName: row.backupPath,
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });
  };

  const onAgree = (data, fileName, deleteFlag) => {
    application
      .post("conferenceRoom/backup", {
        accessCode: data,
        fileName: fileName,
        deleteFile: deleteFlag,
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: pageName,
          status: error.response.data.messageDetail,
        });
      });
    onCancel();
  };

  const onDownload = async (row) => {
    try {
      const operationURL = `${variables.app.services}auth/download/defaultTar?filePath=${row.backupPath}`;
      const response = await fetch(operationURL);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }
      setNotify({
        isOpen: true,
        message: "",
        type: "success",
        pagename: pageName,
        status: "Backup File Download In Progress...",
      });
      const link = document.createElement("a");
      link.href = operationURL;
      link.download = `${row.roomName}.tar`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
      setNotify({
        isOpen: true,
        message: "",
        type: "error",
        pagename: pageName,
        status: "Unable To Download Backup File",
      });
    }
  };

  return (
    <Card>
      <MDBox>
        <CTLBackupConfirm
          data={selectedRow}
          confirmDelete={confirmDelete}
          onAgree={onAgree}
          onCancel={onCancel}
        />

        <CTLAdvancedFilter
          entityName="Backup Restore"
          columns={getColumns()}
          applyFilter={openFilter}
          onApplyFilter={onApplyFilter}
          onCloseFilter={onCloseFilter}
        />

        <CTLTable
          apiURL="conferenceRoom/backup/list"
          columns={getColumns(onBackup, onRestore, onDownload)}
          advancedFilters={advancedFilters}
          canSearch
          defaultSortDirection="desc"
          defaultSortColumn="createdOn"
          pageId={pageId}
          searchText={searchText}
          updateList={updateList}
          hasRowSelection={false}
          refetchInterval={5000}
        />
      </MDBox>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

export default BackupRestore;
