import { Icon, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { BuildingIcon, FloorIcon, RoomIcon, PhoneIcon } from "./Icons/Icons";
import data from "./options.json";
import { useMaterialUIController } from "context";
import getIcon from "./Icons/getIcon";

const MenuComponent = ({
  currentFolder,
  anchorEl,
  handleCloseMenu,
  handleOpenAddForm,
  marginLeft,
}) => {
  const [getMenuData, setGetMenuData] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    const newData =
      currentFolder?.type === "IVR" || currentFolder?.type === "CallCenter"
        ? data?.options?.filter((item) => item?.id === "extension")
        : data?.options;
    
    setGetMenuData(newData);
  }, [currentFolder]);

  return (
    <Menu
      key={uuidv4()}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "right",
        horizontal: "bottom",
      }}
      sx={{
        ml: marginLeft,
      }}
    >
      {/* <MenuItem
        onClick={(e) => handleOpenAddForm(e, "building")}
        sx={{
          gap: 2,
        }}
      >
        <BuildingIcon /> Add Building
      </MenuItem>

      <MenuItem
        onClick={(e) => handleOpenAddForm(e, "floor")}
        sx={{
          gap: 2,
        }}
      >
        <FloorIcon /> Add Floor
      </MenuItem>
      <MenuItem
        onClick={(e) => handleOpenAddForm(e, "room")}
        sx={{
          gap: 2,
        }}
      >
        <RoomIcon />
        Add Room
      </MenuItem>
      <MenuItem
        onClick={(e) => handleOpenAddForm(e, "number")}
        sx={{
          gap: 2,
        }}
      >
        <PhoneIcon />
        Add Number
      </MenuItem> */}

      {getMenuData?.map((item, idx) => (
        <MenuItem
          key={idx}
          onClick={(e) => handleOpenAddForm(e, item.type)}
          sx={{
            gap: 2,
          }}
        >
          {/* icons */}
          {/* <img src={item?.icon} alt="" /> */}
          {getIcon(item.type, darkMode)}
          {/* <Icon color={darkMode ? "white" : "grey"}>{item?.icon}</Icon> */}
          Add {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MenuComponent;
