import React from 'react';
import { Box, Dialog, TextField } from '@mui/material';
import { useMaterialUIController } from 'context';
function InputBox({
  isEditColumnName,
  setEditedColumnName,
  changeTableProps,
  setColumns,
  columns,
  setIsEditColumnName,
  rows,
  setRows,
  editedColumnName,
  deleteItemTitle,
}) {
  const handleInputColumnName = (e) => {
    setEditedColumnName(e.target.value);
  };

  const handleClose = () => {
    setIsEditColumnName(false);
  };
  const { darkMode } = useMaterialUIController()[0];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClose();

      // Update the rows with the new column name
      const updatedRows = rows.map((rowItem) => {
        const newTransformedRow = { ...rowItem };
        if (deleteItemTitle.itemToEdit in newTransformedRow) {
          newTransformedRow[editedColumnName] = newTransformedRow[deleteItemTitle.itemToEdit];
          delete newTransformedRow[deleteItemTitle.itemToEdit];
        }
        return newTransformedRow;
      });
      setRows(updatedRows);

      // Update the columns with the new column name
      const newColumn = {
        key: editedColumnName,
        title: editedColumnName,
        width: 90,
      };
      const deleteColumnIndex = columns.findIndex((column) => column.key === deleteItemTitle.itemToEdit);
      setColumns((prevColumns) => [
        ...prevColumns.slice(0, deleteColumnIndex),
        newColumn,
        ...prevColumns.slice(deleteColumnIndex + 1),
      ]);

      // Update editable cells with the new column name
      const newEditableCells = updatedRows.map((item) => ({
        columnKey: editedColumnName,
        rowKeyValue: item.id,
      }));

      changeTableProps((prevState) => ({
        ...prevState,
        editableCells: [...prevState.editableCells, ...newEditableCells],
      }));
    }
  };

  return (
    <Dialog maxWidth='sm' open={isEditColumnName} onClose={handleClose} onKeyDown={handleKeyDown}>
      <Box sx={{ pt: 1, pb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', width: '25rem',backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
        <TextField size="large" sx={{ backgroundColor: 'white', width: '96.5%' }} label="Edit Column Name" variant="outlined" onChange={handleInputColumnName} />
      </Box>
    </Dialog>
  );
}

export default InputBox;
