import { getUserJourneyData } from './userJourneyData';

export const filterHistoryData = (startDate, endDate) => {
  // For testing, we'll use user1's data
  const userId = 'user1';
  const journeyPoints = getUserJourneyData(userId, startDate, endDate);
  
  return journeyPoints.map(point => ({
    timestamp: new Date(point.timestamp).getTime(),
    location: point.location,
    speed: point.speed,
    status: point.status
  }));
}; 