import { v4 as uuidv4 } from "uuid";
import { useGeometryDataJSON } from "../useGeometryDataJSON";

export const useFolderStructure = (setFolders) => {
  const { changeGeometryDataJSON } = useGeometryDataJSON(async () => {
    // The refetch callback will be handled by the parent component
  });

  const handleNewFolderStructure = (shapeData) => {
    if (!setFolders || typeof setFolders !== "function") {
      console.error("setFolders is not a function");
      return;
    }

    setFolders((prevFolders) => {
      if (!prevFolders || !Array.isArray(prevFolders) || !prevFolders[0]) {
        console.error("Invalid folders structure");
        return prevFolders;
      }

      // Create a deep copy of the folders array
      const newFolders = [...prevFolders];
      const rootFolder = newFolders[0];

      // Find the appropriate container based on shape type
      const zonesFolder = rootFolder.children?.find(
        (child) => child.type === "zones"
      );
      const routesFolder = rootFolder.children?.find(
        (child) => child.type === "routes"
      );

      if (
        (shapeData?.areaType === "polygon" ||
          shapeData?.areaType === "circle") &&
        zonesFolder
      ) {
        // Add polygon to Zones folder
        const newZone = {
          id: uuidv4(),
          name: shapeData?.name || "New Zone",
          type: "geofence",
          parentId: zonesFolder.id,
          shapesId: shapeData?.id ? [shapeData?.id] : [],
          children: [],
        };
        zonesFolder.children.push(newZone);
      } else if (
        (shapeData?.areaType === "polyline" ||
          shapeData?.areaType === "route") &&
        routesFolder
      ) {
        // Add route to Routes folder
        const newRoute = {
          id: uuidv4(),
          name: shapeData?.name || "New Route",
          type: "route",
          parentId: routesFolder.id,
          shapesId: shapeData?.id ? [shapeData?.id] : [],
          children: [],
        };
        routesFolder.children.push(newRoute);
      }

      // Update the geometry data JSON after modifying folders
      changeGeometryDataJSON(newFolders, `created ${shapeData?.areaType}`);

      return newFolders;
    });
  };

  return { handleNewFolderStructure };
};

function processData(folder) {
  // Iterate over each child in the folder
  folder.children.forEach((child) => {
    // Check if the child is of type 'group'
    if (child.type === "groups") {
      // Set its children to an empty array
      child.children = [];
    }
  });
  return folder;
}
