import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Autocomplete, DialogContent, TextField, Tooltip, Typography } from '@mui/material';
import { TbTableColumn, TbTableRow } from 'react-icons/tb';
// import ThemeContext from '../../context/Context'
import { useMaterialUIController } from 'context';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

export default function MediacenterDialogueConfig({ handleClickOpen, rows, isSelectNewRow, setIsSelectNewRow, createGroup, isSelectExistingRow, setIsSelectExistingRow, closeColumnDiologueBox, setCreateGroup, setCreateName, handleSaveColumn, setColumnName, languageList, handleSaveGroup, openCreateTableDiologue, setOpenCreateTableDiologue, setCloseColumnDiologueBox, setLanguageList }) {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [selectChangeType, setSelectChangeType] = React.useState('')

    const [selectedLanguages, setSelectedLanguages] = React.useState([]);

    // const context = React.useContext(ThemeContext);
    // const { darkMode } = context
    const { darkMode } = useMaterialUIController()[0];

    const handleOpenCreateColumn = () => {
        setCloseColumnDiologueBox(true);

    };
    const handleSelectedLanguage = (event, selectedOptions) => {
        setSelectedLanguages(selectedOptions);
    }

    const handleClose = () => {
        setCloseColumnDiologueBox(false);
        setOpenCreateTableDiologue(false);
        setIsSelectExistingRow(false)
        setIsSelectNewRow(false)
    };


    const handleChangeGroupeName = (e) => {
        setCreateName(e.target.value);

    }
    const handleChangeGroupeType = (e) => {
        setCreateGroup(e.target.value);
    }
    const handleCreateColumn = (e) => {
        setColumnName(e.target.value);
    }

    const handleSelectChangeType = (e) => {
        setSelectChangeType(e.target.value)
        setCreateGroup(e.target.value);
    }

    const handleAddInExistingGroup = (e) => {
        setIsSelectExistingRow(true);
    }

    const handleAddInNewGroup = (e) => {
        setIsSelectNewRow(true);
    }


    const uniqueTypes = [...new Set(rows.map(item => item.type))];

    const handleSaveLanguages = () => {
        handleSaveColumn(selectedLanguages);
        setSelectedLanguages([]);
        setCloseColumnDiologueBox(false);
    }

    return (
        <React.Fragment>
            {rows.length > 0 && <Box sx={{ display: 'flex', marginLeft: '15px' }}>
                <Tooltip title="Add Word/Phrase or Category">
                    <button size='medium' style={{ backgroundColor: darkMode ? '#1a2035' : '#fff', color: darkMode ? 'white' : '#202940', height: '100%', width: '100%', border: 'none', cursor: 'pointer' }} onClick={handleClickOpen}>
                        <TbTableRow fontSize="large" />
                        {/* <TableRowsIcon /> */}
                    </button>
                </Tooltip>
                <Tooltip title="Add Languages">
                    <button style={{ backgroundColor: darkMode ? '#1a2035' : '#fff', color: darkMode ? 'white' : '#202940', height: '100%', width: '100%', border: 'none', cursor: 'pointer', marginLeft: '10px' }} onClick={handleOpenCreateColumn}>
                        <TbTableColumn fontSize="large" />
                    </button>
                </Tooltip>
            </Box>}

            <Dialog
                // fullWidth="small"
                // maxWidth='sm'
                size='xs'
                open={openCreateTableDiologue}
                onClose={handleClose} >
                <DialogTitle textAlign="center" sx={{ color: darkMode ? '#fff' : '#000', backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>Add New File</DialogTitle>
                {!isSelectExistingRow && !isSelectNewRow && <MDBox sx={{ display: 'flex', justifyContent: 'center', gap: '30px', padding: '10px 30px 20px 30px', backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
                    {rows.length > 0 && <MDButton color="info" onClick={handleAddInExistingGroup}>Add new Word/Phrase</MDButton>}
                    <MDButton color="primary" onClick={handleAddInNewGroup}>Add New Category</MDButton>
                </MDBox>
                }
                {
                    isSelectExistingRow &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '10px 30px 20px 30px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Typography variant='h6' sx={{ width: '120px', flexShrink: 0, display: "inline", color: darkMode ? '#fff' : '#000' }}>Category:</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={createGroup}
                                        onChange={handleSelectChangeType}
                                        displayEmpty
                                        sx={{
                                            paddingBlock: 1,
                                            width: '14rem',

                                        }}
                                    >
                                        <MenuItem disabled value="" >
                                            <p style={{ color: darkMode ? 'rgba(111, 117, 133)' : 'rgb(179,182,185)', fontSize: '.75rem', fontWeight: darkMode && 'bold' }}> Choose From Dropdown...</p>
                                        </MenuItem>
                                        {uniqueTypes.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Typography variant='h6' sx={{ width: '120px', flexShrink: 0, color: darkMode ? '#fff' : '#000' }}>Word/Phrase:</Typography>
                                <TextField
                                    placeholder='Hello, Good Morning, etc.'
                                    size="small"
                                    sx={{ backgroundColor: 'white', width: '14rem' }}
                                    variant="outlined"
                                    onChange={handleChangeGroupeName}
                                />
                            </Box>
                        </Box>
                    </Box>
                }
                {isSelectNewRow &&
                    <MDBox sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF', padding: '10px 30px 20px 30px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: '2rem' ,justifyContent: "space-between" }}>
                            <MDBox >
                                <Typography variant='h6' sx={{ color: darkMode ? '#fff' : '#000' }}>Category:</Typography>
                            </MDBox>
                            <MDBox>
                                <MDInput
                                    size="small"
                                    sx={{ width: '16rem' }}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChangeGroupeType}
                                    placeholder='Welcome Message, Menu Options, etc.'
                                />
                            </MDBox>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: "space-between" }}>
                            <MDBox >
                                <Typography variant='h6' sx={{ color: darkMode ? '#fff' : '#000' }}>Word/Phrase:</Typography>
                            </MDBox>
                            <MDBox >
                                <MDInput
                                    size="small"
                                    sx={{ width: '16rem' }}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChangeGroupeName}
                                    placeholder='Hello, Good Morning, etc.'
                                />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                }
                <DialogActions sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
                    {
                        isSelectExistingRow && <MDButton variant="gradient" color="success" size="small" onClick={handleSaveGroup} >Save</MDButton>

                    }
                    {
                        isSelectNewRow && <MDButton variant="gradient" color="success" size="small" onClick={handleSaveGroup} >Save</MDButton>
                    }
                    <MDButton variant="gradient" color="error" size="small" onClick={handleClose}>Close</MDButton>
                </DialogActions>
            </Dialog>




            <Dialog
                // fullWidth={fullWidth}
                // maxWidth='sm'
                size='sm'
                open={closeColumnDiologueBox}
                onClose={handleClose}
            >
                <DialogTitle sx={{ color: darkMode ? '#fff' : '#000', backgroundColor: darkMode ? '#202940' : '#FFFFFF' }} textAlign="center">Add New Languages</DialogTitle>


                {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', width: '30rem', backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
                    <TextField size="large" sx={{ width: '60%' }} label="Language Name" variant="outlined" onChange={handleCreateColumn} />
                </Box> */}
                <DialogContent
                    sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF', display: 'flex', gap: "2rem", alignItems: 'center' }}
                >
                    {/* Add label for the autocomplete */}
                    <Typography variant='h6' sx={{ color: darkMode ? '#fff' : '#000' }}>Languages</Typography>


                    <Autocomplete
                        sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}
                        multiple
                        size='small'
                        id="tags-outlined"
                        options={languageList}
                        getOptionLabel={(option) => option.language}
                        filterSelectedOptions
                        onChange={handleSelectedLanguage}
                        value={selectedLanguages}
                        renderInput={(params) => (
                            <TextField
                                size='small'
                                color={darkMode ? "white" : "black"}
                                sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF', width: '15rem', marginTop: '.5em' }}
                                {...params}
                                // label="Select Languages"
                                placeholder="Select Languages"
                            />
                        )}
                    />
                </DialogContent>

                <DialogActions sx={{ backgroundColor: darkMode ? '#202940' : '#FFFFFF' }}>
                    <MDButton variant="gradient" color="success" size="small" onClick={handleSaveLanguages}>Save</MDButton>
                    <MDButton variant="gradient" color="error" size="small" onClick={handleClose}>Close</MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
