import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function ShapeNameDialog({
  open,
  onClose,
  onSubmit,
  shapeName,
  setShapeName,
  shapeType,
}) {
  const { darkMode } = useMaterialUIController()[0];

  const handleSubmit = () => {
    if (shapeName.trim()) {
      onSubmit();
    }
  };

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
        <DialogTitle sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}>
          <MDTypography
            variant="h5"
            sx={{
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Name your {shapeType === "polygon" ? "zone" : shapeType}
          </MDTypography>
        </DialogTitle>

        <DialogContent
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            gap: ".3em",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="Shape Name"
            type="text"
            fullWidth
            variant="outlined"
            value={shapeName}
            onChange={(e) => setShapeName(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: darkMode ? "#FFFFFF" : "#000000",
                "& fieldset": {
                  borderColor: darkMode ? "#FFFFFF" : "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputLabel-root": {
                color: darkMode ? "#FFFFFF" : "rgba(0, 0, 0, 0.6)",
              },
            }}
          />
        </DialogContent>

        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            padding: "20px",
          }}
        >
          <MDBox display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleSubmit}
              disabled={!shapeName.trim()}
            >
              Save
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              onClick={onClose}
            >
              Cancel
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ShapeNameDialog;
