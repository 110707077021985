import {
  AppBar,
  Avatar,
  Box,
  Icon,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Card,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useEffect, useRef, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { useMaterialUIController } from "context";
import { getOnlineUsers } from "../xmppClient";
import MDButton from "components/MDButton";

function Conversation({
  useStyles,
  messageInput,
  setMessageInput,
  messages,
  selectUser,
  selectGroup,
  handleBack,
  handleFileSelect,
  sendMessage,
  downloadFile,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const messageEndRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };


  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <AppBar
        position="static"
        color={darkMode ? "dark" : "light"}
        style={{ borderRadius: "10px", marginBottom: "10px" }}
      >
        <Toolbar>
          <IconButton onClick={() => handleBack()}>
            <Icon color="text">arrow_back_icon</Icon>
          </IconButton>
          &nbsp; &nbsp;
          {selectUser && (
            <Avatar>
              <PersonIcon />
            </Avatar>
          )}
          {selectGroup && (
            <Avatar>
              <GroupIcon />
            </Avatar>
          )}
          &nbsp; &nbsp;
          <MDTypography variant="h6" component="div">
            {selectUser || selectGroup}
          </MDTypography>
        </Toolbar>
      </AppBar>
      <div
        className={classes.messageContainer}
        style={{
          height: "calc(100vh - 200px)",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            minHeight: 0,
            px: 2,
            py: 3,
            overflowY: "scroll",
            flexDirection: "inherit",
          }}
        >
          {messages &&
            messages.map((message, index) => {
                const sender = message.from.split("@")[0];
                const isCurrentUser =
                  sender === AuthenticationService.getFullName();
                const isSelectedUser = sender === selectUser;

                return (
                  <div
                    key={index}
                    className={
                      isCurrentUser
                        ? classes.senderMessage
                        : classes.receiverMessage
                    }
                  >
                    {!isCurrentUser && message.type === "groupchat" && (
                      <MDTypography variant="h6">
                        <Box display="flex" alignItems="center">
                          <Avatar sx={{ width: 20, height: 20, mr: 1 }}>
                            <PersonIcon sx={{ fontSize: 10 }} />
                          </Avatar>
                          {sender}
                        </Box>
                      </MDTypography>
                    )}

                    {(message.type === "chat" ||
                      message.contentType === "groupchat") && (
                      <MDTypography variant="subtitle2">
                        {message.text}
                      </MDTypography>
                    )}

                    {(message.type === "file" ||
                      message.contentType === "groupfile") && (
                      <MDTypography variant="subtitle2">
                        {isCurrentUser ? (
                          <>
                            <IconButton
                              variant="contained"
                              color="success"
                              padding="5px"
                              onClick={() => downloadFile(message.text)}
                            >
                              <Icon>download_for_offline_icon</Icon>
                            </IconButton>
                            {message.text?.split("/").pop()}
                          </>
                        ) : (
                          <>
                            {message.text?.split("/").pop()}
                            <IconButton
                              variant="contained"
                              color="success"
                              padding="5px"
                              onClick={() => downloadFile(message.text)}
                            >
                              <Icon>download_for_offline_icon</Icon>
                            </IconButton>
                          </>
                        )}
                      </MDTypography>
                    )}

                    <MDTypography variant="caption" className={classes.time}>
                      {message.time} &nbsp; {message.date} &nbsp;
                      {isCurrentUser ? (
                        <Icon>call_made_icon</Icon>
                      ) : (
                        <Icon>call_received_icon</Icon>
                      )}
                    </MDTypography>
                  </div>
                );
              })}
          <div ref={messageEndRef} />
        </Box>
      </div>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          label="Type your message"
          variant="outlined"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <label htmlFor="fileInput">
                  <Tooltip title={"select file"}>
                    <IconButton color="info" component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip>
                </label>
              </>
            ),
          }}
        />
        <MDButton
          variant="contained"
          color="info"
          onClick={sendMessage}
          style={{ height: "50px", marginLeft: "5px" }}
        >
          Send
        </MDButton>
      </div>
    </Card>
  );
}

export default Conversation;
