import MDButton from "components/MDButton";

function CloseChat({ isChatClosed, handleCloseChatConfirmationOpen }) {
  return (
    <MDButton
      variant="gradient"
      color="error"
      size="small"
      sx={{ position: "absolute", top: "1.3em", right: "1.3em" }}
      onClick={handleCloseChatConfirmationOpen}
      disabled={isChatClosed}
      zindex={9999}
    >
      {isChatClosed ? "Closed" : "Close chat"}
    </MDButton>
  );
}

export default CloseChat;
