import { divIcon } from "leaflet";
import { Marker, Popup } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import TrainIcon from "@mui/icons-material/Train";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Delete, FmdBad, FmdGood, LocalHospital } from "@mui/icons-material";
import { application, telemetry } from "globals/endpoints";
import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import loggerFactory from "globals/logger/logger-factory";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import "../../../../App.css";
import {
  useMaterialUIController,
  setClickToCall,
  setIsPreRecordBroadcast,
  setBroadCastCallType,
} from "context";
import dialog from "assets/theme/components/dialog";
import CTLDialogContent from "../../styles/CTLDialogContent";
import L from "leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import WarningIcon from "@mui/icons-material/Warning";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import EmptyImage from "../../../../assets/images/mediacenter/emptyFolder.svg";
import CTLNotification from "application/components/CTLNotification";
import DispatcherGroupCall from "../DispatcherGroupCall";

function AreaSelect({ onAreaSelect }) {
  useEffect(() => {
    if (!map) {
      map = L.map("map");
      if (map) {
        map.selectArea.enable();

        map.on("areaselected", (e) => {
          onAreaSelect(e.bounds);
        });
      }
    }
  }, [onAreaSelect]);

  return null;
}

export default function LocationView({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
}) {
  const Attributes = {
    modes: [],
  };

  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  const componentName = "MAP";
  loggerFactory.debug(
    componentName,
    "Location : " + AuthenticationService.getLocation()
  );
  const mapRef = useRef();
  const mapContainerRef = useRef(null);
  const { darkMode } = controller;
  const userName = AuthenticationService.getUserName();
  const UserNumber = AuthenticationService.getExtensionNumber();
  const [pins, setPins] = useState();
  const [northZoneColor, setNorthZoneColor] = useState("success");
  const [southZoneColor, setSouthZoneColor] = useState("success");
  const [selectedZone, setSelectedZone] = useState(false);
  const [clickButtonName, setClickButtonName] = useState("");
  const [messageToBroadcast, setMessageToBroadcast] = useState("");
  const [message, setMessage] = useState("");
  const [zoneList, setZoneList] = useState();
  const [departmentList, setDepartmentList] = useState();
  const [fileList, setFileList] = useState();
  const [chatData, setChatData] = useState([]);
  const [selectZD, setSelectZD] = useState("");
  const [selectFile, setSelectFile] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [nearestMarkers, setNearestMarkers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTrain, setSelectedTrain] = useState("");

  const [homeLocation, setHomeLocation] = useState(
    AuthenticationService.getLocation()
  );
  const [locationUpdateColor, setLocationUpdateColor] = useState("warn");
  const [data, setData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useQuery(
    "geoTags",
    async () => {
      const response = await telemetry.get("geoTagging/list/location");
      loggerFactory.debug(componentName, "Data Received");
      setData(response.data.data);
    },
    {
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map(mapContainerRef.current, {
        minZoom: window.mapMinZoom ? window.mapMinZoom : 10,
        maxZoom: window.mapMaxZoom ? window.mapMaxZoom : 15,
      }).setView(homeLocation, window.mapDefaultZoom);
      //`https://tile.openstreetmap.org/{z}/{x}/{y}.png`
      mapRef.current = map;
      const osm = L.tileLayer(`${window.mapURL}/tile/{z}/{x}/{y}.png`, {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      });
      osm.addTo(map);
      function addPinToMap(map, location) {
        const customIcon = L.divIcon({
          html: pin,
          iconSize: [0, 0],
        });

        L.marker(location, { icon: customIcon }).addTo(map);
      }

      const locationToAddPin = [homeLocation[0], homeLocation[1]];
      addPinToMap(map, locationToAddPin);

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        position: "topright",
        edit: {
          featureGroup: drawnItems,
          onCreated: _onCreated,
          onDeleted: _onDeleted,
        },
        draw: {
          rectangle: true,
          polyline: false,
          circle: false,
          marker: false,
          polygon: false,
          circlemarker: false,
        },
      });

      map.addControl(drawControl);

      map.on("draw:created", _onCreated);
      map.on("draw:deleted", _onDeleted);

      map.on("draw:drawstart", function (e) {
        drawnItems.clearLayers();
      });

      map.on("draw:created", function (e) {
        const type = e.layerType;
        const layer = e.layer;

        if (type === "rectangle") {
          const bounds = layer.getBounds();
          const topLeft = bounds.getNorthWest();
          const topRight = bounds.getNorthEast();
          const bottomLeft = bounds.getSouthWest();
          const bottomRight = bounds.getSouthEast();

          loggerFactory.debug("Rectangle corners:");
          loggerFactory.debug(
            "Corner 1 (Top Left): " + topLeft.lat + ", " + topLeft.lng
          );
          loggerFactory.debug(
            componentName,
            "Corner 2 (Top Right): " + topRight.lat + ", " + topRight.lng
          );
          loggerFactory.debug(
            componentName,
            "Corner 3 (Bottom Right): " +
              bottomRight.lat +
              ", " +
              bottomRight.lng
          );
          loggerFactory.debug(
            componentName,
            "Corner 4 (Bottom Left): " + bottomLeft.lat + ", " + bottomLeft.lng
          );

          drawnItems.addLayer(layer);
        }
      });
    }
  }, [homeLocation, window.mapDefaultZoom]);

  useEffect(() => {
    if (mapRef.current && data) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });

      const markers = L.layerGroup().addTo(mapRef.current);
      const markerDataMap = new Map();
      const calculateDragDistance = (latlng1, latlng2) =>
        latlng1.distanceTo(latlng2);

      const handleDragEnd = (event) => {
        const draggedMarker = event.target;
        const draggedMarkerPosition = draggedMarker.getLatLng();
        const draggedMarkerData = markerDataMap.get(draggedMarker);

        draggedMarkerData.geoX = draggedMarkerPosition.lat;
        draggedMarkerData.geoY = draggedMarkerPosition.lng;

        let droppedOnMarkerData = null;
        markers.eachLayer((layer) => {
          if (layer instanceof L.Marker && layer !== draggedMarker) {
            const markerPosition = layer.getLatLng();
            const distance = calculateDragDistance(
              draggedMarkerPosition,
              markerPosition
            );
            if (distance <= 50) {
              droppedOnMarkerData = markerDataMap.get(layer);
              dragToCall(
                draggedMarkerData.extensionNumber,
                droppedOnMarkerData.extensionNumber
              );
              loggerFactory.info(
                componentName,
                `marker dragged data:`,
                draggedMarkerData.extensionNumber
              );
              loggerFactory.info(
                componentName,
                `marker dropped data:`,
                droppedOnMarkerData.extensionNumber
              );
            }
          }
        });
      };

      data.forEach((marker) => {
        if (marker.geoX && marker.geoY) {
          const markerLocation = [
            parseFloat(marker.geoX),
            parseFloat(marker.geoY),
          ];
          const customIcon = getMarkerIcon(marker);
          const customMarker = L.marker(markerLocation, {
            icon: customIcon,
            draggable: true,
          });

          markerDataMap.set(customMarker, marker);
          customMarker.addTo(markers);
          customMarker.on("dragend", handleDragEnd);

          let popupContent = "";

          if (marker.data) {
            popupContent = `
            <div>
              <h3 style="color: black;">${marker.data.name}</h3>
              <p style="color: black;">
                <hr />
                <strong>Lat Long:</strong> ${marker.geoX}, ${
              marker.geoY
            }<br /> <hr />
                <strong>Blood Group:</strong> ${
                  marker.data.bloodGroup || "N/A"
                }<br /> <hr />
                <strong>Medical Condition:</strong> ${
                  marker.data.medicalDetails || "N/A"
                }<br /> <hr />
                <strong>Home Address:</strong> ${
                  marker.data.homeAddress || "N/A"
                }<br /> <hr />
                <strong>Office Address:</strong> ${
                  marker.data.officeAddress || "N/A"
                }<br /> <hr />
                <strong>Primary Contact:</strong> ${
                  marker.data.primaryEmergencyContactName || "N/A"
                } ${
              marker.data.primaryEmergencyContactNumber || "N/A"
            }<br /> <hr />
                <strong>Secondary Contact:</strong> ${
                  marker.data.secondaryEmergencyContactName || "N/A"
                } ${marker.data.secondaryEmergencyContactNumber || "N/A"}<br />
                <hr />
               ${
                 marker.emergencyStatus
                   ? `<button style="background-color: green; color: white; padding:5px; margin: auto; display: block; 
                border-radius: 5px; cursor: pointer; margin-top: 10px;"  onclick="window.handleClearEmergency('${marker.id}')">Clear SOS</button>`
                   : ""
               }
              </p>
               ${
                 marker.tagType !== "imsi"
                   ? `<button style="background-color: red; color: white; padding:5px; margin: auto; display: block; border-radius: 10px; cursor: pointer;" onclick="window.handlePinDelete('${marker.id}')">
                    Delete
                </button>`
                   : ""
               }
              <hr />
               ${
                 marker.emergencyStatus && showSelect
                   ? `<select  id="transfer-select" onchange="window.handleSelectChange(event, ${
                       marker.extensionNumber
                     })" style="max-height: 100px; padding: 10px; overflow-y: auto;">
                 <option value="">Select nearest marker to transfer call</option>
                 ${nearestMarkers
                   .map(
                     (m) =>
                       `<option value="${m.extensionNumber}">${m.name}</option>`
                   )
                   .join("")}
               </select>`
                   : `<button style="background-color: #1356c2; color: white; padding:5px; margin: auto; display: block; 
                 border-radius: 5px; cursor: pointer; margin-top: 10px;"  
                 onclick="window.handleTransferCall('${marker.id}')">Transfer Call</button>`
               }
                   <hr/>
              <button style="background-color: #1356c2; color: white; padding:5px; margin: auto; ; 
                 border-radius: 5px; cursor: pointer; margin-top: 10px;"  
                 onclick="window.clickToCallTrain('${
                   marker.extensionNumber
                 }')">Call</button> 
                 
                 ${
                   marker.deviceType !== "phone"
                     ? `
      <button style="background-color: green; color: white; padding:5px; margin: auto;
      border-radius: 5px; cursor: pointer; margin-top: 10px;"  
      onclick="window.clickToMessageTrain('${marker.extensionNumber}')">Message</button>`
                     : ""
                 }


            </div>`;
          } else {
            popupContent = `
  <div>
    <h3 style="color: black;">${marker.name}</h3>
    <p style="color: black;">
      <hr />
      <strong>Lat Long:</strong> ${marker.geoX}, ${marker.geoY}
    </p>
    <hr/>
    <button style="background-color: #1356c2; color: white; padding:5px; margin: auto;
       border-radius: 5px; cursor: pointer; margin-top: 10px;"  
       onclick="window.clickToCallTrain('${
         marker.extensionNumber
       }')">Call</button>

    ${
      marker.deviceType !== "phone"
        ? `
      <button style="background-color: green; color: white; padding:5px; margin: auto;
      border-radius: 5px; cursor: pointer; margin-top: 10px;"  
      onclick="window.clickToMessageTrain('${marker.extensionNumber}')">Message</button>`
        : ""
    }
  </div>`;
          }

          customMarker.bindPopup(popupContent);
          customMarker.addTo(markers);
        }
      });
    }
  }, [showSelect, data, nearestMarkers]);

  const iconTrain = renderToStaticMarkup(
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          right: "-5px",
          bottom: "-5px",
          left: "-5px",
          border: "2px solid #4CAF50",
          borderRadius: "12px",
        }}
      ></div>
      <TrainIcon color="success" fontSize="large" />
    </div>
  );

  const iconTrainUp = renderToStaticMarkup(
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          right: "-5px",
          bottom: "-5px",
          left: "-5px",
          border: "2px solid #4CAF50",
          borderRadius: "12px",
        }}
      ></div>
      <TrainIcon color="success" fontSize="large" />
      <ArrowUpwardIcon color="success" fontSize="medium" />
    </div>
  );

  const iconTrainDown = renderToStaticMarkup(
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          right: "-5px",
          bottom: "-5px",
          left: "-5px",
          border: "2px solid #4CAF50",
          borderRadius: "12px",
        }}
      ></div>
      <TrainIcon color="success" fontSize="large" />
      <ArrowDownwardIcon color="success" fontSize="medium" />
    </div>
  );

  const pin = renderToStaticMarkup(
    <MyLocationIcon color="info" fontSize="small" />
  );

  const iconIMSIEmergency = renderToStaticMarkup(
    <LocalHospital color="error" fontSize="large" />
  );

  const iconIMSIInActive = renderToStaticMarkup(
    <FmdGood color="warning" fontSize="large" />
  );

  const iconEnemy = renderToStaticMarkup(
    <FmdBad color="error" fontSize="large" />
  );

  let icon = iconTrain;

  const markerTrainUp = divIcon({
    html: iconTrainUp,
  });

  const markerTrainDown = divIcon({
    html: iconTrainDown,
  });

  const markerIMSIInActive = divIcon({
    html: iconIMSIInActive,
  });

  const markerEnemy = divIcon({
    html: iconEnemy,
  });

  const markerEmergency = divIcon({
    html: iconIMSIEmergency,
  });

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  window.handleTransferCall = (clickedMarkerId) => {
    const clickedMarker = data.find((marker) => marker.id === clickedMarkerId);
    const sortedMarkers = data
      .filter(
        (marker) => marker.id !== clickedMarker.id && marker.geoX && marker.geoY
      )
      .map((marker) => ({
        ...marker,
        distance: calculateDistance(
          parseFloat(clickedMarker.geoX),
          parseFloat(clickedMarker.geoY),
          parseFloat(marker.geoX),
          parseFloat(marker.geoY)
        ),
      }))
      .sort((a, b) => a.distance - b.distance);
    setNearestMarkers(sortedMarkers);
    setShowSelect(true);
  };

  window.handleSelectChange = (e, number) => {
    const selectedMarker = nearestMarkers.find(
      (marker) => marker.extensionNumber === e.target.value
    );
    dragToCall(number, selectedMarker.extensionNumber);
    setShowSelect(false);
  };

  const dragToCall = (draggedMarkerData, droppedOnMarkerData) => {
    activityLog(
      "event",
      "Transfer Call",
      "Drag And Drop",
      draggedMarkerData,
      droppedOnMarkerData,
      `${userName} transfer call ${draggedMarkerData} to ${droppedOnMarkerData}`
    );
    const body = {
      caller: draggedMarkerData,
      callee: droppedOnMarkerData,
    };
    application
      .post("/extensions/dragCall", body)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "success",
          pagename: componentName,
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "",
          type: "error",
          pagename: componentName,
          status: error.response.data.message,
        });
      });
  };

  window.clickToCallTrain = (number) => {
    setClickToCall(dispatch, number);
  };

  window.clickToMessageTrain = (number) => {
    setSelectedTrain(number);
    setOpen(true);
  };

  window.handleClearEmergency = (imsi) => {
    telemetry.post(`/geoTagging/clearEmergency/${imsi}`);
  };

  window.handlePinDelete = (id) => {
    telemetry.post(`/geoTagging/delete/${id}`);
  };

  const getDropdownList = () => {
    application
      .post("extensions/listAllDepartment")
      .then((response) => {
        setDepartmentList(response.data.data);
      })
      .catch((error) => {
        console.error("Failed to get Department data-", error);
      });

    application
      .post("extensions/listAllZone")
      .then((response) => {
        setZoneList(response.data.data);
      })
      .catch((error) => {
        console.error("Failed to get Zone data-", error);
      });
  };

  const getFileList = () => {
    application
      .post("announcement/listAll")
      .then((response) => {
        setFileList(response.data.data);
      })
      .catch((error) => {
        console.error("Failed to get data-", error);
      });
  };

  const getChatList = () => {
    application
      .post("chatMessage/listAll")
      .then((response) => {
        setChatData(response.data.data);
      })
      .catch((error) => {
        console.error("Failed to get data ", error);
      });
  };

  const announcementClick = (name) => {
    setSelectedZone(true);
    setClickButtonName(name);
    getDropdownList();
  };

  const recordingAnnouncementClick = (name) => {
    setSelectedZone(true);
    setClickButtonName(name);
    getDropdownList();
  };

  const pageClick = (name) => {
    setSelectedZone(true);
    setClickButtonName(name);
    getDropdownList();
    getFileList();
  };

  const messageClick = (name) => {
    setClickButtonName(name);
    setSelectedZone(true);
    getDropdownList();
  };

  const onEmergencyClick = () => {
    const number = AuthenticationService.getPublicEmergencyNumber();
    if (number) {
      setClickToCall(dispatch, number);
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: componentName,
        status: "Please Define Public Emergency Number",
      });
    }
  };

  const messageHistoryClick = (name) => {
    setClickButtonName(name);
    setSelectedZone(true);
    getChatList();
  };

  const onCloseForm = () => {
    setSelectedZone(false);
    setMessageToBroadcast("");
    setSelectFile("");
    setSelectZD("");
  };

  const _onCreated = (e) => {
    let type = e.layerType;
    let layer = e.layer;
    if (type === "marker") {
      loggerFactory.debug(componentName, "_onCreated: marker created", e);
    } else {
      loggerFactory.debug(
        componentName,
        "_onCreated: something else created:",
        type,
        e
      );
    }

    loggerFactory.debug(componentName, "Geojson", layer.toGeoJSON());
    loggerFactory.debug(componentName, "Bounds", layer.getBounds());
    activityLog(
      "Announcement By Selection Area",
      `Broadcast File Play`,
      "",
      ""
    );
    application.post("/gis/areaSelected", layer.getBounds());
  };

  const _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer((layer) => {
      numDeleted += 1;
    });
    loggerFactory.debug(
      componentName,
      `onDeleted: removed ${numDeleted} layers`,
      e
    );
  };

  const handleSelectZD = (event) => {
    setSelectZD(event.target.value);
  };

  const handleFileSelect = (event) => {
    setSelectFile(event.target.value);
  };

  const handleSaveButton = () => {
    const isZone = !!selectZD.zone;
    const isDepartment = !!selectZD.department;

    const apiEndpoint = isZone ? "zone" : isDepartment ? "department" : "";

    if (!apiEndpoint) return;

    const selectedValue = isZone ? selectZD.zone : selectZD.department;
    const number = isZone ? selectZD.extension : selectZD.extension;

    if (clickButtonName === "message") {
      const body = {
        [apiEndpoint]: selectedValue,
        messageDetail: messageToBroadcast,
      };
      application.post(`chatMessage/sendSocket/${apiEndpoint}`, body);
       activityLog(
         "message",
         "Announcement Message",
         "Alert Message",
         UserNumber,
         selectedValue,
         `${userName} send broadcast message in ${selectedValue} ,  message : ${messageToBroadcast}`
       );
      setMessageToBroadcast("");
    } else if (clickButtonName === "announcement") {
      const body = { callType: "muxing", [apiEndpoint]: selectedValue };
      application.post(`extensions/call/${apiEndpoint}`, body);
      activityLog(
        "event",
        "Announcement Call",
        "Alert Call",
        UserNumber,
        selectedValue,
        `${userName} dial broadcast call in ${selectedValue}`
      );
    } else if (clickButtonName === "page") {
      const body = { wavFile: selectFile, [apiEndpoint]: selectedValue };
      application.post(`extensions/play/${apiEndpoint}`, body);
      let fileName = selectFile?.split(".")[0];
      activityLog(
        "event",
        "Announcement Play",
        "Broadcast File Play",
        UserNumber,
        selectedValue,
        `${userName} Announcement Broadcast  ${selectFile} File Play in ${selectedValue}`
      );
      setSelectFile("");
    } else if (clickButtonName === "Recording_Announcement") {
      const data = {
        type: apiEndpoint,
        name: selectedValue,
      };
      setBroadCastCallType(dispatch, data);
      setIsPreRecordBroadcast(dispatch, true);
      setClickToCall(dispatch, number);
      activityLog(
        "event",
        "Announcement Recording",
        "Broadcast Recording Play",
        UserNumber,
        selectedValue,
        `${userName} Announcement Recording Play in ${selectedValue}`
      );
    }
    setSelectZD("");
    setSelectedZone(false);
  };

  const activityLog = (
    event,
    eventCategory,
    eventStatus,
    callerNumber,
    destinationNumber,
    eventData
  ) => {
    const bodyData = {
      event: event,
      eventCategory: eventCategory,
      eventStatus: eventStatus,
      callerNumber: callerNumber,
      destinationNumber: destinationNumber,
      eventData: {
        msg: eventData,
      },
      userId: userName,
      deviceId: UserNumber,
      epochDate: Math.floor(Date.now() / 1000),
    };
    application.post("activityLog/create", bodyData);
  };

  const handleMessageChange = (event) => {
    setMessageToBroadcast(event.target.value);
  };

  const lockOnCurrentLocation = () => {
    getLocation();
  };

  const navigateToLocation = () => {
    mapRef.current.setView(homeLocation, window.mapDefaultZoom);
  };

  const updateMAPLocation = (newLocation) => {
    mapRef.current.setView(newLocation, window.mapDefaultZoom);
  };

  function getLocation() {
    if (navigator.geolocation) {
      setLocationUpdateColor("info");
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      loggerFactory.debug(
        componentName,
        "Geolocation is not supported by this browser."
      );
    }
  }

  function showPosition(position) {
    setLocationUpdateColor("warn");
    loggerFactory.debug(componentName, [
      position.coords.latitude,
      position.coords.longitude,
    ]);
    updateMAPLocation([position.coords.latitude, position.coords.longitude]);
    setHomeLocation([position.coords.latitude, position.coords.longitude]);
    AuthenticationService.setLocation(
      position.coords.latitude,
      position.coords.longitude
    );
  }

  function getMarkerIcon(marker) {
    let customIcon;

    if (marker.tagType === "imsi") {
      if (marker.emergencyStatus) {
        customIcon = markerEmergency;
      } else {
        customIcon = marker.direction == "up" ? markerTrainUp : markerTrainDown;
      }
    } else {
      customIcon = markerEnemy;
    }
    customIcon.options.iconSize = [0, 0];
    customIcon.options.iconAnchor = [10, 15];
    return customIcon;
  }

  function epochToTime(epoch) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(epoch);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${formattedHours}:${minutes}:${seconds} ${meridiem} - ${day} ${month} ${year}`;
  }

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTrainMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClickClose = () => {
    setOpen(false);
    setMessage("");
    setSelectedTrain("");
  };

  const onMessageSend = () => {
    if (selectedTrain) {
      const body = {
        extension: selectedTrain,
        messageDetail: message,
      };
      application
        .post("chatMessage/sendMessageByExtension", body)
        .then((response) => {
            activityLog(
              "message",
              "Alert Message",
              "Send",
              UserNumber,
              selectedTrain,
              `${userName} send message to ${selectedTrain}, message: ${message}`
            );
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: componentName,
            status: response.data.message,
          });
          setOpen(false);
          setMessage("");
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "",
            type: "error",
            pagename: componentName,
            status: error.response.data.message,
          });
          setOpen(false);
          setMessage("");
        });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid
          item
          xs={10}
          style={{
            height: "70vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {homeLocation && (
            <div
              id="map"
              ref={mapContainerRef}
              style={{ height: "100%" }}
              center={homeLocation}
              zoom={window.mapDefaultZoom}
            >
              <AreaSelect
                onAreaSelect={(coordinates) => {
                  application.post("/gis/areaSelected", coordinates);
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={2}>
          <Dialog
            open={selectedZone}
            onClose={onCloseForm}
            fullWidth
            maxWidth="md"
            classes={{ dialog }}
          >
            <DialogTitle
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
              id="alert-dialog-title"
            >
              {clickButtonName == "messageHistory"
                ? "Chat History"
                : `Broadcast ${clickButtonName} to selected zone / department`}
            </DialogTitle>
            <DialogContent
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
            >
              {clickButtonName == "messageHistory" ? (
                <Card
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    {chatData.length != 0 ? (
                      chatData.map((message, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent:
                              message.type === "S" ? "flex-end" : "flex-start",
                            marginBottom: "10px",
                          }}
                        >
                          <Box
                            style={{
                              maxWidth: "50%",
                              textAlign:
                                message.type === "S" ? "right" : "left",
                              padding: "5px",
                              paddingRight: "20px",
                              paddingLeft: "20px",
                              borderRadius: "10px",
                              backgroundColor:
                                message.type === "S" ? "#78b3d0" : "#1e81b0",
                            }}
                          >
                            <Typography variant="body1">
                              {message.messageDetails}
                            </Typography>
                            <Typography variant="caption">
                              {message.type === "S" ? "You" : message.receiver}{" "}
                              - {epochToTime(message.epoch)}&nbsp;&nbsp;
                              {message.type === "S" ? (
                                <CallMadeIcon></CallMadeIcon>
                              ) : (
                                <CallReceivedIcon></CallReceivedIcon>
                              )}{" "}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Card
                        style={{
                          height: "auto",
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img src={EmptyImage} alt="Empty Folder" />
                          <Typography variant="h5" fontFamily={600}>
                            No Chat Found
                          </Typography>
                        </Box>
                      </Card>
                    )}
                    <div />
                  </Box>
                </Card>
              ) : (
                <>
                  <MDTypography variant="caption">{`Select Zone / Department`}</MDTypography>
                  <Select
                    value={selectZD}
                    style={{ height: "55px" }}
                    onChange={handleSelectZD}
                    name="Select Zone / Department"
                    fullWidth
                  >
                    <MenuItem key="select" value="">
                      Select
                    </MenuItem>
                    <MenuItem key="zone-list" value="" disabled>
                      Zone List
                    </MenuItem>
                    {zoneList &&
                      zoneList.map((item) => (
                        <MenuItem key={`zone-${item.extension}`} value={item}>
                          {item.zone || item.extension}
                        </MenuItem>
                      ))}
                    <MenuItem key="department-list" value="" disabled>
                      Department List
                    </MenuItem>
                    {departmentList &&
                      departmentList.map((item) => (
                        <MenuItem
                          key={`department-${item.extension}`}
                          value={item}
                        >
                          {item.department || item.extension}
                        </MenuItem>
                      ))}
                  </Select>

                  {clickButtonName == "page" && (
                    <>
                      {" "}
                      <MDTypography variant="caption">Select File</MDTypography>
                      <Select
                        name="Select File"
                        value={selectFile}
                        style={{ height: "55px" }}
                        onChange={handleFileSelect}
                        fullWidth
                      >
                        <MenuItem key="" value="">
                          Select
                        </MenuItem>
                        {fileList &&
                          fileList.map((item) => (
                            <MenuItem key={item.id} value={item.wavFile}>
                              {item.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                  {clickButtonName == "message" && (
                    <>
                      <Divider></Divider>
                      <TextField
                        fullWidth
                        label="Type your message"
                        multiline
                        rows={4}
                        defaultValue=""
                        value={messageToBroadcast}
                        onChange={handleMessageChange}
                      />
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
            >
              {clickButtonName == "messageHistory" ? (
                <MDButton onClick={onCloseForm} color="error">
                  Close
                </MDButton>
              ) : (
                <>
                  <MDButton onClick={onCloseForm} color="secondary">
                    Cancel
                  </MDButton>
                  <MDButton
                    color="success"
                    autoFocus
                    onClick={handleSaveButton}
                  >
                    Submit
                  </MDButton>
                </>
              )}
            </DialogActions>
          </Dialog>
          <MDBox style={{ textAlign: "center" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDButton
                  color="success"
                  style={{ minHeight: "55px" }}
                  onClick={() => announcementClick("announcement")}
                  endIcon={<Icon>call</Icon>}
                  fullWidth
                >
                  Dial
                </MDButton>
              </Grid>
              <Divider></Divider>
              <Grid item xs={12}>
                <MDButton
                  color="error"
                  style={{ minHeight: "55px" }}
                  onClick={() =>
                    recordingAnnouncementClick("Recording_Announcement")
                  }
                  endIcon={<Icon>mic_icon</Icon>}
                  fullWidth
                >
                  Recording & Page
                </MDButton>
              </Grid>
              <Divider></Divider>

              <Grid item xs={12}>
                <MDButton
                  color="warning"
                  style={{ minHeight: "55px" }}
                  onClick={() => pageClick("page")}
                  endIcon={<Icon>campaign</Icon>}
                  fullWidth
                >
                  Page
                </MDButton>
              </Grid>
              <Divider></Divider>
              <Grid item xs={12}>
                <MDButton
                  color="info"
                  style={{ minHeight: "55px" }}
                  onClick={() => messageClick("message")}
                  endIcon={<Icon>feedback</Icon>}
                  fullWidth
                >
                  Message
                </MDButton>
              </Grid>
              {/* <Grid item xs={12}>
                <MDButton
                  color="secondary"
                  style={{ minHeight: "55px" }}
                  onClick={() => messageHistoryClick("messageHistory")}
                  endIcon={<Icon>history</Icon>}
                  fullWidth
                >
                  Message History
                </MDButton>
              </Grid> */}
              <Grid item xs={12}>
                <DispatcherGroupCall
                  event="Group Call"
                  icon="call"
                  color="success"
                />
              </Grid>
              <Grid item xs={12}>
                <DispatcherGroupCall
                  event="Broadcast Call"
                  icon="campaign"
                  color="warning"
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  color="error"
                  style={{ minHeight: "55px" }}
                  onClick={() => onEmergencyClick()}
                  endIcon={<Icon>emergency_icon</Icon>}
                  fullWidth
                >
                  Public Emergency
                </MDButton>
              </Grid>

              <Divider></Divider>
              <Grid item xs={12}>
                <MDButton
                  color="warn"
                  style={{ minHeight: "55px" }}
                  onClick={navigateToLocation}
                  endIcon={<Icon>my_location</Icon>}
                  fullWidth
                >
                  Navigate to home location
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  color={locationUpdateColor}
                  onClick={lockOnCurrentLocation}
                  endIcon={<Icon>maps_home_work</Icon>}
                  fullWidth
                  style={{ minHeight: "55px" }}
                >
                  Anchor to current location as home
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="sm" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Send Message
        </DialogTitle>
        <DialogContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          style={{ paddingTop: "10px" }}
        >
          <MDBox sx={{ width: 500 }}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              variant="outlined"
              label="Type Message"
              value={message}
              onChange={handleTrainMessageChange}
              multiline
              rows={6}
            />
          </MDBox>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={onMessageSend} color="success" autoFocus>
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}
