import { Padding } from "@mui/icons-material";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import CTLConferencePhone from "application/components/CTLConferencePhone";
import CTLConferenceUserList from "application/components/CTLConferenceUserList";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DefaultItem from "features/Items/DefaultItem";
import { useEffect, useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";

function NextEvents({ data, phoneType, setPhoneType, conferenceType, setConferenceType, handleUserEventsClick }) {
  const currentDate = new Date();
  const [controller, dispatch] = useMaterialUIController();
  const { callState, clickToCall, conferenceData } = controller;
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectConference, setSelectConference] = useState();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

const filteredAndSortedData = data
  .filter((item) => {
    const scheduledEndTime = new Date(
      `${item.scheduledToDate}T${item.toTime}`
    );

    const currentDateTime = new Date(currentDate);
    return scheduledEndTime >= currentDateTime;
  })
  .sort((a, b) => {
    const dateTimeA = new Date(`${a.scheduledToDate}T${a.toTime}`);
    const dateTimeB = new Date(`${b.scheduledToDate}T${b.toTime}`);
    return dateTimeA - dateTimeB;
  });

  const convertTimeTo12HourFormat = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };

  function getFirstTenLetters(str) {
    if (str.length <= 15) {
      return str;
    } else {
      return str.slice(0, 15) + "...";
    }
  }

  const handleClose = () => {
    setOpen(false);
    setFileName("");
  };

  const handleChange = (value) => {
    setFileName(value);
  };

const handleRecordingAction = (data) => {
  if (data) {
    const currentDate = new Date(); 
    const scheduledFromDate = new Date(
      data.meetingScheduledDate + " " + data.fromTime
    );
    const scheduledToDate = new Date(
      data.scheduledToDate + " " + data.toTime
    ); 

    if (currentDate >= scheduledFromDate && currentDate <= scheduledToDate) {
      if (data.recording) {
        let formData = {
          state: "stop",
          accessCode: data.accessCode,
        };
        application
          .post("conferenceRoom/recording", formData)
          .then((response) => {
            setNotify({
              isOpen: true,
              message: response.data.messageDetail,
              type: "success",
              pagename: "Conference Recording",
              status: response.data.message,
            });
          })
          .catch((error) => {
            setNotify({
              isOpen: true,
              message: error.response.data.messageDetail,
              type: "error",
              pagename: "Conference Recording",
              status: error.response.data.message,
            });
          });
      } else {
        setOpen(true);
        setSelectConference(data);
      }
    } else {
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: "Conference Recording",
        status:
          "The meeting has not started yet.Please wait until the meeting start",
      });
    }
  }
};


  const handleRecordingStart = () => {
    let formData = {
      state: "start",
      fileName: fileName,
      accessCode: selectConference.accessCode,
    };
    application
      .post("conferenceRoom/recording", formData)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Conference Recording",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: "Conference Recording",
          status: error.response.data.message,
        });
      });
      setOpen(false);
      setFileName("");
  };

  return (
    <Card
      style={{
        height: "calc(100vh - 150px)",
        overflow: "auto",
        position: "relative",
      }}
    >
      <CTLConferencePhone
        phoneType={phoneType}
        setPhoneType={setPhoneType}
        conferenceType={conferenceType}
        setConferenceType={setConferenceType}
      />
      {AuthenticationService.allowConferenceMenus() ||
      AuthenticationService.allowUCMenus() ? (
        <>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Upcoming Conference
            </MDTypography>
          </MDBox>
          <MDBox>
            {filteredAndSortedData.map((item, index) => {
              let color = "dark";
              const currentDateFormatted = currentDate
                .toISOString()
                .slice(0, 10);
              const eventDateFormatted = new Date(item.scheduledToDate)
                .toISOString()
                .slice(0, 10);
              if (eventDateFormatted === currentDateFormatted) {
                color = "success";
              } else if (eventDateFormatted > currentDateFormatted) {
                const oneWeekLater = new Date();
                oneWeekLater.setDate(oneWeekLater.getDate() + 7);
                if (new Date(eventDateFormatted) <= oneWeekLater) {
                  color = "warning";
                } else {
                  color = "info";
                }
              } else if (eventDateFormatted < currentDateFormatted) {
                color = "error";
              }

              return (
                <Grid item xs={12} key={index}>
                  <MDBox mb={1.5}>
                    <Card>
                      <MDBox display="flex" pt={1} px={1}>
                        <Tooltip title={"join"}>
                          <MDBox
                            variant="gradient"
                            bgColor={color}
                            color={color === "light" ? "dark" : "white"}
                            coloredShadow={color}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="2rem"
                            height="2rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleUserEventsClick(item)}
                          >
                            <Icon fontSize="medium" color="inherit">
                              call_icon
                            </Icon>
                          </MDBox>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title={item.roomName}>
                          <MDTypography variant="h6" color="text">
                            {getFirstTenLetters(item.roomName)}
                          </MDTypography>
                        </Tooltip>
                        <div style={{ flexGrow: 1 }}></div>
                        <MDTypography variant="caption" color="text">
                          {convertTimeTo12HourFormat(item.fromTime)}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                          - {convertTimeTo12HourFormat(item.toTime)}
                        </MDTypography>
                      </MDBox>
                      <Divider />
                      <MDBox
                        m={1}
                        mt={-1}
                        style={{ height: "25px" }}
                        display="flex"
                      >
                        <MDTypography
                          component="p"
                          variant="caption"
                          color="text"
                        >
                          {`Created By - ${getFirstTenLetters(
                            item.createdBy ==
                              AuthenticationService.getUserName()
                              ? "You"
                              : item.createdBy
                          )}`}
                        </MDTypography>
                        <div style={{ flexGrow: 1 }}></div>
                        {item.createdBy ==
                          AuthenticationService.getUserName() && (
                          <Tooltip title="Recording">
                            <>
                              <MDTypography
                                component="p"
                                variant="caption"
                                color="text"
                              >
                                {item.recording
                                  ? "Recording Started"
                                  : "Recording Stopped"}
                              </MDTypography>
                              <IconButton
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRecordingAction(item)}
                              >
                                <Icon
                                  fontSize="medium"
                                  color={item.recording ? "success" : "error"}
                                >
                                  radio_button_checked_icon
                                </Icon>
                              </IconButton>
                            </>
                          </Tooltip>
                        )}
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              );
            })}
          </MDBox>
        </>
      ) : (
        <>
          {/* <CTLConferenceUserList
            conferenceData={conferenceData}
            phoneType={"participant"}
          /> */}
        </>
      )}

      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
          })}
        ></DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <Grid item xs={12}>
            <TextField
              label="Recording File Name"
              type="text"
              fullWidth
              variant="standard"
              value={fileName}
              onChange={(event) => handleChange(event.target.value)}
              required
            />
          </Grid>
        </DialogContent>

        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={() => handleClose()} color="secondary">
            Cancel
          </MDButton>

          <MDButton
            color="success"
            onClick={() => handleRecordingStart()}
            disabled={fileName == ""}
          >
            Start Recording
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

export default NextEvents;
