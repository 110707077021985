const geofenceMenus = [
    {
      icon: "display_settings_icon",
      key: "geoFence",
      name: "geoFence",
      url: "home/app/geofence",
      description: "geofencing Configuration",
      disabled: false,
      productionReady: false,
    },
  ];
  
  export { geofenceMenus };
  