import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  TextField,
  ListItem,
  Autocomplete,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

const NominatimBaseURL = "https://nominatim.openstreetmap.org/search";
const SearchOnMap = ({ currentPage, setCurrentLocation, mapRef }) => {
  const [query, setQuery] = useState(""); // Search query state

  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = useCallback(async (value) => {
    if (value.length > 2) {
      const { data } = await axios.get(NominatimBaseURL, {
        params: {
          q: value,
          format: "json",
          addressdetails: 1,
          limit: 5,
        },
      });

      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  }, []);

  // Handle location selection

  const handleSelectLocation = (place) => {
    if (place) {
      const { lat, lon, boundingbox, display_name } = place;

      const newLocation = [parseFloat(lat), parseFloat(lon), display_name];

      setCurrentLocation(newLocation);

      let zoomLevel = 13; // Default zoom level

      if (boundingbox) {
        const latDiff = Math.abs(boundingbox[0] - boundingbox[1]);

        const lonDiff = Math.abs(boundingbox[2] - boundingbox[3]);

        // Adjust zoom level based on the size of the bounding box

        if (latDiff < 0.1 && lonDiff < 0.1) {
          zoomLevel = 15; // Zoom in for smaller areas like towns
        } else if (latDiff < 1 && lonDiff < 1) {
          zoomLevel = 12; // Medium zoom for cities
        } else {
          zoomLevel = 3; // Zoom out for larger areas like countries
        }
      }

      if (currentPage === "Map" && mapRef.current) {
        mapRef.current.setView(newLocation, zoomLevel);
      }

      setQuery(place.display_name);
    }
  };

  // Handle search button click or Enter key press

  const handleSearch = async () => {
    if (query) {
      const { data } = await axios.get(NominatimBaseURL, {
        params: {
          q: query,

          format: "json",

          addressdetails: 1,

          limit: 1,
        },
      });

      if (data.length > 0) {
        handleSelectLocation(data[0]);
      }
    }
  };
  return (
    <Box
      sx={{
        width: currentPage === "Home" ? "100%" : 300,

        display: "flex",

        justifyContent: "space-between",

        alignItems: "center",
      }}
    >
      <Autocomplete
        freeSolo
        options={suggestions}
        getOptionLabel={(option) => option.display_name || ""}
        onInputChange={(_, newValue) => {
          setQuery(newValue);
          fetchSuggestions(newValue);
        }}
        onChange={(_, newValue) => {
          if (newValue && typeof newValue === "object") {
            handleSelectLocation(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={
              currentPage === "Home"
                ? "Set Default Location"
                : "Search location"
            }
            size={currentPage === "Home" ? "small" : "small"}
            variant="outlined"
            value={query}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            // InputProps={{
            //   style: { paddingBlock: "8px" },
            // }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.place_id}>
            {option.display_name}
          </ListItem>
        )}
        sx={{ flexGrow: 9 }} // Adjusts width to 90%
      />

      {currentPage === "Map" && (
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            ml: 1,

            minWidth: "auto",

            width: "10%", // Adjusts width to 10%

            // height: "40px",

            p: 0,
          }}
        >
          <SearchIcon />
        </Button>
      )}
    </Box>
  );
};

export default SearchOnMap;
