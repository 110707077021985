import CTLParticipantsAction from "application/components/CTLParticipantActions";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 2,
  tabSize: 4,
  modes: [],
};



const getColumns = () => {
  return [
    {
      Header: "Participant Name",
      accessor: "participantName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
    },
    {
      Header: "Extension Number",
      accessor: "extensions",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      // controlType: "select",
      dataType: "string",
      // options: {
      //   keyField: "extension",
      //   keyDescription: "extension",
      //   values: [],
      //   url: "/conferenceParticipant/list/extension",
      // },

      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: false,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
             />
      ),
    },
  ];
};

export { getColumns, Attributes };
