const { client, xml } = require("@xmpp/client");
const debug = require("@xmpp/debug");
const AuthenticationService =
  require("application/modules/authentication/AuthenticationService").default;
const loggerFactory = require("globals/logger/logger-factory").default;

const uniqueResource = `example-${Date.now()}`;
const domain = "xmpp.coraltele.com";
let currentStatus = "Connecting";
let xmpp = null;
let onlineUsers = new Map();
let notificationCallback = null;

const setNotificationCallback = (callback) => {
  notificationCallback = callback;
};

const createClient = () => {
  return new client({
    service: "wss://xmpp.coraltele.com:5281/xmpp-websocket",
    domain: domain,
    resource: uniqueResource,
    username: AuthenticationService.getFullName(),
    password: "12345",
  });
};

const startConnection = async () => {
  if (AuthenticationService.allowChat()) {
    xmpp = createClient();
    debug(xmpp, true);

    xmpp.on("error", (err) => {
      loggerFactory.error("XMPP Error:", err);
    });

    xmpp.on("offline", () => {
      currentStatus = "Offline";
      loggerFactory.debug("XMPP client is offline");
      setTimeout(startConnection, 5000);
    });

    xmpp.on("stanza", async (stanza) => {
      // Handle presence
      if (stanza.is("presence")) {
        const from = stanza.attrs.from;
        const type = stanza.attrs.type;
        const userName = from.split("@")[0];
        if (type !== "unavailable") {
          onlineUsers.set(userName, from);
        } else {
          onlineUsers.delete(userName);
        }
      }

      // Handle messages
      if (stanza.is("message")) {
        const bodyElement = stanza.getChild("body");
        if (bodyElement) {
          const bodyContent = bodyElement.getText();
          if (bodyContent) {
            try {
               const sanitizedContent = bodyContent.replace(
                 /[\x00-\x1F\x7F]/g,
                 ""
               );
              const data = JSON.parse(sanitizedContent);
               if (data && data.type && data.text && data.from && data.id && data.time && data.date && data.contentType) {
              const newMessage = {
                type: data.type,
                text: data.text,
                from: data.from,
                id: data.id,
                time: data.time,
                date: data.date,
                contentType: data.contentType,
              };
              if (notificationCallback) {
                notificationCallback(newMessage); 
              }
            }
            } catch (error) {
              loggerFactory.debug("Failed to parse message body as JSON:", error);
            }
          }
        }
      }
    });

    xmpp.on("online", async (address) => {
      currentStatus = "Online";
      loggerFactory.debug(`XMPP Connected as ${address.toString()}`);
      await xmpp.send(xml("presence"));
    });

    xmpp.on("status", (status) => {
      loggerFactory.debug("XMPP Status:", status);
    });

    try {
      await xmpp.start();
    } catch (error) {
      loggerFactory.error("Failed to start XMPP client:", error);
      setTimeout(startConnection, 5000);
    }
  } 
};

startConnection();

module.exports = {
  xmpp,
  domain,
  uniqueResource,
  setNotificationCallback,
  getCurrentStatus: () => currentStatus,
  getOnlineUsers: () => Array.from(onlineUsers.entries()),
};