import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import {
  useMaterialUIController,
  setActivePage,
  setIsAutoAnswer,
} from "context";

function ChangeUserProfile({ onLoad, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [formData, setFormData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        ExitPage: ExitPage,
      });
  }, [activePage]);

  const UserName = AuthenticationService.getUserName();

  const requestBodyData = {
    sortDirection: "desc",
    sortBy: "username",
    search: "",
    sortDataType: "String",
    advancedFilters: [
      {
        direction: "from",
        dataType: "String",
        fieldName: "username",
        value: UserName,
      },
      {
        direction: "to",
        dataType: "String",
        fieldName: "username",
        value: UserName,
      },
    ],
  };

  useEffect(() => {
    application.post(operationURLS.list, requestBodyData).then((response) => {
      let dataToDisplay = response.data.data.currentPageData[0];
      if (dataToDisplay.dispatcherAutoAnswer) {
        setIsAutoAnswer(dispatch, true);
      } else {
        setIsAutoAnswer(dispatch, false);
      }
      setFormData(dataToDisplay);
    });
  }, []);

  function onSaveForm(data) {
    console.log("Data to update", data);
    application
      .post(operationURLS.update, data)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
        });
        if (data?.dispatcherAutoAnswer == false) {
          window.sessionStorage.setItem(
            `${window.location.hostname}-coral-X-Answer-Ring-Count`,
            "0"
          );
        } else {
          window.sessionStorage.setItem(
            `${window.location.hostname}-coral-X-Answer-Ring-Count`,
            data?.ringAnswerCount
          );
        }
        if (data?.publicEmergencyNumber) {
          window.sessionStorage.setItem(
            `${window.location.hostname}-coral-X-Public-Emergency-Number`,
            data.publicEmergencyNumber
          );
        } else {
          window.sessionStorage.setItem(
            `${window.location.hostname}-coral-X-Public-Emergency-Number`,
            ""
          );
        }
         if (data?.broadcastNumber) {
           window.sessionStorage.setItem(
             `${window.location.hostname}-coral-X-Broadcast-Number`,
             data.broadcastNumber
           );
         } else {
           window.sessionStorage.setItem(
             `${window.location.hostname}-coral-X-Broadcast-Number`,
             ""
           );
         }
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  }

  // exit page
  function ExitPage() {
    activePage.key = "agentDashboard";
    activePage.name = "Agent Dashboard";
    activePage.url = "home/app/agent dashboard";
    activePage.description = "Agent Dashboard";
    setActivePage(dispatch, activePage);
  }
  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Nodes"
        columns={getColumns()}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default ChangeUserProfile;
