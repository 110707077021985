import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import ChatIcon from "@mui/icons-material/Chat";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DevicesIcon from "@mui/icons-material/Devices";

function DispatcherActionIcon({ row, value }) {

  const isLogin = (row) => row.status?.includes("Login");
  const isLogOut = (row) => row.status?.includes("Logout");
  
  const isMissedCall = (row) =>
    row.event?.includes("call") && row.eventStatus?.includes("Missed");
  const isReceivedCall = (row) =>
    row.event?.includes("call") && row.eventCategory?.includes("Incoming");
  const isDialCall = (row) =>
    row.event?.includes("call") && row.eventCategory?.includes("Dial");

  const isEmergencyCall = (row) =>
    row.event?.includes("event") && row.eventCategory?.includes("Conference");
  const isDragCall = (row) =>
    row.event?.includes("event") && row.eventCategory?.includes("Transfer");
    const isDeviceAccess = (row) =>
      row.event?.includes("event") && row.eventCategory?.includes("Access");
  const isAnnounceCall = (row) =>
    row.event?.includes("event") && row.eventCategory?.includes("Announcement");


  const isMessage = (row) => row.event?.includes("message");


  return (
    <MDTypography variant="caption" fontWeight="large" color="text">
      {isMessage(row.original) && (
        <Icon color="info" fontSize="large">
          <ChatIcon></ChatIcon>
        </Icon>
      )}
      {isMissedCall(row.original) && (
        <Icon color="error" fontSize="large">
          <CallMissedIcon></CallMissedIcon>
        </Icon>
      )}

      {isEmergencyCall(row.original) && (
        <Icon color="error" fontSize="large">
          <WarningAmberIcon></WarningAmberIcon>
        </Icon>
      )}

      {isDialCall(row.original) && (
        <Icon color="success" fontSize="large">
          <CallMadeIcon></CallMadeIcon>
        </Icon>
      )}

      {!isMissedCall(row.original) && isReceivedCall(row.original) && (
        <Icon color="info" fontSize="large">
          <CallReceivedIcon></CallReceivedIcon>
        </Icon>
      )}
      {isDragCall(row.original) && (
        <Icon color="primary" fontSize="large">
          <ShowChartIcon></ShowChartIcon>
        </Icon>
      )}
      {isAnnounceCall(row.original) && (
        <Icon color="warning" fontSize="large">
          <CampaignIcon></CampaignIcon>
        </Icon>
      )}
      {isLogOut(row.original) && (
        <Icon color="error" fontSize="large">
          <LogoutIcon></LogoutIcon>
        </Icon>
      )}
      {isDeviceAccess(row.original) && (
        <Icon color="info" fontSize="large">
          <DevicesIcon></DevicesIcon>
        </Icon>
      )}
      {isLogin(row.original) && (
        <Icon color="success" fontSize="large">
          <LoginIcon></LoginIcon>
        </Icon>
      )}
    </MDTypography>
  );
}

export default DispatcherActionIcon;
