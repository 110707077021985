import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ContentCopy, Delete, DeleteForever, Edit, KeyboardArrowDown, Star, StarBorder } from '@mui/icons-material';
import { useMaterialUIController } from 'context';
import MDTypography from 'components/MDTypography';

export default function DropDown({ handleDelete, handleSetDefault, isDefault }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { darkMode } = useMaterialUIController()[0];
    return (
        <Box>
            <Typography variant='p'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ cursor: 'pointer' }}
            >
                <KeyboardArrowDown />
            </Typography>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleSetDefault();
                    handleClose();
                }}>
                    {isDefault ? (
                        <Star fontSize="small" sx={{ mr: 1, color: 'gold' }} />
                    ) : (
                        <StarBorder fontSize="small" sx={{ mr: 1 }} />
                    )}
                    {isDefault ? 'Default Language' : 'Set as Default'}
                </MenuItem>
                <MenuItem onClick={() => {
                    handleDelete();
                    handleClose();
                }}>
                    <Delete fontSize="small" sx={{ mr: 1, color: 'red' }} />
                    Delete
                </MenuItem>
            </Menu>
        </Box>
    );
}
