import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
} from "./styles/sidebarCollapse";

import { useMaterialUIController } from "context";
import { useState } from "react";

function SidebarCollapse({
  icon,
  badge,
  name,
  children,
  active,
  noCollapse,
  open,
  count,
  noHide,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { transparentSidenav, whiteSidenav, darkMode } = controller;
  const theme = useTheme();
  const [NotificationSeen, setNotificationSeen] = useState(false);

  const handleClickItem = () => {
    {!noHide &&
    setNotificationSeen(true);
    }
  };
  return (
    <>
      {count ? (
         (!NotificationSeen ? (
        <ListItem component="li"  onClick={handleClickItem}  >
          
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
              
              })
            }
          
          >
             
            <ListItemIcon   
              sx={(theme) =>
                collapseIconBox(theme, {
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                })
              }
            >
              <Badge
                badgeContent={!NotificationSeen ? badge : null}
                color="error" 
              />  
                  <Icon
                    sx={(theme) =>
                      collapseIcon(theme, {
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                      })
                    }
                  >
                    {icon}
                  </Icon>
            </ListItemIcon>
              
          </MDBox>
        </ListItem>
          ) : (
           null
          ))
      ) : <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
       }
      >
  
        <ListItemIcon  
          sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
        >
    

{typeof icon === "string" ? (
  <Icon sx={(theme) => collapseIcon(theme, { transparentSidenav, whiteSidenav, darkMode })}>{icon}</Icon> 
) : (
 icon
)}
   
        </ListItemIcon>
        </MDBox>
        </ListItem> }
      <MDBox></MDBox>

      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

SidebarCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

SidebarCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidebarCollapse;
