import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDTypography from "components/MDTypography";
import {
  Select,
  Checkbox,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
function CTLMultipleSelectWithString({
  label,
  name,
  value,
  onChange,
  error,
  success,
  url,
  options,
  keyField,
  keyDescription,
}) {
  const [listOptions, setListOptions] = useState(options);
  const [selected, setSelected] = useState(
    Array.isArray(value) ? value : value.split(",")
  );

  const isAllSelected =
    listOptions.length > 0 && selected.length === listOptions.length;

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    if (selectedValues[selectedValues.length - 1] === "all") {
      const newSelected = isAllSelected
        ? []
        : listOptions.map((option) => option[keyField]);
      setSelected(newSelected);
      const formattedValues = isAllSelected ? "" : newSelected.join(",");
      onChange && onChange({ target: { value: formattedValues, name } });
      return;
    }
    setSelected(selectedValues);
    const formattedValues = selectedValues.join(",");
    onChange && onChange({ target: { value: formattedValues, name } });
  };
  useEffect(() => {
    setSelected(Array.isArray(value) ? value : value.split(","));
  }, [value]);

  useEffect(() => {
    if (url) {
      application.post(url).then((response) => {
        setListOptions(response.data.data);
      });
    }
  }, [url]);

  return (
    <>
      <MDTypography variant="caption">{label}</MDTypography>
      <Select
        multiple
        fullWidth
        name={name}
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => {
          return (
            Array.isArray(selected) &&
            selected
              .map((selectedOption) => {
                const option = listOptions.find(
                  (opt) => opt[keyField] === selectedOption
                );
                return option ? option[keyDescription] : selectedOption;
              })
              .join(", ")
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              maxWidth: 100,
            },
          },
        }}
        style={{ height: 45 }}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox checked={isAllSelected} />
          </ListItemIcon>
          Select All
        </MenuItem>
        {listOptions.map((option) => (
          <MenuItem key={option[keyField]} value={option[keyField]}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option[keyField]) > -1} />
            </ListItemIcon>
            {option[keyDescription]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default CTLMultipleSelectWithString;
