// MUI components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

// Template components
import MDButton from "components/MDButton";

// Template context
import { useMaterialUIController } from "context";

function Confirmation({
  confirmationTitle,
  confirmationText,
  isConfirmationOpen,
  handleConfirmationClose,
  handleConfirm,
}) {
  const { darkMode } = useMaterialUIController()[0];

  const dialogStyles = (theme) => ({
    backgroundColor: darkMode
      ? theme.palette.background.default
      : theme.palette.light.main,
  });

  return (
    <Dialog
      maxWidth="xl"
      open={isConfirmationOpen}
      onClose={handleConfirmationClose}
    >
      <DialogTitle sx={dialogStyles}>{confirmationTitle}</DialogTitle>
      <DialogContent sx={dialogStyles}>
        <DialogContentText>{confirmationText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogStyles}>
        <MDButton variant="gradient" color="success" onClick={handleConfirm}>
          Yes
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={handleConfirmationClose}
        >
          No
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Confirmation;
