export default function getTimeFromTimestamp(timestamp) {
  const date = new Date(timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

//code for timezone specific time
// function getTimeFromTimestamp(timestamp) {
//   // Create a Date object from the timestamp
//   const date = new Date(timestamp);

//   // Options for formatting the time in the India timezone
//   const options = { 
//     timeZone: 'Asia/Kolkata', 
//     hour: '2-digit', 
//     minute: '2-digit', 
//     hour12: false // Use 24-hour format
//   };

//   // Get the time in HH:MM format for the specified time zone
//   const timeString = date.toLocaleTimeString('en-US', options);

//   return timeString;
// }

// // Example usage
// const timestamp = Date.now();
// const timeInIndia = getTimeFromTimestamp(timestamp);
// console.log(timeInIndia); // Outputs time in HH:MM format for India timezone
