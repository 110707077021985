import React, { useEffect, useState, useRef } from "react";
import { Stack, Popper, Grow, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { telemetry } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { useMaterialUIController } from "context";
export default function CollapsibleMenu({
  setOpenCollapsibleMenu,
  CollapsibleMenuOpen,
}) {
  const [mainDropdown, setMainDropdown] = useState(null);
  const [nestedDropdown, setNestedDropdown] = useState({});
  const [apiData, setApiData] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showData, setShowData] = useState(false);
  const [controller] = useMaterialUIController();
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const { darkMode } = controller;

  const menuRef = useRef(null);

  const fetchAlertList = () => {
    telemetry
      .get("dashboard/dashboardGroupList")
      .then((response) => {
        setApiData(response.data.data);
        setShowData(true);
      })
      .catch((error) => {
        loggerFactory.error("Unable to fetch list", error);
      });
    setShowData(true);
  };

  const handleIconClick = (event, apiData) => {
    setSelectedMenu(apiData);
    setMainDropdown(mainDropdown === apiData.name ? null : event.currentTarget);
    setNestedDropdown({ ...nestedDropdown, [apiData.name]: null });
  };

  const handleMouseEnter = (event, name) => {
    setNestedDropdownName(name);
    setNestedDropdownEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setOpenCollapsibleMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (event.target.classList.contains("sub-menu-item")) {
          return;
        }
        setOpenCollapsibleMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    fetchAlertList();
  }, []);

  return (
    <>
      <div ref={menuRef}>
        <MDBox
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          style={{ right: 0, top: "100px", position: "absolute" }}
          key={apiData.id}
        >
          <Stack
            spacing={1}
            direction="column"
            style={{
              maxHeight: "520px",
              overflowY: "auto",
            }}
          >
            {showData &&
              apiData
                .filter((data) => data.name === "main")
                .map((data) => (
                  <React.Fragment key={data.id}>
                    {data.dashboard.dashboards.map((dashboard) => (
                        <MDTypography
                          key={dashboard.widgetId}
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            CollapsibleMenuOpen(dashboard),
                              setOpenCollapsibleMenu(false);
                          }}
                          variant="button"
                          textTransform="capitalize"
                          minWidth="14rem"
                          fontWeight="regular"
                          py={1}
                          px={2}
                          sx={({
                            palette: { grey, dark },
                            borders: { borderRadius },
                          }) => ({
                            borderRadius: borderRadius.md,
                            cursor: "pointer",
                            transition: "all 300ms linear",
                            backgroundColor:
                              nestedDropdownName === dashboard.dashboardName
                                ? grey[500]
                                : "transparent",
                            color:
                              nestedDropdownName === dashboard.dashboardName
                                ? dark.main
                                : "text",
                            "&:hover": {
                              backgroundColor: grey[500],
                              color: dark.main,
                              "& *": {
                                color: dark.main,
                              },
                            },
                          })}
                        >
                          <Icon color="secondary">dashboard</Icon>&nbsp;&nbsp;
                          {dashboard.dashboardName}
                        </MDTypography>
                    ))}
                  </React.Fragment>
                ))}
            {showData &&
              apiData
                .filter((data) => data.name !== "main")
                .map((data) => (
                  <div key={data.id}>
                    <MDTypography
                      display="flex"
                      onMouseEnter={(e) => handleIconClick(e, data)}
                      alignItems="center"
                      variant="button"
                      textTransform="capitalize"
                      minWidth="14rem"
                      fontWeight="regular"
                      py={1}
                      px={2}
                      sx={({
                        palette: { grey, dark },
                        borders: { borderRadius },
                      }) => ({
                        borderRadius: borderRadius.md,
                        cursor: "pointer",
                        transition: "all 300ms linear",
                        backgroundColor:
                          nestedDropdownName === data.name
                            ? grey[500]
                            : "transparent",
                        color:
                          nestedDropdownName === data.name ? dark.main : "text",
                        "&:hover": {
                          backgroundColor: grey[500],
                          color: dark.main,
                          "& *": {
                            color: dark.main,
                          },
                        },
                      })}
                    >
                      <Icon color="secondary">list_icon</Icon>
                      &nbsp;&nbsp;
                      {data.name} &nbsp;&nbsp;
                      <Icon color="text">keyboard_arrow_right_icon</Icon>
                    </MDTypography>
                    {nestedDropdown[data.name] && (
                      <div>
                        {data.dashboard.dashboards.map((item, index) => (
                          <React.Fragment key={index}>
                            <MDTypography
                              display="flex"
                              alignItems="center"
                              variant="button"
                              textTransform="capitalize"
                              minWidth="14rem"
                              color="dark"
                              fontWeight="regular"
                              py={item.route ? 1 : 0.625}
                              px={2}
                              sx={({
                                palette: { grey, dark },
                                borders: { borderRadius },
                              }) => ({
                                borderRadius: borderRadius.md,
                                cursor: "pointer",
                                transition: "all 300ms linear",
                                "&:hover": {
                                  backgroundColor: grey[500],
                                  color: dark.main,
                                  "& *": {
                                    color: dark.main,
                                  },
                                },
                              })}
                              onClick={() => {
                                CollapsibleMenuOpen(item);
                              }}
                              className="sub-menu-item"
                            >
                              <Icon color="secondary">dashboard</Icon>
                              &nbsp;&nbsp;
                              {item.dashboardName}
                            </MDTypography>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
          </Stack>
        </MDBox>
      </div>

      {selectedMenu && (
        <Popper
          anchorEl={mainDropdown}
          open={Boolean(mainDropdown)}
          placement="right-start"
          transition
          style={{ zIndex: 999, 
              maxHeight: "400px",
              overflowY: "auto",
           }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          
          onClick={handleMouseLeave}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
            >
              <MDBox ml={2.5} mt={-2.5} borderRadius="lg">
                <MDBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
                  {selectedMenu.dashboard.dashboards.map((item, index) => (
                    <React.Fragment key={index}>
                      <MDTypography
                        display="flex"
                        alignItems="center"
                        variant="button"
                        textTransform="capitalize"
                        minWidth="14rem"
                        color="dark"
                        fontWeight="regular"
                        py={item.route ? 1 : 0.625}
                        px={2}
                        sx={({
                          palette: { grey, dark },
                          borders: { borderRadius },
                        }) => ({
                          borderRadius: borderRadius.md,
                          cursor: "pointer",
                          transition: "all 300ms linear",
                          "&:hover": {
                            backgroundColor: grey[500],
                            color: dark.main,
                            "& *": {
                              color: dark.main,
                            },
                          },
                        })}
                        onClick={() => {
                          CollapsibleMenuOpen(item);
                        }}
                        className="sub-menu-item"
                      >
                        <Icon color="secondary">dashboard</Icon>&nbsp;&nbsp;
                        {item.dashboardName}
                      </MDTypography>
                    </React.Fragment>
                  ))}
                </MDBox>
              </MDBox>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}
