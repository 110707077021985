import { createApi } from "@reduxjs/toolkit/query/react";
import { appServices } from "globals/endpoints";
import { application } from "globals/endpoints";

export const mediaCenterApi = createApi({
    reducerPath: "mediaCenterApi",
    baseQuery: async (args) => {
        try {
            let result;
            if (args.url.includes("/auth/mediaCenter/play")) {
                result = await appServices(args)
            } else {
                result = await application(args)
            }
            return result;
        } catch (axiosError) {
            return { error: axiosError.response?.data || axiosError.message };
        }
    },
    endpoints: (builder) => ({
        getLanguages: builder.mutation({
            query: (payload) => ({
                url: "ivr/languages",
                method: "POST",
                body: JSON.stringify(payload),
            }),
            transformResponse: (res) => res.data,
            invalidatesTags: [""],
        }),
        getAllMediaCenterJson: builder.mutation({
            query: ({ getDataUrl }) => ({
                url: getDataUrl,
                method: "GET",
                headers: {
                    "Content-type": "application/json"
                }
            })
        }),
        saveMediaCenterJson: builder.mutation({
            query: ({ content, path }) => ({
                url: path,
                method: "POST",
                data: content,
                headers: {
                    "Content-type": "application/json;",
                },
            }),
            invalidatesTags: ["saveForm"],
        }),
        saveMP3File: builder.mutation({
            query: ({ content, path }) => ({
                url: path,
                method: "POST",
                data: content,
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }),
        }),
        getMP3File: builder.query({
            query: (path) => ({
                url: path,
                method: 'GET',
                responseType: 'arraybuffer'
            }),
        }),
        getDropdownData: builder.mutation({
            query: (path) => {
                return {
                    url: path,
                    method: "POST",
                    headers: {
                        "Content-type": "application/json;",
                    },
                };
            },
        }),
        deleteAudioFile: builder.mutation({
            query: ({ content, path }) => ({
                url: path,
                method: "POST",
                data: content,
                headers: {
                    "Content-type": "application/json",
                },
            }),
        }),
    }),
});

export const {
    useGetAllMediaCenterJsonMutation,
    useGetFormDataMutation,
    useSaveFormDataMutation,
    useSaveMediaCenterJsonMutation,
    useGetDropdownDataMutation,
    useGetLanguagesMutation,
    useSaveMP3FileMutation,
    useGetMP3FileQuery,
    useDeleteAudioFileMutation
} = mediaCenterApi;
