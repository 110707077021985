import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { groupAdminMenu } from "globals/configuration/groupAdminMenus";
import { nmsMenus } from "globals/configuration/nms";
import { userMenus } from "globals/configuration/user-management";
import formControl from "globals/form-control";
const groupAdmin = AuthenticationService.allowGroupAdmin();

const Attributes = {
  formSize: "lg",
  enableAddButton: true,
  modes: groupAdmin
    ? groupAdminMenu.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      })
    : userMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "userGroupMaster/list",
  update: "userGroupMaster/update",
  create: "userGroupMaster/create",
  delete: "userGroupMaster/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Group Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: formControl.string().required("Required"),
      width: 4,
    },
    {
      Header: "Group Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      width: 4,
    },
    {
      Header: "Group Owner",
      accessor: "groupOwner",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
      controlType: "select",
      options: {
        keyField: "user",
        keyDescription: "user",
        values: [],
        url: "/userRole/listAll/taskManager",
      },
    },
    {
      Header: "Active",
      accessor: "active",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      width: 4,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
