import { configureStore } from "@reduxjs/toolkit";
import { coralApi } from "./apiSlice";
import { dynamicFormApi } from "./dynamicFormApiSlice";
import { customerSupportApi } from "./customerSupportApiSlice";
import { hospitalapi } from "./hospitalSlice";
import { geofenceApi } from "./geofenceSlice";
import { mediaCenterApi } from "./mediaCenterApiSlice";
// import { webSocketApi } from "./socketApiSlice";
// import { coralDummyApi } from "./coralDummyApiSlice";

export const store = configureStore({
  reducer: {
    [coralApi.reducerPath]: coralApi.reducer,
    [dynamicFormApi.reducerPath]: dynamicFormApi.reducer,
    [customerSupportApi.reducerPath]: customerSupportApi.reducer,
    [hospitalapi.reducerPath]: hospitalapi.reducer,
    [geofenceApi.reducerPath]: geofenceApi.reducer,
    [mediaCenterApi.reducerPath]: mediaCenterApi.reducer,
    // [webSocketApi.reducerPath]: webSocketApi.reducer,
    // [coralDummyApi.reducerPath]: coralDummyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      coralApi.middleware,
      dynamicFormApi.middleware,
      customerSupportApi.middleware,
      hospitalapi.middleware,
      geofenceApi.middleware,
      mediaCenterApi.middleware,
      // webSocketApi.middleware,
      // coralDummyApi.middleware,
    ),
});
