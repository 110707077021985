import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Calendar as BigCalendar,
  CalendarProps,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useMaterialUIController } from "context";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  TextField,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import WorkIcon from "@mui/icons-material/Work";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import SquareIcon from "@mui/icons-material/Square";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "./index.css";
import MDTypography from "components/MDTypography";
import colors from "assets/theme-dark/base/colors";
import { Phone } from "@mui/icons-material";
import AlertDelete from "../AlertsDialogs/AlertDelete";

const localizer = momentLocalizer(moment);

const formFontSize = {
  fontSize: "0.7em",
};

const radioTextSize = {
  fontSize: "1em",
};
const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const flexSpace = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export default function Calendar({
  folders,
  setFolders,
  currentFolder,
  schedules,
  setSchedules,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const clickRef = useRef(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [dailyEvents, setDailyEvents] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [events, setEvents] = useState(
    () =>
      schedules.filter((event) =>
        currentFolder?.scheduleIds?.includes(event.id)
      ) || []
  );
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const [changeInput, setChangeInput] = useState({
    workingHoursType: "Working Hours",
    title: "",
    repeat: false,
    start: "",
    end: "",
    forwardTo: "Default",
    extensionType: "",
    OverrideEventsIds: [],
  });
  const [repeat, setRepeat] = useState(false);
  const [editOption, setEditOption] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [currentMonthDates, setCurrentMonthDates] = useState({
    start: "",
    end: "",
  });

  const iconStyles = {
    fontSize: "1.4em",
    color: darkMode ? "#ffffff" : "#000000",
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
  };

  console.log("Events events", events);

  const buildMessage = (slotinfo) => {
    return "SloTInfo in the code" + slotinfo;
  };

  useEffect(() => {
    /**
     * What Is This?
     * This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  const onSelectSlot = useCallback((slotInfo) => {
    /**
     * Here we are waiting 250 milliseconds prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setSelectedSlot(slotInfo);
      setIsEditing(true);
      setChangeInput({
        ...changeInput,
        start: slotInfo?.start,
        end: slotInfo?.end,
      });
      // window.alert(buildMessage(slotInfo));
    }, 250);
  }, []);

  const onSelectEvent = useCallback(
    (eventInfo) => {
      window.clearTimeout(clickRef?.current);
      clickRef.current = window.setTimeout(() => {
        const eventDetails = dailyEvents?.filter(
          (item) => item.id === eventInfo?.id
        );
        setSelectedSlot(eventDetails[0]);
        setOpenEdit(false);
      }, 250);
    },
    [dailyEvents]
  );

  const handleChange = (e) => {
    let { name, value } = e.target;
    const newChangeInput = { ...changeInput };

    // Update the state object based on the input
    newChangeInput.forwardTo =
      value === "Non-Working Hours"
        ? "VoiceMail"
        : value === "Working Hours"
        ? "Default"
        : newChangeInput.forwardTo;

    if (name === "ends-radio-button") {
      newChangeInput.repeat = {
        ...newChangeInput.repeat,
        endstype: value,
      };
    } else {
      newChangeInput[name] = value;
    }

    // Update the state once with the new object
    setChangeInput(newChangeInput);
  };

  const handleEditOption = (e) => {
    setEditOption(e.target.value);
  };

  const handleEdit = () => {
    setOpenEdit((prev) => !prev);
    setEditOption("Override");
  };

  const closeEventDetailDialog = () => {
    setSelectedSlot(null);
    setEditOption("");
  };

  const repeatChange = (e) => {
    const repeatChecked = e.target.checked;
    setRepeat(repeatChecked);

    if (repeatChecked) {
      const repeatList = {
        days: [
          {
            day: "Sunday",
            marked: true,
          },
          {
            day: "Monday",
            marked: true,
          },
          {
            day: "Tuesday",
            marked: true,
          },
          {
            day: "Wednesday",
            marked: true,
          },
          {
            day: "Thursday",
            marked: true,
          },
          {
            day: "Friday",
            marked: true,
          },
          {
            day: "Saturday",
            marked: true,
          },
        ],
        endstype: "Never",
        endsDate: "",
      };
      setChangeInput({
        ...changeInput,
        repeat: repeatList,
      });
    } else {
      setChangeInput({
        ...changeInput,
        repeat: repeatChecked,
      });
    }
  };

  const handleSaveEvent = () => {
    const startDate = changeInput?.start?.$d
      ? changeInput?.start?.$d
      : changeInput?.start;

    const endDate =
      changeInput?.repeat?.endstype === "Never"
        ? moment("Fri Dec 31 2099 23:59:59 GMT+0530 (India Standard Time)")
            .clone()
            .set({
              hour: moment(changeInput?.end).hour(),
              minute: moment(changeInput?.end).minute(),
              second: moment(changeInput?.end).second(),
            })
        : changeInput?.end?.$d
        ? changeInput?.end?.$d
        : changeInput?.end;

    const newEventId = uuidv4();
    const newEvent = {
      ...changeInput,
      id: newEventId,
      start: moment(startDate).toDate(),
      end: moment(endDate).toDate(),
      title: changeInput?.title,
      assignedExtensionId: currentFolder?.id,
      extensionName: currentFolder?.name,
    };

    console.log("edit Option", editOption, openEdit);
    if (changeInput?.id) {
      if (editOption === "Override") {
        const updatedScheduleEvents = schedules?.map((event) =>
          event.id === changeInput?.parentEventId
            ? {
                ...event,
                OverrideEventsIds: [
                  ...event?.OverrideEventsIds,
                  newEventId,
                ] || [newEventId],
              }
            : event
        );

        const updatedEvents = events?.map((event) =>
          event.id === changeInput?.parentEventId
            ? {
                ...event,
                OverrideEventsIds: [
                  ...event?.OverrideEventsIds,
                  newEventId,
                ] || [newEventId],
              }
            : event
        );
        const updatedSchedule = [...updatedScheduleEvents, newEvent];
        const updated = [...updatedEvents, newEvent];
        setSchedules(updatedSchedule);
        setEvents(updated);
        setFolders((prevFolders) => {
          const updatedFolders = prevFolders.map((folder) =>
            updateFolderAndChildren(folder, currentFolder, newEventId)
          );
          return updatedFolders;
        });
      }

      if (editOption !== "Override") {
        if (currentFolder?.id === changeInput?.assignedExtensionId) {
          setSchedules((prevSchedules) =>
            prevSchedules.map((event) =>
              event.id === changeInput?.id ? changeInput : event
            )
          );
          setEvents((prev) =>
            prev.map((event) =>
              event.id === changeInput?.id ? changeInput : event
            )
          );
        } else {
          setSchedules([...schedules, newEvent]);
          setEvents((prevEvent) =>
            prevEvent.map((event) =>
              event.id === changeInput?.id ? newEvent : event
            )
          );
          setFolders((prevFolders) => {
            const updatedFolders = prevFolders.map((folder) =>
              updateScheduleIdsInFolderAndChildren(
                folder,
                currentFolder,
                changeInput.id,
                newEventId
              )
            );
            return updatedFolders;
          });
        }
      }
    } else {
      setEvents([...events, newEvent]);
      setSchedules([...schedules, newEvent]);
      setFolders((prevFolders) => {
        const updatedFolders = prevFolders.map((folder) =>
          updateFolderAndChildren(folder, currentFolder, newEventId)
        );
        return updatedFolders;
      });
    }

    setSelectedSlot(null);
    setIsEditing(false);
    setChangeInput({
      workingHoursType: "Working Hours",
      title: "",
      repeat: false,
      start: "",
      end: "",
      forwardTo: "Default",
      extensionType: "",
    });
  };

  const updateFolderAndChildren = (folder, currentFolder, newEventId) => {
    if (folder.id === currentFolder.id) {
      return updateScheduleIdsInFolder(folder, newEventId);
    }

    if (folder.children) {
      return {
        ...folder,
        children: folder.children.map((child) =>
          updateFolderAndChildren(child, currentFolder, newEventId)
        ),
      };
    }

    return folder;
  };

  const updateScheduleIdsInFolder = (folder, scheduleId) => {
    console.log("scheduleId", scheduleId);

    const updateFolder = (item) => {
      // Add scheduleId to the current folder
      const updatedFolder = {
        ...item,
        scheduleIds: item.scheduleIds
          ? [...item.scheduleIds, scheduleId]
          : [scheduleId],
      };

      // Recursively update children if they exist
      if (item.children) {
        updatedFolder.children = item.children.map(updateFolder);
      }
      return updatedFolder;
    };

    return updateFolder(folder);
  };

  const updateScheduleIdsInFolderAndChildren = (
    folder,
    currentFolder,
    oldEventId,
    newEventId
  ) => {
    if (folder.id === currentFolder.id) {
      return changeExistingScheduleId(
        folder,
        currentFolder,
        oldEventId,
        newEventId
      );
    }

    if (folder.children) {
      return {
        ...folder,
        children: folder.children.map((child) =>
          updateScheduleIdsInFolderAndChildren(
            child,
            currentFolder,
            oldEventId,
            newEventId
          )
        ),
      };
    }

    return folder;
  };

  const changeExistingScheduleId = (
    folder,
    currentFolder,
    oldEventId,
    newEventId
  ) => {
    const updateFolder = (item) => {
      // Add scheduleId to the current folders
      const updatedFolder = {
        ...item,
        scheduleIds: folder.scheduleIds.map((scheduleId) =>
          scheduleId === oldEventId ? newEventId : scheduleId
        ),
      };
      console.log("updatedFolder", updatedFolder);

      // Recursively update children if they exist
      if (item.children) {
        updatedFolder.children = item.children.map(updateFolder);
      }
      return updatedFolder;
    };

    return updateFolder(folder);
  };

  const daysConfig = (day, singleEvent) => {
    const dayConfig = singleEvent?.repeat?.days?.find((d) => d.day === day);
    return dayConfig ? dayConfig.marked : false;
  };

  const datesConfig = (date, singleEvent) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return singleEvent?.missingDates?.includes(formattedDate);
    // return dayConfig ? dayConfig.marked : false;
  };

  const OverridenDatesConfig = (date, overriddenDates) => {
    const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const formattedMomentDate = moment(formattedDate, "YYYY-MM-DD");

    for (let range of overriddenDates) {
      const assignedExtensionId = range?.assignedExtensionId;
      if (assignedExtensionId !== currentFolder?.id) {
        return false;
      }
      const beginDate = moment(range.beginDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      const endDate = moment(range.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");

      if (formattedMomentDate.isBetween(beginDate, endDate, null, "[]")) {
        console.log(
          "hello",
          formattedMomentDate.isBetween(beginDate, endDate, null, "[]")
        );
        return true;
      }
    }

    return false;
  };

  const closeForm = () => {
    setIsEditing(false);
    setSelectedSlot(null);
    setChangeInput({
      workingHoursType: "Working Hours",
      title: "",
      repeat: false,
      start: moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"),
      end: moment().format("ddd MMM DD YYYY") + " 23:59:59 GMT+0530",
      forwardTo: "Default",
      extensionType: "",
    });
    setEditOption(false);
    setOpenEdit(false);
  };

  const handleEventDelete = (eventToDelete) => {
    const eventId = eventToDelete?.id;
    setSchedules((prevSchedules) =>
      prevSchedules?.filter((item) => item?.id !== eventId)
    );
    setEvents((prevEvents) =>
      prevEvents?.filter((item) => item?.id !== eventId)
    );
    setIsDelete(null);
  };

  useEffect(() => {
    let eventDetails = [];
    events.map((event) => {
      const singleEvent = generateDailyEvents(
        event.start,
        event.end,
        event.title,
        event,
        event.extension
      );

      eventDetails = [...eventDetails, ...singleEvent];
    });
    setDailyEvents(eventDetails);
  }, [events, currentMonthDates]);

  console.log("schedules", schedules);
  function generateDailyEvents(
    startDate,
    endDate,
    eventName,
    singleEvent,
    extension
  ) {
    let start = currentMonthDates?.start
      ? moment(startDate).isAfter(currentMonthDates?.start)
        ? moment(startDate)
        : moment(currentMonthDates?.start)
      : moment(startDate);

    let end = currentMonthDates?.end
      ? moment(endDate).isBefore(currentMonthDates?.end)
        ? moment(endDate)
        : moment(currentMonthDates?.end)
      : moment(endDate);
    let event = [];

    let endTemplate = singleEvent?.end ? moment(singleEvent.end) : null;

    const overriddenEvents = schedules.filter((event) =>
      schedules.some((otherEvent) =>
        otherEvent.OverrideEventsIds?.includes(event.id)
      )
    );

    const getIntersectionDate = () => {
      return (
        overriddenEvents?.map((event) => ({
          beginDate: event?.start,
          endDate: event?.end,
          assignedExtensionId: event?.assignedExtensionId,
        })) || []
      );
    };
    const rangedDateforOverride = getIntersectionDate();

    // const isWithinRange = (beginDate, endDate, start, end) => {
    //   return moment(beginDate).isSameOrAfter(start) && moment(endDate).isSameOrBefore(end);
    // };

    while (start.isBefore(end)) {
      const startDate = moment(start);
      // const endDate = moment(start).clone().add(1, "days"); // One day later, same time
      const endDate = moment(start).clone().set({
        hour: endTemplate.hour(),
        minute: endTemplate.minute(),
        second: endTemplate.second(),
      });

      const dayOfWeek = startDate.format("dddd");
      const isMarked = singleEvent?.repeat
        ? daysConfig(dayOfWeek, singleEvent)
        : true;

      const missingDate = singleEvent?.missingDates
        ? datesConfig(startDate, singleEvent)
        : false;

      const isWithinRange = singleEvent?.OverrideEventsIds
        ? OverridenDatesConfig(startDate, rangedDateforOverride, currentFolder)
        : false;

      console.log("rangedDateforOverride", rangedDateforOverride);
      console.log("isWithinRange", isWithinRange);
      if (isMarked && !isWithinRange) {
        event.push({
          id: uuidv4(),
          parentEventId: singleEvent?.id,
          start: startDate.toDate(),
          end: moment(endDate)
            .set({
              hour: endTemplate.hour(),
              minute: endTemplate.minute(),
              second: endTemplate.second(),
            })
            .toDate(),
          title: eventName,
          workingHoursType: singleEvent.workingHoursType,
          extension: singleEvent?.extension,
          forwardTo: singleEvent?.forwardTo,
          repeat: singleEvent.repeat,
          extension,
        });
      }
      // Move to the next day
      start.add(1, "days");
    }
    // setDailyEvents(event);
    return event;
  }

  const defaultDate = useMemo(() => new Date(2015, 3, 1), []);

  const chooseEditFunction = () => {
    if (editOption === "new") {
      setIsEditing(true);
      setChangeInput({
        ...changeInput,
        start: selectedSlot?.start,
        end: selectedSlot?.end,
      });
    }
    if (editOption === "Override") {
      setIsEditing(true);
      setChangeInput({ ...selectedSlot });
    }
  };

  const editEvent = () => {
    setIsEditing(true);
    setChangeInput({ ...selectedSlot });
  };
  const editAgendaEvent = (eventdetails) => {
    setIsEditing(true);
    setSelectedSlot(eventdetails);
    setChangeInput({ ...eventdetails });
  };
  const onRangeChange = useCallback((range) => {
    setCurrentMonthDates({
      start: range?.start,
      end: range?.end,
    });
  }, []);

  const CustomAgenda = ({ customeEvents }) => (
    <TableContainer component={Paper} sx={{ color: "white" }}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead
          sx={{
            "&.MuiTableHead-root": {
              display: "contents",
            },
          }}
        >
          <TableRow sx={{ width: "100%" }}>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
            ></TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
            >
              From Date
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
            >
              To Date
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
              align="center"
            >
              Time
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
              align="center"
            >
              Title
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
              align="center"
            >
              Repeat
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
              align="center"
            >
              Extension Name{" "}
            </TableCell>
            <TableCell
              sx={{
                color: darkMode && "#fff",
              }}
              align="center"
            >
              Extension Number
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events?.map((event) => (
            <TableRow key={event.id}>
              <TableCell
                component="th"
                scope="event"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                <EditIcon
                  onClick={() => editAgendaEvent(event)}
                  sx={{
                    fontSize: "1.4em",
                    borderRadius: "0.35em",
                    marginRight: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.2)",
                      cursor: "pointer",
                    },
                  }}
                />
                <DeleteIcon
                  onClick={() => setIsDelete(event)}
                  sx={{
                    fontSize: "1.4em",
                    borderRadius: "0.35em",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.2)",
                      cursor: "pointer",
                    },
                  }}
                />
              </TableCell>
              <TableCell
                component="th"
                scope="event"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {moment(event.start).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell
                component="th"
                scope="event"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {moment(event.end).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {moment(event.start).format("hh:mm A ")}-
                {moment(event.end).format(" hh:mm A")}
              </TableCell>{" "}
              <TableCell
                align="center"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {event.title}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {!event.repeat ||
                event?.repeat?.days?.every((day) => day.marked)
                  ? "All days"
                  : event?.repeat?.days
                      ?.filter((day) => day?.marked)
                      .map((day) => day?.day[0])
                      .join(", ")}{" "}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {event?.extensionName}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: darkMode && "#fff",
                }}
              >
                {event.extension}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  CustomAgenda.title = () => {
    return <span>Agenda</span>;
  };

  return (
    <>
      <BigCalendar
        events={dailyEvents}
        localizer={localizer}
        onSelectSlot={onSelectSlot}
        selectable
        onSelectEvent={onSelectEvent}
        onRangeChange={onRangeChange}
        views={{ month: true, agenda: CustomAgenda }}
        style={{ color: darkMode && "white" }}
        popup
      />

      {isDelete && (
        <AlertDelete
          name={isDelete?.title}
          handleConfirmDelete={() => handleEventDelete(isDelete)}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      )}
      <Dialog
        maxWidth="lg"
        open={Boolean(selectedSlot && isEditing)}
        onClose={closeForm}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        >
          {/* Add Details */}
          Schedule your Unavailabilty
        </DialogTitle>
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            padding: "10px 30px 20px 30px",
          }}
        >
          <MDBox
            sx={{
              width: "100%",
            }}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              {/* <FormLabel id="demo-row-radio-buttons-group-label">
              Forward to
            </FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="workingHoursType"
                value={changeInput?.workingHoursType}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  value="Working Hours"
                  control={<Radio />}
                  label={
                    <MDTypography sx={{ ...radioTextSize }}>
                      Working Hours
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="Non-Working Hours"
                  control={<Radio />}
                  label={
                    <MDTypography sx={{ ...radioTextSize }}>
                      Non-Working Hours
                    </MDTypography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </MDBox>
          <MDInput
            fullWidth
            sx={{ textAlign: "center" }}
            size="large"
            name="title"
            // label="Add Title"
            // autoFocus
            placeholder="Add Title"
            variant="standard"
            InputLabelProps={{
              shrink: false,
            }}
            // variant="standard"
            value={changeInput?.title}
            onChange={handleChange}
            inputProps={{ autoComplete: "off" }}
          />
          <MDBox
            sx={{
              border: "1px solid gray",
              padding: "1em",
              borderRadius: "0.5em",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                padding: "0px",
              }}
            >
              <Checkbox
                sx={{
                  ml: "-0.5em",
                }}
                checked={repeat}
                inputProps={{ "aria-label": "controlled" }}
                name="repeat"
                onChange={repeatChange}
              />
              <MDTypography
                variant="p"
                sx={{
                  ...formFontSize,
                  padding: "0",
                  pr: "4em",
                }}
              >
                Repeat
              </MDTypography>

              <MDBox sx={{ display: "flex", gap: 2 }}>
                {days.map((day, index) => (
                  <MDTypography
                    variant="p"
                    sx={{
                      ...formFontSize,
                      color: changeInput?.repeat
                        ? darkMode
                          ? "white"
                          : "black"
                        : "lightgray",
                      // backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      display: "flex",
                      gap: "1",
                      padding: "0.4em",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "1.5em",
                      height: "1.5em",
                      borderRadius: "50%",
                      cursor: "default",
                      backgroundColor: changeInput?.repeat
                        ? changeInput?.repeat?.days[index].marked
                          ? colors.info.main
                          : "gray"
                        : "gray",
                      ":hover": changeInput?.repeat && {
                        backgroundColor: changeInput?.repeat?.days[index].marked
                          ? "gray"
                          : colors.info.focus,
                        color: "black",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      const state = changeInput?.repeat?.days[index].marked;

                      setChangeInput((prev) => ({
                        ...prev,
                        repeat: {
                          ...prev.repeat,
                          days: prev.repeat.days.map((day, idx) => {
                            if (idx === index) {
                              return {
                                ...day,
                                marked: !day.marked,
                              };
                            }
                            return day;
                          }),
                        },
                      }));
                    }}
                  >
                    {day}
                  </MDTypography>
                ))}
              </MDBox>
            </MDBox>
            {/* <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "0px",
              gap: 1,
            }}
          >
            <MDTypography
              variant="p"
              sx={{
                fontSize: "0.7em",
                padding: "0",
              }}
            >
              Repeat On{" "}
            </MDTypography>
          </MDBox> */}
            <MDBox
              sx={{
                width: "100%",
              }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <MDTypography
                    sx={{
                      ...formFontSize,
                      color: changeInput?.repeat ? "white" : "gray",
                    }}
                  >
                    Ends
                  </MDTypography>
                </FormLabel>
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RadioGroup
                    row
                    name="ends-radio-button"
                    value={changeInput?.repeat?.endstype || "Never"}
                    onChange={handleChange}
                    sx={{
                      flexGrow: 2,
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <FormControlLabel
                      value="Never"
                      control={<Radio />}
                      label={
                        <MDTypography
                          sx={{
                            fontSize: 16,
                            color: changeInput?.repeat ? "" : " gray",
                          }}
                        >
                          Never
                        </MDTypography>
                      }
                      disabled={!changeInput?.repeat}
                      InputLabelProps={{
                        style: { color: "red" },
                      }}
                    />
                    <FormControlLabel
                      value="On"
                      control={<Radio />}
                      label={
                        <MDTypography
                          sx={{
                            fontSize: 16,
                            color: changeInput?.repeat ? "" : " gray",
                          }}
                        >
                          On
                        </MDTypography>
                      }
                      disabled={!changeInput?.repeat}
                    />
                  </RadioGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      disablePast
                      disabled={
                        !changeInput?.repeat ||
                        changeInput?.repeat?.endstype === "Never"
                      }
                      inputFormat="MM/DD/YYYY"
                      value={changeInput?.end}
                      onChange={(newValue) => {
                        setChangeInput({ ...changeInput, end: newValue?.$d });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          sx={{
                            // marginLeft:"auto",
                            width: "25%",
                            padding: "0px",
                            bgcolor: "rgba(255,255,255,0.1)",
                          }}
                          InputProps={{
                            inputProps: {
                              style: { textAlign: "center" },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </MDBox>
              </FormControl>
            </MDBox>
          </MDBox>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {changeInput?.repeat ? (
              <>
                <TimePicker
                  renderInput={(props) => (
                    <TextField fullWidth {...props} variant="standard" />
                  )}
                  // label="start Time"

                  value={changeInput?.start}
                  onChange={(newValue) => {
                    setChangeInput({ ...changeInput, start: newValue?.$d });
                  }}
                />
                <TimePicker
                  renderInput={(props) => (
                    <TextField fullWidth {...props} variant="standard" />
                  )}
                  // label="To Time"
                  value={changeInput?.end}
                  onChange={(newValue) => {
                    setChangeInput({ ...changeInput, end: newValue?.$d });
                  }}
                />{" "}
              </>
            ) : (
              <>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      fullWidth
                      {...props}
                      variant="standard"
                      sx={{
                        svg: { color: darkMode && "#fff" },
                        input: { color: darkMode && "#fff" },
                      }}
                    />
                  )}
                  // label="start Date and Time"
                  value={changeInput?.start}
                  onChange={(newValue) => {
                    setChangeInput({ ...changeInput, start: newValue.$d });
                  }}
                />

                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      fullWidth
                      {...props}
                      variant="standard"
                      sx={{
                        svg: { color: darkMode && "#fff" },
                        input: { color: darkMode && "#fff" },
                      }}
                    />
                  )}
                  // label="To Date and Time"
                  value={changeInput?.end}
                  onChange={(newValue) => {
                    setChangeInput({ ...changeInput, end: newValue.$d });
                  }}
                />
              </>
            )}
          </LocalizationProvider>
          <MDBox
            sx={{
              width: "100%",
            }}
          >
            <FormControl value={changeInput?.forwardTo} onChange={handleChange}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <MDTypography sx={{ ...formFontSize }}>Forward to</MDTypography>
              </FormLabel>
              <RadioGroup
                row
                name="forwardTo"
                value={changeInput?.forwardTo}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  gap: 4,
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="Default"
                  label={
                    <MDTypography sx={{ ...radioTextSize }}>
                      Default
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="VoiceMail"
                  control={<Radio />}
                  label={
                    <MDTypography sx={{ ...radioTextSize }}>
                      Voice Mail
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="Number"
                  control={<Radio />}
                  label={
                    <MDTypography sx={{ ...radioTextSize }}>
                      Number
                    </MDTypography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </MDBox>
          <MDInput
            fullWidth
            sx={{
              textAlign: "center",
              bgcolor:
                changeInput?.forwardTo != "Number"
                  ? "rgba(255,255,255,0.1) !important"
                  : "",
            }}
            size="large"
            // label="Extension Number"
            name="extension"
            placeholder="Extension Number"
            // variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            value={changeInput?.extension}
            onChange={handleChange}
            inputProps={{ autoComplete: "off" }}
            disabled={changeInput?.forwardTo != "Number"}
          />
        </MDBox>
        <DialogActions
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            textAlign: "center",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSaveEvent}
          >
            Save
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => setSelectedSlot(null)}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        // maxWidth="xs"
        // fullWidth
        open={Boolean(selectedSlot && !isEditing)}
        onClose={closeForm}
      >
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8em",
            padding: "10px 40px 20px 30px",
          }}
        >
          {/* tools */}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              // gap: "0.2em",
              width: "100%",
              margin: "0px",
              padding: "0px",
            }}
          >
            <IconButton>
              <EditIcon
                onClick={handleEdit}
                sx={{
                  ...iconStyles,
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    borderRadius: "0.35em",
                  },
                }}
              />
            </IconButton>
            {/* <IconButton>
              <DeleteIcon
                // onClick={handleDeleteClick}
                sx={{
                  ...iconStyles,
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    borderRadius: "0.35em",
                  },
                }}
              />
            </IconButton> */}
            <IconButton>
              <CloseIcon
                onClick={closeEventDetailDialog}
                sx={{
                  ...iconStyles,
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    borderRadius: "0.35em",
                  },
                }}
              />
            </IconButton>
          </MDBox>
          {/* title with date  */}

          <MDBox
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <SquareIcon sx={{ color: colors.info.main }} />
            <MDTypography
              sx={{
                color: darkMode ? "#fff" : "#000",
                backgroundColor: darkMode ? "#202940" : "#FFFFFF",
              }}
            >
              {selectedSlot?.title}
            </MDTypography>
          </MDBox>

          <MDBox
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <AccessAlarmIcon sx={{ color: darkMode && "#fff" }} />
            <MDTypography variant={"p"} fontSize="14px">
              {moment(selectedSlot?.start).format("DD MMMM YY,  LT")} {"-"}
              {moment(selectedSlot?.end).format("   DD MMMM YY,  LT")}
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <WorkIcon sx={{ color: darkMode && "#fff" }} />
            <MDTypography variant={"p"} fontSize="14px">
              {selectedSlot?.workingHoursType}
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Phone sx={{ color: darkMode && "#fff" }} />
            <MDTypography variant={"p"} fontSize="14px">
              Forward To :{" "}
              {selectedSlot?.forwardTo === "Number"
                ? selectedSlot?.extension
                : selectedSlot?.forwardTo}
            </MDTypography>
          </MDBox>
          {openEdit && (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2em",
                width: "100%",
              }}
            >
              {/* <MDTypography sx={{ fontSize: 16, fontWeight: "none" }}>
                This will override the current open event
              </MDTypography> */}
              <RadioGroup
                name="edit-radio-button"
                value={editOption || "Override"}
                onChange={handleEditOption}
                sx={{
                  flexGrow: 2,
                  display: "flex",
                }}
              >
                <FormControlLabel
                  value={"Override"}
                  control={<Radio />}
                  label={
                    <MDTypography
                      sx={{
                        fontSize: 16,
                        // color: changeInput?.repeat ? "" : " gray",
                      }}
                    >
                      Modify Current Event
                    </MDTypography>
                  }
                  InputLabelProps={{
                    style: { color: "red" },
                  }}
                />
                <FormControlLabel
                  value="new"
                  control={<Radio />}
                  label={
                    <MDTypography
                      sx={{
                        fontSize: 16,
                        // color: changeInput?.repeat ? "" : " gray",
                      }}
                    >
                      Create New Event
                    </MDTypography>
                  }
                />
              </RadioGroup>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={chooseEditFunction}
                sx={{ marginLeft: "auto" }}
              >
                Proceed
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </Dialog>
    </>
  );
}
