import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ReactPlayer from "react-player";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import CTLNotification from "application/components/CTLNotification";
import variables from "globals/variables";
import { useMaterialUIController } from "context";
import CTLDialogContent from "application/components/styles/CTLDialogContent";

export default function PlayTaskFile({ row }) {
  let filePath = row?.original?.filePath;
  const [open, setOpen] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleClickOpen = () => {
    if (!filePath) {
      setNotify({
        isOpen: true,
        status: "Unable To Play File: File Not Exist",
        type: "error",
        pagename: "voice picker",
      });
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsMaximized(false);
  };

  const handleMaximizeToggle = () => {
    setIsMaximized(!isMaximized);
  };

  const isVideoFile = filePath?.endsWith(".mp4");
  const isAudioFile = filePath?.endsWith(".mp3") || filePath?.endsWith(".wav");
  const isImageFile =
    filePath?.endsWith(".jpeg") ||
    filePath?.endsWith(".jpg") ||
    filePath?.endsWith(".img") ||
    filePath?.endsWith(".png");

  const fileUrl = `${variables.app.telemetry}auth/play?filePath=${filePath}`;

  const renderIcon = () => {
    if (!filePath) {
      return <DoNotDisturbAltOutlinedIcon color="error" />;
    }
    if (isVideoFile) {
      return <VideocamIcon color="info" />;
    }
    if (isAudioFile) {
      return <MusicVideoIcon color="success" />;
    }
    if (isImageFile) {
      return <ImageIcon color="primary" />;
    }
    return <DoNotDisturbAltOutlinedIcon color="error" />;
  };

  return (
    <div>
      <Tooltip
        title={
          !filePath
            ? "File Not Exist"
            : isVideoFile
            ? "Video Play"
            : isAudioFile
            ? "Audio Play"
            : isImageFile
            ? "View Image"
            : ""
        }
      >
        <IconButton
          variant="contained"
          color={
            filePath
              ? isVideoFile
                ? "info"
                : isAudioFile
                ? "success"
                : isImageFile
                ? "primary"
                : "error"
              : "error"
          }
          style={{ margin: "10px" }}
          onClick={handleClickOpen}
        >
          {renderIcon()}
        </IconButton>
      </Tooltip>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        maxWidth={isImageFile ? "xl" : "lg"}
        fullScreen={isMaximized ? isMaximized : ""}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {isImageFile ? "View Image" : `Play File`}
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="maximize"
            onClick={handleMaximizeToggle}
            sx={{
              position: "absolute",
              right: "40px",
              top: "8px",
            }}
          >
            {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {isImageFile ? (
            <img
              src={fileUrl}
              alt="File"
              style={{
                width: isMaximized ? "100%" : "",
                maxHeight: isMaximized ? "100vh" : "50vh",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          ) : isAudioFile ? (
            <ReactPlayer
              url={fileUrl}
              style={{
                width: "100%",
                maxHeight: "50px",
                objectFit: "contain",
                cursor: "pointer",
              }}
              playing={true}
              controls={true}
            />
          ) : isVideoFile ? (
            <ReactPlayer
              url={fileUrl}
              width={isMaximized ? "100%" : "100%"}
              height={isMaximized ? "100%" : "auto"}
              playing={true}
              controls={true}
            />
          ) : null}
        </DialogContent>
      </Dialog>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}
