import { v4 as uuidv4 } from "uuid";
import { WIDGET_TYPES } from "../../configs/widgetsConfigs";
import Piechart from "../../widgets/charts/Piechart";
import Doughnutchart from "../../widgets/charts/Doughnutchart";
import Barchart from "../../widgets/charts/Barchart";
import Counter from "../../widgets/counter";
import ServerTable from "../../widgets/tables/ServerTable";
import UITable from "../../widgets/tables/UITable";
import Linechart from "../../widgets/charts/Linechart";
import Calendar from "../../widgets/calendar";
import Topology from "../../widgets/topology";
import SmartTopology from "../../widgets/smartTopology";
import GraphTopology from "../../widgets/graphTopology";
import Iframe from "../../widgets/iFrame";
import VideoStream from "../../widgets/videoStream";
import Speedometer from "../../widgets/speedometer";
import Timeseries from "../../widgets/charts/Timeseries";
import Multiseries from "../../widgets/charts/Multiseries";
import LocationGraph from "../../widgets/locationGraph";
import LocationTree from "../../widgets/locationTree";
import Custom from "../../../../globals/widgets/Custom";
import CGraph from "../../widgets/cGraph";
import CytoscapeGraph from "../../widgets/cytoscapeGraph";
import Multicounter from "../../widgets/multicounter";
import GIS from "../../widgets/gis";

function widgetsSelected(
  masterContainerId,
  cellId,
  handleDeleteWidget,
  cellData,
  setLayoutData,
  containerType,
  widgetsDataObj,
  handleBreadcrumbs,
  widgetsDataObj2,
  isEditMode,
  isAdmin,
) {
  const widgets = cellData.widgetIds.map((cellWidgetInfo, index) => {
    const widget =
      widgetsDataObj[cellWidgetInfo.id] || widgetsDataObj2[cellWidgetInfo.id];

    if (cellWidgetInfo.widgetType === WIDGET_TYPES.counter)
      return (
        <Counter
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.multicounter)
      return (
        <Multicounter
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.calendar)
      return (
        <Calendar
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.piechart)
      return (
        <Piechart
          key={uuidv4()}
          setLayoutData={setLayoutData}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.doughnutchart)
      return (
        <Doughnutchart
          key={uuidv4()}
          setLayoutData={setLayoutData}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.barchart)
      return (
        <Barchart
          key={uuidv4()}
          setLayoutData={setLayoutData}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.linechart)
      return (
        <Linechart
          key={uuidv4()}
          setLayoutData={setLayoutData}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.timeseries)
      return (
        <Timeseries
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.multiseries)
      return (
        <Multiseries
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          widgetHeight={
            containerType === "horizontal"
              ? cellData.height
              : cellData.defaultWidgetHeight || cellData.height
          }
          index={index}
          containerType={containerType}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.graphTopology)
      return (
        <GraphTopology
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.cose) {
      return (
        <CGraph
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    }
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.preset)
      return (
        <CytoscapeGraph
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.locationGraph)
      return (
        <LocationGraph
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.locationTree)
      return (
        <LocationTree
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.topology)
      return (
        <Topology
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.serverTable)
      return (
        <ServerTable
          key={uuidv4()}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          index={index}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.UITable)
      return (
        <UITable
          key={uuidv4()}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          index={index}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          handleBreadcrumbs={handleBreadcrumbs}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.smartTopology)
      return (
        <SmartTopology
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.iFrame)
      return (
        <Iframe
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.videoStream)
      return (
        <VideoStream
          key={uuidv4()}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          containerType={containerType}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if (cellWidgetInfo.widgetType === WIDGET_TYPES.speedometer)
      return (
        <Speedometer
          key={uuidv4()}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          containerType={containerType}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      );
    if(cellWidgetInfo.widgetType === WIDGET_TYPES.gisMap)
      return (
        <GIS
          key={uuidv4()}
          widgetHeight={cellData.defaultWidgetHeight || cellData.height}
          widget={widget}
          cellWidgetInfo={cellWidgetInfo}
          index={index}
          masterContainerId={masterContainerId}
          cellId={cellId}
          handleDeleteWidget={handleDeleteWidget}
          containerType={containerType}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      )
    return (
      <Custom
        key={uuidv4()}
        widgetHeight={cellData.defaultWidgetHeight || cellData.height}
        widget={widget}
        cellWidgetInfo={cellWidgetInfo}
        index={index}
        masterContainerId={masterContainerId}
        cellId={cellId}
        handleDeleteWidget={handleDeleteWidget}
        containerType={containerType}
        isEditMode={isEditMode}
        isAdmin={isAdmin}
      />
    );
  });

  return widgets;
}

export default widgetsSelected;
