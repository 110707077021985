const adminMenus = [
  {
    icon: "list",
    key: "pbx.extension",
    name: "Extensions",
    url: "home/app/pbx/extension",
    description: "Subscriber List",
    disabled: true,
  },
  {
    icon: "ondemand_video",
    key: "video.recording",
    name: "Video Recording",
    url: "home/app/video/recording",
    description: "video recording",
    disabled: true,
  },
  {
    icon: "route",
    key: "configuration.routes",
    name: "Routes",
    url: "home/app/configurations/routes",
    description: "Routes",
    disabled: true,
    productionReady: true,
  },
  {
    icon: " wifi_channel",
    key: "configuration.wans",
    name: "Wans",
    url: "home/app/configurations/wans",
    description: "Wans",
    disabled: true,
    productionReady: true,
  },
  {
    icon: "timer",
    key: "configuration.timer",
    name: "Configuration Timer",
    url: "home/app/configurations/timer",
    description: "Configuration Timer",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "account_circle",
    key: "change-Usert-Profile",
    name: "Change-User-Profile",
    url: "home/app/configurations/changeAgentProfile",
    description: "Change User Profile",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "person",
    key: "configuration.UserZoneConfiguration",
    name: "Zone Configuration",
    url: "home/app/configurations/UserZoneConfiguration",
    description: "User-Zone",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "map",
    key: "nms.maps",
    name: "Suraksha",
    url: "home/app/nms/map",
    description: "MAP",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "call",
    key: "uc.External.api.call.log",
    name: "External API call log",
    description: "external api call log",
    url: "home/app/uc/External-API-call-log",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "settings",
    key: "default.extension.services",
    name: "Default extension services",
    description: "Default extension services",
    url: "home/app/default/extension/services",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "settings",
    key: "configuration.priconfiguration",
    name: "PRI Configuration",
    description: "pri_configuration",
    url: "home/app/configurations/priconfiguration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "compare",
    key: "configuration.comparison",
    name: "Comparison Page ",
    description: "Default Configuration Comparison Page ",
    url: "home/app/configurations/comparison",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "phone",
    key: "configuration.siptrunk",
    name: "Sip Trunk",
    description: "Default Sip Trunk",
    url: "home/app/configurations/siptrunk",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub",
    key: "configuration.system-configuration",
    name: "System-Configuration",
    url: "home/app/configurations/system-configuration",
    description: "System-Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "map",
    key: "user.zone",
    name: "User Zone",
    url: "home/app/user-zone",
    description: "User-Zone",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "person",
    key: "userStatus",
    name: "User Status",
    url: "home/app/user-status",
    description: "User-Status",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "security",
    key: "security.configuration",
    name: "Security-Configuration",
    url: "home/app/securityConfiguration",
    description: "Security Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "upload_file",
    key: "configuration.JSONFileCreation",
    name: "JSON File Creation",
    url: "home/app/configurations/JSONFileCreation",
    description: "JSON File Creation",
    productionReady: false,
    disabled: false,
  },
  {
    icon: "phone",
    key: "internalForward",
    name: "Call Forwarding",
    url: "home/app/configurations/InternalForward",
    description: "Call Forwarding",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "security",
    key: "inboundGateway",
    name: "Inbound Gateway",
    url: "home/app/configurations/inboundGateway",
    description: "Inbound Gateway",
    productionReady: true,
  },
  // {
  //   icon: "admin",
  //   key: "Campaign",
  //   name: "Campaign",
  //   url: "home/app/Campaign",
  //   description: "Campaign",
  //   productionReady: true,
  // },
  {
    icon: "outbound",
    key: "outboundgateway",
    name: "OutboundGateway",
    url: "home/app/outboundgateway",
    description: "OutboundGateway",
    productionReady: true,
  },
  {
    icon: "confirmation_number_icon",
    key: "imsiconfiguration",
    name: "IMSI Configuration",
    url: "home/app/imsiconfiguration",
    description: "",
    productionReady: true,
  },
  {
    icon: "castle",
    key: "companyconfiguration",
    name: "Company Configuration",
    url: "home/app/companyconfiguration",
    description: "",
    productionReady: true,
  },
  {
    icon: "candlestick_chart",
    key: "departmentconfiguration",
    name: "Department Configuration",
    url: "home/app/departmentconfiguration",
    description: "",
    productionReady: true,
  },
  {
    icon: "casino",
    key: "zone&department",
    name: "Zone & Department Update",
    url: "home/app/department_&_zone_update",
    description: "Configure Zone and Department For Extension",
    productionReady: true,
  },
  {
    icon: "person_pin_icon",
    key: "userActivityLog",
    name: "User Activity log",
    url: "home/app/user activity log",
    description: "User Activity Log",
    productionReady: true,
  },
  {
    icon: "person",
    key: "userShift",
    name: "User Shift",
    url: "home/app/user-shift",
    description: "User Shift",
    productionReady: true,
  },
  {
    icon: "access_time_icon",
    key: "userShiftTime",
    name: "Shift Time",
    url: "home/app/user-shift-time",
    description: "Shift Time",
    productionReady: true,
  },
  {
    icon: "gpp_good_icon",
    key: "smdpCredentials",
    name: "SMDP Credentials",
    url: "home/app/SMDP Credentials",
    description: "Configuration",
    productionReady: true,
  },
];

export { adminMenus };
